<div *ngIf="upPII" style="margin-top:0em; padding-top:0em;">
  <form *ngIf="trip_details_client_form" [formGroup]="trip_details_client_form">
    <div class="divLeft">
      <div style="padding-left:0em; ">
        <div style="display: flex; width:93.5em">
          <div style="width:82.5em ">
            <h5>{{selectedTripCode}} - Trip Details {{tripCodeType}} {{customerTripID!=''?'Client Trip # '+customerTripID:''}}</h5>
          </div>
          <div >
            <div style="margin-top:0em;margin-right:0em ">
              <button type="button" mat-button class="btn-low" style="font-weight: normal !important; height: 2em !important" (click)="clickOpenTripSearch()">
                <div style="display: flex; margin-top:0em">
                  <div style="margin-top: 0.25em; margin-left:0em">
                    <i class="material-icons" style="font-size: medium;cursor: pointer; color:#337dff">arrow_back</i>
                  </div>
                  <div style="margin-top:0em; font-weight:normal !important">
                    Open Trip Schedules
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div style="margin-left:0em;padding:0em; display: flex; ">
          <div style="width: 48.5em; margin-right: 0.5em; background-color: #f8f8f8; border-radius:0.25em;border: 0.0625em lightgrey solid; padding-left:0.25em; padding-right:0.25em; padding-top:0.25em;height: 6em" >
            <div style="display:flex; justify-content: space-between ; margin-top:-0.5em; font-size: small; text-align: right">
              <div style="display:flex; margin-right:0.5em" *ngIf="grantAccessToAccountGrouping">
                <div style="margin-right:0.25em; margin-top:0.25em; width:5.25em;">Account</div>
                <div *ngIf="grantAccessCompanyProfiles" class="divHover" style="font-size:medium; font-weight: bold; margin-top:0em; float: left; cursor: pointer" (click)="clickClient()"> {{clientName}}</div>
                <div *ngIf="!grantAccessCompanyProfiles" style="font-size:medium; font-weight: bold; margin-top:0em; float: left" > {{clientName}}</div>
              </div>
            </div>
            <div style="display: flex">
              <div style="margin-right:0.25em">
                <div style="display: flex; justify-content: space-between; margin-top: 0em; font-size: small; text-align: right">
                  <div></div><div style="float: right" >Registration</div>
                </div>
                <div style="margin-top:0.25em; text-align: right; margin-left:-0.5em"><i class="material-icons-outlined" style="font-size:x-large;color: gray">flight</i></div>
              </div>
              <div>
                <div style="display: flex; justify-content: space-between; min-width:15em">
                  <div style="float:left">
                    <div *ngIf="grantAccessAircraftProfiles" style="font-size:small; font-weight:bold; margin-top:0em; cursor: pointer" class="divHover" title="{{acTooltip}}" (click)="clickAircraft()">{{registration}}</div>
                    <div *ngIf="!grantAccessAircraftProfiles" style="font-size:small; font-weight:bold; margin-top:0em;" title="{{acTooltip}}" >{{registration}}</div>
                  </div>
                </div>
                <div style="display: flex">
                  <div style="margin-right:0.5em">
                    <div style="font-size: small; margin-top:-0.25em">{{acType}}</div>
                    <!--<div style="font-size: small; margin-top:-0.25em; white-space:nowrap; ">{{aircraftInfo}}</div>-->
                  </div>
                  <div>
                    <div *ngIf="homeBaseAirport!=''" style="font-size: small; margin-top:-0.25em; white-space:nowrap; display: flex">
                      <div style="margin-left: -0.25em; margin-top:0.25em"><i class="material-icons-outlined" style="font-size:large;color: gray">location_on</i></div>
                      <div>HOME BASE&nbsp;{{homeBaseAirport}}</div>
                    </div>
                  </div>
                </div>
                <div style="display: flex;margin-top:-0.25em; font-size:small">
                  <div *ngIf="aircraftInfo!=''" style="white-space: nowrap;margin-right:1.5em">{{aircraftInfo}}</div>
                  <div *ngIf="flightPlanProviderName!=''" style="white-space: nowrap;margin-right:1.5em">{{flightPlanProviderName!=''?'Flight Plan '+flightPlanProviderName:''}}</div>
                  <div *ngIf="uplinkVendor!=''" style="white-space: nowrap;">{{uplinkVendor!=''?'Datalink '+uplinkVendor:''}}</div>
                </div>

              </div>
            </div>
            <div *ngIf="selectedGroundStopList.length>0" style="font-size: small; margin-top:-0.5em;display: flex">
              <div style="display: flex">
                <div style="margin-right: 0.25em; text-align: right; width:5.25em">Route</div>
                <div>
                  <div *ngIf="selectedGroundStopList.length>19" style="display: flex" class="divHover" (mouseover)="showWholeRoute=true" (mouseout)="showWholeRoute=!showWholeRoute">
                    <div *ngFor="let gs of selectedGroundStopList; let i=index">
                      <div *ngIf="i<=18" style="display: flex">
                        <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i==19" style="display: flex">
                        <div [style.background-color]="gs.groundStopTypeColor">....</div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="selectedGroundStopList.length<=19" style="display: flex">
                    <div *ngFor="let gs of selectedGroundStopList; let i=index">
                      <div *ngIf="i<=18" style="display: flex">
                        <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="showWholeRoute" style="margin-top:1.75em; margin-left: 5.25em; background-color: #f8f8f8; position:absolute; z-index:1000; border: 0.0625em solid lightgray; border-radius:0.25em; padding-left:0.25em; padding-right:0.25em">
                <div style="font-weight: bold">Route: </div>
                <div *ngFor="let gs of selectedGroundStopList; let i=index">
                  <div *ngIf="i % 19==0" style="display: flex">
                    <div style="display: flex">
                      <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 1 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+1].groundStopTypeColor">{{selectedGroundStopList[i+1].icao}}</div><div>{{(i+1)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 2 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+2].groundStopTypeColor">{{selectedGroundStopList[i+2].icao}}</div><div>{{(i+2)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 3 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+3].groundStopTypeColor">{{selectedGroundStopList[i+3].icao}}</div><div>{{(i+3)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 4 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+4].groundStopTypeColor">{{selectedGroundStopList[i+4].icao}}</div><div>{{(i+4)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 5 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+5].groundStopTypeColor">{{selectedGroundStopList[i+5].icao}}</div><div>{{(i+5)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 6 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+6].groundStopTypeColor">{{selectedGroundStopList[i+6].icao}}</div><div>{{(i+6)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 7 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+7].groundStopTypeColor">{{selectedGroundStopList[i+7].icao}}</div><div>{{(i+7)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 8 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+8].groundStopTypeColor">{{selectedGroundStopList[i+8].icao}}</div><div>{{(i+8)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 9 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+9].groundStopTypeColor">{{selectedGroundStopList[i+9].icao}}</div><div>{{(i+9)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 10 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+10].groundStopTypeColor">{{selectedGroundStopList[i+10].icao}}</div><div>{{(i+10)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 11 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+11].groundStopTypeColor">{{selectedGroundStopList[i+11].icao}}</div><div>{{(i+11)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 12 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+12].groundStopTypeColor">{{selectedGroundStopList[i+12].icao}}</div><div>{{(i+12)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 13 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+13].groundStopTypeColor">{{selectedGroundStopList[i+13].icao}}</div><div>{{(i+13)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 14 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+14].groundStopTypeColor">{{selectedGroundStopList[i+14].icao}}</div><div>{{(i+14)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 15 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+15].groundStopTypeColor">{{selectedGroundStopList[i+15].icao}}</div><div>{{(i+15)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 16 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+16].groundStopTypeColor">{{selectedGroundStopList[i+16].icao}}</div><div>{{(i+16)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 17 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+17].groundStopTypeColor">{{selectedGroundStopList[i+17].icao}}</div><div>{{(i+17)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 18 < selectedGroundStopList.length" style="display: flex">
                      <div [style.background-color]="selectedGroundStopList[i+18].groundStopTypeColor">{{selectedGroundStopList[i+18].icao}}</div><div>{{(i+18)==selectedGroundStopList.length-1? '': '-'}}</div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </div>
          <div style="margin-right:0.5em; background-color: #f8f8f8; border-radius:0.25em;border: 0.0625em lightgrey solid; padding-left:0.25em; padding-right:0.25em;width:20em;height: 6em">
            <div style="font-weight: bold; font-size: small">Shortcuts</div>
            <div style="display: flex;margin-top:0.25em ">
              <div style="margin-right:0.5em">
                <!--<div>
    <button type="button" mat-button class="btn-med" style="height:1.5em; width:11em;padding-left:0.25em !important;padding-right:0.25em !important" (click)="clickClientInstruction()">
      <div style="display: flex">
        <div style="margin-top: 0em; margin-left:0.25em">
          <i class="material-icons-two-tone icon_lightblue" style="font-size: medium;cursor: pointer;">fact_check</i>
        </div>
        <div style="margin-top:-0.25em; font-weight:normal !important">
          Client Instructions
        </div>
        <div *ngIf="clientInstructions!=''" style="width:1em"><i class="material-icons" style="font-size: small;cursor: pointer">notes</i></div>
      </div>
    </button>
  </div>-->
                <!--<div>
    <button type="button" mat-button class="btn-med" style="height:1.5em;width:6.5em;padding-left:0.25em !important;padding-right:0.25em !important">
      <div style="display: flex">
        <div style="margin-top: 0em; margin-left:0em">
          <i class="material-icons-two-tone icon_lightblue" style="font-size: medium;cursor: pointer;">info</i>
        </div>
        <div style="margin-top:-0.25em; font-weight:normal !important">
          Trip Alerts
        </div>
      </div>
    </button>
  </div>-->
                <div>
                  <button type="button" mat-button class="btn-med" style="height:1.5em; width:11.35em;padding-left:0.25em !important;padding-right:0.25em !important" (click)="clickContact()">
                    <div style="display: flex">
                      <div style="margin-top: 0em; margin-left:0.25em">
                        <i class="material-icons-two-tone icon_lightblue" style="font-size: medium;cursor: pointer;">contact_page</i>
                      </div>
                      <div style="margin-top:-0.25em; font-weight:normal !important">
                        Contact
                      </div>
                    </div>
                  </button>
                </div>
                <div>
                  <button type="button" mat-button class="btn-med" style="height: 1.5em; width: 11.35em; padding-left: 0.25em !important; padding-right: 0.25em !important" (click)="openTripSummaryReport()">
                    <div style="display: flex">
                      <div style="margin-top: 0em; margin-left:0.25em">
                        <i class="material-icons-two-tone icon_lightblue" style="font-size: medium;cursor: pointer;">summarize</i>
                      </div>
                      <div style="margin-top:-0.25em; font-weight:normal !important">
                        Trip Summary Report
                      </div>
                    </div>
                  </button>
                </div>
                <div>
                  <div>
                    <button type="button" mat-button class="btn-med" style="height:1.5em; width:11.35em;padding-left:0.25em !important;padding-right:0.25em !important" (click)="clickTripDocuments()">
                      <div style="display: flex">
                        <div style="margin-top: 0em; margin-left:.25em">
                          <i class="material-icons-two-tone icon_lightblue" style="font-size: medium;cursor: pointer;">description</i>
                        </div>
                        <div style="margin-top:-0.25em; font-weight:normal !important">
                          Trip Documents
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                
              </div>

              <div>

                <div>
                  <button type="button" mat-button class="btn-med" style="height:1.5em; width:11em;padding-left:0.25em !important;padding-right:0.25em !important" (click)="clickMessages()">
                    <div style="display: flex">
                      <div style="margin-top: 0em; margin-left:.25em">
                        <i class="material-icons-two-tone icon_lightblue" style="font-size: medium;cursor: pointer;">mail_outline</i>
                      </div>
                      <div style="margin-top:-0.25em; font-weight:normal !important">
                       Trip Messages
                      </div>
                    </div>
                  </button>
                </div>
                <div>
                  <div>
                    <button type="button" mat-button class="btn-med" style="height:1.5em; width:11em;padding-left:0.25em !important;padding-right:0.25em !important" (click)="clickAddEditCrewPax()">
                      <div style="display: flex">
                        <div style="margin-top: 0em; margin-left:.25em">
                          <i class="material-icons-two-tone icon_lightblue" style="font-size: medium;cursor: pointer;">manage_accounts</i>
                        </div>
                        <div style="margin-top:-0.25em; font-weight:normal !important">
                          Manifest
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div>
                  <div>
                    <button type="button" mat-button class="btn-med" style="height:1.5em; width:11em;padding-left:0.25em !important;padding-right:0.25em !important" (click)="openTripPlanner()">
                      <div style="display: flex">
                        <div style="margin-top: 0em; margin-left:.25em">
                          <i class="material-icons-two-tone icon_lightblue" style="font-size: medium;cursor: pointer;">connecting_airports</i>
                        </div>
                        <div style="margin-top:-0.25em; font-weight:normal !important">
                          Trip Planner
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              
            </div>
            
              
           
          </div>

          <!--<div style="width: 24.5em; height:5em; background-color: #f8f8f8; border-radius:0.25em;border: 0.0625em lightgrey solid; padding-left:0.25em; padding-right:0.25em; padding-top:0em; margin-right:0.5em">
        <div style="font-weight: bold; font-size: small">Audit Trail</div>
        <div style="display: flex; margin-top:0.25em">
          <div style="margin-right:0.5em;">
            <button mat-button class="btn-med" style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em" (click)="clickAudit('')"><div style="display: flex"><div style="margin-top: 0em;margin-bottom:-0.25em "><i class="material-icons" style="font-size: medium;">manage_search</i></div><div style="margin-top:-0.25em; font-weight: normal !important;">Audit Trail {{auditHrText}}</div> </div></button>
          </div>
          <div class="slidecontainer" style="margin-top:-2em; margin-right:0.5em; width:10em">
            <input type="range" min="0" max="8" class="slider" [value]="auditIndex" step="1" (change)="auditSlideChange($event)">
          </div>
          <div style="display: flex; margin-top:0em;">
            <div>
              <i class="material-icons" style="font-size: larger;cursor: pointer; color: gray" (click)="clickRefreshAudit()">refresh</i>
            </div>
            <div style="font-size: small; white-space:nowrap; color:#337dff">
              <a style="cursor: pointer" (click)="clickRefreshAudit()">Refresh Audit</a>
            </div>
          </div>
        </div>
        <div style="display:flex; margin-top:-0.5em; font-size: small">
          <div *ngFor="let u of usernameList; let i=index">
            <div *ngIf="i<3" style="margin-right:0.25em;">
              <button mat-button class="btn-low" style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em" (click)="clickAudit(u.userName)"><div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div></button>
            </div>
            <div *ngIf="i==3 && !showMoreUsernameList" style="margin-right:0.25em;">
              <button mat-button class="btn-low" style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em" (click)="showMoreUsernameList=!showMoreUsernameList"><div style="margin-top:-0.25em; font-weight: normal !important;">More...</div></button>
            </div>
          </div>
          <div *ngIf="showMoreUsernameList" style="float: left; overflow-y:auto; background-color:#f8f8f8; margin-top:-0.5em; display: flex; justify-content: space-between; min-width: 10em; border: solid gray 0.0625em; border-radius:0.25em; padding:0.5em 1em 0.5em 1em; z-index: 1000;">
            <div>
              <div *ngFor="let u of usernameList; let i=index" style="margin-left:auto; margin-right:auto">
                <div *ngIf="i>=3" style="margin-right:0.25em;">
                  <button mat-button class="btn-low" style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em" (click)="clickAudit(u.userName)"><div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div></button>
                </div>
              </div>
            </div>
            <div style="float: right">
              <i class="material-icons" style="cursor: pointer; color:black;font-size: medium" (click)="showMoreUsernameList=!showMoreUsernameList">close</i>
            </div>
          </div>
        </div>
      </div>-->
          <div style="width:24em; background-color: #f8f8f8; border-radius:0.25em;border: 0.0625em lightgrey solid; padding-left:0.25em; padding-right:0.25em; padding-top:0em; margin-right:0em;height:6em">
            <div style="font-weight: bold; font-size:small">Filter Options</div>
            <div style="display: flex; margin-top:0.25em">
              <div style="margin-top:0.5em; margin-right:0.25em;">
                <button type="button" mat-button class="btn-low" style="width:6em; padding-left:0.25em !important; padding-right:0.25em !important" (click)="clickTripCode()">Trip Code</button>
              </div>
              <!--<div style="margin-top:0.5em; margin-right:0.25em;">
            <button type="button" mat-button class="btn-low" style="width:6em; padding-left:0.25em !important; padding-right:0.25em !important" [ngClass]="selectedServiceTypeCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickService()">Service{{selectedServiceTypeCountText}}</button>
          </div>-->
              <div style="margin-top:0.5em; margin-right:0.25em;">
                <button type="button" mat-button class="btn-low" style="width:6em; padding-left:0.25em !important; padding-right:0.25em !important" [ngClass]="selectedTaskStatusCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickTaskStatus()">Status{{selectedTaskStatusCountText}}</button>
              </div>
              <div>
                <div style="margin-left: 2.4em; margin-top: 0.5em; white-space: nowrap">
                  <button type="button" mat-button class="btn-low" (click)="clickSubmit()" style="height:2em">
                    <div style="display: flex">
                      <div style="margin-top: 0.25em; margin-left:-0.25em">
                        <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>
                      </div>
                      <div>
                        REFRESH
                      </div>
                    </div>
                  </button>
                </div>

                <div *ngIf="errGSMsg!=''" class="d-block" style="margin-left:1em; display:flex;margin-top:0.5em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errGSMsg}}</label></div>
              </div>
            </div>
            <!--trip code list-->
            <div *ngIf="showTripCodeList" style="position: absolute; float: left; min-width: 11em; margin-top: 0em; margin-left: -14em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
              <div style="font-weight: bold; text-decoration:underline">Trip Code</div>
              <div style="max-height: 25em;margin-bottom:-1.5em" class="scroll-thin">
                <table mat-table *ngIf="tripCodeList" [dataSource]="tripCodeList" class="example-container mat-elevation-z8">
                  <tr>
                    <ng-container matColumnDef="select">
                      <th mat-header-cell *matHeaderCellDef style="width:10%; ">
                        <div style="margin-top:-0.25em;">Select</div>
                      </th>
                      <td mat-cell *matCellDef="let element" style="white-space:nowrap; ">
                        <mat-radio-button (click)="$event.stopPropagation()" style="transform: scale(0.75)"
                                          (change)="tripCodeOnChange($event, element)"
                                          [checked]="element.selected"></mat-radio-button>

                      </td>
                    </ng-container>
                    <ng-container matColumnDef="tripcode">
                      <th mat-header-cell *matHeaderCellDef style="width:15%; text-align:left !important; ">
                        <div style="margin-top:-0.25em;">Trip Codes</div>
                      </th>
                      <td mat-cell #tripCodeRefs *matCellDef="let element" [attr.tripCode]="element.tripCode" style="white-space:nowrap; text-align:left !important; ">{{element.tripCode}} </td>
                    </ng-container>
                    <ng-container matColumnDef="route">
                      <th mat-header-cell *matHeaderCellDef style="width:50%; text-align:left !important; ">
                        <div style="margin-top:-0.25em;">Route</div>
                      </th>
                      <td mat-cell *matCellDef="let element" style="text-align: left !important; ">
                        <div *ngFor="let gs of element.groundStopList; let i=index">
                          <div *ngIf="i % 7==0" style="display: flex">
                            <div style="display: flex">
                              <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==element.groundStopList.length-1? '': '-'}}</div>
                            </div>
                            <div *ngIf="i + 1 < element.groundStopList.length" style="display: flex">
                              <div [style.background-color]="element.groundStopList[i+1].groundStopTypeColor">{{element.groundStopList[i+1].icao}}</div><div>{{(i+1)==element.groundStopList.length-1? '': '-'}}</div>
                            </div>
                            <div *ngIf="i + 2 < element.groundStopList.length" style="display: flex">
                              <div [style.background-color]="element.groundStopList[i+2].groundStopTypeColor">{{element.groundStopList[i+2].icao}}</div><div>{{(i+2)==element.groundStopList.length-1? '': '-'}}</div>
                            </div>
                            <div *ngIf="i + 3 < element.groundStopList.length" style="display: flex">
                              <div [style.background-color]="element.groundStopList[i+3].groundStopTypeColor">{{element.groundStopList[i+3].icao}}</div><div>{{(i+3)==element.groundStopList.length-1? '': '-'}}</div>
                            </div>
                            <div *ngIf="i + 4 < element.groundStopList.length" style="display: flex">
                              <div [style.background-color]="element.groundStopList[i+4].groundStopTypeColor">{{element.groundStopList[i+4].icao}}</div><div>{{(i+4)==element.groundStopList.length-1? '': '-'}}</div>
                            </div>
                            <div *ngIf="i + 5 < element.groundStopList.length" style="display: flex">
                              <div [style.background-color]="element.groundStopList[i+5].groundStopTypeColor">{{element.groundStopList[i+5].icao}}</div><div>{{(i+5)==element.groundStopList.length-1? '': '-'}}</div>
                            </div>
                            <div *ngIf="i + 6 < element.groundStopList.length" style="display: flex">
                              <div [style.background-color]="element.groundStopList[i+6].groundStopTypeColor">{{element.groundStopList[i+6].icao}}</div><div>{{(i+6)==element.groundStopList.length-1? '': '-'}}</div>
                            </div>

                          </div>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="startDate">
                      <th mat-header-cell *matHeaderCellDef style="width:15%; ">
                        <div style="margin-top:-0.25em;">Start Date</div>
                      </th>
                      <td mat-cell *matCellDef="let element" style="white-space:nowrap;">{{element.estimatedTripStartDate}} </td>
                    </ng-container>
                    <ng-container matColumnDef="endDate">
                      <th mat-header-cell *matHeaderCellDef style="width:15%;">
                        <div style="margin-top:-0.25em;">End Date</div>
                      </th>
                      <td mat-cell *matCellDef="let element" style="white-space:nowrap;">{{element.estimatedTripEndDate}} </td>
                    </ng-container>
                  </tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnTripCode; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnTripCode"></tr>
                </table>
              </div>
              <div style="margin-top:-0.5em; height:0.75em"><hr color="#f8f8f8" /></div>
              <div style="display: flex; float: right">
                <div style="margin-right:0.5em">
                  <button type="button" mat-button class="btn-low" style="height:2em" (click)="clickCloseTripCode()">CLOSE</button>
                </div>
                <div>
                  <button type="button" mat-button class="btn-high" (click)="clickDoneTripCode()" style="height:2em">DONE</button>
                </div>
              </div>
            </div>
            <!--trip code list end-->
            <!--task status-->
            <div *ngIf="showTaskStatusList" style="position: absolute; float: left; min-width: 20em; margin-top: 0.25em; margin-left: 0em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
              <div style="display: flex; justify-content:space-between">
                <div style="font-weight: bold; text-decoration:underline">Task Status</div>
                <div style="float:right" (click)="showTaskStatusList=!showTaskStatusList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
              </div>
              <div *ngIf="taskStatusList.length>0" style="max-height: 22em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                <div *ngFor="let t of taskStatusList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                  <div style="display: flex; font-size: small; margin-top:-0.5em">
                    <div>
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="onTaskStatusChange($event, t)" [checked]="t.selected">{{t.taskStatusDescription}}</mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div style="margin-top:-0.5em; height:0.75em"><hr color="#f8f8f8" /></div>
              <div style="display: flex; float: right">
                <div style="margin-right:0.5em">
                  <button type="button" mat-button class="btn-low" (click)="clickResetTaskStatus()" style="height:2em">RESET</button>
                </div>
                <div>
                  <button type="button" mat-button class="btn-high" (click)="clickDoneTaskStatus()" style="height:2em">DONE</button>
                </div>
              </div>
            </div>
            <!--task status-->
          </div>

        </div>
      </div>

      <div style="display: flex; ">
        <div [style.width.em]="childTableWidth">
          <div class="form-inline" style="margin-top:0.5em;">
            <div style="margin-right:0.5em">
              <ul class="tabul">
                <li [ngClass]="{ 'btn-high':selectedTabName=='groundStop','btn-low pointer':selectedTabName!='groundStop'  }" style="border-left: black 0.0625em solid;" (click)="clickTab('groundStop')">
                  <div style="display: flex; margin-left:0.2em">
                    <div style="margin-top: 0.25em;margin-right: 0.25em;">
                      <i class="material-icons" style="font-size: x-large;cursor: pointer" [style.color]="selectedTabName=='groundStop'?'white':'gray'">airline_stops</i>
                    </div>
                    <div style="margin-top:0.25em">Airport View</div>
                  </div>
                </li>
                <li [ngClass]="{ 'btn-high':selectedTabName=='leg','btn-low pointer':selectedTabName!='leg' }" (click)="clickTab('leg')">
                  <div style="display: flex; margin-left: 0.2em">
                    <div style="margin-top: 0.5em;margin-right: 0.25em;">
                      <i class="material-icons" style="font-size: large;cursor: pointer" [style.color]="selectedTabName=='leg'?'white':'gray'">language</i>
                    </div>
                    <div style="margin-top:0.25em">Leg View</div>
                  </div>
                </li>
              </ul>
            </div>
            <div style="margin-left:-0.5em">
              <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="hidePastStop" formControlName="hidePastStop" (change)="hidePastLegsChange($event)">Hide Prior Legs</mat-slide-toggle>
              <mat-slide-toggle style="transform:scale(.8); height:2em; margin-left:-1em" *ngIf="hasCancelledStop" color="primary" [checked]="includeActiveStopOnly" formControlName="includeActiveStopOnly" (change)="includeActiveStopOnlyChange($event)">Show Active Legs Only</mat-slide-toggle>
            </div>
            
          </div>
        </div>
        <div style="margin-top: 1em; ">
          <a style="cursor: pointer; font-size:small; color: Highlight" (click)="clickLegend();">Legend</a>
        </div>
      </div>
      <div>
        <div>
          <app-ground-stoplist-client *ngIf="selectedTabName=='groundStop'" [selectedTabName]="selectedTabName" [requestInput]="request" [reloadChildPage]="reloadChildPage" [refreshChildColumn]="refreshChildColumn" [hidePastStop]="hidePastStop" [customerGUID]="customerGUID" (updateParentPage)="onUpdateParentPage($event)"></app-ground-stoplist-client>
        </div>
        <div style="display: flex;" *ngIf="selectedTabName=='leg'">
          <div style="margin-right:0.5em">
            <app-ground-stop-leglist-client [selectedTabName]="selectedTabName" [requestInput]="request" [reloadChildPage]="reloadChildPage" [refreshChildColumn]="refreshChildColumn" [hidePastStop]="hidePastStop" [customerGUID]="customerGUID" (updateParentPage)="onUpdateParentPage($event)"></app-ground-stop-leglist-client>
          </div>
        </div>
      </div>
    </div>  
    
  </form>
</div>
