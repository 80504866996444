import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, ViewContainerRef, QueryList, ViewChildren, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { TripModel } from '../models/trip.model';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { AccountingStatusModel } from '../models/accounting-status.model';
import { TripRevisionModel } from '../models/trip-revision.model';
import { TripAuditDialogComponent } from './trip-audit-dialog.component';
import { ServiceTypeModel } from '../models/service-type.model';
import { IconLegendDialogComponent } from '../common-utility/icon-legend-dialog.component';
import { AircraftSelectionDialogComponent } from './aircraft-selection-dialog.component';
import { DeliveryCommsDialogComponent } from '../ground-stop-advanced/delivery-comms-dialog.component';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { TripClientInstructionEditDialogComponent } from '../ground-stops-client/trip-client-instruction-edit-dialog.component';
import { TripSetupDialogComponent } from './trip-setup-dialog.component';
import { TripNotesEditDialogComponent } from './trip-notes-edit-dialog.component';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TripLegModel } from '../models/trip-leg.model';
import { LeglistEditorDialogComponent } from '../trip-legs/leglist-editor-dialog.component';
import { TripPersonlistEditorDialogComponent } from '../trip-legs/trip-personlist-editor-dialog.component';
import { ClientHaltServicesViewDialogComponent } from '../ground-stop-advanced/client-halt-services-view-dialog.component';
import { AddBusinessRulesTasksDialogComponent } from '../ground-stop-advanced/add-business-rules-tasks-dialog.component';
import { TripServicelistEditorDialogComponent } from '../trip-legs/trip-servicelist-editor-dialog.component';
import { TripSummaryReportDialogComponent } from './trip-summary-report-dialog.component';
import { FlightPlanPreferenceEditComponent } from '../flight-plan-preference/flight-plan-preference-edit.component';
import { MenuService } from '../services/menu.service';
import { EmailInternalDialogComponent } from '../messages/email-internal-dialog.component';
import { TripMessageCenterComponent } from './trip-message-center.component';
import { TripDocumentSelectionDialogComponent } from '../messages/trip-document-selection-dialog.component';
import { TemplateService } from '../services/template.service';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { FuelQuoteSummaryComponent } from '../fuel-quote/fuel-quote-summary.component';
import { FuelQuoteSummaryHistoryComponent } from '../fuel-quote/fuel-quote-summary-history.component';
import { CustomerInformationDialogComponent } from '../customers/customer-information-dialog.component';
import { CustomerInstructionsComponent } from '../customers/customer-instructions.component';
import { FlightPlanLeglistComponent } from '../flight-plan-preference/flight-plan-leglist.component';
import { FlightPlanPreferenceDisplayComponent } from '../flight-plan-preference/flight-plan-preference-display.component';
import { CustomerProfileSetupEditComponent } from '../customers/customer-profile-setup-edit.component';
import { TripPlannerComponent } from '../trip-planner/trip-planner.component';
import { AircraftProfileComponent } from '../aircraft/aircraft-profile.component';
import { AircraftProfileEditComponent } from '../aircraft/aircraft-profile-edit.component';

@Component({
  selector: 'app-trip-details-internal',
  templateUrl: './trip-details-internal.component.html',
  styleUrls: ['./trip-details-internal.component.css']
})

export class TripDetailsInternalComponent implements OnInit {
  userType: string;
  customerGUID: string;
  aircraftGUID: string;
  //tripCodeGUID: string;
  selectedTabName: string;
  trip_details_internal_form: UntypedFormGroup;
  errGSMsg: string;
  errTSMsg: string;
  errMsg: string;
  msg: string;
  clientName: string;
  registration: string;
  acType: string;
  aircraftInfo: string;
  tripCodeList: TripModel[];
  selectedTripCode: string;
  selectedTripCodeGUID: string;
  gsTypeList: GroundStopTypeModel[];
  selectedGSTypeList: GroundStopTypeModel[];
  taskStatusList: TripTaskStatusModel[];
  selectedTaskStatusList: TripTaskStatusModel[];
  accountingStatusList: AccountingStatusModel[];
  selectedAccountingStatusList: AccountingStatusModel[];
  //trip_alert_filter: string;
  reloadChildPage: boolean;
  request: TripModel;
  showSpin: boolean = false;
  pageFrom: string;
  lockdownTrip: boolean = false;
  checkGSTypeArray: UntypedFormControl; // for mat-select
  checkTaskStatusArray: UntypedFormControl; // for mat-select
  checkAccountingStatusArray: UntypedFormControl;
  checkServiceTypeArray: UntypedFormControl; // for mat-select
  refreshChildColumn: string;

  v: number = this._authService.getCurrentTimeNumber();
  warningMsg: string = "Trip is locked. No edits allowed";
  homeBaseAirport: string;
  auditHr: number;
  auditHrText: string;
  usernameList: TripRevisionModel[];
  auditHourList: Array<number>;
  auditIndex: number;
  orgServiceTypeList: ServiceTypeModel[];
  serviceTypeList: ServiceTypeModel[];
  selectedServiceTypeList: ServiceTypeModel[];

  showMoreUsernameList: boolean = false;
  bias: number;
  tas: number;
  maxRange: string;
  wingspan: string;
  tripStarted: boolean;
  selectedGroundStopList: GroundStopModel[] = [];
  acTooltip: string;
  upPII: boolean = false;
  grantAccessCompanyProfiles: boolean = false;
  grantAccessAircraftProfiles: boolean = false;
  clientInstructions: string;
  tripCodeType: string;
  showTripCodeList: boolean = false;
  showGroundStopTypeList: boolean = false;
  showServiceTypeList: boolean = false;
  showTaskStatusList: boolean = false;
  showAccountingStatusList: boolean;
  selectedGSTypeCountText: string = "";
  selectedServiceTypeCountText: string = "";
  selectedTaskStatusCountText: string = "";
  selectedAcctStatusCountText: string = "";
  @ViewChildren('tripCodeRefs', { read: ViewContainerRef }) tripCodeRefs: QueryList<ViewContainerRef>;
  displayedColumnTripCode: string[] = ['select', 'tripcode', 'route', 'startDate', 'endDate'];
  showWholeRoute: boolean = false;
  flightPlanProviderName: string = "";
  uplinkVendor: string = "";
  opsNotes: string;
  nonStandardClientInstructions: string;
  wxNotes: string;
  customerTripID: string = "";
  hidePastStop: boolean;
  includeDisabledTask: boolean;
  includeActiveStopOnly: boolean;
  gsId: string = "";
  gstId: string = "";
  internalUsernameList: TripRevisionModel[] = [];
  externalUsernameList: TripRevisionModel[] = [];
  showMoreInternalUsernameList: boolean = false;
  showMoreExternalUsernameList: boolean = false;
  childTableWidth: number = 90.5;
  hasHaltServices: boolean = false;
  websiteEnvironment: string = "";
  activeGroundStopList: GroundStopModel[] = [];
  allGroundStopList: GroundStopModel[] = [];
  hasCancelledStop: boolean = false;
  selectedOption: string = "note";
  isGetOut: boolean = true;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService,
    private readonly _groundStopClientService: GroundStopClientService, private _titleService: Title, private readonly _menuService: MenuService, private sanitized: DomSanitizer,
    private readonly _templateService: TemplateService) {

  }

  getAllData(): Observable<any[]> {
    let request = new AircraftTripModel();
    request.aircraftGUID = this.aircraftGUID;
    request.customerGUID = this.customerGUID;
    request.startDateText = localStorage.getItem('trip.sd');
    request.endDateText = localStorage.getItem('trip.ed');
    request.tripCodeGUID = this.selectedTripCodeGUID;
    let getACCustomerResponse = this._groundStopService.getAircraftCustomerByByacIdcId(request);
    let getTripResponse;
    if (this.selectedTripCodeGUID != "" && this.selectedTripCodeGUID != null && this.selectedTripCodeGUID != undefined)
      getTripResponse = this._groundStopClientService.getTripBytId(request);
    else
      getTripResponse = this._groundStopService.getTripCodeListForInternalByacIdcId(request);

    //let getGSTypeResponse = this._groundStopService.getGroundStopTypeList();
    let getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    let getService = this._groundStopAdvancedService.getServiceClass_ServiceTypeList(1);
    let getAcctStatusResponse = this._groundStopAdvancedService.getAccountingStatusList();
    return forkJoin([getACCustomerResponse, getTripResponse, getTaskStatusResponse, getService, getAcctStatusResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");

      return;
    }
    else {
      this.upPII = true;
    }
    this.grantAccessAircraftProfiles = false;
    this.grantAccessCompanyProfiles = false;
    if (localStorage.getItem('up.acPro') == 'true') {
      this.grantAccessAircraftProfiles = true;
    }
    if (localStorage.getItem('up.cPro') == 'true') {
      this.grantAccessCompanyProfiles = true;
    }
    this.customerGUID = this._route.snapshot.paramMap.get('customerId');
    this.aircraftGUID = this._route.snapshot.paramMap.get('aircraftId');
    this.selectedTripCodeGUID = this._route.snapshot.paramMap.get('tripId');
    //this.tripCodeType = this._route.snapshot.paramMap.get('tType');
    //console.log("route: "+this._route.snapshot.paramMap.get('pf'));
    if (this.aircraftGUID == null || this.aircraftGUID == undefined || this.aircraftGUID == "") {
      this._route.queryParams.subscribe(params => {
        this.aircraftGUID = params["aircraftId"];
        this.customerGUID = params["customerId"];
        this.selectedTripCodeGUID = params["tripId"];
        this.pageFrom = params["pf"];
        this.tripCodeType = params["tType"];
        this.gsId = params["gsId"];
        this.gstId = params["gstId"];
        //console.log("query param: "+params["pf"]);

      });
    }


    if (this.tripCodeType == undefined || this.tripCodeType == null) {
      this.tripCodeType = "";
    }

    this.errGSMsg = "";
    this.errTSMsg = "";
    this.errMsg = "";
    this.msg = "";
    this.clientName = "";
    this.registration = "";
    this.acType = "";
    this.aircraftInfo = "";
    this.tripCodeList = [];
    this.gsTypeList = [];
    this.selectedGSTypeList = [];
    this.taskStatusList = [];
    this.selectedTaskStatusList = [];
    this.accountingStatusList = [];
    this.selectedAccountingStatusList = [];
    this.orgServiceTypeList = [];
    this.serviceTypeList = [];
    this.selectedServiceTypeList = [];
    this.selectedGroundStopList = [];

    this.reloadChildPage = false;
    this.selectedTabName = "groundStop";
    this.homeBaseAirport = "";
    this.lockdownTrip = false;
    this.request = new TripModel();

    let gst: string = "";
    this.usernameList = [];
    this.auditHourList = [1, 8, 12, 24, 36, 48, 60, 72, 0];
    this.auditIndex = 0;
    this.auditHr = this.auditHourList[this.auditIndex];
    this.auditHrText = this.auditHr.toString() + " hr";
    this.refreshChildColumn = "";
    this.showMoreUsernameList = false;
    this.bias = 0;
    this.tas = 0;
    this.maxRange = '';
    this.wingspan = ''
    this.tripStarted = false;
    this.acTooltip = "";
    this.clientInstructions = "";
    this.selectedTripCode = "";
    this.opsNotes = "";
    this.wxNotes = "";
    this.hidePastStop = true;
    this.includeDisabledTask = false;
    this.includeActiveStopOnly = false;
    if (localStorage.getItem('trip.gst') != null) {
      gst = localStorage.getItem('trip.gst');
    }

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let ac_customer = new AircraftTripModel();
          ac_customer = responses[0].model;
          this.clientName = ac_customer.customerName;
          this.registration = ac_customer.registration;
          this.acType = ac_customer.aircraftModel; //ac_customer.aircraftManufacturer + " " + ac_customer.aircraftTypeDesignator;// 
          this.aircraftInfo = ac_customer.aircraftInfo;
          this.homeBaseAirport = ac_customer.homeBaseAirport;
          this.bias = ac_customer.bias;
          this.tas = ac_customer.tas;
          this.wingspan = ac_customer.wingspan;
          this.maxRange = ac_customer.maxRange;
          if (this.bias > 0) {
            if (this.acTooltip != "") {
              this.acTooltip += " ";
            }
            this.acTooltip += "Bias: " + this.bias.toString();
          }
          if (this.tas > 0) {
            if (this.acTooltip != "") {
              this.acTooltip += " ";
            }
            this.acTooltip += "TAS: " + this.tas.toString();
          }
          if (this.wingspan != "") {
            if (this.acTooltip != "") {
              this.acTooltip += " ";
            }
            this.acTooltip += "Wingspan: " + this.wingspan;
          }
          if (this.maxRange != "") {
            if (this.acTooltip != "") {
              this.acTooltip += " ";
            }
            this.acTooltip += "Max Range: " + this.maxRange + " nm";
          }
          if (ac_customer.aircraftLength != "") {
            if (this.acTooltip != "") {
              this.acTooltip += " ";
            }
            this.acTooltip += "Length: " + ac_customer.aircraftLength;
          }

          this.flightPlanProviderName = ac_customer.flightPlanProviderName;
          this.uplinkVendor = ac_customer.uplinkVendor;
          this.hasHaltServices = ac_customer.hasHaltServices;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }


      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (this.selectedTripCodeGUID == null || this.selectedTripCodeGUID == "" || this.selectedTripCodeGUID == undefined) {
            let tl: TripModel[];
            tl = responses[1].model;
            if (tl.length > 0) {
              this.selectedTripCodeGUID = tl[0].tripCodeGUID;
              this.allGroundStopList = tl[0].groundStopList;
              this.lockdownTrip = tl[0].lockdownTrip;
              this.tripStarted = tl[0].tripStarted;
              this.clientInstructions = tl[0].clientInstructions;
              this.selectedTripCode = tl[0].tripCode;
              this.tripCodeType = tl[0].tripCodeType;
              this.opsNotes = tl[0].opsNotes;
              this.nonStandardClientInstructions = tl[0].internalNonStandardProcedures;
              this.wxNotes = tl[0].wxNotes;
              this.customerTripID = tl[0].customerTripID;
              this.activeGroundStopList = tl[0].activeGroundStopList;
              this.hasCancelledStop = tl[0].hasCancelledStop;
              //if (this.opsNotes != null && this.opsNotes != "") {
              //  this.selectedOption = "note";
              //}
              //else {
              //  if (this.clientInstructions != null && this.clientInstructions != "")
              //    this.selectedOption = "client"
              //  else
              //    this.selectedOption = "filter";
              //}
            }
          }
          else {
            let t = new TripModel();
            t = responses[1].model;
            this.selectedTripCodeGUID = t.tripCodeGUID;
            this.allGroundStopList = t.groundStopList;
            this.lockdownTrip = t.lockdownTrip;
            this.tripStarted = t.tripStarted;
            this.clientInstructions = t.clientInstructions;
            this.selectedTripCode = t.tripCode;
            this.tripCodeType = t.tripCodeType;
            this.opsNotes = t.opsNotes;
            this.nonStandardClientInstructions = t.internalNonStandardProcedures;
            this.wxNotes = t.wxNotes;
            this.customerTripID = t.customerTripID;
            this.activeGroundStopList = t.activeGroundStopList;
            this.hasCancelledStop = t.hasCancelledStop;
            //if (this.opsNotes != null && this.opsNotes != "") {
            //  this.selectedOption = "note";
            //}
            //else {
            //  if (this.clientInstructions != null && this.clientInstructions != "")
            //    this.selectedOption = "client"
            //  else
            //    this.selectedOption = "filter";
            //}
          }
          if (this.includeActiveStopOnly) {
            this.selectedGroundStopList = this.activeGroundStopList;
          }
          else {
            this.selectedGroundStopList = this.allGroundStopList;
          }
          if (this.lockdownTrip)
            this.hidePastStop = false;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      //if (responses[2] != null) {
      //  if (responses[2].code == "200" && responses[2].message == "") {
      //    let g = new GroundStopTypeModel();
      //    g.groundStopTypeGUID = "all";
      //    g.groundStopTypeDescription = "Select All";
      //    g.selected = false;
      //    this.gsTypeList.push(g);
      //    responses[2].model.forEach(x => {
      //      g = new GroundStopTypeModel();
      //      g.groundStopTypeGUID = x.groundStopTypeGUID;
      //      g.groundStopTypeDescription = x.groundStopTypeDescription;
      //      g.selected = false; 
      //if (g.groundStopTypeGUID.toLowerCase() == "868a62b1-9cd6-499b-abfa-46542ccb7424") {
      //  g.selected = true; 
      //}

      //      this.gsTypeList.push(g);
      //    });

      //    this.setGSTypeList();
      //  }
      //  else {
      //    if (responses[2].code == "401") {
      //      this._authService.signOut();
      //    }
      //  }
      //}

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          let t = new TripTaskStatusModel();
          t.taskStatusGUID = "all";
          t.taskStatusDescription = "Select All";
          t.selected = false;
          this.taskStatusList.push(t);
          responses[2].model.forEach(x => {
            t = new TripTaskStatusModel();
            t.taskStatusGUID = x.taskStatusGUID;
            t.taskStatusDescription = x.taskStatusDescription;
            t.selected = false;

            this.taskStatusList.push(t);
          });
          this.setTaskStatusList();
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {

          let s = new ServiceTypeModel();
          s.serviceTypeID = 0;
          s.serviceTypeDescription = "Select All";
          s.selected = false;
          this.serviceTypeList.push(s);
          responses[3].model.forEach(x => {
            s = new ServiceTypeModel();
            s.serviceTypeID = x.serviceTypeID;
            s.serviceTypeDescription = x.serviceTypeDescription;
            s.selected = false;
            this.serviceTypeList.push(s);

          });
          this.setServiceTypeList();
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {

          let a = new AccountingStatusModel();
          a.accountingStatusGUID = "all";
          a.accountingStatusDescription = "Select All";
          a.selected = false;
          this.accountingStatusList.push(a);
          responses[4].model.forEach(x => {
            a = new AccountingStatusModel();
            a.accountingStatusGUID = x.accountingStatusGUID;
            a.accountingStatusDescription = x.accountingStatusDescription;
            if (x.isGTSFlag == true) {
              a.accountingStatusDescription = "GTS - " + x.accountingStatusDescription;
            }
            a.selected = false;

            this.accountingStatusList.push(a);
          });
          this.setAcctStatusList();
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      //if (responses[5] != null) {
      //  if (responses[5].code == "200" && responses[5].message == "") {
      //    this.websiteEnvironment = responses[5].model;

      //  }
      //  else {
      //    if (responses[5].code == "401") {
      //      this._authService.signOut();
      //    }
      //  }
      //}
      this.websiteEnvironment = localStorage.getItem("websiteEnvironment");
      if (this.websiteEnvironment == "" || this.websiteEnvironment == null || this.websiteEnvironment == undefined)
        this._titleService.setTitle(this.registration + " - " + this.selectedTripCode);
      else {
        this._titleService.setTitle(this.websiteEnvironment + "-" + this.registration + " - " + this.selectedTripCode);
      }

      this.initControls();

      this.showSpin = false;

      this.openChildPage('');

    });


  }

  initControls() {
    this.trip_details_internal_form = this._formBuilder.group({
      //trip_code_select: [this.selectedTripCodeGUID],
      includeDisabledTask: [this.includeDisabledTask],
      includeActiveStopOnly: [this.includeActiveStopOnly],
      hidePastStop: [this.hidePastStop]

    });

    //this.checkGSTypeArray = new FormControl(this.selectedGSTypeList); // for mat-select
    //this.checkTaskStatusArray = new FormControl(this.selectedTaskStatusList); // for mat-select
    //this.checkAccountingStatusArray = new FormControl(this.selectedAccountingStatusList); // for mat-select
    //this.checkServiceTypeArray = new FormControl(this.selectedServiceTypeList); // for mat-select
  }

  get f() { return this.trip_details_internal_form.controls; }

  // filter box
  // trip code list
  clickTripCode() {
    this.getTripCodeList();
  }
  getHTML(data: any) {
    if(data === undefined || data === '' || data === null) {
      return this.sanitized.bypassSecurityTrustHtml(this.opsNotes);
    }

    return this.sanitized.bypassSecurityTrustHtml(data + this.opsNotes);
  }
  getTripCodeList() {
    var root = this;
    this.showTripCodeList = true;
    this.showGroundStopTypeList = false;
    this.showServiceTypeList = false;
    this.showTaskStatusList = false;
    this.showAccountingStatusList = false;
    let request = new AircraftTripModel();
    request.aircraftGUID = this.aircraftGUID;
    request.tripCodeType = this.tripCodeType;
    request.startDateText = localStorage.getItem('trip.sd');
    request.endDateText = localStorage.getItem('trip.ed');
    request.customerGUID = this.customerGUID;
    this._groundStopService.getTripCodeListForInternalByacIdcId<ResponseModel<TripModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.tripCodeList = [];
          this.tripCodeList = response.model;
          if (this.tripCodeList.length > 0) {
            this.lockdownTrip = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].lockdownTrip;
            this.tripStarted = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].tripStarted;
            this.allGroundStopList = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].groundStopList;
            this.clientInstructions = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].clientInstructions;
            this.selectedTripCode = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].tripCode;
            this.tripCodeType = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].tripCodeType;
            this.opsNotes = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].opsNotes;
            this.wxNotes = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].wxNotes;
            this.customerTripID = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].customerTripID;
            this.activeGroundStopList = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].activeGroundStopList;
            this.hasCancelledStop = this.tripCodeList.filter(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase())[0].hasCancelledStop;
            if (this.includeActiveStopOnly) {
              this.selectedGroundStopList = this.activeGroundStopList;
            }
            else {
              this.selectedGroundStopList = this.allGroundStopList;
            }
            let i = this.tripCodeList.findIndex(x => x.tripCodeGUID.toLowerCase() == this.selectedTripCodeGUID.toLowerCase());
            this.tripCodeList[i].selected = true;

            setTimeout(function () {
              var selectedItem = root.tripCodeRefs.find(r => r.element.nativeElement.getAttribute('tripCode') === root.selectedTripCode);
              selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
            }, 500);
          }

        }
        //else {
        //  if (response.code == "401") {
        //    this._authService.signOut();
        //  }
        //}
      }

    })
  }

  tripCodeOnChange(e: any, item: TripModel) {
    this.tripCodeList.forEach(x => {
      x.selected = false;
    });
    item.selected = true;
  }

  clickDoneTripCode() {
    let i: number = this.tripCodeList.findIndex(x => x.selected == true);
    let item = new TripModel();
    item = this.tripCodeList[i];
    this.selectedTripCodeGUID = item.tripCodeGUID;
    this.selectedTripCode = item.tripCode;
    this.lockdownTrip = item.lockdownTrip;
    this.tripStarted = item.tripStarted;
    this.allGroundStopList = item.groundStopList;
    this.clientInstructions = item.clientInstructions;
    this.tripCodeType = item.tripCodeType;
    this.opsNotes = item.opsNotes;
    this.wxNotes = item.wxNotes;
    this.customerTripID = item.customerTripID;
    this.activeGroundStopList = item.activeGroundStopList;
    this.hasCancelledStop = item.hasCancelledStop;
    //this.includeActiveStopOnly = false;
    //this.f.includeActiveStopOnly.setValue(this.includeActiveStopOnly);
    if (this.includeActiveStopOnly) {
      this.selectedGroundStopList = this.activeGroundStopList;
    }
    else {
      this.selectedGroundStopList = this.allGroundStopList;
    }
    this.showTripCodeList = false;
    this.clickSubmit();
  }

  clickCloseTripCode() {
    this.showTripCodeList = false;
  }
  // trip code list end
  // task status
  clickTaskStatus() {
    this.showTripCodeList = false;
    this.showGroundStopTypeList = false;
    this.showServiceTypeList = false;
    this.showTaskStatusList = true;
    this.showAccountingStatusList = false;
  }

  onTaskStatusChange(e: any, item: TripTaskStatusModel) {
    if (item.taskStatusGUID == 'all') {
      this.taskStatusList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.taskStatusList.forEach(x => {
        if (item.taskStatusGUID.toLowerCase() == x.taskStatusGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setTaskStatusList();

    this.reloadChildPage = false;

  }

  setTaskStatusList() {
    let excludealllist: TripTaskStatusModel[]
    excludealllist = this.taskStatusList.filter(x => x.taskStatusGUID != 'all' && x.selected == false);
    if (excludealllist.length == 0) {
      this.taskStatusList.find(x => x.taskStatusGUID == 'all').selected = true;
    }
    else {
      this.taskStatusList.find(x => x.taskStatusGUID == 'all').selected = false;
    }
    this.selectedTaskStatusList = this.taskStatusList.filter(u => u.selected == true);
    this.selectedTaskStatusCountText = "";
    if (this.taskStatusList.length > 0) {
      if (this.selectedTaskStatusList.length == this.taskStatusList.length) {
        this.selectedTaskStatusCountText = ": All";
      }
      else {
        if (this.selectedTaskStatusList.length > 0)
          this.selectedTaskStatusCountText = ": " + this.selectedTaskStatusList.length.toString();
      }
    }

  }

  clickDoneTaskStatus() {
    this.showTaskStatusList = false;
    this.clickSubmit();
  }

  clickResetTaskStatus() {
    this.taskStatusList.forEach(x => {
      x.selected = false;
    });
    this.setTaskStatusList();
  }
  // task status end
  // gs type
  /*
  clickGSType() {
    this.showTripCodeList = false;
    this.showGroundStopTypeList = true;
    this.showServiceTypeList = false;
    this.showTaskStatusList = false;
    this.showAccountingStatusList = false;
  }

  onGSTypeChange(e: any, item: GroundStopTypeModel) {
    if (item.groundStopTypeGUID == 'all') {
      this.gsTypeList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.gsTypeList.forEach(x => {
        if (item.groundStopTypeGUID.toLowerCase() == x.groundStopTypeGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setGSTypeList();
   
    this.reloadChildPage = false;

  }

  setGSTypeList() {
    let excludealllist: GroundStopTypeModel[]
    excludealllist = this.gsTypeList.filter(x => x.groundStopTypeGUID != 'all' && x.selected == false);
    if (excludealllist.length == 0) {
      this.gsTypeList.find(x => x.groundStopTypeGUID == 'all').selected = true;
    }
    else {
      this.gsTypeList.find(x => x.groundStopTypeGUID == 'all').selected = false;
    }
    this.selectedGSTypeList = this.gsTypeList.filter(u => u.selected == true);
    this.selectedGSTypeCountText = "";
    if (this.gsTypeList.length > 0) {
      if (this.selectedGSTypeList.length == this.gsTypeList.length) {
        this.selectedGSTypeCountText = ": All";
      }
      else {
        if (this.selectedGSTypeList.length > 0)
          this.selectedGSTypeCountText = ": " + this.selectedGSTypeList.length.toString();
      }
    }

  }

  clickDoneGSType() {
    this.showGroundStopTypeList = false;
    this.clickSubmit();
  }

  clickResetGSType() {
   
    this.gsTypeList.forEach(x => {
      x.selected = false;
    });   
    this.setGSTypeList();
  }
  */
  // gs type end
  // serviceType
  clickServiceType() {
    this.showTripCodeList = false;
    this.showGroundStopTypeList = false;
    this.showServiceTypeList = true;
    this.showTaskStatusList = false;
    this.showAccountingStatusList = false;
  }

  onServiceTypeChange(e: any, item: ServiceTypeModel) {
    if (item.serviceTypeID == 0) {
      this.serviceTypeList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.serviceTypeList.forEach(x => {
        if (item.serviceTypeID == x.serviceTypeID) {
          x.selected = e.checked;
        }
      });
    }
    this.setServiceTypeList();

    this.reloadChildPage = false;

  }

  setServiceTypeList() {
    let excludealllist: ServiceTypeModel[]
    excludealllist = this.serviceTypeList.filter(x => x.serviceTypeID != 0 && x.selected == false);
    if (excludealllist.length == 0) {
      this.serviceTypeList.find(x => x.serviceTypeID == 0).selected = true;
    }
    else {
      this.serviceTypeList.find(x => x.serviceTypeID == 0).selected = false;
    }
    this.selectedServiceTypeList = this.serviceTypeList.filter(u => u.selected == true);
    this.selectedServiceTypeCountText = "";
    if (this.serviceTypeList.length > 0) {
      if (this.selectedServiceTypeList.length == this.serviceTypeList.length) {
        this.selectedServiceTypeCountText = ": All";
      }
      else {
        if (this.selectedServiceTypeList.length > 0)
          this.selectedServiceTypeCountText = ": " + this.selectedServiceTypeList.length.toString();
      }
    }

  }

  clickDoneServiceType() {
    this.showServiceTypeList = false;
    this.clickSubmit();
  }

  clickResetServiceType() {
    this.serviceTypeList.forEach(x => {
      x.selected = false;
    });
    this.setServiceTypeList();
  }
  // service type end
  // accounting status
  clickAcctStatus() {
    this.showTripCodeList = false;
    this.showGroundStopTypeList = false;
    this.showServiceTypeList = false;
    this.showTaskStatusList = false;
    this.showAccountingStatusList = true;
  }

  onAcctStatusChange(e: any, item: AccountingStatusModel) {
    if (item.accountingStatusGUID == 'all') {
      this.accountingStatusList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.accountingStatusList.forEach(x => {
        if (item.accountingStatusGUID.toLowerCase() == x.accountingStatusGUID.toLowerCase()) {
          x.selected = e.checked;
        }
      });
    }
    this.setAcctStatusList();

    this.reloadChildPage = false;

  }

  setAcctStatusList() {
    let excludealllist: AccountingStatusModel[]
    excludealllist = this.accountingStatusList.filter(x => x.accountingStatusGUID != 'all' && x.selected == false);
    if (excludealllist.length == 0) {
      this.accountingStatusList.find(x => x.accountingStatusGUID == 'all').selected = true;
    }
    else {
      this.accountingStatusList.find(x => x.accountingStatusGUID == 'all').selected = false;
    }
    this.selectedAccountingStatusList = this.accountingStatusList.filter(u => u.selected == true);
    this.selectedAcctStatusCountText = "";
    if (this.accountingStatusList.length > 0) {
      if (this.selectedAccountingStatusList.length == this.accountingStatusList.length) {
        this.selectedAcctStatusCountText = ": All";
      }
      else {
        if (this.selectedAccountingStatusList.length > 0)
          this.selectedAcctStatusCountText = ": " + this.selectedAccountingStatusList.length.toString();
      }
    }

  }

  clickDoneAcctStatus() {
    this.showAccountingStatusList = false;
    this.clickSubmit();
  }

  clickResetAcctStatus() {

    this.accountingStatusList.forEach(x => {
      x.selected = false;
    });
    this.setAcctStatusList();
  }
  // accounting status end
  // filter box end

  auditSlideChange(e: any) {
    //let x = e.target.value;
    this.msg = "";
    this.auditIndex = e.target.value;
    this.auditHr = this.auditHourList[this.auditIndex];
    switch (this.auditHr) {
      case 1:
        this.auditHrText = this.auditHr.toString() + " hr";
        break;
      case 0:
        this.auditHrText = " All Time";
        break;
      default:
        this.auditHrText = this.auditHr.toString() + " hrs";
    }

    //this.getUsernameList();
    this.getUsernameList().subscribe(res1 => {
    });

    this.refreshChildColumn = "callLog";
    this.openChildPage(this.refreshChildColumn);

  }

  clickAudit(username: string) {
    this.getUsernameList().subscribe(res1 => {
      if (res1 != null || res1 != undefined) {
        if (res1 == false) {
          switch (this.auditHr) {
            case 1:
              this.msg = "There is no trip audit record within past " + this.auditHr.toString() + " hour";
              break;
            case 0:
              this.msg = "There is no trip audit record within past time.";
              break;
            default:
              this.msg = "There is no trip audit record within past " + this.auditHr.toString() + " hours";
          }
          return;
        }
        else {

          this.openTripAuditDialog(username);
        }

      }

    });


  }

  openTripAuditDialog(username) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID, tripCodeGUID: this.selectedTripCodeGUID, pastHours: this.auditHr,
      userName: username, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }

  clickUpdate() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID, tripCodeGUID: this.selectedTripCodeGUID, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(AircraftSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        this.aircraftGUID = result.aircraftGUID;
        this.getACCustomer();
        this.getTripCodeList();
        this.openChildPage('');
      }

    });

  }

  clickContact() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.selectedTripCodeGUID, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(DeliveryCommsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  clickClientInstruction() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.selectedTripCodeGUID, customerGUID: this.customerGUID, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripClientInstructionEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTripBytId();
        this.clickRefreshAudit();
      }

    });

  }

  getTripBytId() {
    this._authService.updateAccessTime();
    let request = new AircraftTripModel();
    request.tripCodeGUID = this.selectedTripCodeGUID;
    request.customerGUID = this.customerGUID;
    this._groundStopClientService.getTripBytId<ResponseModel<TripModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let t = new TripModel();
          t = response.model;
          this.selectedTripCodeGUID = t.tripCodeGUID;
          this.allGroundStopList = t.groundStopList;
          this.lockdownTrip = t.lockdownTrip;
          this.tripStarted = t.tripStarted;
          this.clientInstructions = t.clientInstructions;
          this.opsNotes = t.opsNotes;
          this.wxNotes = t.wxNotes;
          this.selectedTripCode = t.tripCode;
          this.activeGroundStopList = t.activeGroundStopList;
          this.hasCancelledStop = t.hasCancelledStop;
          if (this.includeActiveStopOnly) {
            this.selectedGroundStopList = this.activeGroundStopList;
          }
          else {
            this.selectedGroundStopList = this.allGroundStopList;
          }
        }
        else {

        }
        this.showSpin = false;

      }
      else {
        if (response.code == "401") {
          if (response.message == "")
            this._authService.signOut();
          else
            this.errMsg = response.message;
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  clickTripCloseout() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.selectedTripCodeGUID, customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID, v: this._authService.getCurrentTimeNumber(),
      route: this.selectedGroundStopList.map(s => s.icao).join('-'), customerName: this.clientName
    };

    const dialogRef = this._dialog.open(TripSetupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTripBytId();
        this.clickRefreshAudit();
      }

    });
  }

  getACCustomer() {
    let request = new AircraftTripModel();
    request.aircraftGUID = this.aircraftGUID;
    request.customerGUID = this.customerGUID;
    this._groundStopService.getAircraftCustomerByByacIdcId<ResponseModel<AircraftTripModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let ac_customer = new AircraftTripModel();
          ac_customer = response.model;
          this.clientName = ac_customer.customerName;
          this.registration = ac_customer.registration;
          this.acType = ac_customer.aircraftManufacturer + " " + ac_customer.aircraftModel;
          this.aircraftInfo = ac_customer.aircraftInfo;
          this.homeBaseAirport = ac_customer.homeBaseAirport;
          this.bias = ac_customer.bias;
          this.tas = ac_customer.tas;
          this.wingspan = ac_customer.wingspan;
          this.maxRange = ac_customer.maxRange;

        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }

    })
  }

  clickRefreshAudit() {
    this.msg = "";
    //this.getUsernameList();
    this.getUsernameList().subscribe(res1 => {
      setTimeout(() => {
        this.selectedOption = "note";
      }, 1000);
    });
  }


  getUsernameList(): Observable<boolean> {
    this.msg = "";
    this._authService.updateAccessTime();

    return new Observable<boolean>(ob => {
      if (this.userType != 'internal') {
        ob.next(false);
      }
      else {
        let obj = new TripRevisionModel();
        obj.aircraftGUID = this.aircraftGUID;
        obj.customerGUID = this.customerGUID;
        obj.tripCodeGUID = this.selectedTripCodeGUID;
        obj.pastHours = Math.round(this.auditHr);//parseInt(this.auditHr);
        this._groundStopService.getTripRevisionUsernameListBytIdHours<ResponseModel<TripRevisionModel[]>>(obj).subscribe(response => {
          if (response != null && response.code == "200") {
            this.usernameList = [];
            this.internalUsernameList = [];
            this.externalUsernameList = [];
            if (response.model.length > 0) {
              this.usernameList = response.model;
              this.internalUsernameList = this.usernameList.filter(x => x.isExternal == false);
              this.externalUsernameList = this.usernameList.filter(x => x.isExternal == true);
              if (this.internalUsernameList.length < 10) {
                this.showMoreInternalUsernameList = false;
              }
              if (this.externalUsernameList.length < 10) {
                this.showMoreExternalUsernameList = false;
              }
              //if (this.usernameList.length < 10) {
              //  this.showMoreUsernameList = false;
              //}
              ob.next(true);
            }
            else {
              ob.next(false);
            }
          }
          else {
            if (response.code == "401") {
              //this.errMsg = response.message
              this._authService.signOut();
            }
            ob.next(false);
          }

        })
      }

    });

  }

  clickshowMoreInternalUsernameList() {
    this.showMoreInternalUsernameList = !this.showMoreInternalUsernameList;
    if (this.showMoreInternalUsernameList) {
      this.showMoreExternalUsernameList = false;
    }
  }

  clickshowMoreExternalUsernameList() {
    this.showMoreExternalUsernameList = !this.showMoreExternalUsernameList;
    if (this.showMoreExternalUsernameList) {
      this.showMoreInternalUsernameList = false;
    }
  }

  includeDisabledTaskChange(e: any) {
    //let x = e.checked;
    this.clickSubmit();
  }

  includeActiveStopOnlyChange(e: any) {
    this.includeActiveStopOnly = e.checked;
    this.selectedGroundStopList = [];
    if (e.checked) {
      this.selectedGroundStopList = this.activeGroundStopList;
    }
    else {
      this.selectedGroundStopList = this.allGroundStopList;
    }
    this.clickSubmit();
  }

  hidePastStopChange(e: any) {
    this.hidePastStop = e.checked;
    this.request.hidePastStop = this.hidePastStop;
    this.reloadChildPage = true;
  }

  clickSubmit() {
    this.openChildPage('');
  }

  openChildPage(refreshColumn: string) {
    this.errGSMsg = "";
    this.errTSMsg = "";
    this.msg = "";
    //this.showSpin = true;
    this._authService.updateAccessTime();
    this.request = new TripModel();
    this.request.customerGUID = "";
    if (this.userType == "internal") {
      this.request.customerGUID = this.customerGUID;
    }
    this.request.aircraftGUID = this.aircraftGUID;
    this.request.tripCodeGUID = "";// this.f.trip_code_select.value;
    if (this.selectedTripCodeGUID != null && this.selectedTripCodeGUID != undefined && this.selectedTripCodeGUID != "") {
      this.request.tripCodeGUID = this.selectedTripCodeGUID;
    }
    //if (this.selectedGSTypeList.length == this.gsTypeList.length) {
    //  this.request.groundStopTypeGUIDList = "";
    //}
    //else {
    //  this.request.groundStopTypeGUIDList = Array.prototype.map.call(this.selectedGSTypeList, s => s.groundStopTypeGUID).toString();
    //}
    if (this.selectedTaskStatusList.length == this.taskStatusList.length) {
      this.request.tripTaskStatusGUIDList = "";
    }
    else {
      this.request.tripTaskStatusGUIDList = Array.prototype.map.call(this.selectedTaskStatusList, s => s.taskStatusGUID).toString();
    }
    if (this.selectedServiceTypeList.length == this.serviceTypeList.length) {
      this.request.serviceTypeIDList = "";
    }
    else {
      this.request.serviceTypeIDList = Array.prototype.map.call(this.selectedServiceTypeList, s => s.serviceTypeID).toString();
    }
    if (this.selectedAccountingStatusList.length == this.accountingStatusList.length) {
      this.request.accountingStatusGUIDList = "";
    }
    else {
      this.request.accountingStatusGUIDList = Array.prototype.map.call(this.selectedAccountingStatusList, s => s.accountingStatusGUID).toString();
    }

    this.request.pastHours = this.auditHr;
    this.request.includeDisabledTask = this.f.includeDisabledTask.value;
    this.request.includeActiveStopOnly = this.f.includeActiveStopOnly.value;
    this.request.tripCodeType = this.tripCodeType;
    this.request.tripCode = this.selectedTripCode;
    this.request.registration = this.registration;
    this.refreshChildColumn = refreshColumn;

    if (refreshColumn == "") {
      this.reloadChildPage = true;
    }
    else {
      this.reloadChildPage = false;
    }
    this.request.groundStopGUID = this.gsId;
    this.request.groundStopTaskGUID = this.gstId;
    this.request.hidePastStop = this.hidePastStop;
    //this.getUsernameList().subscribe(res1 => {

    //});
    setTimeout(() => {
      this.selectedOption = "note";
    }, 1000);

  }

  clickOpenTripSearch() {
    this._dialog.closeAll();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        pf: "tripdetail",
        v: this._authService.getCurrentTimeNumber()
      }
    };
    this._router.navigate(['/ground-stops/trip-quick-search-control-internal'], navigationExtras);
  }

  onUpdateParentPage(e: any) {

    switch (e.updateSection) {
      case "leg":
      case "allLegTask":
        //this.lockdownTrip = e.lockdownTrip;
        //this.tripCodeList.find(x => x.tripCodeGUID == this.selectedTripCodeGUID.toLowerCase()).lockdownTrip = this.lockdownTrip;
        this.getUsernameList().subscribe(res1 => {

        });
        if (e.updateSection == 'leg')
          this.getTripBytId();
        break;
      case "groundStop":
        this.getUsernameList().subscribe(res1 => {

        });
        this.getTripBytId();
        break;
    }

  }


  clickTab(s: string) {
    this.errMsg = "";
    if (this.selectedTabName != s) {
      //if (s == 'tripSetup') {
      //  if (this.f.trip_code_select.value == "") {
      //    this.errMsg = "Please select a Trip Code";
      //    return;
      //  }
      //}

      this.selectedTabName = s;
      if (this.selectedTabName != 'leg') {
        this.childTableWidth = 90.5;
      }
      else {
        this.childTableWidth = 63.5;
      }
      this.reloadChildPage = true;
      this.openChildPage('');
    }
  }

  clickClient() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    config.height = "50em";
    config.width = "90em";
    config.maxWidth = "90em";
    config.maxHeight = "50em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGUID, customerName: this.clientName, userType: 'customer' };
    config.position = { left: "10px", top: "140px" };

    this._dialog.open(CustomerProfileSetupEditComponent, config);
  }

  clickAircraft() {
    let s = "";
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    config.height = "57em";
    config.width = "87em";
    config.maxWidth = "87em";
    config.restoreFocus = false;
    config.hasBackdrop = false;

    config.data = {
      dialogTitle: s, customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID, tripCodeGUID: this.selectedTripCodeGUID, v: this._authService.getCurrentTimeNumber()
    };

    this._dialog.open(AircraftProfileEditComponent, config);
  }

  clickLegend() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    let legendType: string = "";
    dialogConfig.data = {
      dialogTitle: s, legendType: legendType
    };

    const dialogRef = this._dialog.open(IconLegendDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  clickTripNotes(pageFrom: string) {
    this._authService.updateAccessTime();
    let s = "";
    if (pageFrom == 'alerts')
      s = "ALERTS";
    else
      s = "CLIENT INSTRUCTIONS";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.selectedTripCodeGUID, customerGUID: this.customerGUID, v: this._authService.getCurrentTimeNumber(), pf: pageFrom
    };

    const dialogRef = this._dialog.open(TripNotesEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTripBytId();
        this.clickRefreshAudit();
      }

    });

  }

  clickAddEditLegs() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = { dialogTitle: s, customerGUID: this.customerGUID, tripCodeGUID: this.selectedTripCodeGUID, aircraftGUID: this.aircraftGUID, v: this._authService.getCurrentTimeNumber() };
    const dialogRef = this._dialog.open(LeglistEditorDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //if (this.selectedTabName == "leg") {
        this.getUsernameList().subscribe(res1 => {

        });
        this.getTripBytId();
        this.clickSubmit();

        //}
      }

    });

  }

  clickAddEditCrewPax() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = { dialogTitle: s, customerGUID: this.customerGUID, tripCodeGUID: this.selectedTripCodeGUID, aircraftGUID: this.aircraftGUID, v: this._authService.getCurrentTimeNumber() };
    const dialogRef = this._dialog.open(TripPersonlistEditorDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //if (this.selectedTabName == "leg") {
        this.getUsernameList().subscribe(res1 => {

        });
        this.getTripBytId();
        this.clickSubmit();

        //}
      }

    });

  }


  clickAddBRTasks() {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "34em";//"1000px";
    dialogConfig.minHeight = "23em";//"500px";
    dialogConfig.maxHeight = "40em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    if (this.selectedGroundStopList[0].hasHaltServices) {
      dialogConfig.data = {
        customerGUID: this.request.customerGUID, v: this._authService.getCurrentTimeNumber()
      };

      const dialogRef = this._dialog.open(ClientHaltServicesViewDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.openBRTaskSelection();

        }
      });
    }
    else {
      this.openBRTaskSelection();
    }

  }

  openBRTaskSelection() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.minWidth = "34em";//"1000px";
    //dialogConfig.minHeight = "39em";//"500px";
    //dialogConfig.maxHeight = "40em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.panelClass = "custom-dialog-container";
    this.refreshChildColumn = "";
    dialogConfig.data = {
      tripCodeGUID: this.selectedTripCodeGUID, isActive: this.includeActiveStopOnly, hidePastStop: this.hidePastStop
    };

    const dialogRef = this._dialog.open(AddBusinessRulesTasksDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        if (result) {
          //if (this.selectedTabName == "leg") {
          this.getUsernameList().subscribe(res1 => {

          });
          this.getTripBytId();
          this.clickSubmit();

          //}


        }
      }

    });
  }

  clickAddServices() {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    if (this.hasHaltServices) {
      dialogConfig.data = {
        customerGUID: this.request.customerGUID, v: this._authService.getCurrentTimeNumber()
      };

      const dialogRef = this._dialog.open(ClientHaltServicesViewDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.openServiceSelection();

        }
      });
    }
    else {
      this.openServiceSelection();
    }

  }

  openServiceSelection() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = { dialogTitle: "", customerGUID: this.customerGUID, tripCodeGUID: this.selectedTripCodeGUID, aircraftGUID: this.aircraftGUID, v: this._authService.getCurrentTimeNumber() };

    const dialogRef = this._dialog.open(TripServicelistEditorDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //if (this.selectedTabName == "leg") {
        this.clickSubmit();

        //}
      }
    });


  }

  openTripSummaryReport() {

    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.restoreFocus = false;
    config.data = { tripCodeGUID: this.selectedTripCodeGUID, customerGUID: this.customerGUID };
    this._dialog.open(TripSummaryReportDialogComponent, config);
  }

  openFP() {

    const config = new MatDialogConfig();
    //config.panelClass = "custom-dialog-container"
    config.restoreFocus = false;
    config.data = { tripCodeGUID: this.selectedTripCodeGUID, customerGUID: this.customerGUID, registration: this.registration, acType: this.acType };
    this._dialog.open(FlightPlanPreferenceEditComponent, config);
  }

  clickEmail() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.selectedTripCodeGUID, groundStopGUID: '', groundStopTaskGUID: '', nextGroundStopGUID: '', serviceTypeID: 0,
      aircraftGUID: this.aircraftGUID, customerGUID: this.customerGUID, taskGroundStopGUID: '', v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        //this.isModified = true;
      }

    });

  }



  viewEmails() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "92em";//"1000px";
    dialogConfig.width = "89em";//"1000px";
    // dialogConfig.height = "42.5em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      tripCodeGUID: this.selectedTripCodeGUID, groundStopGUID: '', groundStopTaskGUID: '', nextGroundStopGUID: '', serviceTypeID: 0,
      aircraftGUID: this.aircraftGUID, customerGUID: this.customerGUID, taskGroundStopGUID: '', hidePastStop: this.hidePastStop,
      includeActiveStopOnly: this.includeActiveStopOnly, registration: this.registration, tripCodeText: this.selectedTripCode, lockdownTrip: this.lockdownTrip
    };

    const dialogRef = this._dialog.open(TripMessageCenterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        //this.isModified = true;
      }
    });

  }

  viewClientInstructions() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.height = '48em';

    dialogConfig.data = {
      tripCodeGUID: this.selectedTripCodeGUID, customerGUID: this.customerGUID,
      aircraftGUID: this.aircraftGUID, pageFrom: 'trip', clientName: this.clientName, clientGUID: this.customerGUID, nonStandardProcedures: this.nonStandardClientInstructions, 
      clientInstructions: this.clientInstructions, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(CustomerInstructionsComponent, dialogConfig);
  }

  viewFlightPlanPreferences() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.height = 'max-content';
    dialogConfig.maxHeight = '48em';
    dialogConfig.maxWidth = '96em';

    dialogConfig.data = {
      tripCodeGUID: this.selectedTripCodeGUID, customerGUID: this.customerGUID,
      aircraftGUID: this.aircraftGUID, pageFrom: 'trip', clientName: this.clientName,
      request: this.request, registration: this.registration, tripCode: this.selectedTripCode, hidePastStop: this.hidePastStop, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(FlightPlanLeglistComponent, dialogConfig);
  }

  getOut() {
    setTimeout(() => {
      this.selectedOption = "note";
    }, 10000);
  }


  clickTripDocuments() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    /*dialogConfig.disableClose = true;*/

    //let leg: string = "";

    //leg = item.departureICAO;
    //if (item.nextArrivalICAO != "") {
    //  leg += "-" + item.nextArrivalICAO;
    //}

    dialogConfig.data = {
      tripCodeGUID: this.selectedTripCodeGUID, customerGUID: this.customerGUID,
      aircraftGUID: this.aircraftGUID, pageFrom: 'trip', v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripDocumentSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {

        //this.getTripLegBydgIdagId(item.groundStopGUID, item.nextArrivalGroundStopGUID);
      }
    });

  }

  clickFuelQuoteSummary() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.width = "60em";
    dialogConfig.panelClass = "custom-dialog-container";

    dialogConfig.data = {
      tripCodeGUID: this.selectedTripCodeGUID,
      tripCode: this.selectedTripCode
    };
    if (localStorage.getItem('up.fuel') == 'true') {
      const dialogRef = this._dialog.open(FuelQuoteSummaryComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result.refresh == true) {
        }
      });
    }
    else {
      const dialogRef = this._dialog.open(FuelQuoteSummaryHistoryComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result.refresh == true) {
        }
      });
    }

  }

  openTripPlanner() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.disableClose = true;
    config.minWidth = "75vw"
    config.maxWidth = "90vw";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { tID: this.selectedTripCodeGUID, cID: this.customerGUID };
    this._dialog.open(TripPlannerComponent, config);
  }

}
