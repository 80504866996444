import { Injectable } from "@angular/core";
import { Observable, throwError, of } from "rxjs";
import { Routes, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpXhrBackend } from "@angular/common/http";
import { MenuModel } from "../models/menu.model";
import { ResponseModel } from '../models/response.model';
import { GlobalConstant } from "../common-utility/global-constant";
import { catchError, retry } from "rxjs/operators";
import { AuthenticateService } from "./authenticate.service";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from 'file-saver';
import { HoursObjModel } from '../models/hoursObj.model';
import { LegPermitsModel } from '../models/leg-permits-model';
import { DatePipe, registerLocaleData } from "@angular/common";
import { GroundStopAdvancedService } from "../services/ground-stop-advanced.service";
import { FlightBriefModel } from "../models/flight-brief.model";
import { VendorService } from "../services/vendor.service";
import { GroundStopService } from "../services/ground-stop.service";
import { DocumentModel } from "../models/document.model";
import { UtilityFunctions } from "../common-utility/utility.functions";
import { VendorModel } from "../models/vendor.model";
import { ReturnObjModel } from "../models/return-obj.model";
import { VendorHoursModel } from "../models/vendor-hours.model";
import { Workbook } from "exceljs";
import { UkGarModel } from "../models/uk-gar.model";
import { RemoteClearanceModel } from "../models/remote-clearance.model";
import { UploadTaskDocument } from "../ground-stop-advanced/upload-task-document";
import { GroundStopTaskDocumentModel } from "../models/ground-stop-task-document.model";
import { DialogService } from "./dialog.service";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { ChooseSSNDialogComponent } from "../common-utility/choose-ssn-dialog.component";
import { ApisManifest } from "../models/apis-manifest";
import localeMx from "@angular/common/locales/es-MX"
import { FlightBriefSectionModel } from "../models/flight-brief-sections.model";
import { GenDecModel } from "../models/gendec.model";
import { PermitsModel } from "../models/permits.model";
import { now } from "moment";
import { FuelTaskModel } from "../models/fuel-task.model";
import { GroundStopModel } from "../models/ground-stop.model";
import { FuelService } from "./fuel.service";
import { FuelQuoteSummary } from "../models/fuel-quote-summary";
import { TripDocumentModel } from "../models/trip-document.model";
import dayjs from "dayjs";
import { AirportModel } from "../models/airport.model";
import { AdvisoryModel } from "../models/advisory.model";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
var expressions = require("angular-expressions");
var assign = require("lodash/assign");
// define your filter functions here, for example
// to be able to write {clientname | lower}
expressions.filters.lower = function (input) {
  // This condition should be used to make sure that if your input is
  // undefined, your output will be undefined as well and will not
  // throw an error
  if (!input) return input;
  return input.toLowerCase();
};
function angularParser(tag) {
  tag = tag
    .replace(/^\.$/, "this")
    .replace(/(ï¿½|ï¿½)/g, "'")
    .replace(/(ï¿½|ï¿½)/g, '"');
  const expr = expressions.compile(tag);
  return {
    get: function (scope, context) {
      let obj = {};
      const scopeList = context.scopeList;
      const num = context.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = assign(obj, scopeList[i]);
      }
      return expr(scope, obj);
    },

  };

  
}

function parser(tag) {
  // We write an exception to handle the tag "$pageBreakExceptLast"
  if (tag === "$pageBreakExceptLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (!isLast) {
          return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }
  if (tag === "$pageBreak") {
    return {
      get(scope, context) {
        return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';

      },
    };
  }
  if (tag === "$lineBreak") {
    return {
      get(scope, context) {
        return '<w:br/>';

      },
    };
  }
  if (tag === "$lineBreakIfLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (isLast) {
          return '<w:p><w:r><w:br/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }


  const expressionParser = require("docxtemplater/expressions.js");
  expressionParser.filters.toFixed = function (input, precision) {
    // In our example precision is the integer 2

    // Make sure that if your input is undefined, your
    // output will be undefined as well and will not
    // throw an error
    if (!input) return input;

    return input.toFixed(precision);
  };

  // We use the angularParser as the default fallback
  // If you don't wish to use the angularParser,
  // you can use the default parser as documented here:
  // https://docxtemplater.com/docs/configuration#default-parser
  return angularParser(tag);
}
const datepipe: DatePipe = new DatePipe('en-US');
const datepipeMEX: DatePipe = new DatePipe('es-MX');
const datepipeUK: DatePipe = new DatePipe('en-UK');

@Injectable()
export class TemplateService {

  constructor(private readonly _authService: AuthenticateService, private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly http: HttpClient,
    private readonly _vendorService: VendorService, private readonly _groundStopService: GroundStopService, private _dialog: MatDialog, private readonly _fuelService: FuelService) {
    registerLocaleData(localeMx, 'es-MX');
  }

  hoursData: HoursObjModel[];

  initHoursData() {
    this.hoursData = [
      { dayOfWeek: "Sunday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sun_Open", closeTimeObjName: "sun_Close", closedObjName: "sunClosed", hasHours: false },
      { dayOfWeek: "Monday", abbreviation: "M", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "mon_Open", closeTimeObjName: "mon_Close", closedObjName: "monClosed", hasHours: false },
      { dayOfWeek: "Tuesday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "tue_Open", closeTimeObjName: "tue_Close", closedObjName: "tueClosed", hasHours: false },
      { dayOfWeek: "Wednesday", abbreviation: "W", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "wed_Open", closeTimeObjName: "wed_Close", closedObjName: "wedClosed", hasHours: false },
      { dayOfWeek: "Thursday", abbreviation: "T", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "thu_Open", closeTimeObjName: "thu_Close", closedObjName: "thuClosed", hasHours: false },
      { dayOfWeek: "Friday", abbreviation: "F", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "fri_Open", closeTimeObjName: "fri_Close", closedObjName: "friClosed", hasHours: false },
      { dayOfWeek: "Saturday", abbreviation: "S", daySelected: false, openTime: "", closeTime: "", is24Hours: false, isClosed: 0, openTimeObjName: "sat_Open", closeTimeObjName: "sat_Close", closedObjName: "satClosed", hasHours: false }
    ]
  }

  generateFlightBrief(request: FlightBriefModel, briefFormat: string, flightBriefSectionList: FlightBriefSectionModel[], tripCode: string, registration: string, format: string, reportName: string): Observable<ReturnObjModel> {
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();

      this._groundStopAdvancedService.getFlightBrief<ResponseModel<FlightBriefModel>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let flightBrief = response.model;
            flightBrief.groundStopTaskDocuments = request.groundStopTaskDocuments;
            flightBrief.handlingNotes = [];
            var self = this;
            let finished = false;
            if (flightBrief.handlingVendorGUIDs != null && flightBriefSectionList.find(x => x.flightBriefReportSectionID == 2).selected == true) {
              this.getVendorDetails(flightBrief).subscribe(x => {
                if (x.refresh) {
                  this.createFlightBriefDocument(flightBrief, briefFormat, flightBriefSectionList, tripCode, registration, format, reportName).subscribe(x => {
                    ob.next(x);

                  });
                }
                else {
                  ob.next(x);
                }

              });


            }
            else {
              this.createFlightBriefDocument(flightBrief, briefFormat, flightBriefSectionList, tripCode, registration, format, reportName).subscribe(x => {
                ob.next(x);
              });
            }
          }
        }
      });
    });
  }

  createFlightBriefDocument(flightBrief: FlightBriefModel, briefFormat: string, flightBriefSectionList: FlightBriefSectionModel[], tripCode: string, registration: string, format: string, reportName: string): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();
      var self = this;
      let uplinkProvider = "";
      let hasPermits = false;
      let hasSlots = false;
      let overflightPermits = null;
      let landingPermits = null;
      let overflightPermitLegs = [];
      let landingPermitLegs = [];
      let taskNotes = [];
      if (flightBrief.permits != null) {
        hasPermits = true;
        overflightPermits = flightBrief.permits.filter(v => v.serviceTypeDescription == "OVERFLIGHT PERMIT");
        let tempLeg: string = "";
        overflightPermits.forEach(x => {
          let leg = x.departureICAO + '-' + x.arrivalICAO;
          if (tempLeg != leg) {
            tempLeg = leg;
            overflightPermitLegs.push({ leg: leg, permits: overflightPermits.filter(a => a.departureICAO + '-' + a.arrivalICAO == tempLeg) });
          }
        });
        landingPermits = flightBrief.permits.filter(v => v.serviceTypeDescription.includes("LANDING PERMIT"));
        tempLeg = "";
        landingPermits.forEach(x => {
          let leg = x.departureICAO + '-' + x.arrivalICAO;
          if (tempLeg != leg) {
            tempLeg = leg;
            let y = new LegPermitsModel();
            landingPermitLegs.push({ leg: leg, permits: landingPermits.filter(a => a.departureICAO + '-' + a.arrivalICAO == tempLeg) });

          }
        });
      }
      let hasOverflights = false;
      if (overflightPermits != null)
        if (overflightPermits.length > 0) {
          hasOverflights = true;
        }
      let hasLanding = false;
      if (landingPermits != null)
        if (landingPermits.length > 0)
          hasLanding = true;
      if (flightBrief.slotsPPR != null)
        hasSlots = true;
      if (flightBrief.flightPlan != null) {
        if (flightBrief.flightPlan.length > 0)
          uplinkProvider = flightBrief.flightPlan[0].uplinkProvider;
      }
      let hasTerminal = false;
      let terminal = "";
      if (flightBrief.terminalForecast != "") {
        hasTerminal = true;
        terminal = flightBrief.terminalForecast.replace(/(||)/g, '_');;
      }
      let hasMetars = false;
      let metars = "";
      if (flightBrief.metars != "") {
        hasMetars = true;
        metars = flightBrief.metars.replace(/(||)/g, '_');;
      }
      let hasSigmets = false;
      let sigmets = "";
      if (flightBrief.sigmets != "") {
        hasSigmets = true;
        sigmets = flightBrief.sigmets.replace(/(||)/g, '_');;
      }
      let hasAirmets = false;
      let airmets = "";
      if (flightBrief.airmets != "") {
        hasAirmets = true;
        airmets = flightBrief.airmets.replace(/(||)/g, '_');;
      }
      let hasPireps = false;
      let pireps = "";
      if (flightBrief.pireps != "") {
        hasPireps = true;
        pireps = flightBrief.pireps.replace(/(||)/g, '_');;
      }
      let hasTropical = false;
      let tropical = "";
      if (flightBrief.tropicalAdvisory != "") {
        hasTropical = true;
        tropical = flightBrief.tropicalAdvisory.replace(/(||)/g, '_');;
      }
      let hasVA = false;
      let va = "";
      if (flightBrief.vaAdvisory != "") {
        hasVA = true;
        va = flightBrief.vaAdvisory.replace(/(||)/g, '_');;
      }
      let hasDepartureNotams = false;
      let departureNotams = "";
      if (flightBrief.departureNotams != "") {
        hasDepartureNotams = true;
        departureNotams = flightBrief.departureNotams.replace(/(||)/g, '_');;
      }
      let hasDestinationNotams = false;
      let destinationNotams = "";
      if (flightBrief.destinationNotams != "") {
        hasDestinationNotams = true;
        destinationNotams = flightBrief.destinationNotams.replace(/(||)/g, '_');;
      }
      let hasETPNotams = false;
      let etpNotams = "";
      if (flightBrief.etpNotams != "") {
        hasETPNotams = true;
        etpNotams = flightBrief.etpNotams.replace(/(||)/g, '_');;
      }
      let hasFDCNotams = false;
      let fdcNotams = "";
      if (flightBrief.fdcNotams != "") {
        hasFDCNotams = true;
        fdcNotams = flightBrief.fdcNotams.replace(/(||)/g, '_');;
      }
      let hasTrackMessage = false;
      let trackMessage = "";
      if (flightBrief.trackMessage != "") {
        hasTrackMessage = true;
        trackMessage = flightBrief.trackMessage.replace(/(||)/g, '_');
      }

      let hasSynopsis = false;
      let synopsis = "";
      if (flightBrief.synopsis != "") {
        hasSynopsis = true;
        synopsis = flightBrief.synopsis.replace(/(||)/g, '_');
      }

      let hasTurbulence = false;
      let turbulence = "";
      if (flightBrief.turbulence != "") {
        hasTurbulence = true;
        turbulence = flightBrief.turbulence.replace(/(||)/g, '_');
      }

      let hasTfrNotams = false;
      let tfrNotams = "";
      if (flightBrief.tfrNotams != "") {
        hasTfrNotams = true;
        tfrNotams = flightBrief.tfrNotams.replace(/(||)/g, '_');
      }

      let hasTaskNotes = false;
      if (flightBrief.groundStopTasks != null)
        hasTaskNotes = true;

      let filePath = "";
      if (briefFormat == "a")
        filePath = window.origin + "/assets/templates/FlightBriefTemplate.docx"

      else
        filePath = window.origin + "/assets/templates/FlightBriefTemplate2.docx";
      if (reportName == "Wx Outlook")
        filePath = window.origin + "/assets/templates/WxOutlookTemplate.docx";
      loadFile(filePath, function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser });
        let reportDate = datepipe.transform(new Date().toUTCString(), 'MMM-dd-yyyy HH:mm', "UTC");
        flightBrief.reportDate = reportDate;
        let hasTo = false;
        let hasAddress = false;
        flightBrief.handlingNotes.sort(function (a, b) {
          if (a.pageIndex < b.pageIndex) { return -1; }
          if (a.pageIndex > b.pageIndex) { return 1; }
          return 0
        });
        doc.setData({
          reportName: reportName.toUpperCase(),
          includeImportantNote: reportName == "Wx Outlook" ? false : flightBriefSectionList.find(v => v.sectionName == "Important Note").selected,
          includeAirportInformation: flightBriefSectionList.find(v => v.sectionName == "Airport Information").selected,
          includePermits: flightBriefSectionList.find(v => v.sectionName == "Permits").selected,
          includeSlots: flightBriefSectionList.find(v => v.sectionName == "Slots").selected,
          includeWeather: reportName == "Trip Brief" ? false : flightBriefSectionList.find(v => v.sectionName == "Weather").selected,
          includeNotams: reportName == "Trip Brief" ? false : flightBriefSectionList.find(v => v.sectionName == "NOTAMS").selected,
          includeFlightPlan: reportName == "Trip Brief" || reportName == "Wx Outlook" ? false : flightBriefSectionList.find(v => v.sectionName == "Flight Plan").selected,
          importantNote: flightBrief.importantNote == null ? '' : flightBrief.importantNote,
          includeUplink: reportName == "Trip Brief" || reportName == "Wx Outlook" ? false : true,
          hasUplink: flightBrief.hasArincUplink,
          weatherNote: flightBrief.weatherNote,
          notamsNote: flightBrief.notamsNote,
          flightPlan: flightBrief.flightPlan,
          uplinkProvider: uplinkProvider,
          airport: flightBrief.handlingNotes,
          hasPermits: hasPermits,
          hasOverflight: hasOverflights,
          overflightPermits: overflightPermitLegs,
          hasLanding: hasLanding,
          landingPermits: landingPermitLegs,
          hasSlots: hasSlots,
          slotsPPR: flightBrief.slotsPPR,
          hasTaskNotes: hasTaskNotes,
          taskNotes: flightBrief.groundStopTasks,
          tripCode: tripCode,
          tailNo: registration,
          clientName: flightBrief.customerName.toUpperCase(),
          minDate: flightBrief.minDate,
          maxDate: flightBrief.maxDate,
          route: flightBrief.tripRoute,
          reportDate: reportDate,
          hasTo: hasTo,
          //reportTo: self.f.summary_to.value,
          hasAddress: hasAddress,
          //address: self.f.summary_address.value,
          //addressType: self.addressType,
          //  coverPageNote: self.f.cover_note.value,
          reportID: flightBrief.reportID,
          reportType: reportName,
          hasTerminal: hasTerminal,
          terminal: terminal,
          hasMetars: hasMetars,
          metars: metars,
          hasSigmets: hasSigmets,
          sigmets: sigmets,
          hasAirmets: hasAirmets,
          airmets: airmets,
          hasPireps: hasPireps,
          pireps: pireps,
          hasTropical: hasTropical,
          tropical: tropical,
          hasVA: hasVA,
          va: va,
          hasDepartureNotams: hasDepartureNotams,
          departureNotams: departureNotams,
          hasDestinationNotams: hasDestinationNotams,
          destinationNotams: destinationNotams,
          hasETPNotams: hasETPNotams,
          etpNotams: etpNotams,
          hasFDCNotams: hasFDCNotams,
          fdcNotams: fdcNotams,
          hasTrackMessage: hasTrackMessage,
          trackMessage: trackMessage,
          hasSynopsis: hasSynopsis,
          synopsis: synopsis,
          hasTurbulence: hasTurbulence,
          turbulence: turbulence,
          prelimPlans: flightBrief.prelimPlans,
          hasTfrNotams: hasTfrNotams,
          tfrNotams: tfrNotams,

        });
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                //self.isLoading = false;
                return error;
              },
                {});
            }
            return value;
          }
          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
            // self.errMsg = "There was an error creating the report."
            // errorMessages is a humanly readable message looking like this:
            // 'The tag beginning with "foobar" is unopened'
          }

          throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });

        var reader = new FileReader();

        reader.readAsDataURL(out);
        let request = new FlightBriefModel();
        reader.onloadend = function () {
          let taskDocument = new DocumentModel();
          taskDocument.bFile = (<string>reader.result).split(',')[1];
          taskDocument.mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          request.flightBriefDocument = taskDocument;
          //  request.groundStopTaskDocuments = request.groundStopTaskDocuments.selectDocumentList;
          if (reportName == "Flight Brief" && flightBriefSectionList.find(v => v.sectionName == "Flight Plan").selected)
            request.icaoForms = flightBrief.icaoForms;
          request.weatherCharts = [];
          request.reportDate = flightBrief.reportDate;
          if (flightBrief.weatherCharts != null) {
            flightBrief.weatherCharts.forEach(v => {
              request.weatherCharts.push(v);
            });
          }
          request.docFormat = format;
          request.reportID = flightBrief.reportID;
          request.groundStopTaskDocuments = flightBrief.groundStopTaskDocuments;
          self._groundStopAdvancedService.compileFlightBrief<ResponseModel<FlightBriefModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {

              let obj = response.model.flightBriefDocument;
              let mimeType = "";
              if (format == "word")
                mimeType = "application / vnd.openxmlformats - officedocument.wordprocessingml.document";
              else
                mimeType = "application/pdf";
              let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
              let file = new Blob([byteArray], { type: mimeType });


              if (format == 'word') {
                saveAs(file, reportName + " - " + registration + " " + flightBrief.tripRoute + ".docx");

              }
              objReturn.refresh = true;
              objReturn.remarks = "";
              ob.next(objReturn);

            }
            else {
              if (response.code == "401") {
                self._authService.signOut();
              }
              else {
                objReturn.refresh = false;
                objReturn.remarks = "An error occurred generating flight brief.";
                ob.next(objReturn);
              }
            }
          });
        }
      });
    });
  }

  getVendorDetails(flightBrief: FlightBriefModel): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    let userType = "";
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        userType = "internal";
        break;
      case "c":
        userType = "customer";
        break;
      case "v":
        userType = "vendor";
        break;
      default:
        userType = "";
        break;
    }
    return new Observable<ReturnObjModel>((x) => {
      let objReturn = new ReturnObjModel();
      let ssnVendor = 0;
      let idx = 0;

      for (var v of flightBrief.handlingVendorGUIDs) {
        this._vendorService.getVendorDetailsByVendorID<ResponseModel<VendorModel>>(v.split("|")[0]).subscribe(r => {
          if (r.model != null) {
            if (r.code == "200" && r.message == "") {
              let vendorDetails = r.model;
              let vendorID = vendorDetails.vendorGUID + "|";
              let index = -1;
              if (vendorDetails.relatedVendors != null && vendorDetails.relatedVendors != undefined) {
                index = flightBrief.handlingVendorGUIDs.indexOf(vendorID);
                if (index == -1)
                  index = flightBrief.handlingVendorGUIDs.indexOf(vendorID.toUpperCase());
                let i = 0;
                while (index == -1) {
                  index = flightBrief.handlingVendorGUIDs.indexOf(vendorID + vendorDetails.relatedVendors[i].vendorID.toString());
                  if (index == -1)
                    index = flightBrief.handlingVendorGUIDs.indexOf(vendorID.toUpperCase() + vendorDetails.relatedVendors[i].vendorID.toString());
                  if (index == -1)
                    index = flightBrief.handlingVendorGUIDs.indexOf(vendorID + "18548");
                  if (index == -1)
                    index = flightBrief.handlingVendorGUIDs.indexOf(vendorID.toUpperCase() + "18548");
                  if (index == -1)
                    index = flightBrief.handlingVendorGUIDs.indexOf(vendorID + "-1");
                  if (index == -1)
                    index = flightBrief.handlingVendorGUIDs.indexOf(vendorID.toUpperCase() + "-1");
                  i += 1;
                }

              }
              else {
                index = flightBrief.handlingVendorGUIDs.indexOf(vendorID);
                if (index == -1)
                  index = flightBrief.handlingVendorGUIDs.indexOf(vendorID.toUpperCase());
                if (index == -1)
                  index = flightBrief.handlingVendorGUIDs.indexOf(vendorID + "0");
                if (index == -1)
                  index = flightBrief.handlingVendorGUIDs.indexOf(vendorID.toUpperCase() + "0");
                if (index == -1)
                  index = flightBrief.handlingVendorGUIDs.indexOf(vendorID + "-1");
                if (index == -1)
                  index = flightBrief.handlingVendorGUIDs.indexOf(vendorID.toUpperCase() + "-1");
              }
              if (index == -1) {
                objReturn.refresh = false;
                objReturn.remarks = "Selected SSN vendor is not related to handler."
                x.next(objReturn);
                return;
              }

              ssnVendor = Number(flightBrief.handlingVendorGUIDs[index].split("|")[1]);
              vendorDetails.pageIndex = index;
              if (vendorDetails.airportNotes.length > 0)
                vendorDetails.hasAirportNotes = true;
              else
                vendorDetails.hasAirportNotes = false;
              if (vendorDetails.customsNotes.length > 0)
                vendorDetails.hasCustomsNotes = true;
              else
                vendorDetails.hasCustomsNotes = false;
              if (vendorDetails.handlingNotes.length > 0)
                vendorDetails.hasHandlingNotes = true;
              else
                vendorDetails.hasHandlingNotes = false;
              if (vendorDetails.vendorPersons.length > 0 || vendorDetails.vendorComms != null) {
                if (vendorDetails.vendorPersons.length > 0) {
                  vendorDetails.vendorPersons = vendorDetails.vendorPersons.filter(person => person.includeInBrief == true);
                  vendorDetails.vendorPersons.forEach(person => {
                    person.personCommsAddress = person.personCommsAddress.filter(x => x.includeInBrief == true);
                  });
                }
                vendorDetails.hasContacts = true;
              }
              else
                vendorDetails.hasContacts = false;
              let relatedVendorDetails;
              var self = this;
              if (vendorDetails.relatedVendors != null)
                if (userType != 'internal' && ssnVendor == 0 && vendorDetails.relatedVendors.length > 1)
                  vendorDetails.relatedVendors = null;
              //if (vendorDetails.relatedVendors != null) {
              //  if (ssnVendor == 0 && vendorDetails.relatedVendors.length > 1) {
              //    const dialogConfig = new MatDialogConfig();
              //    dialogConfig.autoFocus = true;
              //    dialogConfig.disableClose = false;
              //    dialogConfig.width = "30em";
              //    dialogConfig.height = "10m";
              //    dialogConfig.data = { vendorDetails: vendorDetails, relatedVendorDetails: vendorDetails.relatedVendors };
              //    const dialogRef = this._dialog.open(ChooseSSNDialogComponent, dialogConfig);
              //    dialogRef.afterClosed().subscribe(result => {
              //      ssnVendor = result;
              //      if (ssnVendor != 0) {
              //        relatedVendorDetails = vendorDetails.relatedVendors.filter(v => v.vendorID == ssnVendor)[0];
              //        if (relatedVendorDetails != undefined) {
              //          if (relatedVendorDetails.vendorPersons.length > 0) {
              //            relatedVendorDetails.vendorPersons = relatedVendorDetails.vendorPersons.filter(x => x.includeInBrief == true);
              //            relatedVendorDetails.vendorPersons.forEach(x => {
              //              x.personCommsAddress = x.personCommsAddress.filter(y => y.includeInBrief == true);
              //            });
              //          }
              //        }
              //        vendorDetails.relatedVendors = relatedVendorDetails;

              //      }
              //      else {
              //        vendorDetails.relatedVendors = null;
              //      }

              //      let vendorHours = vendorDetails.vendorHours;
              //      if (vendorHours != null) {
              //        vendorDetails.hasHours = true;
              //        vendorHours.forEach(h => {
              //          self.initHoursData();
              //          if (!h.is24Hours) {
              //            let sameHours = true;
              //            let openTime = h.sun_Open;
              //            let closeTime = h.sun_Close;
              //            let utcLocal = "Local";
              //            if (h.isUTC)
              //              utcLocal = "UTC";
              //            closeTime += ' ' + utcLocal;
              //            self.hoursData.forEach(v => {
              //              let utcLocal = "Local";
              //              if (h.isUTC)
              //                utcLocal = "UTC";
              //              switch (v.dayOfWeek) {
              //                case "Sunday":
              //                  if (h.is24Hours || h.sunClosed == 2)
              //                    v.is24Hours = true;
              //                  else
              //                    v.is24Hours = false;
              //                  v.openTime = h.sun_Open;
              //                  v.closeTime = h.sun_Close + ' ' + utcLocal;
              //                  v.isClosed = h.sunClosed;
              //                  v.hasHours = h.hasSun;
              //                  if (v.is24Hours || v.isClosed)
              //                    v.hasHours = true;
              //                  break;
              //                case "Monday":
              //                  if (h.is24Hours || h.monClosed == 2)
              //                    v.is24Hours = true;
              //                  else
              //                    v.is24Hours = false;
              //                  v.openTime = h.mon_Open;
              //                  v.closeTime = h.mon_Close + ' ' + utcLocal;
              //                  v.isClosed = h.monClosed;
              //                  v.hasHours = h.hasMon;
              //                  if (v.is24Hours || v.isClosed)
              //                    v.hasHours = true;
              //                  break;
              //                case "Tuesday":
              //                  if (h.is24Hours || h.tueClosed == 2)
              //                    v.is24Hours = true;
              //                  else
              //                    v.is24Hours = false;
              //                  v.openTime = h.tue_Open;
              //                  v.closeTime = h.tue_Close + ' ' + utcLocal;
              //                  v.isClosed = h.tueClosed;
              //                  v.hasHours = h.hasTue;
              //                  if (v.is24Hours || v.isClosed)
              //                    v.hasHours = true;
              //                  break;
              //                case "Wednesday":
              //                  if (h.is24Hours || h.wedClosed == 2)
              //                    v.is24Hours = true;
              //                  else
              //                    v.is24Hours = false;
              //                  v.openTime = h.wed_Open;
              //                  v.closeTime = h.wed_Close + ' ' + utcLocal;
              //                  v.isClosed = h.wedClosed;
              //                  v.hasHours = h.hasWed;
              //                  if (v.is24Hours || v.isClosed)
              //                    v.hasHours = true;
              //                  break;
              //                case "Thursday":
              //                  if (h.is24Hours || h.thuClosed == 2)
              //                    v.is24Hours = true;
              //                  else
              //                    v.is24Hours = false;
              //                  v.openTime = h.thu_Open;
              //                  v.closeTime = h.thu_Close + ' ' + utcLocal;
              //                  v.isClosed = h.thuClosed;
              //                  v.hasHours = h.hasThu;
              //                  if (v.is24Hours || v.isClosed)
              //                    v.hasHours = true;
              //                  break;
              //                case "Friday":
              //                  if (h.is24Hours || h.friClosed == 2)
              //                    v.is24Hours = true;
              //                  else
              //                    v.is24Hours = false;
              //                  v.openTime = h.fri_Open;
              //                  v.closeTime = h.fri_Close + ' ' + utcLocal;
              //                  v.isClosed = h.friClosed;
              //                  v.hasHours = h.hasFri;
              //                  if (v.is24Hours || v.isClosed)
              //                    v.hasHours = true;
              //                  break;
              //                case "Saturday":
              //                  if (h.is24Hours || h.satClosed == 2)
              //                    v.is24Hours = true;
              //                  else
              //                    v.is24Hours = false;
              //                  v.openTime = h.sat_Open;
              //                  v.closeTime = h.sat_Close + ' ' + utcLocal;
              //                  v.isClosed = h.satClosed;
              //                  v.hasHours = h.hasSat;
              //                  if (v.is24Hours || v.isClosed)
              //                    v.hasHours = true;
              //                  break;
              //              }
              //              if (openTime != v.openTime || closeTime != v.closeTime)
              //                sameHours = false;
              //            });
              //            h.sameHours = sameHours;
              //            h.openTime = openTime;
              //            h.closeTime = closeTime;
              //            h.vendorDailyHours = this.hoursData.filter(x => x.hasHours == true);
              //          }
              //        });
              //      }

              //      flightBrief.handlingNotes.push(vendorDetails);
              //      idx += 1;
              //      if (idx == flightBrief.handlingVendorGUIDs.length) {
              //        objReturn.refresh = true;
              //        objReturn.vendorDetails = flightBrief.handlingNotes;
              //        x.next(objReturn);
              //      }
              //    });

              //  }
              //}

              if (vendorDetails.relatedVendors != null && ssnVendor != -1) {
                //if (ssnVendor != 0 || vendorDetails.relatedVendors.length == 1) {
                if (ssnVendor != 0)
                  relatedVendorDetails = vendorDetails.relatedVendors.filter(v => v.vendorID == ssnVendor)[0];
                else
                  relatedVendorDetails = vendorDetails.relatedVendors[0];
                if (relatedVendorDetails != undefined) {
                  if (relatedVendorDetails.vendorPersons.length > 0) {
                    relatedVendorDetails.vendorPersons = relatedVendorDetails.vendorPersons.filter(x => x.includeInBrief == true);
                    relatedVendorDetails.vendorPersons.forEach(x => {
                      x.personCommsAddress = x.personCommsAddress.filter(y => y.includeInBrief == true);
                    });
                  }
                }
                vendorDetails.relatedVendors = relatedVendorDetails;

                //  }
              }
              else {
                vendorDetails.relatedVendors = null;
              }

              let vendorHours = vendorDetails.vendorHours;
              if (vendorHours != null) {
                vendorDetails.hasHours = true;
                vendorHours.forEach(h => {
                  self.initHoursData();
                  if (!h.is24Hours) {
                    let sameHours = true;
                    let openTime = h.sun_Open;
                    let closeTime = h.sun_Close;
                    let utcLocal = "Local";
                    if (h.isUTC)
                      utcLocal = "UTC";
                    closeTime += ' ' + utcLocal;
                    self.hoursData.forEach(v => {
                      let utcLocal = "Local";
                      if (h.isUTC)
                        utcLocal = "UTC";
                      switch (v.dayOfWeek) {
                        case "Sunday":
                          if (h.is24Hours || h.sunClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.sun_Open;
                          v.closeTime = h.sun_Close + ' ' + utcLocal;
                          v.isClosed = h.sunClosed;
                          v.hasHours = h.hasSun;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Monday":
                          if (h.is24Hours || h.monClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.mon_Open;
                          v.closeTime = h.mon_Close + ' ' + utcLocal;
                          v.isClosed = h.monClosed;
                          v.hasHours = h.hasMon;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Tuesday":
                          if (h.is24Hours || h.tueClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.tue_Open;
                          v.closeTime = h.tue_Close + ' ' + utcLocal;
                          v.isClosed = h.tueClosed;
                          v.hasHours = h.hasTue;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Wednesday":
                          if (h.is24Hours || h.wedClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.wed_Open;
                          v.closeTime = h.wed_Close + ' ' + utcLocal;
                          v.isClosed = h.wedClosed;
                          v.hasHours = h.hasWed;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Thursday":
                          if (h.is24Hours || h.thuClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.thu_Open;
                          v.closeTime = h.thu_Close + ' ' + utcLocal;
                          v.isClosed = h.thuClosed;
                          v.hasHours = h.hasThu;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Friday":
                          if (h.is24Hours || h.friClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.fri_Open;
                          v.closeTime = h.fri_Close + ' ' + utcLocal;
                          v.isClosed = h.friClosed;
                          v.hasHours = h.hasFri;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                        case "Saturday":
                          if (h.is24Hours || h.satClosed == 2)
                            v.is24Hours = true;
                          else
                            v.is24Hours = false;
                          v.openTime = h.sat_Open;
                          v.closeTime = h.sat_Close + ' ' + utcLocal;
                          v.isClosed = h.satClosed;
                          v.hasHours = h.hasSat;
                          if (v.is24Hours || v.isClosed)
                            v.hasHours = true;
                          break;
                      }
                      if (openTime != v.openTime || closeTime != v.closeTime)
                        sameHours = false;
                    });
                    h.sameHours = sameHours;
                    h.openTime = openTime;
                    h.closeTime = closeTime;
                    h.vendorDailyHours = this.hoursData.filter(x => x.hasHours == true);
                  }
                });
              }

              flightBrief.handlingNotes.push(vendorDetails);
              idx += 1;
              if (idx == flightBrief.handlingVendorGUIDs.length) {
                objReturn.refresh = true;
                objReturn.vendorDetails = flightBrief.handlingNotes;
                x.next(objReturn);
              }


            }
          }



        });
        //  if (generateBrief == false && flightBrief.handlingVendorGUIDs.length > 1 && idx == flightBrief.handlingVendorGUIDs.length-1) {
        //  objReturn.refresh = true;
        //  objReturn.vendorDetails = flightBrief.handlingNotes;
        //  x.next(objReturn);
        //}

      };
    });
  }

  generateHandlingBrief(format: string, briefType: string, vendorDetails: VendorModel, relatedVendorDetails: VendorModel, vendorHours: VendorHoursModel[], icao: string): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(y => {
      let objReturn = new ReturnObjModel();

      let handlingNotes = vendorDetails;
      let hasRelatedVendor = false;
      let relatedVendors;
      if (relatedVendorDetails != undefined) {
        if (relatedVendorDetails != null) {
          relatedVendors = relatedVendorDetails;

          if (relatedVendors.vendorPersons.length > 0) {
            relatedVendors.vendorPersons = relatedVendors.vendorPersons.filter(x => x.includeInBrief == true);
            relatedVendors.vendorPersons.forEach(x => {
              x.personCommsAddress = x.personCommsAddress.filter(y => y.includeInBrief == true);
            });
          }
          hasRelatedVendor = true;
        }
      }
      let persons = handlingNotes.vendorPersons;
      if (briefType == "client") {
        if (persons != null) {
          persons = persons.filter(v => v.includeInBrief);
          persons.forEach(v => {
            v.personCommsAddress = v.personCommsAddress.filter(x => x.includeInBrief);
          });
        }
      }
      let hasHours = false;
      if (vendorHours != null)
        hasHours = true;
      var self = this;
      let filePath = window.origin + "/assets/templates/HandlingNotesTemplate.docx";
      //filePath = "https://localhost:5001/assets/templates/HandlingNotesTemplate.docx";

      loadFile(filePath, function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser });
        let reportDate = datepipe.transform(new Date().toUTCString(), 'MMM-dd-yyyy HH:mm', "UTC");
        doc.setData({
          icao: handlingNotes.icao,
          hasRelatedVendor: hasRelatedVendor,
          relatedVendorDetails: relatedVendors,
          hasHours: hasHours,
          sameHours: hasHours ? vendorHours[0].sameHours : '',
          openTime: hasHours ? vendorHours[0].openTime : '',
          closeTime: hasHours ? vendorHours[0].closeTime : '',
          vendorHours: vendorHours,
          vendorName: handlingNotes.vendorName,
          vendorPerson: persons,
          vendorComms: handlingNotes.vendorComms,
          airportNotes: handlingNotes.airportNotes,
          customsNotes: handlingNotes.customsNotes,
          handlingNotes: handlingNotes.handlingNotes,
          reportDate: reportDate

        });
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
                {});
            }
            return value;
          }

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
            objReturn.remarks = "There was an error creating the report."
            // errorMessages is a humanly readable message looking like this:
            // 'The tag beginning with "foobar" is unopened'
          }
          this.loading = false;
          throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        if (format == 'word') {
          saveAs(out, "Handling Notes - " + icao + ".docx");
          objReturn.remarks = "";
          y.next(objReturn);
        }
        else {

          let uri;
          if (window.origin.indexOf('dev') > -1 || window.origin.indexOf('localhost') > -1)
            uri = "https://fpipdfconversion-staging.azurewebsites.net/api/ConvertToPdf";
          else
            uri = "https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf";
          const functionURI = uri;

          let headers = new HttpHeaders();
          const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
          headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

          httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {
            var reader = new FileReader();
            reader.readAsDataURL(data.body);
            reader.onloadend = function () {
              var doc = reader.result;
              objReturn.docBFile = doc.toString().split(',')[1];
              objReturn.docSize = Math.round(data.body.size / 1024);
              objReturn.remarks = "";
              y.next(objReturn);
            }
            var fileURL = URL.createObjectURL(data.body);
            window.open(fileURL);
          }), catchError((error: HttpErrorResponse) => {
            this.catchMyError(error);
            return throwError(error.message)
          });
        }

      });

    });





  }

  generateUKGAR(tripCodeGUID: string, groundStopGUID: string, groundStopTaskGUID: string, isDeparture: boolean): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();

      let request = new UkGarModel();
      request.tripCodeGUID = tripCodeGUID;
      request.groundStopGUID = groundStopGUID;
      request.groundStopTaskGUID = groundStopTaskGUID;
      request.isDeparture = isDeparture;
      this._groundStopAdvancedService.getDataForUkGar<ResponseModel<UkGarModel>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let ukGAR = response.model;
            if (ukGAR.flightType.toLowerCase() == "arrival")
              ukGAR.isDeparture = false;
            else
              ukGAR.isDeparture = true;
            let workbook = new Workbook();
            loadFile(window.origin + "/assets/templates/GARtemplate.xlsx", function (
              error,
              content
            ) {
              if (error) {
                throw error;
              }
              workbook.xlsx.load(content).then(function () {
                const worksheet = workbook.getWorksheet(1);
                for (let i = 1; i < 13; i++) {
                  worksheet.getColumn(i).width = worksheet.getColumn(i).width * 1.17;
                }
                let d: Date;
                worksheet.getCell('B2').value = ukGAR.flightType;
                worksheet.getCell('B3').value = ukGAR.arrivalICAO;
                d = new Date(ukGAR.arrivalDate);
                worksheet.getCell('D3').value = new Date(ukGAR.arrivalDate);
                worksheet.getCell('F3').value = ukGAR.arrivalTime;
                worksheet.getCell('H3').value = ukGAR.operator;
                //worksheet.getCell('J3').value = ukGAR.picContactNumber;
                worksheet.getCell('B4').value = ukGAR.departureICAO;
                d = new Date(ukGAR.departureDate);
                worksheet.getCell('D4').value = new Date(ukGAR.departureDate);// new Date(d.getFullYear(), d.getMonth(), d.getDate());
                worksheet.getCell('F4').value = ukGAR.departureTime;
                worksheet.getCell('B5').value = ukGAR.registration;
                worksheet.getCell('D5').value = ukGAR.aircraftType;
                worksheet.getCell('F5').value = ukGAR.responsibleSurname;
                worksheet.getCell('H5').value = ukGAR.homebaseAirport;
                worksheet.getCell('B6').value = ukGAR.reasonForVisit;
                let rowNum = 9;
                ukGAR.crewManifest.forEach((x, i) => {
                  worksheet.getCell(rowNum, 1).value = x.documentType;
                  worksheet.getCell(rowNum, 3).value = x.countryOfIssue;
                  worksheet.getCell(rowNum, 4).value = x.documentNumber;
                  worksheet.getCell(rowNum, 5).value = x.lastName;
                  worksheet.getCell(rowNum, 6).value = (x.firstName + ' ' + x.middleName).trim();
                  worksheet.getCell(rowNum, 7).value = x.gender;
                  d = new Date(x.dob);
                  worksheet.getCell(rowNum, 8).value = new Date(x.dob);
                  worksheet.getCell(rowNum, 9).value = x.countryOfBirth;
                  worksheet.getCell(rowNum, 10).value = x.nationality;
                  d = new Date(x.documentExpirationDate);
                  worksheet.getCell(rowNum, 11).value = new Date(x.documentExpirationDate);
                  let homeAddress = "";
                  if (x.streetAddress != null)
                    homeAddress = x.streetAddress;
                  if (x.city != null)
                    homeAddress += ', ' + x.city;
                  if (x.postalCode != null)
                    homeAddress += ', ' + x.postalCode;
                  worksheet.getCell(rowNum, 12).value = homeAddress;
                  //if (i < ukGAR.crewManifest.length - 1) {
                  if (rowNum > 15) {
                    worksheet.insertRows(rowNum + 1, [''], 'i+');
                  }
                  rowNum += 1;
                });

                let found = false;
                while (!found) {
                  if (worksheet.getCell(rowNum, 1).value == 'PASSENGERS')
                    found = true;
                  rowNum += 1;
                }
                rowNum += 1;
                //  worksheet.getCell(rowNum - 1, 9).value = "PLACE OF BIRTH";
                let paxMax = rowNum + 19;
                ukGAR.paxManifest.forEach((x, i) => {
                  worksheet.getCell(rowNum, 1).value = x.documentType;
                  worksheet.getCell(rowNum, 3).value = x.countryOfIssue;
                  worksheet.getCell(rowNum, 4).value = x.documentNumber;
                  worksheet.getCell(rowNum, 5).value = x.lastName;
                  worksheet.getCell(rowNum, 6).value = (x.firstName + ' ' + x.middleName).trim();
                  worksheet.getCell(rowNum, 7).value = x.gender;
                  d = new Date(x.dob);
                  worksheet.getCell(rowNum, 8).value = new Date(x.dob);
                  worksheet.getCell(rowNum, 9).value = x.countryOfBirth;
                  worksheet.getCell(rowNum, 10).value = x.nationality;
                  d = new Date(x.documentExpirationDate)
                  worksheet.getCell(rowNum, 11).value = new Date(x.documentExpirationDate);
                  let homeAddress = "";
                  if (x.streetAddress != null)
                    homeAddress = x.streetAddress;
                  if (x.city != null)
                    homeAddress += ', ' + x.city;
                  if (x.postalCode != null)
                    homeAddress += ', ' + x.postalCode;
                  worksheet.getCell(rowNum, 12).value = homeAddress;
                  if (rowNum > paxMax) {
                    worksheet.insertRows(rowNum + 1, [''], 'i+');
                  }
                  rowNum += 1;
                });
                // if (ukGAR.paxManifest.length > 0)
                //   worksheet.getCell(rowNum + ukGAR.paxManifest.length, 2).value = { formula: 'COUNTA(E' + rowNum.toString() + ':E' + (rowNum + ukGAR.paxManifest.length - 1).toString() + ')', date1904: false };

                if (rowNum < 40)
                  rowNum = 40;
                worksheet.pageSetup.printArea = 'A1:L' + (rowNum + ukGAR.paxManifest.length).toString();

                workbook.xlsx.writeBuffer().then((data) => {
                  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                  let d: Date;

                  if (ukGAR.isDeparture) {
                    d = new Date(ukGAR.departureDate);
                  }
                  else {
                    d = new Date(ukGAR.arrivalDate)
                  }
                  let flightDate = datepipeUK.transform(new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()), 'dd MMM');
                  worksheet.getCell('D4').value = new Date(ukGAR.departureDate);// new Date(d.getFullYear(), d.getMonth(), d.getDate());
                  // let flightDate = ukGAR.isDeparture ? datepipe.transform(new Date(ukGAR.departureDateUTC)) : datepipe.transform(new Date(ukGAR.arrivalDateUTC), 'dd MMM');
                  let fileName = ukGAR.registration + ' ' + ukGAR.flightType + ' ' + 'GAR ' + flightDate + '.xlsx';
                  saveAs(blob, fileName.toUpperCase());
                  objReturn.refresh = true;
                  objReturn.remarks = "";
                  ob.next(objReturn);
                });
              });
            });


          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              objReturn.refresh = false;
              objReturn.remarks = "An error occurred generating UK GAR.";
              ob.next(objReturn);
            }
          }
        }
      });


    });
  }

  generateRemoteClearance(groundStopGUID: string, gstId: string, format: string, tripCode: string): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();
      let self = this;
      let request = new RemoteClearanceModel();
      request.groundStopGUID = groundStopGUID;
      this._groundStopAdvancedService.getDataForRemoteClearance<ResponseModel<RemoteClearanceModel[]>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let remoteClearance = response.model;
            remoteClearance.forEach(x => {
              let filePath = window.origin + "/assets/templates/RemoteClearanceForm.docx";

              loadFile(filePath, function (
                error,
                content
              ) {
                if (error) {
                  throw error;
                }
                const zip = new PizZip(content);
                const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser });
                doc.setData({
                  registration: x.registration,
                  lastName: x.lastName,
                  firstName: x.firstName,
                  middleName: x.middleName,
                  dob: x.dob,
                  nationality: x.nationality,
                  passportNumber: x.passportNumber,
                  passportDOE: x.passportDOE,
                  phoneNumber: x.personPhone,
                  email: x.personEmail,
                  airportName: x.airportName,
                  arrivalDate: x.arrivalDate,
                  address: x.ukAddress,
                  visaDOI: x.visaDOI,
                  visaDOE: x.visaDOE

                });
                try {
                  // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                  doc.render();
                } catch (error) {
                  // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                  function replaceErrors(key, value) {
                    if (value instanceof Error) {
                      return Object.getOwnPropertyNames(value).reduce(function (
                        error,
                        key
                      ) {
                        error[key] = value[key];
                        return error;
                      },
                        {});
                    }
                    return value;
                  }

                  if (error.properties && error.properties.errors instanceof Array) {
                    const errorMessages = error.properties.errors
                      .map(function (error) {
                        return error.properties.explanation;
                      })
                      .join("\n");
                    //console.log("errorMessages", errorMessages);
                    objReturn.remarks = "There was an error creating the report."
                    // errorMessages is a humanly readable message looking like this:
                    // 'The tag beginning with "foobar" is unopened'
                  }
                  this.loading = false;
                  throw error;
                }
                const out = doc.getZip().generate({
                  type: "blob",
                  mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                });
                let documentName = "RCF - " + x.registration + " " + x.arrivalDate + " " + x.lastName + " " + x.firstName;
                if (format == 'word') {
                  saveAs(out, documentName + ".docx");
                  objReturn.remarks = "";
                  objReturn.refresh = true;
                  ob.next(objReturn);
                }
                else {
                  let uri;
                  //if (window.origin.indexOf('dev') > -1 || window.origin.indexOf('localhost') > -1)
                  //  uri = 'https://fpipdfconversion-dev.azurewebsites.net/api/ConvertToPdf'
                  //else
                  uri = 'https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf'
                  const functionURI = uri;

                  let headers = new HttpHeaders();
                  const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
                  headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

                  httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {

                    var reader = new FileReader();
                    reader.readAsDataURL(data.body);
                    reader.onloadend = function () {
                      var doc = reader.result;
                      documentName += ".pdf"
                      let taskDocument = new GroundStopTaskDocumentModel();
                      taskDocument.documentName = documentName
                      taskDocument.fileExtension = "pdf";
                      taskDocument.documentSizeInKB = Math.round(data.body.size / 1024);
                      taskDocument.mimeType = "application/pdf";
                      taskDocument.bFile = doc.toString().split(',')[1]; //(<string>reader.result).split(',')[1];
                      taskDocument.groundStopTaskGUID = gstId;
                      taskDocument.tripCode = tripCode;
                      self._groundStopAdvancedService.insertGroundStopTaskDocument<ResponseModel<number>>(taskDocument).subscribe(response => {
                        if (response != null) {
                          if (response.code == "200") {
                            objReturn.remarks = "";
                            objReturn.refresh = true;
                            ob.next(objReturn);
                          }
                        }
                      });
                    }

                  });
                }
              });

            });
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              objReturn.refresh = false;
              objReturn.remarks = "An error occurred generating Remote Clearance Form.";
              ob.next(objReturn);
            }
          }
        }
      });
    });
  }

  exportNotes(serviceTypeDesc: string, notes: string, format: string, docName: string, includePageNo: boolean = false) {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();
      let self = this;

      if (format == "text") {
        this.dynamicDownloadByHtmlTag({
          fileName: docName + ".txt",
          text: notes
        });
        objReturn.remarks = "";
        ob.next(objReturn);
      }
      else {
        let filePath = window.origin;
        if (includePageNo)
          filePath += "/assets/templates/NotesTemplateWithPageNo.docx";
        else
          filePath += "/assets/templates/NotesTemplate.docx";

        loadFile(filePath, function (
          error,
          content
        ) {
          if (error) {
            throw error;
          }
          const zip = new PizZip(content);
          const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser, nullGetter() { return ''; } });
          doc.setData({
            note: notes,
            serviceTypeDesc: serviceTypeDesc
          });
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            function replaceErrors(key, value) {
              if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function (
                  error,
                  key
                ) {
                  error[key] = value[key];
                  return error;
                },
                  {});
              }
              return value;
            }

            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join("\n");
              console.log("errorMessages", errorMessages);
              objReturn.remarks = "There was an error creating the report."
              // errorMessages is a humanly readable message looking like this:
              // 'The tag beginning with "foobar" is unopened'
            }
            this.loading = false;
            throw error;
          }
          const out = doc.getZip().generate({
            type: "blob",
            mimeType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          });
          let documentName = docName;
          if (format == 'word') {
            saveAs(out, documentName + ".docx");
          }
          else {
            let uri;
            //if (window.origin.indexOf('dev') > -1 || window.origin.indexOf('localhost') > -1)
            //  uri = 'https://fpipdfconversion-dev.azurewebsites.net/api/ConvertToPdf'
            //else
            uri = 'https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf'
            const functionURI = uri;

            let headers = new HttpHeaders();
            const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
            headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

            httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {

              //var fileURL = URL.createObjectURL(data.body);
              //window.open(fileURL);
              saveAs(data.body, docName + ".pdf");
            });
          }
          objReturn.remarks = "";
          ob.next(objReturn);
        });
      }
    });

  }

  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  }

  private dynamicDownloadByHtmlTag(arg: {
    fileName: string,
    text: string
  }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }

  generateMexicoAPIS(tripCodeGUID: string, groundStopGUID: string, groundStopTaskGUID: string, tripCode: string): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();

      let request = new ApisManifest();
      request.tripCodeGUID = tripCodeGUID;
      request.groundStopGUID = groundStopGUID;
      request.groundStopTaskGUID = groundStopTaskGUID;
      let self = this;
      this._groundStopAdvancedService.getMexicoApisData<ResponseModel<ApisManifest>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let apis = response.model;
            let origin = apis.departureCity + ', ' + apis.departureCountryCode;
            let destination = apis.arrivalCity + ', ' + apis.arrivalCountryCode;
            let flightDate;
            let flightTime;
            if (apis.departureCountryCode == "MEX") {
              flightDate = apis.departureDateLocal;
              flightTime = apis.departureTimeLocal;
            }
            else {
              flightDate = apis.arrivalDateLocal;
              flightTime = apis.arrivalTimeLocal;
            }
            let registration = apis.registration;
            let workbook = new Workbook();
            loadFile(window.origin + "/assets/templates/MexicoAPISTemplate.xlsx", function (
              error,
              content
            ) {
              if (error) {
                throw error;
              }
              workbook.xlsx.load(content).then(function () {
                const worksheet = workbook.getWorksheet(1);
                //for (let i = 1; i < 13; i++) {
                //  worksheet.getColumn(i).width = worksheet.getColumn(i).width * 1.17;
                //}

                //worksheet.getCell('B4').value = "Fetcha: " + datepipeMEX.transform(new Date(), 'dd \'de\' MMM \'de\' yyyy');

                worksheet.getCell('B13').value = apis.ownerCompany;
                worksheet.getCell('C13').value = apis.operatorCompany;
                //worksheet.getCell('D13').value = apis.farTypeID == 1 ? "Privada" : "Taxi Aereo";
                worksheet.getCell('D13').value = apis.farTypeID == 1 ? "P91" : "P135";
                worksheet.getCell('E13').value = registration;
                if (apis.farTypeID == 2) {
                  worksheet.getCell('F13').value = "ZZ";
                  worksheet.getCell('G13').value = apis.flightNumber;
                }
                worksheet.getCell('H13').value = apis.departureIATA;
                worksheet.getCell('I13').value = apis.arrivalIATA;
                worksheet.getCell('J13').value = new Date(apis.departureDateLocalDateFormat);
                worksheet.getCell('K13').value = new Date(Date.UTC(1899, 11, 30, new Date(apis.departureTimeLocalTimeFormat).getHours(), new Date(apis.departureTimeLocalTimeFormat).getMinutes(), new Date(apis.departureTimeLocalTimeFormat).getSeconds(), 0));
                worksheet.getCell('L13').value = new Date(apis.arrivalDateLocalDateFormat);
                worksheet.getCell('M13').value = new Date(Date.UTC(1899, 11, 30, new Date(apis.arrivalTimeLocalTimeFormat).getHours(), new Date(apis.arrivalTimeLocalTimeFormat).getMinutes(), new Date(apis.arrivalTimeLocalTimeFormat).getSeconds(), 0));
                worksheet.getCell('B17').value = localStorage.getItem('ln');
                worksheet.getCell('C17').value = localStorage.getItem('fn');
                worksheet.getCell('D17').value = "+1 346-415-6900";
                let rowNum = 22;
                if (apis.crewList != null) {
                  apis.crewList.forEach((x, i) => {
                    worksheet.getCell(i + rowNum, 2).value = x.lastName;
                    worksheet.getCell(i + rowNum, 3).value = x.firstName;
                    worksheet.getCell(i + rowNum, 4).value = x.middleName;
                    worksheet.getCell(i + rowNum, 5).value = x.sex;
                    worksheet.getCell(i + rowNum, 6).value = x.tType
                    worksheet.getCell(i + rowNum, 7).value = x.travelerType;
                    worksheet.getCell(i + rowNum, 8).value = new Date(x.dobDateFormat);
                    worksheet.getCell(i + rowNum, 9).value = x.countryOfBirth;
                    worksheet.getCell(i + rowNum, 10).value = x.citizenshipCountry;
                    worksheet.getCell(i + rowNum, 11).value = apis.departureIATA;
                    worksheet.getCell(i + rowNum, 12).value = apis.arrivalIATA;
                    worksheet.getCell(i + rowNum, 13).value = x.apisTravelDocuments[0].documentType;
                    worksheet.getCell(i + rowNum, 14).value = x.apisTravelDocuments[0].documentNumber;
                    worksheet.getCell(i + rowNum, 15).value = new Date(x.apisTravelDocuments[0].documentExpiryDateFormat);
                    worksheet.getCell(i + rowNum, 16).value = x.apisTravelDocuments[0].documentCountryCode;
                    worksheet.getCell(i + rowNum, 17).value = x.residenceCountry;
                    if (x.apisTravelDocuments.length > 1) {
                      worksheet.getCell(i + rowNum, 19).value = x.apisTravelDocuments[1].documentType;
                      worksheet.getCell(i + rowNum, 20).value = x.apisTravelDocuments[1].documentNumber;
                      worksheet.getCell(i + rowNum, 21).value = new Date(x.apisTravelDocuments[1].documentExpiryDateFormat);
                      worksheet.getCell(i + rowNum, 22).value = x.apisTravelDocuments[1].documentCountryCode;
                    }
                  });
                }
                rowNum += apis.crewList.length;
                if (apis.paxList != null) {
                  apis.paxList.forEach((x, i) => {
                    worksheet.getCell(i + rowNum, 2).value = x.lastName;
                    worksheet.getCell(i + rowNum, 3).value = x.firstName;
                    worksheet.getCell(i + rowNum, 4).value = x.middleName;
                    worksheet.getCell(i + rowNum, 5).value = x.sex;
                    worksheet.getCell(i + rowNum, 6).value = x.tType
                    worksheet.getCell(i + rowNum, 8).value = new Date(x.dobDateFormat);
                    worksheet.getCell(i + rowNum, 9).value = x.countryOfBirth;
                    worksheet.getCell(i + rowNum, 10).value = x.citizenshipCountry;
                    worksheet.getCell(i + rowNum, 11).value = apis.departureIATA;
                    worksheet.getCell(i + rowNum, 12).value = apis.arrivalIATA;
                    worksheet.getCell(i + rowNum, 13).value = x.apisTravelDocuments[0].documentType;
                    worksheet.getCell(i + rowNum, 14).value = x.apisTravelDocuments[0].documentNumber;
                    worksheet.getCell(i + rowNum, 15).value = new Date(x.apisTravelDocuments[0].documentExpiryDateFormat);
                    worksheet.getCell(i + rowNum, 16).value = x.apisTravelDocuments[0].documentCountryCode;
                    worksheet.getCell(i + rowNum, 17).value = x.residenceCountry;
                    if (x.apisTravelDocuments.length > 1) {
                      worksheet.getCell(i + rowNum, 19).value = x.apisTravelDocuments[1].documentType;
                      worksheet.getCell(i + rowNum, 20).value = x.apisTravelDocuments[1].documentNumber;
                      worksheet.getCell(i + rowNum, 21).value = new Date(x.apisTravelDocuments[1].documentExpiryDateFormat);
                      worksheet.getCell(i + rowNum, 22).value = x.apisTravelDocuments[1].documentCountryCode;
                    }
                  });
                }
                const options = {
                  useStyles: true,
                  useSharedStrings: true
                };
                workbook.xlsx.writeBuffer(options).then((data) => {
                  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                  let fileName = apis.registration + ' Mexico APIS ' + apis.departureICAO + '-' + apis.arrivalICAO + ' ' + datepipeMEX.transform(new Date(flightDate.replace(/-/g, '\/')), 'dd MMM').replace('.', '') + '.xlsx';
                  //saveAs(blob, fileName.toUpperCase());
                  var reader = new FileReader();
                  reader.readAsDataURL(blob);
                  reader.onloadend = function () {
                    var doc = reader.result;
                    let taskDocument = new GroundStopTaskDocumentModel();
                    taskDocument.documentName = fileName.toUpperCase();
                    taskDocument.fileExtension = "xlsx";
                    taskDocument.documentSizeInKB = Math.round(blob.size / 1024);
                    taskDocument.mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                    taskDocument.bFile = doc.toString().split(',')[1]; //(<string>reader.result).split(',')[1];
                    taskDocument.groundStopTaskGUID = groundStopTaskGUID;
                    taskDocument.tripCode = tripCode;
                    self._groundStopAdvancedService.insertGroundStopTaskDocument<ResponseModel<number>>(taskDocument).subscribe(response => {
                      if (response != null) {
                        if (response.code == "200") {
                          objReturn.remarks = "";
                          objReturn.refresh = true;
                          ob.next(objReturn);
                        }
                      }
                    });
                  }
                  objReturn.refresh = true;
                  objReturn.remarks = "";
                  ob.next(objReturn);
                });

              });
            });


          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              objReturn.refresh = false;
              objReturn.remarks = "An error occurred generating Mexico APIS.";
              ob.next(objReturn);
            }
          }
        }
      });


    });
  }

  generateEcuadorOverflight(tripCodeGUID: string, groundStopGUID: string): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();

      let request = new PermitsModel();
      request.tripCodeGUID = tripCodeGUID;
      request.groundStopGUID = groundStopGUID;
      let self = this;
      this._groundStopAdvancedService.getDataForEcuadorOverflight<ResponseModel<PermitsModel>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let permit = response.model;

            let workbook = new Workbook();
            loadFile(window.origin + "/assets/templates/EcuadorOverflightTemplate.xlsx", function (
              error,
              content
            ) {
              if (error) {
                throw error;
              }
              workbook.xlsx.load(content).then(function () {
                const worksheet = workbook.getWorksheet(1);


                worksheet.getCell('D15').value = permit.aircraftOperator;
                worksheet.getCell('D17').value = permit.operatorPhone;
                worksheet.getCell('N17').value = permit.operatorEmail;
                worksheet.getCell('D19').value = permit.operatorAddress;
                worksheet.getCell('D21').value = permit.managerName;
                worksheet.getCell('N21').value = permit.managerPhone;
                worksheet.getCell('D24').value = permit.aircraftType;
                worksheet.getCell('O24').value = permit.callSign;
                worksheet.getCell('D26').value = permit.registration;
                worksheet.getCell('O26').value = permit.mtow;
                worksheet.getCell('D28').value = permit.flightDate;
                worksheet.getCell('D30').value = permit.departureCity + "/" + permit.departureICAO + " - " + permit.arrivalCity + "/" + permit.arrivalICAO;
                worksheet.getCell('D31').value = permit.route;

                workbook.xlsx.writeBuffer().then((data) => {
                  let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                  let fileName = permit.registration + ' Ecuador Overflight ' + permit.departureICAO + '-' + permit.arrivalICAO + ' ' + permit.flightDate + '.xlsx';
                  saveAs(blob, fileName.toUpperCase());
                  //var reader = new FileReader();
                  //reader.readAsDataURL(blob);
                  //reader.onloadend = function () {
                  //  var doc = reader.result;
                  //  let taskDocument = new GroundStopTaskDocumentModel();
                  //  taskDocument.documentName = fileName.toUpperCase();
                  //  taskDocument.fileExtension = "xlsx";
                  //  taskDocument.documentSizeInKB = Math.round(blob.size / 1024);
                  //  taskDocument.mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                  //  taskDocument.bFile = doc.toString().split(',')[1]; //(<string>reader.result).split(',')[1];
                  //  taskDocument.groundStopTaskGUID = "";
                  //  taskDocument.tripCode = "";
                  //  self._groundStopAdvancedService.insertGroundStopTaskDocument<ResponseModel<number>>(taskDocument).subscribe(response => {
                  //    if (response != null) {
                  //      if (response.code == "200") {
                  //        objReturn.remarks = "";
                  //        objReturn.refresh = true;
                  //        ob.next(objReturn);
                  //      }
                  //    }
                  //  });
                  //}
                  objReturn.refresh = true;
                  objReturn.remarks = "";
                  ob.next(objReturn);
                });

              });
            });


          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              objReturn.refresh = false;
              objReturn.remarks = "An error occurred generating template.";
              ob.next(objReturn);
            }
          }
        }
      });


    });
  }

  generateNicaraguaOverflight(tripCodeGUID: string, groundStopGUID: string): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();

      let request = new PermitsModel();
      request.tripCodeGUID = tripCodeGUID;
      request.groundStopGUID = groundStopGUID;
      let self = this;
      this._groundStopAdvancedService.getDataForNicaraguaOverflight<ResponseModel<PermitsModel>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let permit = response.model;
            let filePath = window.origin + "/assets/templates/NicaraguaPermitRequest.docx";
            let captainName;
            let captainPhone;
            let i = permit.crewManifest.findIndex(x => x.isPIC == true);
            if (i != undefined) {
              captainName = permit.crewManifest[i].firstName + " " + permit.crewManifest[i].lastName;
              captainPhone = permit.crewManifest[i].phone;
            }
            loadFile(filePath, function (
              error,
              content
            ) {
              if (error) {
                throw error;
              }
              const zip = new PizZip(content);
              const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser, nullGetter() { return ''; } });
              doc.setData({
                currentDate: datepipe.transform(now(), 'dd MMM yyyy'),
                currentTime: datepipe.transform(now(), 'hh:mm'),
                registration: permit.registration,
                aircraftType: permit.aircraftType,
                beaconCode: permit.eltBeaconCode,
                flightDate: permit.flightDate,
                mtow: permit.mtow,
                departureDate: permit.departureDate,
                departureTime: permit.departureTime,
                departureICAO: permit.departureICAO,
                departureAirportName: permit.departureAirportName,
                arrivalTime: permit.arrivalTime,
                arrivalICAO: permit.arrivalICAO,
                arrivalAirportName: permit.arrivalAirportName,
                altArrivalICAO: permit.alternateICAO,
                altArrivalAirport: permit.alternateAirportName,
                operatorName: permit.aircraftOperator,
                crew: permit.crewManifest,
                pax: permit.paxManifest,
                captainName: captainName,
                captainPhone: captainPhone,
                flightType: permit.farTypeID == 1 ? 'Private' : 'Charter'
              });
              try {
                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                doc.render();
              } catch (error) {
                // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                function replaceErrors(key, value) {
                  if (value instanceof Error) {
                    return Object.getOwnPropertyNames(value).reduce(function (
                      error,
                      key
                    ) {
                      error[key] = value[key];
                      return error;
                    },
                      {});
                  }
                  return value;
                }

                if (error.properties && error.properties.errors instanceof Array) {
                  const errorMessages = error.properties.errors
                    .map(function (error) {
                      return error.properties.explanation;
                    })
                    .join("\n");
                  //console.log("errorMessages", errorMessages);
                  objReturn.remarks = "There was an error creating the report."
                  // errorMessages is a humanly readable message looking like this:
                  // 'The tag beginning with "foobar" is unopened'
                }
                this.loading = false;
                throw error;
              }
              const out = doc.getZip().generate({
                type: "blob",
                mimeType:
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              });
              let documentName = "Nicaragua Permit Request - " + permit.departureICAO + '-' + permit.arrivalICAO + ' ' + permit.flightDate + ".docx";

              saveAs(out, documentName);
              objReturn.remarks = "";
              objReturn.refresh = true;
              ob.next(objReturn);


            });


          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              objReturn.refresh = false;
              objReturn.remarks = "An error occurred generating template.";
              ob.next(objReturn);
            }
          }
        }
      });


    });
  }


  generateBoliviaOverflight(tripCodeGUID: string, groundStopGUID: string): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();

      let request = new PermitsModel();
      request.tripCodeGUID = tripCodeGUID;
      request.groundStopGUID = groundStopGUID;
      let self = this;
      this._groundStopAdvancedService.getDataForBoliviaOverflight<ResponseModel<PermitsModel>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let permit = response.model;
            let filePath = window.origin + "/assets/templates/BoliviaPermitRequest.docx";
            loadFile(filePath, function (
              error,
              content
            ) {
              if (error) {
                throw error;
              }
              const zip = new PizZip(content);
              const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser, nullGetter() { return ''; } });
              doc.setData({
                currentDate: datepipe.transform(now(), 'dd/MM/yyyy'),
                registration: permit.registration,
                aircraftType: permit.aircraftType,
                flightDate: permit.flightDate,
                mtow: permit.mtow,
                departureDate: permit.departureDate,
                departureTime: permit.departureTime,
                departureICAO: permit.departureICAO,
                departureAirportName: permit.departureAirportName,
                arrivalDate: permit.arrivalDate,
                arrivalTime: permit.arrivalTime,
                arrivalICAO: permit.arrivalICAO,
                arrivalAirportName: permit.arrivalAirportName,
                altArrivalICAO: permit.alternateICAO,
                altArrivalAirport: permit.alternateAirportName,
                operatorName: permit.aircraftOperator,
                operatorAddress: permit.operatorAddress,
                aircraftCountry: permit.aircraftCountry,
                ete: permit.ete,
                flightType: permit.farTypeID == 1 ? 'PRIVADO' : 'CHARTER (PASAJEROS Y/O CARGA)'

              });
              try {
                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                doc.render();
              } catch (error) {
                // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                function replaceErrors(key, value) {
                  if (value instanceof Error) {
                    return Object.getOwnPropertyNames(value).reduce(function (
                      error,
                      key
                    ) {
                      error[key] = value[key];
                      return error;
                    },
                      {});
                  }
                  return value;
                }

                if (error.properties && error.properties.errors instanceof Array) {
                  const errorMessages = error.properties.errors
                    .map(function (error) {
                      return error.properties.explanation;
                    })
                    .join("\n");
                  //console.log("errorMessages", errorMessages);
                  objReturn.remarks = "There was an error creating the report."
                  // errorMessages is a humanly readable message looking like this:
                  // 'The tag beginning with "foobar" is unopened'
                }
                this.loading = false;
                throw error;
              }
              const out = doc.getZip().generate({
                type: "blob",
                mimeType:
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              });
              let documentName = "Bolivia Permit Request - " + permit.departureICAO + '-' + permit.arrivalICAO + ' ' + permit.flightDate + ".docx";

              saveAs(out, documentName);
              objReturn.remarks = "";
              objReturn.refresh = true;
              ob.next(objReturn);


            });


          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              objReturn.refresh = false;
              objReturn.remarks = "An error occurred generating template.";
              ob.next(objReturn);
            }
          }
        }
      });


    });
  }


  generateHondurasOverflight(tripCodeGUID: string, groundStopGUID: string, serviceTypeID: number): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();

      let request = new PermitsModel();
      request.tripCodeGUID = tripCodeGUID;
      request.groundStopGUID = groundStopGUID;
      let self = this;
      this._groundStopAdvancedService.getDataForHondurasOverflight<ResponseModel<PermitsModel>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let permit = response.model;
            let filePath = window.origin + "/assets/templates/HondurasPermitRequest.docx";
            loadFile(filePath, function (
              error,
              content
            ) {
              if (error) {
                throw error;
              }
              const zip = new PizZip(content);
              const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser, nullGetter() { return ''; } });
              doc.setData({
                currentDate: datepipe.transform(now(), 'dd MMM yyyy'),
                currentTime: datepipe.transform(now(), 'hh:mm'),
                registration: permit.registration,
                aircraftType: permit.aircraftType,
                flightDate: serviceTypeID == 3 ? permit.departureDate : permit.arrivalDate,
                mtow: permit.mtow,
                departureDate: serviceTypeID == 3 ? permit.departureDate : permit.priorDepartureDate,
                departureTime: serviceTypeID == 3 ? permit.departureTime : permit.priorDepartureTime,
                departureICAO: serviceTypeID == 3 ? permit.departureICAO : permit.priorDepartureICAO,
                departureCity: serviceTypeID == 3 ? permit.departureCity : permit.priorDepartureCity,
                arrivalDate: serviceTypeID == 3 ? permit.nextArrivalDate : permit.arrivalDate,
                arrivalTime: serviceTypeID == 3 ? permit.nextArrivalTime : permit.arrivalTime,
                arrivalICAO: serviceTypeID == 3 ? permit.nextArrivalICAO : permit.arrivalICAO,
                arrivalCity: serviceTypeID == 3 ? permit.nextArrivalCity : permit.arrivalCity,
                nextArrival: serviceTypeID == 5 ? permit.nextArrivalCity + ' / ' + permit.nextArrivalICAO : '',
                nextArrivalDate: serviceTypeID == 5 ? permit.nextArrivalDate : '',
                aircraftOwner: permit.aircraftOperator,
                pilotName: permit.pilotName,
                pilotLicense: permit.pilotLicense,
                paxCount: permit.paxCount,
                paxNationalityList: permit.paxNationalityList,
                flightType: permit.farTypeID == 1 ? 'Private' : 'Charter',
                ownerAddress: permit.operatorAddress == '' || permit.operatorAddress == null ? '               ' : permit.operatorAddress,
                ownerPhone: permit.operatorPhone == '' || permit.operatorPhone == null ? '               ' : permit.operatorPhone
              });
              try {
                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                doc.render();
              } catch (error) {
                // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                function replaceErrors(key, value) {
                  if (value instanceof Error) {
                    return Object.getOwnPropertyNames(value).reduce(function (
                      error,
                      key
                    ) {
                      error[key] = value[key];
                      return error;
                    },
                      {});
                  }
                  return value;
                }

                if (error.properties && error.properties.errors instanceof Array) {
                  const errorMessages = error.properties.errors
                    .map(function (error) {
                      return error.properties.explanation;
                    })
                    .join("\n");
                  //console.log("errorMessages", errorMessages);
                  objReturn.remarks = "There was an error creating the report."
                  // errorMessages is a humanly readable message looking like this:
                  // 'The tag beginning with "foobar" is unopened'
                }
                this.loading = false;
                throw error;
              }
              const out = doc.getZip().generate({
                type: "blob",
                mimeType:
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              });
              let documentName = "Honduras Permit Request - ";
              if (serviceTypeID == 3)
                documentName += permit.departureICAO + '-' + permit.nextArrivalICAO + ' ' + permit.departureDate + ".docx";
              else
                documentName += permit.priorDepartureICAO + '-' + permit.arrivalICAO + ' ' + permit.arrivalDate + ".docx";
              saveAs(out, documentName);
              objReturn.remarks = "";
              objReturn.refresh = true;
              ob.next(objReturn);


            });


          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              objReturn.refresh = false;
              objReturn.remarks = "An error occurred generating template.";
              ob.next(objReturn);
            }
          }
        }
      });


    });
  }

  generateCubaOverflight(tripCodeGUID: string, groundStopGUID: string): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();

      let request = new PermitsModel();
      request.tripCodeGUID = tripCodeGUID;
      request.groundStopGUID = groundStopGUID;
      let self = this;
      this._groundStopAdvancedService.getDataForEcuadorOverflight<ResponseModel<PermitsModel>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let permit = response.model;
            let hasCallSign = false;
            if (permit.callSign != null && permit.callSign != "" && permit.callSign != permit.registration)
              hasCallSign = true;
            let filePath = window.origin + "/assets/templates/CubaOverflightRequest.docx";
            loadFile(filePath, function (
              error,
              content
            ) {
              if (error) {
                throw error;
              }
              const zip = new PizZip(content);
              const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser, nullGetter() { return ''; } });
              doc.setData({
                currentDate: datepipe.transform(now(), 'dd MMM yyyy'),
                registration: permit.registration,
                hasCallSign: hasCallSign,
                callSign: permit.callSign,
                aircraftType: permit.aircraftType,
                depDate: permit.departureDate == permit.arrivalDate ? permit.departureDate : '',
                depICAO: permit.departureICAO,
                arrDate: permit.departureDate == permit.arrivalDate ? permit.arrivalDate : '',
                arrICAO: permit.arrivalICAO,
                dow: permit.departureDate == permit.arrivalDate ? permit.dow : '',
                customerName: permit.aircraftOperator,

              });
              try {
                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                doc.render();
              } catch (error) {
                // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                function replaceErrors(key, value) {
                  if (value instanceof Error) {
                    return Object.getOwnPropertyNames(value).reduce(function (
                      error,
                      key
                    ) {
                      error[key] = value[key];
                      return error;
                    },
                      {});
                  }
                  return value;
                }

                if (error.properties && error.properties.errors instanceof Array) {
                  const errorMessages = error.properties.errors
                    .map(function (error) {
                      return error.properties.explanation;
                    })
                    .join("\n");
                  //console.log("errorMessages", errorMessages);
                  objReturn.remarks = "There was an error creating the report."
                  // errorMessages is a humanly readable message looking like this:
                  // 'The tag beginning with "foobar" is unopened'
                }
                this.loading = false;
                throw error;
              }
              const out = doc.getZip().generate({
                type: "blob",
                mimeType:
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              });
              let documentName = "Overflight-FIR Cuba - " + permit.departureICAO + '-' + permit.arrivalICAO + ' ' + permit.flightDate + ".docx";

              saveAs(out, documentName);
              objReturn.remarks = "";
              objReturn.refresh = true;
              ob.next(objReturn);


            });


          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              objReturn.refresh = false;
              objReturn.remarks = "An error occurred generating template.";
              ob.next(objReturn);
            }
          }
        }
      });


    });
  }


  generateFuelQuote(fuel: FuelTaskModel[], gsInfo: GroundStopModel, reportID: string, format: string = 'pdf'): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();
      let self = this;
      let filePath;
      if (fuel.length == 0)
        filePath = window.origin + "/assets/templates/FuelQuoteBlank.docx";
      else
        filePath = window.origin + "/assets/templates/FuelQuote.docx";
      loadFile(filePath, function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser, nullGetter() { return ''; } });
        doc.setData({
          gsInfo: gsInfo,
          reportDate: datepipe.transform(now(), 'dd MMM yyyy'),
          reportID: reportID,
          hasDomestic: Number(fuel[0]?.totalPrice) != Number(fuel[0]?.totalPriceDomestic) ? true : false,
          fuel: fuel,
          taxes: fuel[0]?.taxesAndFeesList

        });
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
                {});
            }
            return value;
          }

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            //console.log("errorMessages", errorMessages);
            objReturn.remarks = "There was an error creating the report."
            // errorMessages is a humanly readable message looking like this:
            // 'The tag beginning with "foobar" is unopened'
          }
          this.loading = false;
          throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        let documentName = "FuelQuote.docx";


        if (format == 'word') {
          saveAs(out, documentName);
          objReturn.remarks = "";
          ob.next(objReturn);
        }
        else {
          let uri;
          //if (window.origin.indexOf('dev') > -1 || window.origin.indexOf('localhost') > -1)
          //  uri = 'https://fpipdfconversion-dev.azurewebsites.net/api/ConvertToPdf'
          //else
          uri = 'https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf'
          const functionURI = uri;

          let headers = new HttpHeaders();
          const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
          headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

          httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {
            var reader = new FileReader();
            reader.readAsDataURL(data.body);
            reader.onloadend = function () {
              var doc = reader.result;
              objReturn.docBFile = doc.toString().split(',')[1];
              objReturn.docSize = Math.round(data.body.size / 1024);
              objReturn.remarks = "";
              ob.next(objReturn);
            }
            var fileURL = URL.createObjectURL(data.body);
            window.open(fileURL);
          });
        }
      });



    });
  }

  generateCanpass(tripCodeGUID: string, groundStopGUID: string, gstId: string, format: string, tripCode: string): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();

      let request = new PermitsModel();
      request.tripCodeGUID = tripCodeGUID;
      request.groundStopGUID = groundStopGUID;
      let self = this;
      this._groundStopAdvancedService.getDataForCanpass<ResponseModel<PermitsModel>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let permit = response.model;
            let filePath = window.origin + "/assets/templates/Canpass.docx";
            loadFile(filePath, function (
              error,
              content
            ) {
              if (error) {
                throw error;
              }
              const zip = new PizZip(content);
              const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser, nullGetter() { return ''; } });
              doc.setData({
                registration: permit.registration,
                aircraftType: permit.aircraftType,
                departureICAO: permit.departureICAO,
                arrivalDate: permit.arrivalDate,
                arrivalTime: permit.arrivalTime,
                arrivalICAO: permit.arrivalICAO,
                operatorName: permit.aircraftOperator,
                handler: permit.handlerName,
                crew: permit.crewManifest,
                pax: permit.paxManifest
              });
              try {
                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                doc.render();
              } catch (error) {
                // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                function replaceErrors(key, value) {
                  if (value instanceof Error) {
                    return Object.getOwnPropertyNames(value).reduce(function (
                      error,
                      key
                    ) {
                      error[key] = value[key];
                      return error;
                    },
                      {});
                  }
                  return value;
                }

                if (error.properties && error.properties.errors instanceof Array) {
                  const errorMessages = error.properties.errors
                    .map(function (error) {
                      return error.properties.explanation;
                    })
                    .join("\n");
                  //console.log("errorMessages", errorMessages);
                  objReturn.remarks = "There was an error creating the report."
                  // errorMessages is a humanly readable message looking like this:
                  // 'The tag beginning with "foobar" is unopened'
                }
                this.loading = false;
                throw error;
              }
              const out = doc.getZip().generate({
                type: "blob",
                mimeType:
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              });
              let documentName = "Canpass - " + permit.registration + ' ' + permit.departureICAO + '-' + permit.arrivalICAO + ' ' + permit.arrivalDate;
              if (format == 'word') {
                saveAs(out, documentName + ".docx");
                objReturn.remarks = "";
                objReturn.refresh = true;
                ob.next(objReturn);
              }
              else {
                let uri;
                //if (window.origin.indexOf('dev') > -1 || window.origin.indexOf('localhost') > -1)
                //  uri = 'https://fpipdfconversion-dev.azurewebsites.net/api/ConvertToPdf'
                //else
                uri = 'https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf'
                const functionURI = uri;

                let headers = new HttpHeaders();
                const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
                headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

                httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {

                  var reader = new FileReader();
                  reader.readAsDataURL(data.body);
                  reader.onloadend = function () {
                    var doc = reader.result;
                    documentName += ".pdf"
                    let taskDocument = new GroundStopTaskDocumentModel();
                    taskDocument.documentName = documentName
                    taskDocument.fileExtension = "pdf";
                    taskDocument.documentSizeInKB = Math.round(data.body.size / 1024);
                    taskDocument.mimeType = "application/pdf";
                    taskDocument.bFile = doc.toString().split(',')[1]; //(<string>reader.result).split(',')[1];
                    taskDocument.groundStopTaskGUID = gstId;
                    taskDocument.tripCode = tripCode;
                    self._groundStopAdvancedService.insertGroundStopTaskDocument<ResponseModel<number>>(taskDocument).subscribe(response => {
                      if (response != null) {
                        if (response.code == "200") {
                          objReturn.remarks = "";
                          objReturn.refresh = true;
                          ob.next(objReturn);
                        }
                      }
                    });
                  }

                });
              }

            });


          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              objReturn.refresh = false;
              objReturn.remarks = "An error occurred generating template.";
              ob.next(objReturn);
            }
          }
        }
      });


    });
  }

  generateFuelQuoteSummary(fuel: FuelQuoteSummary[], remarks: string, reportID: string, format: string = ' pdf'): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(ob => {
      let objReturn = new ReturnObjModel();
      let self = this;


      let filePath = window.origin + "/assets/templates/FuelQuoteSummary.docx";
      loadFile(filePath, function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser, nullGetter() { return ''; } });
        doc.setData({
          reportDate: datepipe.transform(now(), 'dd MMM yyyy'),
          reportID: reportID,
          fuel: fuel,
          remarks: remarks,
          hasRemarks: remarks == "" ? false : true
        });
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
                {});
            }
            return value;
          }

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            //console.log("errorMessages", errorMessages);
            objReturn.remarks = "There was an error creating the report."
            // errorMessages is a humanly readable message looking like this:
            // 'The tag beginning with "foobar" is unopened'
          }
          this.loading = false;
          throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        let documentName = "FuelQuoteSummary - " + Array.prototype.map.call(fuel, s => s.gsInfo.icao).toString().replace(/,/g, "-");


        if (format == 'word') {
          saveAs(out, documentName + ".docx");
          objReturn.remarks = "";
          ob.next(objReturn);
        }
        else {
          let uri;
          //if (window.origin.indexOf('dev') > -1 || window.origin.indexOf('localhost') > -1)
          //  uri = 'https://fpipdfconversion-dev.azurewebsites.net/api/ConvertToPdf'
          //else
          uri = 'https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf'
          const functionURI = uri;

          let headers = new HttpHeaders();
          const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
          headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

          httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {
            var reader = new FileReader();
            reader.readAsDataURL(data.body);
            reader.onloadend = function () {
              var doc = reader.result;
              objReturn.docBFile = doc.toString().split(',')[1];
              objReturn.docSize = Math.round(data.body.size / 1024);
              objReturn.remarks = "";
              ob.next(objReturn);
            }
            var fileURL = URL.createObjectURL(data.body);
            window.open(fileURL);
          });
        }
      });

    });
  }

  generateTripPlannerBrief(format: string, briefType: string, vendorDetails: VendorModel, relatedVendorDetails: VendorModel, vendorHours: VendorHoursModel[], customerName: string, farType: string, selectedAirport: AirportModel, advisoryList: AdvisoryModel[]): Observable<ReturnObjModel> {
    this._authService.updateAccessTime();
    return new Observable<ReturnObjModel>(y => {
      let objReturn = new ReturnObjModel();

      let handlingNotes = vendorDetails;
      let hasRelatedVendor = false;
      let relatedVendors;
      let hasVendor = true;
      if (vendorDetails == null || vendorDetails == undefined)
        hasVendor = false;
      if (relatedVendorDetails != undefined) {
        if (relatedVendorDetails != null) {
          relatedVendors = relatedVendorDetails;

          if (relatedVendors?.vendorPersons.length > 0) {
            relatedVendors.vendorPersons = relatedVendors.vendorPersons.filter(x => x.includeInBrief == true);
            relatedVendors.vendorPersons.forEach(x => {
              x.personCommsAddress = x.personCommsAddress.filter(y => y.includeInBrief == true);
            });
          }
          hasRelatedVendor = true;
        }
      }
      let persons = handlingNotes?.vendorPersons;
      if (briefType == "client") {
        if (persons != null) {
          persons = persons.filter(v => v.includeInBrief);
          persons.forEach(v => {
            v.personCommsAddress = v.personCommsAddress.filter(x => x.includeInBrief);
          });
        }
      }
      let hasHours = false;
      let hoursText = "";
      if (vendorHours != null) {
        hasHours = true;
        if (vendorHours[0].is24Hours)
          hoursText = "24 hours";
        else {
          if (vendorHours[0].sameHours)
            hoursText = vendorHours[0].openTime + " - " + vendorHours[0].closeTime + "Daily";
        }
      }

      var self = this;
      let filePath = window.origin + "/assets/templates/TripPlannerTemplate.docx";

      loadFile(filePath, function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true, parser: parser, nullGetter() { return ''; } });
        let reportDate = datepipe.transform(new Date().toUTCString(), 'MMM-dd-yyyy HH:mm', "UTC");
        doc.setData({
          hasVendor: hasVendor,
          selectedAirport: selectedAirport,
          hasRelatedVendor: hasRelatedVendor,
          relatedVendorDetails: relatedVendors,
          hasHours: hasHours,
          hoursText: hoursText,
          vendorHours: vendorHours,
          vendorName: handlingNotes?.vendorName,
          vendorPerson: persons,
          vendorComms: handlingNotes?.vendorComms,
          customsNotes: handlingNotes?.customsNotes,
          handlingNotes: handlingNotes?.handlingNotes,
          reportDate: reportDate,
          star: handlingNotes?.isPreferred,
          slots: selectedAirport.isRequiredSlots == null ? '-' : selectedAirport.isRequiredSlots == 1 ? 'Yes' : selectedAirport.isRequiredSlots == 2 ? 'See Notes' : 'No',
          ppr: selectedAirport.isRequiredSlots == null ? '-' : selectedAirport.isRequiredPPR == 1 ? 'Yes' : selectedAirport.isRequiredPPR == 2 ? 'See Notes' : 'No',
          advisories: advisoryList,
          customerName: customerName,
          farType: farType,
          reportID: UtilityFunctions.makeID(10).toUpperCase()

        });
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
                {});
            }
            return value;
          }

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);

            objReturn.remarks = "There was an error creating the report."
            y.next(objReturn);
            // errorMessages is a humanly readable message looking like this:
            // 'The tag beginning with "foobar" is unopened'
          }
          this.loading = false;
          throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        let docName = "AirportBrief - "
        if (customerName != "")
          docName += customerName + " - ";
        docName += selectedAirport.icao;
        if (format == 'word') {
          saveAs(out, docName + ".docx");
          objReturn.remarks = "";
          y.next(objReturn);
        }
        else {

          let uri;
          if (window.origin.indexOf('dev') > -1 || window.origin.indexOf('localhost') > -1)
            uri = "https://fpipdfconversion-staging.azurewebsites.net/api/ConvertToPdf";
          else
            uri = "https://fpipdfconversion.azurewebsites.net/api/ConvertToPdf";
          const functionURI = uri;

          let headers = new HttpHeaders();
          const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
          headers.append('Content-Type', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');

          httpClient.post(functionURI, out, { observe: 'response', responseType: 'blob' }).subscribe(data => {
            var reader = new FileReader();
            reader.readAsDataURL(data.body);
            reader.onloadend = function () {
              var doc = reader.result;
              saveAs(data.body, docName + ".pdf");
              objReturn.docBFile = doc.toString().split(',')[1];
              objReturn.docSize = Math.round(data.body.size / 1024);
              objReturn.remarks = "";
              y.next(objReturn);
            }
            //var fileURL = URL.createObjectURL(data.body);
            //window.open(fileURL);

          }), catchError((error: HttpErrorResponse) => {
            this.catchMyError(error);
            return throwError(error.message)
          });
        }

      });

    });

  }
}
