import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first, take, takeUntil } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
//import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
//import { CommAddressTypeModel } from '../models/comm-address-type.model';
//import { AddressRatingModel } from '../models/address-rating.model';
//import { AddressUseModel } from '../models/address-use.model';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { PersonTravelDocSelectionDialogComponent } from './person-travel-doc-selection-dialog.component';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopService } from '../services/ground-stop.service';
import { TripTaskStatusModel } from '../models/trip-task-status.model';
import { AccountingStatusModel } from '../models/accounting-status.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';

import { FileItem, FileUploader, ParsedResponseHeaders, FileUploadModule, FileSelectDirective } from 'ng2-file-upload';
import { FileModel } from '../models/file.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';
import { CountryModel } from '../models/country.model';
import { VendorModel } from '../models/vendor.model';
import { HotelModel } from '../models/hotel.model';
import { GroundStopTaskDocumentModel } from '../models/ground-stop-task-document.model';
import { HotelEditDialogComponent } from '../hotels/hotel-edit-dialog.component';
import { GenerateTaskDocumentsComponent } from './generate-task-documents.component';
import { ServiceArrangementModel } from '../models/service-arrangement.model';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from 'file-saver';
import { VendorContactModel } from '../models/vendor-contact.model';
import { HandlingNotesModel } from '../models/handling-notes.model';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
import { VendorService } from '../services/vendor.service';
import { UploadTaskDocument } from './upload-task-document';
import { GenerateMessageTemplateComponent } from '../ground-stops/generate-message-template.component';
import { TripAuditDialogComponent } from '../ground-stops/trip-audit-dialog.component';
import { FlightBriefModel } from '../models/flight-brief.model';
import { WeatherChartsDialogComponent } from '../flight-brief/weather-charts-dialog.component';
import { CompileFlightBriefDialogComponent } from '../flight-brief/compile-flight-brief-dialog.component';
import { DatePipe } from '@angular/common';
import { DeliveryCommsDialogComponent } from './delivery-comms-dialog.component';
import { FlightBriefSectionsComponent } from '../flight-brief/flight-brief-sections.component';
import { DocumentModel } from '../models/document.model';
import { BuildFlightBriefDialogComponent } from '../flight-brief/build-flight-brief-dialog.component';
import { VendorProfileEditDialogComponent } from '../vendors/vendor-profile-edit-dialog.component';
import { GroundStopAlternateAirportEditDialogComponent } from './ground-stop-alternate-airport-edit-dialog.component';
import { HoursObjModel } from '../models/hoursObj.model';
import { VendorHoursModel } from '../models/vendor-hours.model';
import { GroundStopAlternateAirportModel } from '../models/ground-stop-alternate-airport.model';
import { TemplateService } from '../services/template.service';
import { BusinessRulesModel } from '../models/business-rules.model';
import { BusinessRulesEditComponent } from '../business-rules/business-rules-edit.component';
import { BusinessRulesService } from '../services/business-rules-service';
import { EmailInternalDialogComponent } from '../messages/email-internal-dialog.component';
import { EmailAddressSelectionDialogComponent } from '../messages/email-address-selection-dialog.component';
import { GenerateApis135Component } from '../apis/generate-apis-135.component';
import { GenerateApis91Component } from '../apis/generate-apis-91.component';
import { GenerateApisCaricomComponent } from '../apis/generate-apis-caricom.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { FeedbackDialogComponent } from '../common-utility/feedback-dialog.component';
import { TripMessageCenterComponent } from '../ground-stops/trip-message-center.component';
import { ReturnObjModel } from '../models/return-obj.model';

const datepipe: DatePipe = new DatePipe('en-US')

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}
var expressions = require("angular-expressions");
var assign = require("lodash/assign");
// define your filter functions here, for example
// to be able to write {clientname | lower}
expressions.filters.lower = function (input) {
  // This condition should be used to make sure that if your input is
  // undefined, your output will be undefined as well and will not
  // throw an error
  if (!input) return input;
  return input.toLowerCase();
};
function angularParser(tag) {
  tag = tag
    .replace(/^\.$/, "this")
    .replace(/(�|�)/g, "'")
    .replace(/(�|�)/g, '"');
  const expr = expressions.compile(tag);
  return {
    get: function (scope, context) {
      let obj = {};
      const scopeList = context.scopeList;
      const num = context.num;
      for (let i = 0, len = num + 1; i < len; i++) {
        obj = assign(obj, scopeList[i]);
      }
      return expr(scope, obj);
    },
  };
}

function parser(tag) {
  const lowerRegex = /\[lower\]$/;
  const upperRegex = /\[upper\]$/;
  let changeCase = "";
  if (lowerRegex.test(tag)) {
    changeCase = "lower";
    // transform tag from "user[lower]" to "user"
    tag = tag.replace(lowerRegex, "");
  }
  if (upperRegex.test(tag)) {
    changeCase = "upper";
    // transform tag from "user[upper]" to "user"
    tag = tag.replace(upperRegex, "");
  }
  if (changeCase != "") {
    return {
      get: function (scope) {
        let result = null;
        // scope will be {user: "John"}
        if (tag === ".") {
          result = scope;
        } else {
          // Here we use the property "user" of the object {user: "John"}
          result = scope[tag];
        }

        if (typeof result === "string") {
          if (changeCase === "upper") {
            return result.toUpperCase();
          } else if (changeCase === "lower") {
            return result.toLowerCase();
          }
        }
        return result;
      }
    }
  }
  if (tag === "$pageBreakExceptLast") {
    return {
      get(scope, context) {
        const totalLength =
          context.scopePathLength[
          context.scopePathLength.length - 1
          ];
        const index =
          context.scopePathItem[
          context.scopePathItem.length - 1
          ];
        const isLast = index === totalLength - 1;
        if (!isLast) {
          return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';
        } else {
          return "";
        }
      },
    };
  }
  if (tag === "$pageBreak") {
    return {
      get(scope, context) {
        return '<w:p><w:r><w:br w:type="page"/></w:r></w:p>';

      },
    };
  }



  // We use the angularParser as the default fallback
  // If you don't wish to use the angularParser,
  // you can use the default parser as documented here:
  // https://docxtemplater.com/docs/configuration#default-parser
  return angularParser(tag);
}

@Component({
  selector: 'app-ground-stop-task-edit-dialog',
  templateUrl: './ground-stop-task-edit-dialog.component.html',
  styleUrls: ['./ground-stop-task-edit-dialog.component.css']
})

export class GroundStopTaskEditDialogComponent implements OnInit {

  dialogTitle: string;
  customerGUID: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  //maxGroundStopSequenceID: number;
  //currentGroundStopSequenceID: number;
  tripCodeGUID: string;
  icao: string;
  serviceTypeID: number;
  serviceTypeDesc: string;
  //boxHeight: string;
  vendorReq: boolean;

  ground_stop_task_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  errMsg2: string;
  msg: string;
  showSpin: boolean = true;
  icao_iata: string;
  airportName: string;
  airportCountry: string;
  arrivalDateTime: string;
  departureDateTime: string;
  arrivalDateTimeLocal: string;
  departureDateTimeLocal: string;
  nextICAO_IATA: string;
  nextAirportName: string;
  nextCountry: string;
  nextCountryGUID: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;

  lead_time: string;
  lead_datetime: string;
  lead_timeChanged: boolean = false;
  due_date: string;
  due_datetime: string;
  due_dateChanged: boolean = false;
  selectedTaskStatusGUID: string;
  taskStatusList: TripTaskStatusModel[];
  acctStatusList: AccountingStatusModel[];
  acctStatus_TPList: AccountingStatusModel[];
  selectedAcctStatusGUID: string;
  selectedAcctStatusGUID_TP: string;
  confirmation_number: string;
  service_date: Date;
  service_time: string;
  requiresServiceDate: boolean;
  selectedTabName: string;
  public_note: string;
  vendor_note: string;
  internal_note: string;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  recall_number: string;
  fpRecallNumberList: GroundStopTaskModel[];
  countryList: CountryModel[];
  fboList: VendorModel[];
  vendorList: VendorModel[];
  hotelList: HotelModel[];
  fuelSupplierList: VendorModel[];
  intoPlaneAgentList: VendorModel[];
  overflightCountryList: GroundStopTaskModel[];
  permit_order_number: string;
  leadTime_Min_Value: number;
  dueDate_Min_Value: number;
  selectedFBOGUID: string;
  selectedVendorGUID: string;
  selectedOverflightCountryGUID: string;
  selectedLandingCountryGUID: string;
  selectedLandingVendorGUID: string;
  selectedFPRecallNumber: string;
  selectedHotelGUID: string;
  selectedFuelSupplierGUID: string;
  //selectedIntoPlaneAgentName: string;
  selectedFuelSupplierName: string;
  hasExistingDocument: boolean = false;
  countryGUID: string;
  serviceSubTitle: string;
  tripCode: string;
  hasTaskAudit: boolean;
  isRevisionRequired: boolean;
  isRevisionRequiredValue: number;
  @ViewChild('service_date') service_dateRef: ElementRef;

  //displayedColumn: string[] = ['personName'];
  isModified: boolean = false;
  taskDocumentList: GroundStopTaskDocumentModel[]=[];
  allTaskDocumentList: GroundStopTaskDocumentModel[]=[];
  maxTempDocId: number = 0;
  allowedFileType: string;
  uploader: FileUploader;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  docSize: string;

  allowedFileTypeList: string[];
  maxFileSize: number;
  maxFileUploadNumber: number;
  showSuccessMsg: boolean = false;
  serviceArrangementList: ServiceArrangementModel[];
  selectedServiceArrangementID: number;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  userType: string;
  nextICAO: string;
  prevICAO: string;
  registration: string;
  lockdownTrip: boolean = false;
  important_note: string;
  weather_note: string;
  notams_note: string;
  vendorContacts: VendorContactModel[];
  handlingNotes: HandlingNotesModel[];
  loadingWord: boolean = false;
  loadingPDF: boolean = false;
  revision_counter: number = 0;
  flightBriefGroundStopGUIDs: string[] = [];
  groundStopList: GroundStopModel[] = [];
  selectedGroundStopList: GroundStopModel[] = [];
  checkFlightBriefStops: UntypedFormControl;
  weatherChartIds: string[] = [];
  loadingFlightBrief: boolean = false;
  serviceType_LeadTime: string = "";
  serviceType_LeadTime_Min: string = "";
  serviceType_DueDate: string = "";
  serviceType_DueDate_Min: string = "";
  serviceType_CalculateFrom: string = "";
  showOrginalLeadTimeDueDate: boolean = false;
  selectedWXTabName: string = "terminal";
  selectedNotamsTabName: string = "departure";
  terminalForecast: string = "";
  metars: string = "";
  sigmets: string = "";
  airmets: string = "";
  pireps: string = "";
  tropicalAdvisory: string = "";
  vaAdvisory: string = "";
  departureNotams: string = "";
  destinationNotams: string = "";
  etpNotams: string = "";
  fdcNotams: string = "";
  tfrNotams: string = "";
  trackMessage: string = "";
  leg: string;
  nextGroundStopGUID: string;
  ssnVendorID: number = 0;
  vendorHours: VendorHoursModel[];
  hoursData: HoursObjModel[];
  flightBriefFormat: string;
  synopsis: string = "";
  turbulence: string = "";
  isGARLoading: boolean = false;
  selected = new UntypedFormControl(0);
  selectedNotams = new UntypedFormControl(0);
  businessRuleID: number = 0;
  aircraftGUID: string;
  avoidList: GroundStopTaskModel[];
  avoidLocation: boolean = false;
  avoidLocationMsg: string = "";
  avoidBusinessRuleID: number = 0;
  upPII: boolean = false;
  methodOfPayment: string = "";
  customsVendorList: VendorModel[];
  depGroundStopGUID: string = "";
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  destroyed = new Subject<void>();
  maxSize: number;
  taskLabel: string = "";
  displayNameMap = new Map([
    [Breakpoints.XSmall, 10],
    [Breakpoints.Small, 12],
    [Breakpoints.Medium, 14],
    [Breakpoints.Large, 16],
    [Breakpoints.XLarge, 26],
  ]);
  hasOverflightPermitDocument: boolean = false;
  hasLandingPermitDocument: boolean = false;
  totalMessageAttachmentFileSize: number = 0;
  immediateAction: number = 0;
  clientSetpupStatusGUID: string = "73abb6e5-ef14-4c01-b390-089f658a819f";
  onHoldText: string = "";
  clientName: string;
  route_note: string;
  farTypeID: number;
  defaultEmail: string;
  taskColor: string = "";

  hasFeedback: boolean = false;
  airportID: number;
  countryID: number;
  gsInfo: GroundStopModel;
  grantAccessToTripCloseout: boolean = false;
  groundStopGUIDList: string[] = [];
  showDeletedDocuments: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<GroundStopTaskEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService,
    private readonly _dialogService: DialogService,
    private readonly _vendorService: VendorService,
    private readonly _templateService: TemplateService, private readonly _businessRulesService: BusinessRulesService, private _ngZone: NgZone, breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.maxSize = this.displayNameMap.get(query) ?? 14;
          }
        }
      });
    this.groundStopGUID = _data.groundStopGUID; // either dep or arr
    this.serviceTypeID = _data.serviceTypeID;
    this.vendorReq = _data.vendorReq;
    this.dialogTitle = _data.dialogTitle;
    //this.currentGroundStopSequenceID = _data.currentGroundStopSequenceID;
    //this.maxGroundStopSequenceID = _data.maxGroundStopSequenceID;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.icao = _data.icao;
    this.groundStopTaskGUID = _data.groundStopTaskGUID;
    //this.boxHeight = _data.boxHeight.toString() + "em";
    this.countryGUID = _data.countryId;
    this.serviceTypeDesc = _data.serviceTypeDesc;
    this.customerGUID = _data.customerGUID;
    this.leg = _data.leg;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.depGroundStopGUID = _data.depGroundStopGUID;
    this.clientName = _data.clientName;
  }

  getAllData(): Observable<any[]> {
    let request = new GroundStopModel();
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    let getGSResponse = this._groundStopAdvancedService.getGroundStopForTaskEditBygsId<ResponseModel<GroundStopModel>>(request);
    let getTaskStatusResponse = this._groundStopService.getTripTaskStatusList();
    let getAcctStatusResponse = this._groundStopAdvancedService.getAccountingStatusList();
    let req2 = new GroundStopTaskModel();
    req2.groundStopTaskGUID = this.groundStopTaskGUID;
    req2.groundStopGUID = this.groundStopGUID
    let getTaskResponse = this._groundStopAdvancedService.getGroundStopTaskBygstId(req2);
    let getTaskDocResponse = this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId(req2);
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();

    let getCountryList;
    if (this.serviceTypeID == 3 || this.serviceTypeID == 5 || this.serviceTypeID == 26 || this.serviceTypeID == 88) {
      getCountryList = this._commonService.getCountryList();
    }
    else {
      getCountryList = of(null);
    }

    request.icao = this.icao
    request.serviceTypeDescription = this.serviceTypeDesc;
    let getFBOResponse;
    if (this.serviceTypeID != 3 && this.serviceTypeID != 5 && this.serviceTypeID != 26 && this.serviceTypeID != 88 && this.vendorReq == true) {
      //getFBOResponse = this._groundStopAdvancedService.getFBOListByAirport(request);
      getFBOResponse = this._groundStopAdvancedService.getFBOListByAirportServiceType(request);
    }
    else {
      getFBOResponse = of(null);
    }

    //let getVendorResponse; 
    //if (this.serviceTypeID == 3) {
    //  getVendorResponse = this._groundStopAdvancedService.getVendorListForPermitByAirportCountry(request);      
    //}
    //else {
    //  getVendorResponse = of(null);      
    //}

    let getfpRecallNumberResponse;
    if (this.serviceTypeID == 3) {
      getfpRecallNumberResponse = this._groundStopAdvancedService.getFlightPlanRecallNumberListBygsId(req2);
    }
    else {
      getfpRecallNumberResponse = of(null);
    }

    let req3 = new HotelModel();
    //req3.countryGUID = this.countryGUID;
    req3.icao = this.icao;
    req3.distanceFromAirport = 50;
    let getHotelResponse;
    if (this.serviceTypeID == 7 || this.serviceTypeID == 20) {
      //getHotelResponse = this._commonService.getHotelList(req3);
      getHotelResponse = this._commonService.getHotelListByAirport(req3);
    }
    else {
      getHotelResponse = of(null);
    }

    let getFuelSupplierResponse;
    if (this.serviceTypeID == 56) {
      getFuelSupplierResponse = this._groundStopAdvancedService.getFuelSupplierListByAirport(request);
    }
    else {
      getFuelSupplierResponse = of(null);
    }

    let getServiceArrangementResponse;
    if (this.vendorReq == true || this.serviceTypeID == 56) {
      getServiceArrangementResponse = this._groundStopAdvancedService.getServiceArrangementList();
    }
    else {
      getServiceArrangementResponse = of(null);
    }

    let getGroundStopList;
    if (this.serviceTypeID == 34 || this.serviceTypeID == 79 || this.serviceTypeID == 32 || this.serviceTypeID == 85 || this.serviceTypeID == 87 || this.serviceTypeID == 83 || this.serviceTypeID == 84 || this.serviceTypeID == 91) {
      let request = new GroundStopModel;
      request.tripCodeGUID = this.tripCodeGUID;
      request.groundStopGUID = this.groundStopGUID;
      request.customerGUID = this.customerGUID;
      getGroundStopList = this._groundStopService.getGroundStopPairListBytId(request); //this._groundStopService.getGroundStopListForFlightBrief(request);
    }
    else {
      getGroundStopList = of(null);
    }

    let getSSNVendorResponse = this._groundStopService.getSSNVendorIDbygsID(this.groundStopGUID);
    let brRequest = new GroundStopModel();
    brRequest.tripCodeGUID = this.tripCodeGUID;
    brRequest.groundStopGUID = this.groundStopGUID;
    let getAvoidList = this._businessRulesService.getBusinessRulesLocationsToAvoidByTripCodeGUID(brRequest);

    let getCustomsResponse;
    if (this.serviceTypeID == 54 || this.serviceTypeID == 55 || this.serviceTypeID == 98 || this.serviceTypeID == 73) {
      request.serviceTypeIDList = this.serviceTypeID.toString();
      getCustomsResponse = this._groundStopAdvancedService.getVendorListForCustomsByAirport(request);
    }
    else {
      getCustomsResponse = of(null);
    }


    return forkJoin([getGSResponse, getTaskStatusResponse, getAcctStatusResponse, getTaskResponse, getTaskDocResponse,
      getAllowedDocTypeResponse, getCountryList, getFBOResponse, getfpRecallNumberResponse, getHotelResponse, getFuelSupplierResponse,
      getServiceArrangementResponse, getGroundStopList, getSSNVendorResponse, getAvoidList, getCustomsResponse]);

  }

  ngOnInit() {
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (localStorage.getItem('up.tClose') == 'true') {
      this.grantAccessToTripCloseout = true;
    }
    else {
      this.grantAccessToTripCloseout = false;
      // this._dialogService.openAlertDialog("Permission denied.", "OK");

      // return;
    }
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }

    this.showSpin = true;
    this._authService.updateAccessTime();
    this.allowedFileType = ""; // new Array(GlobalConstant.allowedFileTypeList).toString().split(",").join(", ");
    this.errMsg = "";
    this.errMsg2 = "";
    this.msg = "";
    this.tripCode = "";
    this.icao_iata = "";
    this.airportName = "";
    this.airportCountry = "";
    this.arrivalDateTime = "";
    this.departureDateTime = "";
    this.arrivalDateTimeLocal = "";
    this.departureDateTimeLocal = "";
    this.nextICAO_IATA = "";
    this.nextAirportName = "";
    this.nextCountry = "";
    this.aircraftManufacturer = "";
    this.aircraftModel = "";
    this.aircraftInfo = "";
    this.lead_time = "";
    this.lead_datetime = "";
    this.lead_timeChanged = false;
    this.due_date = "";
    this.due_datetime = "";
    this.due_dateChanged = false;
    this.taskStatusList = [];
    this.acctStatusList = [];
    this.acctStatus_TPList = [];
    this.selectedTaskStatusGUID = "";
    this.selectedAcctStatusGUID = "";
    this.selectedAcctStatusGUID_TP = "";
    this.confirmation_number = "";
    this.service_date = null;
    this.service_time = "";
    this.requiresServiceDate = false;
    this.selectedTabName = "public";
    this.public_note = "";
    this.vendor_note = "";
    this.internal_note = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.recall_number = "";
    this.fpRecallNumberList = [];
    this.countryList = [];
    this.fboList = [];
    this.vendorList = [];
    this.hotelList = [];
    this.fuelSupplierList = [];
    this.intoPlaneAgentList = [];
    this.overflightCountryList = [];
    this.permit_order_number = "";
    this.selectedFBOGUID = "";
    this.selectedVendorGUID = "";
    this.selectedOverflightCountryGUID = "";
    this.selectedLandingCountryGUID = "";
    this.selectedLandingVendorGUID = "";
    this.selectedFPRecallNumber = "";
    this.selectedHotelGUID = "";
    this.selectedFuelSupplierGUID = "";
    this.selectedFuelSupplierName = "";
    this.leadTime_Min_Value = 0;
    this.dueDate_Min_Value = 0;
    this.fileList = [];
    this.taskDocumentList = [];
    this.maxTempDocId = 0;
    this.hasExistingDocument = false;
    this.allowedFileTypeList = [];
    this.maxFileSize = 0;
    this.maxFileUploadNumber = 0;
    this.serviceSubTitle = "";
    this.showSuccessMsg = false;
    this.nextICAO = "";
    this.prevICAO = "";
    this.registration = "";
    this.serviceArrangementList = [];
    this.selectedServiceArrangementID = 0;
    this.important_note = "";
    this.notams_note = "";
    this.weather_note = "";
    this.hasTaskAudit = false;
    this.isRevisionRequiredValue = 0;
    this.isRevisionRequired = false;
    this.avoidList = [];
    this.customsVendorList = [];
    this.totalMessageAttachmentFileSize = 0;
    this.immediateAction = 0;
    this.route_note = "";
    this.defaultEmail = "";
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          let gs = new GroundStopModel();
          this.gsInfo = responses[0].model;
          gs = responses[0].model;
          this.tripCode = gs.tripCode;
          this.icao = gs.icao;
          this.icao_iata = gs.icaO_IATA;
          this.airportName = gs.airportName;
          this.airportCountry = gs.airportState_CountryCode;
          this.arrivalDateTime = gs.arrivalDateTextUTC;
          this.departureDateTime = gs.departureDateTextUTC;
          this.arrivalDateTimeLocal = gs.arrivalDateTextLocal;
          this.departureDateTimeLocal = gs.departureDateTextLocal;
          this.nextICAO_IATA = gs.nextICAO_IATA;
          this.nextAirportName = gs.nextAirportName;
          this.nextICAO = gs.nextICAO;
          this.nextCountry = gs.nextAirportCity_CountryCode;
          this.nextCountryGUID = gs.nextCountryGUID.toLowerCase();
          if ((this.serviceTypeID == 5 || this.serviceTypeID == 26)) {
            this.selectedLandingCountryGUID = this.countryGUID.toLowerCase();
            if (this.vendorList.length == 0) {
              this.getPermitVendorList(this.selectedLandingCountryGUID);
            }
          }
          this.prevICAO = gs.prevICAO;
          this.registration = gs.registration;
          this.aircraftManufacturer = gs.aircraftManufacturer;
          this.aircraftModel = gs.aircraftModel;
          this.aircraftInfo = gs.aircraftInfo;
          this.countryGUID = gs.countryGUID;
          this.lockdownTrip = gs.lockdownTrip;
          this.aircraftGUID = gs.aircraftGUID;
          this.farTypeID = gs.farTypeID;
          this.airportID = gs.airportID;
          this.countryID = gs.countryID;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.taskStatusList = responses[1].model;

        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          let tempacctStatusList: AccountingStatusModel[];
          tempacctStatusList = [];
          tempacctStatusList = responses[2].model;
          //this.acctStatusList = responses[2].model;
          this.acctStatusList = tempacctStatusList.filter(x => x.isGTSFlag == true);
          this.acctStatus_TPList = tempacctStatusList.filter(x => x.isGTSFlag != true);

        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          if (responses[3].model != null) {
            let gst = new GroundStopTaskModel();
            gst = responses[3].model;
            //this.serviceTypeID = gst.serviceTypeID;
            this.serviceTypeDesc = gst.serviceTypeDescription;
            this.modifiedBy = gst.modifiedBy;
            this.modifiedDate = gst.modifiedDate;

            if (gst.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
            //this.vendorReq = gst.vendorRequired;
            this.selectedTaskStatusGUID = gst.taskStatusGUID.toLowerCase();
            this.selectedAcctStatusGUID = gst.accountingStatusGUID;
            if (this.selectedAcctStatusGUID != "")
              this.selectedAcctStatusGUID = this.selectedAcctStatusGUID.toLowerCase();

            this.selectedAcctStatusGUID_TP = gst.accountingStatusGUID_TP;
            if (this.selectedAcctStatusGUID_TP != "")
              this.selectedAcctStatusGUID_TP = this.selectedAcctStatusGUID_TP.toLowerCase();

            if (gst.serviceDate != "")
              this.service_date = new Date(gst.serviceDate);
            this.service_time = gst.serviceTime;
            this.requiresServiceDate = gst.requiresServiceDate;
            this.recall_number = gst.flightPlanRecallNo;
            this.getOverflightCountryList();
            this.lead_time = gst.leadTime_Min;
            this.due_date = gst.dueDate_Min;
            this.lead_datetime = gst.lead_DateTimeDisplay;
            this.due_datetime = gst.due_DateDisplay;
            this.confirmation_number = gst.fpiConfirmationReference;
            this.serviceSubTitle = gst.serviceSubTitle;
            if (this.serviceSubTitle == "") {
              this.serviceSubTitle = "FBO / Handler";
            }
            this.hasTaskAudit = gst.hasTaskAudit;
            this.isRevisionRequiredValue = gst.isRevisionRequiredValue;
            if (this.isRevisionRequiredValue != 0) {
              this.isRevisionRequired = true;
            }
            this.revision_counter = gst.revisionCounter;
            this.serviceType_LeadTime = gst.serviceType_LeadTime;
            this.serviceType_LeadTime_Min = gst.serviceType_LeadTime_Min;
            this.serviceType_DueDate = gst.serviceType_DueDate;
            this.serviceType_DueDate_Min = gst.serviceType_DueDate_Min;
            this.serviceType_CalculateFrom = gst.serviceType_CalculateFrom;
            this.public_note = gst.publicNote;
            this.internal_note = gst.internalNote;
            this.vendor_note = gst.vendorNote;
            this.important_note = gst.importantNote;
            this.weather_note = gst.weatherNote;
            this.notams_note = gst.notamsNote;
            this.terminalForecast = gst.terminalForecast;
            this.metars = gst.metars;
            this.sigmets = gst.sigmets;
            this.airmets = gst.airmets;
            this.pireps = gst.pireps;
            this.tropicalAdvisory = gst.tropicalAdvisory;
            this.vaAdvisory = gst.vaAdvisory;
            this.departureNotams = gst.departureNotams;
            this.destinationNotams = gst.destinationNotams;
            this.etpNotams = gst.etpNotams;
            this.fdcNotams = gst.fdcNotams;
            this.trackMessage = gst.trackMessage;
            this.tfrNotams = gst.tfrNotams;
            this.synopsis = gst.synopsis;
            this.turbulence = gst.turbulence;
            this.taskLabel = gst.taskLabel;
            this.route_note = gst.routeNote;
            this.defaultEmail = gst.defaultEmail;
            this.hasFeedback = gst.hasFeedback;


            this.taskColor = this.taskStatusList.filter(x => x.taskStatusGUID.toLowerCase() == this.selectedTaskStatusGUID)[0].hexColor;
            if (gst.countryGUID == '00000000-0000-0000-0000-000000000000')
              this.selectedOverflightCountryGUID = gst.countryGUID;
            if (gst.vendorGUID != "") {
              if (this.vendorReq && this.serviceTypeID != 3 && this.serviceTypeID != 5 && this.serviceTypeID != 26 && this.serviceTypeID != 54 && this.serviceTypeID != 55 && this.serviceTypeID != 88 && this.serviceTypeID != 98 && this.serviceTypeID != 73) {
                this.selectedFBOGUID = gst.vendorGUID.toLowerCase();
              }
              else {
                if (this.serviceTypeID == 3 || this.serviceTypeID == 88) {
                  this.selectedVendorGUID = gst.vendorGUID.toLowerCase();
                  this.selectedOverflightCountryGUID = gst.countryGUID.toLowerCase();
                  if (this.selectedVendorGUID != "") {
                    this.getPermitVendorList(gst.countryGUID);
                  }
                }
                if (this.serviceTypeID == 5 || this.serviceTypeID == 26) {
                  this.selectedLandingVendorGUID = gst.vendorGUID.toLowerCase();
                  this.selectedLandingCountryGUID = gst.countryGUID.toLowerCase();
                  if (this.selectedLandingVendorGUID != '' && this.vendorList.length == 0) {
                    this.getPermitVendorList(gst.countryGUID);
                  }
                }
                if (this.serviceTypeID == 54 || this.serviceTypeID == 55 || this.serviceTypeID == 98 || this.serviceTypeID == 73) {
                  this.selectedVendorGUID = gst.vendorGUID.toLowerCase();
                }

              }
            }
            //this.selectedFBOGUID = gst.vendorGUID.toLowerCase();
            //this.selectedVendorGUID = gst.vendorGUID.toLowerCase();
            this.recall_number = gst.flightPlanRecallNo;
            this.selectedFPRecallNumber = gst.flightPlanRecallNo;
            //this.selectedOverflightCountryGUID = gst.countryGUID.toLowerCase();
            if (gst.overflightPermitOrderNo > 0)
              this.permit_order_number = gst.overflightPermitOrderNo.toString();
            this.selectedHotelGUID = gst.hotelGUID.toLowerCase();
            this.selectedFuelSupplierGUID = gst.fuelSupplierGUID.toLowerCase();
            this.selectedFuelSupplierName = gst.fuelSupplierName;
            if (this.selectedFuelSupplierName != "") {
              this.getIntoPlaneAgentList();
            }
            this.flightBriefGroundStopGUIDs = gst.flightBriefGroundStopGUIDs;
            if (gst.weatherChartIds != null)
              this.weatherChartIds = gst.weatherChartIds;
            this.selectedServiceArrangementID = gst.serviceArrangementID;
            this.flightBriefFormat = gst.flightBriefFormat;
            this.businessRuleID = gst.businessRulesID;
            if ((this.public_note == "" || this.public_note == null) && (this.serviceTypeID == 83 || this.serviceTypeID == 84 || this.serviceTypeID == 91)) {
              let stops = "";
              if (this.selectedGroundStopList.length > 0) {
                this.selectedGroundStopList.forEach(x => {
                  if (stops == '')
                    stops = x.departureICAO + '-' + x.arrivalICAO;
                  else
                    stops += '-' + x.arrivalICAO;
                });
              }
              let wx_sum: string = "";
              wx_sum += "Greetings,\n\n";
              wx_sum += this.serviceTypeID == 83 ? '24' : this.serviceTypeID == 91 ? '48' : '72';
              wx_sum += " HR check in for your flight " + stops + ".\n\n\n";
              wx_sum += "Forecast Summary:\n\n";
              wx_sum += "Significant Notams:\n\n";
              wx_sum += "Temporary Flight Restrictions:\n\n";
              wx_sum += "Estimated Terminal Forecasts:\n\n";
              wx_sum += "Please let us know should you have any questions or concerns.\n\n";
              wx_sum += "Thank you and best regards,";
              this.public_note = wx_sum;

            }

            if (gst.immediateActionRequired) {
              this.immediateAction = 1;
            }
            else {
              this.immediateAction = 0;
            }
            if (gst.serviceLocation == 'D' && gst.departureOnHold) {
              this.onHoldText = "On Hold";
            }
            else {
              if (gst.serviceLocation == 'A' && gst.arrivalOnHold) {
                this.onHoldText = "On Hold";
              }
            }
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          if (responses[4].model.length > 0) {
            this.allTaskDocumentList = responses[4].model;
            this.taskDocumentList = this.allTaskDocumentList.filter(x => x.isActive == true).slice();
            this.hasExistingDocument = true;
          }
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          if (responses[5].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[5].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }


      if (responses[6] != null) {
        if (responses[6].code == "200" && responses[6].message == "") {
          this.countryList = responses[6].model;
          if (this.serviceTypeID == 3) {
            let c = new CountryModel();
            c.countryGUID = '00000000-0000-0000-0000-000000000000';
            c.countryName = 'Not Required';
            this.countryList.push(c);
          }
          if (this.selectedOverflightCountryGUID != "" && this.selectedOverflightCountryGUID != '00000000-0000-0000-0000-000000000000')
            this.hasOverflightPermitDocument = this.countryList.find(x => x.countryGUID == this.selectedOverflightCountryGUID).hasOverflightPermitDocument;
          if (this.selectedLandingCountryGUID != "")
            this.hasLandingPermitDocument = this.countryList.find(x => x.countryGUID == this.selectedLandingCountryGUID).hasLandingPermitDocument;

        }
        else {
          if (responses[6].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[7] != null) {
        if (responses[7].code == "200" && responses[7].message == "") {
          this.fboList = responses[7].model;
          if (this.selectedFBOGUID != "") {
            this.methodOfPayment = this.fboList.find(x => x.vendorGUID == this.selectedFBOGUID)?.vendorMethodOfPaymentDescription;
          }
        }
        else {
          if (responses[7].code == "401") {
            this._authService.signOut();
          }
        }
      }

      //if (responses[8] != null) {
      //  if (responses[8].code == "200" && responses[8].message == "") {
      //    this.vendorList = responses[8].model;
      //  }
      //  else {
      //    if (responses[8].code == "401") {
      //      this._authService.signOut();
      //    }
      //  }
      //}

      if (responses[8] != null) {
        if (responses[8].code == "200" && responses[8].message == "") {
          this.fpRecallNumberList = responses[8].model;
        }
        else {
          if (responses[8].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[9] != null) {
        if (responses[9].code == "200" && responses[9].message == "") {
          this.hotelList = responses[9].model; // no data in database
        }
        else {
          if (responses[9].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[10] != null) {
        if (responses[10].code == "200" && responses[10].message == "") {
          this.fuelSupplierList = responses[10].model;
        }
        else {
          if (responses[10].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[11] != null) {
        if (responses[11].code == "200" && responses[11].message == "") {
          this.serviceArrangementList = responses[11].model;
        }
        else {
          if (responses[11].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[12] != null) {
        if (responses[12].code == "200" && responses[12].message == "") {
          this.groundStopList = responses[12].model;
          this.groundStopList = this.groundStopList.filter(v => v.nextGroundStopGUID != '');
          this.groundStopList = this.groundStopList.filter(v => v.groundStopTypeDescription != 'Contingency');
          if (this.flightBriefGroundStopGUIDs != null && this.flightBriefGroundStopGUIDs.length > 0) {
            this.flightBriefGroundStopGUIDs.forEach(v => {
              this.groundStopList.forEach(x => {
                if (x.groundStopGUID.toLowerCase() == v.toLowerCase()) {
                  x.selected = true;
                }

              });

            });
            this.setGroundStopList();
          }
        }
        else {
          if (responses[12].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[13] != null) {
        if (responses[13].code == "200" && responses[13].message == "") {
          this.ssnVendorID = Number(responses[13].model);
        }
        else {
          if (responses[13].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[14] != null) {
        if (responses[14].code == "200" && responses[14].message == "") {
          this.avoidList = responses[14].model;
        }
        else {
          if (responses[14].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[15] != null) {
        if (responses[15].code == "200" && responses[14].message == "") {
          this.customsVendorList = responses[15].model;
          if (this.selectedVendorGUID == "") {
            if (this.customsVendorList.length == 1)
              this.selectedVendorGUID = this.customsVendorList[0].vendorGUID;
            else
              if (this.customsVendorList.length == 2)
                this.selectedVendorGUID = this.customsVendorList.find(x => x.vendorGUID.toLowerCase() != '4383ada6-2161-49e6-965f-47fc67fa5cd4').vendorGUID;
          }
        }
        else {
          if (responses[15].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (this.selectedAcctStatusGUID == "") {
        this.selectedAcctStatusGUID = this.acctStatusList[0].accountingStatusGUID.toLowerCase();
      }

      if (this.vendorReq == true) {
        if (this.selectedAcctStatusGUID_TP == "") {
          this.selectedAcctStatusGUID_TP = this.acctStatus_TPList[0].accountingStatusGUID.toLowerCase();
        }
        if (this.selectedServiceArrangementID == 0) {

          switch (this.serviceTypeID) {
            case 3: // overflight permit
            case 5: // landing permit
            case 26: // charter
            case 88: //curacao nav fees
              this.selectedServiceArrangementID = 1;
              break;
            default:
              this.selectedServiceArrangementID = 2;
              break;
          }
        }
      }
      else {
        if (this.serviceTypeID == 56 && this.selectedServiceArrangementID == 0) { //  fuel release
          this.selectedServiceArrangementID = 2;
        }
      }

      this.initControls();

      if (this.serviceTypeID == 35 && this.f.public_note.value == '') {
        let ims: string = "";
        ims += "UPLINK RECALL NUMBER " + this.icao + '-' + this.nextICAO + ' ';
        this.f.public_note.setValue(ims);
      }

      this.clearValidatorsByServices();
      //if (!this.vendorReq || this.serviceTypeID == 3 || this.serviceTypeID == 5 || this.serviceTypeID == 26) {
      //  this.f.fbo_select.clearValidators();
      //  this.f.fbo_select.updateValueAndValidity();
      //}

      //if (this.serviceTypeID != 3) {
      //  this.f.vendor_select.clearValidators();
      //  this.f.vendor_select.updateValueAndValidity();
      //  this.f.overflight_country_select.clearValidators();
      //  this.f.overflight_country_select.updateValueAndValidity();
      //}

      //if (this.serviceTypeID != 5 && this.serviceTypeID != 26)  {
      //  this.f.landing_vendor_select.clearValidators();
      //  this.f.landing_vendor_select.updateValueAndValidity();
      //  this.f.landing_country_select.clearValidators();
      //  this.f.landing_country_select.updateValueAndValidity();
      //}

      //if (this.serviceTypeID!=56) {     
      //  this.f.fuel_supplier_select.clearValidators();
      //  this.f.fuel_supplier_select.updateValueAndValidity();
      //  this.f.intoplane_agent_select.clearValidators();
      //  this.f.intoplane_agent_select.updateValueAndValidity();
      //}

      this.showSpin = false;

    });

  }

  clearValidatorsByServices() {
    if (this.isActive == 0 || this.f.task_status_select.value.toLowerCase() == this.clientSetpupStatusGUID) {
      this.f.fbo_select.clearValidators();
      this.f.fbo_select.updateValueAndValidity();
      this.f.vendor_select.clearValidators();
      this.f.vendor_select.updateValueAndValidity();
      this.f.overflight_country_select.clearValidators();
      this.f.overflight_country_select.updateValueAndValidity();
      this.f.landing_vendor_select.clearValidators();
      this.f.landing_vendor_select.updateValueAndValidity();
      this.f.landing_country_select.clearValidators();
      this.f.landing_country_select.updateValueAndValidity();
      this.f.fuel_supplier_select.clearValidators();
      this.f.fuel_supplier_select.updateValueAndValidity();
      this.f.intoplane_agent_select.clearValidators();
      this.f.intoplane_agent_select.updateValueAndValidity();
      this.f.customs_vendor_select.clearValidators();
      this.f.customs_vendor_select.updateValueAndValidity();
    }
    else {
      if (!this.vendorReq || this.serviceTypeID == 3 || this.serviceTypeID == 5 || this.serviceTypeID == 26 || this.serviceTypeID == 55 || this.serviceTypeID == 54 || this.serviceTypeID == 88 || this.serviceTypeID == 98 || this.serviceTypeID == 73) {
        this.f.fbo_select.clearValidators();
        this.f.fbo_select.updateValueAndValidity();
      }

      if (this.serviceTypeID != 3 && this.serviceTypeID != 88) {
        this.f.vendor_select.clearValidators();
        this.f.vendor_select.updateValueAndValidity();
        this.f.overflight_country_select.clearValidators();
        this.f.overflight_country_select.updateValueAndValidity();
      }

      if (this.serviceTypeID != 5 && this.serviceTypeID != 26) {
        this.f.landing_vendor_select.clearValidators();
        this.f.landing_vendor_select.updateValueAndValidity();
        this.f.landing_country_select.clearValidators();
        this.f.landing_country_select.updateValueAndValidity();
      }
      if (this.serviceTypeID != 54 && this.serviceTypeID != 55 && this.serviceTypeID != 98 && this.serviceTypeID != 73) {
        this.f.customs_vendor_select.clearValidators();
        this.f.customs_vendor_select.updateValueAndValidity();
      }
      if (this.serviceTypeID != 56) {
        this.f.fuel_supplier_select.clearValidators();
        this.f.fuel_supplier_select.updateValueAndValidity();
        this.f.intoplane_agent_select.clearValidators();
        this.f.intoplane_agent_select.updateValueAndValidity();
      }

    }

  }

  initControls() {
    this.ground_stop_task_edit_form = this._formBuilder.group({
      lead_time: [this.lead_time, Validators.pattern("^(([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?)$")],
      //lead_datetime: [this.lead_datetime],
      due_date: [this.due_date, Validators.pattern("^([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?$")],
      //due_datetime: [this.due_datetime],
      task_status_select: [this.selectedTaskStatusGUID],
      acct_status_select: [this.selectedAcctStatusGUID],
      acct_status_tp_select: [this.selectedAcctStatusGUID_TP],
      confirmation_number: [this.confirmation_number],
      service_date: [this.service_date],
      service_time: [this.service_time],
      public_note: [this.public_note],
      vendor_note: [this.vendor_note],
      internal_note: [this.internal_note],
      record_status: this.isActive,
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      fbo_select: [this.selectedFBOGUID, Validators.required],
      recall_number: [this.recall_number],
      vendor_select: [this.selectedVendorGUID, Validators.required],
      overflight_country_select: [this.countryList.find(v => v.countryGUID === this.selectedOverflightCountryGUID), Validators.required],
      landing_vendor_select: [this.selectedLandingVendorGUID, Validators.required],
      landing_country_select: [this.countryList.find(v => v.countryGUID === this.selectedLandingCountryGUID), Validators.required],
      //permit_order_number: [this.permit_order_number, Validators.pattern("([0-9]{1,2})?")],
      permit_order_number: [this.permit_order_number],
      fp_recall_number_select: [this.selectedFPRecallNumber],
      hotel_select: [this.selectedHotelGUID],
      fuel_supplier_select: [this.selectedFuelSupplierName, Validators.required],
      intoplane_agent_select: [this.selectedFuelSupplierGUID, Validators.required],
      service_arrangement_fuel_select: [this.selectedServiceArrangementID],
      service_arrangement_handling_select: [this.selectedServiceArrangementID],
      service_arrangement_landing_select: [this.selectedServiceArrangementID],
      service_arrangement_overflight_select: [this.selectedServiceArrangementID],
      document: [null],
      important_note: this.important_note,
      weather_note: this.weather_note,
      notams_note: this.notams_note,
      isRevisionRequired: [this.isRevisionRequired],
      /*revision_counter: [this.revision_counter],*/
      terminal_note: this.terminalForecast,
      metars_note: this.metars,
      sigmets_note: this.sigmets,
      airmets_note: this.airmets,
      pireps_note: this.pireps,
      tropical_note: this.tropicalAdvisory,
      va_note: this.vaAdvisory,
      departure_note: this.departureNotams,
      destination_note: this.destinationNotams,
      etp_note: this.etpNotams,
      fdc_note: this.fdcNotams,
      track_note: this.trackMessage,
      tfr_notams: this.tfrNotams,
      synopsis_note: this.synopsis,
      turbulence_note: this.turbulence,
      customs_vendor_select: [this.selectedVendorGUID, Validators.required],
      immediateAction: this.immediateAction,
      route_note: this.route_note
    });

    if (this.selectedOverflightCountryGUID == '00000000-0000-0000-0000-000000000000') {
      this.f.vendor_select.clearValidators();
      this.f.vendor_select.updateValueAndValidity();
    }

    this.checkFlightBriefStops = new UntypedFormControl(this.selectedGroundStopList); // for mat-select

  }

  get f() { return this.ground_stop_task_edit_form.controls; }

  getPermitVendorList(countryId: string, isUpdate: boolean = false) {
    let request = new GroundStopModel()
    request.countryGUID = countryId;
    this._groundStopAdvancedService.getVendorListForPermitByAirportCountry<ResponseModel<VendorModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.vendorList = response.model;
        if (this.vendorList.find(x => x.vendorGUID == this.selectedVendorGUID) != undefined)
          this.methodOfPayment = this.vendorList.find(x => x.vendorGUID == this.selectedVendorGUID).vendorMethodOfPaymentDescription;
        else {
          if (this.vendorList.find(x => x.vendorGUID == this.selectedLandingVendorGUID) != undefined)
            this.methodOfPayment = this.vendorList.find(x => x.vendorGUID == this.selectedLandingVendorGUID).vendorMethodOfPaymentDescription;
          else
            this.methodOfPayment = "";
        }
        if (isUpdate) {
          if (this.serviceTypeID == 3) {
            if (this.vendorList.find(x => x.ratingID == 4 && (x.permitTypeID == 3 || x.permitTypeID == 6)) != undefined) {
              this.f.vendor_select.setValue(this.vendorList.find(x => x.ratingID == 4 && (x.permitTypeID == 3 || x.permitTypeID == 6)).vendorGUID);
              this.methodOfPayment = this.vendorList.find(x => x.vendorGUID == this.f.vendor_select.value).vendorMethodOfPaymentDescription;
            }
            else {
              this.f.vendor_select.setValue(null);
              this.methodOfPayment = "";
            }
          }
          if (this.serviceTypeID == 5 || this.serviceTypeID == 26) {
            if (this.serviceTypeID == 5)
              if (this.vendorList.find(x => x.ratingID == 4 && (x.permitTypeID == 1 || x.permitTypeID == 6)) != undefined) {
                this.f.landing_vendor_select.setValue(this.vendorList.find(x => x.ratingID == 4 && (x.permitTypeID == 1 || x.permitTypeID == 6)).vendorGUID);
                this.methodOfPayment = this.vendorList.find(x => x.vendorGUID == this.f.landing_vendor_select.value).vendorMethodOfPaymentDescription;
              }
              else {
                this.f.landing_vendor_select.setValue(null);
                this.methodOfPayment = "";
              }
            else
              if (this.vendorList.find(x => x.ratingID == 4 && (x.permitTypeID == 2 || x.permitTypeID == 6)) != undefined) {
                this.f.landing_vendor_select.setValue(this.vendorList.find(x => x.ratingID == 4 && (x.permitTypeID == 2 || x.permitTypeID == 6)).vendorGUID);
                this.methodOfPayment = this.vendorList.find(x => x.vendorGUID == this.f.landing_vendor_select.value).vendorMethodOfPaymentDescription;
              }
              else {
                this.f.landing_vendor_select.setValue(null);
                this.methodOfPayment = "";
              }
          }
        }
      }
      else {
        this.vendorList = [];
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    })
  }

  getIntoPlaneAgentList() {
    let request = new VendorModel()
    request.vendorName = this.selectedFuelSupplierName;
    request.icao = this.icao;
    this._groundStopAdvancedService.getIntoPlaneAgentListByAirportVendor<ResponseModel<VendorModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.intoPlaneAgentList = response.model;
        if (this.f.fuel_supplier_select.value == 'TBD') {
          let intoplaneagentid = this.intoPlaneAgentList.find(x => x.intoPlaneAgent == 'TBD').vendorGUID;
          this.f.intoplane_agent_select.setValue(intoplaneagentid);
        }

      }
      else {
        this.intoPlaneAgentList = [];
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    })
  }

  getOverflightCountryList() {
    if (this.serviceTypeID == 35 && this.recall_number != "") {
      let request = new GroundStopTaskModel()
      request.groundStopGUID = this.groundStopGUID;
      request.flightPlanRecallNo = this.recall_number;
      this._groundStopAdvancedService.getOverflightCountryListBygsIdfpNum<ResponseModel<GroundStopTaskModel[]>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          this.overflightCountryList = response.model;
          //response.model.forEach(x => {
          //  this.overflightCountryList.push(x);
          //});
          //response.model.forEach(x => {
          //  this.overflightCountryList.push(x);
          //});

        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          //else {
          //  this.errMsg2 = "Failed to save the record. Please try again later.";
          //}
        }
        //this.loading = false;
      })
    }
  }

  countryChange(e: any, serviceType: string) {
    this.avoidLocationMsg = "";
    this.avoidBusinessRuleID = 0;
    let countryGUID = "";

    if (serviceType == 'landing') {
      this.selectedLandingVendorGUID = null;
      this.f.landing_vendor_select.setValue(null);
      this.methodOfPayment = "";
      this.getPermitVendorList(this.f.landing_country_select.value['countryGUID'], true);
      countryGUID = this.f.landing_country_select.value['countryGUID']
      this.hasLandingPermitDocument = this.f.overflight_country_select.value['hasLandingPermitDocument'];
    }
    if (serviceType == 'overflight') {
      if (this.f.overflight_country_select.value['countryGUID'] != '00000000-0000-0000-0000-000000000000') {
        this.selectedVendorGUID = null;
        this.f.vendor_select.setValue(null);
        this.methodOfPayment = "";
        this.getPermitVendorList(this.f.overflight_country_select.value['countryGUID'], true);
        countryGUID = this.f.overflight_country_select.value['countryGUID'];
        this.hasOverflightPermitDocument = this.f.overflight_country_select.value['hasOverflightPermitDocument'];
      }
    }

    if (this.avoidList.length > 0) {
      if (this.avoidList.some(v => v.countryGUID == countryGUID && (v.serviceTypeID == this.serviceTypeID || v.serviceTypeID == 0) && v.vendorGUID == "")) {
        this.avoidLocation = true;
        this.avoidBusinessRuleID = this.avoidList.find(v => v.countryGUID == countryGUID && (v.serviceTypeID == this.serviceTypeID || v.serviceTypeID == 0)).businessRulesID
        this.avoidLocationMsg = "Country should be avoided (BR ID " + this.avoidBusinessRuleID.toString() + ").";
        //this._dialogService.openAlertDialog(this.avoidLocationMsg);
      }

    }

    if (serviceType == 'overflight' && this.f.overflight_country_select.value['countryGUID'] == '00000000-0000-0000-0000-000000000000') {
      this.f.vendor_select.setValue(null);
      this.f.vendor_select.clearValidators();
    }
    else {
      this.f.vendor_select.setValidators(Validators.required);
    }
    this.f.vendor_select.updateValueAndValidity();
  }

  leadTimeChange(e: any) {
    if (e.target.value != "") {
      this.lead_timeChanged = true;
      if (this.f.lead_time.errors) {
        return;
      }
      this.lead_timeChanged = false;
      this.calculateLeadTime_DueDate('l', e.target.value);
    }
    else {
      //this.f.lead_datetime.setValue('');
      this.leadTime_Min_Value = 0;
    }
  }

  dueDateChange(e: any) {
    if (e.target.value != "") {
      this.due_dateChanged = true;
      if (this.f.due_date.errors) {
        return;
      }
      this.due_dateChanged = false;
      this.calculateLeadTime_DueDate('d', e.target.value);
    }
    else {
      //this.f.due_datetime.setValue('');
      this.dueDate_Min_Value = 0;
    }
  }

  calculateLeadTime_DueDate(type: string, s: string) {
    if (type == 'l') {
      //this.f.lead_datetime.setValue('');
    }
    if (type == 'd') {
      //this.f.due_datetime.setValue('');
    }
    if (s != "") {
      let d = 0;
      s = s.toLowerCase();
      var i = -1;
      i = s.indexOf('d');
      if (i > 0) {
        let sd = s.substring(0, i);
        if (Number.isInteger(Number(sd))) {
          d = Number(sd);
          s = s.substring(i + 1);
        }
      }

      let h = 0;
      if (s != "") {
        i = s.indexOf('h');
        if (i > 0) {
          let sh = s.substring(0, i);
          if (Number.isInteger(Number(sh))) {
            h = Number(sh);
            s = s.substring(i + 1);
          }
        }
      }

      let mi = 0;
      if (s != "") {
        i = s.indexOf('m');
        if (i > 0) {
          let sm = s.substring(0, i);
          if (Number.isInteger(Number(sm))) {
            mi = Number(sm);
            s = s.substring(i + 1);
          }
        }
      }

      let totalmin = 0;
      if (d > 0) {
        totalmin += d * 24 * 60;
      }
      if (h > 0) {
        totalmin += h * 60;
      }
      if (mi > 0) {
        totalmin += mi;
      }
      if (type == 'l') {
        this.leadTime_Min_Value = totalmin;
      }
      if (type == 'd') {
        this.dueDate_Min_Value = totalmin;
      }
      if (totalmin > 0) {
        if (type == 'l') {
          this.leadTime_Min_Value = totalmin;
        }
        if (type == 'd') {
          this.dueDate_Min_Value = totalmin;
        }
        if (this.arrivalDateTime != "") {
          let dt = new Date(this.arrivalDateTime);
          dt.setMinutes(dt.getMinutes() - totalmin);
          if (dt != null) {
            let m = dt.getMonth() + 1;
            let tempMin = dt.getMinutes();
            let stempMin = "";
            if (tempMin < 10) {
              stempMin = "0" + tempMin.toString();
            }
            else {
              stempMin = tempMin.toString();
            }
            let sdt = m.toString() + "/" + dt.getDate().toString() + '/' + dt.getFullYear().toString() + ' ' + dt.getHours().toString() + ':' + stempMin;
            //if (type == 'l') {
            //  this.f.lead_datetime.setValue(sdt);             
            //}
            //if (type == 'd') {
            //  this.f.due_datetime.setValue(sdt);
            //  this.f.due_datetime.setErrors(null);
            //}
          }
        }
        else {
          if (this.departureDateTime != "") {
            let dt = new Date(this.departureDateTime);
            dt.setMinutes(dt.getMinutes() - totalmin);
            if (dt != null) {
              let m = dt.getMonth() + 1;
              let tempMin = dt.getMinutes();
              let stempMin = "";
              if (tempMin < 10) {
                stempMin = "0" + tempMin.toString();
              }
              else {
                stempMin = tempMin.toString();
              }
              let sdt = m.toString() + "/" + dt.getDate().toString() + '/' + dt.getFullYear().toString() + ' ' + dt.getHours().toString() + ':' + stempMin;
              //if (type == 'l') {
              //  this.f.lead_datetime.setValue(sdt);              
              //}
              //if (type == 'd') {
              //  this.f.due_datetime.setValue(sdt);
              //  this.f.due_datetime.setErrors(null);
              //}
            }
          }
        }
      }

    }

  }

  recallNumberChange(e: any) {
    if (e.target.value != "") {
      this.recall_number = e.target.value;
      this.getOverflightCountryList();
    }
  }

  fuelSupplierChange(e: any) {
    this.selectedFuelSupplierName = this.f.fuel_supplier_select.value;
    if (this.selectedFuelSupplierName != "") {
      this.getIntoPlaneAgentList();
    }
  }

  record_statusChange(e: any) {
    this.isActive = Number(this.f.record_status.value);
    if (this.isActive == 0) {
      this.clearValidatorsByServices();
    }
    else {
      this.backUpControlValues();
      this.initControls();
      this.clearValidatorsByServices();
    }
  }

  // upload file
  dropFile(event: any) {
    this.errMsg = "";
    //setTimeout(() => {
    //  this.errMsg="", 1000;
    //});

    if ((this.fileList.length + event.length) > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      //this.finishAllUploads = true;
      //return;
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;
        setTimeout(() => this.removeFromUploaderQueue(filename), 100);
      }
    }
    else {
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event[i].size >= this.maxFileSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          //}
          if (event[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {
            if (this.errMsg != "") {
              this.errMsg += "\n";
            }
            this.errMsg += 'Total file sizes are over limit';
            setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          }

        }
        else {

          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          setTimeout(() => this.removeFromUploaderQueue(filename), 100);

        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }
    this.errMsg = "";
    this.uploadSubmit();
  }

  removeFromUploaderQueue(filename: string) {
    var index = -1;
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (filename == fileItem.name) {
        index = i;
        break;
      }
    }

    if (index >= 0) {
      this.uploader.queue[index].remove();
    }

  }

  importFile(event: any) {
    this.errMsg = "";

    if ((this.fileList.length + event.target.files.length) > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;
    //this.fileList = [];
    var files = [];
    if (event.target.files.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event.target.files[i].size >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  this.finishAllUploads = true;
          //  return;
          //}
          //else {
          //  files.push(event.target.files[i]);

          //}
          if (event.target.files[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {             
            this.errMsg = 'Total file sizes are over limit.';
            this.finishAllUploads = true;
            return;
          }
          else {
            files.push(event.target.files[i]);

          }
        }
        else {
          this.finishAllUploads = true;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }
    }

    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            let obj = new FileModel();
            let obj2 = new GroundStopTaskDocumentModel();
            this.maxTempDocId += 1;
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            obj.tempId = this.maxTempDocId;
            obj.isInternal = item.isInternal;
            obj.isActive = true;
            this.totalMessageAttachmentFileSize += item.fileSize * 1024;
            this.fileList.push(obj);

            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.documentSizeInKB = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.tempId = this.maxTempDocId;
            obj2.groundStopTaskDocumentGUID = "";
            obj2.isActive = true;
            obj2.isInternal = item.isInternal;
            this.allTaskDocumentList.push(obj2);
            this.taskDocumentList.push(obj2);
          });
        }
      }
      else {
        this.errMsg = result.message;
      }
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  clickPreviewDocument(e: any, item: GroundStopTaskDocumentModel) {
    let request = new GroundStopTaskDocumentModel();
    request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
    if (item.groundStopTaskDocumentGUID != "") {
      this._groundStopAdvancedService.getGroundStopTaskDocumentBygstdId<ResponseModel<GroundStopTaskDocumentModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model != null) {
            let obj = new GroundStopTaskDocumentModel();
            obj = response.model;
            let byteArray = UtilityFunctions.base64ToArrayBuffer(obj.bFile);
            let file = new Blob([byteArray], { type: obj.mimeType });
            var fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.setAttribute("style", "display:none");
            a.href = fileURL;
            a.target = "_blank";
            switch (obj.fileExtension) {
              case "doc":
              case "docx":
              case "xls":
              case "xlsx":
                a.download = obj.documentName;
            }
            a.click();
            document.body.removeChild(a);

          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
      let file = new Blob([byteArray], { type: item.mimeType });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

  }

  clickRemoveDocument(item: GroundStopTaskDocumentModel) {
    //this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the document: " + item.documentName + "?");
    //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
    //  this.eventSubscription.unsubscribe();
    //  if (result) {
    //    this.isModified = true;
    //    if (item.groundStopTaskDocumentGUID != "") {
    //      // remove doc from database, get newlist and then add unsaved file list
    //      let request = new GroundStopTaskDocumentModel();
    //      request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
    //      this._groundStopAdvancedService.removeGroundStopTaskDocumentBygstdocId<ResponseModel<boolean>>(request).subscribe(response => {
    //        if (response != null && response.code == "200") {
    //          if (response.model) {
    //            this.getTaskDocumentList();
    //          }
    //        }
    //        else {
    //          if (response.code == "401") {
    //            //this.errMsg = response.message
    //            this._authService.signOut();
    //          }

    //        }

    //      })
    //    }
    //    else {
    //      this.fileList.forEach((x, index) => {
    //        if (x.tempId == item.tempId) {
    //          this.fileList.splice(index, 1);
    //        }
    //      }
    //      );
    //      this.taskDocumentList.forEach(
    //        (x, index) => {
    //          if (x.tempId == item.tempId && x.groundStopTaskDocumentGUID == "") {
    //            this.taskDocumentList.splice(index, 1);
    //          }
    //        }
    //      )

    //    }


    //  }
    //})
    this.errMsg = "";
    this.isModified = true;
    if (item.groundStopTaskDocumentGUID != "") {
      // remove doc from database, get newlist and then add unsaved file list
      let request = new GroundStopTaskDocumentModel();
      request.groundStopTaskDocumentGUID = item.groundStopTaskDocumentGUID;
      request.isActive = !item.isActive;
      this._groundStopAdvancedService.removeGroundStopTaskDocumentBygstdocId<ResponseModel<boolean>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model) {
            this.getTaskDocumentList();
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      this.fileList.forEach((x, index) => {
        if (x.tempId == item.tempId) {
          this.totalMessageAttachmentFileSize -= item.fileSize * 1024;
          this.fileList.splice(index, 1);
        }
      }
      );
      this.taskDocumentList.forEach(
        (x, index) => {
          if (x.tempId == item.tempId && x.groundStopTaskDocumentGUID == "") {
            this.taskDocumentList.splice(index, 1);
          }
        }
      )

    }

  }

  onFileClick(event) {
    this.errMsg = "";
    event.target.value = '';
  }
  // upload file end

  getTaskDocumentList() {
    let request = new GroundStopTaskModel()
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    this._groundStopAdvancedService.getGroundStopTaskDocumentBygstId<ResponseModel<GroundStopTaskDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.allTaskDocumentList = [];
        this.allTaskDocumentList = response.model;
        if (this.allTaskDocumentList.filter(x => x.isActive).length > 0) {
          this.hasExistingDocument = true;
        }
        if (this.fileList.length > 0) {
          this.fileList.forEach(x => {
            let obj = new GroundStopTaskDocumentModel();
            obj.documentName = x.fileName;
            obj.fileExtension = x.fileType;
            obj.documentSizeInKB = x.fileSize;
            obj.mimeType = x.mimeType;
            //obj.bFile = x.bFile;
            obj.tempId = x.tempId;
            obj.isInternal = x.isInternal;
            obj.isActive = x.isActive;
            obj.groundStopTaskDocumentGUID = "";
            this.allTaskDocumentList.push(obj);
          });
        }
        if (this.showDeletedDocuments)
          this.taskDocumentList = this.allTaskDocumentList.slice()
        else
          this.taskDocumentList = this.allTaskDocumentList.filter(x => x.isActive == true).slice();
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    })
  }

  clickCopyNote() {
    let s: string = "";
    switch (this.selectedTabName) {
      case "public":
        s = this.f.public_note.value;
        break;
      case "vendor":
        s = this.f.vendor_note.value;
        break;
      case "internal":
        s = this.f.internal_note.value;
        break;
      case "weather":
        if (this.serviceTypeID == 32 || this.serviceTypeID == 85 || this.serviceTypeID == 87) {
          switch (this.selected.value) {
            case 0:
              s = this.f.synopsis_note.value;
              break;
            case 1:
              s = this.f.turbulence_note.value;
              break;
            case 2:
              s = this.f.terminal_note.value;
              break;
            case 3:
              s = this.f.tropical_note.value;
              break;
            case 4:
              s = this.f.va_note.value;
              break;
          }
        }
        else {
          switch (this.selected.value) {
            case 0:
              s = this.f.terminal_note.value;
              break;
            case 1:
              s = this.f.metars_note.value;
              break;
            case 2:
              s = this.f.sigmets_note.value;
              break;
            case 3:
              s = this.f.airmets_note.value;
              break;
            case 4:
              s = this.f.pireps_note.value;
              break;
            case 5:
              s = this.f.tropical_note.value;
              break;
            case 6:
              s = this.f.va_note.value;
              break;
          }
        }
        break;
      case "notams":
        switch (this.selectedNotams.value) {
          case 0:
            s = this.f.departure_note.value;
            break;
          case 1:
            s = this.f.destination_note.value;
            break;
          case 2:
            s = this.f.etp_note.value;
            break;
          case 3:
            s = this.f.fdc_note.value;
            break;
          case 4:
            s = this.f.track_note.value;
            break;
          case 5:
            s = this.f.tfr_notams.value;
            break;
        }
        break;
      default:
        s = this.f.public_note.value;
        break;
    }

    let sNew: string = "";

    if (s != "") {
      var s1 = s.split('\n');
      for (var i = 0; i < s1.length; i++) {
        if (s1[i].trim() == "") {
          s1[i] = " ";
        }
        sNew += s1[i] + "\n";
      }

    }
    else {
      sNew = s;
    }
    return sNew;

    //return s;
  }

  clickCopyHeader() {
    let firstlinestring: string = "";
    let secondlinestring: string = "";
    // registration
    firstlinestring = "Registration"; // 12 characters
    secondlinestring = this.registration;
    if (this.registration.length > 12) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.registration, 12);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.registration, 12);
    }
    // registration end
    // trip code
    firstlinestring += "Trip Code"; // 9
    secondlinestring += this.tripCode;
    if (this.tripCode.length > 9) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, this.tripCode, 9);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, this.tripCode, 9);
    }
    // trip code end
    // business stop
    firstlinestring += "Business Stop"; // 13
    let bs: string = "";
    if (this.prevICAO != "") {
      bs = this.prevICAO + "-";
    }
    bs += this.icao;
    if (this.nextICAO != "") {
      bs += "-" + this.nextICAO;
    }
    secondlinestring += bs;
    if (bs.length > 13) {
      secondlinestring += "  ";
      firstlinestring = this.getFirstLineString(firstlinestring, bs, 13);
    }
    else {
      firstlinestring += "  ";
      secondlinestring = this.getSecondLineString(secondlinestring, bs, 13);
    }
    // business stop end
    // arrival
    let arr1stLineCharacterNumber = firstlinestring.length;
    let arr2ndLineCharacterNumber = secondlinestring.length;
    if (this.arrivalDateTime != "") {
      firstlinestring += "Arrival(" + this.icao + ")"; // 13
      let at: string = this.arrivalDateTime;
      secondlinestring += at;
      if (at.length > 13) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, at, 13);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, at, 13);
      }
    }
    let arr1stLineCharacterNumber2 = firstlinestring.length;
    let arr2ndLineCharacterNumber2 = secondlinestring.length;
    // arrival end
    // departure
    let dep1stLineCharacterNumber = firstlinestring.length;
    let dep2ndLineCharacterNumber = secondlinestring.length;
    if (this.departureDateTime != "") {
      firstlinestring += "Departure(" + this.icao + ")"; // 15
      let dt: string = this.departureDateTime;
      secondlinestring += dt;
      if (dt.length > 15) {
        secondlinestring += "  ";
        firstlinestring = this.getFirstLineString(firstlinestring, dt, 15);
      }
      else {
        firstlinestring += "  ";
        secondlinestring = this.getSecondLineString(secondlinestring, dt, 15);
      }
    }
    // departure
    // aircraft info
    firstlinestring += this.aircraftManufacturer + " " + this.aircraftModel;
    secondlinestring += this.aircraftInfo;
    // aircraft info end
    let thirdEmptySpaceNum = 0;
    let thirdLineString = "";
    if (this.arrivalDateTimeLocal != "") {
      if (arr1stLineCharacterNumber > arr2ndLineCharacterNumber) {
        thirdEmptySpaceNum = arr1stLineCharacterNumber;
      }
      else {
        thirdEmptySpaceNum = arr2ndLineCharacterNumber;
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.arrivalDateTimeLocal;
    }
    if (this.departureDateTimeLocal != "") {
      if (this.arrivalDateTime != "") {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber - arr1stLineCharacterNumber2 + 2;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber - arr2ndLineCharacterNumber2 + 2;
        }
      }
      else {
        if (dep1stLineCharacterNumber > dep2ndLineCharacterNumber) {
          thirdEmptySpaceNum = dep1stLineCharacterNumber;
        }
        else {
          thirdEmptySpaceNum = dep2ndLineCharacterNumber;
        }
      }
      for (var i = 0; i < thirdEmptySpaceNum; i++) {
        thirdLineString += " ";
      }
      thirdLineString += this.departureDateTimeLocal;
    }

    let s: string;
    s = firstlinestring + "\n" + secondlinestring;
    if (thirdLineString != "") {
      s += "\n" + thirdLineString;
    }
    return s;

  }

  getFirstLineString(firstLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (localString.length - headerCharacterNumber); i++) {
      firstLinesString += " ";
    }
    firstLinesString += "  ";
    return firstLinesString;
  }

  getSecondLineString(secondLinesString: string, localString: string, headerCharacterNumber: number) {
    for (var i = 0; i < (headerCharacterNumber - localString.length); i++) {
      secondLinesString += " ";
    }
    secondLinesString += "  ";
    return secondLinesString;
  }

  clickSave(callFrom: string = "", keepOpen: boolean = false) {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.fileList.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      return;
    }

    if (this.totalMessageAttachmentFileSize >= this.maxFileSize) {
      this.errMsg = "The total file size over the limit. Please remove some of documents.";
      return;
    }

    if (this.requiresServiceDate && this.f.task_status_select.value.toLowerCase() == "ae2ee6b7-437a-4f5a-9bdb-0e0a810de2a8") {
      if ((this.f.service_date.value == "" || this.f.service_date.value == null) || (this.f.service_time.value == "" || this.f.service_time.value == null)) {
        this.errMsg = "Service date/time is required."
        return;
      }
    }

    //if (this.serviceTypeID != 35) {
    if (this.requiresServiceDate) {
      if (this.service_dateRef.nativeElement.value !== "") {
        if (this.f.service_date.value != "" && this.f.service_date.value != null && this.f.service_date.value != undefined) {
          let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.service_date.value));
          let f1 = CustomValidators.isValidDate(s1);
          if (!f1) {
            this.f.service_date.setErrors({ isValidDate: f1 });

          }
        }
        else {
          this.f.service_date.setErrors({ isValidDate: false });

        }
      }
    }
    if (this.ground_stop_task_edit_form.invalid || this.errMsg != "") {
      return;
    }

    if (this.f.lead_time.value != "" && this.leadTime_Min_Value == 0) {
      this.calculateLeadTime_DueDate('l', this.f.lead_time.value);
    }
    if (this.f.due_date.value != "" && this.dueDate_Min_Value == 0) {
      this.calculateLeadTime_DueDate('d', this.f.due_date.value);
    }

    let request = new GroundStopTaskModel();

    request.groundStopGUID = this.groundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    request.serviceTypeDescription = this.serviceTypeDesc;
    request.vendorGUID = "";
    request.countryGUID = "";
    if (this.vendorReq && this.serviceTypeID != 3 && this.serviceTypeID != 5 && this.serviceTypeID != 26 && this.serviceTypeID != 54 && this.serviceTypeID != 55 && this.serviceTypeID != 88 && this.serviceTypeID != 98 && this.serviceTypeID != 73) { // handling
      request.vendorGUID = this.f.fbo_select.value;
    }
    if (this.serviceTypeID == 3 || this.serviceTypeID == 88) { // overflight permit
      request.vendorGUID = this.f.vendor_select.value;
      if (this.f.overflight_country_select.value != null)
        request.countryGUID = this.f.overflight_country_select.value['countryGUID'];
    }
    if (this.serviceTypeID == 54 || this.serviceTypeID == 55 || this.serviceTypeID == 98 || this.serviceTypeID == 73) { // customs 
      request.vendorGUID = this.f.customs_vendor_select.value;
    }
    //request.countryGUID = "";
    if (this.serviceTypeID == 5 || this.serviceTypeID == 26) { // landing permit or charter
      request.vendorGUID = this.f.landing_vendor_select.value;
      if (this.f.landing_country_select.value != null)
        request.countryGUID = this.f.landing_country_select.value['countryGUID'];
    }
    request.flightPlanRecallNo = "";
    if (this.serviceTypeID == 35) { // flight plan 
      request.flightPlanRecallNo = this.f.recall_number.value;
    }

    request.overflightPermitOrderNo = 0;
    if (this.serviceTypeID == 3) { // overflight permit
      request.flightPlanRecallNo = this.f.fp_recall_number_select.value;
      if (this.f.overflight_country_select.value != null)
        request.countryGUID = this.f.overflight_country_select.value['countryGUID'];
      if (this.f.permit_order_number.value != "")
        request.overflightPermitOrderNo = Number(this.f.permit_order_number.value);
    }
    request.hotelGUID = "";
    if (this.serviceTypeID == 7 || this.serviceTypeID == 20) {
      request.hotelGUID = this.f.hotel_select.value;
    }
    request.fuelSupplierGUID = "";
    request.fuelIntoPlaneAgentName = "";
    if (this.serviceTypeID == 56) {
      request.fuelSupplierGUID = this.f.intoplane_agent_select.value;
      if (request.fuelSupplierGUID != "")
        request.fuelIntoPlaneAgentName = this.intoPlaneAgentList.find(x => x.vendorGUID.toLowerCase() == request.fuelSupplierGUID.toLowerCase()).intoPlaneAgent;
    }

    request.isActive = true;
    if (this.f.record_status.value == "1")
      request.isActive = true;
    else
      request.isActive = false;
    request.taskStatusGUID = this.f.task_status_select.value;
    request.accountingStatusGUID = this.f.acct_status_select.value;
    request.accountingStatusGUID_TP = this.f.acct_status_tp_select.value;
    request.leadTime_Min = this.f.lead_time.value;
    request.leadTime_Min_Value = this.leadTime_Min_Value;
    request.fpiConfirmationReference = this.f.confirmation_number.value;
    //if (this.serviceTypeID != 35)
    if (this.requiresServiceDate)
      request.serviceDate = this.service_dateRef.nativeElement.value;
    request.serviceTime = this.f.service_time.value;
    request.dueDate_Min = this.f.due_date.value;
    request.dueDate_Min_Value = this.dueDate_Min_Value;
    request.serviceArrangementID = 0;
    if (this.vendorReq) {
      switch (this.serviceTypeID) {
        case 3: // overflight permit
        case 5: // landing permit
        case 26: // charter
        case 88:
          request.serviceArrangementID = Number(this.f.service_arrangement_landing_select.value);
          break;
        default:
          request.serviceArrangementID = Number(this.f.service_arrangement_handling_select.value);
          break;
      }
    }
    else {
      if (this.serviceTypeID == 56) {
        request.serviceArrangementID = Number(this.f.service_arrangement_fuel_select.value);
      }
    }
    if (this.f.isRevisionRequired.value == true) {
      request.isRevisionRequiredValue = this.isRevisionRequiredValue;
    }
    else {
      request.isRevisionRequiredValue = 0;//this.f.isRevisionRequired.value;
    }

    request.revisionCounter = this.revision_counter; //Number(this.f.revision_counter.value);
    request.publicNote = this.f.public_note.value;
    request.vendorNote = this.f.vendor_note.value;
    request.internalNote = this.f.internal_note.value;
    request.importantNote = this.f.important_note.value;
    request.weatherNote = this.f.weather_note.value;
    request.notamsNote = this.f.notams_note.value;
    request.routeNote = this.f.route_note.value;
    if ((this.serviceTypeID == 34 || this.serviceTypeID == 79 || this.serviceTypeID == 32 || this.serviceTypeID == 85 || this.serviceTypeID == 87 || this.serviceTypeID == 83 || this.serviceTypeID == 84 || this.serviceTypeID == 91) && this.selectedGroundStopList.length > 0) {
      request.flightBriefGroundStopGUIDs = [];
      this.selectedGroundStopList.forEach(v => {
        request.flightBriefGroundStopGUIDs.push(v.groundStopGUID);
      });
    }
    request.groundStopTaskDocumentList = [];

    if (this.fileList.length > 0) {
      this.fileList.forEach(x => {
        let obj = new GroundStopTaskDocumentModel();
        obj.documentName = x.fileName;
        obj.fileExtension = x.fileType;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.groundStopTaskDocumentGUID = "";
        obj.isInternal = x.isInternal;
        obj.isActive = x.isActive;
        request.groundStopTaskDocumentList.push(obj);
      });
    }

    request.terminalForecast = this.f.terminal_note.value;
    request.metars = this.f.metars_note.value;
    request.sigmets = this.f.sigmets_note.value;
    request.airmets = this.f.airmets_note.value;
    request.pireps = this.f.pireps_note.value;
    request.tropicalAdvisory = this.f.tropical_note.value;
    request.vaAdvisory = this.f.va_note.value;
    request.departureNotams = this.f.departure_note.value;
    request.destinationNotams = this.f.destination_note.value;
    request.etpNotams = this.f.etp_note.value;
    request.fdcNotams = this.f.fdc_note.value;
    request.trackMessage = this.f.track_note.value;
    request.tfrNotams = this.f.tfr_notams.value;
    request.synopsis = this.f.synopsis_note.value;
    request.turbulence = this.f.turbulence_note.value;
    request.immediateActionRequired = false;
    if (this.f.immediateAction.value == 1) {
      request.immediateActionRequired = true;
    }
    this.loading = true;

    request.isAttachmentIcon = (
      (request.groundStopTaskDocumentList?.filter(doc => doc.isInternal === false && doc.isActive == true).length > 0) ||
      (this.taskDocumentList?.filter(doc => doc.isInternal === false && doc.isActive).length > 0)
    ) ? true : false;
    request.isInternalAttachmentIcon = (
      (request.groundStopTaskDocumentList?.filter(doc => doc.isInternal === true && doc.isActive == true).length > 0) ||
      (this.taskDocumentList?.filter(doc => doc.isInternal === true && doc.isActive).length > 0)
    ) ? true : false;
    request.isPublicNoteIcon = (request.publicNote.trim() != '' && request.publicNote.trim() != null) ? true : false;
    request.isInternalNoteIcon = (request.internalNote.trim() != '' && request.internalNote.trim() != null) ? true : false;


    this._groundStopAdvancedService.updateGroundStopTaskBygstId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.fileList = [];
          this.maxTempDocId = 0;
          this.errMsg = "";
          this.errMsg2 = "";
          this.getTaskDocumentList();
          //this._dialogService.openAlertDialog("");
          this.isModified = true;
          if (callFrom != "flightBrief") {
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
              if (this.serviceTypeID != 29 && this.serviceTypeID != 31 && this.serviceTypeID != 32 && this.serviceTypeID != 33 && this.serviceTypeID != 34 &&
                this.serviceTypeID != 35 && this.serviceTypeID != 76 && this.serviceTypeID != 79 && this.serviceTypeID != 83 && this.serviceTypeID != 84 &&
                this.serviceTypeID != 85 && this.serviceTypeID != 87 && this.serviceTypeID != 91 && !keepOpen)
                this.clickClose();
            }, 1000); //change to 1 second
          }
        }
        else {
          //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          this.errMsg2 = "Failed to save this record at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg2 = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    });

    if (this.fileList.length == 0 && this.taskDocumentList.length > 0) {
      this.taskDocumentList.forEach(x => {
        let obj = new GroundStopTaskDocumentModel();
        obj.documentName = x.documentName;
        obj.fileExtension = x.fileExtension;
        obj.documentSizeInKB = x.fileSize;
        obj.mimeType = x.mimeType;
        obj.bFile = x.bFile;
        obj.groundStopTaskDocumentGUID = x.groundStopTaskDocumentGUID;
        obj.isInternal = x.isInternal;
        obj.isActive = x.isActive;
        request.groundStopTaskDocumentList.push(obj);
      })

      this._groundStopAdvancedService.updateExistingDocumentsBygstId<ResponseModel<boolean>>(request).subscribe(response => {

      })
    }

  }

  clickTab(s: string) {
    if (this.selectedTabName != s) {
      this.selectedTabName = s;
      //if (this.selectedTabName == 'internal' && this.serviceTypeID == 35 && this.f.internal_note.value == '') {
      //let fps: string = "";
      //fps += "CRZ SPD:\n";
      //fps += "FLIGHT LEVEL:\n";
      //fps += "PAYLOAD:\n\n";
      //fps += "DEP FUEL:\n";
      //fps += "FOD:\n";
      //fps += "HOLD:\n";
      //fps += "RESERVE:\n";
      //fps += "TANKERING:\n\n";
      //fps += "ALT:\n";
      //fps += "ETPS:\n\n";
      //fps += "Routing:\n\n";
      //fps += "RMKS:\n";
      //this.f.internal_note.setValue(fps);

      //}
      //else {
      if (this.selectedTabName == 'important_note' && this.f.important_note.value == '') {
        let ims: string = "";
        ims += "WEATHER ALERT FOR ......\n\n\n\n\n";
        ims += "PLEASE NOTE SIGNIFICANT NOTAMS:";
        this.f.important_note.setValue(ims);
      }

      if (this.selectedTabName == 'weather' && this.f.synopsis_note.value == '') {
        let ims: string = "";
        ims += "Departure:\n\n";
        ims += "Significant Weather at Flight Level:\n\n";
        ims += "Turbulence:\n\n";
        ims += "Arrival:\n\n";
        ims += "Flight Plan Notes:\n\n";
        ims += "Significant Notams:\n\n";
        this.f.synopsis_note.setValue(ims);
      }



      //}

    }
  }

  toggleInternalFile(item: GroundStopTaskDocumentModel) {
    var file = this.fileList.find(x => x.fileName == item.documentName);
    if (file !== undefined)
      file.isInternal = !file.isInternal;

    var document = this.taskDocumentList.find(x => x.documentName == item.documentName);
    document.isInternal = !document.isInternal;

   // this.hasModifiedDocuments = true;
  }


  toggleRemoveFile(item: GroundStopTaskDocumentModel) {
    var file = this.fileList.find(x => x.fileName == item.documentName);
    if (file !== undefined)
      file.isActive = !file.isActive;

    var document = this.taskDocumentList.find(x => x.documentName == item.documentName);
    document.isActive = !document.isActive;

   // this.hasModifiedDocuments = true;
  }


  clickWXTab(s: string) {
    if (this.selectedWXTabName != s) {
      this.selectedWXTabName = s;

    }
  }

  clickNotamsTab(s: string) {
    if (this.selectedNotamsTabName != s) {
      this.selectedNotamsTabName = s;

    }
  }

  getHotelList() {
    let request = new HotelModel()
    request.countryGUID = this.countryGUID;
    this._commonService.getHotelList<ResponseModel<HotelModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.hotelList = [];
        this.hotelList = response.model;

      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    })
  }

  clickEditHotel(actionName: string) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //dialogConfig.width = "58em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    let hotelGUID = "";
    if (actionName == 'E') {
      hotelGUID = this.f.hotel_select.value;
    }
    dialogConfig.data = { countryId: this.countryGUID, hotelChainId: "", hotelGUID: hotelGUID, v: this._authService.getCurrentTimeNumber() };
    const dialogRef = this._dialog.open(HotelEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.refresh) {
          this.selectedHotelGUID = result.newId.toLowerCase();
          this.getHotelList();
          this.f.hotel_select.setValue(this.selectedHotelGUID);
        }

      }
    });

  }

  clickContact() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, nextGroundStopGUID: this.nextGroundStopGUID, serviceTypeID: this.serviceTypeID, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(DeliveryCommsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  clickClose() {
    let returnObj = new ReturnObjModel();
    returnObj.isModified = this.isModified;
    returnObj.groundStopGUIDList = this.groundStopGUIDList;
    
    this._dialogRef.close(returnObj);
  }

  generateGenDec() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    let isDeparture = true;
    let country = this.airportCountry;
    if (this.serviceTypeDesc.includes("ARR")) {
      isDeparture = false;
    }

    dialogConfig.data = { groundStopGUID: this.groundStopGUID, tripCodeGUID: this.tripCodeGUID, isDeparture: isDeparture, v: this._authService.getCurrentTimeNumber(), groundStopTaskGUID: this.groundStopTaskGUID, country: country, icao: this.icao };

    //const dialogRef = this._dialog.open(GroundStopServiceSelectionDialogComponent, dialogConfig);
    const dialogRef = this._dialog.open(GenerateTaskDocumentsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTaskDocumentList();
      }
      this.isModified = result;
    });

  }

  generateApis91() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { groundStopGUID: this.groundStopGUID, tripCodeGUID: this.tripCodeGUID, v: this._authService.getCurrentTimeNumber(), groundStopTaskGUID: this.groundStopTaskGUID };

    //const dialogRef = this._dialog.open(GroundStopServiceSelectionDialogComponent, dialogConfig);
    const dialogRef = this._dialog.open(GenerateApis91Component, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.isModified = true;
        this.ngOnInit();
      }
      this.getTaskDocumentList();

    });

  }

  generateApis135() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    dialogConfig.data = { groundStopGUID: this.groundStopGUID, tripCodeGUID: this.tripCodeGUID, v: this._authService.getCurrentTimeNumber(), groundStopTaskGUID: this.groundStopTaskGUID };

    //const dialogRef = this._dialog.open(GroundStopServiceSelectionDialogComponent, dialogConfig);
    const dialogRef = this._dialog.open(GenerateApis135Component, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.isModified = true;
        this.ngOnInit();
      }
      this.getTaskDocumentList();

    });

  }

  generateApisCaricom() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;

    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";

    dialogConfig.data = { groundStopGUID: this.groundStopGUID, tripCodeGUID: this.tripCodeGUID, v: this._authService.getCurrentTimeNumber(), groundStopTaskGUID: this.groundStopTaskGUID };

    //const dialogRef = this._dialog.open(GroundStopServiceSelectionDialogComponent, dialogConfig);
    const dialogRef = this._dialog.open(GenerateApisCaricomComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.isModified = true;
        this.ngOnInit();
      }
      this.getTaskDocumentList();

    });

  }

  openVendorDetails() {
    let vendorID;
    switch (this.serviceTypeID) {
      case 1:
      case 71:
        if (this.f.fbo_select.value == null || this.f.fbo_select.value == "" || this.f.fbo_select.value == "4383ada6-2161-49e6-965f-47fc67fa5cd4")
          return;
        vendorID = this.f.fbo_select.value;
        break;
      case 54:
      case 55:
      case 98:
      case 73:
        if (this.f.customs_vendor_select.value == null || this.f.customs_vendor_select.value == "" || this.f.customs_vendor_select.value == "4383ada6-2161-49e6-965f-47fc67fa5cd4")
          return;
        vendorID = this.f.customs_vendor_select.value;
        break;
      case 3:
      case 88:
      case 71:
        if (this.f.vendor_select.value == null || this.f.vendor_select.value == "" || this.f.vendor_select.value == "4383ada6-2161-49e6-965f-47fc67fa5cd4")
          return;
        vendorID = this.f.vendor_select.value;
        break;
      case 5:
      case 26:
        if (this.f.landing_vendor_select.value == null || this.f.landing_vendor_select.value == "" || this.f.landing_vendor_select.value == "4383ada6-2161-49e6-965f-47fc67fa5cd4")
          return;
        vendorID = this.f.landing_vendor_select.value;
        break;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    dialogConfig.data = {
      vendorId: vendorID,
      icao: this.icao,
      gsID: this.groundStopGUID
    };
    this._dialog.open(VendorDetailsComponent, dialogConfig);
  }


  generateBrief(format: string, briefType: string, vendorDetails: VendorModel, vendorHours: VendorHoursModel[], icao: string) {
    if (format == "notes") {
      this.populateHandlingNotes(vendorDetails);
      return;
    }

    this._templateService.generateHandlingBrief(format, "client", vendorDetails, <VendorModel><unknown>vendorDetails.relatedVendors, vendorHours, icao).subscribe(x => {
      if (x.remarks != "")
        this.errMsg = x.remarks;
      switch (format) {
        case "word": this.loadingWord = false;
          break;
        case "pdf": this.loadingPDF = false;
          if (x.docBFile != undefined) {
            let taskDocument = new GroundStopTaskDocumentModel();
            taskDocument.documentName = "Handling Notes - " + icao + ".pdf";
            taskDocument.fileExtension = "pdf";
            taskDocument.documentSizeInKB = x.docSize
            taskDocument.mimeType = "application/pdf";
            taskDocument.bFile = x.docBFile
            taskDocument.groundStopTaskGUID = this.groundStopTaskGUID;
            taskDocument.tripCode = this.tripCode;
            this._groundStopAdvancedService.insertGroundStopTaskDocument<ResponseModel<number>>(taskDocument).subscribe(response => {
              if (response != null) {
                if (response.code == "200") {
                  this.getTaskDocumentList();
                }
                else {
                  if (response.code == "401")
                    this._authService.signOut();
                  else {
                    this.errMsg = "An error has occurred.";
                  }
                }
              }

            });
          }
          break;
      }
    });
  }
  generateHandlingNotes(format: string) {
    this.errMsg2 = "";
    if (this.f.fbo_select.value == null || this.f.fbo_select.value == "" || this.f.fbo_select.value == "4383ada6-2161-49e6-965f-47fc67fa5cd4")
      return;
    switch (format) {
      case "word": this.loadingWord = true;
        break;
      case "pdf": this.loadingPDF = true;
        break;
    }
    let flightBrief = new FlightBriefModel();
    flightBrief.handlingVendorGUIDs = [this.f.fbo_select.value + "|" + this.ssnVendorID.toString()];
    flightBrief.handlingNotes = [];
    this.getAlternates().subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let alternates = response.model;
          alternates.forEach(x => {
            if (x.vendorGUID != null && x.vendorGUID != "" && x.vendorGUID != this.f.fbo_select.value && x.vendorGUID.toUpperCase() != '4383ADA6-2161-49E6-965F-47FC67FA5CD4')
              flightBrief.handlingVendorGUIDs.push(x.vendorGUID + "|");
          })
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
        }
      }

      let finished = false;
      this._templateService.getVendorDetails(flightBrief).subscribe(r => {
        if (r.refresh)
          finished = true;
        if (finished) {
          r.vendorDetails.forEach(vendor => {
            this.generateBrief(format, "client", vendor, vendor.vendorHours, vendor.icao.substring(0, 4));
          });

        }
        else {
          if (r.remarks != "") {
            this.errMsg2 = r.remarks;
            this.loadingWord = false;
            this.loadingPDF = false;
          }
        }

      });
    });
  }

  populateHandlingNotes(notes: VendorModel) {
    var noteString: string;
    noteString = this.icao + " - " + notes.vendorName + "\n" + "\n";
    if (this.ssnVendorID != null) {
      let relatedVendorDetails = <VendorModel><unknown>notes.relatedVendors;
      noteString += relatedVendorDetails.vendorName + " Representatives" + "\n";
      relatedVendorDetails.vendorPersons.forEach(v => {
        noteString += v.jobTitle + ": " + v.firstName + " " + v.lastName + "\n";
        v.personCommsAddress.forEach(x => {
          noteString += v.jobTitle + " " + x.commAddressType + ": " + x.address + "\n";
        });
      });
      if (relatedVendorDetails.vendorComms != null) {
        relatedVendorDetails.vendorComms.forEach(v => {
          noteString += v.commAddressType + ": " + v.address + "\n";
        });
      }
      noteString += "\n";
    }
    noteString += notes.vendorName + " Primary Contacts" + "\n";
    notes.vendorPersons.forEach(v => {
      noteString += v.jobTitle + ": " + v.firstName + " " + v.lastName + "\n";
      v.personCommsAddress.forEach(x => {
        noteString += v.jobTitle + " " + x.commAddressType + ": " + x.address + "\n";
      });
    });
    if (notes.vendorComms != null) {
      notes.vendorComms.forEach(v => {
        noteString += v.commAddressType + ": " + v.address + "\n";
      });
    }

    if (notes.vendorHours != null) {
      notes.vendorHours.forEach(v => {
        noteString += "\n" + v.hoursTypeDescription + " Hours" + "\n";
        if (v.endDate != null)
          noteString += "Effective Date: " + v.effectiveDate + "\n" + "Expiration Date: " + v.endDate + "\n";
        if (v.is24Hours)
          noteString += "24 Hours \n";
        else {
          v.vendorDailyHours.forEach(h => {
            noteString += h.dayOfWeek + ": ";
            noteString += h.isClosed == 1 ? 'Closed' : h.is24Hours ? '24 Hours' : h.openTime == null || h.openTime == '' ? ' ' : h.openTime + ' - ' + h.closeTime + "\n";
          });
        }
        if (v.remarks != null && v.remarks != '')
          noteString += "Remarks: " + v.remarks + "\n";
      });
    }
    noteString += "\nAirport Notes \n";
    notes.airportNotes.forEach(v => {
      noteString += v.noteTypeDescription + ": " + v.note + "\n";
    })
    noteString += "\nCustom Notes \n";
    notes.customsNotes.forEach(v => {
      noteString += v.noteTypeDescription + ": " + v.note + "\n";
    })
    noteString += "\nHandling Information \n";
    notes.handlingNotes.forEach(v => {
      noteString += v.noteTypeDescription + ": " + v.note + "\n";
    })

    this.f.public_note.setValue(noteString);

  }

  generateMessageTemplate() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container"
    config.data = { gstId: this.groundStopTaskGUID, gsId: this.groundStopGUID, requiredGsId: this.groundStopGUID, tripCodeId: this.tripCodeGUID, serviceTypeId: this.serviceTypeID, customerGUID: this.customerGUID, v: this._authService.getCurrentTimeNumber(), farTypeID: this.farTypeID };
    this._dialog.open(GenerateMessageTemplateComponent, config);
  }

  clickAudit() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: "", aircraftGUID: "", tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID,
      groundStopTaskGUID: this.groundStopTaskGUID, pastHours: 0,
      userName: "", v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }



  onGroundStopListChange(e: any, item: GroundStopModel) {
    this.groundStopList.forEach(x => {
      if (x.groundStopGUID.toLowerCase() == e.source.value.groundStopGUID.toLowerCase()) {
        x.selected = e.source.selected;
      }

    });
    this.setGroundStopList();
  }

  setGroundStopList() {
    this.selectedGroundStopList = this.groundStopList.filter(u => u.selected == true);
  }

  selectWeatherCharts() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = "60em";
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = {
      gstId: this.groundStopTaskGUID, v: this._authService.getCurrentTimeNumber(), weatherChartIds: this.weatherChartIds
    };

    const dialogRef = this._dialog.open(WeatherChartsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

      if (typeof result != "string") {
        this.weatherChartIds = [];
        result.forEach(v => {
          this.weatherChartIds.push(v.weatherChartID.toString());
        });
      }
    });
  }

  compileFlightBrief() {
    this.clickSave("flightBrief");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    //dialogConfig.minWidth = "60em";
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = {
      gstId: this.groundStopTaskGUID, v: this._authService.getCurrentTimeNumber(), tripCodeGUID: this.tripCodeGUID, selectedStops: this.selectedGroundStopList, icao: this.icao,
      registration: this.registration, tripCode: this.tripCode, weatherChartIds: this.weatherChartIds
    };

    const dialogRef = this._dialog.open(CompileFlightBriefDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        this.f.confirmation_number.setValue(result.remarks);
        //this.clickSave("flightBrief");      
      }
    });
  }


  setupFlightBrief() {
    this.clickSave("flightBrief");
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    //dialogConfig.minWidth = "60em";
    dialogConfig.panelClass = "custom-dialog-container";
    let route = this.selectedGroundStopList[0].departureICAO;
    this.selectedGroundStopList.forEach(v => {
      route += '-' + v.arrivalICAO;
    });
    dialogConfig.data = {
      gstId: this.groundStopTaskGUID, v: this._authService.getCurrentTimeNumber(), tripCodeGUID: this.tripCodeGUID, selectedStops: this.selectedGroundStopList, icao: this.icao,
      registration: this.registration, tripCode: this.tripCode, weatherChartIds: this.weatherChartIds, route: route, briefFormat: this.flightBriefFormat, serviceTypeID: this.serviceTypeID
    };
    //const dialogRef = this._dialog.open(FlightBriefSectionsComponent, dialogConfig);
    const dialogRef = this._dialog.open(BuildFlightBriefDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getTaskDocumentList();
      if (result.actionName == "sections" && result.refresh) {
        this.flightBriefFormat = result.newId;
      }
      else {
        if (result.refresh == true) {
          this.f.confirmation_number.setValue(result.remarks);
          //this.clickSave("flightBrief");
        }
      }

    });
  }

  addNewVendor() {
    let s = "Add New Profile";
    let countryGUID = "";
    let icao = undefined;
    if (this.serviceTypeID == 5 || this.serviceTypeID == 26) { // landing permit or charter
      countryGUID = this.f.landing_country_select.value['countryGUID'];
    }
    if (this.serviceTypeID == 3) { // overflight permit
      countryGUID = this.f.overflight_country_select.value['countryGUID'];
    }
    if (this.serviceTypeID != 3 && this.serviceTypeID != 5 && this.serviceTypeID != 26)
      icao = this.icao;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, vendorGUID: null, pageName: 'task', icao: icao, serviceTypeID: this.serviceTypeID, permitCountry: countryGUID };
    const dialogRef = this._dialog.open(VendorProfileEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        switch (result.actionName) {
          case "edit":
            let request = new GroundStopModel();
            request.groundStopGUID = this.groundStopGUID;
            request.tripCodeGUID = this.tripCodeGUID;
            request.icao = this.icao
            request.serviceTypeDescription = this.serviceTypeDesc;
            if (this.serviceTypeID != 3 && this.serviceTypeID != 5 && this.serviceTypeID != 26 && this.vendorReq == true) {
              this._groundStopAdvancedService.getFBOListByAirportServiceType<ResponseModel<VendorModel[]>>(request).subscribe(response => {
                if (response.code == "200" && response.message == "") {
                  this.fboList = response.model;
                  this.selectedFBOGUID = result.newId;
                  this.f.fbo_select.setValue(this.selectedFBOGUID);
                }
                else {
                  if (response.code == "401") {
                    this._authService.signOut();
                  }
                }
              });
            }
            else {
              let request = new GroundStopModel()
              if (this.serviceTypeID == 3 && this.vendorReq == true)
                request.countryGUID = this.f.overflight_country_select.value['countryGUID'];
              if (this.serviceTypeID == 5 || this.serviceTypeID == 26)
                request.countryGUID = this.f.landing_country_select.value['countryGUID'];
              this._groundStopAdvancedService.getVendorListForPermitByAirportCountry<ResponseModel<VendorModel[]>>(request).subscribe(response => {
                if (response != null && response.code == "200") {
                  this.vendorList = response.model;
                  if (this.serviceTypeID == 3) {
                    this.selectedVendorGUID = result.newId;
                    this.f.vendor_select.setValue(this.selectedVendorGUID);
                  }
                  else {
                    this.selectedLandingVendorGUID = result.newId;
                    this.f.landing_vendor_select.setValue(this.selectedLandingVendorGUID);
                  }
                }
                else {
                  this.vendorList = [];
                  if (response.code == "401") {
                    //this.errMsg = response.message
                    this._authService.signOut();
                  }

                }

              })
            }

            break;

        }
      }


    });

  }


  openAlternatPage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    let leg: string = "";

    dialogConfig.data = {
      groundStopGUID: this.groundStopGUID, leg: this.leg, nextGroundStopGUID: this.nextGroundStopGUID,
      tripCodeGUID: this.tripCodeGUID, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(GroundStopAlternateAirportEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        this.isModified = true;
      }
    });
  }

  vendorChange(e: any, controlName: string) {
    let serviceArrangementID = 0;
    this.avoidLocationMsg = "";
    this.avoidBusinessRuleID = 0;
    switch (controlName) {
      case 'fbo_select':
        serviceArrangementID = this.fboList.filter(v => v.vendorGUID == this.f.fbo_select.value)[0].serviceArrangementID;
        this.f.service_arrangement_handling_select.setValue(serviceArrangementID == null ? 1 : serviceArrangementID);
        this.methodOfPayment = this.fboList.filter(v => v.vendorGUID == this.f.fbo_select.value)[0].vendorMethodOfPaymentDescription;
        break;
      case 'vendor_select':
        serviceArrangementID = this.vendorList.filter(v => v.vendorGUID == this.ground_stop_task_edit_form.get(controlName).value)[0].serviceArrangementID;
        this.f.service_arrangement_overflight_select.setValue(serviceArrangementID == null ? 1 : serviceArrangementID);
        this.methodOfPayment = this.vendorList.filter(v => v.vendorGUID == this.f.vendor_select.value)[0].vendorMethodOfPaymentDescription;
        break;
      case 'landing_vendor_select':
        serviceArrangementID = this.vendorList.filter(v => v.vendorGUID == this.ground_stop_task_edit_form.get(controlName).value)[0].serviceArrangementID;
        this.f.service_arrangement_landing_select.setValue(serviceArrangementID == null ? 1 : serviceArrangementID);
        this.methodOfPayment = this.vendorList.filter(v => v.vendorGUID == this.f.landing_vendor_select.value)[0].vendorMethodOfPaymentDescription;
        break;
    }

    if (this.avoidList.length > 0) {
      if (this.avoidList.some(v => (v.serviceTypeID == this.serviceTypeID || v.serviceTypeID == 0) && v.vendorGUID == this.ground_stop_task_edit_form.get(controlName).value)) {
        this.avoidLocation = true;
        this.avoidBusinessRuleID = this.avoidList.find(v => (v.serviceTypeID == this.serviceTypeID || v.serviceTypeID == 0) && v.vendorGUID == this.ground_stop_task_edit_form.get(controlName).value).businessRulesID
        this.avoidLocationMsg = "Vendor should be avoided (BR ID " + this.avoidBusinessRuleID.toString() + ").";
        // this._dialogService.openAlertDialog(this.avoidLocationMsg);
      }

    }
  }

  generateUKGAR() {
    this.errMsg2 = "";
    this.isGARLoading = true;
    let isDeparture = true;
    if (this.serviceTypeID == 80)
      isDeparture = false;
    this._templateService.generateUKGAR(this.tripCodeGUID, this.groundStopGUID, this.groundStopTaskGUID, isDeparture).subscribe(r => {
      if (r.remarks != "")
        this.errMsg2 = r.remarks;
      this.isGARLoading = false;
    });
  }

  generateRemoteClearance(format: string) {
    this.errMsg2 = "";
    if (format == "word")
      this.loadingWord = true;
    else
      this.loadingPDF = true;
    this._templateService.generateRemoteClearance(this.groundStopGUID, this.groundStopTaskGUID, format, this.tripCode).subscribe(r => {
      if (r.remarks != "")
        this.errMsg2 = r.remarks;
      if (r.refresh)
        this.getTaskDocumentList();
      this.loadingWord = false;
      this.loadingPDF = false;
    });
  }

  exportNote(format: string) {
    this.errMsg2 = "";
    let s: string = "";
    switch (this.selectedTabName) {
      case "public":
        s = this.f.public_note.value;
        break;
      case "vendor":
        s = this.f.vendor_note.value;
        break;
      case "internal":
        s = this.f.internal_note.value;
        break;
      case "weather":
        if (this.serviceTypeID == 32 || this.serviceTypeID == 85 || this.serviceTypeID == 87) {
          switch (this.selected.value) {
            case 0:
              s = this.f.synopsis_note.value;
              break;
            case 1:
              s = this.f.turbulence_note.value;
              break;
            case 2:
              s = this.f.terminal_note.value;
              break;
            case 3:
              s = this.f.tropical_note.value;
              break;
            case 4:
              s = this.f.va_note.value;
              break;
          }
        }
        else {
          switch (this.selected.value) {
            case 0:
              s = this.f.terminal_note.value;
              break;
            case 1:
              s = this.f.metars_note.value;
              break;
            case 2:
              s = this.f.sigmets_note.value;
              break;
            case 3:
              s = this.f.airmets_note.value;
              break;
            case 4:
              s = this.f.pireps_note.value;
              break;
            case 5:
              s = this.f.tropical_note.value;
              break;
            case 6:
              s = this.f.va_note.value;
              break;
          }
        }
        break;
      case "notams":
        switch (this.selectedNotams.value) {
          case 0:
            s = this.f.departure_note.value;
            break;
          case 1:
            s = this.f.destination_note.value;
            break;
          case 2:
            s = this.f.etp_note.value;
            break;
          case 3:
            s = this.f.fdc_note.value;
            break;
          case 4:
            s = this.f.track_note.value;
            break;
          case 5:
            s = this.f.tfr_notams.value;
            break;
        }
        break;
      default:
        s = this.f.public_note.value;
        break;
    }
    if (format == "word")
      this.loadingWord = true;
    else
      this.loadingPDF = true;
    let includePageNo = false;
    if (this.serviceTypeID == 31 || this.serviceTypeID == 35 || this.serviceTypeID == 29)
      includePageNo = true;
    let docName = this.registration + ' ' + this.icao + '-' + this.nextICAO + ' ' + this.serviceTypeDesc + ' ' + datepipe.transform(new Date().toUTCString(), 'MMddyy', "UTC");
    this._templateService.exportNotes(this.serviceTypeDesc.toUpperCase(), s, format, docName, includePageNo).subscribe(r => {
      if (r.remarks != "")
        this.errMsg2 = r.remarks;
      if (r.refresh)
        this.getTaskDocumentList();
      this.loadingWord = false;
      this.loadingPDF = false;
    });
  }

  getAlternates(): Observable<any> {
    let obj = new GroundStopAlternateAirportModel();
    obj.groundStopGUID = this.groundStopGUID;
    obj.nextGroundStopGUID = this.groundStopGUID;
    let getGroundStopAltAirport = this._groundStopAdvancedService.getGroundStopAlternateAirportForEditBygsIdnextgsId(obj);
    return getGroundStopAltAirport;
  }

  clickPromoteBusinessRule() {
    let br = new BusinessRulesModel();

    br.serviceTypeID = this.serviceTypeID;
    br.customerGUID = this.customerGUID.toLowerCase();
    br.aircraftGUIDList = this.aircraftGUID;
    br.icao = this.icao;
    br.vendorGUID = "";
    br.taskStatusGUID = this.f.task_status_select.value.toLowerCase();
    if (this.vendorReq && this.serviceTypeID != 3 && this.serviceTypeID != 5 && this.serviceTypeID != 26 && this.serviceTypeID != 54 && this.serviceTypeID != 55 && this.serviceTypeID != 71 && this.serviceTypeID != 98 && this.serviceTypeID != 73) { // handling
      br.vendorGUID = this.f.fbo_select.value.toLowerCase();
    }
    if (this.serviceTypeID == 3 || this.serviceTypeID == 71) { // overflight permit
      br.vendorGUID = this.f.vendor_select.value.toLowerCase();
    }
    if (this.serviceTypeID == 54 || this.serviceTypeID == 55 || this.serviceTypeID == 98 || this.serviceTypeID == 73) { // customs
      br.vendorGUID = this.f.customs_vendor_select.value.toLowerCase();
    }
    br.countryGUID = "";
    if (this.serviceTypeID == 5 || this.serviceTypeID == 26) { // landing permit or charter
      br.vendorGUID = this.f.landing_vendor_select.value.toLowerCase();
      br.countryGUID = this.f.landing_country_select.value['countryGUID'];
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { businessRulesID: 0, brData: br, title: "Add Business Rule from Task" };
    const dialogRef = this._dialog.open(BusinessRulesEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  openBusinessRule() {
    if (this.avoidBusinessRuleID == 0)
      return;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    let s = "View Business Rule " + this.avoidBusinessRuleID.toString();
    let isReadOnly = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { businessRulesID: this.avoidBusinessRuleID, title: s, readonly: isReadOnly };


    const dialogRef = this._dialog.open(BusinessRulesEditComponent, dialogConfig);


  }

  generateMexicoAPIS() {
    this.errMsg2 = "";
    this.isGARLoading = true;
    let isDeparture = true;
    if (this.serviceTypeID == 80)
      isDeparture = false;
    this._templateService.generateMexicoAPIS(this.tripCodeGUID, this.groundStopGUID, this.groundStopTaskGUID, this.tripCode).subscribe(r => {
      if (r.remarks != "")
        this.errMsg2 = r.remarks;
      this.isGARLoading = false;
      this.getTaskDocumentList();
    });
  }

  clearFPNote() {
    let index = this.f.public_note.value.indexOf("\n");
    if (index > 0 && this.f.public_note.value.indexOf("UPLINK") < index) {
      this.f.public_note.setValue(this.f.public_note.value.substring(0, index));
    }
  }

  clickEmail() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    let taskGroundStopGUID: string = "";
    if (this.groundStopGUID.toLowerCase() == this.nextGroundStopGUID.toLowerCase()) {
      taskGroundStopGUID = this.nextGroundStopGUID
    }
    else {
      taskGroundStopGUID = this.groundStopGUID
    }
    let vendorGUID: string = "";
    if (this.vendorReq && this.serviceTypeID != 3 && this.serviceTypeID != 5 && this.serviceTypeID != 26 && this.serviceTypeID != 54 && this.serviceTypeID != 55 && this.serviceTypeID != 88 && this.serviceTypeID != 98 && this.serviceTypeID != 73) { // handling
      vendorGUID = this.f.fbo_select.value;
    }
    if (this.serviceTypeID == 3 || this.serviceTypeID == 88) { // overflight permit
      vendorGUID = this.f.vendor_select.value;
    }
    if (this.serviceTypeID == 54 || this.serviceTypeID == 55 || this.serviceTypeID == 98 || this.serviceTypeID == 73) { // customs 
      vendorGUID = this.f.customs_vendor_select.value;
    }
    //request.countryGUID = "";
    if (this.serviceTypeID == 5 || this.serviceTypeID == 26) { // landing permit or charter
      vendorGUID = this.f.landing_vendor_select.value;
    }
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.depGroundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, nextGroundStopGUID: this.nextGroundStopGUID, serviceTypeID: this.serviceTypeID,
      aircraftGUID: this.aircraftGUID, customerGUID: this.customerGUID, taskGroundStopGUID: taskGroundStopGUID, v: this._authService.getCurrentTimeNumber(),
      fromTaskEditDaialog: true, clientName: this.clientName, tripCode: this.tripCode, leg: this.leg, serviceDesc: this.serviceTypeDesc, requiredGsId: this.groundStopGUID,
      servcieStatus: this.taskStatusList.filter(s => s.taskStatusGUID == this.selectedTaskStatusGUID)[0].taskStatusDescription, farTypeID: this.farTypeID,
      defaultEmail: this.defaultEmail, lockdownTrip: this.lockdownTrip, icao: this.icao, methodOfPayment: this.methodOfPayment, serviceArrangementID: this.selectedServiceArrangementID, vendorGUID: vendorGUID
    };

    const dialogRef = this._dialog.open(EmailInternalDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result['emailAutoGenerated'] == true && (this.serviceTypeID == 1 || this.serviceTypeID == 55)) {
        if(this.f.task_status_select.value === '144b6b7d-94cb-44ff-bcf4-4006001c5ab6') {
          this.f.task_status_select.setValue('d07b2e7c-c070-4f2b-95f5-718936640a74');
          this.taskColor = this.taskStatusList.filter(x => x.taskStatusGUID.toLowerCase() == this.f.task_status_select.value.toLowerCase())[0].hexColor;
          if (this.serviceTypeID==1)
          this.updateTaskStatusForSameCountry();

          //save without closing
          this.clickSave('', true);
        }
      }
      
      if(result['isModified'] == true) {
        this.isModified = true;
      }

    });
  }

  updateTaskStatusForSameCountry() {
    let request: GroundStopTaskModel = new GroundStopTaskModel();
    request.tripCode = this.tripCode;
    request.countryGUID = this.countryGUID;
    request.serviceTypeID = this.serviceTypeID;
    request.groundStopGUID = this.groundStopGUID;
    request.icao = this.icao;
    request.modifiedBy = localStorage.getItem('un');

    this._groundStopService.updateTaskStatusByCountryGroup<ResponseModel<GroundStopTaskModel[]>>(request).subscribe(response => {
      if(response != null && response.code == "200") {
        if(response.model != null) {
          response.model.forEach(item => {
            this.groundStopGUIDList.push(item.groundStopGUID);
          });
        }
      }
    })
  }

  clickTripMessages() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "105em";//"1000px";
    dialogConfig.height = "45em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID,
      registration: this.registration, selectedGroundStopList: this.selectedGroundStopList, tripCodeText: this.tripCode,
      groundStopGUID: this.groundStopGUID, serviceLocation: "A"
    };

    const dialogRef = this._dialog.open(TripMessageCenterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        //this.isModified = true;
      }
    });
  }

  clickEmailSelection() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    let taskGroundStopGUID: string = "";
    if (this.groundStopGUID.toLowerCase() == this.nextGroundStopGUID.toLowerCase()) {
      taskGroundStopGUID = this.nextGroundStopGUID
    }
    else {
      taskGroundStopGUID = this.groundStopGUID
    }
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: taskGroundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, nextGroundStopGUID: this.nextGroundStopGUID,
      serviceTypeID: this.serviceTypeID, openFrom: 'task', v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(EmailAddressSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {


      }

    });
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  generateOverflight() {
    this.errMsg2 = "";
    let countryGUID;
    if (this.serviceTypeID == 3)
      countryGUID = this.f.overflight_country_select.value['countryGUID'];
    else
      countryGUID = this.f.landing_country_select.value['countryGUID']
    switch (countryGUID) {
      case 'cf26325c-5f5c-49da-aab9-d48170d2eef3':
        this._templateService.generateNicaraguaOverflight(this.tripCodeGUID, this.groundStopGUID).subscribe(r => {
          if (r.remarks != "")
            this.errMsg2 = r.remarks;
        });
        break;
      case '56f97055-ec4a-4c16-b811-141f74491fc4':
        if (this.serviceTypeID == 3) {
          this._templateService.generateEcuadorOverflight(this.tripCodeGUID, this.groundStopGUID).subscribe(r => {
            if (r.remarks != "")
              this.errMsg2 = r.remarks;
          });
        }
        break;
      case '711ed9f4-db83-4fc1-923b-6d047c0eb109':
        this._templateService.generateBoliviaOverflight(this.tripCodeGUID, this.groundStopGUID).subscribe(r => {
          if (r.remarks != "")
            this.errMsg2 = r.remarks;
        });
        break;
      case 'cdfabd2d-f5dd-4707-8349-a4c0cc1e0f76':
        this._templateService.generateHondurasOverflight(this.tripCodeGUID, this.groundStopGUID, this.serviceTypeID).subscribe(r => {
          if (r.remarks != "")
            this.errMsg2 = r.remarks;
        });
        break;
      case 'd4315421-ec58-457c-987f-939e52d46990':
        this._templateService.generateCubaOverflight(this.tripCodeGUID, this.groundStopGUID).subscribe(r => {
          if (r.remarks != "")
            this.errMsg2 = r.remarks;
        });
        break;
    }

  }

  taskStatusChange() {
    this.taskColor = this.taskStatusList.filter(x => x.taskStatusGUID.toLowerCase() == this.f.task_status_select.value.toLowerCase())[0].hexColor;
    this.selectedTaskStatusGUID = this.f.task_status_select.value.toLowerCase();
    if (this.selectedTaskStatusGUID == this.clientSetpupStatusGUID) {
      this.clearValidatorsByServices();
    }
    else {
      this.backUpControlValues();
      this.initControls();
      this.clearValidatorsByServices();
    }

  }

  backUpControlValues() {
    this.lead_time = this.f.lead_time.value;
    this.due_date = this.f._due_date.value;
    this.selectedAcctStatusGUID = this.f.acct_status_select.value;
    this.selectedAcctStatusGUID_TP = this.f.acct_status_tp_select.value;
    this.confirmation_number = this.f.confirmation_number.value;
    this.service_date = this.f.service_date.value;
    this.service_time = this.f.service_time.value;
    this.public_note = this.f.public_note.value;
    this.vendor_note = this.f.vendor_note.value;
    this.internal_note = this.f.internal_note.value;
    this.isActive = this.f.record_status.value;
    this.modifiedBy = this.f.modifiedBy.value;
    this.modifiedDate = this.f.modifiedDate.value;
    this.selectedFBOGUID = this.f.fbo_select.value;
    this.recall_number = this.f.recall_number.value;
    this.selectedVendorGUID = this.f.vendor_select.value;
    this.selectedOverflightCountryGUID = "";
    if (this.f.overflight_country_select.value != null) {
      this.selectedOverflightCountryGUID = this.f.overflight_country_select.value['countryGUID'];
    }
    this.selectedLandingVendorGUID = this.f.landing_vendor_select.value;
    this.selectedLandingCountryGUID = "";
    if (this.f.landing_country_select.value != null) {
      this.selectedLandingCountryGUID = this.f.landing_country_select.value['countryGUID'];
    }
    this.permit_order_number = this.f.permit_order_number.value;
    this.selectedFPRecallNumber = this.f.fp_recall_number_select.value;
    this.selectedHotelGUID = this.f.hotel_select.value;
    this.selectedFuelSupplierName = this.f.fuel_supplier_select.value;
    this.selectedFuelSupplierGUID = this.f.intoplane_agent_select.value;
    this.selectedServiceArrangementID = this.f.service_arrangement_fuel_select.value;
    this.selectedServiceArrangementID = this.f.service_arrangement_handling_select.value;
    this.selectedServiceArrangementID = this.f.service_arrangement_landing_select.value;
    this.selectedServiceArrangementID = this.f.service_arrangement_overflight_select.value;
    this.important_note = this.f.important_note.value;
    this.weather_note = this.f.weather_note.value;
    this.notams_note = this.f.notams_note.value;
    this.isRevisionRequired = this.f.isRevisionRequired.value;
    this.terminalForecast = this.f.terminal_note.value;
    this.metars = this.f.metars_note.value;
    this.sigmets = this.f.sigmets_note.value;
    this.airmets = this.f.airmets_note.value;
    this.pireps = this.f.pireps_note.value;
    this.tropicalAdvisory = this.f.tropical_note.value;
    this.vaAdvisory = this.f.va_note.value;
    this.departureNotams = this.f.departure_note.value;
    this.destinationNotams = this.f.destination_note.value;
    this.etpNotams = this.f.etp_note.value;
    this.fdcNotams = this.f.fdc_note.value;
    this.trackMessage = this.f.track_note.value;
    this.tfrNotams = this.f.tfr_notams.value;
    this.synopsis = this.f.synopsis_note.value;
    this.turbulence = this.f.turbulence_note.value;
    this.selectedVendorGUID = this.f.customs_vendor_select.value;
    this.immediateAction = this.f.immediateAction.value;

  }

  timeOnchange(e: any) {
    if (e.target.value != "") {
      let timeObj = new DateTimeObjModel();
      timeObj.timeString = e.target.value;
      timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
      this.f.service_time.setValue(timeObj.timeString);
      if (!timeObj.isValidDateTime) {
        this.f.service_time.setErrors({ isValidDate: false })
      }
    }
  }


  generateCanpass(format: string) {
    this.errMsg2 = "";
    if (format == "word")
      this.loadingWord = true;
    else
      this.loadingPDF = true;
    this._templateService.generateCanpass(this.tripCodeGUID, this.groundStopGUID, this.groundStopTaskGUID, format, this.tripCode).subscribe(r => {
      if (r.remarks != "")
        this.errMsg2 = r.remarks;
      if (r.refresh)
        this.getTaskDocumentList();
      this.loadingWord = false;
      this.loadingPDF = false;
    });
  }


  clickFeedback() {
    const config = new MatDialogConfig();
    // config.panelClass = "custom-dialog-container"
    //config.disableClose = true;
    config.height = "42.5em";//"1000px";
    config.width = "40em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {
      serviceTypeDesc: this.serviceTypeDesc, icao_iata: this.icao_iata, tripCode: this.tripCode, registration: this.registration, arrivalDateTime: this.arrivalDateTime,
      departureDateTime: this.departureDateTime, isTaskFeedback: true, airportID: this.airportID, countryID: this.countryID,
      aircraftGUID: this.aircraftGUID, customerGUID: this.customerGUID, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.groundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID
      , v: this._authService.getCurrentTimeNumber()
    };
    this._dialog.open(FeedbackDialogComponent, config);
  }

  generateFuelQuote() {
    let reportID = this.tripCode + UtilityFunctions.makeID(4).toUpperCase();
    this._templateService.generateFuelQuote([], this.gsInfo, reportID, "word").subscribe(x => {
      if (x.remarks != "") {
        this.errMsg = x.remarks;
        this.isGARLoading = false;
      }
      else
        this.isGARLoading = false;
    });
  }

  toggleDeleted() {
    this.showDeletedDocuments = !this.showDeletedDocuments;
    if (this.showDeletedDocuments)
      this.taskDocumentList = this.allTaskDocumentList.slice()
    else
      this.taskDocumentList = this.allTaskDocumentList.filter(x => x.isActive == true).slice();

  }
}
