<div style="background-color:#f8f8f8">
  <div cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle style="display: flex; justify-content: space-between;">
    <div style="padding-left:1em;margin-top:0.3125em;"><h5 class="mat-h2">Client Setup - {{dialogTitle}}</h5></div>

  </div>

  <div>
    <form *ngIf="customer_profile_main_edit_form" [formGroup]="customer_profile_main_edit_form" (ngSubmit)="clickSave()">
      <div style="display:flex; margin-top:0em; padding-left: 1em;padding-right:1em">
        <div style="margin-top:0em;background-color:white; padding-left:0.5em;padding-right:0.5em;padding-bottom:1em;">
          <div *ngIf="errMsg!=''" class="alert alert-danger p-0" style="display:inline-block; white-space:pre-wrap;margin-top:.5em"><label style="font-size: small">{{errMsg}}</label></div>

          <div style="display:flex">
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="customerName" style="font-size:small">Client Name</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <input type="text" formControlName="customerName" class="form-control inputBox" maxlength="255" style="width:13em" [ngClass]="{ 'is-invalid': submitted && f.customerName.errors }" />
              </div>
              <div *ngIf="submitted && f.customerName.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.customerName.errors.required">Client Name is required.</div>
              </div>
            </div>
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="legalEntityName" style="font-size:small">Legal Entity Name</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <input type="text" formControlName="legalEntityName" class="form-control inputBox" maxlength="255" style="width:13em" [ngClass]="{ 'is-invalid': submitted && f.legalEntityName.errors }" />
              </div>
              <div *ngIf="submitted && f.legalEntityName.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.legalEntityName.errors.required">Legal Entity Name is required.</div>
              </div>
            </div>
            <div class="form-group" style="margin-top:0em; margin-right:0.625em">
              <label for="dbaName" style="font-size:small">DBA Name (Optional)</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                <input type="text" formControlName="dbaName" class="form-control inputBox" maxlength="255" style="width:13em" />
              </div>
            </div>
          </div>

          <div class="d-inline-flex">
            <div>
              <div style="display:flex">
                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em; width:6em">
                  <label for="customer_type" style="font-size:small">Account Status</label>
                  <div style="margin-top:-0.35em;">
                    <select *ngIf="f" formControlName="customer_type" class="form-control form-control-sm dropdown" [ngClass]="{ 'is-invalid': submitted && f.customer_type.errors }">
                      <option *ngFor="let cusType of customerTypeList" [ngValue]="cusType.customerTypeID">{{cusType.customerTypeDesc}}</option>
                    </select>
                  </div>

                  <div *ngIf="submitted && f.customer_type.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.customer_type.errors.required">Account Status is required.</div>
                  </div>
                </div>
                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em; width:6em">
                  <label for="credit_status" style="font-size:small">Credit Status</label>
                  <div style="margin-top:-0.35em;">
                    <select *ngIf="f" formControlName="credit_status" class="form-control form-control-sm dropdown">
                      <option *ngFor="let credStatus of creditStatusList" [ngValue]="credStatus.creditStatusID">{{credStatus.creditStatusDesc}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em; width:6.5em">
                  <label for="apis_carrier_code" style="font-size:small">Apis Carrier Code</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="apis_carrier_code" class="form-control form-control-sm inputBox " style="width:6.5em" [ngClass]="{ 'is-invalid': submitted && f.apis_carrier_code.errors }" />
                  </div>

                  <div *ngIf="submitted && f.apis_carrier_code.errors" class="alert alert-danger p-0 small">
                    <div *ngIf="f.apis_carrier_code.errors.pattern">Invalid format.</div>
                  </div>
                </div>


                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em; width:8.25em">
                  <label for="acct_create_date" style="font-size:small">Account Creation Date</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="acct_create_date" readonly class="form-control" style="width:8em;height:1.5625em;font-size:small;padding:0" />
                  </div>
                </div>

              </div>
              <div class="d-flex">

                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em; width:6em">
                  <label for="sales_territory" style="font-size:small">Sales Territory</label>
                  <div style="margin-top:-0.35em;">
                    <select *ngIf="f" formControlName="sales_territory" class="form-control form-control-sm dropdown">
                      <option *ngFor="let mkt of marketingRegionList" [ngValue]="mkt.marketingRegionID">{{mkt.marketingRegionDesc}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group" style="margin-top:0em; margin-right:0.625em; width:6em">
                  <label for="stock_symbol" style="font-size:small">Stock Symbol</label>
                  <div style="margin-top:-0.35em;">
                    <input type="text" formControlName="stock_symbol" class="form-control form-control-sm inputBox text-uppercase" style="width:5em" />
                  </div>
                </div>             
                <div class="form-group" style="margin-left: 0em;margin-top:0em;margin-right:0.625em; ">
                  <label for="industry_type" style="font-size:small">Industry Type</label>
                  <div style="margin-top:-0.35em;">
                    <select *ngIf="f" formControlName="industry_type" class="form-control form-control-sm dropdown">
                      <option *ngFor="let industry of industryTypeList" [ngValue]="industry.industryTypeID">{{industry.industryDescription}}</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
            <!--<div class="d-flex">
              <div class="form-group" style="margin-top:0em; margin-right:0.625em">
                <label for="internal_note" style="font-size:small">Internal Note</label>
                <div style="margin-top:-0.4em;">
                  <textarea formControlName="internal_note" *ngIf="f" class="form-control" maxlength=255 style="width:22.5em; height:11.25em; font-size:small; padding:0em 0em 0em 0em;background-color:#eaf0fd">   
            </textarea>
                </div>
                </div>
              </div>-->
            </div>
          <!-- <div>
            <mat-checkbox formControlName="prelim_apis" style="transform:scale(.8);margin-left:-1em">Requests Preliminary eAPIS</mat-checkbox>
          </div> -->
    
          <div style=" margin-left: -2.5em; margin-top:0em" > 
            <div class="d-flex flex-column" style="font-size: medium; margin-top:-0.5em; margin-left:-0.75em">
              <mat-slide-toggle style="transform:scale(.8); height:2em; "   formControlName="prelim_apis" color="primary" >Requests Preliminary eAPIS</mat-slide-toggle>
            </div>
            <div class="d-flex flex-column" style="font-size: medium; margin-top:-0.5em; margin-left:-0.75em">
              <mat-slide-toggle style="transform:scale(.8); height:2em; "   formControlName="excludeCCOptions" color="primary" >Turn Off CC Options</mat-slide-toggle>
            </div>
          </div>
          
        </div>
        <div *ngIf="msg!=''" class="d-block" style="margin:0"><label style="color:forestgreen">{{msg}}</label></div>
      </div>
      <div style="width:0.5em; background-color: #f8f8f8;"></div>


    </form>

  </div>
  <div class="d-inline-flex">
    <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em">
      <button mat-button class="btn-high" (click)="clickSave()">Save</button>&nbsp;
      <button mat-button class="btn-low" (click)="clickClose()" >Close</button>&nbsp;
      <button mat-button class="btn-high" *ngIf="customerGUID!='' && pageName!='main'" (click)="clickEdit()">
        Edit Profile
      </button>
    </div>
    <div class="mt-2">
      <app-success-message *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>
    </div>
  </div>
</div>
<app-spin *ngIf="showSpin"></app-spin>





