<div *ngIf="upPII" style="background-color:#f8f8f8;">
  <div style="display: flex;padding-left:1em; justify-content: space-between; " cdkDrag
       cdkDragRootElement=".cdk-overlay-pane"
       cdkDragHandle>
    <!--<div>
      <i class="material-icons" style="font-size: xx-large;cursor: pointer; color: gray">airline_stops</i>
    </div>-->
    <div style="margin-top:0.3125em;"><h5>Audit Trail Details: {{auditHourText}}</h5></div>
    <button  mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button>

  </div>
  <div>
    <form *ngIf="trip_audit_form" [formGroup]="trip_audit_form">
      <div style="padding-left: 1em; padding-right:1em">
        <div style="background-color: white; padding-left: 1em; padding-right:1em; max-height:38em;overflow-y: auto">
          <div *ngIf="tripList.length==0" style="font-size: small">
            <div>{{msg}}</div>
          </div>        
          <div *ngFor="let trip of tripList; let i=index">
            <div *ngIf="i>0"><hr style="background-color: black; margin-bottom:-0.15em" /></div>
            <div style="display:flex">
              <div style="margin-right:1em">
                <div>Trip Code</div>
                <div style="font-weight: bold">{{trip.tripCode}}</div>
              </div>
              <div style="margin-right:1em">
                <div>Registration</div>
                <div style="font-weight: bold">{{trip.registration}}</div>
              </div>
              <div>
                {{trip.aircraftManufacturer}}&nbsp;{{trip.aircraftModel}}
              </div>
            </div>
            <div>
              <table mat-table *ngIf="trip.tripRevisionExcludeTripOpenList.length>0" [dataSource]="trip.tripRevisionExcludeTripOpenList" class="example-container mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="transactionDate">
                    <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em; white-space: nowrap">Transaction Date/Time</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div>{{element.transactionDateTime}}</div>
                      <div style="font-weight:bold;">{{element.userName}}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="changeRequestType">
                    <th mat-header-cell *matHeaderCellDef style="width:25%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em;">Change Request Type</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div *ngIf="element.isNewTrip">New Trip</div>
                      <div *ngIf="element.isTripOpened">Trip Open</div>
                      <div *ngIf="element.isTripLockDown">Trip Lock Down</div>
                      <div *ngIf="element.isRemoveTripFromExternalView">Remove Trip From External View</div>
                      <div *ngIf="element.isTripCancellation">Trip Cancellation</div>
                      <div *ngIf="element.isClientInstructionsChange">Client Instructions</div>
                      <div *ngIf="element.isTripNotesChange">Trip Notes</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="changeRequest">
                    <th mat-header-cell *matHeaderCellDef style="width:30%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em;">Change Request</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div style="white-space:pre-wrap">{{element.changeRequest}}</div>

                    </td>
                  </ng-container>
                  <ng-container matColumnDef="userComments">
                    <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:25%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em;">User Comments</div>
                    </th>
                    <td mat-cell *matCellDef="let element" [hidden]="hideColumn" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div style="white-space: pre-wrap">{{element.userComments}}</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:10%">
                      <div>Action</div>
                    </th>
                    <td mat-cell *matCellDef="let element" [hidden]="hideColumn">
                      <a mat-button class="btn-high grid" style="align-self:center" (click)="clickOpenUserComment($event, element);">
                        User Comments
                      </a>
                    </td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
            <!--gs-->
            <div *ngFor="let gs of trip.groundStopList">
              <div style="display:flex; font-size:small">
                <div style="margin-right:1em">
                  <div>Business Stop</div>
                  <div style="font-weight: bold; margin-top:-0.25em">{{gs.icao}}</div>
                </div>
                <div style="margin-right:1em" *ngIf="!gs.isFirstGroundStop">
                  <div>Arrival ({{gs.icao}})</div>
                  <div style="display:flex; margin-top:-0.25em" *ngIf="gs.arrivalDateTextUTC!=''">
                    <div style="font-weight: bold">{{gs.arrivalDateTextUTC!=''?gs.arrivalDateTextUTC+' Z':''}}</div><div style="font-weight:normal">&nbsp;/ {{gs.arrivalDateTextLocal!=''?gs.arrivalDateTextLocal+' L':''}}</div>
                  </div>
                </div>
                <div style="margin-right:1em" *ngIf="!gs.isLastGroundStop">
                  <div>Departure ({{gs.icao}})</div>
                  <div style="display:flex; margin-top:-0.25em" *ngIf="gs.departureDateTextUTC!=''"><div style="font-weight: bold">{{gs.departureDateTextUTC!=''?gs.departureDateTextUTC+' Z':''}}</div><div style="font-weight:normal">&nbsp;/ {{gs.departureDateTextLocal!=''?gs.departureDateTextLocal+' L':''}}</div></div>
                </div>
              </div>
              <div>
                <table mat-table *ngIf="gs.tripRevisionList.length>0" [dataSource]="gs.tripRevisionList" class="example-container mat-elevation-z8">
                  <tr>
                    <ng-container matColumnDef="transactionDate">
                      <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important; border-right: 0.0625em solid lightgray">
                        <div style="margin-top:-0.25em; white-space: nowrap">Transaction Date/Time</div>
                      </th>
                      <td mat-cell *matCellDef="let elementGS" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                        <div>{{elementGS.transactionDateTime}}</div>
                        <div style="font-weight:bold">{{elementGS.userName}}</div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="changeRequestType">
                      <th mat-header-cell *matHeaderCellDef style="width:25%; text-align:left !important; border-right: 0.0625em solid lightgray">
                        <div style="margin-top:-0.25em;">Change Request Type</div>
                      </th>
                      <td mat-cell *matCellDef="let elementGS" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                        <div *ngIf="elementGS.isNewGroundStop">New Ground Stop</div>
                        <div *ngIf="elementGS.isGroundStopCancellation">Ground Stop Cancellation</div>
                        <div *ngIf="elementGS.isAirportChange">Airport Change</div>
                        <div *ngIf="elementGS.isArrivalDateTimeChange">Arrival Date Time Change</div>
                        <div *ngIf="elementGS.isDepartureDateTimeChange">Departure Date Time Change</div>
                        <div *ngIf="elementGS.isCrewChange">Crew Change</div>
                        <div *ngIf="elementGS.isPAXChange">PAX Change</div>
                        <div *ngIf="elementGS.isTaskStatusChange">Task Status Change</div>
                        <div *ngIf="elementGS.isTaskVendorChange">Task Vendor Change</div>
                        <div *ngIf="elementGS.isTaskConfirmationChange">Task Confirmation Change</div>
                        <div *ngIf="elementGS.isTaskCountryChange">Task Country Change</div>
                        <div *ngIf="elementGS.isTaskNotesChange">Task Notes Change</div>
                        <div *ngIf="elementGS.isTaskDocumentsChange">Task Documents Change</div>
                        <div *ngIf="elementGS.isTaskRevisionRequiredChange">Task Revision Required Change</div>
                        <div *ngIf="elementGS.isTaskRevisionCounterChange">Task Revision Counter Change</div>
                        <div *ngIf="elementGS.isFuelQuoteChange">Fuel Quote Change</div>
                        <div *ngIf="elementGS.isSequenceChange">Route Sequence Change</div>
                        <div *ngIf="elementGS.isGroundStopDetailsChange">Airport Details Change</div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="changeRequest">
                      <th mat-header-cell *matHeaderCellDef style="width:60%; text-align:left !important; border-right: 0.0625em solid lightgray">
                        <div style="margin-top:-0.25em;">Change Request</div>
                      </th>
                      <td mat-cell *matCellDef="let elementGS" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                        <div style="white-space:pre-wrap">{{elementGS.changeRequest}}</div>

                      </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="userComments">
                      <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:25%; text-align:left !important; border-right: 0.0625em solid lightgray">
                        <div style="margin-top:-0.25em;">User Comments</div>
                      </th>
                      <td mat-cell *matCellDef="let elementGS" [hidden]="hideColumn" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                        <div style="white-space: pre-wrap">{{elementGS.userComments}}</div>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:10%">
                        <div>Action</div>
                      </th>
                      <td mat-cell *matCellDef="let elementGS" [hidden]="hideColumn">
                        <a mat-button class="btn-high grid" style="align-self:center" (click)="clickOpenUserComment($event, elementGS);">
                          User Comments
                        </a>
                      </td>
                    </ng-container> -->
                  </tr>
                  <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
                </table>

              </div>

            </div>
            <!--gs end-->
            <div style="margin-top:0.25em">
              <table mat-table *ngIf="trip.tripRevisionForTripOpenList.length>0" [dataSource]="trip.tripRevisionForTripOpenList" class="example-container mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="transactionDate">
                    <th mat-header-cell *matHeaderCellDef style="width:10%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em; white-space: nowrap">Transaction Date/Time</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div>{{element.transactionDateTime}}</div>
                      <div style="font-weight:bold;">{{element.userName}}</div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="changeRequestType">
                    <th mat-header-cell *matHeaderCellDef style="width:25%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em;">Change Request Type</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div *ngIf="element.isNewTrip">New Trip</div>
                      <div *ngIf="element.isTripOpened">Trip Open</div>
                      <div *ngIf="element.isTripLockDown">Trip Lock Down</div>
                      <div *ngIf="element.isRemoveTripFromExternalView">Remove Trip From External View</div>
                      <div *ngIf="element.isTripCancellation">Trip Cancellation</div>
                      <div *ngIf="element.isClientInstructionsChange">Client Instructions</div>
                      <div *ngIf="element.isTripNotesChange">Trip Notes</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="changeRequest">
                    <th mat-header-cell *matHeaderCellDef style="width:30%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em;">Change Request</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div style="white-space:pre-wrap">{{element.changeRequest}}</div>

                    </td>
                  </ng-container>
                  <ng-container matColumnDef="userComments">
                    <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:25%; text-align:left !important; border-right: 0.0625em solid lightgray">
                      <div style="margin-top:-0.25em;">User Comments</div>
                    </th>
                    <td mat-cell *matCellDef="let element" [hidden]="hideColumn" style="text-align: left !important; border-right: 0.0625em solid lightgray">
                      <div style="white-space: pre-wrap">{{element.userComments}}</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:10%">
                      <div>Action</div>
                    </th>
                    <td mat-cell *matCellDef="let element" [hidden]="hideColumn">
                      <a mat-button class="btn-high grid" style="align-self:center" (click)="clickOpenUserComment($event, element);">
                        User Comments
                      </a>
                    </td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>

            </div>
          </div>
          
          
        </div>
      </div>
    </form>
  </div>
      <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
        <div style="margin-right:1em">
          <button type="button" mat-button class="btn-low" (click)="clickClose()" ><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>
        </div>

      </div>
  </div>
  <app-spin *ngIf="showSpin"></app-spin>
