export class MapProfileModel {
  mapProfileID: number;
  airportID: number;
  vendorGUID: string;
  mapLocationID: number;
  mapLocationDescription: string;
  iconName: string;
  iconColor: string;
  latitude: number;
  longitude: number;
  notes: string;
  isPublic: boolean;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  markerText: string;
}
