<!--<div [id]="uniqueId" style="background: #f0f0f0;">-->
<!--[ngClass]="{  'mini1': conditionValue === 1 && isMinimized === true,
    'mini2': conditionValue === 2 && isMinimized === true,
    'mini3': conditionValue === 3 && isMinimized === true }" -->
<!--<div class="dialog-buttons minimizedDialog" cdkDrag cdkDragRootElement=".dialog-buttons" cdkDragHandle
     style="border-radius:5px; margin-bottom: -0.5em; margin-right: -0.4em; display: flex; justify-content: space-between;">


  <div *ngIf="isMinimized" style="margin-top: 0.5em; display: flex;">
    <mat-icon style="font-size: large; margin-top:0.2em; margin-left: 0.2em; margin-right: 0.2em;"> flight</mat-icon>
    <span> Airport Brief {{selectedAirport? selectedAirport.icao: ''}}</span>
  </div>-->
<!-- <button *ngIf = "!isMinimized" mat-icon-button (click)="minimizeDialog()">  <i class="material-icons" style="font-size:x-large;  ">remove</i> </button> -->
<!--<div style="display: flex;">
    <button *ngIf="isMinimized" mat-icon-button (click)="maximizeDialog()"> <i class="material-icons" style="font-size: large; margin-right: -1.2em; ">open_in_full</i> </button>
    <button *ngIf="isMinimized" mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button>
  </div>
</div>

<div class="model-content" [ngClass]="{'minimized': isMinimized}" style="padding: 0em 1em 1em 1em;">-->

<div *ngIf="selectedAirport" style="width: 100%; overflow:hidden"
     cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle>
  <div class="d-flex justify-content-between">
    <div class="d-inline-flex align-items-baseline">
      <div style="margin-right:2em">
        <div style="margin-bottom:-.5em;margin-left:.4em">
          <h5>Trip Planner</h5>
        </div>
        <div>
          <span style="font-size:x-large;font-weight:bold;margin-left:.4em">AIRPORT BRIEF</span>
          <!--<span> <label style="font-weight:bold">FAR Type</label> {{farType}} </span><span *ngIf="route!=''"> <label style="font-weight:bold">Route</label> {{route}}</span>-->
        </div>
      </div>
      <!--<div style="position:relative;min-width:20em;max-width:80%">
        <div [ngStyle]="{'position':  route!=''? '':'absolute'}" style="margin-bottom:-.5em;width:100%">
          <label style="font-weight:bold">FAR Type</label> {{farType}} <label style="font-weight:bold">Route</label> {{route}}
        </div>

      </div>-->

    </div>
    <div>
      <!--<button *ngIf="!isMinimized" mat-icon-button (click)="minimizeDialog()">  <i class="material-icons" style="font-size:x-large;  ">remove</i> </button>-->
      <mat-icon mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <div *ngIf="selectedAirport" style="background-color:white;padding:.5em;">
    <div class="d-flex justify-content-between">
      <div class="d-inline-flex">
        <div style="margin-top:-.5em">
          <span style="font-size:xx-large;font-weight:bold;margin-right:.5em">{{selectedAirport.icao}} {{selectedAirport.iata}}</span> <span style="font-size:x-large;color:gray;font-weight:bold;">{{selectedAirport.airportName}} {{selectedAirport.airportLocation}}</span><span style="font-size:x-large;color:gray;font-weight:bold;" *ngIf="userType=='c'">{{selectedAirport.countryName}}</span> <span *ngIf="userType=='i'" style="font-size: x-large; color: Highlight; cursor: pointer; font-weight: bold;" (click)="openCountryDetails()">{{selectedAirport.countryName}}</span>

        </div>
        <div *ngIf="selectedAirport.flagFile" style="width:4em; margin-left:1em">
          <img style="border: 1px solid lightgray" [src]="getFlag(selectedAirport.flagFile)" />
        </div>
        <!--<div style="margin-left:1em ">
          <button mat-button class="btn btn-med" (click)="openCountryDetails()">Country Details</button>
        </div>-->
      </div>
      <div style="font-size:small">
        UTC Offset {{selectedAirport.utcConversion}}<br />
        Local Time {{selectedAirport.airportLocalDateTime}}
      </div>
    </div>
    <div class="d-flex justify-content-start mt-2">
      <div [ngClass]="{ 'alert-warning': selectedAirport.airportOfEntry=='Yes', 'border': selectedAirport.airportOfEntry!='Yes' }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='notes'">
        <div>Airport of Entry</div>
        <div>{{selectedAirport.airportOfEntry}}</div>
      </div>
      <div [ngClass]="{ 'alert-warning': selectedAirport.isRequiredSlots==1 || selectedAirport.isRequiredSlots==2, 'border': selectedAirport.isRequiredSlots==0 || selectedAirport.isRequiredSlots==null }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='notes'">
        <div>Slots Required</div>
        <div>{{selectedAirport.isRequiredSlots==null? '-' : selectedAirport.isRequiredSlots==1? 'Yes' : selectedAirport.isRequiredSlots==2? 'See Notes' : 'No'}}</div>
      </div>
      <div [ngClass]="{ 'alert-warning': selectedAirport.isRequiredPPR==1 || selectedAirport.isRequiredPPR==2, 'border': selectedAirport.isRequiredPPR==0 || selectedAirport.isRequiredPPR==null }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='notes'">
        <div>PPR Required</div>
        <div>{{selectedAirport.isRequiredPPR==null? '-' : selectedAirport.isRequiredPPR==1? 'Yes' : selectedAirport.isRequiredPPR==2? 'See Notes' : 'No'}}</div>
      </div>
      <div [ngClass]="{ 'alert-warning': selectedAirport.countryRequiresLandingPermit=='Yes' || selectedAirport.countryRequiresLandingPermit=='See Notes', 'border': selectedAirport.countryRequiresLandingPermit=='No' ||selectedAirport.countryRequiresLandingPermit=='n/a' || selectedAirport.countryRequiresLandingPermit==null }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='notes'">
        <div>Landing Permit Required</div>
        <div>{{selectedAirport.countryRequiresLandingPermit==null? '-' : selectedAirport.countryRequiresLandingPermit}}</div>
      </div>
      <div [ngClass]="{ 'alert-danger': selectedAirport.hasRestrictions, 'border': selectedAirport.hasRestrictions==false || selectedAirport.hasRestrictions==null }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='notes'">
        <div>Restrictions</div>
        <div>{{selectedAirport.hasRestrictions==null? '-' : selectedAirport.hasRestrictions? 'Yes':'No'}}</div>
      </div>
      <div *ngIf="advisoryList.length>0" [ngClass]="{ 'alert-danger': advisoryList.length>0, 'border': advisoryList.length==0 }" class="text-center rounded pointer py-1 px-2 mr-2" style="font-size:small" (click)="selectedTab='advisories'">
        <div>Advisory Alerts</div>
        <div>{{advisoryList.length}}</div>
      </div>
      <div class="text-center rounded py-1 px-2 mr-2 border" style="font-size:small">
        <div>FAR Type</div>
        <div>{{farType}}</div>
      </div>
      <div class="text-left rounded py-1 px-2 mr-2 border pointer" style="font-size:small" *ngIf="relatedVendorDetails || vendorDetails" (click)="goToHandling()">
        <div *ngIf="relatedVendorDetails"><i style="font-size: medium;" *ngIf="relatedVendorDetails.ratingID==4" class="material-symbols-outlined filled yellow">grade</i> SSN: <span style="color:#c00000">{{relatedVendorDetails.vendorName}}</span></div>
        <div *ngIf="vendorDetails"><i style="font-size: medium;" *ngIf="vendorDetails.isPreferred" class="material-symbols-outlined filled yellow">grade</i> GH: <span style="color:#c00000">{{vendorDetails.vendorName}}</span></div>
      </div>
      <!--<div class="text-left rounded py-1 px-2 mr-2 border" style="font-size:small">
    <div>
      UTC Offset {{selectedAirport.utcConversion}}
    </div>
    <div>Local Time {{selectedAirport.airportLocalDateTime}}</div>
  </div>-->
    </div>

      </div>
      <section class="mt-2">
        <mat-button-toggle-group [(ngModel)]="selectedTab" [ngModelOptions]="{standalone: true}" (click)="changeTab()">
          <mat-button-toggle *ngIf="showHandler" checked [value]="'handling'"><span *ngIf="isLoadingVendor" class="spinner-border spinner-border-sm mr-1"></span>Handler Information</mat-button-toggle>
          <mat-button-toggle [value]="'notes'"><span *ngIf="isLoadingAirport" class="spinner-border spinner-border-sm mr-1"></span>Airport Notes</mat-button-toggle>
          <mat-button-toggle *ngIf="showHandler" [value]="'customs'"><span *ngIf="isLoadingVendor" class="spinner-border spinner-border-sm mr-1"></span>Customs Notes</mat-button-toggle>
          <mat-button-toggle [value]="'details'"><span *ngIf="isLoadingAirport" class="spinner-border spinner-border-sm mr-1"></span>Airport Details</mat-button-toggle>
          <mat-button-toggle *ngIf="userType=='i'" [value]="'alternates'"><span *ngIf="isLoadingNearby" class="spinner-border spinner-border-sm mr-1"></span>Alternate Airports</mat-button-toggle>
          <!--<mat-button-toggle [value]="'country'" *ngIf="countryDetails && countryNotes!=''">Country Notes</mat-button-toggle>-->
          <mat-button-toggle *ngIf="advisoryList.length>0" [value]="'advisories'">Advisory Alerts</mat-button-toggle>
          <mat-button-toggle *ngIf="userType=='i'" [value]="'transportation'"><span *ngIf="isLoadingAdditional" class="spinner-border spinner-border-sm mr-1"></span>Transportation</mat-button-toggle>
          <mat-button-toggle *ngIf="userType=='i'" [value]="'catering'"><span *ngIf="isLoadingAdditional" class="spinner-border spinner-border-sm mr-1"></span>Catering</mat-button-toggle>
          <mat-button-toggle *ngIf="userType=='i'" [value]="'hotels'"><span *ngIf="isLoadingHotels" class="spinner-border spinner-border-sm mr-1"></span>Hotels</mat-button-toggle>
          <mat-button-toggle *ngIf="userType=='i'" [value]="'trips'">Trip Activity</mat-button-toggle>
          <mat-button-toggle *ngIf="userType=='i' && homebaseAircraft && homebaseAircraft.length>0" [value]="'homebase'">Client Homebase</mat-button-toggle>          
        </mat-button-toggle-group>
      </section>
      <div style="height: 40em; overflow-y: auto; overflow-x: hidden; background-color: white; font-size: small; padding: 1em;">
        <div [hidden]="selectedTab!='handling'">
          <div style="user-select:text" (mousedown)="$event.stopPropagation()">
            <div class="d-inline-flex" style="float:right"><span style="font-size: large; margin-top: .1em; " class="material-symbols-outlined filled yellow">star</span><div style="font-size:small">FPI Preferred Handler/SSN</div></div>  <div class="d-inline-flex" style="width:100%">
              <div style="min-width:60%;margin-right:.5em;flex-grow:1">
                <div style="margin-bottom:1em" *ngIf="relatedVendorDetails && (relatedVendorDetails.vendorComms.length>0 || relatedVendorDetails.vendorPersons.length>0)">
                  <div><label class="font-weight-bold">Special Services Network {{relatedVendorDetails.vendorRoleID==5? '(Supervision)':'(Coordination)'}} - <span style="color:#c00000;font-size:large" >{{relatedVendorDetails.vendorName}}</span></label><i style="font-size: medium;" *ngIf="relatedVendorDetails.ratingID==4" class="material-symbols-outlined filled yellow">grade</i></div>
                  <div *ngIf="userType=='i'" style="margin-top:-.5em;"><span class="font-weight-bold">Payment Method: </span>{{relatedVendorDetails.vendorMethodOfPaymentDescription}}</div>
                  <div *ngIf="userType=='i'" style="margin-bottom:1em;"><span class="font-weight-bold">Contract Status: </span>{{relatedVendorDetails.contractStatusDescription}}</div>
                  <table width="100%">
                    <ng-template ngFor let-person [ngForOf]="relatedVendorDetails.vendorPersons" let-i="index">
                      <tr class="striped-row" *ngIf="userType=='i' || (userType=='c' && person.includeInBrief)">
                        <td colspan="2"><span style="font-weight: bold">{{person.firstName}} {{person.lastName}}</span> {{person.jobTitle}}</td>
                        <!--<td width="35%" style="font-weight: 600">{{person.jobTitle}}</td>
        <td style="font-weight: 600">{{person.firstName}} {{person.lastName}}</td>-->
                      </tr>
                      <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
                        <tr class="striped-row" *ngIf="userType=='i' || (userType=='c' && comm.includeInBrief)">
                          <td style="text-align: right; vertical-align: top" width="20%">{{comm.commAddressType}}: </td>
                          <td *ngIf="comm.commAddressTypeGUID=='fdc854b2-02f2-4029-bd99-75f97ade81f1'"><a target="_blank" noreferrer noopener href="{{comm.address}}">{{comm.address}}</a></td>
                          <td *ngIf="comm.commAddressTypeGUID!='fdc854b2-02f2-4029-bd99-75f97ade81f1'">{{comm.address}}</td>
                        </tr>
                      </ng-template>
                    </ng-template>
                    <ng-template *ngIf="relatedVendorDetails.vendorComms && relatedVendorDetails.vendorComms.length>0" ngFor let-comm [ngForOf]="relatedVendorDetails.vendorComms" let-i="index">
                      <tr class="striped-row">
                        <td style="text-align: right; vertical-align: top" width="20%">{{comm.commAddressType}}: </td>
                        <td *ngIf="comm.commAddressTypeGUID=='fdc854b2-02f2-4029-bd99-75f97ade81f1'"><a target="_blank" noreferrer noopener href="{{comm.address}}">{{comm.address}}</a></td>
                        <td *ngIf="comm.commAddressTypeGUID!='fdc854b2-02f2-4029-bd99-75f97ade81f1'">{{comm.address}}</td>
                      </tr>
                    </ng-template>
                  </table>
                </div>
                <div style="margin-bottom:1em" *ngIf="vendorDetails && (vendorDetails.vendorComms) && (vendorDetails.vendorPersons!=null || relatedVendorDetails.vendorPersons.length>0)">
                  <div><label class="font-weight-bold">Ground Handler - <span style="color: #c00000; font-size: large">{{vendorDetails.vendorName}}</span></label><i style="font-size: medium;" *ngIf="vendorDetails.isPreferred" class="material-symbols-outlined filled yellow">grade</i></div>
                  <div *ngIf="userType=='i'" style="margin-top:-.5em;"><span class="font-weight-bold">Payment Method: </span>{{vendorDetails.vendorMethodOfPaymentDescription}}</div>
                  <div *ngIf="userType=='i'" style="margin-bottom:1em;"><span class="font-weight-bold">Contract Status: </span>{{vendorDetails.contractStatusDescription}}</div>
                  <div>
                    <table width="100%">
                      <ng-template ngFor let-person [ngForOf]="vendorDetails.vendorPersons" let-i="index">
                        <tr class="striped-row" *ngIf="userType=='i' || (userType=='c' && person.includeInBrief)">
                          <td colspan="2"><span style="font-weight: bold">{{person.firstName}} {{person.lastName}}</span> {{person.jobTitle}}</td>
                          <!--<td width="35%" style="font-weight: 600">{{person.jobTitle}}</td>
          <td style="font-weight: 600 ">{{person.firstName}} {{person.lastName}}</td>-->
                        </tr>
                        <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
                          <tr class="striped-row" *ngIf="userType=='i' || (userType=='c' && comm.includeInBrief)">
                            <td style="text-align: right; vertical-align: top" width="20%">{{comm.commAddressType}}: </td>
                            <td *ngIf="comm.commAddressTypeGUID=='fdc854b2-02f2-4029-bd99-75f97ade81f1'"><a target="_blank" noreferrer noopener href="{{comm.address}}">{{comm.address}}</a></td>
                            <td *ngIf="comm.commAddressTypeGUID!='fdc854b2-02f2-4029-bd99-75f97ade81f1'">{{comm.address}}</td>
                          </tr>
                        </ng-template>
                      </ng-template>
                      <tr class="striped-row" *ngIf="vendorDetails.vendorComms">
                        <td colspan="2" style="font-weight: bold">Main Office</td>
                      </tr>
                      <ng-template ngFor let-comm [ngForOf]="vendorDetails.vendorComms" let-i="index">
                        <tr class="striped-row">
                          <td style="text-align: right; vertical-align: top" width="20%">{{comm.commAddressType}}: </td>
                          <td *ngIf="comm.commAddressTypeGUID=='fdc854b2-02f2-4029-bd99-75f97ade81f1'"><a target="_blank" noreferrer noopener href="{{comm.address}}">{{comm.address}}</a></td>
                          <td *ngIf="comm.commAddressTypeGUID!='fdc854b2-02f2-4029-bd99-75f97ade81f1'">{{comm.address}}</td>
                        </tr>
                      </ng-template>
                    </table>
                  </div>
                </div>
            <div *ngIf="vendorDetails && vendorDetails.vendorHours!=null && vendorDetails.vendorHours.length>0">
              <div>
                <label class="font-weight-bold">Handler Hours</label>
              </div>
              <div>
                <ng-template ngFor let-hours [ngForOf]="vendorHours" let-i="index">
                  <table width="100%">                    
                    <tr *ngIf="hours.endDate!=null"><td width="20%">Effective Date</td><td>{{hours.effectiveDate}}</td></tr>
                    <tr *ngIf="hours.endDate!=null"><td width="20%">Expiration Date</td><td>{{hours.endDate}}</td></tr>
                    <tr *ngIf="hours.is24Hours"><td colspan="2">24 Hours</td></tr>
                    <tr *ngIf="hours.sameHours"><td colspan="2">{{hours.isClosed==1? 'Closed' : hours.is24Hours? '24 Hours': hours.openTime==null || hours.openTime==''? ' ': hours.openTime + ' - ' +  hours.closeTime}} Daily</td></tr>
                    <ng-template *ngIf="hours.is24Hours==0 && !hours.sameHours" ngFor let-element [ngForOf]="hours.vendorDailyHours" let-i="index">
                      <tr>
                        <td width="20%">{{element.dayOfWeek}}</td>
                        <td>{{element.isClosed==1? 'Closed' : element.is24Hours? '24 Hours': element.openTime==null || element.openTime==''? ' ': element.openTime + ' - ' +  element.closeTime}}</td>
                      </tr>
                    </ng-template>
                    <tr *ngIf="hours.remarks!=null && hours.remarks!=''"><td width="20%">Remarks</td><td>{{hours.remarks}}</td></tr>
                  </table>
                  <br />
                </ng-template>
              </div>

            </div>
          </div>
          <div style="width:40%;margin-top:1em">
            <div style="margin-bottom:1em" *ngIf="relatedVendorDetails && relatedVendorDetails.vendorRoleID==5">
              <div style="color:#c00000;font-weight:bold">Supervision </div>
              <div>The Special Services Network (SSN) team coordinates your operational requirements prior to arrival and is an extension of your crew while in-country.  Upon arrival and departure, our SSN supervisory representative is dedicated to your flight only, ensuring that all your needs are met. The personal attention provided by the SSN supervisor will be over and above the services received by the ground handler. </div>
            </div>
            <div style="margin-bottom:1em" *ngIf="relatedVendorDetails && relatedVendorDetails.vendorRoleID==19">
              <div style="color:#c00000;font-weight:bold">
                Coordination
              </div>
              <div>
                The Special Services Network (SSN) team coordinates your operational requirements prior to arrival and is an extension of your crew while in-country.  Our remote SSN coordinator is a phone call away to provide dedicated support to your flight, ensuring that all your needs are met. The attention provided by the SSN coordinator will be over and above the services received by the ground handler.
              </div>
            </div>

            <div style="width:100%;">
              <div style="position: relative;" (mousedown)="$event.stopPropagation()">
                <div style="width:100%;height:25em;border-radius:.25em" id="{{'map'+handlerGUID}}"></div>
                <div *ngIf="selectedAirport" style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
                  fullscreen
                </div>
              </div>

            </div>
            <div>
              <ng-template ngFor let-profile [ngForOf]="vendorDetails?.vendorMapProfiles" let-i="index">
                <div>{{profile.markerText}}. {{profile.mapLocationDescription}} <span *ngIf="profile.notes" >- {{profile.notes}}</span></div>

              </ng-template>
              <!--<table>
    <ng-template ngFor let-profile [ngForOf]="vendorDetails?.vendorMapProfiles" let-i="index">
      <tr><td>{{profile.markerText}} - </td><td>{{profile.mapLocationDescription}}</td></tr>
      <tr><td></td><td>{{profile.notes}}</td></tr>

    </ng-template>
  </table>-->
            </div>
          </div>
        </div>

        <div class="mt-2" *ngIf="vendorDetails && vendorDetails.handlingNotes!=null && vendorDetails.handlingNotes.length>0">

          <div>
            <table width="100%">
              <ng-template ngFor let-note [ngForOf]="vendorDetails.handlingNotes" let-i="index">
                <tr class="striped-row"><td width="20%" valign="top">{{note.noteTypeDescription}}</td><td style="white-space:pre-wrap">{{note.note}}</td></tr>
              </ng-template>
            </table>
          </div>
        </div>
        <div class="mt-2" *ngIf="userType=='i' && relatedVendorDetails && relatedVendorDetails.vendorDocuments!=null && relatedVendorDetails.vendorDocuments.length>0">

          <div>
            <table width="100%">
              <thead><tr><th>GH Documents</th></tr></thead>
              <thead>
                <tr>
                  <td>Type</td>
                  <td>Name</td>
                  <td>Effective</td>
                  <td>Expiration</td>
                </tr>
              </thead>
              <ng-template ngFor let-doc [ngForOf]="relatedVendorDetails.vendorDocuments" let-i="index">
                <tr class="striped-row">
                  <td>{{doc.documentTypeDescription}}</td>
                  <td>
                    <a href="javascript:void(0)" style="cursor:pointer" (click)="getVendorDocuments(doc)">{{doc.documentName}}</a>
                  </td>
                  <td>{{doc.effectiveDate}}</td>
                  <td>{{doc.expirationDate}}</td>
                </tr>
              </ng-template>
            </table>
          </div>
        </div>
        <div class="mt-2" *ngIf="userType=='i' && vendorDetails && vendorDetails.vendorDocuments!=null && vendorDetails.vendorDocuments.length>0">

          <div>
            <table width="100%">
              <thead><tr><th>GH Documents</th></tr></thead>
              <thead>
                <tr>
                  <td>Type</td>
                  <td>Name</td>
                  <td>Effective</td>
                  <td>Expiration</td>
                </tr>
              </thead>
              <ng-template ngFor let-doc [ngForOf]="vendorDetails.vendorDocuments" let-i="index">
                <tr class="striped-row">
                  <td>{{doc.documentTypeDescription}}</td>
                  <td>
                    <a href="javascript:void(0)" style="cursor:pointer;" (click)="getVendorDocuments(doc)">{{doc.documentName}}</a>
                  </td>
                  <td>{{doc.effectiveDate}}</td>
                  <td>{{doc.expirationDate}}</td>
                </tr>
              </ng-template>
            </table>
          </div>
        </div>

      </div>
    </div>
    <div [hidden]="selectedTab!='notes'">
      <div style="user-select:text" (mousedown)="$event.stopPropagation()" *ngIf="selectedAirport">

        <div>
          <table width="100%">
            <tr class="striped-row"><td width="20%" valign="top">Airport of Entry</td><td style="white-space:pre-wrap">{{selectedAirport.airportOfEntry}}</td></tr>
            <ng-template *ngIf="selectedAirport.airportNotes" ngFor let-note [ngForOf]="selectedAirport.airportNotes" let-i="index">
              <tr class="striped-row"><td width="20%" valign="top">{{note.noteType}}</td><td style="white-space:pre-wrap">{{note.airportNote}}</td></tr>
            </ng-template>
            <ng-container *ngIf="userType=='i'">
              <tr class="striped-row">
                <td width="20%" valign="top">Landing Permit</td>
                <td style="white-space:pre-wrap">
                  <span *ngIf="((landingPermitNotes!=null && landingPermitNotes!='') || (showValidity || showLeadTime || showDueDate)) && !countryPermitNotRequired">
                    {{landingPermitNotes!=null && landingPermitNotes!=''? landingPermitNotes : ''}}{{landingPermitNotes!=null && landingPermitNotes!='' && (showValidity || showLeadTime || showDueDate)? '\n\n':''}}{{showValidity? 'Validity: ' + landingPermitValidity + ' ' : ''}}{{showLeadTime? 'Lead Time: ' + landingPermitLeadTime + ' ' : ''}}{{showDueDate? 'Due Date: ' + landingPermitDueDate + ' ' : ''}}
                  </span>
                  <span *ngIf="countryPermitNotRequired">Not Required</span>
                </td>
              </tr>
            </ng-container>
            <ng-container *ngIf="userType=='i' && landingRequiredDocuments && landingRequiredDocuments.length>0">
              <tr class="striped-row">
                <td width="20%" valign="top">Landing Permit Documents</td>
                <td style="white-space:pre-wrap">
                  <ng-template ngFor let-doc [ngForOf]="landingRequiredDocuments" let-i="index">{{doc.documentType}}<br /></ng-template>
                </td>
              </tr>
            </ng-container>
          </table>
          <div class="mt-2" *ngIf="selectedAirport.documentList && selectedAirport.documentList.length>0">
            <table width="100%">
              <thead><tr><th>Additional Documents</th></tr></thead>
              <thead>
                <tr>
                  <td>Type</td>
                  <td>Name</td>
                  <td>Effective</td>
                  <td>Expiration</td>
                  <td *ngIf="userType=='i'" align="center">Show to Client</td>
                </tr>
              </thead>
              <ng-template ngFor let-doc [ngForOf]="selectedAirport.documentList" let-i="index">
                <tr class="striped-row">
                  <td>{{doc.documentTypeList}}</td>
                  <td>
                    <a style="cursor:pointer" (click)="getAirportDocuments(doc)" matTooltipClass="tooltipLineBreak"
                       matTooltip="{{doc.documentDescription }}">{{doc.documentName}}</a>
                  </td>
                  <td>{{doc.effectiveDate}}</td>
                  <td>{{doc.expirationDate}}</td>
                  <td *ngIf="userType=='i'" align="center">{{doc.showToClient? 'Yes':'No'}}</td>
                </tr>
              </ng-template>
            </table>
          </div>
        </div>
      </div>

    </div>
    <div [hidden]="selectedTab!='customs'">
      <div style="user-select:text" (mousedown)="$event.stopPropagation()">
        <div *ngIf="vendorDetails && vendorDetails.customsNotes!=null && vendorDetails.customsNotes.length>0">
          <!--<div>
            <label class="font-weight-bold">{{vendorDetails.customsNotes[0].groupName}}</label>
          </div>-->
          <div>
            <table width="100%">
              <ng-template ngFor let-note [ngForOf]="vendorDetails.customsNotes" let-i="index">
                <tr class="striped-row"><td width="20%" valign="top">{{note.noteTypeDescription}}</td><td style="white-space:pre-wrap">{{note.note}}</td></tr>
              </ng-template>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="selectedTab!='details'">
      <div style="user-select:text" (mousedown)="$event.stopPropagation()">
        <div class="d-inline-flex" style="width:100%">
          <div style="width:45%">

            <div style="width:100%;">
              <div style="position: relative;" (mousedown)="$event.stopPropagation()">
                <div style="width:100%;height:39em;border-radius:.25em" id="{{'details-map'+airportID}}"></div>
                <div *ngIf="selectedAirport" style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
                  fullscreen
                </div>
              </div>
            </div>
          </div>
          <div (mousedown)="$event.stopPropagation()" style="user-select:text;width:55%;margin-left:1em">
            <div style="margin-bottom:1em">
              Lat/Long {{selectedAirport.latitudeDMS}} / {{selectedAirport.longitudeDMS}}
            </div>
            <div>
              Elevation (ft) {{selectedAirport.elevation}}
            </div>
            <div>
              Lighting {{selectedAirport.lighting}}
            </div>
            <div>
              Approach {{selectedAirport.approachList}}
            </div>
            <div style="margin-top:1em">
              Distance from City {{selectedAirport.distanceFromCity}}
            </div>
            <div>
              Airport Type {{selectedAirport.airportType}}
            </div>

            <div class="d-inline-flex" style="width:100%;margin-top:1em">
              <div style="margin-right:1.5em">
                <h6>Longest Runway</h6>
                <div>
                  Primary Runway {{selectedAirport.primaryRunway}}
                </div>
                <div>
                  Max Runway Length (ft) {{selectedAirport.runwayMaxLength}}
                </div>
                <div>Max Runway Width (ft) {{selectedAirport.runwayMaxWidth}}</div>
              </div>
              <div style="margin-right:1.5em">
                <h6>Frequency</h6>
                <ng-template ngFor let-element [ngForOf]="selectedAirport.airportFrequencies" let-i="index">
                  <div>{{element.frequencyCommsType}} {{element.frequency}} MHz</div>
                </ng-template>
              </div>
              <div>
                <h6>Observes Daylight Saving - {{selectedAirport.hasDaylightSavings}}</h6>
                <div *ngIf="selectedAirport.hasDaylightSavingsBool">
                  DLS Start {{selectedAirport.dlsStart}}
                </div>
                <div *ngIf="selectedAirport.hasDaylightSavingsBool">
                  DLS End {{selectedAirport.dlsEnd}}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [hidden]="selectedTab!='country'">
      <div style="user-select: text; white-space: pre-wrap" (mousedown)="$event.stopPropagation()" *ngIf="countryDetails">
        {{countryNotes}}
      </div>

    </div>
    <div [hidden]="selectedTab!='advisories'">

      <div style="width:100%;max-height:34em;overflow-y:auto">
        <div *ngIf="advisoryList.length==0">No Advisories Found</div>
        <table *ngIf="advisoryList.length>0" mat-table [dataSource]="advisoryList" class="advisory-table">
          <tr>
            <ng-container matColumnDef="rating">
              <th mat-header-cell *matHeaderCellDef width="10%">
                Rating
              </th>
              <td mat-cell *matCellDef="let element">{{element.advisoryRating}}</td>
            </ng-container>
            <ng-container matColumnDef="dates">
              <th mat-header-cell *matHeaderCellDef width="10%">
                Effective Dates
              </th>
              <td mat-cell *matCellDef="let element" style="text-align:left !important;">
                <span style="display: flex;">
                  {{element.effectiveDate}} <span *ngIf="element.expirationDate">- {{element.expirationDate}}</span>z
                </span>
                <span style="display: flex;">
                  {{element.advisoryValidTimeStart}} <span *ngIf="element.advisoryValidTimeEnd">
                    -
                    {{element.advisoryValidTimeEnd}}
                  </span>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="area">
              <th mat-header-cell *matHeaderCellDef width="10%">
                Area of Interest
              </th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.icao">{{ element.icao }}&nbsp; {{ element.iata }}, </div>
                <div *ngIf="element.countryName">{{ element.countryName }}</div>
                <div *ngIf="element.stateProvinceName">{{ element.stateProvinceName }}</div>
                <div *ngIf="element.groundHandlerVendorName">
                  {{ element.groundHandlerVendorName}}
                </div>
                <div *ngIf="element.ssnVendorName!=null" style="cursor: pointer"> {{ element.ssnVendorName }}</div>
                <div *ngIf="element.fuelSupplierName!=null">
                  {{ element.fuelSupplierName }}
                </div>
                <div *ngIf="element.intoplaneAgent!=null">
                  {{ element.intoplaneAgent  }}
                </div>
                <div *ngIf="element.hotelName!=null"> {{ element.hotelName }}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef width="10%">
                Advisory Type
              </th>
              <td mat-cell *matCellDef="let element">{{element.advisoryType}}</td>
            </ng-container>
            <ng-container matColumnDef="issue">
              <th mat-header-cell *matHeaderCellDef width="60%">
                Issue
              </th>
              <td mat-cell *matCellDef="let element">
                <div style="font-weight: bold;">{{element.title}}</div>
                <div class="ellipsis">{{element.issue}}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="hasDocument">
              <th mat-header-cell *matHeaderCellDef width="3%">

              </th>
              <td mat-cell *matCellDef="let element"><span *ngIf="element.hasDocument" class="material-symbols-outlined">attach_file</span></td>
            </ng-container>
            <ng-container matColumnDef="hasLink">
              <th mat-header-cell *matHeaderCellDef width="3%">

              </th>
              <td mat-cell *matCellDef="let element"><span *ngIf="element.weblink!=null && element.weblink!=''" class="material-symbols-outlined">link</span></td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef width="5%">

              </th>
              <td mat-cell *matCellDef="let element"><button mat-button class="btn-low grid">View</button></td>
            </ng-container>

          </tr>
          <tr mat-header-row *matHeaderRowDef="advisoryColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: advisoryColumn" (click)="viewAdvisory(row)"></tr>
        </table>
      </div>

    </div>

    <div [hidden]="selectedTab!='alternates'">
      <div class="d-inline-flex" style="margin-bottom:-.5em">
        <mat-checkbox style="transform:scale(.8);font-size:medium;margin-top:.6em" color="primary" [(ngModel)]="isPreferred" [ngModelOptions]="{standalone: true}" (change)="getNearbyAirports()">Preferred</mat-checkbox>
        <label style="font-size:small;margin-top:1em;width:10em">Distance in NM: {{distance}}</label>
        <mat-slider (mousedown)="$event.stopPropagation()" thumbLabel tickInterval="50" step="50" min="50" max="1000" color="primary" [(ngModel)]="distance" [ngModelOptions]="{standalone: true}" (change)="getNearbyAirports()"></mat-slider>

          </div>
          <div class="d-inline-flex" style="width:100%">
            <div style="width:65%;max-height:34em;overflow-y:auto">
              <table mat-table [dataSource]="nearbyAirports" class="example-container">
                <tr>
                  <ng-container matColumnDef="preferred">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      Preferred
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.isPreferredAlternate? 'Yes':''}}</td>
                  </ng-container>
                  <ng-container matColumnDef="airport">
                    <th mat-header-cell *matHeaderCellDef width="20%">
                      Airport
                    </th>
                    <td mat-cell *matCellDef="let element" style="text-align:left !important;" >{{element.icao}} {{element.iata}} {{element.airportName}} {{element.airportLocationWithCountryNameAbbr}}</td>
                  </ng-container>
                  <ng-container matColumnDef="distance">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      Distance
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.distanceFromAirport}}</td>
                  </ng-container>
                  <ng-container matColumnDef="airport_of_entry">
                    <th mat-header-cell *matHeaderCellDef width="6%" matTooltip="Airport of Entry">
                      AOE
                    </th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.airportOfEntry=='Yes'? 'beige':'', 'font-weight': element.airportOfEntry=='Yes'? '500':''}"><div>{{element.airportOfEntry}}</div></td>
                  </ng-container>
                  <ng-container matColumnDef="restrictions">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      Restrictions
                    </th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.hasRestrictions? 'beige':'', 'font-weight': element.hasRestrictions? '500':''}">{{element.hasRestrictions==null? '-' : element.hasRestrictions? 'Yes':'No'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="slots">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      Slots
                    </th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.isRequiredSlots >0? 'beige':'', 'font-weight': element.isRequiredSlots !=0? '500':''}">{{element.isRequiredSlots==null? '-' : element.isRequiredSlots==1? 'Yes': element.isRequiredSlots==2? 'Notes':'No'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="ppr">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      PPR
                    </th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.isRequiredPPR >0? 'beige':'', 'font-weight': element.isRequiredPPR !=0? '500':''}">{{element.isRequiredPPR==null? '-' : element.isRequiredPPR==1? 'Yes': element.isRequiredPPR==2? 'Notes':'No'}}</td>
                  </ng-container>
                  <ng-container matColumnDef="landing_permit">
                    <th mat-header-cell *matHeaderCellDef width="5%">
                      Landing Permit
                    </th>
                    <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.countryRequiresLandingPermit=='Yes' || element.countryRequiresLandingPermit=='See Notes'? 'beige':'', 'font-weight': element.countryRequiresLandingPermit=='Yes' || element.countryRequiresLandingPermit=='See Notes'? '500':''}">{{element.countryRequiresLandingPermit==null? '-' : element.countryRequiresLandingPermit}}</td>
                  </ng-container>

            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="openAirportBrief(row)"></tr>
          </table>
        </div>
        <div style="width:35%; margin-left:1em">
          <div style="width:100%;">
            <div style="position: relative;" (mousedown)="$event.stopPropagation()">
              <div style="width:100%;height:36em;border-radius:.25em" id="{{'nearby-map'+airportID}}"></div>
              <div *ngIf="nearbyAirports" style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
                fullscreen
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div [hidden]="selectedTab!='transportation'">
      <div *ngIf="transportationVendors==null || transportationVendors.length==0">No Records Found</div>
      <div *ngIf="transportationVendors" style="user-select:text" (mousedown)="$event.stopPropagation()">
        <ng-template ngFor let-vendorDetails [ngForOf]="transportationVendors" let-i="index">
          <div><label class="font-weight-bold"><span style="color: #c00000; font-size: large">{{vendorDetails.vendorName}}</span></label><i style="font-size: medium;" *ngIf="vendorDetails.isPreferred" class="material-symbols-outlined filled yellow">grade</i></div>
          <div>
            <table width="60%">
              <ng-template ngFor let-person [ngForOf]="vendorDetails.vendorPersons" let-i="index">
                <tr class="striped-row" *ngIf="userType=='i' || (userType=='c' && person.includeInBrief)">
                  <td colspan="2"><span style="font-weight: bold">{{person.firstName}} {{person.lastName}}</span> {{person.jobTitle}}</td>
                  <!--<td width="35%" style="font-weight: 600">{{person.jobTitle}}</td>
              <td style="font-weight: 600 ">{{person.firstName}} {{person.lastName}}</td>-->
                </tr>
                <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
                  <tr class="striped-row" *ngIf="userType=='i' || (userType=='c' && comm.includeInBrief)">
                    <td style="text-align: right; vertical-align: top" width="20%">{{comm.commAddressType}}: </td>
                    <td *ngIf="comm.commAddressTypeGUID=='fdc854b2-02f2-4029-bd99-75f97ade81f1'"><a target="_blank" noreferrer noopener href="{{comm.address}}">{{comm.address}}</a></td>
                    <td *ngIf="comm.commAddressTypeGUID!='fdc854b2-02f2-4029-bd99-75f97ade81f1'">{{comm.address}}</td>
                  </tr>
                </ng-template>
              </ng-template>
              <tr class="striped-row" *ngIf="vendorDetails.vendorComms && vendorDetails.vendorComms.length>0">
                <td colspan="2" style="font-weight: bold">Main Office</td>
              </tr>
              <ng-template ngFor let-comm [ngForOf]="vendorDetails.vendorComms" let-i="index">
                <tr class="striped-row">
                  <td style="text-align: right; vertical-align: top" width="20%">{{comm.commAddressType}}: </td>
                  <td *ngIf="comm.commAddressTypeGUID=='fdc854b2-02f2-4029-bd99-75f97ade81f1'"><a target="_blank" noreferrer noopener href="{{comm.address}}">{{comm.address}}</a></td>
                  <td *ngIf="comm.commAddressTypeGUID!='fdc854b2-02f2-4029-bd99-75f97ade81f1'">{{comm.address}}</td>
                </tr>
              </ng-template>
            </table>
          </div>
          <div *ngIf="vendorDetails && vendorDetails.vendorHours!=null && vendorDetails.vendorHours.length>0">
            <div>
              <label class="font-weight-bold">Hours</label>
            </div>
            <div>
              <ng-template ngFor let-hours [ngForOf]="vendorHours" let-i="index">
                <table width="60%">
                  <tr><td colspan="2"><span style="text-decoration:underline">{{hours.hoursTypeDescription}} Hours</span></td></tr>
                  <tr *ngIf="hours.endDate!=null"><td width="20%">Effective Date</td><td>{{hours.effectiveDate}}</td></tr>
                  <tr *ngIf="hours.endDate!=null"><td width="20%">Expiration Date</td><td>{{hours.endDate}}</td></tr>
                  <tr *ngIf="hours.is24Hours"><td colspan="2">24 Hours</td></tr>
                  <tr *ngIf="hours.sameHours"><td colspan="2">{{hours.isClosed==1? 'Closed' : hours.is24Hours? '24 Hours': hours.openTime==null || hours.openTime==''? ' ': hours.openTime + ' - ' +  hours.closeTime}} Daily</td></tr>
                  <ng-template *ngIf="hours.is24Hours==0 && !hours.sameHours" ngFor let-element [ngForOf]="hours.vendorDailyHours" let-i="index">
                    <tr>
                      <td width="20%">{{element.dayOfWeek}}</td>
                      <td>{{element.isClosed==1? 'Closed' : element.is24Hours? '24 Hours': element.openTime==null || element.openTime==''? ' ': element.openTime + ' - ' +  element.closeTime}}</td>
                    </tr>
                  </ng-template>
                  <tr *ngIf="hours.remarks!=null && hours.remarks!=''"><td width="20%">Remarks</td><td>{{hours.remarks}}</td></tr>
                </table>
                <br />
              </ng-template>
            </div>

          </div>
          <div class="mb-2" *ngIf="vendorDetails && vendorDetails.vendorNotes!=null && vendorDetails.vendorNotes.length>0">
            <div>
              <label class="font-weight-bold">Notes</label>
            </div>
            <div>
              <table width="60%">
                <ng-template ngFor let-note [ngForOf]="vendorDetails.vendorNotes" let-i="index">
                  <tr *ngIf="userType=='i' && !note.includeInBrief" class="striped-row"><td width="20%" valign="top">{{note.noteTypeDesc}}</td><td style="white-space:pre-wrap">{{note.vendorNote}}</td></tr>
                </ng-template>
              </table>
            </div>
          </div>
          <div class="mb-2" *ngIf="vendorDetails.vendorDocuments!=null && vendorDetails.vendorDocuments.length>0">

            <div>
              <table width="60%">
                <thead><tr><th>Documents</th></tr></thead>
                <thead>
                  <tr>
                    <td>Type</td>
                    <td>Name</td>
                    <td>Effective</td>
                    <td>Expiration</td>
                  </tr>
                </thead>
                <ng-template ngFor let-doc [ngForOf]="vendorDetails.vendorDocuments" let-i="index">
                  <tr class="striped-row">
                    <td>{{doc.documentTypeDescription}}</td>
                    <td>
                      <a href="javascript:void(0)" style="cursor:pointer" (click)="getVendorDocuments(doc)">{{doc.documentName}}</a>
                    </td>
                    <td>{{doc.effectiveDate}}</td>
                    <td>{{doc.expirationDate}}</td>
                  </tr>
                </ng-template>
              </table>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div [hidden]="selectedTab!='catering'">
      <div *ngIf="cateringVendors==null || cateringVendors.length==0">No Records Found</div>
      <div *ngIf="cateringVendors" style="user-select:text" (mousedown)="$event.stopPropagation()">
        <ng-template ngFor let-vendorDetails [ngForOf]="cateringVendors" let-i="index">
          <div><label class="font-weight-bold"><span style="color: #c00000; font-size: large">{{vendorDetails.vendorName}}</span></label><i style="font-size: medium;" *ngIf="vendorDetails.isPreferred" class="material-symbols-outlined filled yellow">grade</i></div>
          <div>
            <table width="60%">
              <ng-template ngFor let-person [ngForOf]="vendorDetails.vendorPersons" let-i="index">
                <tr class="striped-row" *ngIf="userType=='i' || (userType=='c' && person.includeInBrief)">
                  <td colspan="2"><span style="font-weight: bold">{{person.firstName}} {{person.lastName}}</span> {{person.jobTitle}}</td>
                  <!--<td width="35%" style="font-weight: 600">{{person.jobTitle}}</td>
                  <td style="font-weight: 600 ">{{person.firstName}} {{person.lastName}}</td>-->
                </tr>
                <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
                  <tr class="striped-row" *ngIf="userType=='i' || (userType=='c' && comm.includeInBrief)">
                    <td style="text-align:right;vertical-align:top" width="20%">{{comm.commAddressType}}: </td>
                    <td *ngIf="comm.commAddressTypeGUID=='fdc854b2-02f2-4029-bd99-75f97ade81f1'"><a target="_blank" noreferrer noopener href="{{comm.address}}">{{comm.address}}</a></td>
                    <td *ngIf="comm.commAddressTypeGUID!='fdc854b2-02f2-4029-bd99-75f97ade81f1'">{{comm.address}}</td>
                  </tr>
                </ng-template>
              </ng-template>
              <tr class="striped-row" *ngIf="vendorDetails.vendorComms && vendorDetails.vendorComms.length>0">
                <td colspan="2" style="font-weight: bold">Main Office</td>
              </tr>
              <ng-template ngFor let-comm [ngForOf]="vendorDetails.vendorComms" let-i="index">
                <tr class="striped-row">
                  <td style="text-align: right; vertical-align: top" width="20%">{{comm.commAddressType}}: </td>
                  <td *ngIf="comm.commAddressTypeGUID=='fdc854b2-02f2-4029-bd99-75f97ade81f1'"><a target="_blank" noreferrer noopener href="{{comm.address}}">{{comm.address}}</a></td>
                  <td *ngIf="comm.commAddressTypeGUID!='fdc854b2-02f2-4029-bd99-75f97ade81f1'">{{comm.address}}</td>
                </tr>
              </ng-template>
            </table>
          </div>
          <div *ngIf="vendorDetails && vendorDetails.vendorHours!=null && vendorDetails.vendorHours.length>0">
            <div>
              <label class="font-weight-bold">Hours</label>
            </div>
            <div>
              <ng-template ngFor let-hours [ngForOf]="vendorHours" let-i="index">
                <table width="60%">
                  <tr><td colspan="2"><span style="text-decoration:underline">{{hours.hoursTypeDescription}} Hours</span></td></tr>
                  <tr *ngIf="hours.endDate!=null"><td width="20%">Effective Date</td><td>{{hours.effectiveDate}}</td></tr>
                  <tr *ngIf="hours.endDate!=null"><td width="20%">Expiration Date</td><td>{{hours.endDate}}</td></tr>
                  <tr *ngIf="hours.is24Hours"><td colspan="2">24 Hours</td></tr>
                  <tr *ngIf="hours.sameHours"><td colspan="2">{{hours.isClosed==1? 'Closed' : hours.is24Hours? '24 Hours': hours.openTime==null || hours.openTime==''? ' ': hours.openTime + ' - ' +  hours.closeTime}} Daily</td></tr>
                  <ng-template *ngIf="hours.is24Hours==0 && !hours.sameHours" ngFor let-element [ngForOf]="hours.vendorDailyHours" let-i="index">
                    <tr>
                      <td width="20%">{{element.dayOfWeek}}</td>
                      <td>{{element.isClosed==1? 'Closed' : element.is24Hours? '24 Hours': element.openTime==null || element.openTime==''? ' ': element.openTime + ' - ' +  element.closeTime}}</td>
                    </tr>
                  </ng-template>
                  <tr *ngIf="hours.remarks!=null && hours.remarks!=''"><td width="20%">Remarks</td><td>{{hours.remarks}}</td></tr>
                </table>
                <br />
              </ng-template>
            </div>

          </div>
          <div class="mb-2" *ngIf="vendorDetails && vendorDetails.vendorNotes!=null && vendorDetails.vendorNotes.length>0">
            <div>
              <label class="font-weight-bold">Notes</label>
            </div>
            <div>
              <table width="60%">
                <ng-template ngFor let-note [ngForOf]="vendorDetails.vendorNotes" let-i="index">
                  <tr *ngIf="userType=='i' && !note.includeInBrief" class="striped-row"><td width="20%" valign="top">{{note.noteTypeDesc}}</td><td style="white-space:pre-wrap">{{note.vendorNote}}</td></tr>
                </ng-template>
              </table>
            </div>
          </div>
          <div class="mb-2" *ngIf="vendorDetails.vendorDocuments!=null && vendorDetails.vendorDocuments.length>0">

                <div>
                  <table width="60%">
                    <thead><tr><th>Documents</th></tr></thead>
                    <thead>
                      <tr>
                        <td>Type</td>
                        <td>Name</td>
                        <td>Effective</td>
                        <td>Expiration</td>
                      </tr>
                    </thead>
                    <ng-template ngFor let-doc [ngForOf]="vendorDetails.vendorDocuments" let-i="index">
                      <tr class="striped-row">
                        <td>{{doc.documentTypeDescription}}</td>
                        <td>
                          <a href="javascript:void(0)" style="cursor:pointer" (click)="getVendorDocuments(doc)">{{doc.documentName}}</a>
                        </td>
                        <td>{{doc.effectiveDate}}</td>
                        <td>{{doc.expirationDate}}</td>
                      </tr>
                    </ng-template>
                  </table>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div [hidden]="selectedTab!='hotels'">
          <div style="user-select: text; max-height: 37em; overflow-y: auto" (mousedown)="$event.stopPropagation()">
            <div style="width:100%" class="d-flex justify-content-end">
              Total Count {{hotelList?.length}}
            </div>
            <table mat-table [dataSource]="hotelList" class="example-container">
              <tr>
                <ng-container matColumnDef="hotelName">
                  <th mat-header-cell *matHeaderCellDef style="width:25%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Hotel</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.hotelName}}</td>
                </ng-container>
                <ng-container matColumnDef="hotelChainName">
                  <th mat-header-cell *matHeaderCellDef style="width:15%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Hotel Chain</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.hotelChainName}}</td>
                </ng-container>
                <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef style="width:20%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Address</div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="white-space:pre-wrap; text-align:left !important">{{element.streetAddress}}</td>
                </ng-container>
                <ng-container matColumnDef="near_airport">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Nearest<span style="display:block">Airport</span></div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.icao}}</td>
                </ng-container>
                <ng-container matColumnDef="distance">
                  <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Distance<span style="display:block">Miles</span></div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.distanceFromAirport}}</td>
                </ng-container>
                <ng-container matColumnDef="remarks">
                  <th mat-header-cell *matHeaderCellDef style="width:20%; border-bottom:none">
                    <div style="margin-top:-0.25em;">Remarks</div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="text-align:left !important">{{element.remarks}}</td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
                    Action
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <a mat-button class="btn btn-low grid" style="align-self:center">
                      Edit
                    </a>
                  </td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumnHotel; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnHotel" (click)="editHotel(row)"></tr>
            </table>
          </div>
        </div>
        <div [hidden]="selectedTab!='homebase'">
          <div style="user-select: text; max-height: 37em; overflow-y: auto" (mousedown)="$event.stopPropagation()">
            <div *ngIf="!homebaseAircraft || homebaseAircraft.length==0">No records found</div>
            <table *ngIf="homebaseAircraft && homebaseAircraft.length>0" mat-table [dataSource]="homebaseAircraft" class="homebase-table">
              <tr>
                <ng-container matColumnDef="customer">
                  <th mat-header-cell *matHeaderCellDef style=" border-bottom:none">
                    <div style="margin-top:-0.25em;">Client</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
                </ng-container>
                <ng-container matColumnDef="registration">
                  <th mat-header-cell *matHeaderCellDef style="border-bottom:none">
                    <div style="margin-top:-0.25em;">Registration</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.registration}}</td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="homebaseColumn; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: homebaseColumn"></tr>
            </table>

          </div>
        </div>
        <div [hidden]="selectedTab!='trips'">
          <div style="user-select: text;width:100%" (mousedown)="$event.stopPropagation()">
            <div style="width:65%">
              <div style="margin-bottom:-1em" >
                <div>
                  <h6 class="mr-2">Filter Trips By:</h6>
                  <mat-radio-group class="form-control" (change)="getTripActivity()" [(ngModel)]="timeSpan" [ngModelOptions]="{standalone: true}" style="width:60em;display: flex; flex-direction: row; transform: scale(.8); align-items: flex-start;border:none!important;background-color:transparent;margin-top:-2.15em;">
                    <mat-radio-button class="mr-3" [checked]="timeSpan=='current'" [value]="'current'">Current Trips</mat-radio-button>
                    <mat-radio-button class="mr-3" [checked]="filterOption=='30d'" [value]="'30d'">Prior 30 Days</mat-radio-button>
                    <mat-radio-button class="mr-3" [checked]="filterOption=='12m'" [value]="'12m'">Prior 12 Months</mat-radio-button>
                    <mat-radio-button [checked]="filterOption=='2y'" [value]="'2y'">Prior 2 Years</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div style="float:right;margin-top:-1.25em;margin-right:1em">
                Total Count {{tripActivity?.length}}
              </div>
            </div>
            <div class="d-inline-flex" style="width:100%">
              
                <div style="width: 65%;max-height: 35em; overflow-y: auto; overflow-x: hidden; margin-top: 1em">
                  <table mat-table [dataSource]="filteredtripActivity" class="trip-table">
                    <tr>
                      <ng-container matColumnDef="customer">
                        <th mat-header-cell *matHeaderCellDef style="width:25%; border-bottom:none">
                          Client
                        </th>
                        <td mat-cell *matCellDef="let element" ><div class="ellipsis">{{element.customerName}}</div></td>
                      </ng-container>
                      <ng-container matColumnDef="trip_code">
                        <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                          Trip Code
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.tripCode}}</td>
                      </ng-container>
                      <ng-container matColumnDef="registration">
                        <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
                          Registration
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.registration}}</td>
                      </ng-container>
                      <ng-container matColumnDef="departureDateTime">
                        <th mat-header-cell *matHeaderCellDef style="width:15%; border-bottom:none">
                          Dept Date/Time (Z)
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.departureDateTimeUTCDisplay}}</td>
                      </ng-container>
                      <ng-container matColumnDef="leg">
                        <th mat-header-cell *matHeaderCellDef style="width:15%; border-bottom:none">
                          Leg
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.departureICAO}}-{{element.nextArrivalICAO}}</td>
                      </ng-container>
                      <ng-container matColumnDef="arrivalDateTime">
                        <th mat-header-cell *matHeaderCellDef style="width:15%; border-bottom:none">
                          Arrv Date/Time (Z)
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.nextArrivalDateTimeUTCDisplay}}</td>
                      </ng-container>
                      <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef style="width:7%; border-bottom:none">
                          Action
                        </th>
                        <td mat-cell *matCellDef="let element" style="text-align:left !important"><button mat-button class="btn btn-low grid">Open</button></td>
                      </ng-container>
                    </tr>
                    <tr mat-header-row *matHeaderRowDef="tripActivityColumn; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: tripActivityColumn" (click)="openTrip(row)"></tr>
                  </table>
                
              </div>
              <div style="width:35%; margin-left:1em;margin-top:1em">
                <div style="width:100%;">
                  <div style="position: relative;" (mousedown)="$event.stopPropagation()">
                    <div style="width:100%;height:38em;border-radius:.25em" id="{{'trip-map'+airportID}}"></div>
                    <div *ngIf="tripActivity" style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
                      fullscreen
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <button mat-button [disabled]="isLoadingDoc || isLoadingAirport || isLoadingVendor || isLoadingAdvisory" class="btn btn-high mr-2" (click)="createAirportBrief()"><span *ngIf="isLoadingDoc" class="spinner-border spinner-border-sm mr-1"></span><span style="font-size: medium; margin-top: -.25em; margin-right: .25em; margin-left: -.25em" class="material-symbols-outlined">cloud_download</span>Download Report</button>
        <button mat-dialog-close mat-button class="btn btn-low mr-2"><span style="font-size: medium; margin-top: -.25em; margin-right: .25em; margin-left: -.25em" class="material-symbols-outlined">close</span>Close</button>
        <button mat-button class="btn btn-med mr-2" (click)="newMessage()"><span style="font-size: medium; margin-top: -.25em; margin-right: .25em; margin-left: -.25em" class="material-symbols-outlined">send</span>Email</button>
        <button *ngIf="grantAccessVendorProfiles" mat-button class="btn btn-med mr-2" (click)="editVendorProfile()"><span style="font-size: medium; margin-top: -.25em; margin-right: .25em; margin-left: -.25em" class="material-symbols-outlined">settings_account_box</span>Manage Vendors</button>
        
      </div>
            </div>
