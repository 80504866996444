<div *ngIf="grantAccessToMessages" style="padding: 1em 1em 1em 2em; max-height:45em;">
  <form *ngIf="email_client_form" [formGroup]="email_client_form">
    <div style="display: flex; ">

      <div *ngIf="messageGUID!=''">
        <div class="d-flex justify-content-between mb-2" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
          <div class="d-flex">
            <!--<div class="mr-3" style="width:22em">
            <button mat-button *ngIf="!showSpin" [disabled]="loading || lockdownTrip" (click)="replyMessage('reply')" class="btn-med mr-1"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">reply</i><div>Reply</div></div></button>
            <button mat-button *ngIf="!showSpin" [disabled]="loading || lockdownTrip" (click)="replyMessage('replyall')" class="btn-med mr-1"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">reply_all</i><div>Reply All</div></div></button>
            <button mat-button *ngIf="!showSpin" [disabled]="loading || lockdownTrip" (click)="replyMessage('forward')" class="btn-med mr-1"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">forward</i><div>Forward</div></div></button>
          </div>-->
            <div class="mr-3" style="width:50em">
              <button mat-button class="btn-med  mr-1" (click)="onPrintClick()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">print</i><div>Print</div></div></button>
              <!--<button mat-button *ngIf="!isFlagged" [disabled]="loading" class="btn-med  mr-1" (click)="FlagMessage()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">bookmark</i><div>Flag</div></div></button>
            <button mat-button *ngIf="isFlagged" [disabled]="loading" class="btn-med  mr-1" (click)="FlagMessage()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">bookmark_remove</i><div>UnFlag</div></div></button>

            <--button mat-button *ngIf="reloadBtnVisible && !isDraft" class="btn-med mr-1" (click)="reloadEmbeddedImage()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">refresh</i><div>Load Image</div></div></button>-->
            </div>

            <div class="d-inline-flex">
              <div style="width:3em;">
              </div>
            </div>

            <!-- *ngIf = " !showAssigned" -->
            <div style="margin-top:-.5em; display: flex;" *ngIf="messageGuid!='' && !isDraft && !showAssigned">
              <button mat-icon-button aria-label="previous message" [disabled]="isFirstMessage" (click)="showPrevious()">
                <mat-icon>arrow_upward</mat-icon>
              </button>
              <button mat-icon-button aria-label="next message" [disabled]="isLastMessage" (click)="showNext()">
                <mat-icon>arrow_downward</mat-icon>
              </button>
            </div>


          </div>

          <button *ngIf="isMinimized" mat-icon-button (click)="maximizeDialog()"> <i class="material-icons" style="font-size:x-large; ">crop_square</i> </button>
          <!-- <div style="float: right">
          <button mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>&nbsp;
        </div>   -->

          <div style="float: right; margin-left:  .5em; margin-top: -.5em; display: flex;" align="end">
            <button *ngIf="!isMinimized" mat-icon-button (click)="minimizeDialog()"> <i class="material-icons" style="font-size:x-large;  ">remove</i> </button>
            <button mat-icon-button (click)="clickClose()"> <i class="material-icons" style="font-size: x-large;">close</i> </button>
          </div>

        </div>
        <div class="d-inline-flex" id="emailHead">
          <div *ngIf="!printing">
            <div class="form-inline form-group" style="flex-flow: inherit !important;">
              <label class="searchLabel">From</label>
              <div style="font-size:small;width:40em;border-bottom:solid thin gray">
                {{emailFrom}}
              </div>
              <div style="width:11em">
                <label style="font-size:small;float:right">{{sentDateTime | date:'E M/d HH:mm'}} Z</label>
              </div>
            </div>
            <div class="form-inline form-group" style="flex-flow: inherit !important;">
              <label class="searchLabel">To</label>
              <div style="font-size: small; width: 55em; border-bottom: solid thin gray; word-wrap: break-word;">
                {{emailTo}}
              </div>
            </div>
            <div class="form-inline form-group" style="flex-flow: inherit !important;" *ngIf="emailCc">
              <label class="searchLabel">CC</label>
              <div style="font-size: small; width: 55em; border-bottom: solid thin gray; word-wrap: break-word;">
                {{emailCc}}
              </div>
            </div>
            <div class="form-inline form-group" style="flex-flow: inherit !important;">
              <label class="searchLabel">Subject</label>
              <div style="font-size: small; width: 55em; border-bottom: solid thin gray; word-wrap: break-word;">
                {{subject}}
              </div>
            </div>
          </div>
          <div *ngIf="printing">
            <div style="display: grid;margin-bottom: 15px;">
              <span><strong>From:  </strong>{{emailFrom}} <label style="font-size:small;float:right">{{sentDateTime | date:'E M/d HH:mm'}} Z</label></span>
              <span><strong>To: </strong>{{emailTo}}</span>
              <span><strong>CC: </strong>{{emailCc}}</span>
              <span><strong>Subject: </strong>{{subject}}</span>
              <span>

              </span>
            </div>
          </div>
          <!--<div *ngIf="(linkGroup.length>0 || clientName!='' || assignedTo!='')" class="ml-2 p-2 border border-info rounded" style="font-size:small;width:25em;max-height:9.5em;overflow-y:auto" [ngStyle]="{'margin-bottom':printing?'25px':'0px'}">
        <div>
          <label *ngIf="assignedTo!=''  || messageGUID ==''" style="font-size:small" class="mr-1">Assigned To: {{assignedTo}}</label>
        </div>
        <div *ngIf="linkGroup.length>0 || clientName!=''">
          <div *ngIf="!autoLinkVisible" style="display: inline-flex;">Link Details </div>
          <div *ngIf="autoLinkVisible" style="display: inline-flex; margin-right: 0.5em;"><b>Auto Link Details</b></div>
          <button *ngIf="!autoLinkVisible && !showAssigned && messageGUID != '' &&  linkGroup.length >1" mat-button class="btn-med" (click)="removelinkMessage()" style="display: inline-flex; margin-left: 1em; padding: 0px 0.25rem 0 0 !important"><i class="material-symbols-outlined" style="font-size: medium;  ">close</i>Remove Link</button>


          <ng-template ngFor let-link [ngForOf]="linkGroup">-->
          <!--<span class="d-flex flex-column">{{clientName}}</span>   "-->
          <!--<button *ngIf="!autoLinkVisible && !showAssigned && messageGUID != '' && linkGroup.length ==1" mat-button class="btn-med" (click)="updatelinkMessage(element)" style="display: inline-flex; margin-left: 1em; padding: 0px 0.25rem 0 0 !important">Update Link</button>

        <button *ngIf="autoLinkVisible" mat-button class="btn-med" (click)="autoLink(link.messageGUID)" style="display: inline-flex;  padding: -1px 0.25rem 0 0 !important">AutoLink {{linkGroup.length>1?link.message:''}}</button>-->
          <!-- {{linkGroup.length>1?link.message:''}} -->
          <!--<span class="d-flex flex-column">{{link.custName}}</span>

          <ng-template ngFor let-trip [ngForOf]="link.tripList">
            <div>
              <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" (click)="clickOpenTrip(trip.tripCodeGUID,trip.custGUID,trip.aircraftGUID);">{{trip.tripCode}}</a>
            </div>

            <div>
              <ng-template ngFor let-leg [ngForOf]="trip.legList">
                {{leg.tripLeg}}
                <div class="d-flex flex-column">
                  <ng-template ngFor let-task [ngForOf]="leg.taskList">
                    <span>
                      {{task.serviceTypeDescription}} ({{task.taskStatus}})
                    </span>
                  </ng-template>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </ng-template>-->
          <!-- <span class="d-flex flex-column">{{clientName}}</span> -->
          <!--</div>
        </div>-->
          <div *ngIf="linkGroup.length>0 || clientName!=''" class="ml-2 p-2 mb-1 border border-info rounded" style="font-size:x-small;width:21em;max-height: 13em;min-height: 6em;overflow-y:auto" [ngStyle]="{'margin-bottom':printing?'25px':'0px'}">
            <span class="d-flex flex-column">{{clientName}}</span>
            <ng-template ngFor let-link [ngForOf]="linkGroup">
              <ng-template ngFor let-trip [ngForOf]="link.tripList">
                <div>
                  <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" (click)="clickOpenTrip(trip.tripCodeGUID,trip.custGUID,trip.aircraftGUID);">{{trip.tripCode}}</a>
                </div>

                <div>
                  <ng-template ngFor let-leg [ngForOf]="trip.legList">
                    {{leg.tripLeg}}
                    <div class="d-flex flex-column">
                      <ng-template ngFor let-task [ngForOf]="leg.taskList">
                        <span>
                          {{task.serviceTypeDescription}} ({{task.taskStatus}})
                        </span>
                      </ng-template>
                    </div>
                  </ng-template>
                </div>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div style="width:6em" *ngIf="messageGUID=='' || isDraft">
          <div>
            <button mat-button class="btn-high" *ngIf="!showSpin" [disabled]="loading" (click)="clickSend()" style="padding-left:0.5em !important; padding-right: 0.5em !important"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em; margin-right:0.25em">send</i><div>Send</div></div></button>&nbsp;
          </div>
        </div>
        <div *ngIf="messageGUID==''">

          <div style="display: flex; justify-content:space-between; min-width:57.5em; margin-bottom:0.25em">
            <div style="display: flex; margin-right:1em; width:51.5em" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
              <div style="margin-right:0.5em; width: 5em; text-align: right">From</div>
              <div style="border-bottom: 0.0625em solid lightgray; width: 25em; margin-bottom:0.25em; font-size: small">{{userName}}</div>
            </div>
            <div style="float: right">
              <button mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>&nbsp;
            </div>
          </div>
          <div style="display: flex;margin-bottom:0.25em">
            <div style="margin-right:0.5em; width: 5em;text-align: right">To</div>
            <div><input type="text" formControlName="emailTo" readonly style="font-size: small; padding: 0.25em;width:42em;height:2em;border-radius: 0.25em; background-color:#f8f8f8" /></div>
          </div>
          <div style="display: flex;margin-bottom:0.25em">
            <div style="display:flex; width:45em; margin-right: 0.25em; margin-left: 1em;">
              <div style="margin-right:0.5em;">
                <button mat-button class="btn-low" (click)="clickEmailSelection()">Cc</button>
              </div>

              <div><input type="text" formControlName="emailCc" (keydonw)="emailCcKeydown($event)" [ngClass]="{ 'is-invalid': submitted && f.emailCc.errors, 'is-valid': !f.emailCc.errors}" style="font-size: small; padding: 0.25em;width:42em;height:2em;border-radius: 0.25em; background-color:#eaf0fd" /></div>
            </div>
            <div *ngIf="submitted && f.emailCc.errors" class="invalid-feedback" style="margin-top:0em; display:flex">
              <div *ngIf="f.emailCc.errors.validateEmail==false" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; min-width:9em">{{wrongEmailMsg}}</div>
              <div></div>
            </div>
          </div>
          <div style="display: flex;margin-bottom:0.25em">
            <div style="display:flex; width:45em; margin-right: 0.25em">
              <div style="margin-right:0.5em; width: 5em;text-align: right">Subject</div>
              <div><input type="text" formControlName="subject" style="font-size: small; padding: 0.25em;width:42em;height:2em;background-color:#eaf0fd; border-radius: 0.25em" [ngClass]="{ 'is-invalid': submitted && f.subject.errors, 'is-valid': !f.subject.errors}" /></div>
            </div>
            <div *ngIf="submitted && f.subject.errors" class="invalid-feedback" style="margin-top:0em">
              <div *ngIf="f.subject.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:9em">Subject is required</div>
            </div>
          </div>
          <div style="display: flex;margin-bottom:0.25em">
            <div style="margin-right:0.5em; width: 5em;text-align: right">
              <label style="border: 0.0625em solid lightgray; height:1.75em; border-radius: 0.25em; cursor:pointer;margin-left:2.25em; padding-left:0.5em; padding-right:0.5em">
                <input #document formControlName="document" id="document" type="file" ng2FileDrop multiple [uploader]="uploader" style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />
                <i class="material-icons" style="font-size: x-large; transform: rotate(-45deg);color:gray;">attachment</i>
              </label>
              <div *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1" style="position:absolute; margin-top:0.5em; margin-left:-1.75em"></div>
            </div>
            <div ng2FileDrop [uploader]="uploader" style="min-height: 3em; min-width:34.15em; border: 1px solid grey;border-radius:0.25em;padding-left:0.25em" (onFileDrop)="dropFile($event)">
              <div style="min-height: 2em">
                <div *ngFor="let ma of messageAttachmentList; let i=index">
                  <div *ngIf="i % 2==0" style="display: flex;">
                    <div style="font-size: small; display:flex; padding:0em; margin-right:0.5em">
                      <div><a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" title="{{ma.documentName}}" (click)="clickPreviewDocument($event, ma)">{{ma.documentName}}</a></div>
                      <div style="margin-top:0.25em; margin-left: 0.25em"><i class="material-icons" style="color:red; font-size: small; cursor: pointer;" title="click to remove the document" (click)="clickRemoveDocument($event, ma)">cancel</i></div>
                    </div>
                    <div *ngIf="i + 1 < messageAttachmentList.length" style="font-size: small; display:flex; margin-right:0.5em;padding:0em">
                      <div><a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" title="{{messageAttachmentList[i+1].documentName}}" (click)="clickPreviewDocument($event, messageAttachmentList[i+1])">{{messageAttachmentList[i+1].documentName}}</a></div>
                      <div style="margin-top:0.25em; margin-left:0.25em"><i class="material-icons" style="color:red; font-size: small; cursor: pointer;" title="click to remove the document" (click)="clickRemoveDocument($event, messageAttachmentList[i+1])">cancel</i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="d-inline-flex mb-1" *ngIf="messageGUID!='' && ((messageAttachmentList && messageAttachmentList.length>0) || (embeddedImageListAtt && embeddedImageListAtt.length>0))">
      <div *ngIf="messageGUID!='' && !isDraft" style="margin-right:0.5em; border: 0.0625em solid lightgray; display: flex; border-radius: 0.25em; height:1.75em ">
        <label style=" display: flex;   margin-top: 0.2em; margin-bottom: -0.01em;">
          <i class="material-icons" style="cursor: pointer; font-size: large;  color:gray;  ">attach_file</i>
          <i *ngIf="messageAttachmentList.length>0" class="material-icons" title="Download all attachments" style="  font-size: large; cursor: pointer;" (click)="downloadAllAttachments()">download</i>

          <span style="margin-top: -0.2em;margin-right:.25em; font-size:small">{{messageAttachmentList.length}} </span>
        </label>
      </div>
      <div style=" border: 0.0625em solid lightgray; ">
        <div *ngFor="let ma of messageAttachmentList; let i=index">
          <div style="font-size: small; display:flex; padding:0em; margin-right:0.5em;">
            <div style="white-space: nowrap">
              <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" title="{{ma.documentName}}" (click)="clickPreviewDocument($event, ma,false)">{{ma.documentName}}</a>
              <i class="material-icons" title="download {{ma.documentName}}" style=" font-size: medium; cursor: pointer;padding-left: 5px;" (click)="downloadAttachment(ma,false)">cloud_download</i>
            </div>
            <div *ngIf="messageGUID=='' || isDraft" style="margin-top:0.25em; margin-left: 0.25em"><i class="material-icons" style="color:red; font-size: small; cursor: pointer;" title="click to remove the document" (click)="clickRemoveDocument($event, ma)">cancel</i></div>
          </div>
        </div>
        <div *ngFor="let ma of embeddedImageListAtt; let i=index">
          <div style="font-size: small; display:flex; padding:0em; margin-right:0.5em;">
            <div style="white-space: nowrap">
              <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" title="{{ma.documentName}}" (click)="clickPreviewDocument($event, ma,true)">{{ma.documentName}}</a>
              <i class="material-icons" title="download {{ma.documentName}}" style=" font-size: medium; cursor: pointer;padding-left: 5px;" (click)="downloadAttachment(ma,true)">cloud_download</i>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div>
      <div style="display: flex;">
        <div *ngIf="showSuccessMsg">
          <app-success-message [successMsg]="successMsg"></app-success-message>
        </div>
        <div></div>
      </div>
      <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; min-width:20em;font-size: small ">{{errMsg}}</div>
    </div>
    <div>
      <ckeditor #emailCKEditor formControlName="emailCKEditor" id="emailCKEditor" name="emailCKEditor" [editor]="Editor" [config]="CKEConfig" (ready)="onReady($event)" [disabled]="isDisabled" style="line-height:1.2em; background-color:#eaf0fd !important;"></ckeditor>
    </div>
  </form>
      <!--<div><p><span style="background-color: hsl(60, 75%, 60%); ">gddfhgf</span></p><p><i>sdgdfhdf</i></p><p><span style="font-family: 'Courier New' ; ">xcbcvnvn</span></p></div>-->
            <!--<div [innerHTML]="emailContent | safeHtml"></div>-->
        </div>
<app-spin *ngIf="showSpin"></app-spin>
