import { Component, Inject, OnInit, Optional } from "@angular/core";
import { CountryModel } from "../models/country.model";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { CommonService } from "../services/common.service";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GlobalSettingsDashboardComponent } from "../common-utility/global-settings-dashboard.component";
import { AuthenticateService } from "../services/authenticate.service";
import { AirportService } from "../services/airport.service";
import { AirportNotesComponent } from "./airport-notes.component";
import { AirportNotesEditDialogComponent } from "./airport-notes-edit-dialog.component";
import { AirportNotesModel } from "../models/airport-notes.model";
import { ResponseModel } from "../models/response.model";
import { CommonFilter } from "../models/trip-closeout-queue.model";
import { map, startWith } from "rxjs/operators";
import { AirportLocatorComponent } from "./airport-locator.component";
import { AirportDocumentModel } from "../models/airport-document.model";
import { AirportDocumentsEditDialogComponent } from "./airport-documents-edit-dialog.component";
import { UtilityFunctions } from "../common-utility/utility.functions";
import { ActivatedRoute } from "@angular/router";
import { ManageAirportsComponent } from "./manage-airports.component";
import { AirportDetailsComponent } from "./airport-details.component";
import { AirportDetailsEditComponent } from "./airport-details-edit.component";

@Component({
  selector: 'app-airport-revision-modal-dialog',
  templateUrl: './airport-revision-modal-dialog.component.html',
  styleUrls: ['./airport-revision-modal-dialog.component.css']
})

export class AirportRevisionModalComponent implements OnInit {
  airport_revision_notes_form: UntypedFormGroup;
  isDialog: boolean = false;
  isNoteRevisions: boolean = true;
  totalRecords: number = 0;
  userType: string;
  username: string;
  errMsg: string;
  msg: string;
  loading: boolean = false;
  showSpin: boolean = false;
  showDisabledRecords: boolean = false;
  airportSearchKey: string = "";
  daysAhead: number = 7;
  countryList: CountryModel[] = [];
  monthFilter: CommonFilter[] = [];
  showMonthList: boolean = false;
  selectedMonthList: CommonFilter[] = [];
  selectedMonthCountText: string = "";
  filteredMonthList: Observable<CommonFilter[]>;
  monthList: CommonFilter[] = [];
  monthIDList: string = "";
  monthData: CommonFilter[] = [];
  selectedCountry: string = "";
  selectedRowIndex: number = -1;
  currentDate: Date;
  recordList: any[] = [];
  filteredRecordList: any[] = [];
  noteRevisionList: any[] = [];
  documentExpirationList: any[] = [];
  routeString: string = "";
  displayedColumn: string[] = ['airport_name', 'revision', 'days_until_due', 'category', 'notes', 'option'];
  displayedColumn1: string[] = ['airport_name', 'revision', 'days_until_due', 'category', 'notes', 'option'];
  displayedColumn2: string[] = ['airport_name', 'expiration', 'days_until_due', 'document_name', 'option'];

  constructor(@Optional() private readonly _dialogRef: MatDialogRef<AirportRevisionModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly dialog: MatDialog,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService,
    private readonly _airportService: AirportService,
    private readonly _dialog: MatDialog,
    private _route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    if(this.data) {
      this.isDialog = true;
      this.username = this.data.username;
      this.isNoteRevisions = this.data.isNoteRevisions;

      if(this.isNoteRevisions) {
        this.displayedColumn = this.displayedColumn1;
        this.routeString = 'airports/airport-note-revision-dialog';
      }
      else {
        this.displayedColumn = this.displayedColumn2;
        this.routeString = 'airports/airport-document-expiration-dialog';
      }
    }
    else {
      this._route.queryParams.subscribe(params => {
        this.isNoteRevisions = JSON.parse(params["isNoteRevisions"]);

        if(this.isNoteRevisions){
          this.displayedColumn = this.displayedColumn1;
        }
        else {
          this.displayedColumn = this.displayedColumn2;
        }
      });
    }

    this.currentDate = new Date();

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.countryList = responses[0].model;
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.recordList = responses[1].model;
          this.filteredRecordList = this.recordList;
          this.totalRecords = this.filteredRecordList.length;
        }
      }
    });

    this.populateMonthData();
    this.initControls();
  }

  initControls() {
    this.airport_revision_notes_form = this._formBuilder.group({
      country: [this.selectedCountry],
      airport_search: [this.airportSearchKey],
      monthsearch: [''],
      selectedyearsearch: [this.currentYear]
    });
  }

  getAllData(): Observable<any[]> {
    let getCountryList = this._commonService.getCountryList();

    let getAirportRevisionList = of(null);

    if(this.isNoteRevisions) {
      getAirportRevisionList = this._airportService.getAirportRevisionNotes<ResponseModel<AirportNotesModel[]>>(this.daysAhead, '', this.currentYear, this.airportSearchKey, this.selectedCountry);
    }
    else {
      getAirportRevisionList = this._airportService.getAirportDocumentExpirationList<ResponseModel<AirportDocumentModel[]>>(this.daysAhead, '', this.currentYear, this.airportSearchKey, this.selectedCountry);
    } 
    
    return forkJoin([getCountryList, getAirportRevisionList]);
  }

  get f() { return this.airport_revision_notes_form.controls; }

  clickClose(val) {
    this._dialogRef.close(val);
  }

  getAirportRevisionNotes(daysAhead: number, monthIDList: string, selectedYear: number, airportSearchKey: string, selectedCountry: string) {
    this._airportService.getAirportRevisionNotes<ResponseModel<AirportNotesModel[]>>(daysAhead, monthIDList, selectedYear, 
      airportSearchKey, selectedCountry).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.recordList = response.model;
          this.filteredRecordList = this.recordList;
          this.totalRecords = this.filteredRecordList.length;
        }
      }
    });
  }

  getAirportDocumentExpirationList(daysAhead: number, monthIDList: string, selectedYear: number, airportSearchKey: string, selectedCountry: string) {
    this._airportService.getAirportDocumentExpirationList<ResponseModel<AirportDocumentModel[]>>(daysAhead, monthIDList, selectedYear, 
      airportSearchKey, selectedCountry).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.recordList = response.model;
          this.filteredRecordList = this.recordList;
          this.totalRecords = this.filteredRecordList.length;
        }
      }
    });
  }

  filterBySearchKey(e: any) {
    this.airportSearchKey = this.getSearchKey();

    if(this.isNoteRevisions)
      this.getAirportRevisionNotes(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);

    else
      this.getAirportDocumentExpirationList(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
  }

  getSearchKey(): string {
    let searchVal: string = this.f.airport_search.value;
    if (searchVal) {
      searchVal = searchVal.replaceAll('-', ',');
      searchVal = searchVal.replaceAll(' ', ',');
      searchVal = searchVal.replaceAll(',,,,', ','); 
      searchVal = searchVal.replaceAll(',,,', ',');
      searchVal = searchVal.replaceAll(',,', ',');
    }
    
    // if(searchVal?.length > 2)
    //   return '';
    // else{
      return searchVal;
    // }
    
  }

  openAirportDetails(airportID: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
  //  dialogConfig.width = "100%";
    dialogConfig.maxHeight= "95vh";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { airportID: airportID };
    const dialogRef = this._dialog.open(AirportDetailsEditComponent, dialogConfig);
  }

  hideAllFilters(){
    // this.showClientList = false;
    // this.showCloseoutList = false;
    // this.showRegistrationList = false;
    // this.showTripCodeList = false;
    this.showMonthList = false;
  }

  populateMonthData() {
    for (let i = 1; i <= 12; i++) {
      this.monthData.push({
        filterID: i.toString(),
        filterName: this.getMonthName(i),
        selected: false
      });
    }

    this.monthFilter = this.monthData;
    // for (let i = 1; i <= 12; i++) {
    //   this.monthFilter.push({
    //     filterID: i.toString(),
    //     filterName: this.getMonthName(i),
    //     selected: false
    //   });
    // }
  }

  getMonthName(monthIndex: number): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[monthIndex - 1];
  }

  getMonthList() {
    this.hideAllFilters();
    this.showMonthList = true;

    this.monthList = [];
    let c = new CommonFilter();

      this.monthData.forEach(x => {
        c = new CommonFilter();
        c.filterName = x.filterName
        // c.filterID = x.filterID;
        c.filterID = x.filterID;
        c.selected = false;
        if (this.selectedMonthList?.length > 0) {
          let f: CommonFilter[];
          f = this.selectedMonthList.filter(y => y.filterID == x.filterID );
          //f = this.selectedMonthList.filter(y => y.filterID.toLowerCase() == x.filterID.toLowerCase());
          if (f.length > 0) {
            c.selected = true;
          }
        }
        this.monthList.push(c);
      });
      // this.monthList.sort((a, b) => Number(b.selected) - Number(a.selected) || a.filterName.localeCompare(b.filterName));
      c = new CommonFilter();
      // c.filterID = 0;
      // c.filterID = "all";
      // c.filterName = "Select All";
      // c.selected = false;
      // this.monthList.unshift(c);
      this.filteredMonthList = this.f.monthsearch.valueChanges.pipe(
        startWith(''),
        map(value => (typeof value === 'string' ? value : value.filterName)),
        map(filterName => (filterName ? this._filterMonth(filterName) : this.monthList.slice())),
      );
      this.setMonthList();
  }

  private _filterMonth(name: string): CommonFilter[] {
    const filterValue = name.toLowerCase();

    return this.monthList.filter(option => option.filterName.toLowerCase().includes(filterValue));
  }

  checkMonthChange(e: any, item: CommonFilter) {
    if (item.filterID == "all") {
      this.monthList.forEach(x => {
        x.selected = e.checked;
      });
    }
    else {
      this.monthList.forEach(x => {
        if (item.filterID == x.filterID) {
          x.selected = e.checked;
        }
      });
    }
    this.setMonthList();
  }

  setMonthList() {
    this.selectedMonthList = this.monthList.filter(u => u.selected == true);
    this.selectedMonthCountText = "";
    if (this.monthList.length > 0) {
      if (this.selectedMonthList.length == this.monthList.length) {
        this.selectedMonthCountText = ": All";
      }
      else {
        if (this.selectedMonthList.length > 0)
          this.selectedMonthCountText = ": " + this.selectedMonthList.length.toString();
      }
    }
  }

  clickDoneMonth() {
    this.showMonthList = false;
    this.getMonthData();
  }

  clickResetMonth() {
    this.monthList.forEach(x => {
      x.selected = false;
    });
    this.f.monthsearch.setValue("");
    this.f.selectedyearsearch.setValue(this.currentYear);
    this.setMonthList();
    //this.clickSubmit();
  }

  get currentYear(){ return new Date().getFullYear();}
  get nextYear(){ return new Date().getFullYear() + 1;}

  getMonthData() {
    this.monthIDList = Array.prototype.map.call(this.selectedMonthList, s => s.filterID).toString();

    if(this.isNoteRevisions)
      this.getAirportRevisionNotes(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);

    else
      this.getAirportDocumentExpirationList(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
  }

  clickReset(resetSearch: boolean = false) {
    if(resetSearch) {
      this.airportSearchKey = "";
      this.f.airport_search.setValue(this.airportSearchKey);

      if(this.isNoteRevisions)
        this.getAirportRevisionNotes(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
  
      else
        this.getAirportDocumentExpirationList(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
    }

    else {
      this.airportSearchKey = "";
      this.selectedCountry = "";
  
      this.f.airport_search.setValue(this.airportSearchKey);
      this.f.country.setValue(this.selectedCountry);

      if(this.isNoteRevisions)
        this.getAirportRevisionNotes(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
  
      else
        this.getAirportDocumentExpirationList(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
    }
  }

  changeDaysAhead(e: any) {
    if(e.value === 'choose') {
      this.daysAhead = 0;
      this.getMonthList();
      this.showMonthList = true;
    }

    else {
      this.daysAhead = e.value;
      this.monthIDList = '';

      if(this.isNoteRevisions)
        this.getAirportRevisionNotes(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
  
      else
        this.getAirportDocumentExpirationList(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
    }
  }

  changeCountry(e: any) {
    this.selectedCountry = e.target.value;

    if(this.isNoteRevisions)
      this.getAirportRevisionNotes(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);

    else
      this.getAirportDocumentExpirationList(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
  }

  previewDocument(element: any) {
    this._airportService.getAirportDocument<ResponseModel<string>>(element).subscribe(response => {
      if (response.code == "200" && response.model != '') {
        let byteArray = UtilityFunctions.base64ToArrayBuffer(response.model);
        let file = new Blob([byteArray], {type: element.mimeType});
        file.size
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    })
  }

  editAirportRevisionNotes(element: any, index: number) {
    this.selectedRowIndex = index;
    
    const config = new MatDialogConfig();
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px"};
    config.data = { dialogTitle: '', airportID: element.airportID, airportNoteID: element.airportNotesID };
    const dialogRef = this.dialog.open(AirportNotesEditDialogComponent, config);  

    dialogRef.afterClosed().subscribe(response => {
      this.selectedRowIndex = -1;

      if(this.isNoteRevisions)
        this.getAirportRevisionNotes(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
  
      else
        this.getAirportDocumentExpirationList(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
    })
  }

  editAirportDocumentExpiration(element: any, index: number) {
    this.selectedRowIndex = index;
    
    const config = new MatDialogConfig();
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px"};
    config.data = { dialogTitle: '', airportID: element.airportID, icao: element.icao,
      action: 'edit',  airportDocumentGUID: element.airportDocumentGUID, document: element };
    const dialogRef = this.dialog.open(AirportDocumentsEditDialogComponent, config);  

    dialogRef.afterClosed().subscribe(response => {
      this.selectedRowIndex = -1;

      if(this.isNoteRevisions)
        this.getAirportRevisionNotes(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
  
      else
        this.getAirportDocumentExpirationList(this.daysAhead, this.monthIDList, this.f.selectedyearsearch.value, this.airportSearchKey, this.selectedCountry);
    })
  }

  openGlobalSettingsDashboardComponent() {
    if(this.isDialog){  
      this.clickClose(true);
    }     
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "41.5em";//"1000px";
    config.width = "45em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "",  username : this.username,  isDialog: true, v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(GlobalSettingsDashboardComponent, config);  
  }

  clickRouteNew(route: string,display: string){
    if(this.isDialog){  
      this.clickClose(true);
    }     
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber() + "&isNoteRevisions=" + this.isNoteRevisions);
  }
}
