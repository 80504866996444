<div class="d-inline-flex">

  <div style="user-select: text; max-height: 37em; overflow-y: auto" (mousedown)="$event.stopPropagation()">
    <table mat-table [dataSource]="mapProfileList" class="map-table">
      <tr>
        <ng-container matColumnDef="locationDescription">
          <th mat-header-cell *matHeaderCellDef style=" border-bottom:none;padding-left:1em !important">
            <div style="margin-top:-0.25em;">Location</div>
          </th>
          <td width="40%" style="padding-left: 1em !important" mat-cell *matCellDef="let element"><div class="d-flex"><div style="width: 2em">{{element.markerText}}</div><div>{{element.mapLocationDescription}}</div></div></td>
        </ng-container>
        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef style=" border-bottom:none">
            <div style="margin-top:-0.25em;">Notes</div>
          </th>
          <td width="40%" mat-cell *matCellDef="let element">{{element.notes}}</td>
        </ng-container>
        <ng-container matColumnDef="share_client">
          <th mat-header-cell *matHeaderCellDef style=" border-bottom:none">
            <div style="margin-top:-0.25em;">Share with<br />Clients</div>
          </th>
          <td width="10%" mat-cell *matCellDef="let element">{{element.isPublic? 'Yes' : ''}}</td>
        </ng-container>
        <ng-container matColumnDef="disabled">
          <th [hidden]="!includeDisabledRecords" mat-header-cell *matHeaderCellDef style=" border-bottom:none">
            <div style="margin-top:-0.25em;">Disabled</div>
          </th>
          <td width="5%" [hidden]="!includeDisabledRecords" mat-cell *matCellDef="let element">{{element.isActive? '':'Yes'}}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef style="width: 5%; text-align: center !important ">
            <div>Action</div><div style="margin-top:0em;padding-bottom:0.25em"><a mat-button class="btn-high grid" style="align-self:center" (click)="clickEdit(null);">Add</a></div>
          </th>
          <td width="5%" mat-cell style="text-align:center !important" *matCellDef="let element">
            <a mat-button class="btn-low grid" style="align-self:center" (click)="clickEdit(element);">
              Edit
            </a>
          </td>
        </ng-container>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayColumn; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumn"></tr>
    </table>

  </div>

<div style="width:33em;margin-left:1em">

  <div style="position: relative;">
    <div style="width:100%;height:30em;border-radius:.25em" id="map-profile"></div>
    <!--<div style="position:absolute; top:.5em;left:.5em;color:gray;background-color:white;cursor:pointer" class="material-icons-outlined" (click)="openMapComponent()">
      fullscreen
    </div>-->
  </div>

</div>
  </div>
