import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { ThirdPartyVendorsComponent } from './third-party-vendors.component';
import { GlobalSettingsDashboardComponent } from './global-settings-dashboard.component';
import { FpcPicklistDataComponent } from './fpc-picklist-data.component';
import { CustomerProfileSetupComponent2 } from '../customers/customer-profile-setup2.component';
import { AdminServicesClientComponent } from '../user-accounts/admin-services-client.component';
import { AdvisoryDetailsComponent } from '../advisory/advisory-details.component';

@Component({
  selector: 'app-maintenance-dashboard',
  templateUrl: './maintenance-dashboard.component.html',
  styleUrls: ['./maintenance-dashboard.component.css']
})

export class MaintenanceDashboardComponent implements OnInit {
  // userType: string; 
  maintenance_dashboard_form: UntypedFormGroup;
  loading = false;
  // v: number = this._authService.getCurrentTimeNumber();
  // upPII: boolean = false;
  username: string;
  isDialog: boolean = false;


  constructor(   
  @Optional() private readonly _dialogRef: MatDialogRef<MaintenanceDashboardComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
  private readonly _formBuilder: UntypedFormBuilder, private readonly dialog: MatDialog, private readonly _authService: AuthenticateService, 
    ) {
   
    // this.tripRevisionID = _data.tripRevisionID;
    // this.userComments = _data.userComments;
    if (this.data) {
      this.username = this.data.username;
      this.isDialog = true;
    }  

  }

  ngOnInit() {

    // this._authService.updateAccessTime();



   }

  initControls() {
    this.maintenance_dashboard_form = this._formBuilder.group({
      // userComments: [this.userComments]
    });


  }

  get f() { return this.maintenance_dashboard_form.controls; }


 

  clickClose(val) {
    this._dialogRef.close(val);
  }


  openThirdPartyVendorComponent(){
    if(this.isDialog){  
      this.clickClose(true);
    } 
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "23em";//"1000px";
    config.width = "43em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {    isDialog: true,  username : this.username,  v: this._authService.getCurrentTimeNumber() };
    this.dialog.open(ThirdPartyVendorsComponent, config);
  }

  
  clickRouteNew(route: string,display: string){
    if(this.isDialog){  
      this.clickClose(true);
    }     
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }
  
  
  openGlobalSettingsDashboardComponent() {
    if(this.isDialog){  
      this.clickClose(true);
    }     
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "41.5em";//"1000px";
    config.width = "45em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "",  username : this.username,  isDialog: true, v: this._authService.getCurrentTimeNumber()   };
    this.dialog.open(GlobalSettingsDashboardComponent, config);  
  }

    
  openFpcPicklistDataComponent() {
    if(this.isDialog){  
      this.clickClose(true);
    }     
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "33em";//"1000px";
    config.width = "55em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "",  username : this.username,  isDialog: true, v: this._authService.getCurrentTimeNumber()   };
    this.dialog.open(FpcPicklistDataComponent, config);  
  }

  openCustomerProfileSetup() {
    if(this.isDialog){  
      this.clickClose(true);
    }      
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.width = "55em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = {left: "10px", top:"140px"};
    // config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "",  username : this.username,  isDialog: true, v: this._authService.getCurrentTimeNumber()   };
    this.dialog.open(CustomerProfileSetupComponent2, config);
  }

  openInternalAdminServicesComponent() {
    if(this.isDialog){  
      this.clickClose(true);
    }      
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.height = "max-content";
    config.width = "max-content";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = {left: "10px", top:"140px"};
    // config.position = { left: "100px"};
    config.data = { gsId: "", tripCodeId: "",  username : this.username,  isDialog: true, v: this._authService.getCurrentTimeNumber() , userType: 'internal'  };
    this.dialog.open(AdminServicesClientComponent, config);
  }

  clickAdvisoryDetails() {

    if (this.isDialog) {
      this.clickClose(true);
    }


      //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
      const config = new MatDialogConfig();
      config.panelClass = "custom-dialog-container4b"
      config.minWidth = "64em";
      config.maxWidth = "83em";//"1000px";
      config.minHeight = "36.5em";//"1000px";
      config.maxHeight = "41em";//"1000px";
      config.restoreFocus = false;
      config.hasBackdrop = false;
      // config.position = { left: "100px" };

      let title = 'Add';
      let advisoryId = 0;

      config.data = { gsId: "", tripCodeId: "", dialogTitle: title, advisoryId: advisoryId, v: this._authService.getCurrentTimeNumber() };
      const dialogRef = this.dialog.open(AdvisoryDetailsComponent, config);
  
  }


}