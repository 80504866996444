<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
        <div >
          <table mat-table [dataSource]="recordList" class="example-container">
            <tr>
              <ng-container matColumnDef="document_name">
                <th mat-header-cell *matHeaderCellDef style="width: 30%">
                  Document Name
                </th>
                <td mat-cell *matCellDef="let element">{{element.documentName}}</td>
              </ng-container>
              <ng-container matColumnDef="preview">
                <th mat-header-cell *matHeaderCellDef style="width: 8%">
                  &nbsp;
                </th>
                <td mat-cell *matCellDef="let element">
                  <a class="a1" *ngIf="element.hasDocument" (click)="clickPreviewDoc($event, element);">Preview</a>
                </td>
              </ng-container>
              <ng-container matColumnDef="doc_type">
                <th mat-header-cell *matHeaderCellDef style="width: 30%">
                  Type
                </th>
                <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.documentTypeDescription}}</td>
              </ng-container>
              <ng-container matColumnDef="effective_date">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">
                  Effective Date
                </th>
                <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.effectiveDate}}</td>
              </ng-container>
              <ng-container matColumnDef="expiration_date">
                <th mat-header-cell *matHeaderCellDef style="width: 10%">
                  Expiration Date
                </th>
                <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.expirationDate}}</td>
              </ng-container>
              <!--<ng-container matColumnDef="vendor_svc_type">
                <th mat-header-cell *matHeaderCellDef style="width: 30%">
                  Vendor Service document requirement
                </th>
                <td mat-cell *matCellDef="let element" style="white-space:pre-wrap">{{element.vendorServiceDocumentTypeList}}</td>
              </ng-container>-->
              <ng-container matColumnDef="disabled" style="width: 7%">
                <th mat-header-cell *matHeaderCellDef>
                  Disabled
                </th>
                <td mat-cell *matCellDef="let element">{{element.isActive? '': 'Yes'}}</td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="width: 5%">
                  Action<br /> <a mat-button class="btn-high grid" (click)="clickEdit($event, null);">
                    ADD
                  </a>
                </th>
                <td mat-cell *matCellDef="let element; let i=index ">
                  <a mat-button class="btn-low grid" (click)="clickEdit($event, element);">
                    EDIT
                  </a>
                </td>
              </ng-container>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
          </table>
        </div>
        <div>
          <mat-paginator style="border: none; width:86.75em" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSize]="pageSize"
                         [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
          </mat-paginator>
        </div>
      
<app-spin *ngIf="showSpin"></app-spin>
