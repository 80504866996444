<div style="background-color:#f8f8f8;  height: 100%;">

  <div style="display: flex; justify-content: space-between;padding-left:1em; margin-top:-0.5em; background-color: #f8f8f8;  " cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div style="display: flex;">
      <h5>{{dialogTitle}} Advisory </h5>
      <span *ngIf="dialogTitle == 'Edit'" style="color:  #7a6e6e; margin: -0.1em 0 0 0.5em; font-size: large;"> - Alert ID :
        {{currentAdvisoryId}}</span>
      </div>

      <div style="display: flex;">

        <button mat-icon-button (click)="clickAdvisoryDetails()"> <i class="material-icons-outlined"
          style="font-size: x-large;">arrow_circle_left
        </i>
      </button>
      <button mat-icon-button (click)="clickClose(false)"> <i class="material-icons" style="font-size: x-large;">close
      </i>
    </button>
  </div>
</div>
<form *ngIf="advisory_edit_form" [formGroup]="advisory_edit_form">

  <div style="padding: 0.5em; background-color:white ; min-height: 30em;  ">




      <div style="display: flex; margin: 0.5em 0 0 1em; justify-content: space-between;">
        <!-- <div style="display: flex; width: 35em;">
          <label for="ratingDDL" style="font-size:small; margin-right:1em; ">Rating</label>


          <select id="ratingDDL" class="form-control form-control-sm dropdown" style="width: 8.6em;font-size:small;"
            formControlName="lk_AdvisoryRatingID">
            <option *ngFor="let r of ratings" [value]="r.lookupId">{{r.description}}</option>
          </select>

        </div> -->
        <div>
          <div style=" width: 100%;">
            <div>
              <label style="font-size: small;" ><b>Location</b></label>
            </div>
            <div style="display:flex"> 
              <div>
              
                <div style=" display: flex; margin-left:0em;">
                  <div style="margin-right:0.25em; justify-content: space-around; display: flex;">
                    <input formControlName="iscountryorairport" type="radio"  (change)="removeStateProvince($event)" name="iscountryorairport" value="airport" />
                  </div>
                  <div style="font-size: small;margin-top:-0.2em;white-space: nowrap">Airport</div>
                </div>
              
              
                <div style="display: flex;  margin-left: 0em;">
                  <div style="margin-right:0.25em; justify-content: space-around; display: flex;">
                    <input formControlName="iscountryorairport" (change)="removeAirport($event)" type="radio" name="iscountryorairport" value="country" />
                  </div>
                  <div style="font-size: small; margin-top:-0.2em;white-space: nowrap ">Country </div>
                </div>
              </div>
            
        
        
              <div class="ml-2" style="padding-top: 1em;">
        
                <div *ngIf="f.iscountryorairport.value=='country'" class="col">
                  <div style="display: flex; margin-top:-2.35em;">
        
                    <div class="form-group" style="   margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <label style="font-size:small; margin-right: 0.5em; "> Please Select Country </label>
                      <select [attr.disabled]="showModified ? '' : null" (change)="countryChange($event, c)"
                        [ngClass]="!showModified?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputDisabled'"
                        style="width: 26.6em;" formControlName="countryID">
                    
                        <option value="" selected></option>
                        <option *ngFor="let c of countrylist" [value]="c.countryID">{{c.countryName}}</option>
                      </select>
                    
                    </div>
               
                    <div *ngIf="stateProvinceList?.length >0 " class="form-group" style="   margin-left: 1em; margin-top:-0.35em; margin-bottom:0em">
                      <label style="font-size:small; margin-right: 0.5em; "> Select State Province </label>
                      <select [attr.disabled]="showModified ? '' : null"  
                        [ngClass]="!showModified?'form-control form-control-sm dropdown':'form-control form-control-sm dropdown inputDisabled'"
                        style="width: 13em;" formControlName="stateProvinceID">
                    
                        <option value="" selected></option>
                        <option *ngFor="let s of stateProvinceList" [value]="s.stateProvinceID">{{s.stateProvinceName}}</option>
                      </select>
                    
                    </div>
                  </div>
               
        
                </div>
                <div class="col" *ngIf="f.iscountryorairport.value=='airport'" style="display: flex; margin-top: -2.9em;">
                  <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                    <label for="icao" style="font-size:small; display: table-cell;">Please Enter</label>
                    <label for="icao" style="font-size:small">ICAO / IATA</label>
                    <div class="form-group" style="margin-left: 0em; margin-top:-0.35em; margin-bottom:0em">
                      <input  type="text" formControlName="icaoOrIata"
                        class =" form-control form-control-sm inputBox"
                        maxlength="255" style="width:6em"
                        (keyup)="icaoValidation($event,'icao')" /> 
                    </div>
                    <div *ngIf="submitted && f.icao.errors" class="alert alert-danger p-0 small">
                      <div *ngIf="f.icao.errors.required">ICAO is required.</div>
                    </div>
                  </div>
                    <div *ngIf="f.icao.value?.length > 2  " style=" padding-top: 1.2em; margin-left: -0.3em;">
                      <div style="margin: 1.3em 0 0 0.3em;">
                        <label *ngIf="f.icao.value?.length > 2" style="font-size:small"> <b>{{(f.icao.value)?.toUpperCase()}}
                            {{(f.iata.value)?.toUpperCase()}} &nbsp; </b>{{airportName}},</label>
                        <label style="font-size:small">&nbsp; {{countryName}} </label>
                      </div>
                    </div>
 
                  
                </div>
              </div>
            </div>
          

          </div>
        </div>

        

        <div style="display: flex; margin-right: 0.5em;">
          <!-- <div style="width: 0.5em; margin: 1em 1em 0 0;">
            <div style="width: 0.2em; height: 160%;margin-top: -1em;float: right; background-color: #f18e8e; ">
            </div>
          </div> -->
          <div style=" display: flex;">
            <div style="margin-top: 0em; margin-right: 4em;">
              <label style="font-size:small">  <b>Please enter date & time UTC</b></label>
              <div style="font-size: small;  white-space:nowrap; display: flex;">Effective Date </div>
              <div style="margin: -2.5em 0 0 6em;">
                <div style="display: flex;margin-top:-1.25em">
                  <mat-date-range-input [rangePicker]="picker"
                    style="display: flex; margin-top:0.5em; width:11.5em !important">
                    <input matStartDate formControlName="effectiveDate" #startDate placeholder="mm/dd/yy" maxlength="10"
                      [ngClass]="{ 'is-invalid1': isValideffectiveDate==false,  'is-valid1': isValidStartDate==true }"
                      (dateChange)="dateTypeChange()"
                      style="font-size:small;margin-top:0em; margin-right:1em;background-color:#eaf0fd; border-radius:0.25em;padding-left:0.125em;padding-right:0.125em; width: 6em !important">
                    <input matEndDate formControlName="expirationDate" #endDate placeholder="mm/dd/yy" maxlength="10"
                      [ngClass]="{ 'is-invalid1': !isValidexpirationDate,  'is-valid1': isValidEndDate }"
                      (dateChange)="dateTypeChange()"
                      style="font-size:small;margin-left:0em; background-color:#eaf0fd; border-radius:0.25em;padding-left:0.125em; width: 6em !important;  ">

                  </mat-date-range-input>
                  <!-- <div style="margin: 1em 0.4em 0 -0.5em;font-size: small;">(z)</div> -->
                  <mat-datepicker-toggle matSuffix [for]="picker"
                    style="margin-top:0.25em; margin-left:-0.7em"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
           
                
                <div style="display:flex; font-size: small; margin-top:-0.25em; margin-left: -3.5em;">
                  <div style="font-size: small;  white-space:nowrap; margin-left: -0.2em; margin-right:  1.1em;">
                    Times </div>
                  <div>
                    <input type="text" class="input-noborder" placeholder="HH:MM" formControlName="startTime"
                      maxlength="5" [ngClass]="{ 'is-invalid': f.startTime.errors }"
                      style="background-color:#eaf0fd; width:6em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em; border-color: lightgray"
                      (change)="timeChange('S', $event)" />
                  </div>
                  <div style="margin-left:0.25em; margin-right:0.25em"><i class="material-icons-outlined"
                      style="font-size: small;">remove
                    </i></div>
                  <div>
                    <input type="text" class="input-noborder" placeholder="HH:MM" formControlName="endTime"
                      maxlength="5" [ngClass]="{ 'is-invalid': f.endTime.errors }"
                      style="background-color:#eaf0fd; width: 6em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em; border-color: lightgray"
                      (change)="timeChange('E', $event)" />
                  </div>
                  <!-- <span style="margin-left: 0.3em;">(z)</span> -->
                </div>
              </div>
            </div>
          </div> 
        </div> 
      </div>

      <div style="margin-top:0.5em; height: 0.25em; background: #f8f8f8; margin-bottom: .9em;"></div> 
      <div> 
        <div style="display: flex;">
          <div style="font-size:small;  "><b> Alert Type:</b> </div>
      
          <button mat-button class="btn-high" style="font-size: small; margin: -0.5em 0 0.5em 1em;" [disabled]="!hasCountry"
            (click)="getAdvisoryTagListComponent()">
            + Tags</button> 
        </div>
        <div style="width: 57em; display: flex; flex-wrap: wrap;">
          <span
            *ngIf="filteredAdvisoryTags === 'Fuel' || filteredAdvisoryTags === 'Handling' || filteredAdvisoryTags === 'Hotels'"
            style="font-size: medium; color: #4b36e6; font-weight: 600; display: flex;">
            {{filteredAdvisoryTags}} </span>
          <span
            *ngIf="filteredAdvisoryTags != 'Fuel' && filteredAdvisoryTags != 'Handling' && filteredAdvisoryTags != 'Hotels'"
            style="font-size:medium; color: #4b36e6;   display: flex;"> {{filteredAdvisoryTags}} </span>
      
        </div>
      </div><div style="margin-top:0.5em; height: 0.25em; background: #f8f8f8; margin-bottom: .9em;"></div>

      <div style="display: flex;">
          <!-- <div style="  width: 25.8em;">
            <label for="ratingDDL" style="font-size:small; margin-right:1em; ">Rating</label>
          
          
            <select id="ratingDDL" class="form-control form-control-sm dropdown" style="width: 8.6em;font-size:small;"
              formControlName="lk_AdvisoryRatingID">
              <option *ngFor="let r of ratings" [value]="r.lookupId">{{r.description}}</option>
            </select>
          
          </div> -->

          <div style="width: 17.8em;" >
            <label for="ratingDDL" style="font-size:small; margin-right:1em; "><b> Rating</b></label>

            <div *ngIf="ratings != undefined" style="display: flex; margin-left:-0.5em;">
              <div *ngFor="let r of ratings" style="display: flex;">
                <div style="display: flex; margin: 0 0.5em;">
                  <div style="margin-right: 0.25em; justify-content: space-around; display: flex;">
                    <input formControlName="lk_AdvisoryRatingID" type="radio" name="lk_AdvisoryRatingID" [value]="r.lookupId" />
                  </div>
                  <div style="font-size: small; margin-top:-0.2em; white-space: nowrap;">
                    {{ r.description }}
                  </div>
                </div>
              </div>
            </div>
 
          </div>

          <div   >
            <label for="ratingDDL" style="font-size:small; margin-right:1em; "><b> Public News</b></label>

            <div *ngIf="ratings != undefined" style="display: flex; margin-left:-0.5em;">
              
                <div style="display: flex; margin: 0 0.5em;">
                  <div style="margin-right: 0.25em; justify-content: space-around; display: flex;">
                    <input formControlName="isPublicNews" type="radio" name="isPublicNews" [value]=true />
                  </div>
                  <div style="font-size: small; margin-top:-0.2em; white-space: nowrap;">
                    Yes
                  </div>
                </div>
                <div style="display: flex; margin: 0 0.5em;">
                  <div style="margin-right: 0.25em; justify-content: space-around; display: flex;">
                    <input formControlName="isPublicNews" type="radio" name="isPublicNews" [value]=false />
                  </div>
                  <div style="font-size: small; margin-top:-0.2em; white-space: nowrap;">
                    No
                  </div>
                </div> 
            </div>
 
          </div>

          <div style="display: flex;">
            <div >
              <label for="sourceDDL" style="font-size: small; margin-right:1em; "><b> Source</b></label>
              <select class="form-control form-control-sm dropdown" id="sourceDDL" formControlName="advisorySourceID"
                style="width: 11em;">
                <option *ngFor="let s of sources" [value]="s.lookupId">{{s.description}}</option>
              </select>
            </div>
          
          </div>
      </div>

      <div style="display: flex; margin-top:  0em;"> 
          <div style="display:flex">
            <div style="  display: flex; width: 36em;">
              <div style="display: flex; margin-right: 0.5em;  ">
                <!-- <div style="width: 0.5em; margin: 1em 1em 0 0;">
                              <div style="width: 0.2em; height: 160%;margin-top: -1em;float: right; background-color: #f18e8e; ">
                              </div>
                            </div> -->
                <div>
          
                  <div style="margin-top: 0em;" class="row">
                    <div class="col">
                      <label style="font-size:small; " for="inpTitle"><b>Title</b></label>
                      <input   style="font-size:small;width:42.7em " type="text" [disabled]="!hasTags"
                        id="inpTitle" formControlName="inpTitle"
                        [ngClass]="!hasTags?'form-control form-control-sm inputBox   ':'form-control form-control-sm inputBox'">
                    </div>
          
                  </div>
                  <div class="row">
                    <div class="col">
                      <div style="display: flex; justify-content: space-between; width: 100%;">
                        <label style="font-size:small; " for="inpIssue"><b>Issue</b></label>
                        <label *ngIf="totalCharactersInIssue > 0" style="font-size: small;">characters: {{totalCharactersInIssue}}</label>
                      </div> 
                      <textarea   style="font-size:small; " type="text" id="inpIssue" formControlName="issue"
                        [ngClass]="!hasTags?'form-control form-control-sm inputBox    input-lg2':'form-control form-control-sm inputBox  input-lg2'"></textarea>
                    </div>
                  </div>
                </div>
          
              </div>
            </div>
          </div>
        
        
        
        <div>
          <div>
            <div  style="margin-top: -3em;"   >
          
              <div style="display: flex; margin-right: 0.5em; ">
                <!-- <div style="width: 0.5em; margin: 1em 1em 0 0;">
                  <div style="width: 0.2em; height: 160%;margin-top: -1em;float: right; background-color: #f18e8e; ">
                  </div>
                </div> -->
                <div>
          
                  <div style="display: flex;">
                    <!-- <div style="font-size:small;  "> Ground Handlers SSN Fuel Vendors:
                    </div> -->
          
                    <button mat-button [disabled]="!(hasIcao)" class="btn-high" style="font-size: small;  "
                      (click)="getVendorListComponent()">
                      + Vendors</button>
          
                  </div>
                  <div style=" display: grid; height: 5em">
                    <span style="font-size:small; color: #4b36e6; font-weight: 600;"
                      *ngIf="groundHandlerVendorName">
                      Vendor: {{groundHandlerVendorName}} </span>
                    <span style="font-size:small; color: #4b36e6; font-weight: 600;" *ngIf="ssnVendorName">
                      SSN Vendor: {{ssnVendorName}}
                    </span>
                    <span style="font-size:small; color: #4b36e6; font-weight: 600;" *ngIf="fuelSupplierName">
                      Fuel Supplier: {{fuelSupplierName}} </span>
                    <span style="font-size:small; color: #4b36e6; font-weight: 600;" *ngIf="intoplaneAgent">
                      Into Plane Agent: {{intoplaneAgent}} </span>
          
          
                  </div>
                </div>
          
              </div>
              <div  style="display: flex; margin-right: 0.5em; margin-top: 1em;">
                <!-- <div style="width: 0.5em; margin: 1em 1em 0 0;">
                  <div style="width: 0.2em; height: 160%;margin-top: -1em;float: right; background-color: #f18e8e; ">
                  </div>
                </div> -->
                <div>
          
                  <div style="display: flex;">
                    <!-- <div style="font-size:small;   "> Hotels:
                    </div> -->
          
                    <button mat-button class="btn-high" [disabled]="!(hasIcao)" style="  font-size: small;"
                      (click)="getAdvisoryHotelListComponent()"> + Hotels </button>
          
                  </div>
                  <div style=" display: flex; flex-wrap: wrap; height: 1.1em;"><span style="font-size:small;  color: #4b36e6; font-weight: 600;">
                      {{
                      filteredHotel?.hotelName}}</span> </div>
                </div>
          
              </div>
            </div>
          </div>
          <div style="display: grid;">
          
            <div class="row">
              <div class="col">
                  <div >
                    <label   *ngIf="!f.inpWebLink.value" style="font-size:small; " for="inpWebLink">WebLink</label>
                    <!-- <button  *ngIf="f.inpWebLink.value" class="btn btn-link" style="text-decoration: underline;" [disabled]="!f.inpWebLink.value"
                      (click)="openLink(f.inpWebLink.value)">
                      Open Link
                    </button> -->
                  <a *ngIf="f.inpWebLink.value " style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" title="{{f.inpWebLink.value}}"
                    (click)="openLink(f.inpWebLink.value)">WebLink</a>
                  </div>
           
                  <textarea style="font-size:small; height: 7em; width: 35em" type="text" id="inpWebLink" formControlName="inpWebLink"
                    [ngClass]="!hasTags?'form-control form-control-sm inputBox ':'form-control form-control-sm inputBox '" ></textarea>
              </div>
           
          
            </div>
            <!-- <div class="col" style="font-size: small; display: inline-grid; margin-top: 1em;">
              <mat-slide-toggle labelPosition="before" formControlName="isHeadlineNews" [disabled]="!hasTags"
                color="primary">Share
                with HeadLine
                News</mat-slide-toggle>
              <mat-slide-toggle labelPosition="before" formControlName="isPublicNews" [disabled]="!hasTags" color="primary"><span
                  style="margin-right: 3.8em;"> Share with Clients</span></mat-slide-toggle>
           
            </div> -->

            <div *ngIf="hasIcao" style="margin-top:  1.8em;font-size: small;">
              <div style="width: 28em; display: inline-flex;">
                <label style="margin-bottom: 0em;">Documents</label>
                <label mat-button class="btn-high"
                  style="border: 0.0625em solid transparent; background-color: transparent; height:1.5em; border-radius: 0.25em; cursor:pointer; margin-left: 0.5em; margin-right: 0.5em;">
                  <input #document formControlName="document" id="document" type="file" ng2FileDrop [uploader]="uploader" multiple
                    style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000"
                    (click)="onFileClick($event)" />
                  <div style="display: flex"><i class="material-icons"
                      style="font-size: small; margin-top:0.25em; color: white; transform: rotate(-90deg);">attachment</i>
                    <div style="font-size: small;">Load New Documents</div>
                  </div><span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
                </label>
              </div>
            </div>

            <div *ngIf="hasIcao"  style=" height: 3em;" ng2FileDrop [uploader]="uploader"
              (onFileDrop)="dropFile($event)" accept="*" multiple>
              <div style="height: 100%;     background: white;">
                <div style="overflow-y:auto; height: 100%;">
                  <div *ngFor="let item of advisoryDocumentsList; let i=index" style="margin-top:-0.25em">
                    <ng-container *ngIf="item.isActive">
                      <div style="font-size: small; display:flex; padding:0em; height: 2em;">
                        <div>
                          <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline"
                            title="{{item.documentName}}" (click)="previewDocument($event, item)">{{item.documentName}}</a>
                        </div>
                     
                        <div style="margin-top:0.25em; margin-right: 0.25em" *ngIf="!lockdownTrip">
                          <i class="material-icons" style="color:red; font-size: small; cursor: pointer;"
                            title="click to remove the document" (click)="clickRemoveDocument($event, item)">cancel</i>
                        </div>
                        
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>



          </div>


        </div>
      





      </div> 


      <!-- <div style="overflow:auto;margin-top:1em">
        <table id="msg" mat-table matSort matSortActive="type" matSortDisableClear matSortDirection="asc"
          [dataSource]="advisoryDayHoursList"> 
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%">
              Type
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
              {{element.type}}
            </td>
          </ng-container>
 
          <ng-container *ngFor="let day of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']" [ngSwitch]="day">
            <ng-container matColumnDef="{{ day.toLowerCase() }}">
              <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%">
                {{ day }}
              </th>
              <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
                {{ element[day.toLowerCase()] == true? 'Yes': 'No' }}
              </td>
            </ng-container>
          </ng-container>
 
          <ng-container matColumnDef="twentyFourHrs">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%">
              24 Hrs
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
              {{ element.twentyFourHrs == true? 'Yes': 'No' }}
            </td>
          </ng-container>
 
          <ng-container matColumnDef="hoursStart">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 7%;width:7%">
              Hours Start
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 7%;width:7%">
              {{ element.hoursStart }}
            </td>
          </ng-container>
 
          <ng-container matColumnDef="hoursEnd">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 7%;width:7%">
              Hours End
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 7%;width:7%">
              {{ element.hoursEnd }}
            </td>
          </ng-container>
 
          <ng-container matColumnDef="remarks">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 15%;width:15%">
              Remarks
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 15%;width:15%">
              {{ element.remarks }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%">
              Action
              <button class="btn-high" mat-button>Add</button>

            </th>
            <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
              <button mat-button>Action</button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div> -->
 
      <!-- <div class="row" style="margin-top: 2em;"> 
        <div class="col">

          <div style="margin-top: -1.8em;font-size: small;">
            <div style="width: 28em; display: inline-flex;">
              <label style="margin-bottom: 0em;">Documents</label>
              <label mat-button class="btn-high"
                style="border: 0.0625em solid transparent; background-color: transparent; height:1.5em; border-radius: 0.25em; cursor:pointer; margin-left: 0.5em; margin-right: 0.5em;">
                <input #document formControlName="document" id="document" type="file" ng2FileDrop [uploader]="uploader"
                  multiple style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000"
                  (click)="onFileClick($event)" />
                <div style="display: flex"><i class="material-icons"
                    style="font-size: medium; margin-top:0.25em; color: white; transform: rotate(-90deg);">attachment</i>
                  <div style="font-size: small;">Load New Documents</div>
                </div><span *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1"></span>
              </label>
            </div>
          </div>

          <div style="border-radius: 0.25em;border: 1px solid rgb(206, 212, 218);
                                  height: 6em; width: 32.5em;" ng2FileDrop [uploader]="uploader"
            (onFileDrop)="dropFile($event)" accept="*" multiple>
            <div style="height: 100%;     background: white;">
              <div style="overflow-y:auto; height: 100%;">
                <div *ngFor="let item of advisoryDocumentsList; let i=index" style="margin-top:-0.25em">
                  <ng-container *ngIf="item.isActive">
                    <div style="font-size: small; display:flex; padding:0em; height: 2em;">
                      <div>
                        <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline"
                          title="{{item.documentName}}"
                          (click)="previewDocument($event, item)">{{item.documentName}}</a>
                      </div>
                      <div *ngIf="item.groundStopTaskDocumentGUID=='' && hasExistingDocument"
                        style="margin-top:0.5em; font-size:x-small">
                        <i class="material-icons" style="color:green; font-size: small; ">fiber_new</i>
                      </div>
                      <div style="margin-top:0.25em; margin-right: 0.25em" *ngIf="!lockdownTrip">
                        <i class="material-icons" style="color:red; font-size: small; cursor: pointer;"
                          title="click to remove the document" (click)="clickRemoveDocument($event, item)">cancel</i>
                      </div>
                      <div *ngIf="!item.isInternal" title="Toggle Public/Internal"
                        style="cursor: pointer; user-select: none;" (click)="toggleInternalFile(item)">
                        <i class="material-symbols-outlined">more_horiz</i>
                      </div>
                      <div *ngIf="item.isInternal" title="Toggle Public/Internal"
                        style="cursor: pointer; background-color:#f9d2de; border-radius:0.25em; height: 1.5em; user-select: none;"
                        class="d-block" (click)="toggleInternalFile(item)">Internal...</div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>
 
      </div> --> 
  </div>


  <div style="display: flex; width: 100%; justify-content: space-between;">
    <div style="display: flex;">

      <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em;   margin-right:1em; ">
        <button [disabled]="!hasTags || !dataChanged" class="btn-high" mat-button (click)="clickSave()">Save</button>
        <button class="btn-low" mat-button (click)="clickClose(false)"
          style="background-color:grey; color: white; margin-left: 1em;">Close</button>
      </div>
 

      <div *ngIf="errMsg!=''"
        style="margin:0; display:flex;margin-top:0.25em; background-color:#f9d2de; padding-left:0.25em;height: 2em;">
        <label style="color:black; font-size: small">{{errMsg}}</label>
      </div>
      <!-- <div *ngIf="showSuccessMsg" style="margin-top:0.25em">
        <app-success-message [successMsg]=""></app-success-message>
      </div> -->
    </div>

    <div style="display: flex;">

      <div style="display: flex;margin-right: 2em; margin-top: 0.6em;">
        <label style="font-size:small">Disable Record</label>
        <div style="  display: inline-block;">
          <select formControlName="isActive" class="form-control form-control-sm dropdown" style="margin-left: 1em; ">
            <option value=true>No</option>
            <option value=false>Yes</option>
          </select>
        </div>
      </div>

      <div *ngIf="dialogTitle == 'Edit'" class="flex-column" style="margin-bottom: -0.8em;">
        <div>
          <label for="modifiedBy" style="font-size:small">Modified By: {{modifiedBy}}</label>
        </div>
        <div style="margin-top:-.5em">
          <label for="modifiedDate" style="font-size:small">Modified Date: {{modifiedDate}}</label>
        </div>

      </div>
    </div>
  </div>
  </form>
</div>
