<div class="p-1 d-flex" cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle [ngClass]="from=='manage' && this.airports.length>1? 'justify-content-between':'justify-content-end'">
  <mat-slide-toggle *ngIf="from=='manage' && this.airports.length>1" style="transform:scale(.8)" color="primary" [(ngModel)]="showRoute" [ngModelOptions]="{standalone: true}" (change)="createMap()">Show Route</mat-slide-toggle>
  <span mat-dialog-close class="material-icons float-right">close</span>
</div>

<div style="position: relative;">
  <div style="margin-left: .5em;width: 99%; height: 90vh; border-radius: .25em " id="map2" class="border"></div>
  <div style="position: absolute; top: .5em; left: 1em; padding-top: .25em; padding-bottom: .25em; background-color: white; font-size: small; border-radius: 10px; " class="small d-inline-flex">
    <!--Select weather overlay:
  <select [(ngModel)]="layerName" style="font-size:small;height:1.85em;padding:0" (change)="updateTileLayer()" [ngModelOptions]="{standalone: true}" class="form-control form-control-sm">
    <option value="">None</option>
    <option value="microsoft.weather.radar.main">Radar</option>
    <option value="microsoft.weather.infrared.main">Infrared</option>
  </select>-->
    <mat-button-toggle-group style="transform:scale(.8)" [(ngModel)]="layerName" aria-label="Weather Layer" (change)="updateTileLayer()">
      <mat-button-toggle matTooltip="Clear" value=""><span class="material-symbols-outlined">do_not_disturb_on</span></mat-button-toggle>
      <mat-button-toggle matTooltip="Radar" value="microsoft.weather.radar.main"><span class="material-symbols-outlined">radar</span></mat-button-toggle>
      <mat-button-toggle matTooltip="Infrared" value="microsoft.weather.infrared.main"><span class="material-symbols-outlined">contactless</span></mat-button-toggle>
    </mat-button-toggle-group>    
  </div>
  </div>

