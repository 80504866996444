import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopCreatorDialogComponent } from '../ground-stops/ground-stop-creator-dialog.component';
import { GroundStopEditorDialogComponent } from '../ground-stops/ground-stop-editor-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopTaskEditDialogComponent } from '../ground-stop-advanced/ground-stop-task-edit-dialog.component';
import { GroundStopServiceTypeSelectionDialogComponent } from '../ground-stop-advanced/ground-stop-service-type-selection-dialog.component';
import { ServiceClassModel } from '../models/service-class.model';
import { GroundStopCrewPaxEditDialogComponent } from '../ground-stop-advanced/ground-stop-crew-pax-edit-dialog.component';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAlternateAirportEditDialogComponent } from '../ground-stop-advanced/ground-stop-alternate-airport-edit-dialog.component';
import { GroundStopDetailsDialogComponent } from '../ground-stops/ground-stop-details-dialog.component';
import { AirportDetailsComponent } from '../airports/airport-details.component';
import { GroundStopAlternateAirportModel } from '../models/ground-stop-alternate-airport.model';
import { FlightFollowingEditDialogComponent } from '../ground-stop-advanced/flight-following-edit-dialog.component';
import { TripRevisionModel } from '../models/trip-revision.model';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
import { TripAuditDialogComponent } from '../ground-stops/trip-audit-dialog.component';
import { TaskRevisionRequiredEditDialogComponent } from '../ground-stop-advanced/task-revision-required-edit-dialog.component';
import { ClientHaltServicesViewDialogComponent } from '../ground-stop-advanced/client-halt-services-view-dialog.component';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { VendorModel } from '../models/vendor.model';
import { AddBusinessRulesTasksDialogComponent } from '../ground-stop-advanced/add-business-rules-tasks-dialog.component';
import { AuthenticationType } from 'azure-maps-control';
import * as atlas from 'azure-maps-control';
import { AlertList } from '../current-events-client/client-alert-dialog.component';
import { MatAccordion } from '@angular/material/expansion';
import { AircraftDocumentModel } from '../models/aircraft-document.model';
import { CustomerDocumentModel } from '../models/customer-document.model';
import { GroundStopManifestDocumentModel } from '../models/ground-stop-manifest-document.model';
import { TripLegDocumentDownloadsDialogComponent } from '../trip-legs/trip-leg-document-downloads-dialog.component';
import { AzureMapComponent } from '../common-utility/azure-map.component';
import { TripDashboardModel } from '../models/trip-dashboard';
import { TripLegClientService } from '../services/trip-leg-client.service';
import { CustomerPersonDetailsComponent } from '../persons/customer-person-details.component';
import { AirportModel } from '../models/airport.model';
import { AirportBriefComponent } from '../trip-planner/airport-brief.component';

export class TableElement {
  legItem: TripLegModel;
  expanded: boolean;
  refreshChildRow: boolean;
  updateTask: boolean;
}

@Component({
  selector: 'app-ground-stop-leglist-client',
  templateUrl: './ground-stop-leglist-client.component.html',
  styleUrls: ['./ground-stop-leglist-client.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})

export class GroundStopLeglistClientComponent implements OnInit, OnChanges {

  @Input() requestInput: TripModel;
  @Input() reloadChildPage: boolean;
  @Input() selectedTabName: string;
  @Input() v: number;
  @Input() refreshChildColumn: string;
  @Input() updateGroundStopGUID: string;
  @Input() hidePastStop: boolean;
  @Input() customerGUID: string;


  request: TripModel;
  //customerGUID: string;
  ground_stop_leglist_client_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = false;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;

  legList: TableElement[];
  oldLegList: TableElement[];
  @Output() public updateParentPage = new EventEmitter();

  //displayedColumn: string[] = ['legOrder', 'departure', 'arrival', 'crew', 'pax', 'far', 'other', 'legStatus', 'action', 'expand'];
  displayedColumn: string[] = ['legOrder', 'departure', 'arrival', 'crew', 'pax', 'far', 'other', 'action', 'expand'];
  //displayedColumn2: string[] = ['expand_collapse'];
  scrHeight: number;
  upPII: boolean = false;
  gsId: string = "";
  gstId: string = "";
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";
  map: atlas.Map;
  points: any = [];
  //alerts
  acDocAlerts: AlertList[] = [];
  expiredACDocList: AircraftDocumentModel[];
  customerDocAlerts: AlertList[] = [];
  expiredCustomerDocList: CustomerDocumentModel[];
  expiredTravelDocAlerts: AlertList[] = [];
  expiredTravelDocList: GroundStopManifestDocumentModel[];
  missingTravelDocAlerts: AlertList[] = [];
  missingTravelDocuments: GroundStopManifestModel[];
  waitingClientTaskAlerts: AlertList[] = [];
  waitingTaskList: GroundStopTaskModel[];
  waitingVendorTaskAlerts: AlertList[] = [];
  waitingVendorTaskList: GroundStopTaskModel[];
  openTaskAlerts: AlertList[] = [];
  openTaskList: GroundStopTaskModel[];
  manifestList: GroundStopManifestModel[];
  missingCrewAlerts: AlertList[] = [];
  missingCrewList: TripLegModel[] = [];
  missingPaxAlerts: AlertList[] = [];
  missingPaxList: TripLegModel[] = [];
  onHoldTaskAlerts: AlertList[] = [];
  onHoldTaskList: GroundStopTaskModel[];

  @ViewChild(MatAccordion) alertAccordion: MatAccordion;
  
  grantAccessToManageManifestProfiles: boolean;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _tripLegClientService: TripLegClientService, private readonly _tripLegService:TripLegService
  ) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //if ((!this.reloadChildPage && this.refreshChildColumn == "") || this.selectedTabName != 'leg') {     
    //    return;    
    //}
    if (this.selectedTabName != 'leg') {
      return;
    }
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
      return;
    }
    else {
      this.upPII = true;
    }
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.request = (JSON.parse(JSON.stringify(this.requestInput)));
    if (!this.reloadChildPage && this.refreshChildColumn == "") {
    }
    else {
      if (this.refreshChildColumn == "") {
        this.showSpin = true;

        this.scrHeight = window.innerHeight;
        //if (this.request.groundStopGUID != null && this.request.groundStopGUID != undefined && this.request.groundStopGUID != "")
        //  this.gsId = this.request.groundStopGUID.toLowerCase();
        //if (this.request.groundStopTaskGUID != null && this.request.groundStopTaskGUID != undefined && this.request.groundStopTaskGUID != "")
        //  this.gstId = this.request.groundStopTaskGUID.toLowerCase();

        this.initControls();
        this.getLegList();
      }
    }

  }


  initControls() {
    this.ground_stop_leglist_client_form = this._formBuilder.group({

    })
  }

  get f() { return this.ground_stop_leglist_client_form.controls; }

  getLegList() {
    this.oldLegList = [];
    if (this.legList != undefined && this.legList != null) {
      if (this.legList.length > 0) {
        this.legList.forEach(x => {
          var ol = new TableElement();
          ol.legItem = x.legItem;
          ol.expanded = x.expanded;
          ol.refreshChildRow = false;
          this.oldLegList.push(ol);
        });
      }
    }
    this.legList = [];
    this.acDocAlerts = [];
    this.expiredACDocList = [];
    this.customerDocAlerts = [];
    this.expiredCustomerDocList = [];
    this.expiredTravelDocAlerts = [];
    this.expiredTravelDocList = [];
    this.missingTravelDocAlerts = [];
    this.missingTravelDocuments = [];
    this.waitingClientTaskAlerts = [];
    this.waitingTaskList = [];
    this.waitingVendorTaskAlerts = [];
    this.waitingVendorTaskList = [];
    this.openTaskAlerts = [];
    this.openTaskList = [];
    this.manifestList = [];
    this.missingCrewAlerts = [];
    this.missingCrewList = [];
    this.missingPaxAlerts = [];
    this.missingPaxList = [];
    
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.totalRecordsText = "";
    this.request.isFromLegEdit = false;
    this._tripLegClientService.getGroundStopLegListForClientByConditions<ResponseModel<TripLegModel[]>>(this.request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          var result = response.model;
          this.legList = [];
          let findex: number;
          for (var i = 0; i < result.length; i++) {
            var te = new TableElement();
            te.legItem = result[i];
            if (this.oldLegList.length > 0) {
              findex = this.oldLegList.findIndex(x => x.legItem.groundStopGUID.toLowerCase() == te.legItem.groundStopGUID.toLowerCase() && x.legItem.nextArrivalGroundStopGUID.toLowerCase() == te.legItem.nextArrivalGroundStopGUID.toLowerCase());
              if (findex >= 0) {
                te.expanded = this.oldLegList[findex].expanded;
              }
              else {
                te.expanded = false;
              }
            }
            else {
              te.expanded = false;
            }

            if (te.expanded) {
              te.refreshChildRow = true;
            }
            else {
              te.refreshChildRow = false;
            }
            this.legList.push(te);
          }

          this._tripLegService.getAlertListForTripLegs<ResponseModel<TripDashboardModel>>(result).subscribe(response => {
            if (response != null && response.code == "200") {
              let tripDashboard = response.model;
              this.expiredACDocList = tripDashboard.expiredAircraftDocuments;
              this.expiredCustomerDocList = tripDashboard.expiredCustomerDocuments;
              if (result != null) {
                this.missingCrewList = result.filter(x => x.crewCount == 0);
                this.missingPaxList = result.filter(x => x.paxCount == 0 && !x.isDeptFerryFlight);
                let groundStopGUID;
                result.forEach(x => {
                  
                    x.pic.groundStopManifestDocumentList.forEach(z => {
                      if (z.isExpiredForGroundStop) {
                        if (!this.expiredTravelDocList.includes(z))
                          this.expiredTravelDocList.push(z);
                      }
                    });
                    if (x.pic.isBellAlert && x.pic.lastName.toLowerCase() != 'crew tba' && x.pic.lastName.toLowerCase() != 'crew' && x.pic.lastName.toLowerCase() != 'pax'
                      && x.pic.lastName.toLowerCase() != 'pax tba' && x.pic.lastName.toLowerCase() != 'crew followup' && x.pic.lastName.toLowerCase() != 'pax followup') {
                      this.missingTravelDocuments.push(x.pic);
                    }


                  x.sic.groundStopManifestDocumentList.forEach(z => {
                    if (z.isExpiredForGroundStop) {
                      if (!this.expiredTravelDocList.includes(z))
                        this.expiredTravelDocList.push(z);
                    }
                  });
                  if (x.sic.isBellAlert && x.sic.lastName.toLowerCase() != 'crew tba' && x.sic.lastName.toLowerCase() != 'crew' && x.sic.lastName.toLowerCase() != 'pax'
                    && x.sic.lastName.toLowerCase() != 'pax tba' && x.sic.lastName.toLowerCase() != 'crew followup' && x.sic.lastName.toLowerCase() != 'pax followup') {
                    this.missingTravelDocuments.push(x.sic);
                  }

                  x.tripCrewList.forEach(y => {
                    y.groundStopManifestDocumentList.forEach(z => {
                      if (z.isExpiredForGroundStop) {
                        if (!this.expiredTravelDocList.includes(z))
                          this.expiredTravelDocList.push(z);
                      }
                    });
                    if (y.isBellAlert && y.lastName.toLowerCase() != 'crew tba' && y.lastName.toLowerCase() != 'crew' && y.lastName.toLowerCase() != 'pax'
                      && y.lastName.toLowerCase() != 'pax tba' && y.lastName.toLowerCase() != 'crew followup' && y.lastName.toLowerCase() != 'pax followup') {
                      this.missingTravelDocuments.push(y);
                    }
                    
                  });
                  x.tripPAXList.forEach(y => {
                    y.groundStopManifestDocumentList.forEach(z => {
                      if (z.isExpiredForGroundStop) {
                        if (!this.expiredTravelDocList.includes(z))
                          this.expiredTravelDocList.push(z);
                      }
                    });
                    if (y.isBellAlert && y.lastName.toLowerCase() != 'crew tba' && y.lastName.toLowerCase() != 'crew' && y.lastName.toLowerCase() != 'pax'
                      && y.lastName.toLowerCase() != 'pax tba' && y.lastName.toLowerCase() != 'crew followup' && y.lastName.toLowerCase() != 'pax followup') {
                      this.missingTravelDocuments.push(y);
                    }                    

                  });
                });

                if (this.missingTravelDocuments != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(this.missingTravelDocuments, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.missingTravelDocAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: this.missingTravelDocuments.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
               
                groundStopGUID = "";
                this.waitingTaskList = tripDashboard.waitingTaskList;
                if (this.waitingTaskList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(tripDashboard.waitingTaskList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.waitingClientTaskAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: tripDashboard.waitingTaskList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                groundStopGUID = "";
                this.onHoldTaskList = tripDashboard.onHoldTaskList;
                if (this.onHoldTaskList != null) {
                  result.forEach(x => {
                    if (Array.prototype.map.call(tripDashboard.onHoldTaskList, s => s.groundStopGUID).toString().indexOf(x.groundStopGUID) > -1) {
                      if (groundStopGUID != x.groundStopGUID) {
                        groundStopGUID = x.groundStopGUID;
                        this.onHoldTaskAlerts.push({
                          categoryName: x.tripLegOrder + ' ' + x.departureICAO + '-' + x.nextArrivalICAO + ' / ' + x.departureDateTextUTC + ' Z',
                          alertObject: tripDashboard.onHoldTaskList.filter(y => y.groundStopGUID == groundStopGUID)
                        });
                      }
                    }
                  });
                }
                
                
              }
            }
            else {
              if (response.code == "401") {

                this._authService.signOut();
              }
              else {
                this.errMsg = "Failed to get trip alerts at this time. Please try later.";
              }
            }

          });

        }
        else {
          this.totalRecordsText = "No record found";


        }
        this.createMap();
        this.showSpin = false;

      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  expandCollapseLeg(e: any, item: TableElement, flag: string) {
    this.refreshChildColumn = "";
    if (item.expanded == false) {
      item.expanded = true;
      item.refreshChildRow = true;
    }
    else {
      item.expanded = false;
    }
    
  }

  openAll() {
    this.legList.forEach(x => {
      x.expanded = true;
      x.refreshChildRow = true;
    });
  }

  closeAll() {
    this.legList.forEach(x => {
      x.expanded = false;
    });
  }

  //clickEditAlternateAirport(event: any, item: TripLegModel) {
  //  this._authService.updateAccessTime();
  //  let s = "";
  //  const dialogConfig = new MatDialogConfig();
  //  dialogConfig.autoFocus = true;
  //  dialogConfig.disableClose = true;

  //  let leg: string = "";

  //  leg = item.departureICAO;
  //  if (item.nextArrivalICAO != "") {
  //    leg += "-" + item.nextArrivalICAO;
  //  }

  //  dialogConfig.data = {
  //    groundStopGUID: item.groundStopGUID, leg: leg, nextGroundStopGUID: item.nextArrivalGroundStopGUID,
  //    tripCodeGUID: item.tripCodeGUID, aircraftGUID: item.aircraftGUID, v: this._authService.getCurrentTimeNumber()
  //  };

  //  const dialogRef = this._dialog.open(GroundStopAlternateAirportEditDialogComponent, dialogConfig);
  //  dialogRef.afterClosed().subscribe(result => {
  //    if (result.refresh == true) {
  //      this.getTripLegBydgIdagId(item.groundStopGUID, item.nextArrivalGroundStopGUID);
  //    }
  //  });

  //}

  clickGroundStopDetail(event: any, item: GroundStopModel) {
    event.stopPropagation();
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      groundStopGUID: item.groundStopGUID, isFirstGroundStop: item.isFirstGroundStop, nextGroundStopGUID: item.nextGroundStopGUID,
      tripCodeGUID: item.tripCodeGUID, tripCodeType: item.tripCodeType, v: this._authService.getCurrentTimeNumber()
    };

    //const dialogRef = this._dialog.open(GroundStopDetailsDialogComponent, dialogConfig);
    //dialogRef.afterClosed().subscribe(result => {
    //  if (result.refresh == true) {
    //    let rIndex: number;
    //    this.getTripInfo(item.tripCodeGUID);
    //    this.updateParentPage.emit({ updateSection: 'groundStop' });
    //    this.getTripRevisionUsernameListBygIdList('');

    //  }
    //});

  }

  //getTripLegBydgIdagId(depGroundStopGUID: string, arrGroundStopGUID) {
  //  this._authService.updateAccessTime();
  //  let req = new TripLegModel();
  //  req.groundStopGUID = depGroundStopGUID;
  //  req.nextArrivalGroundStopGUID = arrGroundStopGUID;
  //  req.aircraftGUID = this.request.aircraftGUID
  //  this._tripLegService.getTripLegBydgIdagId<ResponseModel<TripLegModel>>(req).subscribe(response => {
  //    if (response != null && response.code == "200") {
  //      if (response.model != null) {
  //        let obj = new TripLegModel();
  //        obj = response.model;
  //        let i: number;
  //        i = this.legList.findIndex(x => x.legItem.groundStopGUID.toLowerCase() == depGroundStopGUID.toLowerCase() && x.legItem.nextArrivalGroundStopGUID.toLowerCase() == arrGroundStopGUID.toLowerCase());
  //        if (i != -1) {
  //          obj.tripLegOrder = this.legList[i].legItem.tripLegOrder;
  //        }
  //        this.legList[i].legItem = obj;
  //      }
  //    }
  //    else {
  //      if (response.code == "401") {
  //        this._authService.signOut();
  //      }
  //      else {
  //        this.errMsg = "Failed to get trips at this time. Please try later.";
  //      }
  //    }

  //  })

  //}

  clickPersonName(e: any, itemLeg: TripLegModel, item: GroundStopManifestModel) {
    e.stopPropagation();
    if (item.capacityID !=1) {
      return;
    }
    if (this.grantAccessToManageManifestProfiles) {
      //window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID + '&selectedPersonClassDescription=' + item.personClassDescription + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
      // window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID +  '&tripCodeGUID=' + itemLeg.tripCodeGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.panelClass = "custom-dialog-container";
      dialogConfig.maxHeight = "95vh";
      dialogConfig.hasBackdrop = false;
      dialogConfig.data = { personGUID: item.personGUID, customerGUID: this.customerGUID, selectedPersonClassGUID: item.personClassGUID, selectedPersonClassDescription: item.personClassDescription, tripCodeGUID:itemLeg.tripCodeGUID };

      const dialogRef = this._dialog.open(CustomerPersonDetailsComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // this.getAllData();
        }

      });
    }
    else
      return;

    //if (itemLeg.departureGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID || itemLeg.departureGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID
    //  || itemLeg.nextArrivalGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID || itemLeg.nextArrivalGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID) {
    //  window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
    //}
    //else {
    //  const dialogConfig = new MatDialogConfig();
    //  dialogConfig.autoFocus = true;
    //  dialogConfig.disableClose = true;
    //  let personType: string = "";
    //  personType = item.groundStopManifestTypeDescription;
    //  personType = personType.replace("Departure ", "");
    //  personType = personType.replace("Arrival ", "");
    //  let alreadyHaveCrewOnTrip: boolean = false;
    //  let alreadyHavePaxOnTrip: boolean = false;
    //  if (personType != 'PAX') {
    //    alreadyHaveCrewOnTrip = true;
    //  }
    //  else {
    //    alreadyHavePaxOnTrip = true;
    //  }
    //  dialogConfig.data = {
    //    tripCodeGUID: itemLeg.tripCodeGUID, customerGUID: itemLeg.customerGUID, personGUID: item.personGUID, groundStopGUID: itemLeg.groundStopGUID, nextArrivalGroundStopGUID: itemLeg.nextArrivalGroundStopGUID,
    //    departureCountryGUID: itemLeg.departureCountryGUID, personType: personType, actionType: 'Edit', v: this._authService.getCurrentTimeNumber()
    //  };

    //  const dialogRef = this._dialog.open(TripPersonEditorDialogComponent, dialogConfig);
    //  dialogRef.afterClosed().subscribe(result => {
    //    if (result.refresh == true) {
    //      this.getLegList();
    //      this.updateParentPage.emit({ updateSection: 'leg' });
    //    }
    //  });

    //}

  }

  clickAltICAO(e: any, leg: TripLegModel, a: GroundStopAlternateAirportModel, legType: string) {
    e.stopPropagation();
    //const dialogConfig = new MatDialogConfig();
    //dialogConfig.autoFocus = true;
    ////dialogConfig.disableClose = true;
    //dialogConfig.panelClass = "custom-dialog-container";
    //// dialogConfig.width = "45em";
    //let groundStopGUID: string = "";
    //if (legType == "D") {
    //  groundStopGUID = leg.groundStopGUID;
    //}
    //else {
    //  groundStopGUID = leg.nextArrivalGroundStopGUID;
    //}
    //dialogConfig.data = {
    //  icao: a.icao,
    //  icaoIata: a.icaO_IATA,
    //  groundStopGUID: groundStopGUID,
    //  tripCodeGUID: leg.tripCodeGUID
    //};
    //this._dialog.open(AirportDetailsComponent, dialogConfig);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";
    let airport = new AirportModel();
    airport.airportID = a.airportID;
    airport.latitude = a.Latitude;
    airport.longitude = a.Longitude;
    airport.icao = a.icao;
    airport.iata = a.iata;
    airport.countryId = a.countryID,
      airport.airportName = a.airportName;
    dialogConfig.data = {
      selectedAirport: airport,
      airportID: legType == "D" ? leg.departureAirportID : leg.nextArrivalAirportID,
      icao: legType == "D" ? leg.departureICAO : leg.nextArrivalICAO,
      customerGUID: this.customerGUID,
      farTypeID: leg.departureFARTypeID,
      farType: leg.departureFARTypeDescription,
      route: '',
      handlerGUID: a.vendorGUID,
      ssnGUID: null,
      callFrom: 'trip'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);
  }


  clickICAO(e: any, leg: TripLegModel, legType: string) {
    e.stopPropagation();
    //const dialogConfig = new MatDialogConfig();
    //dialogConfig.autoFocus = true;
    ////dialogConfig.disableClose = true;
    //dialogConfig.panelClass = "custom-dialog-container";
    ////    dialogConfig.maxWidth = "70em";

    //if (legType == "D") {
    //  dialogConfig.data = {
    //    icao: leg.departureICAO,
    //    icaoIata: leg.departureICAO_IATA,
    //    groundStopGUID: leg.groundStopGUID,
    //    tripCodeGUID: leg.tripCodeGUID
    //  };
    //}
    //else {
    //  dialogConfig.data = {
    //    icao: leg.nextArrivalICAO,
    //    icaoIata: leg.nextArrivalICAO_IATA,
    //    groundStopGUID: leg.nextArrivalGroundStopGUID,
    //    tripCodeGUID: leg.tripCodeGUID
    //  };
    //}
    //this._dialog.open(AirportDetailsComponent, dialogConfig);

    let airport = new AirportModel();
    airport.airportID = legType == "D" ? leg.departureAirportID : leg.nextArrivalAirportID;
    airport.latitude = legType == "D" ? leg.departureLatitude : leg.nextArrivalLatitude;
    airport.longitude = legType == "D" ? leg.departureLongitude : leg.nextArrivalLongitude;
    airport.icao = legType == "D" ? leg.departureICAO : leg.nextArrivalICAO;
    airport.iata = legType == "D" ? leg.departureIATA : leg.nextArrivalIATA;
    airport.airportName = legType == "D" ? leg.departureAirportName : leg.nextArrivalAirportName;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";

    dialogConfig.data = {
      selectedAirport: airport,
      airportID: airport.airportID,
      icao: airport.icao,
      customerGUID: this.customerGUID,
      farTypeID: leg.departureFARTypeID,
      farType: leg.departureFARTypeDescription,
      route: '',
      handlerGUID: legType == "D" ? leg.departureVendorList.find(x => x.vendorRoleDescription == 'HandlingVendor')?.vendorGUID : leg.nextArrivalVendorList.find(x => x.vendorRoleDescription == 'HandlingVendor')?.vendorGUID,
      ssnGUID: legType == "D" ? leg.departureVendorList.find(x => x.vendorRoleDescription == 'SSNVendor')?.vendorGUID : leg.nextArrivalVendorList.find(x => x.vendorRoleDescription == 'SSNVendor')?.vendorGUID,
      callFrom: 'trip'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);

  }

  onUpdateParentPage(e: any) {

    switch (e.updateSection) {
      case "clearRefreshTaskStatus":
        this.legList.forEach(x => {
          if (x.expanded)
            x.refreshChildRow = false;
        })
        break;
      case "allLegTask":
        this.legList.forEach(x => {
          if (x.expanded)
            x.refreshChildRow = true;
        })
        this.updateParentPage.emit({ updateSection: 'allLegTask' });
        break;
      case "leg":
        this.updateParentPage.emit({ updateSection: 'leg' });
        break;
      case "groundStop":
        break;
    }

  }


  

  clickDownloadTripDocument(e: any, item: TripLegModel) {
    e.stopPropagation();
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      groundStopGUID: item.groundStopGUID, nextGroundStopGUID: item.nextArrivalGroundStopGUID,
      tripCodeGUID: item.tripCodeGUID, customerGUID: item.customerGUID, aircraftGUID: item.aircraftGUID, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripLegDocumentDownloadsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        
      }
    });
  }


  createMap() {

    this.map = new atlas.Map('map', {
      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      var zoomControl = new atlas.control.ZoomControl();

      /* Add the zoom control to the map*/
      self.map.controls.add(zoomControl, {
        position: atlas.ControlPosition.TopRight
      });

      if (self.legList.length > 0) {
        self.legList.forEach((v, i) => {
          var startPosition = [v.legItem.departureLongitude, v.legItem.departureLatitude];
          var endPosition = [v.legItem.nextArrivalLongitude, v.legItem.nextArrivalLatitude];
          var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
            title: v.legItem.departureICAO,
            name: v.legItem.departureAirportName
          });

          var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
            title: v.legItem.nextArrivalICAO,
            name: v.legItem.nextArrivalAirportName
          });
          self.points.push(startPosition);
          self.points.push(endPosition);
          var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
          var poly = new atlas.data.LineString(path);
          dataSource.add([poly, startPoint, endPoint]);

        });

        var popupTemplate = "<div class='popup'>{name}</div>";
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            image: 'pin-round-darkblue',
            cluster: false,
            optional: true,
            allowOverlap: true,
            size: .5
          },
          textOptions: {
            textField: ['get', 'title'],
            offset: [0, -1.5],
            cluster: false,
            allowOverlap: true,
            size: 10
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        });
        self.map.layers.add([new atlas.layer.LineLayer(dataSource, null, {
          strokeColor: "black",
          strokeWidth: 1.5
        }),
          symbolLayer
        ]);
        var popup = new atlas.Popup({
          pixelOffset: [0, -18],
          closeButton: false
        });
        //Add a hover event to the symbol layer.
        self.map.events.add('mouseover', symbolLayer, function (e) {
          //Make sure that the point exists.
          if (e.shapes && e.shapes.length > 0) {
            var pos = e.position;
            var offset = [0, 0];
            var properties;
            var content, coordinate;

            if (e.shapes[0] instanceof atlas.Shape) {
              properties = e.shapes[0].getProperties();

              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].getType() === 'Point') {
                coordinate = e.shapes[0].getCoordinates();
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            } else {
              properties = e.shapes[0].properties;
              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].type === 'Point') {
                coordinate = e.shapes[0].geometry.coordinates;
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            }
            popup.setOptions({
              //Update the content of the popup.
              content: content,

              //Update the popup's position with the symbol's coordinate.
              position: coordinate

            });
            //Open the popup.
            popup.open(self.map);
          }
        });

        self.map.events.add('mouseleave', symbolLayer, function () {
          popup.close();
        });

        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        self.map.setCamera({
          bounds: bbox,
          padding: 40
        });
      }


    });
  }
  openMapComponent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80em";
    dialogConfig.height = "40em";
    //dialogConfig.panelClass = "custom-dialog-container"

    dialogConfig.data = {
      legList: this.legList
    };

    this._dialog.open(AzureMapComponent, dialogConfig);

  }


  hasTasks(taskList: any, taskType: string): boolean {
    if (taskList.filter(x => x.serviceLocation == taskType).length > 0)
      return true;
    else
      return false;
  }
}
