import { Component, Inject, OnInit, Optional } from "@angular/core";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService } from "../services/dialog.service";
import { CustomerProfileSetupComponent2 } from "../customers/customer-profile-setup2.component";
import { AuthenticateService } from "../services/authenticate.service";
import { CustomerProfileSetupEditComponent } from "../customers/customer-profile-setup-edit.component";
import { CustomerInformationDialogComponent } from "../customers/customer-information-dialog.component";
import { AircraftProfileComponent } from "../aircraft/aircraft-profile.component";
import { RegisterUserComponent } from "./register-user.component";
import { ResetPasswordAdminComponent } from "./reset-password-admin.component";
import { UserPermissionClientComponent } from "./user-permission-client.component";
import { ManageContactDetailsComponent } from "../persons/manage-contact-details.component";
import { UserPermissionAdminComponent } from "./user-permission-admin.component";
import { MaintenanceDashboardComponent } from "../common-utility/maintenance-dashboard.component";

@Component({
  selector: 'app-admin-services-client',
  templateUrl: './admin-services-client.component.html',
  styleUrls: ['./admin-services-client.component.css']
})

export class AdminServicesClientComponent implements OnInit {
  customerGuid: string = "";
  selectedClientName: string = "";
  userType: string = "";
  isPopup: boolean = false;

  constructor(private readonly _dialog: MatDialog, private _router: Router,
    private _route: ActivatedRoute, private readonly _dialogService: DialogService,
    private readonly _authService: AuthenticateService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private readonly _dialogRef: MatDialogRef<AdminServicesClientComponent>) {

  }

  ngOnInit(): void {
    if (this.data) {
      this.customerGuid = this.data.customerGUID;
      this.selectedClientName = this.data.customerName;
      this.userType = this.data.userType;
      this.isPopup = true;
    }
  }

  openClientProfileSetupComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.width = "55em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "10px", top: "140px" };
    config.data = { gsId: "", tripCodeId: "", isDialog: true, v: this._authService.getCurrentTimeNumber() };

    this.clickClose(true);

    if (this.userType === 'customer') {
      this._dialog.open(CustomerProfileSetupComponent2, config);
    }
    else if (this.userType === 'internal') {
      config.height = "34em";
      config.width = "43em";
      this._dialog.open(MaintenanceDashboardComponent, config);
    }
  }

  clickClose(val) {
    this._dialogRef.close(val);
  }

  openSelection(columnName: string) {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "32.5em";//"1000px";
    //config.maxWidth = "65em";//"1000px";
    config.width = "65em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGuid, customerName: this.selectedClientName };
    config.position = { left: "10px", top: "140px" };

    this.clickClose(true);

    switch (columnName) {
      case 'Acct Setup':
        config.height = "50em";
        config.width = "90em";
        config.maxWidth = "90em";
        config.maxHeight = "50em";
        config.position = { left: "10px", top: "140px" };
        this._dialog.open(CustomerProfileSetupEditComponent, config);
        break;
      case 'Client Information':
        config.width = "69em";
        this._dialog.open(CustomerInformationDialogComponent, config);
        break;
      case 'Aircraft':
        config.width = "92em";
        config.maxWidth = "92em";
        this._dialog.open(AircraftProfileComponent, config);
        break;
      case 'Crew, PAX & Support':
        config.height = "auto";
        config.maxHeight = "80em";
        config.width = "81em";
        this._dialog.open(ManageContactDetailsComponent, config);
        break;
      case 'Admin Services':
        config.height = "max-content";
        config.width = "max-content";
        this._dialog.open(AdminServicesClientComponent, config);
        break;
    }
  }

  openRegisterNewUsers() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3";
    
    config.height = "max-content";
    config.width = "max-content";

    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {
      v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGuid,
      customerName: this.selectedClientName, selectedUserType: this.data.userType
    };
    config.position = { left: "10px", top: "140px" };

    this.clickClose(true);

    this._dialog.open(RegisterUserComponent, config);
  }

  openResetPassword() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3";
    config.height = "45em";
    config.width = "49em";

    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGuid, customerName: this.selectedClientName, userType: this.userType };
    config.position = { left: "10px", top: "140px" };

    this.clickClose(true);

    this._dialog.open(ResetPasswordAdminComponent, config);
  }

  openManageUserPermissions() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container5";
    config.height = "50em";
    config.width = "97em";
    config.maxWidth = "97em";

    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGuid, customerName: this.selectedClientName, userType: this.userType };
    config.position = { left: "10px", top: "140px" };

    this.clickClose(true);

    if (this.userType === 'customer') {
      this._dialog.open(UserPermissionClientComponent, config);
    }
    else if (this.userType === 'internal') {
      config.height = "45em";
      config.width = "60em";
      this._dialog.open(UserPermissionAdminComponent, config);
    }
  }
}
