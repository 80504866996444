import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { ResponseModel } from '../models/response.model';
import { UkGarDirectModel } from '../models/uk-gar-direct.model';
import { UkGarModel } from '../models/uk-gar.model';

@Component({
  selector: 'app-validate-manifest-data',
  templateUrl: './validate-manifest-data.component.html',
  styleUrls: ['./validate-manifest-data.component.css']
})
export class ValidateManifestDataComponent implements OnInit {
  tripCodeGUID: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  serviceTypeID: number;
  canSubmit: boolean;
  garData: UkGarDirectModel[];
  displayedColumn: string[] = ['type','surname', 'given_name', 'dob', 'nationality', 'sex', 'document_type','document_number', 'document_country', 'document_expiration'];

  constructor(private readonly _authService: AuthenticateService, private readonly _groundStopAdvancedService: GroundStopAdvancedService,
    private _dialogRef: MatDialogRef<ValidateManifestDataComponent>, @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _dialog: MatDialog) {
    if (_data) {
      if (_data.tripCodeGUID != undefined) {
        this.tripCodeGUID = _data.tripCodeGUID;
      }
      if (_data.groundStopGUID != undefined) {
        this.groundStopGUID = _data.groundStopGUID;
      }
      if (_data.groundStopTaskGUID != undefined) {
        this.groundStopTaskGUID = _data.groundStopTaskGUID;
      }
      if (_data.serviceTypeID != undefined) {
        this.serviceTypeID = _data.serviceTypeID;
      }
      if (_data.canSubmit != undefined) {
        this.canSubmit = _data.canSubmit;
      }
    }
  }
    ngOnInit() {
      this._authService.updateAccessTime();
      switch (this.serviceTypeID) {
        case 80:
        case 81:
          this.validateGAR();
          break;
      }


    }

  validateGAR() {
    let request = new UkGarModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopGUID = this.groundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    let isDeparture = true;
    if (this.serviceTypeID == 80)
      isDeparture = false;
    request.isDeparture = isDeparture;
    this._groundStopAdvancedService.getDataForUkGarService<ResponseModel<UkGarDirectModel[]>>(request, false).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "")
          this.garData = response.model;
        else {
          if (response.code == "401") {
            this._authService.logout();
          }
        }
      }
    });
  }

  submitGar() {
    this._dialogRef.close(true);
  }
}
