export class CriticalAlertsModel { 
  tripRevisionID: number;
  tripCodeGUID: string;
  customerGUID: string;
  aircraftGUID: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;
  customerName: string;
  tripID: number;
  transactionDateTime: string;
  userName: string;
  tripCode: string;
  registration: string;
  departureIcao: string;
  arrivalIcao: string;
  arrivalDateUTC: string;
  arrivalDateTimeUTC: string;
  departureDateUTC: string;
  departureDateTimeUTC: string;
  modifiedBy: string;
  iata: string;
  serviceTypeDescription: string;
  timeFilter: string; 
}
