export class AirportNotesModel {
  airportID: number;
  airportNotesID: number;
  noteType: string;
  noteTypeID: number;
  airportNote: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: boolean;
  requiresYN: boolean;
  isRequired: number;
  businessRuleCount: number;
  advisoryCount: number;
  revisionDate: string;
  revisionRemarks: string;
  icao: string;
  iata: string;
  daysUntilDue: number;
  leadTime: string;
  expectedApproval: string;
  validTimeStart: string;
  validTimeEnd: string;
}
