import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
//import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';
import { FileModel } from '../models/file.model';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import * as DecoupledEditor from '../ckeditor5/ckeditor';
import { MessageAttachmentModel } from '../models/message-attachment';
import { MessageModel } from '../models/message.model';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { TripModel } from '../models/trip.model';
import { MessageService } from '../services/message.service';
import { IdentityService } from '../services/identity.service';
import { UserModel } from '../models/user.model';
import { IdentityRequestModel } from '../models/identity-request.model';
import { EmailAddressSelectionDialogComponent } from './email-address-selection-dialog.component';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { TaskDocumentSelectionDialogComponent } from './task-document-selection-dialog.component';
import { TripLegDocumentDownloadsDialogComponent } from '../trip-legs/trip-leg-document-downloads-dialog.component';
import { TripDocumentSelectionDialogComponent } from './trip-document-selection-dialog.component';
import { CKEditorConfig } from '../ckeditor5/ckeditor-config';
import { GenerateMessageTemplateComponent } from '../ground-stops/generate-message-template.component';
import { MessageTextModel } from '../models/message-text-model';
import { GroundStopService } from '../services/ground-stop.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageEmbeddedImageModel } from '../models/message-embedded-image';
import { MessageLinkModel } from '../models/message-link';
import plainTextToHtml from '@ckeditor/ckeditor5-clipboard/src/utils/plaintexttohtml';
import { MessageLinkComponent } from './message-link.component';
import { MatTableDataSource } from '@angular/material/table';
import { MessageFolderModel } from '../models/message-folder.model';
import { saveAs } from 'file-saver';
import { ClipboardDetailsDialogComponent } from './clipboard-details-dialog.component';
import { GroundStopModel } from '../models/ground-stop.model';
import { DownloadDocumentsComponent } from './download-documents.component';

export interface LegGroup {
  tripLeg: string;
  taskList: any;
}
export interface TripGroup {
  tripCode: string;
  tripCodeGUID: string;
  aircraftGUID: string;
  custGUID: string;
  legList: LegGroup[];
}
export interface LinkGroup {
  message: string;
  messageGUID: string;
  custGUID: string;
  custName: string;
  tripList: TripGroup[]
  // tripCode: string;
  // tripCodeGUID: string;
  // aircraftGUID: string;
  // legList: LegGroup[];

}
export interface Customer {
  clientName: string;
}
@Component({
  selector: 'app-email-internal-dialog',
  templateUrl: './email-internal-dialog.component.html',
  styleUrls: ['./email-internal-dialog.component.css']
})

export class EmailInternalDialogComponent implements OnInit {
  // ckeditor

  public Editor = DecoupledEditor;

  public CKEConfig = CKEditorConfig.ckeditorConfig;
  editorIsModified: boolean = false;
  isReply: boolean = false;
  lockdownTrip: boolean = false;
  displayMessage: string;
  downloading2: boolean = false;
  methodOfPayment: string = '';
  downloadCount: number = 0;
  totalDocuments: number = 0;

  public onReady(editor) {

    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()

    );

    const clipboardPlugin = editor.plugins.get('ClipboardPipeline');
    const editingView = editor.editing.view;

    editingView.document.on('clipboardInput', (evt, data) => {
      if (editor.isReadOnly) {
        return;
      }
      const dataTransfer = data.dataTransfer;
      let content = plainTextToHtml(dataTransfer.getData('text/plain'));
      content = content.replace(/<p>/gi, '<p class=\"email-body\">')
      data.content = editor.data.htmlProcessor.toView(content);

      editingView.scrollToTheSelection();
    }, { priority: 'high' });


    const imageUploadEditing = editor.plugins.get('ImageUploadEditing');

    imageUploadEditing.on('uploadComplete', (evt, { data, imageElement }) => {
      if (this.embeddedImageList == undefined || this.embeddedImageList == null)
        this.embeddedImageList = [];
      let img = new MessageEmbeddedImageModel();
      img.imageBase64String = data.model[0].bFile;
      img.documentName = data.model[0].fileName;
      img.mimeType = data.model[0].mimeType;
      img.isInline = true;
      img.contentId = data.model[0].fileName;
      this.embeddedImageList.push(img);
    });
    let data = "";
    if (!this.isDraft && this.messageGUID == '')
      data += "<p class=\"email-body\"><br><br></p>" + this.defaultSignature

    data += this.emailBody;
    editor.setData(data);
    this.reloadBtnVisible = data.indexOf('cid:') > -1;
    //editor.focus();
    editor.execute('style', 'Email Body');
    // editor.execute('style', { name: 'Email Body' });
    //editor.execute('fontSize', { value: '14px' });

    const toolbarElement = editor.ui.view.toolbar.element;
    if (this.messageGUID != '' && !this.isDraft) {
      toolbarElement.style.display = 'none';
      editor.enableReadOnlyMode('a');
    }
    else {
      toolbarElement.style.display = 'flex';
      editor.disableReadOnlyMode('a');
    }
    //console.log(editor.getData());

  }


  isDisabled: boolean;
  @ViewChild('emailCKEditor') emailCKEditorRef: any;//ElementRef;
  // ckeditor end
  dialogTitle: string;
  customerGUID: string;
  groundStopGUID: string;
  nextGroundStopGUID: string;
  groundStopTaskGUID: string;
  tripCodeGUID: string;
  serviceTypeID: number;
  aircraftGUID: string;

  email_internal_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  showSpin: boolean = false;
  userType: string;
  showSuccessMsg: boolean = false;
  successMsg: string = "Email sent successfully";
  showSuccessMsg2: boolean = false;
  successMsg2: string = "Draft saved successfully";
  showSuccessCopy: boolean = false;
  successCopy: string = "Text Copied successfully";

  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  loadingNote: boolean = false;
  loadingPreSelectedCommm: boolean = false;

  controlValueChanged: boolean = false;
  isModified: boolean = false;
  webLink: string = "";
  bodyText: string;
  grantAccessToMessages: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  maxTempDocId: number = 0;
  allowedFileType: string;
  uploader: FileUploader;
  fileList: FileModel[];
  finishAllUploads: boolean = true;
  docSize: string;

  allowedFileTypeList: string[];
  maxFileSize: number;
  maxFileUploadNumber: number;
  userName: string;
  emailContent: string;
  emailBody: string = "";
  messageAttachmentList: MessageAttachmentModel[] = [];
  tripCode: string;
  subject: string;
  emailTo: string = "";
  hasDefualtEmailTo: string = "";
  hasDefualtEmailCc: string = "";
  hasDefualtEmails: boolean = false;
  emailCc: string = "";
  wrongEmailToMsg: string = "";
  wrongEmailCcMsg: string = "";
  personName: string;
  personEmail: string;
  clientName: string;
  emailFrom: string;
  senderEmail: string;
  opsEmail: string;
  userSignature: string = "";
  publicNote: string = "";
  taskGroundStopGUID: string;
  defaultSignature: string = "";
  taskDocCount: number = 0;
  isInBound: boolean = false;
  messageGUID: string;
  mainMessageGUID: string;
  messageData: MessageModel;
  isDraft: boolean = false;
  logoBase64String: string = "";
  sentDateTime: string;
  receivedDateTime: string;
  isDelayed: boolean;
  allMessageData: MessageModel[];
  totalMessageAttachmentFileSize: number = 0;
  totalMessageAttachmentFileSizeKB: number = 0;
  totalMessageAttachmentFileSizeMB: number = 0;
  embeddedImageList: MessageEmbeddedImageModel[] = [];
  embeddedImageListAtt: MessageEmbeddedImageModel[] = [];
  messageLinkList: MessageLinkModel[];
  linkGroup: LinkGroup[] = [];
  orgLinkGroup: LinkGroup[] = [];
  displayType: string = "html";
  ckEditorData: string;
  assignedTo: string = "";
  isSpam: boolean = false;
  messageList = new MatTableDataSource<MessageModel>();
  scheduleMessage: string = "";
  isPending: boolean = false;
  messageFolderID: number;
  currentUser: string = localStorage.getItem('un');
  messageFolder: MessageFolderModel[];
  farTypeID: number;
  messageKeyId: string = null;
  autoLinkVisible: boolean = false;
  removeLinkVisible: boolean = true;
  printing: boolean = false;
  showAssigned: boolean = false;
  trip_Code: string = null;
  conversationID: string = "";
  outlookMessageID: string = "";
  canAutolink: boolean = false;
  showDraftBtn: boolean = false;
  arrowDraft: boolean = false;

  textToCopy: string = 'Text to copy';
  copied: boolean = false;
  reloadBtnVisible: boolean = false;
  messagesOpened: number = 0;

  originalPosition: { top: string, left: string } = { top: '0px', left: '0px' };
  currentPosition: { top: string, left: string } = { top: '0px', left: '0px' };
  showCommsInTrip: boolean = true;
  isFirstMessage: boolean = false;
  isLastMessage: boolean = false;
  defaultEmail: string = "";

  isFlagged: boolean = false;
  expandComms: boolean = false;
  emailSent: boolean = false;
  icao: string;
  requiredGsId: string;
  isAmexCard: boolean = false;

  replyToEmail: string;
  replyToName: string;
  senderCustomerName: string;
  attachmentCount: number;
  uploadCount: number;
  groundStopList: GroundStopModel[] = [];
  messageTemplateText: string = "";
  serviceArrangementID: number = 0;
  showAsAutolink: boolean = false;
  vendorGUID: string = "";
  emailAutoGenerated: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<EmailInternalDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _dialog: MatDialog, private readonly _commonService: CommonService, private readonly _groundStopClientService: GroundStopClientService,
    private readonly _dialogService: DialogService, private readonly _messageService: MessageService, private readonly _identityService: IdentityService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _groundStopService: GroundStopService,
    private sanitized: DomSanitizer
  ) {

    this.dialogTitle = _data.dialogTitle;
    this.tripCodeGUID = _data.tripCodeGUID;
    this.messagesOpened = _data.messagesOpened;
    this.lockdownTrip = _data.lockdownTrip;

    this.methodOfPayment = _data.methodOfPayment;
    if (this.methodOfPayment == 'Credit Card Only') {
      this.isAmexCard = true;
    }

    if (this.methodOfPayment == '' || this.methodOfPayment == null) {
      this.isAmexCard = false;
    }


    if (_data.expandComms != null && _data.expandComms != undefined)
      this.expandComms = _data.expandComms;


    this.requiredGsId = "";
    if (_data.requiredGsId != null && _data.requiredGsId != undefined && _data.requiredGsId != "")
      this.requiredGsId = _data.requiredGsId;

    this.groundStopGUID = "";
    if (_data.groundStopGUID != null && _data.groundStopGUID != undefined && _data.groundStopGUID != "")
      this.groundStopGUID = _data.groundStopGUID;
    this.groundStopTaskGUID = "";
    if (_data.groundStopTaskGUID != null && _data.groundStopTaskGUID != undefined && _data.groundStopTaskGUID != "")
      this.groundStopTaskGUID = _data.groundStopTaskGUID;
    this.nextGroundStopGUID = "";
    if (_data.nextGroundStopGUID != null && _data.nextGroundStopGUID != undefined && _data.nextGroundStopGUID != "")
      this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.customerGUID = "";
    if (_data.customerGUID != null && _data.customerGUID != undefined && _data.customerGUID != "")
      this.customerGUID = _data.customerGUID;
    this.serviceTypeID = 0;
    if (_data.serviceTypeID != null && _data.serviceTypeID != undefined && _data.serviceTypeID != "")
      this.serviceTypeID = _data.serviceTypeID;
    this.aircraftGUID = "";
    if (_data.aircraftGUID != null && _data.aircraftGUID != undefined && _data.aircraftGUID != "")
      this.aircraftGUID = _data.aircraftGUID;
    this.taskGroundStopGUID = "";
    if (_data.taskGroundStopGUID != null && _data.taskGroundStopGUID != undefined && _data.taskGroundStopGUID != "")
      this.taskGroundStopGUID = _data.taskGroundStopGUID
    this.messageGUID = "";
    if (_data.messageGUID != null && _data.messageGUID != undefined && _data.messageGUID != "")
      this.messageGUID = _data.messageGUID
    this.isDraft = false;
    if (_data.isDraft != null && _data.isDraft != undefined && _data.isDraft != "")
      this.isDraft = _data.isDraft
    this.allMessageData = [];
    if (_data.allMessageData != null && _data.allMessageData != undefined && _data.allMessageData != "")
      this.allMessageData = _data.allMessageData
    this.messageFolderID = _data.messageFolderID;
    this.messageKeyId = _data.messageKeyId;
    if (_data.farTypeID != null && _data.farTypeID != undefined)
      this.farTypeID = _data.farTypeID;
    this.trip_Code = "";
    if (_data.tripCode != null && _data.tripCode != undefined && _data.tripCode != "")
      this.trip_Code = _data.tripCode;
    // this.showAssigned = "";
    if (_data.showAssigned != null || _data.showAssigned != undefined || _data.showAssigned != "")
      this.showAssigned = _data.showAssigned;
    if (_data.showCommsInTrip != null && _data.showCommsInTrip != undefined)
      this.showCommsInTrip = _data.showCommsInTrip;
    this.icao = "";
    if (_data.icao != null && _data.icao != undefined && _data.icao != "")
      this.icao = _data.icao;
    if(_data.serviceArrangementID != null && _data.serviceArrangementID != undefined && _data.serviceArrangementID != "")
      this.serviceArrangementID = _data.serviceArrangementID
    if(_data.vendorGUID != null && _data.vendorGUID != undefined && _data.vendorGUID != "")
      this.vendorGUID = _data.vendorGUID

    if (this.messageGUID == "" || this.isDraft) {
      this.showAssigned = true;
      this.assignedTo = this.currentUser;
    }

    if (_data.fromTaskEditDaialog) {
      this.clientName = _data.clientName;
      let trip = _data.tripCode;
      let leg = _data.leg;
      let serviceDesc = _data.serviceDesc;
      let servcieStatus = _data.servcieStatus;

      this.linkGroup = [];
      let legList: LegGroup[] = [];
      let taskList: MessageLinkModel[] = [];
      let task: MessageLinkModel = new MessageLinkModel();
      task.aircraftGUID = this.aircraftGUID;
      task.customerGUID = this.customerGUID;
      task.customerName = this.clientName;
      task.groundStopGUID = this.groundStopGUID;
      task.groundStopTaskGUID = this.groundStopTaskGUID;
      task.leg = leg;
      task.serviceTypeDescription = serviceDesc;
      task.taskStatus = servcieStatus;
      task.tripCode = trip;
      task.tripCodeGUID = this.tripCodeGUID;

      taskList.push(task);
      legList.push({ tripLeg: leg, taskList: taskList });
      let tripList: TripGroup[] = [{ tripCode: trip, tripCodeGUID: this.tripCodeGUID, aircraftGUID: this.aircraftGUID, custGUID: this.customerGUID, legList: legList }]
      this.linkGroup.push({ message: "", messageGUID: this.messageGUID, custGUID: this.customerGUID, custName: this.clientName, tripList: tripList });

    }
    if (_data.defaultEmail != null && _data.defaultEmail != undefined)
      this.defaultEmail = _data.defaultEmail;
    // this.fileList = _data.fileList;
    // this.messageAttachmentList = _data.msgAttachments;
  }

  imageUploadComplete(self, errorMessage) {
    self.errMsg = errorMessage;
  }

  getAllData(): Observable<any[]> {
    let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();
    let tripResponse;
    let taskResponse;
    tripResponse = of(null);
    taskResponse = of(null);
    if (this.tripCodeGUID != "" && this.groundStopGUID == "" && this.groundStopTaskGUID == "") {
      let req = new AircraftTripModel();
      req.tripCodeGUID = this.tripCodeGUID;
      req.customerGUID = this.customerGUID;
      tripResponse = this._groundStopClientService.getTripBytId(req);
    }
    else {
      if (this.tripCodeGUID != "" && this.taskGroundStopGUID != "" && this.groundStopTaskGUID != "") {
        let req2 = new MessageModel();
        req2.tripCodeGUID = this.tripCodeGUID;
        //req2.groundStopGUID = this.taskGroundStopGUID;
        req2.groundStopGUID = this.groundStopGUID;
        req2.nextGroundStopGUID = this.nextGroundStopGUID;
        req2.groundStopTaskGUID = this.groundStopTaskGUID;
        /*taskResponse = this._messageService.getGroundStopTaskEmailSubjectByIds(req2);*/
        taskResponse = this._messageService.getGroundStopTaskEmailSubjectForInternalByIds(req2);
      }
      else {
        if (this.tripCodeGUID != "" && this.groundStopGUID != "" && this.nextGroundStopGUID != "" && this.groundStopTaskGUID == "") {
          let req2 = new MessageModel();
          req2.tripCodeGUID = this.tripCodeGUID;
          req2.groundStopGUID = this.groundStopGUID;
          req2.nextGroundStopGUID = this.nextGroundStopGUID;
          taskResponse = this._messageService.getGroundStopEmailSubjectByIds(req2);
        }
      }
    }
    let req3 = new IdentityRequestModel();
    let userResponse = this._identityService.getUser(req3);

    let taskNoteResponse;
    if (this.groundStopTaskGUID != "") {
      let req4 = new GroundStopTaskModel();
      req4.groundStopTaskGUID = this.groundStopTaskGUID;
      //taskNoteResponse = this._groundStopAdvancedService.getGroundStopTaskPublicNoteBygstId(req4);
      taskNoteResponse = this._groundStopAdvancedService.getGroundStopTaskPublicNoteTaskDocCountBygstId(req4);
    }
    else {
      taskNoteResponse = of(null);
    }

    let emailFromResponse = this._messageService.getEmailFromForOps();
    let preCommResponse = of(null);

    if (this.groundStopTaskGUID != "") {
      let reqComm = new GroundStopTaskModel();
      reqComm.groundStopTaskGUID = this.groundStopTaskGUID;
      preCommResponse = this._messageService.getGroundStopTaskDefaultEmailBygstId(reqComm);
    }
    else {
      if (this.tripCodeGUID != "") {
        let reqComm = new TripModel();
        reqComm.tripCodeGUID = this.tripCodeGUID;
        preCommResponse = this._messageService.getTripDefaultEmailBytId(reqComm);
      }
    }
    let messageResponse = of(null);
    if (this.messageGUID != "") {
      messageResponse = this._messageService.getMessageByMessageGUID(this.messageGUID);

    }
    let messageFolderResponse = this._messageService.getMessageFolderList();

    let request2 = new GroundStopModel();
    request2.tripCodeGUID = this.tripCodeGUID;
    request2.customerGUID = this.customerGUID;
    request2.groundStopGUID = this.groundStopGUID;
    request2.hidePastStop = true;
    let groundStopListResponse = this._groundStopService.getGroundStopList<ResponseModel<GroundStopModel[]>>(request2);

    return forkJoin([getAllowedDocTypeResponse, tripResponse, taskResponse, userResponse, taskNoteResponse, emailFromResponse, preCommResponse, messageResponse, messageFolderResponse, groundStopListResponse]);

  }

  getAutoLinkData() {

    this._messageService.getLinkDetailsByConversationID<ResponseModel<MessageLinkModel[]>>(this.conversationID).subscribe(response => {

      if (response.code == "200" && response.message == "" && response.model != null) {

        let trip = "";
        let custGUID = "";
        let custName = "";
        let tripCodeGUID = "";
        let aircraftGUID = "";
        let index = 1;
        this.linkGroup = [];
        this.messageLinkList = response.model;
        response.model.forEach(v => {
          if (this.linkGroup.findIndex(l => l.messageGUID == v.messageGUID) == -1) {
            let legList: LegGroup[] = [];
            let tempList = response.model.map((item) => { if (item.messageGUID == v.messageGUID) return item.leg });
            tempList.forEach(t => {
              if (legList.findIndex(l => l.tripLeg == t) == -1) {
                trip = v.tripCode;
                custGUID = v.customerGUID;
                custName = v.customerName;
                this.clientName = v.customerName;

                tripCodeGUID = v.tripCodeGUID;
                aircraftGUID = v.aircraftGUID;
                if (t != "" && t != null && t != undefined) {
                  legList.push({ tripLeg: t, taskList: response.model.filter(x => x.leg == t && x.messageGUID == v.messageGUID) });
                }
              }
            });
            if (this.linkGroup.length == 0) {
              let tripList: TripGroup[] = [{ tripCode: trip, tripCodeGUID: tripCodeGUID, aircraftGUID: aircraftGUID, custGUID: custGUID, legList: legList }]
              this.linkGroup.push({ message: "" + index, messageGUID: v.messageGUID, custGUID: custGUID, custName: custName, tripList: tripList });
              index++;
            }
          }
        });
        this.orgLinkGroup = this.linkGroup;
      }
    });
    this.autoLinkVisible = true;
    this.removeLinkVisible = false;

  }

  ngOnInit() {
    this.isReply = false;
    if (this.messageGUID != "")
      this.linkGroup = [];
    this.isDisabled = false;
    this._authService.updateAccessTime();
    this.grantAccessToMessages = false;
    if (localStorage.getItem('up.msg') == 'true') {
      this.grantAccessToMessages = true;
    }
    let i = this.allMessageData.findIndex(v => v.messageGUID == this.messageGUID);
    this.isFirstMessage = (i == 0);
    this.isLastMessage = (i + 1 == this.allMessageData.length);

    if (!this.grantAccessToMessages) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this._dialogRef.close(false);
      return;
    }
    this.showSpin = true;
    this.userName = localStorage.getItem('un');
    this.errMsg = "";

    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = true;
    this.showSuccessMsg = false;
    this.showSuccessMsg2 = false;
    this.showSuccessCopy = false;
    this.fileList = [];
    this.allowedFileTypeList = [];
    this.maxFileSize = 0;
    this.maxFileUploadNumber = 0;
    this.tripCode = "";
    // this.subject = "";
    // this.emailContent = '';
    // this.emailTo = "";// GlobalConstant.opsEmail;
    // this.emailFrom = "";// GlobalConstant.opsEmail;
    this.personName = "";
    this.personEmail = "";
    this.clientName = "";
    this.webLink = "";
    this.embeddedImageListAtt = [];
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    this.messageAttachmentList = [];
    this.totalMessageAttachmentFileSize = 0;
    this.uploader = new FileUploader({
      url: GlobalConstant.baseUrl + "Common/uploadFiles",
      method: "post",
      authToken: 'bearer ' + localStorage.getItem('token'),
      disableMultipart: false
    });
    this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    this.uploader.onCompleteAll = () => {
      this.finishAllUploads = true;
      this.uploader.clearQueue();
    };



    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (responses[0].model != null) {
            let fileObj = new FileModel();
            fileObj = responses[0].model;
            this.allowedFileTypeList = fileObj.allowedFileTypeList;
            this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
            this.maxFileSize = fileObj.fileSize;
            this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
          }
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (responses[1].model != null) {
            let t = new TripModel();
            t = responses[1].model;
            //this.subject = "Trip code: " + t.tripCode + ' ' + t.registration;
            this.subject = t.customerName + " / " + t.registration + " / " + t.tripCode;
            if (t.customerTripID != "")
              this.subject += " / " + t.customerTripID;
          }
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          if (responses[2].model != "") {
            this.subject = responses[2].model;
          }
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          if (responses[3].model != null) {
            let user = new UserModel();
            user = responses[3].model;
            this.personName = user.firstName + ' ' + user.lastName;
            //this.personEmail = user.email;
            this.userSignature = user.signatureInCKEditorFormat;
            if (this.userSignature == "") {
              /*this.userSignature += "Best regards,<br>";*/
              this.userSignature += this.personName + "<br>";
              //this.userSignature += "P: " + GlobalConstant.opsPhoneWorldwide + "<br>";
              //this.userSignature += "E: " + GlobalConstant.opsEmail + "<br>";
              //this.userSignature += GlobalConstant.fpiUrl + "<br>";
              //this.userSignature += GlobalConstant.fpiOfficeAddress;
            }
            this.defaultSignature = "<tr><td><table style=\"border:0;\">";
            this.defaultSignature += "<tr>";
            this.defaultSignature += "<td style=\"background-color:#ffffff;border:0;white-space: nowrap;width:12em\" >";
            this.defaultSignature += "<span style='font-family:Tahoma;font-size:11.0pt' >";
            this.defaultSignature += this.userSignature.replace(/<p>/gi, '<p class=\"signature\">');
            this.defaultSignature += "</span>";
            this.defaultSignature += "</td>";
            this.defaultSignature += "<td style=\"background-color:#ffffff;border:0;\">";
            this.defaultSignature += "<img src=\"https://www.flightproconnect.com/assets/images/FlightProLogoWhiteBG.jpg\"  border=0>";
            //this.defaultSignature += "<img src=\"data:image;base64,"+this.logoBase64String+"\"  border=0>";
            this.defaultSignature += "</td>";
            this.defaultSignature += "</tr>";
            this.defaultSignature += "<tr>";
            this.defaultSignature += " <td style=\"background-color:#ffffff;border:0\" colspan =2 \"><span style='font-family:Tahoma;font-size:8.0pt;white-space: nowrap;'>This email and any attachments are confidential.  If you received this email in error, please inform the sender and delete it.  Thank you.</span></td>";
            //this.defaultSignature += " <td style=\"background-color:#ffffff;border:0\"><span class='text-tiny' style='font-family:Tahoma;white-space: nowrap;'>This email and any attachments are confidential.  If you received this email in error, please inform the sender and delete it.  Thank you.</span></td>"; this.defaultSignature += "</tr>";
            this.defaultSignature += "</table>";
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          if (responses[4].model != null) {
            //this.publicNote = responses[4].model;
            let t = new GroundStopTaskModel();
            t = responses[4].model;
            this.publicNote = t.publicNote;
            this.taskDocCount = t.documentCount;
          }
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          if (responses[5].model != "") {
            this.emailFrom = responses[5].model;
            this.opsEmail = this.emailFrom;
          }
        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[6] != null) {
        if (responses[6].code == "200" && responses[6].message == "") {
          if (responses[6].model != "") {
            if (this.groundStopTaskGUID != "") {
              let task = new GroundStopTaskModel();
              task = responses[6].model;
              this.emailTo = task.defaultEmailTo;
              this.hasDefualtEmailTo = task.defaultEmailTo;
              this.emailCc = task.defaultEmailCc;
              this.hasDefualtEmailCc = task.defaultEmailCc;
              if ((task.defaultEmailTo != "" && task.defaultEmailTo != undefined) || (task.defaultEmailCc != "" && task.defaultEmailCc != undefined)) {
                this.hasDefualtEmails = true;
              }
            }
            else {
              if (this.tripCodeGUID != "") {
                let trip = new TripModel();
                trip = responses[6].model;
                this.emailTo = trip.defaultEmailTo;
                this.emailCc = trip.defaultEmailCc;
              }
            }
          }
        }
        else {
          if (responses[6].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[7] != null) {
        if (responses[7].code == "200" && responses[7].message == "") {
          if (responses[7].model != "") {
            this.messageData = responses[7].model;
            this.subject = this.messageData.subject;
            this.emailTo = this.messageData.sendTo;
            this.emailCc = this.messageData.sendCC;
            this.assignedTo = this.messageData.assignedTo;
            this.isSpam = this.messageData.isSpam;
            this.isDraft = this.messageData.isDraft;
            this.sentDateTime = this.messageData.sentDateTime;
            this.receivedDateTime = this.messageData.receivedDateTime;
            this.isDelayed = this.messageData.isDelayed;
            this.messageKeyId = this.messageData.messageKeyID;
            this.outlookMessageID = this.messageData.outlookMessageID;
            this.conversationID = this.messageData.conversationID;
            if (this.messageData.isInBound == false && this.messageData.bodyHtml != null)
              this.emailContent = this.messageData.bodyHtml;
            else
              this.emailContent = this.messageData.body;

            this.emailBody = this.emailContent;
            this.bodyText = this.messageData.bodyTxt;
            this.replyToEmail = this.messageData.replyToEmail;
            this.replyToName = this.messageData.replyToName;
            this.senderCustomerName = this.messageData.customerName;

            let sender;
            if (this.messageData.sender?.toLowerCase() == 'no-reply-fpc@flightprointl.com' && this.replyToEmail != null) {
              sender = this.replyToEmail;
              if (this.replyToName != '')
                sender = this.messageData.replyToName + "<" + this.messageData.replyToEmail + ">";
            }
            else {
              sender = this.messageData.sender;
              if (this.messageData.senderName != '')
                sender = this.messageData.senderName + "<" + this.messageData.sender + ">";
            }
            this.emailFrom = sender;
            this.senderEmail = this.messageData.sender;
            if (this.messageData.messageAttachmentList != null) {
              this.messageAttachmentList = this.messageData.messageAttachmentList;
              this.totalDocuments += this.messageAttachmentList.length;
            }
            this.tripCode = this.messageData.tripCodeGUID;
            this.scheduleMessage = this.messageData.scheduleMessage;
            this.isPending = this.messageData.isPending;
            this.isActive = this.messageData.isActive;

            this.isFlagged = this.messageData.isFlagged;

            this.attachmentCount = this.messageData.attachmentCount;
            this.uploadCount = this.messageData.uploadCount;

            //this.messageFolderID = this.messageData.messageFolderID;
            //mani
            //this.clientName = this.messageData.customerGUID;
            this.canAutolink = this.messageData.canAutolink;
            if (this.outlookMessageID != null && this.outlookMessageID != "" && this.outlookMessageID != undefined) {
              this.webLink = "https://outlook.office365.com/mail/" + this.opsEmail + "/deeplink/read/" + this.outlookMessageID;
            }
            this.embeddedImageList = this.messageData.messageEmbeddedImageList;
            if (this.embeddedImageList != null) {
              this.embeddedImageList.forEach(x => {
                if (x.containerName != null && x.containerName != '') {
                  this.emailBody = this.emailBody.replace("cid:" + x.contentId + '"', "data:image;base64," + x.imageBase64String + '" style="" title="' + x.documentName + '"');
                }
              });
              this.embeddedImageListAtt = this.embeddedImageList.filter(x => x.containerName != null && x.containerName != '');
              this.attachmentCount += this.embeddedImageListAtt.length;
              this.uploadCount += this.embeddedImageListAtt.length;
              
              this.totalDocuments += this.embeddedImageListAtt.length;
            }
            if ((this.messageFolderID == 1 || this.messageFolderID == 4) && this.canAutolink) {

            }
            else
              this.linkGroup = [];

            this.messageLinkList = this.messageData.messageLinkList;
            this.showAsAutolink = this.messageLinkList?.some(v => v.showAsAutolink);
            if ((this.messageLinkList != null) && (this.messageFolderID != 1 || this.showAsAutolink) ) {
              if (this.messageLinkList.length == 1) {
                this.groundStopGUID = this.messageLinkList[0].groundStopGUID;
                this.taskGroundStopGUID = this.groundStopGUID;
                this.groundStopTaskGUID = this.messageLinkList[0].groundStopTaskGUID;
              }

              this.tripCodeGUID = this.messageLinkList[0]?.tripCodeGUID;
              this.customerGUID = this.messageLinkList[0]?.customerGUID;
              this.aircraftGUID = this.messageLinkList[0]?.aircraftGUID;
              this.linkGroup = [];

              this.messageLinkList.forEach(v => {
                this.clientName = v.customerName;
                let client: LinkGroup = {
                  message: '',
                  messageGUID: v.messageGUID,
                  custGUID: v.customerGUID,
                  custName: v.customerName,
                  tripList: null
                }

                let tripdetails = function (msgLinkList: MessageLinkModel[]): TripGroup[] {
                  let triplist: TripGroup[] = [];
                  msgLinkList.forEach(t => {
                    let tripdetail: TripGroup = {
                      tripCode: t.tripCode,
                      tripCodeGUID: t.tripCodeGUID,
                      aircraftGUID: t.aircraftGUID,
                      custGUID: t.customerGUID,
                      legList: null
                    };

                    let legdetails = function (msgLinkList: MessageLinkModel[]): LegGroup[] {
                      let leglist: LegGroup[] = [];
                      msgLinkList.forEach(l => {
                        if (l.leg != "" && l.leg != null && leglist.findIndex(l2 => l2.tripLeg == l.leg) == -1) {
                          leglist.push({ tripLeg: l.leg, taskList: msgLinkList.filter(x => x.leg == l.leg) });
                        }
                      });
                      return leglist;
                    }

                    if (triplist.findIndex(l => l.tripCodeGUID == t.tripCodeGUID) == -1) {
                      tripdetail.legList = legdetails(msgLinkList.filter(l => l.tripCodeGUID == t.tripCodeGUID));
                      triplist.push(tripdetail);
                    }
                  });

                  return triplist;
                }

                if (this.linkGroup.findIndex(l => l.custGUID == v.customerGUID) == -1) {
                  client.tripList = tripdetails(this.messageLinkList.filter(c => c.customerGUID == v.customerGUID));
                  this.linkGroup.push(client);
                }
                if (this.canAutolink)
                  this.autoLinkVisible = true;

              });

            }
          }
        }
        else {
          if (responses[7].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[8] != null) {
        if (responses[8].code == "200" && responses[8].message == "") {
          if (responses[8].model != "") {
            this.messageFolder = responses[8].model;
          }
        }
        else {
          if (responses[8].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[9] != null) {
        if (responses[9].code == "200" && responses[9].message == "") {
          if (responses[9].model != "") {
            this.groundStopList = responses[9].model;
          }
        }
        else {
          if (responses[9].code == "401") {
            this._authService.signOut();
          }
        }
      }


      if (this.canAutolink && (this.messageFolderID == 1 || this.messageFolderID == 4) && this.conversationID!=null) {
        this.getAutoLinkData();
      }
      if (this.defaultEmail != "")
        this.emailTo = this.defaultEmail;

      this.initControls();
      this.email_internal_form.valueChanges.subscribe(() => {
        if (this.email_internal_form.dirty || this.editorIsModified)
          this.showDraftBtn = true;
      });
      if (this.emailCKEditorRef != undefined) {
        let editor = this.emailCKEditorRef.editorInstance;
        if (editor) {
          let data = "";
          if (!this.isDraft && this.messageGUID == '')
            data += "<p class=\"email-body\"><br><br></p>" + this.defaultSignature
          data += this.emailBody;
          this.setEditorData(data);

        }
      }
      if (this._data.servcieStatus === 'Waiting for Action') {
        this.autoGenerateTemplate();
      }
      this.showSpin = false;
    })
  }

  initControls() {
    this.email_internal_form = this._formBuilder.group({
      /*emailTo: [this.emailTo, Validators.required],*/
      emailTo: [this.emailTo],
      emailCc: [this.emailCc],
      subject: [this.subject, Validators.required],
      // emailCKEditor: [this.emailContent],
      document: [null],

    });
  }

  get f() { return this.email_internal_form.controls; }

  // upload file
  dropFile(event) {
    let msg: string = this.emailCKEditorRef.editorInstance.getData();
    this.finishAllUploads = false;
    this.fileList = [];
    this.errMsg = "";

    if ((event.length + this.messageAttachmentList.length) > this.maxFileUploadNumber) {
      if (this.errMsg != "") {
        this.errMsg += "\n";
      }
      /* this.errMsg += 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum + '.';*/
      this.errMsg += 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;
        setTimeout(() => this.removeFromUploaderQueue(filename), 100);
      }
    }
    else {
      for (var i = 0; i < event.length; i++) {
        let filename = event[i].name;

        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (!a) {
          if (this.errMsg != "") {
            this.errMsg += "\n";
          }
          this.errMsg += 'The file type of "' + filename + '" is not allowed to upload.';
          setTimeout(() => this.removeFromUploaderQueue(filename), 100);
        }
        else {
          //if (event[i].size >= this.maxFileSize) {
          //  if (this.errMsg != "") {
          //    this.errMsg += "\n";
          //  }
          //  this.errMsg += 'The file size "' + filename + '" is too big to upload.';
          //  setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          //}
          if (event[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {
            if (this.errMsg != "") {
              this.errMsg += "\n";
            }
            this.errMsg += 'Total file sizes are over limit';
            setTimeout(() => this.removeFromUploaderQueue(filename), 100);
          }
        }
      }
    }


    if (this.errMsg != "") {
      this.finishAllUploads = true;
      return;
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
    setTimeout(() => {
      this.setMessageBody(msg),
        1000;
    });
  }

  removeFromUploaderQueue(filename: string) {
    var index = -1;
    for (let i = 0; i < this.uploader.queue.length; i++) {
      let fileItem = this.uploader.queue[i]._file;
      if (filename == fileItem.name) {
        index = i;
        break;
      }
    }

    if (index >= 0) {
      this.uploader.queue[index].remove();
    }

  }

  importFile(event: any) {
    let msg: string = this.emailCKEditorRef.editorInstance.getData();
    if ((this.messageAttachmentList.length + event.target.files.length) > this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
      this.finishAllUploads = true;
      return;
    }
    this.finishAllUploads = false;

    var files = [];
    this.errMsg = ""
    if (event.target.files.length > this.maxFileUploadNumber) { //GlobalConstant.maxUploadFileNum) {     
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      this.finishAllUploads = true;
      return;
    }
    else {
      for (var i = 0; i < event.target.files.length; i++) {
        let filename = event.target.files[i].name;
        var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
        if (a) {
          //if (event.target.files[i].size >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {
          //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
          //  this.finishAllUploads = true;
          //  return;
          //}
          //else {
          //  files.push(event.target.files[i]);

          //}
          if (event.target.files[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {             
            this.errMsg = 'Total file sizes are over limit.';
            this.finishAllUploads = true;
            return;
          }
          else {
            files.push(event.target.files[i]);

          }
        }
        else {
          this.finishAllUploads = true;
          this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
          return;
        }
      }
    }

    if (this.errMsg != "") {
      this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
      return;
    }

    if (files.length > 0) {
      this.uploader.addToQueue(files);
    }

    if (this.uploader.queue.length == 0) {
      this.errMsg = "No file selected";
      this.finishAllUploads = true;
      return;
    }

    this.uploadSubmit();
    setTimeout(() => {
      this.setMessageBody(msg),
        1000;
    });
  }

  uploadSubmit() {
    this.finishAllUploads = false;
    this.uploader.uploadAll();
  }

  onProgressItem(fileItem: FileItem, progress: any) {
    //console.log(progress);
  };

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let result = JSON.parse(response); //success server response
    if (result != null) {
      if (result.code == "200") {
        if (result.model.length > 0) {
          result.model.forEach((item, index) => {
            let obj = new FileModel();
            this.maxTempDocId += 1;
            obj.fileName = item.fileName;
            obj.filePath = item.filePath;
            obj.fileSize = item.fileSize;
            obj.bFile = item.bFile;
            obj.mimeType = item.mimeType;
            obj.fileType = item.fileType;
            obj.tempId = this.maxTempDocId;

            this.fileList.push(obj);

            let obj2 = new MessageAttachmentModel();
            obj2.documentName = item.fileName;
            obj2.fileExtension = item.fileType;
            obj2.documentSizeInKB = item.fileSize;
            obj2.mimeType = item.mimeType;
            obj2.bFile = item.bFile;
            obj2.tempId = this.maxTempDocId;
            obj2.messageAttachmentGUID = "";
            obj2.groundStopTaskDocumentGUID = "";
            obj2.documentGUID = "";
            this.totalMessageAttachmentFileSize += item.fileSize * 1024;
            this.totalMessageAttachmentFileSizeKB = this.totalMessageAttachmentFileSize / 1024;
            this.totalMessageAttachmentFileSizeMB = Math.round((this.totalMessageAttachmentFileSizeKB / 1024 + Number.EPSILON) * 100) / 100;
            this.messageAttachmentList.push(obj2);
          });
        }
      }
      else {
        this.errMsg = result.message;
      }


    }

  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let error = JSON.parse(response); //error server response
  }

  getMessageAttachment(messageAttachmentGUID: string, isDownload: boolean) {
    this._messageService.getMessageAttachmentsByMessageAttachmentGUID<ResponseModel<MessageAttachmentModel>>(messageAttachmentGUID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let att = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(att.bFile);
          let file = new Blob([byteArray], { type: att.mimeType });
          if (att.mimeType == "application/octet-stream" || isDownload) {
            
            // await new Promise(resolve => resolve(saveAs(file, att.documentName))); // Pause for 2.5 seconds
               
                saveAs(file, att.documentName);
             this.downloadCount++;
          }
          else {
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }

  getEmbeddedImage(messageEmbeddedImageGUID: string, isDownload: boolean) {
    this._messageService.getMessageEmbeddedImageByMessageEmbeddedImageGUID<ResponseModel<MessageEmbeddedImageModel>>(messageEmbeddedImageGUID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let att = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(att.imageBase64String);
          let file = new Blob([byteArray], { type: att.mimeType });
          if (att.mimeType == "application/octet-stream" || isDownload) {
            saveAs(file, att.documentName);
            this.downloadCount++;
          }
          else {
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
  }

  clickPreviewDocument(e: any, item: any, isEmbedded: boolean) {

    if (isEmbedded) {
      this.getEmbeddedImage(item.messageEmbeddedImageGUID, false);
    }
    else {
      if (item.messageAttachmentGUID == "") {
        let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
        let file = new Blob([byteArray], { type: item.mimeType });
        if (item.mimeType == "application/octet-stream") {
          saveAs(file, item.documentName);
        }
        else {
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }

      }
      else {
        this.getMessageAttachment(item.messageAttachmentGUID, false);
      }
    }

  }
  downloadAttachment(item: any, isEmbedded: boolean) {
    if (isEmbedded) {
      this.getEmbeddedImage(item.messageEmbeddedImageGUID, true);
    }
    else {
      this.getMessageAttachment(item.messageAttachmentGUID, true);
    }
  }

  downloadAllAttachments() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "18em";
    config.width = "26em";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), embeddedImageListAtt: this.embeddedImageListAtt, messageAttachmentList: this.messageAttachmentList, };
    this._dialog.open(DownloadDocumentsComponent, config);    
  }


   async  downloadAllAttachments_old() {

    this.downloadCount = 0;
    // this.messageAttachmentList.forEach((att) => {
    //   // setTimeout(() => {

    //     this.downloadAttachment(att, false);
    //   // }, 2500);
    // });

    // this.embeddedImageListAtt.forEach(att => {
    //   // setTimeout(() => {
    //     this.downloadAttachment(att, true);
    //   // }, 2500);
    // });

    for (const att of this.messageAttachmentList) {
      await this.downloadAttachment(att, false); // Wait for each download to complete
      // await new Promise(resolve => resolve(this.downloadAttachment(att, false))); // Pause for 2.5 seconds
      await new Promise(resolve => setTimeout(resolve, 1000)); // Pause for 2.5 seconds


    }

    // Download embedded image attachments
    for (const att of this.embeddedImageListAtt) {
      await this.downloadAttachment(att, true); // Wait for each download to complete
      // await new Promise(resolve => resolve(this.downloadAttachment(att, true))); // Pause for 2.5 seconds
      await new Promise(resolve => setTimeout(resolve, 1000)); // Pause for 2.5 seconds

    }


  }



  // downloadAttachment(item: MessageAttachmentModel) {
  //   let byteArray = UtilityFunctions.base64ToArrayBuffer(item.bFile);
  //   let file = new Blob([byteArray], { type: item.mimeType });
  //   saveAs(file, item.documentName);
  // }

  // downloadAllAttachments() {
  //   this.messageAttachmentList.forEach((att) => {
  //     setTimeout(() => {
  //       this.downloadAttachment(att);

  //     }, 2000);
  //   });
  // }



  clickRemoveDocument(e: any, item: MessageAttachmentModel) {
    this.errMsg = "";
    //let msg: string = this.emailCKEditorRef.editorInstance.getData();
    //this.confirmDialogRef = this._dialogService.openConfirmDialog("Are you sure you want remove the document: " + item.documentName + "?");
    //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
    //  this.eventSubscription.unsubscribe();
    //  if (result) {
    //    this.isModified = true;
    //    this.fileList.forEach((x, index) => {
    //      if (x.tempId == item.tempId) {
    //        this.fileList.splice(index, 1);
    //      }
    //    });
    //    this.messageAttachmentList.forEach(
    //      (x, index) => {
    //        if (x.tempId == item.tempId && x.messageAttachmentGUID == "") {
    //          this.messageAttachmentList.splice(index, 1);
    //          this.totalMessageAttachmentFileSize -= item.fileSize * 1024;
    //        }
    //      }
    //    )
    //  }
    //  this.setMessageBody(msg);
    //})

    this.isModified = true;
    if (item.messageAttachmentGUID != "") {
      // remove doc from database, get newlist and then add unsaved file list
      let request = new MessageAttachmentModel();
      request.messageAttachmentGUID = item.messageAttachmentGUID;
      this._messageService.removeMessageAttachmentBymaID<ResponseModel<boolean>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model) {
            this.messageAttachmentList.forEach(
              (x, index) => {
                if (x.messageAttachmentGUID == item.messageAttachmentGUID) {
                  this.messageAttachmentList.splice(index, 1);
                  this.totalMessageAttachmentFileSize -= item.fileSize * 1024;
                  this.totalMessageAttachmentFileSizeKB = this.totalMessageAttachmentFileSize / 1024;
                  this.totalMessageAttachmentFileSizeMB = Math.round((this.totalMessageAttachmentFileSizeKB / 1024 + Number.EPSILON) * 100) / 100;
                }
              }
            )
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }

      })
    }
    else {
      this.fileList.forEach((x, index) => {
        if (x.tempId == item.tempId) {
          this.fileList.splice(index, 1);
        }
      });

      this.messageAttachmentList.forEach(
        (x, index) => {
          if (x.tempId == item.tempId && x.messageAttachmentGUID == "") {
            this.messageAttachmentList.splice(index, 1);
            this.totalMessageAttachmentFileSize -= item.fileSize * 1024;
            this.totalMessageAttachmentFileSizeKB = this.totalMessageAttachmentFileSize / 1024;
            this.totalMessageAttachmentFileSizeMB = Math.round((this.totalMessageAttachmentFileSizeKB / 1024 + Number.EPSILON) * 100) / 100;
          }
        }
      );
    }
    //this.setMessageBody(msg);
  }

  onFileClick(event) {
    this.errMsg = "";
    event.target.value = '';
  }
  // upload file end

  emailToKeydown(e: any) {
    this.wrongEmailToMsg = "";
  }

  emailCcKeydown(e: any) {
    this.wrongEmailCcMsg = "";
  }

  clickTaskDocSelection() {
    this.errMsg = "";
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, groundStopTaskGUID: this.groundStopTaskGUID, serviceTypeID: this.serviceTypeID, attachFileList: this.messageAttachmentList,
      totalFileSize: this.totalMessageAttachmentFileSize, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TaskDocumentSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.messageAttachmentList = result.msgAttachementList;
        this.setMessageAttachmentTotalSize();
      }

    });

  }

  clickTripLegDocSelection() {
    this.errMsg = "";
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    /*dialogConfig.disableClose = true;*/

    //let leg: string = "";

    //leg = item.departureICAO;
    //if (item.nextArrivalICAO != "") {
    //  leg += "-" + item.nextArrivalICAO;
    //}

    dialogConfig.data = {
      groundStopGUID: this.groundStopGUID, nextGroundStopGUID: this.nextGroundStopGUID, tripCodeGUID: this.tripCodeGUID, customerGUID: this.customerGUID,
      aircraftGUID: this.aircraftGUID, pageFrom: 'email', attachFileList: this.messageAttachmentList, totalFileSize: this.totalMessageAttachmentFileSize, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripLegDocumentDownloadsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        this.messageAttachmentList = result.msgAttachementList;
        this.setMessageAttachmentTotalSize();
        //this.getTripLegBydgIdagId(item.groundStopGUID, item.nextArrivalGroundStopGUID);
      }
    });

  }

  clickTripDocSelection() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    /*dialogConfig.disableClose = true;*/

    //let leg: string = "";

    //leg = item.departureICAO;
    //if (item.nextArrivalICAO != "") {
    //  leg += "-" + item.nextArrivalICAO;
    //}

    dialogConfig.data = {
      tripCodeGUID: this.tripCodeGUID, customerGUID: this.customerGUID,
      aircraftGUID: this.aircraftGUID, pageFrom: 'email', attachFileList: this.messageAttachmentList, totalFileSize: this.totalMessageAttachmentFileSize, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripDocumentSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        this.messageAttachmentList = result.msgAttachementList;
        this.setMessageAttachmentTotalSize();
        //this.getTripLegBydgIdagId(item.groundStopGUID, item.nextArrivalGroundStopGUID);
      }
    });

  }

  setMessageAttachmentTotalSize() {
    this.totalMessageAttachmentFileSize = 0;
    this.messageAttachmentList.forEach(x => {
      if (x.documentGUID == "" && x.groundStopTaskDocumentGUID == "")
        this.totalMessageAttachmentFileSize += x.documentSizeInKB * 1024;
      else {
        this.totalMessageAttachmentFileSize += x.fileSize * 1024;
      }
    });
    this.totalMessageAttachmentFileSizeKB = this.totalMessageAttachmentFileSize / 1024;
    this.totalMessageAttachmentFileSizeMB = Math.round((this.totalMessageAttachmentFileSizeKB / 1024 + Number.EPSILON) * 100) / 100;
  }

  clickEmailSelection() {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: this.tripCodeGUID, groundStopGUID: this.taskGroundStopGUID, groundStopTaskGUID: this.groundStopTaskGUID, nextGroundStopGUID: this.nextGroundStopGUID,
      serviceTypeID: this.serviceTypeID, openFrom: 'email', showCommsInTrip: this.showCommsInTrip, expandComms: this.expandComms, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(EmailAddressSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        //let msg: string = this.emailCKEditorRef.editorInstance.getData();
        let t: string = "";
        t = this.getUniqueEmailAddess(this.f.emailTo.value, result.to);
        this.f.emailTo.setValue(t);
        let c: string = "";
        c = this.getUniqueEmailAddess(this.f.emailCc.value, result.cc);
        this.f.emailCc.setValue(c);
        // this.setMessageBody(msg);
      }

    });
  }

  getUniqueEmailAddess(controlValue: string, addedValue: string) {
    let returnValue: string = "";
    let s: string = controlValue;
    if (s != "" && s != null) {
      s = s.trim();
      let re = /,/gi;
      s = s.replace(re, ";")
      let re1 = / /gi;
      s = s.replace(re1, ";")
      returnValue = s;
      if (addedValue != "") {
        let s1 = addedValue.split(';');
        for (var i = 0; i < s1.length; i++) {
          s1[i] = s1[i].trim();
          if (s1[i] != "") {
            if (s.toLowerCase().indexOf(s1[i].toLowerCase()) == -1) {
              if (returnValue != "") {
                returnValue += ";";
              }
              returnValue += s1[i];
            }
          }
        }
      }

    }
    else {
      returnValue = addedValue;
    }
    return returnValue;
  }

  validateEmailAddress() {
    let s: string = "";
    if (this.f.emailTo.value != '') {
      let re = /,/gi;
      s = this.f.emailTo.value.trim();
      s = s.replace(re, ";")
      let re1 = / /gi;
      s = s.replace(re1, ";")
      let s1 = s.split(';');
      let s2 = "";
      for (var i = 0; i < s1.length; i++) {
        s1[i] = s1[i].trim();
        if (s1[i] != "") {
          let r = CustomValidators.validateCommonAddress(s1[i], "email");
          if (r == false) {
            this.wrongEmailToMsg = "Invalid email: " + s1[i];
            this.f.emailTo.setErrors({ validateEmail: false });
            return;
          }
          else {
            if (s2 != "") {
              s2 += ";";
            }
            s2 += s1[i];
          }
        }
      }

      this.f.emailTo.setValue(s2);
    }
    let sc: string = "";
    if (this.f.emailCc.value != '') {
      let rec = /,/gi;
      sc = this.f.emailCc.value.trim();
      sc = sc.replace(rec, ";")
      let re1c = / /gi;
      sc = sc.replace(re1c, ";")
      let s1c = sc.split(';');
      let s2c = "";
      for (var i = 0; i < s1c.length; i++) {
        s1c[i] = s1c[i].trim();
        if (s1c[i] != "") {
          let rc = CustomValidators.validateCommonAddress(s1c[i], "email");
          if (rc == false) {
            this.wrongEmailCcMsg = "Invalid email: " + s1c[i];
            this.f.emailCc.setErrors({ validateEmail: false });
            return;
          }
          else {
            if (s2c != "") {
              s2c += ";";
            }
            s2c += s1c[i];
          }
        }
      }

      this.f.emailCc.setValue(s2c);
    }

  }

  clickLoadPreSelectedEmail() {
    this._authService.updateAccessTime();

    if (this.loadingPreSelectedCommm) {
      return;
    }
    this.loadingPreSelectedCommm = true;

    if (this.groundStopTaskGUID != "") {
      let request = new GroundStopTaskModel();
      request.groundStopTaskGUID = this.groundStopTaskGUID;

      this._messageService.getGroundStopTaskDefaultEmailBygstId<ResponseModel<GroundStopTaskModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          let task = new GroundStopTaskModel();
          task = response.model;
          let t: string = "";
          t = this.getUniqueEmailAddess(this.f.emailTo.value, task.defaultEmailTo);
          this.f.emailTo.setValue(t);
          let c: string = "";
          c = this.getUniqueEmailAddess(this.f.emailCc.value, task.defaultEmailCc);
          this.f.emailCc.setValue(c);

        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        this.loadingPreSelectedCommm = false;
      })
    }
    else {
      let request = new TripModel();
      request.tripCodeGUID = this.tripCodeGUID;

      this._messageService.getTripDefaultEmailBytId<ResponseModel<TripModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          let trip = new TripModel();
          trip = response.model;
          let t: string = "";
          t = this.getUniqueEmailAddess(this.f.emailTo.value, trip.defaultEmailTo);
          this.f.emailTo.setValue(t);
          let c: string = "";
          c = this.getUniqueEmailAddess(this.f.emailCc.value, trip.defaultEmailCc);
          this.f.emailCc.setValue(c);

        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        this.loadingPreSelectedCommm = false;
      })
    }

  }

  clickInsertPublicNote(e: any) {
    this._authService.updateAccessTime();
    if (this.publicNote != "") {
      let s: string = this.emailCKEditorRef.editorInstance.getData();
      s = s.replace("<br><br><br><br><br><br><br><br><br>", "");
      /*this.f.emailCKEditor.setValue("<pre><span class='text-small' style=\"font-family:'Courier New'\">" + this.publicNote + "</span></pre>" + s);*/
      //this.f.emailCKEditor.setValue(this.publicNote.replace(/\n/g, "<br>")+ s);     
      this.emailCKEditorRef.editorInstance.setData("<p class='email-body'>" + this.publicNote.replace(/\n/g, "<br>") + "</p>" + s);

    }

  }

  clickSend() {
    this.errMsg = "";
    this._authService.updateAccessTime();

    if (this.f.emailTo.value == '' && this.f.emailCc.value == '') {
      this.errMsg = "You must enter either To or Cc field";
      return;
    }

    if (this.messageAttachmentList.length > this.maxFileUploadNumber) {
      this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.'; //GlobalConstant.maxUploadFileNum + '.';
      return;
    }

    if (this.totalMessageAttachmentFileSize >= this.maxFileSize) {
      this.errMsg = "The total file size over the limit. Please remove some of documents.";
      return;
    }

    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    this.validateEmailAddress();

    if (this.email_internal_form.invalid || this.errMsg != "") {
      return;
    }

    this.loading = true;
    let request = new MessageModel();
    request.messageGUID = this.messageGUID;
    request.mainMessageGUID = this.mainMessageGUID;
    request.subject = this.f.subject.value;
    request.isInBound = false;
    request.sendTo = this.f.emailTo.value;
    request.sendCC = this.f.emailCc.value;
    request.body = this.emailCKEditorRef.editorInstance.getData();
    request.bodyTxt = this.emailCKEditorRef.elementRef.nativeElement.innerText;
    request.bodyTxt = this.formatEmailBody(request);
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopGUID = this.taskGroundStopGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    request.serviceTypeID = this.serviceTypeID;
    request.nextGroundStopGUID = this.nextGroundStopGUID;
    request.messageAttachmentList = this.messageAttachmentList;
    request.messageEmbeddedImageList = this.embeddedImageList;
    request.messageKeyID = this.messageKeyId;
    request.conversationID = this.conversationID;
    request.outlookMessageID = this.outlookMessageID;
    // request.assignedTo = this.currentUser;
    //request.customerGUID = this.customerGUID;
    //if (this.fileList.length > 0) {
    //  this.fileList.forEach(x => {
    //    let obj = new MessageAttachmentModel();
    //    obj.documentName = x.fileName;
    //    obj.fileExtension = x.fileType;
    //    obj.documentSizeInKB = x.fileSize;
    //    obj.mimeType = x.mimeType;
    //    obj.bFile = x.bFile;
    //    obj.messageAttachmentGUID = "";
    //    request.messageAttachmentList.push(obj); 
    //  });
    //}
    request.customerGUID = this.customerGUID;
    request.isDraft = false;
    request.messageLinkList = this.messageLinkList;
    this._messageService.sendEmailWithAttachmentsForInternal<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let m = new MessageModel();
          m = response.model;
          if (m.messageGUID != "") {
            this.isModified = true;
            //this.returnObj.refresh = this.isModified;
            //this.returnObj.newId = t.tripCodeGUID.toLowerCase();
            //this.returnObj.aircraftGUID = obj.aircraftGUID;
            //this.returnObj.customerGUID = obj.customerGUID;

            this.messageGUID = m.messageGUID;
            if (this.isReply && this.canAutolink) {
              this.autoLink(this.messageLinkList[0].messageGUID);
            }

            if (m.emailSent == true) {
              if (m.errMsg != "") {
                if (this.messageGUID == null)
                  this.messageGUID = "";
                this.emailSent = m.emailSent;
                this.errMsg = "Email was sent but an error occurred while saving to the database.";
              }
              else {
                this.showSuccessMsg = true;
                setTimeout(() => {
                  this.showSuccessMsg = false;
                  this.clickClose(true);
                }, 1000);
              }
            }
            else {
              if (m.errMsg != "") {
                this.errMsg = m.errMsg;
              }
              else
                this.errMsg = "Failed to send email at this time. Please try later.";
            }
          }
        }
        else {
          this.errMsg = "Failed to send email at this time. Please try later.";

        }
      }
      else {
        if (response.message != "") {
          this.errMsg = response.message;
        }
        else {
          if (response.message != "") {
            this.errMsg = response.message;
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
            }
            else {
              this.errMsg = "Failed to send email at this time. Please try later.";
            }
          }
        }
        //if (response.code == "401") {
        //  if (response.message != "") {
        //    this.errMsg = response.message;
        //  }
        //  else {
        //    this._authService.signOut();
        //  }
        //}
        //else {
        //  this.errMsg = "Failed to send email at this time. Please try later.";
        //}
      }
      this.loading = false;
      //console.log(this.loading);

    });
    this.clickClose(true);
  }


  // if ((this.email_internal_form.dirty || this.editorIsModified) && this.arrowDraft){
  // if (this.email_internal_form.dirty || this.editorIsModified){

  clickClose(isSent?: boolean) {
    let response: object = {isModified: this.isModified, emailAutoGenerated: this.emailAutoGenerated}
    if (!isSent) {
      if ((this.email_internal_form.dirty || this.editorIsModified) && !this.arrowDraft && !this.emailSent) {
        this.showDraftAlert();
        this.showDraftBtn = true;
      }
      else
        this._dialogRef.close(response);
    }
    else
      this._dialogRef.close(response);
  }

  autoGenerateTemplate() {
    let taskServiceTypeIDs = [1, 3, 5, 26, 55];
    if(!taskServiceTypeIDs.includes(this.serviceTypeID))
      return;

    let request = new MessageTextModel();
    request.messageTemplateID = -1;
    request.groundStopGuid = this.nextGroundStopGUID;
    request.previousGroundStopGuid = this.groundStopGUID;
    request.groundStopTaskId = this.groundStopTaskGUID;
    request.tripCodeGuid = this.tripCodeGUID;
    request.serviceTypeID = this.serviceTypeID;
    request.groundStopGuidList = this.nextGroundStopGUID;
    request.serviceArrangementID = this.serviceArrangementID;
    request.vendorGuid = this.vendorGUID;

    this.loading = true;
    this._groundStopService.getVendorEmailsByTaskAndGroundStop<ResponseModel<MessageTextModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let message = new MessageTextModel();
          message = response.model;
          this.f.emailTo.setValue(message.messageTo);
          if(message.messageCc != null)
            this.f.emailCc.setValue(message.messageCc);
        }
      }
      else {
        this.errMsg = "Template not found."
        this.loading = false;
      }
    });

    if(this._data.servcieStatus === 'Waiting for Action') {
      this._groundStopService.generateMessageTemplate<ResponseModel<MessageTextModel>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let message = new MessageTextModel();
            message = response.model;
            this.loading = false;
            this.emailAutoGenerated = true;
          
            let s: string = this.emailCKEditorRef.editorInstance.getData();
            s = s.replace("<p class=\"email-body\"><br><br></p>", "");
            s = s.replace("<p class=\"email-body\"><br><br>&nbsp;</p>", "");
            s = s.replace("<br><br><br><br><br><br><br><br><br>", "");

            if(message.messageTemplateText !== null) {
              this.emailCKEditorRef.editorInstance.setData("<pre><p class='email-body'>" + message.messageTemplateText.replace(/\n/g, "<br>") + "</p></pre>" + s);
              this.f.subject.setValue(message.subjectLine);
            }
          }
        }
        else {
          this.errMsg = "Template not found."
          this.loading = false;
        }
      });
    }
    else {
      this.loading=false;
    }
  }

  clickTemplate() {
    // if (this.serviceTypeID == 45 || this.serviceTypeID == 49 || this.serviceTypeID == 44 || this.serviceTypeID == 71 || this.serviceTypeID == 20 || this.serviceTypeID == 10)
    let taskServiceTypeIDs = [45, 49, 44, 71, 20, 54, 10];

    this.downloading2 = true;
    if (taskServiceTypeIDs.includes(this.serviceTypeID)) {
      let request = new MessageTextModel();
      request.groundStopGuid = this.groundStopGUID;
      request.groundStopTaskId = this.groundStopTaskGUID;
      request.tripCodeGuid = this.tripCodeGUID;
      switch (this.serviceTypeID) {
        case 44:
          request.messageTemplateID = 22;
          break;
        case 45:
          request.messageTemplateID = 3;
          break;
        case 49:
          request.messageTemplateID = 16;
          break;
        case 71:
          request.messageTemplateID = 8;
          break;
        case 20:
          request.messageTemplateID = 20;
          break;
        case 54:
          request.messageTemplateID = 33;
          break;
        case 10:
          request.messageTemplateID = 35;
          break;

      }

      this._groundStopService.generateMessageTemplate<ResponseModel<MessageTextModel>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            let message = new MessageTextModel();
            message = response.model;
            if (message.messageTemplateText == null || message.messageTemplateText == '') {
              this.errMsg = "Template not found."
              this.downloading2 = false;
              setTimeout(() => {
                this.errMsg = '';
              }, 2000);

            }
            let s: string = this.emailCKEditorRef.editorInstance.getData();
            s = s.replace("<p class=\"email-body\"><br><br></p>", "");
            s = s.replace("<p class=\"email-body\"><br><br>&nbsp;</p>", "");
            s = s.replace("<br><br><br><br><br><br><br><br><br>", "");
            //this.f.emailCKEditor.setValue(message.messageTemplateText.replace(/\n/g, "<br>") + s);

            this.emailCKEditorRef.editorInstance.setData("<pre><p class='email-body'>" + message.messageTemplateText.replace(/\n/g, "<br>") + "</p></pre>" + s);
            this.f.subject.setValue(message.subjectLine);

            this.downloading2 = false;

          }
        }
        else {
          this.errMsg = "Template not found."
          this.downloading2 = false;
          setTimeout(() => {
            this.errMsg = '';
          }, 2000);
        }
      });
    }
    else {
      const config = new MatDialogConfig();
      // config.panelClass = "custom-dialog-container";
      // config.height = "37.5em";
      config.disableClose = true;
      this.downloading2 = false;

      config.restoreFocus = false;
      config.hasBackdrop = false;
      config.width = "45em";
      config.data = {
        gstId: this.groundStopTaskGUID, gsId: this.groundStopGUID, requiredGsId: this.requiredGsId, fromEmail: true,
        tripCodeId: this.tripCodeGUID, serviceTypeId: this.serviceTypeID, customerGUID: this.customerGUID, isAmexCard: this.isAmexCard,
        v: this._authService.getCurrentTimeNumber(), pf: 'message', farTypeID: this.farTypeID, icao: this.icao
      };
      this._dialog.open(GenerateMessageTemplateComponent, config).afterClosed().subscribe(x => {
        if (x != '') {
          this.subject = x.subjectLine;
          this.f.subject.setValue(this.subject);
          let s: string = this.emailCKEditorRef.editorInstance.getData();
          s = s.replace("<p class=\"email-body\"><br><br></p>", "");
          s = s.replace("<p class=\"email-body\"><br><br>&nbsp;</p>", "");
          s = s.replace("<br><br><br><br><br><br><br><br><br>", "");
          /*this.f.emailCKEditor.setValue("<pre><span class='text-small' style=\"font-family:'Courier New'\">" + this.publicNote + "</span></pre>" + s);*/
          //this.f.emailCKEditor.setValue(x.messageTemplateText.replace(/\n/g, "<br>") + s);
          //this.ckEditorData = x.messageTemplateText.replace(/\n/g, "<br>") + s;

          let content = x.messageTemplateText.replace(/<p>/gi, '<p class=\"email-body\">');

          this.emailCKEditorRef.editorInstance.setData("<pre><p class='email-body'>" + content.replace(/\n/g, "<br>") + "</p></pre>" + s);

          // this.emailCKEditorRef.editorInstance.setData("<pre><p class='email-body'>" + x.messageTemplateText.replace(/\n/g, "<br>") + "</p></pre>" + s);
        }
      });
    }
  }

  setMessageBody(body: string) {
    //this.f.emailCKEditor.setValue('');
    //this.f.emailCKEditor.setValue(body);
  }


  replyMessage(replyType: string) {
    this.isReply = true;
    this.errMsg = "";
    this.uploadCount = null;
    this.attachmentCount = null;
    this.arrowDraft = false;
    let request = new MessageModel();
    request.messageType = replyType;
    request.outlookMessageID = this.outlookMessageID;
    request.conversationID = this.conversationID;
    request.messageKeyID = this.messageKeyId;
    this.messageData.messageGUID = "";
    // if (this.assignedTo == '')
    this.assignedTo = this.currentUser;
    this.messageData.messageType = replyType;
    this.embeddedImageListAtt = [];
    this.showSpin = true;

    this._messageService.createMessage<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {

          this.mainMessageGUID = this.messageGUID;
          this.messageGUID = '';
          if (response.model.outlookMessageID == null || response.model.errMsg != "") {
            let reply = "<hr><div  style=\"font-size:11.0pt\"><strong>From:</strong>&nbsp;" + this.emailFrom + "<br>";
            reply += "<strong>Sent:</strong>&nbsp;" + this.sentDateTime + "<br>";
            reply += "<strong>To:</strong>&nbsp;" + this.emailTo + "<br>";
            if (this.emailCc != null)
              reply += "<strong>Cc:</strong>&nbsp;" + this.emailCc + "<br>";
            reply += "<strong>Subject:</strong>&nbsp;" + this.subject + "<br></div>";
            this.outlookMessageID = null;
            this.conversationID = null;
            this.emailBody = reply + "<span>" + this.emailContent + "</span>";
            let data = "<p class=\"email-body\"><br><br></p>" + this.defaultSignature
            data += this.emailBody;
            this.setEditorData(data);
            //this.f.ckEditor = this.emailContent;
            // this.setMessageBody(this.emailContent);
            let to = this.emailTo.replace(this.opsEmail, "");

            this.emailFrom = this.opsEmail;
            switch (replyType) {
              case "reply":
                this.emailTo = this.replyToEmail != null ? this.replyToEmail : this.senderEmail;
                this.emailCc = "";
                this.messageAttachmentList = [];
                break;
              case "replyall":
                this.emailTo = this.replyToEmail != null ? this.replyToEmail : this.senderEmail;
                if (to != "") {
                  this.emailTo += ";" + to;
                }
                if (this.emailCc != null)
                  this.emailCc = this.emailCc.replace(this.emailFrom, "");
                this.messageAttachmentList = [];
                break;
              case "forward":
                this.emailTo = "";
                this.emailCc = "";

            }
            this.emailTo = this.emailTo.replace(";;", ";");
            if (replyType == "forward")
              this.subject = "FW: " + this.subject;
            else
              this.subject = "RE: " + this.subject;
            if (this.messageLinkList != null && this.messageLinkList != undefined && this.messageLinkList.length > 0) {
              if (this.messageLinkList[0].customerTripID != null && this.messageLinkList[0].customerTripID != '') {
                if (this.subject.indexOf(this.messageLinkList[0].customerTripID) == -1)
                  this.subject += " / " + this.messageLinkList[0].customerTripID;
              }
            }

          }
          else {



            this.emailBody = response.model.body.replace(/\r\n/gi, "<br>");
            if (this.embeddedImageList != null) {
              this.embeddedImageList.forEach(x => {
                this.emailBody = this.emailBody.replace("cid:" + x.contentId, "data:image;base64," + x.imageBase64String)
              });
            }
            //this.embeddedImageList = response.model.messageEmbeddedImageList;
            this.emailTo = response.model.sendTo;
            this.emailFrom = response.model.from;
            this.subject = response.model.subject;
            this.emailCc = response.model.sendCC;
            this.outlookMessageID = response.model.outlookMessageID;
            this.conversationID = response.model.conversationID;
            this.messageAttachmentList = [];
            if (response.model.messageAttachmentList != null) {
              response.model.messageAttachmentList.forEach(item => {
                let obj = new FileModel();
                this.maxTempDocId += 1;
                obj.fileName = item.documentName;
                obj.filePath = "";
                obj.fileSize = item.documentSizeInKB;
                obj.bFile = item.bFile;
                obj.mimeType = item.mimeType;
                obj.fileType = item.fileExtension;
                obj.tempId = this.maxTempDocId;
                if (this.messageLinkList != null && this.messageLinkList != undefined && this.messageLinkList.length > 0) {
                  if (this.messageLinkList[0].customerTripID != null && this.messageLinkList[0].customerTripID != '') {
                    if (this.subject.indexOf(this.messageLinkList[0].customerTripID) == -1)
                      this.subject += " / " + this.messageLinkList[0].customerTripID;
                  }
                }

                this.fileList.push(obj);

                let obj2 = new MessageAttachmentModel();
                obj2.documentName = item.documentName;
                obj2.fileExtension = item.fileExtension;
                obj2.documentSizeInKB = item.documentSizeInKB;
                obj2.mimeType = item.mimeType;
                obj2.bFile = item.bFile;
                obj2.tempId = this.maxTempDocId;
                obj2.messageAttachmentGUID = "";
                obj2.groundStopTaskDocumentGUID = "";
                obj2.documentGUID = "";
                obj2.outlookMessageAttachmentId = item.outlookMessageAttachmentId;

                this.totalMessageAttachmentFileSize += item.documentSizeInKB * 1024;
                this.totalMessageAttachmentFileSizeKB = this.totalMessageAttachmentFileSize / 1024;
                this.totalMessageAttachmentFileSizeMB = Math.round((this.totalMessageAttachmentFileSizeKB / 1024 + Number.EPSILON) * 100) / 100;
                this.messageAttachmentList.push(obj2);

              });

            }

            let data = "<p class=\"email-body\"><br><br></p>" + this.defaultSignature
            data += this.emailBody;
            this.setEditorData(data);
          }

          this.initControls();
          this.showSpin = false;

        }
      }
      else {
        if (response.code == "401") {

        }
        else {
          this.errMsg = "There was an error creating the " + replyType + " message.";
        }
      }
    })


  }

  showPrevious() {
    document.getElementsByTagName('ckeditor')[0].children[1].scrollTop = 0
    // let i = this.allMessageData.findIndex(v => v.messageGUID == this.messageGUID);
    let msgGUIDs = this.allMessageData.map(m => m.messageGUID);
    let uniqueMsgs = [...new Set(msgGUIDs)];
    let i = uniqueMsgs.findIndex(m => m == this.messageGUID);
    this.messageGUID = uniqueMsgs[i - 1];
    this.arrowDraft = true;
    this.autoLinkVisible = false;
    this.taskGroundStopGUID = "";
    this.ngOnInit();
    this._messageService.setMessageGUID(this.messageGUID);

  }

  showNext() {
    document.getElementsByTagName('ckeditor')[0].children[1].scrollTop = 0;
    // let i = this.allMessageData.findIndex(v => v.messageGUID == this.messageGUID);
    let msgGUIDs = this.allMessageData.map(m => m.messageGUID);
    let uniqueMsgs = [...new Set(msgGUIDs)];
    let i = uniqueMsgs.findIndex(m => m == this.messageGUID);
    this.messageGUID = uniqueMsgs[i + 1];
    this.arrowDraft = true;
    this.autoLinkVisible = false;
    this.taskGroundStopGUID = "";
    this.ngOnInit();
    this._messageService.setMessageGUID(this.messageGUID);

  }


  getHTML(data: any) {
    return this.sanitized.bypassSecurityTrustHtml(data);
  }


  markSpam() {
    let request = new MessageModel();
    request.messageGUID = this.messageGUID;
    request.isSpam = !this.isSpam
    this._messageService.updateMessageSpam<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.isSpam = !this.isSpam
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();

          }
        }
      }

    });
  }

  deleteMessage(isDelete: boolean) {
    this.removelinkMessage();
    let request = new MessageModel();
    request.messageGUID = this.messageGUID;
    request.outlookMessageID = this.outlookMessageID;
    request.isActive = isDelete;
    this._messageService.deleteMessage<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this._dialogRef.close(true);
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();

          }
        }
      }

    });
  }


  linkMessage() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "61em";//"1000px";
    dialogConfig.height = "38em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", messageGUID: this.messageGUID, v: this._authService.getCurrentTimeNumber(),
      allMessageData: this.messageList.data
    };

    const dialogRef = this._dialog.open(MessageLinkComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.isModified = true;
        this.clickClose();
      }

    });
  }


  removelinkMessage() {
    this._messageService.removeMessageLink<ResponseModel<boolean>>(this.messageGUID).subscribe(response => {
      if (response != null && response.message == "" && response.code == "200") {
        if (this.messageFolderID == 1) {
          this.autoLinkVisible = true;
          this.linkGroup = this.orgLinkGroup;
        }
        else {
          this.linkGroup = [];
          this.assignMe(true);
        }

        this.clientName = '';
        this.messageLinkList = [];
        this.isModified = true;

      }
      else if (response.code == "401")
        this._authService.signOut();

    });
  }

  clickOpenTrip(tripCodeGUID, custGUID, aircraftGUID,legList) {
    //this._router.navigate(['/ground-stops/trip-details', { pId: p.personGUID }]);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "tripId": tripCodeGUID,
        "customerId": custGUID,
        "aircraftId": aircraftGUID,
        "pf": "",
        v: this._authService.getCurrentTimeNumber()
      }
    };
    //this._router.navigate(['/ground-stops/trip-details'], navigationExtras);
    //this._router.navigate(['/ground-stops/trip-details-internal'], navigationExtras);
    let route = '/ground-stops/trip-details-internal?customerId=' + custGUID + "&tripId=" + tripCodeGUID + "&aircraftId=" + aircraftGUID 
    if (legList != null && legList != undefined) {
      if (legList.length == 1 && legList[0].taskList?.length == 1)
        route += "&gstId=" + legList[0].taskList[0].groundStopTaskGUID
    }
    
    window.open(route, '_blank');
  }


  //assignMessage(message: MessageModel, isAssign: boolean) {
  //  let request = new MessageModel();
  //  message.loading = true;
  //  request.messageGUID = message.messageGUID;
  //  if (isAssign)
  //    request.assignedTo = this.currentUser;
  //  else
  //    request.assignedTo = "";
  //  this._messageService.updateMessageAssignedTo<ResponseModel<MessageModel>>(request).subscribe(response => {
  //    if (response != null) {
  //      if (response.message == "" && response.code == "200") {
  //        this.assignedTo = this.currentUser;
  //      }
  //    }
  //  });
  //}

  assignMe(isAssign: boolean) {
    let request = new MessageModel();
    request.messageGUIDList = this.messageGUID;
    if (isAssign)
      request.assignedTo = this.currentUser;
    else
      request.assignedTo = "";
    this._messageService.updateMessageAssignedTo<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.assignedTo = request.assignedTo;
          this.isModified = true;
        }

      }
    });
  }


  moveFolder(folderID: number) {
    let request = new MessageModel();
    request.messageGUIDList = this.messageGUID;
    request.messageFolderID = folderID;
    this._messageService.updateMessageFolder<ResponseModel<MessageModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.message == "" && response.code == "200") {
          this.isModified = true;
        }

      }
    });
  }

  autoLink(sourceMessageGUID) {
      this._messageService.insertMessageLinkWithAutoLink<ResponseModel<boolean>>(this.conversationID, this.messageGUID, sourceMessageGUID, this.showAsAutolink).subscribe(response => {

        if (response != null && response.message == "" && response.code == "200") {
          if (response.model) {
            this.assignMe(true);
            this.isModified = true;
            this.successMsg = "Link details auto linked";
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
              this.clickClose(true);
            }, 1000);
            this.autoLinkVisible = false;
            this.linkGroup = this.orgLinkGroup.filter(lg => lg.messageGUID == sourceMessageGUID);
          }
        }
      });
    
  }

  onPrintClick() {
    this.printing = true;

    setTimeout(() => {
      const emailHead = document.getElementById("emailHead").innerHTML;
      let editor = this.emailCKEditorRef.editorInstance;
      const emailBody = editor.data.get(); //document.getElementById("emailCKEditor").innerHTML;
      const printContent = emailHead + emailBody;
      const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
      WindowPrt.document.write(printContent);
      WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();
      WindowPrt.close();
      this.printing = false;
    }, 50);
  }

  setEditorData(data: any) {
    let editor = this.emailCKEditorRef.editorInstance;
    editor.setData(data);
    //editor.focus();
    editor.execute('style', 'Email Body');
    // editor.execute('style', { name: 'Email Body' });
    //editor.execute('fontSize', { value: '14px' });

    const toolbarElement = editor.ui.view.toolbar.element;
    if (this.messageGUID != '' && !this.isDraft) {
      toolbarElement.style.display = 'none';
      editor.enableReadOnlyMode('a');
    }
    else {
      toolbarElement.style.display = 'flex';
      editor.disableReadOnlyMode('a');
    }

  }


  // showDraftAlert2(){
  //   let s = "";
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.autoFocus = true;
  //   dialogConfig.width = "20em";//"1000px";
  //   dialogConfig.height = "10em";//"500px";
  //   dialogConfig.disableClose = true;

  //   dialogConfig.data = {
  //     dialogTitle: s,   v: this._authService.getCurrentTimeNumber()
  //   };


  //   const dialogRef = this._dialog.open(SaveDraftDialogComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe((result:boolean) => {
  //     if (result) {
  //       this.saveMessageDraft();
  //       this._dialogRef.close(this.isModified);
  //     }
  //     else
  //       this._dialogRef.close(this.isModified);
  //   });
  // }

  showDraftAlert() {
    this.confirmDialogRef = this._dialogService.openConfirmDialog([' Save As Draft? ']);
    this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        //user clicked yes
        this.saveMessageDraft(true);
      }
      else {
        //user clicked no

        this._dialogRef.close(this.isModified);
      }
    });
  }

  onEditorChange(event) {
    this.editorIsModified = true;
    this.showDraftBtn = true;
  }

  saveMessageDraft(fromCloseBtn: boolean) {
    let message: MessageModel = new MessageModel();
    message.messageGUID = this.messageGUID;
    message.mainMessageGUID = this.mainMessageGUID;
    message.messageFolderID = 6;
    message.sendTo = this.f.emailTo.value;
    message.sendCC = this.f.emailCc.value;
    message.subject = this.f.subject.value;
    message.isDraft = true;
    message.assignedTo = this.currentUser;
    message.body = this.emailCKEditorRef.editorInstance.getData();
    message.bodyTxt = this.emailCKEditorRef.elementRef.nativeElement.innerText;
    message.bodyTxt = this.formatEmailBody(message);
    message.tripCodeGUID = this.tripCodeGUID;
    message.groundStopGUID = this.taskGroundStopGUID;
    message.groundStopTaskGUID = this.groundStopTaskGUID;
    message.serviceTypeID = this.serviceTypeID;
    message.nextGroundStopGUID = this.nextGroundStopGUID;
    // message.messageAttachmentList = this.messageAttachmentList;
    message.messageEmbeddedImageList = this.embeddedImageList;
    message.customerGUID = this.customerGUID;
    message.outlookMessageID = this.outlookMessageID;
    message.conversationID = this.conversationID;
    message.messageAttachmentList = this.messageAttachmentList;

    // if (this.fileList.length > 0) {
    //   this.fileList.forEach(x => {
    //     let obj = new MessageAttachmentModel();
    //     obj.documentName = x.fileName;
    //     obj.fileExtension = x.fileType;
    //     obj.documentSizeInKB = x.fileSize;
    //     obj.mimeType = x.mimeType;
    //     obj.bFile = x.bFile;
    //     obj.groundStopTaskDocumentGUID = "";
    //     message.messageAttachmentList.push(obj);
    //   });
    // }
    this._messageService.saveMessage<ResponseModel<string>>(message).subscribe(response => {
      if (response != null && response.message == "" && response.code == "200") {

        if (fromCloseBtn) {
          this.isModified = true;
          this.successMsg2 = "Draft saved successfully";
          this.showSuccessMsg2 = true;
          setTimeout(() => {
            this.showSuccessMsg2 = false;
            this._dialogRef.close(this.isModified);

          }, 1000);
        }
      }
    });
  }

  formatEmailBody(message: MessageModel): string {
    if (message.bodyTxt != "") {
      let re = /Paragraph\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Styles\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Email Body\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Signature\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Heading 1\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Heading 2\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Heading 3\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
      re = /Choose heading\n/gi;
      message.bodyTxt = message.bodyTxt.replace(re, '');
      message.bodyTxt = message.bodyTxt.trim();
    }
    return message.bodyTxt;
  }


  copyToClipboard() {
    const textField = document.createElement('textarea');
    textField.value = this.messageGUID;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    this.copied = true;
    this.showSuccessCopy = true;

    // Optional: Reset the "copied" state after a certain time
    setTimeout(() => {
      this.copied = false;
      this.showSuccessCopy = false;

    }, 2000); // Reset after 2 seconds
  }

  reloadEmbeddedImage() {
    if (this.embeddedImageList != null) {
      this.embeddedImageList.forEach(x => {
        this.emailBody = this.emailBody.replace("cid:" + x.contentId, "data:image;base64," + x.imageBase64String)
      });
    }

    let editor = this.emailCKEditorRef.editorInstance;
    if (editor) {
      let data = "";
      if (!this.isDraft && this.messageGUID == '')
        data += "<p class=\"email-body\"><br><br></p>" + this.defaultSignature
      data += this.emailBody;
      this.setEditorData(data);
      this.updateBodyHtml();
    }
    this.arrowDraft = true;
  }

  updateBodyHtml() {
    let message: MessageModel = new MessageModel();
    message.messageGUID = this.messageGUID;

    message.body = this.emailCKEditorRef.editorInstance.getData();
    message.bodyTxt = this.emailCKEditorRef.elementRef.nativeElement.innerText;
    message.bodyTxt = this.formatEmailBody(message);

    this._messageService.updateMessageBody<ResponseModel<string>>(message).subscribe(response => {
      if (response != null && response.message == "" && response.code == "200") {

        this.isModified = true;
        this.successMsg2 = "Images Loaded successfully";

        this.showSuccessMsg2 = true;
        setTimeout(() => {
          this.showSuccessMsg2 = false;

        }, 1000);

      }
    });
  }


  isMinimized: boolean = false;
  conditionValue: number = 3;

  minimizeDialog() {
    this.isMinimized = true;
    if (this.messageGUID == "")
      this.displayMessage = 'New Message';

    else if (this.subject == "")
      this.displayMessage = ' Message';
    else
      this.displayMessage = (this.subject.length > 0 && this.subject.length > 14) ? this.subject.substr(0, 14) : this.subject

    //this.displayMessage = this.messageGUID == ""? 'New Message': ((this.subject.length>0 && this.subject.length>14 )? this.subject.substr(0,14) : this.subject )

    if (document.getElementById(this.messageGUID).parentElement.closest('.cdk-overlay-pane') != undefined &&
      document.getElementById(this.messageGUID).parentElement.closest('.cdk-overlay-pane') != null)
      (document.getElementById(this.messageGUID).parentElement.closest('.cdk-overlay-pane') as HTMLElement).style.transform = 'none'
  }

  maximizeDialog() {
    this.isMinimized = false;
  }

  updatelinkMessage(element) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "61em";//"1000px";
    dialogConfig.height = "38em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", messageGUID: this.messageGUID, v: this._authService.getCurrentTimeNumber(),
      allMessageData: this.messageList.data, messageLinkList: this.messageLinkList, linkDetailsList: this.linkGroup, currentUser: this.currentUser
    };

    const dialogRef = this._dialog.open(MessageLinkComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.isModified = true;
        this.clickClose();
      }

    });
  }

  updateDisplay() {
    this.setEditorData(this.bodyText);
  }

  FlagMessage() {
    if (this.isFlagged == false) {
      this.isFlagged = true;
    }
    else {
      this.isFlagged = false;
    }
    this.updateFlag();

  }

  updateFlag() {
    let message: MessageModel = new MessageModel();
    message.isFlagged = this.isFlagged;
    message.messageGUID = this.messageGUID;


    // this._messageService.updateMessageBody<ResponseModel<string>>(message).subscribe(response => {

    this._messageService.updateMessageFlag<ResponseModel<boolean>>(message).subscribe(response => {
      if (response != null && response.message == "" && response.code == "200") {

        this.isModified = true;
        if (response.model)
          this.successMsg2 = this.isFlagged == true ? "Message Flagged Succesfully" : "Message UnFlagged Succesfully";
        else this.successMsg2 = "failed";
        this.showSuccessMsg2 = true;

        setTimeout(() => {
          this.showSuccessMsg2 = false;

        }, 1000);

      }
    });
  }



  openClipboard() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "34em";//"1000px";
    // dialogConfig.height = "23em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = {
      dialogTitle: s, tripCodeGUID: "", messageGUID: this.messageGUID, v: this._authService.getCurrentTimeNumber(),
      allMessageData: this.messageList.data, messageLinkList: this.messageLinkList
    };

    const dialogRef = this._dialog.open(ClipboardDetailsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.isModified = true;
        this.clickClose();
      }

    });
  }
}
