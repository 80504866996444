
export class AirportDocumentModel {
  airportID: number;
  airportDocumentGUID: string;
  documentName: string;
  documentDescription: string;
  fileExtension: string;
  fileSize: number;
  containerPath: string;
  containerName: string;
  bFile: string;
  mimeType: string;
  effectiveDate: string;
  expirationDate: string;
  doesNotExpire: boolean; 
  documentTypeGUIDList: string;
  documentTypeList: string;
  modifiedBy: string;
  modifiedDate: string;
  isActive: boolean;
  showToClient: boolean;
  daysUntilDue: number;
  icao: string; 
  iata: string;
  isExpired: boolean;
}
