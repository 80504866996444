import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticateService } from '../services/authenticate.service'
import { CustomerModel } from '../models/customer.model';
import { Observable, forkJoin, Subscription } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { CommonService } from '../services/common.service';
import { ResponseModel } from '../models/response.model';
import { CustomerService } from '../services/customer.service';
import { CustomerRegulatoryServiceModel } from '../models/customer-regulatory-service.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CustomerRegulatoryServicesEditDialogComponent } from './customer-regulatory-service-edit-dialog.component';
import { CustomerProfileMainEditDialogComponent } from './customer-profile-main-edit-dialog.component';
import { CustomerProfileSalesEditDialogComponent } from './customer-profile-sales-edit-dialog.component';
import { CustomerNoteModel } from '../models/customer-note.model';
import { CustomerNotesEditDialogComponent } from './customer-notes-edit-dialog.component';
import { CustomerHomebaseEditDialogComponent } from './customer-homebase-edit-dialog.component';
import { CustomerHomebaseAirportModel } from '../models/customer-homebase-airport.model';
import { CustomerHaltServicesEditDialogComponent } from './customer-halt-services-edit-dialog.component';
import { BillingStatusModel } from '../models/billing-status.model';
import { RequiresBackupInvoicesModel } from '../models/requires-backup-invoices.model';
import { CustomerAccountingProfileModel } from '../models/customer-accounting-profile.model';
import { CustomerAccountingProfileEditDialogComponent } from './customer-accounting-profile-edit-dialog.component';
import { DialogService } from '../services/dialog.service';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { CustomerNoteComponent } from './customer-note.component';
import { CustomerHaltServicesComponent } from './customer-halt-services.component';
import { CustomerAccountingProfileComponent } from './customer-accounting-profile.component';
import { CustomerProfileSetupComponent2 } from './customer-profile-setup2.component';
import { CustomerInformationDialogComponent } from './customer-information-dialog.component';
import { AircraftProfileComponent } from '../aircraft/aircraft-profile.component';
import { AdminServicesClientComponent } from '../user-accounts/admin-services-client.component';
import { ManageContactDetailsComponent } from '../persons/manage-contact-details.component';

@Component({
  selector: 'app-customer-profile-setup-edit',
  templateUrl: './customer-profile-setup-edit.component.html',
  styleUrls: ['./customer-profile-setup-edit.component.css']
})

export class CustomerProfileSetupEditComponent implements OnInit, AfterViewInit {
  customer_profile_setup_edit_form: UntypedFormGroup;
  @Input() getModifiedBy: string;
  @Input() getModifiedDate: string;
  loading = false;
  submitted = false;
  userType: string;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  customerType: string;
  industryType: string;
  creditStatus: string;
  thirdPartyScreening: string;
  marketingRegion: string;
  customerGuid: string;
  selectedTabName: string;
  request = new CustomerRegulatoryServiceModel();
  govList: CustomerRegulatoryServiceModel[];
  noteList: CustomerNoteModel[];
  homebaseList: CustomerHomebaseAirportModel[];
  billingStatusList: BillingStatusModel[];
  accountingList: CustomerAccountingProfileModel[];
  requiresBackupInvoices: RequiresBackupInvoicesModel[];
  tabName: string;
  modifiedBy: string;
  modifiedDate: string;
  customerName: string;
  legalEntityName: string;
  dbaName: string;
  creditCheck: string;
  acctCreationDate: string;
  isModified: boolean = false;
  stock_symbol: string;
  totalRecords: number = 0;
  pageSize: number = 10;
  acctPageSize: number = 5;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  isActive: boolean;
  includeDisabledRecords: boolean;
  apisCarrierCode: string;
  showApisReportFirst: boolean;
  excludeCCOptions: boolean;
  
  selectedBillingStatusId: number;
  selectedRequiresBackupInvoicesId: number;
  referencePO: string;
  accountId: string;
  acctType: string;
  accountID_msg: string = "";
  accountID_good: string = "";
  displayedColumnGov: string[] = ['govService', 'waiver_or_registration', 'effectiveDate', 'expirationDate', 'processedBy', 'renewalStatus',
    'filingRequired', 'remarks', 'disabled', 'action'];
  displayedColumnNotes: string[] = ['noteType', 'notes', 'disabled', 'action'];
  displayedColumnHomebase: string[] = ['icao', 'iata', 'airport_name', 'airport_location', 'action'];
  displayedColumnAccounting: string[] = ['bill_to_id', 'bill_to_account','registration', 'service', 'crew', 'pax',
    'remarks', 'disabled', 'action'];
  childHasUpdates: boolean = false;
  isPopup: boolean = false;
  selectedClientName: string = "";
  @ViewChild('customerNote') customerNoteRef: CustomerNoteComponent;
  @ViewChild('haltServices') haltServicesRef: CustomerHaltServicesComponent;
  @ViewChild('accountingProfile') accountingProfileRef: CustomerAccountingProfileComponent;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService, private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog, private readonly _customerService: CustomerService, private readonly _dialogService: DialogService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private readonly _dialogRef: MatDialogRef<CustomerProfileSetupEditComponent>) {
      if(this.data) {
        this.isPopup = true;
      }
  }

  getModifiedByHandler(getModifiedBy: string) {
    
    this.f.modifiedBy.setValue(getModifiedBy);
  }
  getModifiedDateHandler(getModifiedDate: string) {
    
    this.f.modifiedDate.setValue(getModifiedDate);
  }

  updateParentPageHandler(e: any) {

    this.childHasUpdates = e;
  }


  getAllData(): Observable<any[]> {

    
    let getCustomerResponse = this._customerService.getCustomerBycId<ResponseModel<CustomerModel>>(this.customerGuid);
    let getBillingStatusListResponse = this._commonService.getBillingStatusList<ResponseModel<BillingStatusModel>>();
    let getRequiresBackupInvoicesResponse = this._commonService.getRequiresBackupInvoicesList<ResponseModel<RequiresBackupInvoicesModel>>();
    return forkJoin([getBillingStatusListResponse, getRequiresBackupInvoicesResponse, getCustomerResponse]);

  }

  ngOnInit() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.tabName = this._route.snapshot.paramMap.get('tabName');
    //this.customerGuid = this._route.snapshot.paramMap.get('customerGuid');
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }



    this.customerName = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.customerGuid = this._route.snapshot.paramMap.get('customerGuid');
    if(this.data) {
      this.customerGuid = this.data.customerGUID;
      this.selectedClientName = this.data.customerName;
    }
    else if (this.customerGuid == null || this.customerGuid == "" || this.customerGuid == undefined) {
      this._route.queryParams.subscribe(params => {
        this.customerGuid = params['customerGuid'];
        this.selectedClientName = params['customerName']
      });
    }
    this.selectedTabName = 'regulatory';
    this.isActive = true;
    this.acctType = "billTo";

    this.initControls();
    this.loadPageData();
    
    this.showSpin = false;
  }


  loadPageData() {
    this.showSpin = true;
    this.errMsg = "";
    this.getAllData().subscribe(responses => {
     

      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          if (responses[0].model.length > 0) {
            this.billingStatusList = responses[0].model;
          }
          else {

          }
        }

      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          if (responses[1].model.length > 0) {
            this.requiresBackupInvoices = responses[1].model;
          }
          else {

          }
        }

      }

      if (this.customerGuid != null && this.customerGuid != "") {
        if (responses[2] != null) {
          if (responses[2].code == "200" && responses[2].message == "") {
            let customer = new CustomerModel();
            customer = responses[2].model;
            this.customerName = customer.customerName;
            this.legalEntityName = customer.legalEntityName;
            this.dbaName = customer.operatorName;
            this.acctCreationDate = customer.acctCreationDate;
            this.modifiedBy = customer.modifiedBy;
            this.modifiedDate = customer.modifiedDate;
            this.creditStatus = customer.creditStatus;
            this.customerType = customer.customerTypeDesc;
            this.thirdPartyScreening = customer.thirdPartyScreeningStatusDesc;
            this.industryType = customer.industryDesc;
            this.marketingRegion = customer.salesTerritory;
            this.stock_symbol = customer.stockSymbol;
            this.creditCheck = customer.creditCheckDesc;
            this.apisCarrierCode = customer.apisCarrierCode;
            this.showApisReportFirst = customer.showApisReportFirst;
            this.excludeCCOptions = customer.excludeCCOptions;
            this.initControls()
              

          }
          else {
            if (responses[0].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }

     

      this.showSpin = false;


    });

    this.f.includeDisabledRecords.setValue(!this.isActive);

  }

  initControls() {
    this.customer_profile_setup_edit_form = this._formBuilder.group({
      customerName: this.customerName,
      legalEntityName: this.legalEntityName,
      dbaName: this.dbaName,
      customer_type: this.customerType,
      credit_status: this.creditStatus,
      sales_territory: this.marketingRegion,
      industry_type: this.industryType,
      stock_symbol: this.stock_symbol,
      acctCreationDate: this.acctCreationDate,
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      includeDisabledRecords: this.includeDisabledRecords,
      apis_carrier_code: this.apisCarrierCode,
      prelimAPIS: [{ value:this.showApisReportFirst, disabled: true }],
      excludeCCOptions: [{ value: this.excludeCCOptions, disabled: true }]

    });


  }

  ngAfterViewInit() {

  }

  get f() { return this.customer_profile_setup_edit_form.controls; }


  

 
  clickClose() {
    this._router.navigate(['/customers/customer-profile-setup']);
  }

  clickTab(s: string) {
    switch (this.selectedTabName) {
      case 'notes':
        if (this.customerNoteRef.controlValueChanged) {
          this.confirmDialogRef = this._dialogService.openConfirmDialog("You have unsaved changes. Would you like to save before continuing?");
          this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            this.eventSubscription.unsubscribe();
            if (result) {
              this.customerNoteRef.clickSave();
              if (this.selectedTabName != s)
                this.selectedTabName = s;
            }
            else {
              if (this.selectedTabName != s) {
                this.selectedTabName = s;
              }
            }
          });
        }
        else {
          if (this.selectedTabName != s) {
            this.selectedTabName = s;
          }
        }
        break;
      case 'haltServices':
        if (this.haltServicesRef.controlValueChanged) {
          this.confirmDialogRef = this._dialogService.openConfirmDialog("You have unsaved changes. Would you like to save before continuing?");
          this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            this.eventSubscription.unsubscribe();
            if (result) {
              this.haltServicesRef.clickSave();
              //if (this.selectedTabName != s)
              //  this.selectedTabName = s;
            }
            else {
              if (this.selectedTabName != s) {
                this.selectedTabName = s;
              }
            }
          });
        }
        else {
          if (this.selectedTabName != s) {
            this.selectedTabName = s;
          }
        }
        break;
      case 'accounting':
        if (this.accountingProfileRef.controlValueChanged) {
          this.confirmDialogRef = this._dialogService.openConfirmDialog("You have unsaved changes. Would you like to save before continuing?");
          this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            this.eventSubscription.unsubscribe();
            if (result) {
              this.accountingProfileRef.saveAccountingData();
              if (this.selectedTabName != s)
                this.selectedTabName = s;
            }
            else {
              if (this.selectedTabName != s) {
                this.selectedTabName = s;
              }
            }
          });
        }
        else {
          if (this.selectedTabName != s) {
            this.selectedTabName = s;
          }
        }
        break;
      default:
        if (this.selectedTabName != s) {
          this.selectedTabName = s;

        }
    }  

  }


  openSelection(columnName: string) {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "32.5em";//"1000px";
    //config.maxWidth = "65em";//"1000px";
    config.width = "65em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { v: this._authService.getCurrentTimeNumber(), customerGUID: this.customerGuid, customerName: this.selectedClientName, userType: 'customer' };
    config.position = {left: "10px", top:"140px"};

    if (this.isPopup) {
      this._dialogRef.close(true);
    }

    switch (columnName) {
      case 'Act Scetup':
        config.height = "50em";
        config.width = "90em";
        config.maxWidth = "90em";
        config.maxHeight = "50em";
        config.position = {left: "10px", top:"140px"};
        this._dialog.open(CustomerProfileSetupEditComponent, config);
        break;
      case 'Client Information':
        config.width = "69em";
        this._dialog.open(CustomerInformationDialogComponent, config);
        break;
      case 'Aircraft':
        config.width = "92em";
        config.maxWidth = "92em";
        this._dialog.open(AircraftProfileComponent, config);
        break;
      case 'Crew, PAX & Support':
        config.height = "auto";
        config.maxHeight = "80em";
        config.width = "81em";
        this._dialog.open(ManageContactDetailsComponent, config);
        break;
      case 'Admin Services':
        config.height = "max-content";
        config.width = "max-content";
        this._dialog.open(AdminServicesClientComponent, config);
        break;
    }

  }

  

  clickEditMain() {
   
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //dialogConfig.width = "46.5em";//"1000px";
    //dialogConfig.height = "13em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: 'Edit Profile', customerGUID: this.customerGuid, pageName: 'main' };
    const dialogRef = this._dialog.open(CustomerProfileMainEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.actionName == "edit") {
        this.loadPageData();
      }
      else {
        this.showSpin = false;
      }
    });
 

  }

 
  checkDisabled(event: any) {
    
      this.includeDisabledRecords = event.target.checked;
    
  }

 
  clickRouteNew(route: string, display: string) {
    this._dialogRef.close(true);
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber() + "&customerGuid=" + this.customerGuid + "&customerName=" + this.selectedClientName);
  }

  openClientProfileSetupComponent() {
    if(this.isPopup){  
      this._dialogRef.close(true);
    }      
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    //config.minWidth = "76em";
    config.maxWidth = "92em";//"1000px";
    config.height = "40em";//"1000px";
    config.width = "55em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = {left: "10px", top:"140px"};
    config.data = { gsId: "", tripCodeId: "",  isDialog: true, v: this._authService.getCurrentTimeNumber()   };

    this._dialog.open(CustomerProfileSetupComponent2, config);
  }

 

  }

 
 
 



