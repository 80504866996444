<div>
  <h5>Map Profile</h5>
  <div style="background-color:white;padding:1em" class="d-inline-flex">
    <form *ngIf="vendor_map_profile_edit_form" [formGroup]="vendor_map_profile_edit_form">
      <div>
        <div>
          <div class="form-group mr-2">
            <label style="font-size:small">Location Description</label>
            <select *ngIf="f" formControlName="map_location" style="width:22em;margin-top:-.35em" class="form-control form-control-sm dropdown" (change)="updateIcon()" [ngClass]="{ 'is-invalid': submitted && f.map_location.errors }" >
              <option *ngFor="let l of mapLocationList" [ngValue]="l.mapLocationID">{{l.mapLocationDescription}}</option>
            </select>           
            <div *ngIf="f.map_location.errors && submitted" class="alert alert-danger p-0" style="font-size:small;width:10em">
              <div *ngIf="f.map_location.errors.required">Required.</div>
            </div>
          </div>
        </div>
        <div>
          <div class="form-group mr-2">
            <label style="font-size:small">Notes</label>
            <textarea (mousedown)="$event.stopPropagation()" formControlName="notes" maxlength="255" class="form-control inputBox" style="width:22em; height:6.5em; margin-top:-.35em; padding:0em 0em 0em 0em"></textarea>
          </div>
        </div>
        <div>
          <div class="form-group mr-2">
            <mat-slide-toggle labelPosition="before" formControlName="isPublic" color="primary">
              <span style="font-size:small"> Share with Clients</span>
            </mat-slide-toggle>
          </div>
        </div>
        
      </div>
    </form>
    <div style="width:40em;margin-left:1em">
      <div style="width:100%" class="d-inline-flex justify-content-between">
        <div style="font-size:small" *ngIf="marker==undefined">Right click to add marker</div>
        <div style="font-size:small" *ngIf="marker!=undefined">Click and drag to move marker</div>
        <mat-slide-toggle style="transform:scale(.8)" labelPosition="before" color="primary" (change)="updateMarkers()" [(ngModel)]="toggleMarkers" [ngModelOptions]="{standalone: true}">
          Show All Markers
        </mat-slide-toggle>
      </div>
      <div style="position: relative;">
        <div style="width:100%;height:40em;border-radius:.25em" id="map-profile-edit"></div>
      </div>

    </div>
  </div>

  
  <div style="width:100%" class="d-inline-flex justify-content-between">
    <div class="d-inline-flex mt-3">
      <div>
        <button mat-button class="btn btn-high mr-2" (click)="clickSave()">Save</button>
      </div>
      <div>
        <button mat-button class="btn btn-low mr-2" mat-dialog-close>Close</button>
      </div>
      <app-error-message style="margin-top:-.45em" *ngIf="errMsg!=''" [errorMsg]="errMsg"></app-error-message>
      <app-success-message style="margin-top:-.45em" *ngIf="showSuccessMsg" [successMsg]=""></app-success-message>
    </div>
    <div *ngIf="mapProfileID!=0" class="d-inline-flex">
      <form *ngIf="vendor_map_profile_edit_form" [formGroup]="vendor_map_profile_edit_form">
        <div class="form-group form-inline mt-3">
          <label style="font-size: small; margin-right: .5em">Disable Record </label>
          <select *ngIf="f" formControlName="isActive" style="margin-right:1.5em" class="form-control form-control-sm dropdown">
            <option [ngValue]="false">Yes</option>
            <option [ngValue]="true">No</option>
          </select>
        </div>
      </form>
      <div class="mt-2">
        <div *ngIf="modifiedBy!=''" style="font-size:small">
          <label style="width:7em;text-align:right;margin-right:.5em">Modified By: </label>{{modifiedBy}}
        </div>
        <div *ngIf="modifiedDate!=''" style="font-size:small;margin-top:-.75em">
          <label style="width: 7em; text-align: right; margin-right: .5em ">Modified Date: </label>{{modifiedDate}}
        </div>
      </div>
    </div>
  </div>
</div>
