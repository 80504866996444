import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { CustomValidators } from '../common-utility/custom.validators';
import { AdvisoryLookupModel, AdvisoryModel } from '../models/advisory.model';
import { FuelService } from '../services/fuel.service';
import { TaxesAndFeesModel } from '../models/taxesandfees.model';
import { ResponseModel } from '../models/response.model';
import { AirportModel } from '../models/airport.model';
import { Observable, forkJoin } from 'rxjs';
import { CountryModel } from '../models/country.model';
import { AdvisoryService } from '../services/advisory.service';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { FileModel } from '../models/file.model';
import { GlobalConstant } from '../common-utility/global-constant';
import { CommonService } from '../services/common.service';
import { AdvisoryDocumentModel } from '../models/advisory-document.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { saveAs } from 'file-saver';
import { HotelModel } from '../models/hotel.model';
import { VendorModel } from '../models/vendor.model';
import { VendorService } from '../services/vendor.service';
import { AdvisoryVendorDialogComponent } from './advisory-vendor-dialog/advisory-vendor-dialog.component';
import { AdvisoryTagsComponent } from './advisory-tags.component';
import { AdvisoryHotelsComponent } from './advisory-hotels.component';
import { Router } from '@angular/router';
import { AdvisoryDetailsComponent } from './advisory-details.component';
import { StateProvinceModel } from '../models/state-province.model';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-advisory-edit',
    templateUrl: './advisory-edit.component.html',
    styleUrls: ['./advisory-edit.component.css']
})

export class AdvisoryEditComponent implements OnInit {

    advisory_edit_form: UntypedFormGroup;
    isDialog: boolean = false;

    isCountry: boolean;
    isAirport: boolean;
    sort_by: any;
    pageSize: any;
    pageIndex: any;
    effectiveDate: Date;
    expirationDate: Date;
    startTime: string;
    endTime: string;
    isValideffectiveDate: boolean = true;
    isValidexpirationDate: boolean = true;
    showSpin: boolean;
    totalRecordsText: string;
    iata: any;
    icao: any;
    country: any;
    showModified: any;
    msg: string;
    loading: boolean;
    countryid: number;
    countryName: string;
    countrylength: number;
    errMsg: string = '';
    validateICAO: boolean;
    isValidICAO: boolean;
    countrylist: CountryModel[];
    airportlist: AirportModel[];
    advisoryDetails: AdvisoryModel = new AdvisoryModel();
    hotelName: any;
    vendorName: any;
    currentAdvisoryId: number;
    ratings: [];
    sources: [];
    effectiveDateText: string;
    expirationDateText: string;
    tagIdList: string[];
    displayedColumns: string[] = ['type', 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'twentyFourHrs', 'hoursStart', 'hoursEnd', 'remarks', 'action'];

    advisoryTagsList: AdvisoryLookupModel[] = []; // Your tag list
    numberOfItemsPerColumn = 6;
    advisoryDayHoursList: any;


    uploader: FileUploader;
    finishAllUploads: boolean = true;
    fileList: FileModel[] = [];
    maxFileSize: number;
    maxFileUploadNumber: number;
    allowedFileTypeList: string[];
    totalMessageAttachmentFileSize: number = 0;
    allowedFileType: string;
    maxTempDocId: number = 0;
    advisoryDocumentsList: AdvisoryDocumentModel[] = [];
    hasModifiedDocuments: boolean = false;
    isModified: boolean = false;
    hasExistingDocument: boolean = false;
    hotelList: HotelModel[] = [];
    dialogTitle: string = '';
    countryID: number;
    webLink: string;
    issue: string;
    lk_AdvisoryRatingID: number = 1;
    advisorySourceID: number;
    resolution: string;
    hotelGUID: string;
    // vendorGUID: string;
    isHeadlineNews: boolean = false;
    isPublicNews: boolean = false;
    airportID: number;
    submitted: boolean;
    vendorList: VendorModel[];
    inpTitle: string = '';
    inpWebLink: string = '';


    groundHandlerGUID: string = '';
    ssnVendorGUID: string = '';
    fuelSupplierGUID: string = '';
    groundHandlerVendorName: string = '';
    ssnVendorName: string = '';
    fuelSupplierName: string = '';
    intoplaneAgent: string = '';

    filteredAdvisoryTagsList: AdvisoryLookupModel[];
    filteredAdvisoryTags: string = '';
    selectedTagTypeIds: string;
    filteredHotel: HotelModel;

    hasCountry: boolean = false;
    hasIcao: boolean = false;
    hasTags: boolean = false;
    hasGroundHandlerTag: boolean = false;
    hasFuelTag: boolean = false;
    hasHotelTag: boolean = false;
    filteredAdvisoryTagsDescList: string[] = [];
    hasAllReqValues: boolean = false;
    hasDates: boolean = false;
    utcEffectiveDate: string;
    utcExpirationDate: string;
    localTimes: AdvisoryModel;


    localEffectiveDate: string;
    localExpirationDate: string;
    localAdvisoryValidTimeStart: string;
    localAdvisoryValidTimeEnd: string;
    successMsg: string = '';
    showSuccessMsg: boolean = false;
    isActive: any;
    modifiedDate: string = '';
    modifiedBy: string = '';
    activeAdvisoryDocumentsList: AdvisoryDocumentModel[] = [];
    iscountryorairport: string = 'airport';
    airportName: string = '';
    stateProvinceList: StateProvinceModel[] = [];
    stateProvinceID: number = 0;
    dataChanged: boolean = false;
    isLoading: boolean = false;
    totalCharactersInIssue: number = 0;
    // stateControl = new FormControl<string | StateProvinceModel>('');
    // filteredState: Observable<StateProvinceModel[]>;


    constructor(@Optional() private readonly _dialogRef: MatDialogRef<AdvisoryEditComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private data: any, private readonly _authService: AuthenticateService,
        private readonly _fuel: FuelService, private readonly _vendorService: VendorService, private _router: Router,
        private readonly _advisoryService: AdvisoryService, private readonly _commonService: CommonService,
        private readonly _formBuilder: UntypedFormBuilder, private readonly _dialog: MatDialog,
    ) {

        if (this.data) {
            // this.isDialog = true;
            this.currentAdvisoryId = data.advisoryId;
            this.isDialog = data.isDialog;
        }

        this.currentAdvisoryId = data.advisoryId;
        this.dialogTitle = data.dialogTitle;


        // if (this.dialogTitle == 'Add') {
        //     this.isDialog = true;
        // }

    }

    ngOnInit() {


        this.countryName = '';
        this.countryID = 0;
        this.airportID = 0;
        this.icao = '';
        this.iata = '';
        this.vendorName = '';
        this.hotelName = '';
        this.webLink = '';
        this.issue = '';
        this.lk_AdvisoryRatingID = 1;
        this.advisorySourceID = 1;
        this.resolution = '';
        this.hotelGUID = '';
        this.isActive = true;
        this.pageSize = 0;
        this.pageIndex = 0;

        this.isHeadlineNews = true;
        this.isPublicNews = true;
        this.inpTitle = '';
        this.inpWebLink = '';
        this.hasCountry = false;
        this.hasIcao = false;
        this.hasTags = false;
        this.hasGroundHandlerTag = false;
        this.hasFuelTag = false;
        this.hasHotelTag = false;



        if (this.dialogTitle == 'Add') {

            // this._authService.updateAccessTime();
            let d = new Date();
            let uyear: number = d.getUTCFullYear();
            let umonth: number = d.getUTCMonth();
            let udate: number = d.getUTCDate();
            let uhour: number = d.getUTCHours();
            let uminute: number = d.getUTCMinutes();
            let d1: Date = new Date(uyear, umonth, udate, uhour, uminute);
            let hstartnumber: number = 0;// 672 * 60 * 60 * 1000;  // 2 weeks back
            let hendnumber: number = 672 * 60 * 60 * 1000;    // 4 weeks front
            let dt1: Date = new Date(d1.getTime() - hstartnumber);
            let dt2: Date = new Date(d1.getTime() + hendnumber);
            this.effectiveDate = dt1;
            // this.expirationDate = dt2;
            let m1: number = dt1.getMinutes();
            let m1s: string = m1.toString();
            if (m1 < 10) {
                m1s = "0" + m1.toString();
            }
            //this.startTime = dt1.getHours().toString() + ":" + m1s;
            let m2: number = dt2.getMinutes();
            let m2s: string = m2.toString();
            if (m2 < 10) {
                m2s = "0" + m2.toString();
            }
        }


        // this._authService.updateAccessTime();
        this.uploader = new FileUploader({
            url: GlobalConstant.baseUrl + "Common/uploadFiles",
            method: "post",
            authToken: 'bearer ' + localStorage.getItem('token'),
            disableMultipart: false
        });
        this.uploader.onProgressItem = (fileItem, progress) => this.onProgressItem(fileItem, progress);
        this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
        this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
        this.uploader.onCompleteAll = () => {
            this.finishAllUploads = true;
            this.uploader.clearQueue();
        };

        this.getAllData().subscribe(responses => {
            if (responses != null) { }

            //this.intlfrequency = '38678BE2-03D2-4A3C-B700-2BC6F0262F03';


            if (responses[0] != null) {
                if (responses[0].code == "200" && responses[0].message == "") {

                    //let country = new CountryModel();

                    this.advisoryTagsList = responses[0].model.advisoryTags;
                    this.ratings = responses[0].model.ratings;
                    this.sources = responses[0].model.sources;
                    this.advisoryDayHoursList = responses[0].model.advisoryDayHoursList;
                }
                else {
                    if (responses[0].code == "401") {
                        this._authService.signOut();
                    }
                }
            }

            if (responses[1] != null) {
                if (responses[1].code == "200" && responses[1].message == "") {
                    //let country = new CountryModel();
                    //country.countryName = "All";
                    //responses[1].model.splice(1, 1, country);
                    this.countrylist = responses[1].model;
                }
                else {
                    if (responses[1].code == "401") {
                        this._authService.signOut();
                    }
                }
            }


            if (responses[2] != null) {
                if (responses[2].code == "200" && responses[2].message == "") {
                    if (responses[2].model != null) {
                        let fileObj = new FileModel();
                        fileObj = responses[2].model;
                        this.allowedFileTypeList = fileObj.allowedFileTypeList;
                        this.allowedFileType = new Array(this.allowedFileTypeList).toString().split(",").join(", ");
                        this.maxFileSize = fileObj.fileSize;
                        this.maxFileUploadNumber = fileObj.allowedFileUploadNumber;
                    }
                }
                else {
                    if (responses[2].code == "401") {
                        this._authService.signOut();
                    }
                }
            }


            if (responses[3] != null) {
                if (responses[3].code == "200" && responses[3].message == "") {

                    //let country = new CountryModel();

                    this.advisoryDetails = responses[3].model;

                    this.countryName = this.advisoryDetails.countryName;
                    this.countryID = this.advisoryDetails.countryID;
                    this.stateProvinceID = this.advisoryDetails.stateProvinceID;
                    if (this.countryID != 0) {
                        this.hasCountry = true;
                        this.updateCityAndStateList(this.countryID);

                    }
                    else {
                        this.hasCountry = false;
                    }
                    this.airportID = this.advisoryDetails.airportID;
                    if (this.airportID == null || this.airportID == 0) {
                        this.iscountryorairport = 'country';
                    } 
                    this.icao = this.advisoryDetails.icao;
                    this.iata = this.advisoryDetails.iata;
                    this.vendorName = this.advisoryDetails.vendorName;
                    this.hotelName = this.advisoryDetails.hotelName;
                    this.issue = this.advisoryDetails.issue;
                    this.totalCharactersInIssue = this.issue.replace(/\s/g, '').length;
                    this.lk_AdvisoryRatingID = this.advisoryDetails.lk_AdvisoryRatingID;
                    this.advisorySourceID = this.advisoryDetails.advisorySourceID;
                    this.resolution = this.advisoryDetails.resolution;
                    this.hotelGUID = this.advisoryDetails.hotelGUID;

                    this.groundHandlerGUID = this.advisoryDetails.groundHandlerGUID;
                    this.ssnVendorGUID = this.advisoryDetails.ssnVendorGUID;
                    this.fuelSupplierGUID = this.advisoryDetails.fuelSupplierGUID;
                    this.groundHandlerVendorName = this.advisoryDetails.groundHandlerVendorName;
                    this.ssnVendorName = this.advisoryDetails.ssnVendorName;
                    this.fuelSupplierName = this.advisoryDetails.fuelSupplierName;
                    this.intoplaneAgent = this.advisoryDetails.intoplaneAgent;
                    this.isActive = this.advisoryDetails.isActive;
                    // this.isActive = this.advisoryDetails.isActive == true ? 1 : 0;

                    this.pageSize = this.advisoryDetails.pageSize;
                    this.pageIndex = this.advisoryDetails.pageIndex;
                    this.isHeadlineNews = this.advisoryDetails.isHeadlineNews;
                    this.isPublicNews = this.advisoryDetails.isPublicNews;
                    this.inpTitle = this.advisoryDetails.title;
                    this.airportName = this.advisoryDetails.airportName;
                    this.inpWebLink = this.advisoryDetails.weblink;
                    this.modifiedBy = this.advisoryDetails.modifiedBy;
                    this.modifiedDate = this.advisoryDetails.modifiedDate;

                    this.effectiveDate = new Date(this.advisoryDetails?.effectiveDate);
                    this.expirationDate = this.advisoryDetails?.expirationDate != null ? new Date(this.advisoryDetails?.expirationDate) : null;

                    this.getHotelList(this.advisoryDetails.icao);
                    this.advisoryTagsList?.forEach(x => x.selected = this.advisoryDetails?.advisoryTagIDs?.split(',').includes(x.lookupId.toString()));

                    let selectedTagList = this.advisoryTagsList.filter(x => this.advisoryDetails?.advisoryTagIDs?.split(',').includes(x.lookupId.toString()));

                    this.filteredAdvisoryTagsList = selectedTagList;
                    this.selectedTagTypeIds = this.filteredAdvisoryTagsList.map(x => x.lookupId).join(',');
                    this.filteredAdvisoryTags = this.filteredAdvisoryTagsList.map(x => x.description).join(', ');


                    this.filteredAdvisoryTagsDescList = this.filteredAdvisoryTags.split(',').map(description => description.trim());
                    this.hasFuelTag = this.filteredAdvisoryTagsDescList.includes('Fuel');
                    this.hasGroundHandlerTag = this.filteredAdvisoryTagsDescList.includes('Handling');
                    this.hasHotelTag = this.filteredAdvisoryTagsDescList.includes('Hotels');

                    this.hasIcao = (this.advisoryDetails.icao == '' || this.advisoryDetails.icao == null) ? false : true;
                    this.hasTags = (this.advisoryDetails.advisoryTagIDs == '' || this.advisoryDetails.advisoryTagIDs == null) ? false : true;

                    // console.log(selectedTagList);

                    this.advisoryDocumentsList = this.advisoryDetails.advisoryDocumentsList;
                    if (this.advisoryDocumentsList == null) {
                        this.advisoryDocumentsList = [];
                    }
                    this.finishAllUploads = true;
                }
                else {
                    if (responses[3].code == "401") {
                        this._authService.signOut();
                    }
                }
            }
            // if (responses[4] != null) {
            //   if (responses[4].code == "200" && responses[4].message == "") {
            //     this.hotelList = responses[4].model;
            //   }
            //   else {
            //     if (responses[4].code == "401") {
            //       this._authService.signOut();
            //     }
            //   }
            // }




            this.initControls();
            this.processDates();
            // this.getLocalDateTimes();



            this.checkFormControlValueChanged();
            if (this.countryID != 0) {
                this.hasCountry = true;
            }
            else {
                this.hasCountry = false;
            }

            this.showSpin = false;


            // this.f.effectiveDate.setValue(this.effectiveDate);
            // this.f.expirationDate.setValue(this.expirationDate);
            // this.f.startTime.setValue(this.startTime);
            // this.f.endTime.setValue(this.endTime);

        });

        // this.initControls();


    }

    initControls() {
        this.tagIdList = this.advisoryDetails?.advisoryTagIDs?.split(',');
        this.advisory_edit_form = this._formBuilder.group({
            effectiveDate: [this.effectiveDate, Validators.required],
            expirationDate: [this.expirationDate, Validators.required],
            startTime: [this.advisoryDetails?.advisoryValidTimeStart],
            endTime: [this.advisoryDetails?.advisoryValidTimeEnd],
            sortBy: [this.advisoryDetails?.sortBy],

            // country: [this.advisoryDetails?.countryName],  ///, disabled: this.advisoryDetails?.showModified ],
            countryID: [this.countryID],  ///, disabled: this.showModified ],
            stateProvinceID: [this.stateProvinceID],  ///, disabled: this.showModified ],
            airportID: [this.airportID],  ///, disabled: this.showModified }],
            icao: [this.icao, Validators.required],
            icaoOrIata: [this.icao, Validators.required],
            iata: [this.iata, Validators.required],
            vendorName: [this.vendorName],
            hotelName: [this.hotelName],

            pageSize: [this.pageSize],
            pageIndex: [this.pageIndex],
            selectedTagTypeIds: [this.tagIdList],
            inpTitle: [this.inpTitle],
            inpWebLink: [this.inpWebLink],
            issue: [this.issue],
            lk_AdvisoryRatingID: [this.lk_AdvisoryRatingID],
            advisorySourceID: [this.advisorySourceID],
            resolution: [this.resolution],
            isHeadlineNews: [this.isHeadlineNews],
            isPublicNews: [this.isPublicNews],
            document: [''],
            hotelGUID: [this.hotelGUID],

            isActive: [this.isActive],

            localEffectiveDate: [''],
            localExpirationDate: [''],
            localAdvisoryValidTimeStart: [null],
            localAdvisoryValidTimeEnd: [null],
            iscountryorairport: [this.iscountryorairport],


        });


        // this.checkServiceTypeArray = new UntypedFormControl(this.selectedServiceTypeList); // for mat-select
        // this.checkTaskStatusArray = new UntypedFormControl(this.selectedTaskStatusList); // for mat-select
    }
    

    checkFormControlValueChanged(): void {
        this.advisory_edit_form.get("countryID").valueChanges.subscribe(x => {
            if (this.f.countryID.value != 0) {
                this.hasCountry = true;
            }
            else {
                this.hasCountry = false;
            }

        });
        this.advisory_edit_form.get("airportID").valueChanges.subscribe(x => {
            if (this.f.airportID.value != 0) {
                this.hasIcao = true;
            }
            else {
                this.hasIcao = false;
            }

        });
        this.advisory_edit_form.get("issue").valueChanges.subscribe(x => {
            this.checkIssueChange();
        });

        this.advisory_edit_form.valueChanges.subscribe(changes => {
            this.dataChanged = true;
        });

    }

    get f() { return this.advisory_edit_form.controls; }


    getAllData(): Observable<any[]> {
        let getCountryList = this._fuel.getAllCountryList();
        let getAdvisoryDetailsById = this._advisoryService.getAdvisoryByAdvisoryID(this.currentAdvisoryId);
        let getAdvisoryTagTypes = this._advisoryService.getAdvisoryLookups();
        let getAllowedDocTypeResponse = this._commonService.getAllowedFileTypeAndFileSize();


        let req3 = new HotelModel();
        req3.icao = this.advisoryDetails.icao;
        req3.distanceFromAirport = 50;

        let getHotelResponse = this._commonService.getHotelListByAirport(req3);
        // let req = new TripModel();
        // let getVendorResponse = this._groundStopService.getTripVendorListByDateRange(req);


        if (this.dialogTitle == 'Edit') {
            return forkJoin([getAdvisoryTagTypes, getCountryList, getAllowedDocTypeResponse, getAdvisoryDetailsById]);  //, getHotelResponse]);
        }
        else {
            this.advisoryDetails = new AdvisoryModel();
            return forkJoin([getAdvisoryTagTypes, getCountryList, getAllowedDocTypeResponse]);  //, getHotelResponse]);

        }

    }


    getHotelList(icao: string) {

        let req3 = new HotelModel();
        req3.icao = icao;
        req3.distanceFromAirport = 50;

        this._commonService.getHotelListByAirport<ResponseModel<HotelModel[]>>(req3).subscribe(response => {
            if (response != null && response.code == "200") {
                this.hotelList = [];
                this.hotelList = response.model;

                this.filteredHotel = this.hotelList.filter(x => x.hotelGUID == this.advisoryDetails.hotelGUID)[0];



            }
            else {
                if (response.code == "401") {
                    //this.errMsg = response.message
                    this._authService.signOut();
                }

            }

        })
    }

    dateTypeChange() {
        this.processDates();
    }


    processDates() {
        this._authService.updateAccessTime();

        this.showSpin = true;
        this.totalRecordsText = "";
        let request = new AdvisoryModel();
        request.effectiveDate = this.f.effectiveDate.value;
        request.effectiveDateText = CustomValidators.formatDateToMMDDYYYY(this.f.effectiveDate.value);

        request.expirationDate = this.f.expirationDate.value;

        if (request.expirationDate != null) {
            request.expirationDateText = CustomValidators.formatDateToMMDDYYYY(this.f.expirationDate.value);
        }
        if (this.f.startTime.value == '')
            this.f.startTime.setValue(null);
        if (this.f.endTime.value == '')
            this.f.endTime.setValue(null);

        this.effectiveDateText = request.effectiveDateText;
        this.expirationDateText = request.expirationDateText;

        if (this.effectiveDateText == '' || this.effectiveDateText == null) {
            this.hasDates = false;
        } else {
            this.hasDates = true;

        }

        if (this.f.startTime.value == "" || this.f.startTime.value == null) {
            this.utcEffectiveDate = this.effectiveDateText + " 0:00"
        }
        else {
            this.utcEffectiveDate = this.effectiveDateText + " " + this.f.startTime.value;
        }
        if (this.f.endTime.value == "" || this.f.endTime.value == null) {
            this.utcExpirationDate = this.expirationDateText + " 0:00"
        }
        else {
            this.utcExpirationDate = this.expirationDateText + " " + this.f.endTime.value;
        }
        // this.getLocalDateTimes();

    }


    getLocalDateTimes() {
        var request = new AdvisoryModel();
        request.effectiveDate = new Date(this.utcEffectiveDate);
        request.expirationDate = new Date(this.utcExpirationDate);
        request.airportID = this.f.airportID.value;
        request.icao = this.f.icao.value;

        this._advisoryService.getlocalTimesForAirport<ResponseModel<AdvisoryModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
                this.localTimes = response.model;

                this.f.localEffectiveDate.setValue(this.localTimes.localEffectiveDate);
                this.f.localExpirationDate.setValue(this.localTimes.localExpirationDate);

                if (this.f.startTime.value != "" && this.f.startTime.value != null)
                    this.f.localAdvisoryValidTimeStart.setValue(this.localTimes.localAdvisoryValidTimeStart);

                if (this.f.endTime.value != "" && this.f.endTime.value != null)
                    this.f.localAdvisoryValidTimeEnd.setValue(this.localTimes.localAdvisoryValidTimeEnd);


            }
            else {
                if (response.code == "401") {
                    this._authService.signOut();
                }
            }

        });

    }

    dateOnchange(dateType: string, e: any) {
        if (dateType == "S") {
            if (e.target._elementRef.nativeElement.value != "") {
                if (e.target.value == null) {
                    this.isValideffectiveDate = false;
                    this.f.effectiveDate.setErrors({ isValidDate: false });
                }
                else {
                    this.isValideffectiveDate = true;
                }
            }
            else {
                this.isValideffectiveDate = true;
            }
        }

        // if (dateType == "E") {
        //     if (e.target._elementRef.nativeElement.value != "") {
        //         if (e.target.value == null) {
        //             this.isValidexpirationDate = false;
        //             this.f.expirationDate.setErrors({ isValidDate: false })
        //         }
        //         else {
        //             this.isValidexpirationDate = true;
        //         }
        //     }
        //     else {
        //         this.isValidexpirationDate = true;
        //     }
        // }

        this.processDates();

    }



    timeChange(dateType: string, e: any) {
        if (dateType == "S") {
            if (e.target.value == "") {
                this.f.startTime.setErrors(null);
                this.f.startTime.setValue('');
            }
            else {
                let timeObj = new DateTimeObjModel();
                timeObj.timeString = e.target.value;
                timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
                if (!timeObj.isValidDateTime) {
                    this.f.startTime.setErrors({ pattern: true });
                }
                else {
                    this.f.startTime.setValue(timeObj.timeString);
                    e.target.value = timeObj.timeString;
                }
            }
        }
        if (dateType == "E") {
            if (e.target.value == "") {
                this.f.endTime.setErrors(null);
                this.f.endTime.setValue('');
            }
            else {
                let timeObj = new DateTimeObjModel();
                timeObj.timeString = e.target.value;
                timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
                if (!timeObj.isValidDateTime) {
                    this.f.endTime.setErrors({ pattern: true });
                }
                else {
                    this.f.endTime.setValue(timeObj.timeString);
                    e.target.value = timeObj.timeString;
                }
            }
        }
        this.processDates();

    }



    removeStateProvince(e: any) {

        this.f.stateProvinceID.setValue(0);
    }
    countryChange(e: any, c: CountryModel) {

        let request = new TaxesAndFeesModel();
        // request.countryID = this.f.country.value;
        let countryid = request.countryID;


        this.f.icao.setValue('');
        this.f.icaoOrIata.setValue('');
        this.f.iata.setValue('');
        this.f.airportID.setValue(0);
        this.f.stateProvinceID.setValue(0);
        this.hasIcao = false;

        this.groundHandlerGUID = '';
        this.ssnVendorGUID = '';
        this.fuelSupplierGUID = '';
        this.groundHandlerVendorName = '';
        this.ssnVendorName = '';
        this.fuelSupplierName = '';
        this.intoplaneAgent = '';

        this.updateCityAndStateList(this.f.countryID.value);
        this.countryName = this.countrylist.find(x => x.countryID == this.f.countryID.value).countryName;
        // this.countryName = c.countryName;

        // this._fuel.getAirportByCountryID<ResponseModel<AirportModel[]>>(this.countrylist.filter(x => x.countryID == this.f.countryID.value)[0].countryGUID).subscribe(response => {
        //     if (response != null && response.code == "200") {
        //         if (response.model.length > 0) {
        //             this.airportlist = response.model;
        //         }

        //     }
        //     else {
        //         this.msg = "";
        //     }
        //     this.loading = false;
        //     this.showSpin = false;

        // })
    }


    removeAirport(e: any) { 

        this.f.icao.setValue('');
        this.f.icaoOrIata.setValue('');
        this.f.iata.setValue('');
        this.f.airportID.setValue(0);
        this.hasIcao = false;

        this.groundHandlerGUID = '';
        this.ssnVendorGUID = '';
        this.fuelSupplierGUID = '';
        this.groundHandlerVendorName = '';
        this.ssnVendorName = '';
        this.fuelSupplierName = '';
        this.intoplaneAgent = '';


        this.updateCityAndStateList(this.f.countryID.value);

        // this.countryName = this.countrylist.find(x => x.countryID == this.f.countryID.value).countryName;
        // this.countryName = c.countryName;

 
    }

    icaoValidation(e: any, airportcode: string) { // appending the updated value to the variable
        let length: number = 0;
        // length = airportcode == 'icao' ? 4 : 3;

        if (e.target.value.length >= 3) {
            let icao: string = e.target.value;
            this._fuel.getCountryDetailsByICAO<ResponseModel<AirportModel>>(icao).subscribe(response => {
                if (response != null && response.code == "200") {
                    if (response.model != null) {
                        let airportModel = new AirportModel();

                        airportModel = response.model;
                        //this.country = response.model;
                        // this.advisory_edit_form.get('country').setValue(airportModel.countryName);
                        this.advisory_edit_form.get('countryID').setValue(airportModel.countryId);
                        this.advisory_edit_form.get('airportID').setValue(airportModel.airportID);

                        // if (airportcode == 'icao') { }
                        this.advisory_edit_form.get('iata').setValue(airportModel.iata);


                        // if (airportcode == 'iata')
                            this.advisory_edit_form.get('icao').setValue(airportModel.icao);

                        //  this.icao = airportModel.icao;
                        //this.iata = airportModel.iata;

                        this.airportID = airportModel.airportID;
                        this.airportName = airportModel.airportName;
                        this.countryid = airportModel.countryId;
                        this.countryName = airportModel.countryName;
                        this.countrylength = this.countryName.length;
                        this.errMsg = '';

                        this.getHotelList(this.f.icao.value);
                        //   this.isValidICAO = response.model;
                        // this.initControls();

                        this.groundHandlerGUID = '';
                        this.ssnVendorGUID = '';
                        this.fuelSupplierGUID = '';
                        this.groundHandlerVendorName = '';
                        this.ssnVendorName = '';
                        this.fuelSupplierName = '';
                        this.intoplaneAgent = '';
                        this.hotelGUID = '';
                        this.hotelName = '';
                        this.processDates();

                    }
                    else {

                        this.validateICAO = false;
                    }

                }
                else {
                    if (response.code == "401") {
                        //this.errMsg = response.message
                        this._authService.signOut();
                    }
                    else {
                        this.country = 0;
                        this.countrylength = 0;
                        this.errMsg = "Invalid ICAO.";
                    }
                }
                this.loading = false;
            })
        }
        else {
            this.validateICAO = true;
            this.isValidICAO = false;
        }
    }


    clickClose(val) {
        this._dialogRef.close(val);
    }



    selectTag(tagId: number) {
        // alert(tagId);
    }

    clickSave() {

        this.processDates();

        this.checkValidations();

        if (this.hasAllReqValues) {
            var request = new AdvisoryModel;
            request.advisoryId = this.currentAdvisoryId;
            request.effectiveDate = new Date(this.effectiveDateText);
            request.expirationDate = new Date(this.expirationDateText);
            request.advisoryValidTimeStart = this.f.startTime.value;
            request.advisoryValidTimeEnd = this.f.endTime.value;
            // request.selectedTagTypeIds = this.selectedTagTypeIds?.join();
            request.selectedTagTypeIds = this.selectedTagTypeIds;

            request.countryID = this.f.countryID.value;
            request.airportID = this.f.airportID.value;
            request.stateProvinceID = Number(this.f.stateProvinceID.value);

            // request.hotelGUID = this.hasHotelTag == true ? this.filteredHotel.hotelGUID : null;
            // request.groundHandlerGUID = this.hasGroundHandlerTag == true ? this.groundHandlerGUID : null;
            // request.ssnVendorGUID = this.hasGroundHandlerTag == true ? this.ssnVendorGUID : null;
            // request.fuelSupplierGUID = this.hasFuelTag == true ? this.fuelSupplierGUID : null;
            // request.intoplaneAgent = this.hasFuelTag == true ? this.intoplaneAgent : null;


            request.hotelGUID = this.filteredHotel?.hotelGUID ? this.filteredHotel?.hotelGUID : null;
            request.groundHandlerGUID = this.groundHandlerGUID ? this.groundHandlerGUID : null;
            request.ssnVendorGUID = this.ssnVendorGUID ? this.ssnVendorGUID : null;
            request.fuelSupplierGUID = this.fuelSupplierGUID ? this.fuelSupplierGUID : null;
            request.intoplaneAgent = this.intoplaneAgent ? this.intoplaneAgent : null;

            request.issue = this.f.issue.value;
            request.resolution = this.f.resolution.value;
            request.isHeadlineNews = this.f.isHeadlineNews.value;
            request.isPublicNews = this.f.isPublicNews.value;
            request.title = this.f.inpTitle.value;
            request.weblink = this.f.inpWebLink.value;
            request.lk_AdvisoryRatingID = this.f.lk_AdvisoryRatingID.value;
            request.advisorySourceID = this.f.advisorySourceID.value;
            request.advisoryDocumentsList = this.advisoryDocumentsList;

            request.advisoryDocumentsList = this.advisoryDocumentsList;

            if (this.f.isActive.value == 'true' || this.f.isActive.value == true) {

                request.isActive = true
            }
            if (this.f.isActive.value == 'false' || this.f.isActive.value == false) {

                request.isActive = false
            }
            // request.isActive = this.f.isActive.value == '1' ? true : false;


            this._advisoryService.insertOrUpdateAdvisory<ResponseModel<boolean>>(request).subscribe(response => {
                if (response != null && response.model) {

                    this.showSuccessMsg = true;
                    if (response.model) {
                        this.successMsg = "Advisory is Saved Successfully.";
                    }
                    else if (this.dialogTitle == 'Edit') {
                        this.successMsg = "Advisory is Updated Successfully.";
                    }
                    else {
                        this.successMsg = "Advisory is Saved Successfully.";
                    }


                    // setTimeout(() => {
                    //     this.showSuccessMsg = false;
                    // }, 1000);
                        // if (isSaveAndDuplicate) {

                        //     this.clickDuplicate();
                        //     // this.controlValueChanged = true;

                        // }
                        // else
                        this._dialogRef.close(true);

                }
            });
        }



    }

    checkValidations() {
        this.hasAllReqValues = false;
        this.showSuccessMsg = false;

        if ((!this.hasDates) || this.f.effectiveDate.value == null) {
            this.errMsg = 'Effective Date Required';
            this.successMsg = '';
            return;
        }

        // if (this.hasFuelTag && (this.fuelSupplierGUID == '' || this.fuelSupplierGUID == null)) {
        //     this.errMsg = 'Fuel Supplier is Required';
        //     this.successMsg = '';
        //     return;
        // }
        // if (this.hasFuelTag && (this.intoplaneAgent == '' || this.intoplaneAgent == null)) {
        //     this.errMsg = 'Into Plane Agent is Required';
        //     this.successMsg = '';
        //     return;
        // }
        // if (this.hasGroundHandlerTag && (this.groundHandlerGUID == '' || this.groundHandlerGUID == null)) {
        //     this.errMsg = 'Ground Handler Vendor is Required';
        //     this.successMsg = '';
        //     return;
        // }
        // if (this.hasGroundHandlerTag && (this.ssnVendorGUID == '' || this.ssnVendorGUID == null)) {
        //     this.errMsg = 'SSN Vendor is Required';
        //     this.successMsg = '';
        //     return;
        // }
        if (!this.hasTags) {
            this.errMsg = 'Advisory Tag Type is Empty';
            this.successMsg = '';
            return;
        }
        if (!this.hasCountry) {
            this.errMsg = 'Country is a Required Value';
            this.successMsg = '';
            return;
        }
        if (this.f.lk_AdvisoryRatingID.value == 0) {
            this.errMsg = 'Rating is a Required Value';
            this.successMsg = '';
            return;
        }
        if (this.f.advisorySourceID.value == 0) {
            this.errMsg = 'Source is a Required Value';
            this.successMsg = '';
            return;
        }
        this.hasAllReqValues = true;
        this.errMsg = '';
        this.successMsg = 'Advisory Saved Successfully.';


    }

    getColumns(tags: any[]) {
        let columns = [];
        for (let i = 0; i < tags.length; i += this.numberOfItemsPerColumn) {
            columns.push(tags.slice(i, i + this.numberOfItemsPerColumn));
        }
        return columns;
    }

    //// documents start

    onFileClick(event) {
        this.errMsg = "";
        event.target.value = '';
    }

    removeFromUploaderQueue(filename: string) {
        var index = -1;
        for (let i = 0; i < this.uploader.queue.length; i++) {
            let fileItem = this.uploader.queue[i]._file;
            if (filename == fileItem.name) {
                index = i;
                break;
            }
        }

        if (index >= 0) {
            this.uploader.queue[index].remove();
        }

    }


    dropFile(event: any) {
        this.errMsg = "";
        //setTimeout(() => {
        //  this.errMsg="", 1000;
        //});

        if ((this.fileList.length + event.length) > this.maxFileUploadNumber) {
            this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
            this.finishAllUploads = true;
            return;
        }
        this.finishAllUploads = false;
        //this.fileList = [];
        var files = [];
        if (event.length > this.maxFileUploadNumber) {
            this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
            //this.finishAllUploads = true;
            //return;
            for (var i = 0; i < event.length; i++) {
                let filename = event[i].name;
                setTimeout(() => this.removeFromUploaderQueue(filename), 100);
            }
        }
        else {
            for (var i = 0; i < event.length; i++) {
                let filename = event[i].name;

                var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
                if (a) {
                    //if (event[i].size >= this.maxFileSize) {
                    //  this.errMsg = 'The file size "' + filename + '" is too big to upload.';
                    //  setTimeout(() => this.removeFromUploaderQueue(filename), 100);
                    //}
                    if (event[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {
                        if (this.errMsg != "") {
                            this.errMsg += "\n";
                        }
                        this.errMsg += 'Total file sizes are over limit';
                        setTimeout(() => this.removeFromUploaderQueue(filename), 100);
                    }

                }
                else {

                    this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
                    setTimeout(() => this.removeFromUploaderQueue(filename), 100);

                }
            }
        }

        if (this.errMsg != "") {
            this.finishAllUploads = true;
            return;
        }

        if (files.length > 0) {
            this.uploader.addToQueue(files);
        }

        if (this.uploader.queue.length == 0) {
            this.errMsg = "No file selected";
            this.finishAllUploads = true;
            return;
        }
        this.errMsg = "";
        this.uploadSubmit();
    }



    importFile(event: any) {
        this.errMsg = "";

        if ((this.fileList.length + event.target.files.length) > this.maxFileUploadNumber) {
            this.errMsg = 'The maximum upload files are ' + GlobalConstant.maxUploadFileNum.toString() + '.';
            this.finishAllUploads = true;
            return;
        }
        this.finishAllUploads = false;
        //this.fileList = [];
        var files = [];
        if (event.target.files.length > this.maxFileUploadNumber) {
            this.errMsg = 'The maximum upload files are ' + this.maxFileUploadNumber.toString() + '.';
            this.finishAllUploads = true;
            return;
        }
        else {
            for (var i = 0; i < event.target.files.length; i++) {
                let filename = event.target.files[i].name;

                var a = CustomValidators.checkValidFileType2(filename, this.allowedFileTypeList);
                if (a) {
                    if (event.target.files[i].size + this.totalMessageAttachmentFileSize >= this.maxFileSize) {//GlobalConstant.maxFileUploadSize) {             
                        this.errMsg = 'Total file sizes are over limit.';
                        this.finishAllUploads = true;
                        return;
                    }
                    else {
                        files.push(event.target.files[i]);

                    }
                }
                else {
                    this.finishAllUploads = true;
                    this.errMsg = 'The file type of "' + filename + '" is not allowed to upload.';
                    return;
                }
            }
        }

        if (this.errMsg != "") {
            this.finishAllUploads = true;
            return;
        }

        if (files.length > 0) {
            this.uploader.addToQueue(files);
        }

        if (this.uploader.queue.length == 0) {
            this.errMsg = "No file selected";
            this.finishAllUploads = true;
            return;
        }

        this.uploadSubmit();
    }

    uploadSubmit() {
        this.finishAllUploads = false;
        this.hasModifiedDocuments = true;
        this.uploader.uploadAll();
    }

    onProgressItem(fileItem: FileItem, progress: any) {
        //console.log(progress);
    };


    onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        let result = JSON.parse(response); //success server response

        if (result != null) {
            if (result.code == "200") {
                if (result.model.length > 0) {
                    result.model.forEach((item, index) => {
                        let obj = new FileModel();
                        let obj2 = new AdvisoryDocumentModel();
                        this.maxTempDocId += 1;
                        obj.fileName = item.fileName;
                        obj.filePath = item.filePath;
                        obj.fileSize = item.fileSize;
                        obj.bFile = item.bFile;
                        obj.mimeType = item.mimeType;
                        obj.fileType = item.fileType;
                        obj.tempId = this.maxTempDocId;
                        obj.isInternal = item.isInternal;
                        this.totalMessageAttachmentFileSize += item.fileSize * 1024;
                        this.fileList.push(obj);

                        obj2.documentName = item.fileName;
                        obj2.fileExtension = item.fileType;
                        obj2.documentSizeInKB = item.fileSize;
                        obj2.mimeType = item.mimeType;
                        obj2.bFile = item.bFile;
                        obj2.isActive = true;

                        obj2.isUploaded = true;
                        obj2.tempId = this.maxTempDocId;

                        this.advisoryDocumentsList.push(obj2);
                    });
                }
            }
            else {
                this.errMsg = result.message;
            }
        }
    }

    onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        let error = JSON.parse(response); //error server response
    }

    clickRemoveDocument(e: any, item: AdvisoryDocumentModel) {
        this.errMsg = "";
        this.isModified = true;
        this.hasModifiedDocuments = true;

        if (item.advisoryDocumentID != null) {

            let request = new AdvisoryDocumentModel();
            request.advisoryDocumentID = item.advisoryDocumentID;
            this._advisoryService.removeAdvisoryDocumentByadvdocId<ResponseModel<boolean>>(item).subscribe(response => {
                if (response != null && response.code == "200") {
                    if (response.model) {
                        // this.getTaskDocumentList();

                        // let index = this.advisoryDocumentsList.findIndex(x => x.advisoryDocumentID = item.advisoryDocumentID);
                        this.advisoryDocumentsList.filter(x => x.advisoryDocumentID == item.advisoryDocumentID)[0].isActive = false;
                        // this.advisoryDocumentsList.splice(index, 1);
                        // this.activeAdvisoryDocumentsList.splice(index, 1);
                    }
                }
                else {
                    if (response.code == "401") {
                        this._authService.signOut();
                    }

                }

            });
        }
        else {
            this.fileList.forEach((x, index) => {
                if (x.tempId == item.tempId) {
                    this.totalMessageAttachmentFileSize -= item.fileSize * 1024;
                    this.fileList.splice(index, 1);
                }
            }
            );
            this.advisoryDocumentsList.forEach(
                (x, index) => {
                    if (x.tempId == item.tempId && x.advisoryDocumentID == null) {
                        this.advisoryDocumentsList.splice(index, 1);
                    }
                }
            )
        }
    }

    getadvisoryDocumentsList() {
        let request = new AdvisoryModel()
        request.advisoryId = this.currentAdvisoryId;
        this._advisoryService.getAdvisoryByAdvisoryID<ResponseModel<AdvisoryModel[]>>(request.advisoryId).subscribe(response => {
            if (response != null && response.code == "200") {
                this.advisoryDocumentsList = [];
                this.advisoryDocumentsList = response.model[0].advisoryDocumentsList;
                if (this.advisoryDocumentsList.length > 0) {
                    this.hasExistingDocument = true;
                }
                if (this.fileList.length > 0) {
                    this.fileList.forEach(x => {
                        let obj = new AdvisoryDocumentModel();
                        obj.documentName = x.fileName;
                        obj.fileExtension = x.fileType;
                        obj.documentSizeInKB = x.fileSize;
                        obj.mimeType = x.mimeType;
                        obj.bFile = x.bFile;
                        this.advisoryDocumentsList.push(obj);
                    });
                }
            }
            else {
                if (response.code == "401") {
                    //this.errMsg = response.message
                    this._authService.signOut();
                }

            }

        })
    }

    previewDocument(e: any, advisoryDoc: AdvisoryDocumentModel) {
        let advisoryDocumentID = advisoryDoc.advisoryDocumentID;
        if (advisoryDoc.bFile != null) {

            // let att = this.advisoryDocumentsList.filter(x => x.advisoryDocumentID = advisoryDocumentID)[0];
            let byteArray = UtilityFunctions.base64ToArrayBuffer(advisoryDoc.bFile);
            let file = new Blob([byteArray], { type: advisoryDoc.mimeType });
            if (advisoryDoc.mimeType == "application/octet-stream") {
                saveAs(file, advisoryDoc.documentName);
            }
            else {
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }

        }


    }

    //// documents end


    // hasGroundHandlerTag: this.hasGroundHandlerTag, hasFuelTag: this.hasFuelTag, hasHotelTag: this.hasHotelTag,

    getVendorListComponent() {
        const config = new MatDialogConfig();
        config.panelClass = "custom-dialog-container"
        config.minWidth = "33em";
        // // config.maxWidth = "92em";//"1000px";
        config.height = "20em";//"1000px";
        config.restoreFocus = false;
        config.hasBackdrop = false;
        // config.position = { left: "100px" }; 
        config.data = {
            icao: this.f.icao.value, groundHandlerGUID: this.groundHandlerGUID, ssnVendorGUID: this.ssnVendorGUID, fuelSupplierGUID: this.fuelSupplierGUID,
            hasGroundHandlerTag: true, hasFuelTag: true, hasHotelTag: true,
            intoplaneAgent: this.intoplaneAgent, v: this._authService.getCurrentTimeNumber()
        };
        const dialogRef = this._dialog.open(AdvisoryVendorDialogComponent, config);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.groundHandlerGUID = result.groundHandlerGUID;
                this.ssnVendorGUID = result.ssnVendorGUID;
                this.fuelSupplierGUID = result.fslookupGUID;
                this.intoplaneAgent = result.intoplaneAgent;

                this.groundHandlerVendorName = result.groundHandlerVendorName;
                this.ssnVendorName = result.ssnVendorName;
                this.fuelSupplierName = result.fuelSupplierName;
                this.dataChanged = true;
            }
        });
    }



    getAdvisoryTagListComponent() {
        const config = new MatDialogConfig();
        config.panelClass = "custom-dialog-container4"
        config.minWidth = "39em";
        // // config.maxWidth = "92em";//"1000px";
        config.height = "40em";//"1000px";
        config.restoreFocus = false;
        config.hasBackdrop = false;
        // config.position = { left: "100px" }; 
        config.data = {
            hasGroundHandlerTag: this.hasGroundHandlerTag, hasFuelTag: this.hasFuelTag,
            icao: this.f.icao.value, advisoryTagsList: this.advisoryTagsList, v: this._authService.getCurrentTimeNumber()
        };
        let old_filteredAdvisoryTags = this.filteredAdvisoryTags;
        const dialogRef = this._dialog.open(AdvisoryTagsComponent, config);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.filteredAdvisoryTagsList = result;
                this.filteredAdvisoryTags = this.filteredAdvisoryTagsList.map(x => x.description).join(', ');
                this.selectedTagTypeIds = this.filteredAdvisoryTagsList.map(x => x.lookupId).join(',');


                this.filteredAdvisoryTagsDescList = this.filteredAdvisoryTags.split(',').map(description => description.trim());
                this.hasFuelTag = this.filteredAdvisoryTagsDescList.includes('Fuel');
                this.hasGroundHandlerTag = this.filteredAdvisoryTagsDescList.includes('Handling');
                this.hasHotelTag = this.filteredAdvisoryTagsDescList.includes('Hotels');

                this.hasTags = (this.filteredAdvisoryTags == '' || this.filteredAdvisoryTags == null) ? false : true;

                // this.hasTags = this.filteredAdvisoryTagsDescList?.length > 0 ? true : false;
                // this.hasIcao = (this.advisoryDetails.icao == '' || this.advisoryDetails.icao == null) ? false : true;

                if (old_filteredAdvisoryTags != this.filteredAdvisoryTags) {
                    this.dataChanged = true;
                }
            }
        });

    }

    getAdvisoryHotelListComponent() {
        const config = new MatDialogConfig();
        config.panelClass = "custom-dialog-container"
        config.minWidth = "60em";
        // // config.maxWidth = "92em";//"1000px";
        config.height = "41em";//"1000px";
        config.restoreFocus = false;
        config.hasBackdrop = false;
        // config.position = { left: "100px" }; 
        config.data = {
            hasHotelTag: true,//this.hasHotelTag,
            icao: this.f.icao.value, hotelList: this.hotelList, filteredHotel: this.filteredHotel, v: this._authService.getCurrentTimeNumber()
        };
        const dialogRef = this._dialog.open(AdvisoryHotelsComponent, config);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                this.filteredHotel = result;
                this.dataChanged = true;

            }
        });
    }


    clickRouteNew(route: string, display: string) {
        window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
    }


    clickRoute(routePage: string, display: string) {
        this.clickClose(true);
        this._router.navigate([routePage]);

    }

    openLink(link: string): void {
        if (link) {
            // Ensure the link contains a valid protocol (http/https)
            if (!/^https?:\/\//i.test(link)) {
                link = 'http://' + link;
            }
            window.open(link, '_blank'); // Open the link in a new tab
        }
    }
 
    clickAdvisoryDetails() {

        this.clickClose(true);
        if (this.isDialog) {
            
        

            //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
            const config = new MatDialogConfig();
            config.panelClass = "custom-dialog-container4b"
            config.minWidth = "64em";
            config.maxWidth = "83em";//"1000px";
            config.minHeight = "36.5em";//"1000px";
            config.maxHeight = "41em";//"1000px";
            config.restoreFocus = false;
            config.hasBackdrop = false;
            // config.position = { left: "100px" };

            let title = 'Add';
            let advisoryId = 0;

            config.data = { gsId: "", tripCodeId: "", dialogTitle: title, advisoryId: advisoryId, v: this._authService.getCurrentTimeNumber() };
            const dialogRef = this._dialog.open(AdvisoryDetailsComponent, config);
        }
    }


    updateCityAndStateList(countryID) {
        // this.countryID = this.f.countryID.value;
  
        this._commonService.getStateProvinceListByCountryID<ResponseModel<StateProvinceModel[]>>(countryID).subscribe(response => {
            if (response != null) {
                if (response.code == "200" && response.message == "") {
                    this.stateProvinceList = response.model;
                    // this.filteredState = this.stateControl.valueChanges.pipe(
                    //     startWith(''),
                    //     map(value => {
                    //         const name = typeof value === 'string' ? value : value?.stateProvinceName;
                    //         return name ? this._filterState(name as string) : this.stateProvinceList.slice();
                    //     }),
                    // );
                }
            }
        });
 
    }

    checkIssueChange() {
        let issue = this.f.issue.value;
        this.totalCharactersInIssue = issue.replace(/\s/g, '').length;

    }

    isValidHyperlink(url: string): boolean {
        const urlPattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-zA-Z0-9$\\-_.+!*\'(),;?&=@]|%[0-9a-fA-F]{2})+)(:[0-9]+)?)' + // domain name and port
            '(\\/[a-zA-Z0-9$\\-_.+!*\'(),;?&=:@%]*)*' + // path
            '(\\?[a-zA-Z0-9$\\-_.+!*\'(),;?&=:@%]*)?' + // query string
            '(#[a-zA-Z0-9$\\-_.+!*\'(),;?&=:@%]*)?$' // fragment locator
        );

        return urlPattern.test(url);
    }




}

