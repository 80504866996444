import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { DialogService } from '../services/dialog.service';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { PersonClassModel } from '../models/person-class.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { GlobalConstant } from '../common-utility/global-constant';



@Component({
  selector: 'app-delivery-comms-dialog',
  templateUrl: './delivery-comms-dialog.component.html',
  styleUrls: ['./delivery-comms-dialog.component.css']
})

export class DeliveryCommsDialogComponent implements OnInit {
  userType: string;
  delivery_comms_form: UntypedFormGroup;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  tripCodeGUID: string;
  groundStopGUID: string;
  groundStopTaskGUID: string;

  v: number = this._authService.getCurrentTimeNumber();
 
  personClassList: PersonClassModel[];
  gsList: GroundStopModel[];
  displayedColumnPerson: string[] = ['personType', 'contact','comms', 'tags'];
  displayedColumnVendor: string[] = ['contact', 'comms', 'tags'];
  nextGroundStopGUID: string;
  serviceTypeID: number;
  opsEmail: string;
  salesEmail: string;
  opsPhone: string;
  fpiAddress: string;
  upPII: boolean = false;
  constructor(private readonly _dialogRef: MatDialogRef<DeliveryCommsDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService) {

    this.tripCodeGUID = _data.tripCodeGUID;
    this.groundStopGUID = "";
    if (_data.groundStopGUID != null && _data.groundStopGUID != undefined && _data.groundStopGUID != "") {
      this.groundStopGUID = _data.groundStopGUID;
    }
    this.groundStopTaskGUID = "";
    if (_data.groundStopTaskGUID != null && _data.groundStopTaskGUID != undefined && _data.groundStopTaskGUID != "") {
      this.groundStopTaskGUID = _data.groundStopTaskGUID;
    }

    this.serviceTypeID = _data.serviceTypeID;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;

  }

  getAllData(): Observable<any[]> {
    let request = new GroundStopTaskModel();
    request.groundStopGUID = this.groundStopGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopTaskGUID = this.groundStopTaskGUID;
    request.serviceTypeID = this.serviceTypeID;
    request.nextGroundStopGUID = this.nextGroundStopGUID;
    let getPersonResponse= this._groundStopAdvancedService.getPersonCommsByTrip<ResponseModel<PersonClassModel[]>>(request);
    
    let getvendorResponse;// = this._groundStopAdvancedService.getVendorCommsByTrip<ResponseModel<GroundStopModel[]>>(request);
    if (this.userType == 'internal') {
      getvendorResponse = this._groundStopAdvancedService.getVendorCommsByTrip<ResponseModel<GroundStopModel[]>>(request);   
    }
    else {
      getvendorResponse = of(null);   
    }
    return forkJoin([getPersonResponse, getvendorResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;

    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.errMsg = "";
    this.msg = "";
    this.personClassList = [];
    this.gsList = [];
    this.opsEmail = GlobalConstant.opsEmail;
    this.salesEmail = GlobalConstant.salesEmail;
    this.opsPhone = GlobalConstant.opsPhoneWorldwide;
    this.fpiAddress = GlobalConstant.fpiOfficeAddress;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {        
          this.personClassList = responses[0].model;
          this.personClassList.forEach(x => {
            x.panelOpenState = true;
          });

        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.userType == 'internal') {
        if (responses[1] != null) {
          if (responses[1].code == "200" && responses[1].message == "") {
            this.gsList = responses[1].model;
            this.gsList.forEach(x => {
              x.groundStopTaskList.forEach(y => {
                y.panelOpenState = true;
              });
            });

          }
          else {
            if (responses[1].code == "401") {
              this._authService.signOut();
            }
          }
        }
      }
      
      this.initControls();
   
      this.showSpin = false;

    });
    
  }

  initControls() {
    this.delivery_comms_form = this._formBuilder.group({
      selected_comms: ['']
    });

  }

  get f() { return this.delivery_comms_form.controls; }

  openAll() {
    this.gsList.forEach(x => {
      x.groundStopTaskList.forEach(y => {
        y.panelOpenState = true;
      });
    });
    this.personClassList.forEach(x => {
      x.panelOpenState = true;
    });
  }

  closeAll() {
    this.gsList.forEach(x => {
      x.groundStopTaskList.forEach(y => {
        y.panelOpenState = false;
      });
    });
    this.personClassList.forEach(x => {
      x.panelOpenState = false;
    });
  }

  clickSelect(e: any, item: PersonCommsAddressModel) {
    let s: string;
    s = this.f.selected_comms.value;
    s = s.trim();
    
    if (s != "") {
      if (s.indexOf(item.address) == -1) {        
        s += "; ";       
        s += item.address;
      }
    }
    else {
      s += item.address;
    }
    
    this.f.selected_comms.setValue(s);
  }

  
  clickClose() {
    //this._dialogRef.close(this.isModified);
    this._dialogRef.close();
  }
  


}
