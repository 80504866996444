import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { IndustryTypeModel } from '../models/industry-type.model';
import { CustomerTypeModel } from '../models/customer-type.model';
import { CreditStatusModel } from '../models/credit-status.model';
import { MarketingRegionModel } from '../models/marketing-region.model';
import { ThirdPartyScreeningModel } from '../models/third-party-screening.model';
import { CustomerModel } from '../models/customer.model';
import { CreditCheckModel } from '../models/credit-check.model';
import { ReturnObjModel } from '../models/return-obj.model';


@Component({
  selector: 'app-customer-profile-main-edit-dialog',
  templateUrl: './customer-profile-main-edit-dialog.component.html',
  styleUrls: ['./customer-profile-main-edit-dialog.component.css']
})

export class CustomerProfileMainEditDialogComponent implements OnInit {
  @Input() dialogTitle: string;
  @Input() customerGUID: string;
  @Input() pageName: string;
  customer_profile_main_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  industryTypeList: IndustryTypeModel[];
  customerTypeList: CustomerTypeModel[];
  creditStatusList: CreditStatusModel[];
  marketingRegionList: MarketingRegionModel[];
  thirdPartyScreenList: ThirdPartyScreeningModel[];
  selectedCustomerTypeId: number;
  selectedIndustryTypeId: number;
  selectedAccountStatusId: number;
  selectedThirdPartyScreeningStatusId: number;
  selectedMarketingRegionId: number;
  selectedCreditStatusId: number;
  acctCreateDate: string;
  modifiedBy: string;
  modifiedDate: string;
  customerName: string;
  legalEntityName: string;
  dbaName: string;
  billingAccountName: string;
  isModified: boolean = false;
  stock_symbol: string;
  creditCheckList: CreditCheckModel[];
  selectedCreditCheckId: number;
  internalNote: string;
  showSuccessMsg: boolean = false;
  apisCarrierCode: string;
  showApisReportFirst: boolean = false;
  excludeCCOptions: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<CustomerProfileMainEditDialogComponent >, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, 
    private readonly _commonService: CommonService,
    private readonly _customerService: CustomerService,
    private readonly _dialogService: DialogService
  ) {
    this.customerGUID = data.customerGUID;
    this.dialogTitle = data.dialogTitle;
    this.pageName = data.pageName;

  }

  getAllData(): Observable<any[]> {
    let getCustomerTypeResponse = this._commonService.getCustomerTypeList<ResponseModel<CustomerTypeModel[]>>();
    let getIndustyTypeResponse = this._commonService.getIndustryTypeList<ResponseModel<IndustryTypeModel[]>>();
    let getCreditStatusResponse = this._commonService.getCreditStatusList<ResponseModel<CreditStatusModel[]>>();
    let getMarketingRegionResponse = this._commonService.getMarketingRegionList<ResponseModel<MarketingRegionModel[]>>();
    let getThirdPartyScreeningResponse = this._commonService.getThirdPartyScreeningList<ResponseModel<ThirdPartyScreeningModel[]>>();
    if (this.customerGUID != "") {
      let getCustomerProfileResponse = this._customerService.getCustomerBycId<ResponseModel<CustomerModel>>(this.customerGUID);
      return forkJoin([getCustomerTypeResponse, getIndustyTypeResponse, getMarketingRegionResponse, getThirdPartyScreeningResponse, getCreditStatusResponse, getCustomerProfileResponse]);
    }
    else
      return forkJoin([getCustomerTypeResponse, getIndustyTypeResponse, getMarketingRegionResponse, getThirdPartyScreeningResponse, getCreditStatusResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.selectedAccountStatusId = null;
    this.selectedIndustryTypeId = null;
    this.selectedCustomerTypeId = null;
    this.selectedThirdPartyScreeningStatusId = null;
    this.customerName = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.customerTypeList = responses[0].model;
          if (this.customerGUID == null || this.customerGUID == "") {
            this.customerTypeList.forEach((value, index) => {
              if (value.customerTypeID == 4 || value.customerTypeID == 5) this.customerTypeList.splice(index, 1);
            });
          }
       //   this.selectedCustomerTypeId = null;// this.customerTypeList[0].customerTypeID;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.industryTypeList = responses[1].model;
          //this.selectedIndustryTypeId = this.industryTypeList[0].industryTypeID;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

    if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.marketingRegionList = responses[2].model;
        //  this.selectedMarketingRegionId = this.marketingRegionList[0].marketingRegionID;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.thirdPartyScreenList = responses[3].model;
          //this.selectedThirdPartyScreeningStatusId = this.thirdPartyScreenList[0].thirdPartyScreeningStatusID;
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          this.creditStatusList = responses[4].model; 
          
          //this.selectedAccountStatusId = this.accountStatusList[0].accountStatusID;
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          let customer = new CustomerModel();
          customer = responses[5].model;
          this.customerName = customer.customerName;
          this.legalEntityName = customer.legalEntityName;
          this.dbaName = customer.operatorName;
          this.selectedAccountStatusId = customer.accountStatusId;
          this.selectedCustomerTypeId = customer.customerTypeId;
          this.acctCreateDate = customer.acctCreationDate;
          this.selectedIndustryTypeId = customer.industryId;
          this.selectedMarketingRegionId = customer.marketingRegionId;
          this.selectedCreditStatusId = customer.creditStatusId;
          this.internalNote = customer.internalNote;
          this.stock_symbol = customer.stockSymbol;
          this.apisCarrierCode = customer.apisCarrierCode;
          this.showApisReportFirst = customer.showApisReportFirst;
          this.excludeCCOptions = customer.excludeCCOptions;
        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }


      this.initControls();

      this.showSpin = false;


    });

  }

  initControls() {
    this.customer_profile_main_edit_form = this._formBuilder.group({
      customerName: [this.customerName,Validators.required],
      legalEntityName: [this.legalEntityName,Validators.required],
      dbaName: this.dbaName,
      customer_type: [this.selectedCustomerTypeId,Validators.required],
      sales_territory: this.selectedMarketingRegionId,
      industry_type: this.selectedIndustryTypeId,      
      stock_symbol: this.stock_symbol,
      acct_create_date: this.acctCreateDate,
      internal_note:this.internalNote,
      credit_status: this.selectedCreditStatusId,
      apis_carrier_code: [this.apisCarrierCode, Validators.pattern(/^[a-zA-Z0-9]{3}$/)],
      prelim_apis:this.showApisReportFirst,
      excludeCCOptions: this.excludeCCOptions

    });
  }

  get f() { return this.customer_profile_main_edit_form.controls; }


  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";

    if (this.customer_profile_main_edit_form.invalid || this.errMsg != "") {
      return;
    }

    this.loading = true;
    let obj = new CustomerModel();
    if (this.customerGUID == "") {
      obj.customerGUID = null;
    }
    else {
      obj.customerGUID = this.customerGUID;
    }
    obj.customerName = this.f.customerName.value;
    obj.legalEntityName = this.f.legalEntityName.value;
    obj.operatorName = this.f.dbaName.value;
    obj.customerTypeId = this.f.customer_type.value;
    obj.creditStatusId = this.f.credit_status.value;
    obj.pageName = "main";
    //obj.thirdPartyScreeningStatusId = this.f.third_party_screening_status.value;
    obj.marketingRegionId = this.f.sales_territory.value;
    obj.industryId = this.f.industry_type.value;
    obj.stockSymbol = this.f.stock_symbol.value;
    obj.apisCarrierCode = this.f.apis_carrier_code.value;
    obj.showApisReportFirst = this.f.prelim_apis.value;
    obj.excludeCCOptions = this.f.excludeCCOptions.value;

    this._customerService.saveCustomer<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.customerGUID = response.model;
         // this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          this.showSuccessMsg = true;
          this.isModified = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            let objClose = new ReturnObjModel();
            objClose.actionName = "edit"
            objClose.newId = this.customerGUID;
            this._dialogRef.close(objClose);

          }, 1000);
         
         
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })

  }

 

  clickClose() {
    let obj = new ReturnObjModel();
    if (this.isModified) {
      obj.actionName = "search";
    }
    this._dialogRef.close(obj);
  }

  clickEdit() {


    this._router.navigate(['customers/customer-profile-setup-edit', { tabName: 'gov', customerGuid: this.customerGUID }]);
    this._dialogRef.close();

  }
}
