<div class="divLeft" style="background-color: #f0f0f0; margin-top: 0em;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div  >
    <div>
      <div
        style="border-radius: 0.25em; padding: 0.1em 1em 0em 0.1em; width: 100%; display: inline-block; max-height: 5em; ">
        <div style="display: flex; justify-content: space-between;">
          <div style="display: flex; flex-direction: column;">
            <div style="display: inline-flex;">
              <i class="material-icons-outlined" style="margin-top: 0.2em; margin-right: 0.4em;">engineering</i>
              <h3>Maintenance</h3>
            </div>

          </div>
          <div style="display: flex;">

            <button mat-icon-button    (click)="openMaintenanceComponent()" >  <i class="material-icons-outlined" style="font-size:x-large;  ">arrow_circle_left</i> </button>    
            <button mat-icon-button mat-dialog-close><i class="material-icons" style="font-size: x-large;">close </i></button>
          </div>
        </div>
        <div> 
        </div>
        
      </div>
    </div>


    <div style="display: flex; flex-direction: column; background-color: white; padding: 0.5em;">
      <h4> Third Party Vendors</h4>

      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; background-color: #f2faff !important; color: Black !important; width: 15em;"  (click)="openVendorProfiles()"  >
          <i class="material-symbols-outlined" style="float: left;">Settings_account_box</i>
          Vendor Profile Setup
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Create new accounts, assigning roles, adding communication channels,, contacts, location profiles, documents and other essential details.
        </div>
      </div>


      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; background-color: #f2faff !important; color: Black !important; width: 15em;" (click)="openFuelSupplierProfileComponent()" >
          <i class="material-symbols-outlined" style="float: left;">local_gas_station</i>
          Fuel Supplier Profiles
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Administer fuel supplier profiles utilized for fuel quotations and fuel releases
        </div>
      </div>


      <div style="display: inline-flex; padding: 1em;">
        <button mat-button class="btn-low-no-shadow" style="margin-right: 1em; height: 3em; background-color: #f2faff !important; color: Black !important; width: 15em;" (click)="openHotelListComponent()" >
          <i class="material-icons-outlined" style="float: left;">airline_seat_individual_suite</i>
          Manage Hotel Profiles
        </button>
        <div style="font-weight: 300; font-size: small; width: 30em;">
          Upload hotel listings including full address, detail content, and essential contact details.
        </div>
      </div>



      
      
    </div>
  </div>
</div>
