<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-.75em;width:100%" class="d-inline-flex justify-content-between">
  <div class="d-inline-flex">
    <i class="material-icons mr-1">people</i>
    <h5>
      Handlers & SSN
    </h5>
  </div>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>
<div style="background-color:white;padding:.5em">
  <h5>{{title}}</h5>
  <table mat-table [dataSource]="vendorList" class="example-container">
    <tr>
      <ng-container matColumnDef="handler" style="text-align:left !important">
        <th mat-header-cell *matHeaderCellDef width="37" style="text-align:left !important">
          Handler
        </th>
        <td mat-cell *matCellDef="let element" style="text-align:left !important">
          <div><i style="font-size: medium;width:1em" class="material-symbols-outlined filled yellow">{{element.isPreferred? 'grade':''}}</i> {{element.vendorName}} </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="ssn">
        <th mat-header-cell *matHeaderCellDef width="37%" style="text-align:left !important">
          SSN
        </th>
        <td mat-cell *matCellDef="let element" style="text-align:left !important"><div *ngIf="element.relatedVendors==null">None</div><div *ngIf="element.relatedVendors!=null" ><i style="font-size: medium;width:1em" class="material-symbols-outlined filled yellow">{{element.relatedVendors[0].isPreferred? 'grade':''}}</i> {{element.relatedVendors[0].vendorName}}</div></td>
      </ng-container>
      <ng-container matColumnDef="payment">
        <th [hidden]="userType!='i'" mat-header-cell *matHeaderCellDef width="20%" style="text-align:left !important">
          Method of Payment
        </th>
        <td [hidden]="userType!='i'" mat-cell *matCellDef="let element" style="text-align:left !important">{{element.vendorMethodOfPaymentDescription}}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
          Action
        </th>
        <td mat-cell *matCellDef="let element">
          <a *ngIf="!(element.vendorGUID==handler &&  (element.relatedVendors!=null && element.relatedVendors[0].vendorGUID==ssn || element.relatedVendords==null && ssn==null))" mat-button class="btn-high grid" style="align-self:center" (click)="updateVendors(element)">
            Select
          </a>
          <span *ngIf="element.vendorGUID==handler && (element.relatedVendors!=null && element.relatedVendors[0].vendorGUID==ssn || element.relatedVendors==null && ssn==null)">Selected</span>
        </td>
      </ng-container>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
  </table>

</div>

