import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GlobalConstant } from '../common-utility/global-constant';
import { GroundStopService } from '../services/ground-stop.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { PersonModel } from '../models/person.model';
import { Arrival_DepartureStatusModel } from '../models/arrival_departure-status.model';
import { FARTypeModel } from '../models/far-type.model';
import { GroundStopEditorDialogComponent } from './ground-stop-editor-dialog.component';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { AirportModel } from '../models/airport.model';
import { AirportService } from '../services/airport.service';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopAlternateAirportModel } from '../models/ground-stop-alternate-airport.model';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopAlternateAirportEditComponent } from '../ground-stop-advanced/ground-stop-alternate-airport-edit.component';
import { GenerateMessageTemplateComponent } from './generate-message-template.component';
import { DistanceTimeModel } from '../models/distance-time.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { ReturnObjModel } from '../models/return-obj.model';
import { DatePipe } from '@angular/common';
import { TripAuditDialogComponent } from './trip-audit-dialog.component';
import { TripRevisionModel } from '../models/trip-revision.model';

@Component({
  selector: 'app-ground-stop-details-dialog',
  templateUrl: './ground-stop-details-dialog.component.html',
  styleUrls: ['./ground-stop-details-dialog.component.css']
})

export class GroundStopDetailsDialogComponent implements OnInit {
  isModified: boolean = false;
  showSpin: boolean = false;
  groundStopGUID: string;
  nextGroundStopGUID: string;
  isFirstGroundStop: boolean;
  userType: string;
  customerGUID: string;  
  tripCodeGUID: string;
  tripCodeType: string; 
  ground_stop_details_form: UntypedFormGroup; 
  loading: boolean = false;
  //reloadChildPage: boolean;

  aircraftGUID: string;
  clientName: string;
  validatedAirport: boolean = false;
  icao: string;
  oldICAO: string;
  iata: string;
  //mapLink: string;
  registration: string;
  aircraftManufacturer: string;
  aircraftModel: string;
  aircraftInfo: string;
  ac_speed: number;
  trip_code: string;
  airportName: string;
  airportState_CountryCode: string;
  runwayMaxLength: string;
  elevation: string;
  utc_conversion: string;
  is_tech_stop: number
  maxGroundStopSequenceID: number;
  currentGroundStopSequenceID: number;
  submittedArrDate: boolean = false;
  arrivalDateUTC: Date;
  arrivalTimeUTC: string;
  arrivalDateLocal: string;
  arrivalTimeLocal: string;
  arrStartAt: Date;
  arr_CallSign: string;
  arr_FARType: string;
  submittedDepDate: boolean = false;
  departureDateUTC: Date;
  departureTimeUTC: string;
  departureDateLocal: string;
  departureTimeLocal: string;
  depStartAt: Date;
  dep_CallSign: string;
  ete: string;
  next_arrivalDateUTC: string;
  next_arrivalTimeUTC: string;
  errMsg: string;
  msg: string;
  farTypeList: FARTypeModel[];
  arrival_departureStatusList: Arrival_DepartureStatusModel[];
  groundStopTypeList: GroundStopTypeModel[];
  selectedDepFARTypeID: number;
  selectedArrivalStatusID: number;
  selectedDepartureStatusID: number;
  selectedArrivalStatusColor: string;
  selectedDepartureStatusColor: string;
  selectedGroundStopTypeGUID: string;
  groundStopType: string;
  groundStopTypeColor: string;
  groundStopList: GroundStopModel[];
  selectedGroundStop: GroundStopModel;
  airportList: AirportModel[];
  duplicateIATAErrMsg: string;
  wrongAirportErrMsg: string;
  latitude: number;
  longitude: number;
  
  nextGroundStopTypeGUID: string = "";
  nextGroundStopTypeDesc: string = "";
  nextGroundStopTypeColor: string = "";
  @ViewChild('arrivalDateUTC') arrivalDateUTCRef: ElementRef;
  @ViewChild('departureDateUTC') departureDateUTCRef: ElementRef;
  
  cancelAllServices: boolean = false;
  controlValueChanged: boolean = false;
  arr_pets: boolean;
  arr_weapons: boolean;
  dep_pets: boolean;
  dep_weapons: boolean;
  currentIndex: number;

  next_arrivalLatitude: number = null;
  next_arrivalLongitude: number = null;
  updateNextArrivalDateTime: boolean;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  countryGUID: string;
  
  showSuccessMsg: boolean = false;
  
  prevICAO: string;
  nextICAO: string;
  hasTask: boolean;
  successMsg: string = "Your changes have been saved";
  returnObj = new ReturnObjModel();
  lockdownTrip: boolean = false;
  originalGroundStopTypeGUID: string;
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  upPII: boolean = false;
  confirmed: boolean = false;
  auditHr: number = 1;
  usernameList: TripRevisionModel[] = [];
  internalUsernameList: TripRevisionModel[] = [];
  externalUsernameList: TripRevisionModel[] = [];
  showMoreInternalUsernameList: boolean = false;
  showMoreExternalUsernameList: boolean = false;

  constructor(private readonly _dialogRef: MatDialogRef<GroundStopDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private readonly _dialogService: DialogService,
    private _router: Router, private readonly _groundStopService: GroundStopService, private readonly _airportService: AirportService, private readonly _route: ActivatedRoute,
    private readonly _dialog: MatDialog, private readonly _groundStopAdvancedService: GroundStopAdvancedService
  ) {
    this.groundStopGUID = _data.groundStopGUID;
    this.nextGroundStopGUID = _data.nextGroundStopGUID;
    this.isFirstGroundStop = _data.isFirstGroundStop; 
    this.tripCodeGUID = _data.tripCodeGUID;
    this.tripCodeType = _data.tripCodeType;

  }

  getAllData(): Observable<any[]> {
    let request = new GroundStopModel();
    request.groundStopGUID = this.groundStopGUID;
    //request.customerGUID = this.customerGUID;
    request.tripCodeGUID = this.tripCodeGUID;
    request.nextGroundStopGUID = this.nextGroundStopGUID;
    let getFARListResponse = this._groundStopService.getFARTypeList();
    let getArrival_DepartureStatusList = this._groundStopService.getArrivalDepartureStatusList();
    let getGroundStopTypeList = this._groundStopService.getGroundStopTypeList();
    let getGSResponse = this._groundStopService.getGroundStopBygsId(request);
    
    return forkJoin([getFARListResponse, getArrival_DepartureStatusList, getGroundStopTypeList, getGSResponse]);
  }


  ngOnInit() {
    this.setInit();    
  }

  setInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
    this.duplicateIATAErrMsg = "";
    this.wrongAirportErrMsg = "";
    this.groundStopList = [];
    this.icao = "";
    this.iata = "";
    //this.mapLink = "";
    this.registration = "";
    this.aircraftManufacturer = "";
    this.aircraftModel = "";
    this.aircraftInfo = "";
    this.ac_speed = 0;
    this.trip_code = "";
    this.airportName = "";
    this.airportState_CountryCode = "";
    this.runwayMaxLength = "";
    this.elevation = "";
    this.utc_conversion = "";
    //this.call_sign = "";
    this.maxGroundStopSequenceID = 0;
    this.currentGroundStopSequenceID = 0;
    this.arrivalTimeUTC = "";
    this.arrivalTimeLocal = "";
    this.departureTimeUTC = "";
    this.departureTimeLocal = "";
    this.ete = "";
    this.is_tech_stop = 0;
    //this.next_dest_icao = "";
    //this.next_arrivalDateLocal = "";
    this.next_arrivalDateUTC = "";
    this.next_arrivalTimeUTC = "";
    //this.next_arrivalTimeLocal = "";
    //this.next_runwayMaxLength = "";
    //this.next_arrivalAirportName = "";
    //this.next_elevation = "";
    //this.next_utc_conversion = "";
    this.arr_CallSign = "";
    this.dep_CallSign = "";
    this.arr_FARType = "";
    this.farTypeList = [];
    this.arrival_departureStatusList = [];
    this.groundStopTypeList = [];   
    this.controlValueChanged = false;
    this.groundStopType = "";
    this.groundStopTypeColor = "";
    this.nextGroundStopTypeGUID = "";
    this.nextGroundStopTypeDesc = "";
    this.nextGroundStopTypeColor = "";
    this.countryGUID = "";
    this.arr_pets = false;
    this.arr_weapons = false;
    this.dep_pets = false;
    this.dep_weapons = false;
    this.currentIndex = 0;
    this.latitude = null;
    this.longitude = null;
    this.next_arrivalLatitude = null;
    this.next_arrivalLongitude = null;
    this.updateNextArrivalDateTime = false;
    this.oldICAO = "";
    //this.leg = "";
    this.hasTask = false;
    this.originalGroundStopTypeGUID = "";
    this.lockdownTrip = false;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.farTypeList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.arrival_departureStatusList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.groundStopTypeList = responses[2].model;
          switch (this.tripCodeType) {
            case "Estimate":
              this.groundStopTypeList = this.groundStopTypeList.filter(x => x.groundStopTypeDescription.indexOf('Estimate') != -1 || x.groundStopTypeDescription == "Cancellation")
              break;
            case "Consultation":
              this.groundStopTypeList = this.groundStopTypeList.filter(x => x.groundStopTypeDescription.indexOf('Consultation') != -1 || x.groundStopTypeDescription == "Cancellation")
              break;
            default:
              this.groundStopTypeList = this.groundStopTypeList.filter(x => x.groundStopTypeDescription != "Estimates" && x.groundStopTypeDescription != "Consultation")
              break;
          }
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].model != null) {
          let gs = new GroundStopModel();
          gs = responses[3].model;
          this.clientName = gs.customerName;
          this.icao = gs.icao;
          this.oldICAO = this.icao;
          this.iata = gs.iata;
          this.latitude = gs.latitude;
          this.longitude = gs.longitude;
          //if (gs.latitude != null && gs.longitude != null) {
          //  this.latitude = gs.latitude;
          //  this.longitude = gs.longitude;
          //  this.mapLink = "https://maps.google.com/maps?q=" + gs.latitude.toString() + "," + gs.longitude.toString() + "&t=k&z=13&ie=UTF8&iwloc=&output=embed";
          //}
          //else {
          //  this.mapLink = "https://maps.google.com/maps?q=" + this.icao + "&t=k&z=13&center=29.98444444,-95.34138889&ie=UTF8&iwloc=&output=embed";
          //}
          if (this.nextGroundStopGUID == "") {
            this.nextGroundStopGUID = gs.nextGroundStopGUID;
          }
          this.aircraftGUID = gs.aircraftGUID;
          this.registration = gs.registration;
          this.aircraftManufacturer = gs.aircraftManufacturer;
          this.aircraftModel = gs.aircraftModel;
          this.aircraftInfo = gs.aircraftInfo;
          this.ac_speed = gs.speed;
          if (this.ac_speed == 0) {
            this.ac_speed = 460;
          }
          this.selectedGroundStopTypeGUID = gs.groundStopTypeGUID.toLowerCase();
          this.originalGroundStopTypeGUID = this.selectedGroundStopTypeGUID;
          this.groundStopType = gs.groundStopTypeDescription;
          this.groundStopTypeColor = gs.groundStopTypeColor;
          this.trip_code = gs.tripCode;
          this.airportName = gs.airportName;
          this.airportState_CountryCode = gs.airportState_CountryCode;
          this.runwayMaxLength = "";
          if (gs.runwayMaxLength > 0) {
            this.runwayMaxLength = "Max Runway (" + gs.runwayMaxLength.toString() + "ft)";
          }
          this.elevation = "";//gs.elevation;
          if (gs.elevation > 0) {
            this.elevation = "Elevation (" + gs.elevation.toString() + "ft)";
          }

          this.maxGroundStopSequenceID = gs.maxGroundStopSequenceID;
          this.currentGroundStopSequenceID = gs.groundStopSequenceID;
          if (gs.isTechStop) {
            this.is_tech_stop = 1;
          }
          else {
            this.is_tech_stop = 0;
          }
          this.arrivalDateUTC = gs.arrivalDateUTC;
          this.arrivalTimeUTC = gs.arrivalTimeUTC;
          this.arrivalDateLocal = gs.arrivalDateTextLocal;
          this.arrivalTimeLocal = gs.arrivalTimeLocal;
          this.arrStartAt = gs.arrStartAt;
          this.departureDateUTC = gs.departureDateUTC;
          this.departureTimeUTC = gs.departureTimeUTC;
          this.departureDateLocal = gs.departureDateTextLocal;
          this.departureTimeLocal = gs.departureTimeLocal;
          this.depStartAt = gs.depStartAt;
          this.ete = gs.ete;
          this.confirmed = gs.eteChanged;
          this.arr_CallSign = gs.arrivalCallSign;
          this.arr_FARType = gs.arrivalFARTypeDescription;
          this.dep_CallSign = gs.departureCallSign;
          this.selectedDepFARTypeID = gs.departureFARTypeID;
          this.countryGUID = gs.countryGUID;
          this.arr_pets = gs.arrivalPetsOnBoard;
          this.arr_weapons = gs.arrivalWeaponsOnBoard;
          this.dep_pets = gs.departurePetsOnBoard;
          this.dep_weapons = gs.departureWeaponsOnBoard;
          //if (this.selectedDepFARTypeID == 0) {
          //  this.selectedDepFARTypeID = this.farTypeList[0].farTypeID;
          //}
          this.selectedArrivalStatusID = gs.arrivalStatusID;
          if (this.selectedArrivalStatusID == 0) {
            this.selectedArrivalStatusID = this.arrival_departureStatusList[0].arrival_DepartureStatusID;
          }
          this.selectedArrivalStatusColor = this.arrival_departureStatusList.find(x => x.arrival_DepartureStatusID == this.selectedArrivalStatusID).colorCode;
          this.selectedDepartureStatusID = gs.departureStatusID;
          if (this.selectedDepartureStatusID == 0) {
            this.selectedDepartureStatusID = this.arrival_departureStatusList[0].arrival_DepartureStatusID;
          }
          this.selectedDepartureStatusColor = this.arrival_departureStatusList.find(x => x.arrival_DepartureStatusID == this.selectedDepartureStatusID).colorCode;
          this.prevICAO = gs.prevICAO;
          this.nextICAO = gs.nextICAO;
          this.next_arrivalDateUTC = gs.nextArrivalDateUTC;
          this.next_arrivalTimeUTC = gs.nextArrivalTimeUTC;
          this.next_arrivalLatitude = gs.nextLatitude;
          this.next_arrivalLongitude = gs.nextLongitude;
          this.hasTask = gs.hasTask;
          this.lockdownTrip = gs.lockdownTrip;
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }
    
      //if (this.nextGroundStopTypeDesc != "" && this.nextGroundStopTypeDesc == "Contingency") {
      //  this.selectedGroundStopTypeGUID = this.nextGroundStopTypeGUID.toLowerCase();
      //  this.groundStopTypeColor = this.nextGroundStopTypeColor;
      //}

      this.initControls();

      this.checkFormControlValueChanged();

      this.showSpin = false;

    });

  }

  initControls() {
    this.ground_stop_details_form = this._formBuilder.group({
      //clientName: [this.clientName],
      icao: [this.icao, Validators.compose([
        Validators.required,
        Validators.pattern("^[-a-zA-Z0-9\*]*$")]
      )],
      ground_stop_type_select: [this.selectedGroundStopTypeGUID],
      tech_stop_select: [this.is_tech_stop],
      arrivalDateUTC: [this.arrivalDateUTC],
      /*arrivalTimeUTC: [this.arrivalTimeUTC, Validators.pattern("^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$")],*/
      arrivalTimeUTC: [this.arrivalTimeUTC],
      arrivalDateLocal: [this.arrivalDateLocal],
      arrivalTimeLocal: [this.arrivalTimeLocal],
      arrival_status_select: [this.selectedArrivalStatusID],
      departureDateUTC: [this.departureDateUTC],
      //departureTimeUTC: [this.departureTimeUTC, Validators.pattern("^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$")],
      departureTimeUTC: [this.departureTimeUTC],
      departureDateLocal: [this.departureDateLocal],
      departureTimeLocal: [this.departureTimeLocal],
      dep_fartype_select: [this.selectedDepFARTypeID, Validators.required],
      dep_CallSign: [this.dep_CallSign],
      departure_status_select: [this.selectedDepartureStatusID],
      /*ete: [this.ete, Validators.pattern("^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$")],  */
      ete: [this.ete],  
      dep_pets: [this.dep_pets],
      dep_weapons: [this.dep_weapons],
      confirmed: [this.confirmed],
    });

    setTimeout(() => {
      this.f.departureDateUTC.setErrors(null);
      this.f.arrivalDateUTC.setErrors(null);
    }, 1000
    );
    this.f.departureDateUTC.setErrors({ validateCompareArrival_DepartureDateTime: true});
    this.f.arrivalDateUTC.setErrors(null);

    this.f.arrivalDateUTC.updateValueAndValidity();

  }

  get f() { return this.ground_stop_details_form.controls; }

  checkFormControlValueChanged(): void {
    //this.ground_stop_details_form.get("icao").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    this.ground_stop_details_form.get("ground_stop_type_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_details_form.get("tech_stop_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_details_form.get("arrivalDateUTC").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_details_form.get("arrivalTimeUTC").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_details_form.get("arrival_status_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_details_form.get("departureDateUTC").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_details_form.get("departureTimeUTC").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_details_form.get("departure_status_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_details_form.get("dep_fartype_select").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_details_form.get("dep_CallSign").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_details_form.get("ete").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    //this.ground_stop_details_form.get("arr_pets").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    //this.ground_stop_details_form.get("arr_weapons").valueChanges.subscribe(x => {
    //  this.controlValueChanged = true;
    //});
    this.ground_stop_details_form.get("dep_pets").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
    this.ground_stop_details_form.get("dep_weapons").valueChanges.subscribe(x => {
      this.controlValueChanged = true;
    });
  }

  icaoChange(e: any) {
    if (e.target.value != "") {
      
      this.validateAirport().subscribe(res => {
        if ((res != null || res != undefined) && res == true) {

        }

      });

    }
  }

  validateAirport(): Observable<boolean> {
    
    return new Observable<boolean>(ob => {
      this._authService.updateAccessTime();
      this.duplicateIATAErrMsg = "";
      this.wrongAirportErrMsg = "";
      let request = new AirportModel();
      request.route = this.f.icao.value;
      try {
        this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            this.airportList = response.model;
            if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
              this.airportList.forEach(x => {
                this.f.icao.setValue(x.icao);
                this.icao = x.icao;
                this.iata = x.iata;
                this.airportName = x.airportName;
                this.airportState_CountryCode = x.airportLocation;
                this.runwayMaxLength = "";
                if (x.runwayMaxLength > 0) {
                  this.runwayMaxLength = "Max Runway (" + x.runwayMaxLength.toString() + "ft)";
                }
                this.elevation = "";
                if (x.elevation > 0) {
                  this.elevation = "Elevation (" + x.elevation.toString() + "ft)";
                }
              });
              this.validatedAirport = false;

            }
            if (this.airportList[0].wrongAirportErrMsg != "") {
              this.wrongAirportErrMsg = "Invalid airport"; // + this.airportList[0].wrongAirportErrMsg;
              this.f.icao.setErrors({ wrongAirportValidate: true });
              this.iata = "";
              this.airportName = "";
              this.airportState_CountryCode = "";
              this.runwayMaxLength = "";
              this.elevation = "";

              this.validatedAirport = true;
            }
            if (this.airportList[0].duplicateIATAErrMsg != "") {
              this.iata = "";
              this.airportName = "";
              this.airportState_CountryCode = "";
              this.runwayMaxLength = "";
              this.elevation = "";
              this.duplicateIATAErrMsg = "Duplicated IATAs: " + this.airportList[0].duplicateIATAErrMsg + ". Please change to use ICAO.";
              this.f.icao.setErrors({ duplicateIATAValidate: true });
              this.validatedAirport = true;
            }
            if (this.wrongAirportErrMsg != "" || this.duplicateIATAErrMsg != "") {
              ob.next(false);
            }
            else {
              ob.next(true);
            }
          }
          else {
            if (response.code == "401") {
              //this.errMsg = response.message
              this._authService.signOut();
            }
            else {
              ob.next(null);
            }
          }
        })
      }
      catch (error) {
        ob.next(null);
      }

    });

  }

  gsTypeChange(e: any) {
    let gstype = this.groundStopTypeList.filter(v => v.groundStopTypeGUID.toLowerCase() == this.f.ground_stop_type_select.value.toLowerCase())[0].groundStopTypeDescription;
    let gsTypeColor = this.groundStopTypeList.filter(v => v.groundStopTypeGUID.toLowerCase() == this.f.ground_stop_type_select.value.toLowerCase())[0].colorCode;
    //if (gstype == "Cancellation") {
    if (this.f.ground_stop_type_select.value.toLowerCase() == this.cancellationGroundStopTypeGUID) {
      if (this.hasTask==true) {
        this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to cancel the stop and all related services?");
        this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
          this.eventSubscription.unsubscribe();
          if (result) {
            this.cancelAllServices = true;
            this.groundStopType = gstype;
            this.groundStopTypeColor = gsTypeColor;
          }
          else {
            this.cancelAllServices = false;
            this.f.ground_stop_type_select.setValue(this.selectedGroundStopTypeGUID);
            this.groundStopType = this.groundStopTypeList.filter(v => v.groundStopTypeGUID.toLowerCase() == this.f.ground_stop_type_select.value.toLowerCase())[0].groundStopTypeDescription;
            this.groundStopTypeColor = this.groundStopTypeList.filter(v => v.groundStopTypeGUID.toLowerCase() == this.f.ground_stop_type_select.value.toLowerCase())[0].colorCode;
          }

        });
      }
      else {
        this.groundStopType = gstype;
        this.groundStopTypeColor = gsTypeColor;
      }
    }
    else {
      this.groundStopType = gstype;
      this.groundStopTypeColor = gsTypeColor;
      if (this.originalGroundStopTypeGUID == this.cancellationGroundStopTypeGUID && this.f.ground_stop_type_select.value.toLowerCase() != this.cancellationGroundStopTypeGUID) {
        if (this.nextGroundStopGUID != "" && this.f.departureDateUTC.value != null && this.f.departureTimeUTC.value != "") {
          if (this.f.ete.value != "") {
            if (this.ground_stop_details_form.controls['ete'].invalid == false) {
              this.updateNextArrivalDateTime = true;
            }
            else {
              return;
            }
          }
          else {
            this.calculateNextArrivalDateTime().subscribe(res => {
              if ((res != null || res != undefined) && res == true) {

              }

            });
          }
        }
       
      }

    }

  }

  dateUTCChange(dateType: string, e: any) {
    //this.reloadChildPage = false;
    if (dateType == "A") {
      this.submittedArrDate = true;
      if (e.target.value != "" && e.target.value != null) {
        let vd = CustomValidators.isValidDate(e.target.value);
        if (!vd) {
          this.f.arrivalDateUTC.setErrors({ isValidDate: false });
        }
        else {
          if (new Date(e.target.value).getUTCFullYear() >= (new Date().getUTCFullYear() - 1)) {
            let v = true;
            if (this.f.departureDateUTC.value != null) {
              v = this.validateCompareArrival_DepartureDateTime();
            }
            if (v == true) {
              this.f.arrivalDateUTC.setErrors(null);
              this.f.arrivalTimeUTC.setErrors(null);
              this.f.departureDateUTC.setErrors(null);
              this.f.departureTimeUTC.setErrors(null);
              // add to cal localtime
              if (this.f.arrivalTimeUTC.value != "") {
                let udt = e.target._elementRef.nativeElement.value + " " + this.f.arrivalTimeUTC.value;
                this.getAirportLocalTime(udt, dateType).subscribe(res => {
                  if ((res != null || res != undefined) && res == true) {

                  }

                });
              }
              else {
                this.f.arrivalDateLocal.setValue('');
                this.f.arrivalTimeLocal.setValue('');
              }
              // add to cal localtime end
            }
            else {
              this.f.arrivalDateUTC.setErrors({ validateCompareArrival_DepartureDateTime: false });
            }
          }
          else {
            this.f.arrivalDateUTC.setErrors({ isValidDate: false });
          }
        }
      }
      else {
        if (e.target._elementRef.nativeElement.value != "") {
          this.f.arrivalDateUTC.setErrors({ isValidDate: false });
        }
        else {
          this.f.arrivalDateUTC.setErrors(null);
          this.f.arrivalDateLocal.setValue('');
          this.f.arrivalTimeLocal.setValue('');
        }
      }
    }
    if (dateType == "D") {
      this.submittedDepDate = true;
      if (e.target.value != "" && e.target.value != null) {
        let vd = CustomValidators.isValidDate(e.target.value);
        if (!vd) {
          this.f.departureDateUTC.setErrors({ isValidDate: false });
        }
        else {
          if (new Date(e.target.value).getUTCFullYear() >= (new Date().getUTCFullYear() - 1)) {
            let v = true;
            if (this.f.arrivalDateUTC.value != null) {
              v = this.validateCompareArrival_DepartureDateTime();
            }
            if (v == true) {
              this.f.departureDateUTC.setErrors(null);
              this.f.departureTimeUTC.setErrors(null);
              this.f.arrivalDateUTC.setErrors(null);
              this.f.arrivalTimeUTC.setErrors(null);
              // add to cal localtime
              if (this.f.departureTimeUTC.value != "") {
                let udt = e.target._elementRef.nativeElement.value + " " + this.f.departureTimeUTC.value;
                this.getAirportLocalTime(udt, dateType).subscribe(res => {
                  if ((res != null || res != undefined) && res == true) {

                  }

                });
              }
              else {
                this.f.departureDateLocal.setValue('');
                this.f.departureTimeLocal.setValue('');
              }
              // add to cal localtime end
              if (this.f.departureTimeUTC.value != "" && this.f.departureTimeUTC.value != null && this.nextICAO != "" && (this.next_arrivalDateUTC == "" || this.next_arrivalTimeUTC == "")) {
                this.calculateNextArrivalDateTime().subscribe(res => {
                  if ((res != null || res != undefined) && res == true) {

                  }

                });
              }
              else {
                if (this.next_arrivalDateUTC != "" && this.next_arrivalTimeUTC != "") {
                  //this._dialogService.openAlertDialog("Changing departure date time may impact next arrival date time.");
                  //this.confirmDialogRef = this._dialogService.openConfirmDialog("You updated the Departure Date / Time it might impact next Arrival Date time. Do you want to update next arrival date / time?");
                  //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
                  //  this.eventSubscription.unsubscribe();
                  //  if (result) {
                  //    if (this.f.ete.value != "") {
                  //      if (this.ground_stop_details_form.controls['ete'].invalid == false) {
                  //        this.updateNextArrivalDateTime = true;
                  //      }
                  //      else {
                  //        return;
                  //      }
                  //    }
                  //    else {
                  //      this.calculateNextArrivalDateTime().subscribe(res => {
                  //        if ((res != null || res != undefined) && res == true) {

                  //        }

                  //      });
                  //    }

                  //  }
                  //});
                 
                  if (this.f.ete.value != "") {
                    if (this.ground_stop_details_form.controls['ete'].invalid == false) {
                      this.updateNextArrivalDateTime = true;
                    }
                    else {
                      return;
                    }
                  }
                  else {
                    this.calculateNextArrivalDateTime().subscribe(res => {
                      if ((res != null || res != undefined) && res == true) {

                      }

                    });
                  }
                  
                }
              }
            }
            else {
              this.f.departureDateUTC.setErrors({ validateCompareArrival_DepartureDateTime: false });
            }
          }
          else {
            this.f.departureDateUTC.setErrors({ isValidDate: false });
          }
          
        }
      }
      else {
        if (e.target._elementRef.nativeElement.value != "") {
          this.f.departureDateUTC.setErrors({ isValidDate: false });
        }
        else {
          this.f.departureDateUTC.setErrors(null);
          this.f.departureDateLocal.setValue('');
          this.f.departureTimeLocal.setValue('');
          this.f.ete.setValue('');
          if (this.nextICAO != "") {
            
            if (this.next_arrivalDateUTC != "" && this.next_arrivalTimeUTC != "") {
              //this.confirmDialogRef = this._dialogService.openConfirmDialog("You updated the Departure Date / Time it might impact next Arrival Date time. Do you want to update next arrival date / time?");
              //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
              //  this.eventSubscription.unsubscribe();
              //  if (result) {
              //    this.updateNextArrivalDateTime = true;
              //    this.next_arrivalDateUTC = "";
              //    this.next_arrivalTimeUTC = "";

              //  }
              //});
              this.updateNextArrivalDateTime = true;
              this.next_arrivalDateUTC = "";
              this.next_arrivalTimeUTC = "";
            }
            
          }
          
        }

      }
    }


    this.checkWhetherEteIsDisabled();
  }

  timeUTCChange(dateType: string, e: any) {
    //this.reloadChildPage = false;
    
    if (dateType == "A") {
      if (e.target.value == "") {
        this.f.arrivalTimeUTC.setErrors(null);
        this.f.arrivalTimeUTC.setValue('');
        this.f.arrivalDateLocal.setValue('');
        this.f.arrivalTimeLocal.setValue('');
      }
      else {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        //item.arrivalTimeUTC = timeObj.timeString;
        if (!timeObj.isValidDateTime) {
          this.f.arrivalTimeUTC.setErrors({ pattern: true });
        }
        else {
          this.f.arrivalTimeUTC.setValue(timeObj.timeString);
          e.target.value = timeObj.timeString;
          let v = true;
          if (this.f.departureTimeUTC.value != "") {
            v = this.validateCompareArrival_DepartureDateTime();
          }
          if (v == true) {
            this.f.arrivalTimeUTC.setErrors(null);
            this.f.arrivalDateUTC.setErrors(null);
            this.f.departureDateUTC.setErrors(null);
            this.f.departureTimeUTC.setErrors(null);
            // add to cal localtime
            if (this.f.arrivalDateUTC.value != null) {
              let udt = this.arrivalDateUTCRef.nativeElement.value + " " + this.f.arrivalTimeUTC.value;
              this.getAirportLocalTime(udt, dateType).subscribe(res => {
                if ((res != null || res != undefined) && res == true) {

                }

              });
            }
            else {
              this.f.arrivalDateLocal.setValue('');
              this.f.arrivalTimeLocal.setValue('');
            }
            // add to cal localtime end
          }
          else {
            this.f.arrivalTimeUTC.setErrors({ validateCompareArrival_DepartureDateTime: false });
          }
        }
      }
    }
    if (dateType == "D") {
      if (e.target.value == "") {
        this.f.departureTimeUTC.setErrors(null);
        this.f.departureTimeUTC.setValue('');
        this.f.departureDateLocal.setValue('');
        this.f.departureTimeLocal.setValue('');
        this.f.ete.setValue('');
        if (this.nextICAO != "") {         
          if (this.next_arrivalDateUTC != "" && this.next_arrivalTimeUTC != "") {
            //this.confirmDialogRef = this._dialogService.openConfirmDialog("You updated the Departure Date / Time it might impact next Arrival Date time. Do you want to update next arrival date / time?");
            //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
            //  this.eventSubscription.unsubscribe();
            //  if (result) {

            //    this.updateNextArrivalDateTime = true;
            //    this.next_arrivalDateUTC = "";
            //    this.next_arrivalTimeUTC = "";

            //  }
            //});
              this.updateNextArrivalDateTime = true;
              this.next_arrivalDateUTC = "";
              this.next_arrivalTimeUTC = "";
          }
          
        }      
      }
      else {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = e.target.value;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        //item.arrivalTimeUTC = timeObj.timeString;
        if (!timeObj.isValidDateTime) {
          this.f.departureTimeUTC.setErrors({ pattern: true });
        }
        else {
          e.target.value = timeObj.timeString;
          this.f.departureTimeUTC.setValue(timeObj.timeString);
          let v = true;
          if (this.f.arrivalTimeUTC.value != "") {
            v = this.validateCompareArrival_DepartureDateTime();
          }
          if (v == true) {
            this.f.departureDateUTC.setErrors(null);
            this.f.departureTimeUTC.setErrors(null);
            this.f.arrivalTimeUTC.setErrors(null);
            this.f.arrivalDateUTC.setErrors(null);
            // add to cal localtime
            if (this.f.departureDateUTC.value != null) {
              let udt = this.departureDateUTCRef.nativeElement.value + " " + this.f.departureTimeUTC.value;
              this.getAirportLocalTime(udt, dateType).subscribe(res => {
                if ((res != null || res != undefined) && res == true) {

                }

              });
            }
            else {
              this.f.departureDateLocal.setValue('');
              this.f.departureTimeLocal.setValue('');
            }
            // add to cal localtime end
            if (this.f.departureDateUTC.value != null && this.nextICAO != "" && (this.next_arrivalDateUTC == "" || this.next_arrivalTimeUTC == "")) {
              
              this.calculateNextArrivalDateTime().subscribe(res => {
                if ((res != null || res != undefined) && res == true) {

                }

              });
            }
            else {
              if (this.next_arrivalDateUTC != "" && this.next_arrivalTimeUTC != "") {
                
                //this.confirmDialogRef = this._dialogService.openConfirmDialog("You updated the Departure Date / Time it might impact next Arrival Date time. Do you want to update next arrival date / time?");
                //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
                //  this.eventSubscription.unsubscribe();
                //  if (result) {
                //    if (this.f.ete.value != "") {
                //      if (this.ground_stop_details_form.controls['ete'].invalid == false) {
                //        this.updateNextArrivalDateTime = true;
                //      }
                //      else {
                //        return;
                //      }
                //    }
                //    else {
                //      this.calculateNextArrivalDateTime().subscribe(res => {
                //        if ((res != null || res != undefined) && res == true) {

                //        }

                //      });
                //    }

                //  }
                //});
                    if (this.f.ete.value != "") {
                    if (this.ground_stop_details_form.controls['ete'].invalid == false) {
                      this.updateNextArrivalDateTime = true;
                    }
                    else {
                      return;
                    }
                  }
                  else {
                    this.calculateNextArrivalDateTime().subscribe(res => {
                      if ((res != null || res != undefined) && res == true) {

                      }

                    });
                  }

              }
            }
          }
          else {
            this.f.departureTimeUTC.setErrors({ validateCompareArrival_DepartureDateTime: false });
          }

        }
      }
    }

    this.checkWhetherEteIsDisabled();
  }

  getAirportLocalTime(utcdatetime: string, dateType: string): Observable<boolean> {
    return new Observable<boolean>(ob => {
      let request = new AirportModel();
      request.icao = this.icao;
      request.airportUTCDateTime = utcdatetime;
        
      this._airportService.getAirportLocalTimeFromUTC<ResponseModel<AirportModel>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          let obj = new AirportModel();
          obj = response.model;
          let ldt = obj.airportLocalDateTime;
          if (ldt != "") {
            let i = ldt.indexOf(' ');
            let d = ldt.substring(0, i);
            let t = ldt.substring(i + 1);
            if (dateType == "A") {
              this.f.arrivalDateLocal.setValue(d);
              this.f.arrivalTimeLocal.setValue(t);
            }
            if (dateType == "D") {
              this.f.departureDateLocal.setValue(d);
              this.f.departureTimeLocal.setValue(t);
            }
          }
          else {
            if (dateType == "A") {
              this.f.arrivalDateLocal.setValue('');
              this.f.arrivalTimeLocal.setValue('');
            }
            if (dateType == "D") {
              this.f.departureDateLocal.setValue('');
              this.f.departureTimeLocal.setValue('');
            }
          }
          
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }

        }
        //this.loading = false;
      })
      ob.next(true);
      
    });

  }

  calculateNextArrivalDateTime(): Observable<boolean> {
    return new Observable<boolean>(ob => {
      if (this.latitude != null && this.longitude != null && this.nextGroundStopGUID != "" && this.nextGroundStopGUID != null && this.nextGroundStopGUID != undefined
        && this.next_arrivalLatitude != null && this.next_arrivalLongitude != null) {
        let request = new DistanceTimeModel();
        request.departureLatitude = this.latitude;
        request.departureLongitude = this.longitude;
        request.arrivalLatitude = this.next_arrivalLatitude;
        request.arrivalLongitude = this.next_arrivalLongitude;
        request.airSpeed = this.ac_speed;
        request.bias = 15;
        this._airportService.getDistanceTripTime<ResponseModel<DistanceTimeModel>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            let obj = new DistanceTimeModel();
            obj = response.model;
            let h = Number(obj.tripTimeHour);
            let m = Number(obj.tripTimeMinute);
            let dt = new Date(this.departureDateUTCRef.nativeElement.value + " " + this.f.departureTimeUTC.value);
            let adt = UtilityFunctions.addHours(dt, h);
            adt = UtilityFunctions.addMinutes(dt, m);
            this.next_arrivalDateUTC = CustomValidators.formatDateToMMDDYYYY(adt);
            let ah = adt.getHours();
            let am = adt.getMinutes();
            if (am < 10) {
              this.next_arrivalTimeUTC = ah.toString() + ":0" + am.toString();
            }
            else {
              this.next_arrivalTimeUTC = ah.toString() + ":" + am.toString();
            }
            this.ete = obj.tripTime;
            this.f.ete.setValue(this.ete);
            this.updateNextArrivalDateTime = true;

          }
          else {
            if (response.code == "401") {
              //this.errMsg = response.message
              this._authService.signOut();
            }

          }
          //this.loading = false;
        })
        ob.next(true);
      }
      else
        ob.next(true);
    });

  }

  validateCompareArrival_DepartureDateTime() {
    let a = new Date(this.f.arrivalDateUTC.value);
    let d = new Date(this.f.departureDateUTC.value);
    if (a.getTime() > d.getTime()) {
      return false;
    }
    else {
      if (this.f.arrivalTimeUTC.value != "" && this.f.departureTimeUTC.value != "") {
        let a = new Date(this.arrivalDateUTCRef.nativeElement.value + " " + this.f.arrivalTimeUTC.value);
        let d = new Date(this.departureDateUTCRef.nativeElement.value + " " + this.f.departureTimeUTC.value);
        if (a.getTime() > d.getTime()) {
          return false;
        }
      }
    }
    return true;
  }

  eteTimeChange(e: any) {  
    if (e.target.value == "") {
      this.f.ete.setErrors(null);
      this.f.ete.setValue('');
    }
    else {
      let timeObj = new DateTimeObjModel();
      timeObj.timeString = e.target.value;
      timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
      if (!timeObj.isValidDateTime) {
        this.f.ete.setErrors({ pattern: true });
      }
      else {
        this.f.ete.setValue(timeObj.timeString);
        e.target.value = timeObj.timeString;
        this.updateNextArrivalDateTime = true;
      }
    }
    
  }

  arrivalDepartureStatusChange(flightType: string, e: any) {
    
    switch (flightType) {
      case "A":
        this.selectedArrivalStatusColor = this.arrival_departureStatusList.filter(v => v.arrival_DepartureStatusID == this.f.arrival_status_select.value)[0].colorCode;
        break;
      case "D":
        this.selectedDepartureStatusColor = this.arrival_departureStatusList.filter(v => v.arrival_DepartureStatusID == this.f.departure_status_select.value)[0].colorCode;
        break;
    }
  }

  clickSave(actionName: string) {
    this._authService.updateAccessTime();
    this.duplicateIATAErrMsg = "";
    this.errMsg = "";
    this.submittedArrDate = true;
    
    if (this.isFirstGroundStop == false) {
      if (this.arrivalDateUTCRef.nativeElement.value != "" && this.arrivalDateUTCRef.nativeElement.value != null) {
        if (new Date(this.arrivalDateUTCRef.nativeElement.value).getUTCFullYear() >= (new Date().getUTCFullYear() - 1)) {
          let vd = CustomValidators.isValidDate(this.arrivalDateUTCRef.nativeElement.value);
          if (!vd) {
            this.f.arrivalDateUTC.setErrors({ isValidDate: false });
            return;
          }
          else {
            let v = true;
            if (this.f.departureDateUTC.value != null) {
              v = this.validateCompareArrival_DepartureDateTime();
            }
            if (v == true) {
            }
            else {
              this.f.arrivalDateUTC.setErrors({ validateCompareArrival_DepartureDateTime: false });
              return;
            }
          }
        }
        else {
          this.f.arrivalDateUTC.setErrors({ isValidDate: false });
          return;
        }        
      }     
    }

    this.submittedDepDate = true;
    if (this.nextGroundStopGUID != "") {
      
      if (this.departureDateUTCRef.nativeElement.value != "" && this.departureDateUTCRef.nativeElement.value != null) {
        if (new Date(this.departureDateUTCRef.nativeElement.value).getUTCFullYear() >= (new Date().getUTCFullYear() - 1)) {
          let vd = CustomValidators.isValidDate(this.departureDateUTCRef.nativeElement.value);
          if (!vd) {
            this.f.departureDateUTC.setErrors({ isValidDate: false });
            return;
          }
          else {
            let v = true;
            if (this.f.arrivalDateUTC.value != null) {
              v = this.validateCompareArrival_DepartureDateTime();
            }
            if (v == true) {
            }
            else {
              this.f.departureDateUTC.setErrors({ validateCompareArrival_DepartureDateTime: false });
              return;
            }

          }
        }
        else {
          this.f.departureDateUTC.setErrors({ isValidDate: false });
          return;
        }
        
      }
      
    }
    
    this.validatedAirport = true;

    if (this.nextGroundStopGUID!='' && this.f.dep_fartype_select.value == "0") {
      this.f.dep_fartype_select.setErrors({required: true});
    }
    if (this.ground_stop_details_form.invalid || this.errMsg != "") {
      return;
    }

    this.loading = true;
    let request = new GroundStopModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.groundStopSequenceID = this.currentGroundStopSequenceID;
    request.groundStopGUID = this.groundStopGUID;
    request.groundStopTypeGUID = this.f.ground_stop_type_select.value;
    request.groundStopTypeDescription = this.groundStopTypeList.filter(v => v.groundStopTypeGUID == this.f.ground_stop_type_select.value)[0].groundStopTypeDescription;
    request.oldGroundStopTypeGUID = this.originalGroundStopTypeGUID;
    request.cancelAllServices = this.cancelAllServices;
    request.icao = this.f.icao.value;
    if (this.f.tech_stop_select.value == "1") {
      request.isTechStop = true;
    }
    else {
      request.isTechStop = false;
    }
    request.arrivalDateUTC = this.f.arrivalDateUTC.value;
    request.arrivalDateTextUTC = "";
    if (this.isFirstGroundStop==false) {
      request.arrivalDateTextUTC = this.arrivalDateUTCRef.nativeElement.value;
    }
    request.arrivalTimeUTC = this.f.arrivalTimeUTC.value;
    request.arrivalDateLocal = new Date(this.f.arrivalDateLocal.value);
    request.arrivalDateTextLocal = this.f.arrivalDateLocal.value;//this.arrivalDateUTCRef.nativeElement.value;
    request.arrivalTimeLocal = this.f.arrivalTimeLocal.value;
    request.departureDateUTC = this.f.departureDateUTC.value;
    request.departureDateTextUTC = "";
    if (this.nextGroundStopGUID != "") {
      request.departureDateTextUTC = this.departureDateUTCRef.nativeElement.value;
    }
    request.departureTimeUTC = this.f.departureTimeUTC.value;
    request.departureDateLocal = new Date(this.f.departureDateLocal.value);
    request.departureDateTextLocal = this.f.departureDateLocal.value;//this.arrivalDateUTCRef.nativeElement.value;
    request.departureTimeLocal = this.f.departureTimeLocal.value;
    request.arrivalStatusID = Number(this.f.arrival_status_select.value);
    request.departureStatusID = Number(this.f.departure_status_select.value);
    request.departureCallSign = this.f.dep_CallSign.value;
    request.departureFARTypeID = Number(this.f.dep_fartype_select.value);
    //request.arrivalPetsOnBoard = this.f.arr_pets.value;
    //request.arrivalWeaponsOnBoard = this.f.arr_weapons.value;
    request.departurePetsOnBoard = this.f.dep_pets.value;
    request.departureWeaponsOnBoard = this.f.dep_weapons.value;
    request.ete = this.f.ete.value;
    request.eteChanged = this.f.confirmed.value;
    request.nextGroundStopGUID = "";
    //request.nextArrivalDateUTC = "";
    //request.nextArrivalTimeUTC = "";
    request.nextArrivalDateUTC = this.next_arrivalDateUTC;
    request.nextArrivalTimeUTC = this.next_arrivalTimeUTC;
    if (this.updateNextArrivalDateTime == true) {
      request.nextGroundStopGUID = this.nextGroundStopGUID;
      request.nextArrivalDateUTC = "";//this.next_arrivalDateUTC;
      request.nextArrivalTimeUTC = "";//this.next_arrivalTimeUTC;
    }   
    request.speed = this.ac_speed;

    this._groundStopService.saveGroundStopBygsId<ResponseModel<boolean>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model) {
          this.showSuccessMsg = true;
          this.isModified = true;
          this.returnObj.refresh = this.isModified;
          this.returnObj.groundStopTypeDescription = request.groundStopTypeDescription;
          this.submittedDepDate = false;
          this.validatedAirport = false;
          this.controlValueChanged = false;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this.clickClose();
          }, 1000);
          
        }
        else {
          this.errMsg = "Failed to update the business stop at this time. Please try later.";
        }
        //this.showSpin = false;
        this.loading = false;

      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to update the business stop at this time. Please try later.";
        }
        this.loading = false;
      }
      //this.showSpin = false;
    })


  }

  clickClose() {
    //this._dialogRef.close(this.isModified);
    if (this.controlValueChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes before close the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave('close');
        }
        else {
          this._dialogRef.close(this.returnObj);
        }
      });

    }
    else {
      this._dialogRef.close(this.returnObj);
    }

  }


  clickAudit(username: string) {
    this.getUsernameList().subscribe(res1 => {
      if (res1 != null || res1 != undefined) {
        if (res1 == false) {
          // switch (this.auditHr) {
          //   case 1:
          //     this.msg = "There is no trip audit record within past " + this.auditHr.toString() + " hour";
          //     break;
          //   case 0:
          //     this.msg = "There is no trip audit record within past time.";
          //     break;
          //   default:
          //     this.msg = "There is no trip audit record within past " + this.auditHr.toString() + " hours";
          // }
          // return;
          this.openTripAuditDialog(username);
        }
        else {

          this.openTripAuditDialog(username);
        }

      }

    });


  }


  getUsernameList(): Observable<boolean> {
    this.msg = "";
    this._authService.updateAccessTime();

    return new Observable<boolean>(ob => {
      if (this.userType != 'internal') {
        ob.next(false);
      }
      else {
        let obj = new TripRevisionModel();
        obj.aircraftGUID = this.aircraftGUID;
        obj.customerGUID = this.customerGUID;
        obj.tripCodeGUID = this.tripCodeGUID;
        obj.pastHours = Math.round(this.auditHr);//parseInt(this.auditHr);
        this._groundStopService.getTripRevisionUsernameListBytIdHours<ResponseModel<TripRevisionModel[]>>(obj).subscribe(response => {
          if (response != null && response.code == "200") {
            this.usernameList = [];
            this.internalUsernameList = [];
            this.externalUsernameList = [];
            if (response.model.length > 0) {
              this.usernameList = response.model;
              this.internalUsernameList = this.usernameList.filter(x => x.isExternal == false);
              this.externalUsernameList = this.usernameList.filter(x => x.isExternal == true);
              if (this.internalUsernameList.length < 10) {
                this.showMoreInternalUsernameList = false;
              }
              if (this.externalUsernameList.length < 10) {
                this.showMoreExternalUsernameList = false;
              }
              //if (this.usernameList.length < 10) {
              //  this.showMoreUsernameList = false;
              //}
              ob.next(true);
            }
            else {
              ob.next(false);
            }
          }
          else {
            if (response.code == "401") {
              //this.errMsg = response.message
              this._authService.signOut();
            }
            ob.next(false);
          }

        })
      }

    });

  }

  openTripAuditDialog(username) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID, tripCodeGUID: this.tripCodeGUID, pastHours: this.auditHr,
      userName: username, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });
  }


  checkWhetherEteIsDisabled() {

    const datepipe: DatePipe = new DatePipe('en-US')

    let arrDateTime = new Date(`${datepipe.transform(this.arrivalDateUTC, 'yyyy-MM-dd')}T${(this.arrivalTimeUTC)?.padStart(5, '0') }:00`);
    let depDateTime = new Date(`${datepipe.transform(this.departureDateUTC, 'yyyy-MM-dd')}T${(this.departureTimeUTC)?.padStart(5, '0') }:00`);
      
    let newArrDateTime = new Date(`${datepipe.transform(this.f.arrivalDateUTC.value, 'yyyy-MM-dd')}T${(this.f.arrivalTimeUTC.value)?.padStart(5, '0') }:00`);
    let newDepDateTime = new Date(`${datepipe.transform(this.f.departureDateUTC.value, 'yyyy-MM-dd')}T${(this.f.departureTimeUTC.value)?.padStart(5, '0') }:00`);
      
 
    // console.log((depDateTime.getTime() - newDepDateTime.getTime()) / (1000 * 60 * 60), (arrDateTime.getTime() - newArrDateTime.getTime()) / (1000 * 60 * 60))
  
    /// 2 hours is 7200000 milliSeconds. Checking the changes is greater than 2 hours
    if (Math.abs(depDateTime.getTime() - newDepDateTime.getTime()) > 7200000 || Math.abs(arrDateTime.getTime() - newArrDateTime.getTime()) > 7200000)
    {
      this.f.confirmed.setValue(false);
    }


    if (!(this.f.departureTimeUTC.value) && datepipe.transform(this.departureDateUTC, 'yyyy-MM-dd') != datepipe.transform(this.f.departureDateUTC.value, 'yyyy-MM-dd')) {
      this.f.confirmed.setValue(false);
    }

  

    // if (!(this.f.arrivalTimeUTC.value) && datepipe.transform(this.arrivalDateUTC, 'yyyy-MM-dd') != datepipe.transform(this.f.arrivalDateUTC.value, 'yyyy-MM-dd')) {
    //   this.f.confirmed.setValue(false);
    // }
 
  }

    // if (!(this.f.arrivalTimeUTC.value) && datepipe.transform(this.arrivalDateUTC, 'yyyy-MM-dd') != datepipe.transform(this.f.arrivalDateUTC.value, 'yyyy-MM-dd')) {
    //   this.f.confirmed.setValue(false);
    // }
 

  

}
