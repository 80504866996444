<div style="margin-top:0em; padding-top:0em; display:flex">
  <form [formGroup]="vendor_hours_form">
      <div>
        <table mat-table [dataSource]="recordList" class="example-container">
          <tr>
            <ng-container matColumnDef="effective_date">
              <th mat-header-cell *matHeaderCellDef width="6%">
                Effective Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.effectiveDate}}</td>
            </ng-container>
            <ng-container matColumnDef="expiration_date">
              <th mat-header-cell *matHeaderCellDef width="6%">
                Expiration Date
              </th>
              <td mat-cell *matCellDef="let element">{{element.endDate}}</td>
            </ng-container>
            <ng-container matColumnDef="hours_type">
              <th mat-header-cell *matHeaderCellDef width="8%">
                Type
              </th>
              <td mat-cell *matCellDef="let element">{{element.hoursTypeDescription}}</td>
            </ng-container>
            <ng-container matColumnDef="is24hours">
              <th mat-header-cell *matHeaderCellDef width="5%">
                24 Hours
              </th>
              <td mat-cell *matCellDef="let element">{{element.is24Hours? 'Yes':'No'}}</td>
            </ng-container>
            <ng-container matColumnDef="sunday">
              <th mat-header-cell *matHeaderCellDef width="6%">
                Sun
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.sunClosed==1? 'Closed' : element.sunClosed==2? '24 Hours' : element.is24Hours? '24 Hours': element.sun_Open==null || element.sun_Open==''? ' ': element.sun_Open + ' - ' +  element.sun_Close}}
              </td>
            </ng-container>
            <ng-container matColumnDef="monday">
              <th mat-header-cell *matHeaderCellDef width="6%">
                Mon
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.monClosed==1? 'Closed' : element.monClosed==2? '24 Hours' :  element.is24Hours? '24 Hours': element.mon_Open==null || element.mon_Open==''? ' ': element.mon_Open + ' - ' +  element.mon_Close}}
              </td>
            </ng-container>
            <ng-container matColumnDef="tuesday">
              <th mat-header-cell *matHeaderCellDef width="6%">
                Tue
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.tueClosed==1? 'Closed' : element.tueClosed==2? '24 Hours' :  element.is24Hours? '24 Hours': element.tue_Open==null || element.tue_Open==''? ' ': element.tue_Open + ' - ' +  element.tue_Close}}
              </td>
            </ng-container>
            <ng-container matColumnDef="wednesday">
              <th mat-header-cell *matHeaderCellDef width="6%">
                Wed
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.wedClosed==1? 'Closed' : element.wedClosed==2? '24 Hours' :  element.is24Hours? '24 Hours': element.wed_Open==null || element.wed_Open==''? ' ': element.wed_Open + ' - ' +  element.wed_Close}}
              </td>
            </ng-container>
            <ng-container matColumnDef="thursday">
              <th mat-header-cell *matHeaderCellDef width="6%">
                Thu
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.thuClosed==1? 'Closed' : element.thuClosed==2? '24 Hours' :  element.is24Hours? '24 Hours': element.thu_Open==null || element.thu_Open==''? ' ': element.thu_Open + ' - ' +  element.thu_Close}}

              </td>
            </ng-container>
            <ng-container matColumnDef="friday">
              <th mat-header-cell *matHeaderCellDef width="6%">
                Fri
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.friClosed==1? 'Closed' : element.friClosed==2? '24 Hours' :  element.is24Hours? '24 Hours': element.fri_Open==null || element.fri_Open==''? ' ': element.fri_Open + ' - ' +  element.fri_Close}}
              </td>
            </ng-container>
            <ng-container matColumnDef="saturday">
              <th mat-header-cell *matHeaderCellDef width="6%">
                Sat
              </th>
              <td mat-cell *matCellDef="let element">
                {{element.satClosed==1? 'Closed' : element.satClosed==2? '24 Hours' :  element.is24Hours? '24 Hours': element.sat_Open==null || element.sat_Open==''? ' ': element.sat_Open + ' - ' +  element.sat_Close}}
              </td>
            </ng-container>

            <ng-container matColumnDef="isUTC">
              <th mat-header-cell *matHeaderCellDef width="4%">
              </th>
              <td mat-cell *matCellDef="let element">{{element.is24Hours? '': element.isUTC? 'UTC':'Local'}}</td>
            </ng-container>
            <ng-container matColumnDef="remarks">
              <th mat-header-cell *matHeaderCellDef width="15%">
                Remarks
              </th>
              <td mat-cell *matCellDef="let element" style="text-align:left !important">{{element.remarks}}</td>
            </ng-container>
            <ng-container matColumnDef="disabled">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Disabled
              </th>
              <td mat-cell *matCellDef="let element">{{element.isActive? '':'Yes'}}</td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef width="5%">
                Action <br /><a mat-button class="btn-high grid" (click)="clickEdit($event,null)">ADD</a>
              </th>
              <td mat-cell *matCellDef="let element; let i=index ">
                <a mat-button class="btn-low grid" (click)="clickEdit($event, element);">
                  EDIT
                </a>
              </td>
            </ng-container>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
        </table>
      </div>
  </form>
</div>
<app-spin *ngIf="showSpin"></app-spin>
