import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { VendorModel } from '../models/vendor.model';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { VendorService } from '../services/vendor.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { PersonModel } from '../models/person.model';
import { VendorPersonEditDialogComponent } from './vendor-person-edit-dialog.component';
import { PersonService } from '../services/person.service';
import { PersonClassModel } from '../models/person-class.model';

@Component({
  selector: 'app-vendor-personlist',
  templateUrl: './vendor-personlist.component.html',
  styleUrls: ['./vendor-personlist.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})

export class VendorPersonlistComponent implements OnInit, OnChanges {
  personlist_form: UntypedFormGroup;
  @Input() vendorGUID: string;
  @Input() includeDisabledRecords: boolean;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  vendorList: VendorModel[];
  userType: string;
  showSpin: boolean = true;
  //selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  recordList: PersonModel[];
  //limitTo: number = 10;

  displayedColumn: string[] = ['name', 'title', 'include_in_brief', 'login', 'disabled', 'action'];
  scrHeight: number;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _personService: PersonService) {

  }
    ngOnChanges(changes: SimpleChanges): void {
      this.showSpin = true;
      this._authService.updateAccessTime();
      this.errMsg = "";
      this.msg = "";
      this.vendorList = [];
      this.recordList = [];
      this.totalRecordsText = '';
      this.totalRecords = 0;
      this.currentPageIndex = 0;
      this.totalPageNumber = 0;
      this.scrHeight = window.innerHeight;
      if (this.scrHeight < 900) {
        this.pageSize = 10;
      }
      else {
        this.pageSize = 15;
      }

      this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
      if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
        this.includeDisabledRecords = false;

      switch (localStorage.getItem('ut').toLowerCase()) {
        case "i":
          this.userType = "internal";
          break;
        case "c":
          this.userType = "vendor";
          break;
        case "v":
          this.userType = "vendor";
          break;
        default:
          this.userType = "";
          break;
      }

        this.initControls();

        this.showSpin = false;

        //this.initControls();

        if (this.userType == "internal") {
          if (this.vendorGUID != "") {
            this.getSearchResults();
          }
        }
        else {
          this.getSearchResults();
        }
    }

 

  ngOnInit() {

   
  }


  initControls() {
    this.personlist_form = this._formBuilder.group({
    
    });
  }

  get f() { return this.personlist_form.controls; }

  vendorChange(e: any) {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    if (e.target.value != "") {
      this.getSearchResults();
    }
    //else {
    //  if (this.userType == "internal") {
    //    if (this.f.vendor_select.value == "") {
    //      this.errMsg = "Please select a client";
    //      return;
    //    }
    //  }
    //}

  }

  includeDisabledRecordsOnChange(event: any) {
    //let x = event.target.checked;
    if (this.userType == "internal") {
      if (this.f.vendor_select.value == "") {
        this.errMsg = "Please select a client";
        return;
      }
    }
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.getSearchResults();
  }

  clickShowAllRecords() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    
    this.getSearchResults();
  }

  onSubmit() {

  }

  getSearchResults() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new PersonModel();
    request.vendorGUID = this.vendorGUID;
   
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    request.isActive = !this.includeDisabledRecords;
    this._personService.getVendorPerson<ResponseModel<PersonModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

          }

        }
        else {
          this.recordList = [];

        }
      }
      else {
        this.recordList = [];
      }
      this.loading = false;
      this.showSpin = false;

    });


  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
    }

    this.getSearchResults();

  }

  clickReset() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.initControls();
  }

  //clickTab(s: string) {
  //  if (this.selectedTabName != s) {
  //    this.selectedTabName = s;
  //  }
  //}

  

  

  clickAdd(event: any, p: PersonModel) {
    
    let s = "";
    let personGUID = "";
    //if (p == null) {
    s = "Create New Contact";
    //}
    //else {
    //  s = "EDIT Contact";
    //  personGUID = p.personGUID;
    //}
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
  //  dialogConfig.width = "65em";//"1000px";
  //  dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

      dialogConfig.data = { dialogTitle: s, personGUID: "", vendorGUID: this.vendorGUID};
    
    const dialogRef = this._dialog.open(VendorPersonEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.getSearchResults();
      }
      else {
        if (result.newId != "") {
          // goto person details pages
          let navigationExtras: NavigationExtras = {
            queryParams: {
              "pId": result.newId

            }
          };
          //this._router.navigate(['/persons/vendor-person-details', { pId: result.newId, selectedPersonClass: this.selectedPersonClass }]);
          this._router.navigate(['/vendor/vendor-person-details'], navigationExtras);
        }
      }

    });
  }

  clickEdit(event: any, p: PersonModel) {
    //this._router.navigate(['/persons/vendor-person-details', { pId: p.personGUID }]);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "pId": p.personGUID
        
      }
    };
    this._router.navigate(['/vendor/vendor-person-details'], navigationExtras);


  }

  


}
