<div *ngIf="upLockTrip"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="background-color: #f8f8f8; padding-left:1em; padding-top: 0.5em;   padding-right:1em">
  <div style="display: flex; "  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div style="margin-right: 0.5em">
      <i class="material-icons" style="font-size: x-large;cursor: pointer;color: gray">settings</i>
    </div>
    <div><h5>Trip Closeout</h5></div>
  </div>
  <form *ngIf="trip_setup_form && tripCodeGUID!=''" [formGroup]="trip_setup_form">
    <div>

      <!-- <div style="display: flex; margin-top:-0.5em">
        <div style="margin-right:1em">
          <div style="font-size: small">Registration</div>
          <div style="font-weight: bold; font-size: small">{{registration}}</div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">Trip Code</div>
          <div style="font-weight: bold; font-size: small">{{tripCode}}</div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">Date Range</div>
          <div style="font-weight: bold; font-size: small">{{dateRange}}</div>
        </div>
        <div style="margin-right:1em">
          <div style="font-size: small">Business Stop Route</div>
          <div style="font-weight: normal; font-size: small">
            <div *ngFor="let gs of groundStopList; let i=index">
              <div *ngIf="i % 17==0" style="display: flex">
                <div style="display: flex">
                  <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 1 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+1].groundStopTypeColor">{{groundStopList[i+1].icao}}</div><div>{{(i+1)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 2 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+2].groundStopTypeColor">{{groundStopList[i+2].icao}}</div><div>{{(i+2)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 3 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+3].groundStopTypeColor">{{groundStopList[i+3].icao}}</div><div>{{(i+3)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 4 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+4].groundStopTypeColor">{{groundStopList[i+4].icao}}</div><div>{{(i+4)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 5 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+5].groundStopTypeColor">{{groundStopList[i+5].icao}}</div><div>{{(i+5)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 6 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+6].groundStopTypeColor">{{groundStopList[i+6].icao}}</div><div>{{(i+6)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 7 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+7].groundStopTypeColor">{{groundStopList[i+7].icao}}</div><div>{{(i+7)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 8 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+8].groundStopTypeColor">{{groundStopList[i+8].icao}}</div><div>{{(i+8)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 9 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+9].groundStopTypeColor">{{groundStopList[i+9].icao}}</div><div>{{(i+9)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 10 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+10].groundStopTypeColor">{{groundStopList[i+10].icao}}</div><div>{{(i+10)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 11 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+11].groundStopTypeColor">{{groundStopList[i+11].icao}}</div><div>{{(i+11)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 12 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+12].groundStopTypeColor">{{groundStopList[i+12].icao}}</div><div>{{(i+12)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 13 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+13].groundStopTypeColor">{{groundStopList[i+13].icao}}</div><div>{{(i+13)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 14 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+14].groundStopTypeColor">{{groundStopList[i+14].icao}}</div><div>{{(i+14)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 15 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+15].groundStopTypeColor">{{groundStopList[i+15].icao}}</div><div>{{(i+15)==groundStopList.length-1? '': '-'}}</div>
                </div>
                <div *ngIf="i + 16 < groundStopList.length" style="display: flex">
                  <div [style.background-color]="groundStopList[i+16].groundStopTypeColor">{{groundStopList[i+16].icao}}</div><div>{{(i+16)==groundStopList.length-1? '': '-'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->




      <div style="display:flex; background-color: white;min-height: 21em">
        <div style="padding-left: 0.5em; padding-right: 0.5em; height: 34.5em; overflow: auto;">




          <div style="display: flex; margin-top: 0.5em;">
            <h5 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 7em; font-size: medium;  text-align: end;"> Client Acct :</h5>
            <div>
              <div style="padding-top: 0.3em;font-weight: bold; font-size: medium;" > {{customerName}} </div>
            </div>
          </div>
          <div style="display: flex; margin-top: -0.4em;">
            <h5 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 7em; font-size: medium; text-align: end;"> Trip Code :</h5>
            <div>
              <div style="padding-top: 0.3em; font-size: medium;font-weight: bold;" > {{tripCode}} </div>
            </div>
          </div>
          <div  style="display: flex;  margin: -0.5em 0;">
            <h5 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 7em; font-size: medium; text-align: end;">Registration :</h5>
            <div>
              <div style="padding-top: 0.3em; font-size: medium;" > {{registration}} </div>
            </div>
          </div>
          <div  style="display: flex;  margin: -0.5em 0;">
            <h5 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 7em; font-size: medium; text-align: end;">Date Range :</h5>
            <div>
              <div style="padding-top: 0.3em; font-size: medium;" > {{dateRange}} </div>
            </div>
          </div>
          <div  style="display: flex;  margin: -0.5em 0;">
            <h5 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 7em; font-size: medium; text-align: end;">Route:</h5>
            <div style="    margin-top: 0.3em; ">


              <div style="font-weight: normal; font-size: small">
                <div *ngFor="let gs of groundStopList; let i=index">
                  <div *ngIf="i % 12==0" style="display: flex">
                    <div style="display: flex">
                      <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 1 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+1].groundStopTypeColor">{{groundStopList[i+1].icao}}</div><div>{{(i+1)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 2 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+2].groundStopTypeColor">{{groundStopList[i+2].icao}}</div><div>{{(i+2)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 3 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+3].groundStopTypeColor">{{groundStopList[i+3].icao}}</div><div>{{(i+3)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 4 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+4].groundStopTypeColor">{{groundStopList[i+4].icao}}</div><div>{{(i+4)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 5 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+5].groundStopTypeColor">{{groundStopList[i+5].icao}}</div><div>{{(i+5)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 6 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+6].groundStopTypeColor">{{groundStopList[i+6].icao}}</div><div>{{(i+6)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 7 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+7].groundStopTypeColor">{{groundStopList[i+7].icao}}</div><div>{{(i+7)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 8 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+8].groundStopTypeColor">{{groundStopList[i+8].icao}}</div><div>{{(i+8)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 9 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+9].groundStopTypeColor">{{groundStopList[i+9].icao}}</div><div>{{(i+9)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                     <div *ngIf="i + 10 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+10].groundStopTypeColor">{{groundStopList[i+10].icao}}</div><div>{{(i+10)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 11 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+11].groundStopTypeColor">{{groundStopList[i+11].icao}}</div><div>{{(i+11)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <!--<div *ngIf="i + 12 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+12].groundStopTypeColor">{{groundStopList[i+12].icao}}</div><div>{{(i+12)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 13 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+13].groundStopTypeColor">{{groundStopList[i+13].icao}}</div><div>{{(i+13)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 14 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+14].groundStopTypeColor">{{groundStopList[i+14].icao}}</div><div>{{(i+14)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 15 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+15].groundStopTypeColor">{{groundStopList[i+15].icao}}</div><div>{{(i+15)==groundStopList.length-1? '': '-'}}</div>
                    </div>
                    <div *ngIf="i + 16 < groundStopList.length" style="display: flex">
                      <div [style.background-color]="groundStopList[i+16].groundStopTypeColor">{{groundStopList[i+16].icao}}</div><div>{{(i+16)==groundStopList.length-1? '': '-'}}</div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>



          </div>

         



          <div style="display: flex; min-width:24em; ">
            <div style="margin : 1em;">
              <label for="trip_closeout_select" style="font-weight: 600; font-size:small;">Trip Close Out Status</label>
              <div style="margin-top: -0.5em; ">
                <select *ngIf="f"  class="form-control form-control-sm dropdown" formControlName="trip_closeout_select" style="font-size: small;border-radius:0.25em; border-color: lightgrey; background-color:#eaf0fd " (change)="tripCloseOutChange($event)">
                  <option *ngFor="let t of tripCloseOutList" [ngValue]="t.tripCloseOutID">{{t.tripCloseOutDescription}}</option>
                </select>
              </div>
            </div>
            <div style="margin-top:1em">

              <div style="display: inline-grid;margin-top: 0.5em;">
                <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="checkLockdownTrip" (change)="onLockdownChange($event)"   style="transform:scale(.8);font-size:medium" color="primary">Lockdown</mat-slide-toggle>
                <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="checkRemoveFromExternalView" (change)="removeFromExternalViewChange($event)" style="transform:scale(.8);font-size:medium" color="primary">Remove From External View </mat-slide-toggle>
              </div>


              <!-- <div style="display:flex">
                <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkLockdownTrip" style="transform: scale(0.75)"></mat-checkbox><div style="margin-top:0em; margin-left:0.25em; font-size: small">Lockdown Trip (No Edits)</div>
              </div>
              <div style="display:flex">
                <mat-checkbox (click)="$event.stopPropagation()" formControlName="checkRemoveFromExternalView" style="transform: scale(0.75)"></mat-checkbox><div style="margin-top:0em; margin-left:0.25em; font-size: small">Remove from External View</div>
              </div> -->


            </div>



          </div>

          <div style="margin-right:0.5em; padding: 1em;  padding-top: 0em;">
            <label for="ete" style="font-weight: 600;font-size:small">Client Reference</label>
            <div style="display:flex;margin-top:-0.35em">
              <input type="text" class="form-control form-control-sm inputBox"  (mousedown)="$event.stopPropagation()"  formControlName="customerReference" maxlength="50" style="background-color: #eaf0fd; width:19.7em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em" />

           
            </div>
          </div>
          <div style=" display: flex; margin-top: 0.5em; padding: 1em; padding-right: 0em;  padding-top: 0em;">
   
            <div>

              <label for="submitted_date" style="font-weight: 600; font-size:small">Submitted Date:&nbsp;</label>
              <div>
                <div style="margin-top:0em;display: flex">
                  <input type="text"  (mousedown)="$event.stopPropagation()" [matDatepicker]="submitted_datepicker" [max]="submitMaxDate" placeholder="MM/DD/YYYY" formControlName="submitted_date" #submitted_date class="form-control" [ngClass]="{ 'is-invalid': submitted && f.submitted_date.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                  <mat-datepicker-toggle matSuffix [for]="submitted_datepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                  <mat-datepicker #submitted_datepicker></mat-datepicker>
                </div>
                <div *ngIf="submitted && f.submitted_date.errors" class="small alert alert-danger p-0" style="font-size: small; width: max-content; margin-top: -.75em">
                  <div *ngIf="f.submitted_date.errors.required">Submitted Date is required.</div>
                  <div *ngIf="f.submitted_date.errors.isValidDate==false">Invalid Submitted date. format: MM/DD/YYYY</div>
                </div>
              </div>
            </div>

            <div style="  margin-right:1em">
              <label for="client_trip_number" style="font-weight: 600; font-size:small">Client Trip#:&nbsp;</label>
              <div style="margin-top:0em;">
                <input type="text" formControlName="client_trip_number" class="form-control" maxlength="50" style="width:10em; height: 1.625em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd" />
              </div>
            </div>
          </div>


          <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em; padding: 1em; padding-right: 0em;  padding-top: 0em;">
            <div style="display: inline-flex;  margin: 0.3em; ">
              <label for="remarks" style="font-size:small; font-weight: 600;">Remarks</label>
              <button mat-icon-button (click)="clickOpenInNew()" style="cursor: pointer; margin-top: -0.75em;"> <i class="material-icons" style="margin-left: 17em; font-size:x-large; color: gray">open_in_full</i> </button>
            </div>
            <div  class="form-group" style="margin-left: 0em; margin-top:-0.5em; ">
              <textarea   (mousedown)="$event.stopPropagation()" class="form-control"  formControlName="remarks" readonly style="width:37.25em; height:8em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#f8f8f8; border-radius:0.25em">   
              </textarea>
            </div>
          </div>

          <ng-template #remarksWrapper>

            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em; padding: 1em; padding-right: 0em;  padding-top: 0em;">
              <div style="display: flex; width: 100% ; justify-content: space-between; margin: 0.3em;">
                <label for="remarks" style="font-size:small; font-weight: 600;">Remarks</label>
                <button mat-icon-button   mat-dialog-close><i class="material-icons" style="font-size: x-large;">close </i></button>

              </div>
              <div  class="form-group" style="margin-left: 0em; margin-top:-0.5em; ">
                <textarea  class="form-control"   (mousedown)="$event.stopPropagation()" formControlName="remarks" readonly style="width:50em; height:44em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#f8f8f8; border-radius:0.25em">   
                </textarea>
              </div>
              <button mat-button class="btn-low"  mat-dialog-close>Close  </button>

            </div>
          </ng-template>



















          
          
          <!-- <div style="display:flex; margin-top:-0.5em; margin-bottom:0.5em">
             <div style="margin-top:0em;   justify-content: center !important;">
              <button type="button" mat-button class="btn-high" style=" min-width: 22em; justify-content: center !important;" (click)="downloadWordReport()">&nbsp;
                <mat-icon *ngIf="downloading2">
                  <svg class="black-spinner" viewBox="0 0 50 50">
                    <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="black" stroke-width="4"></circle>
                  </svg>
                </mat-icon>
                Download Trip Summary Report&nbsp;
              </button>&nbsp;  
              <button type="button" mat-button class="btn-high" style=" min-width: 22em; margin-top: 1em;" (click)="downloadExcel()">&nbsp;
                <mat-icon *ngIf="downloading1">
                  <svg class="black-spinner" viewBox="0 0 50 50">
                    <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="black" stroke-width="4"></circle>
                  </svg>
                </mat-icon>
                Download Billing Details For Accounting&nbsp;
              </button>&nbsp;  
            </div> 
          </div> -->
 
              
              
            
          <div style="max-height: 15em; overflow-y:auto">
            <table mat-table *ngIf="fpcBillingList && fpcBillingList.length>0" [dataSource]="fpcBillingList" class="example-container2 mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef style="width:20%;white-space:nowrap;">
                    <div style="margin-top:-0.25em;">Type</div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.billingType}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="invoiceNum">
                  <th mat-header-cell *matHeaderCellDef style="width:60%;white-space:nowrap;">
                    <div style="margin-top:-0.25em;">Invoice #</div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.documentNo}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="invoiceDate">
                  <th mat-header-cell *matHeaderCellDef style="width:20%;white-space:nowrap;">
                    <div style="margin-top:-0.25em;">Invoice Date</div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div>{{element.postingDate}}</div>

                  </td>
                </ng-container>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumnBilling; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnBilling"></tr>
            </table>
          </div>


          <div style="margin-top:0.5em">
            <table mat-table *ngIf="clientAcctProfileList && clientAcctProfileList.length>0" [dataSource]="clientAcctProfileList" class="example-container mat-elevation-z8">
              <tr>
                <ng-container matColumnDef="referencePO">
                  <th mat-header-cell *matHeaderCellDef style="width:20%;">
                    <div style="margin-top:-0.25em;">Reference PO</div>
                  </th>
                  <td mat-cell *matCellDef="let element" style="white-space:nowrap;">
                    {{element.referencePO}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="registration">
                  <th mat-header-cell *matHeaderCellDef style="width:20%;">
                    <div style="margin-top:-0.25em;">Registration</div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.registration}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="service">
                  <th mat-header-cell *matHeaderCellDef style="width:20%;">
                    <div style="margin-top:-0.25em;">Service</div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div>{{element.serviceTypeDescription}}</div>

                  </td>
                </ng-container>
                <ng-container matColumnDef="crew">
                  <th mat-header-cell *matHeaderCellDef style="width:20%;">
                    <div style="margin-top:-0.25em;">Crew</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.crewName}}</td>
                </ng-container>
                <ng-container matColumnDef="pax">
                  <th mat-header-cell *matHeaderCellDef style="width:20%;">
                    <div style="margin-top:-0.25em;">PAX</div>
                  </th>
                  <td mat-cell *matCellDef="let element">{{element.paxName}}</td>
                </ng-container>

              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumnClientProfile; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumnClientProfile"></tr>
            </table>
          </div>



        </div>



        <!-- <div style="width:0.5em; background-color: #f8f8f8"></div>
        <div style="padding-left:0.5em">
          <div>
            <div style="margin-right:0.5em">
              <label for="ete" style="font-size:small">Client Reference</label>
              <div style="display:flex;margin-top:-0.35em">
                <input type="text" formControlName="customerReference" maxlength="50" style="background-color: #eaf0fd; width:39.25em; height: 1.5625em; font-size: small; padding: 0.25em; border-radius: 0.25em" />
              </div>
            </div>
            <div style="display:flex; margin-top: 0.5em; ">
     
              <label for="submitted_date" style="font-size:small">Submitted Date:&nbsp;</label>
              <div>
                <div style="margin-top:0em;display: flex">
                  <input type="text" [matDatepicker]="submitted_datepicker" [max]="submitMaxDate" placeholder="MM/DD/YYYY" formControlName="submitted_date" #submitted_date class="form-control" [ngClass]="{ 'is-invalid': submitted && f.submitted_date.errors }" style="padding: 0.25em; width: 7.5em; height: 1.5625em; font-size: small;background-color:#eaf0fd" maxlength="10" />
                  <mat-datepicker-toggle matSuffix [for]="submitted_datepicker" style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                  <mat-datepicker #submitted_datepicker></mat-datepicker>
                </div>
                <div *ngIf="submitted && f.submitted_date.errors" class="small alert alert-danger p-0" style="font-size: small; width: max-content; margin-top: -.75em">
                  <div *ngIf="f.submitted_date.errors.required">Submitted Date is required.</div>
                  <div *ngIf="f.submitted_date.errors.isValidDate==false">Invalid Submitted date. format: MM/DD/YYYY</div>
                </div>
              </div>
              <div style="display: flex; margin-right:1em">
                <label for="client_trip_number" style="font-size:small">Client Trip#:&nbsp;</label>
                <div style="margin-top:0em;">
                  <input type="text" formControlName="client_trip_number" class="form-control" maxlength="50" style="width:10em; height: 1.625em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd" />
                </div>
              </div>
            </div>
 
            <div style="margin-top:0.5em">
              <table mat-table  [dataSource]="clientAcctProfileList" class="example-container mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="referencePO">
                    <th mat-header-cell *matHeaderCellDef style="width:20%;">
                      <div style="margin-top:-0.25em;">Reference PO</div>
                    </th>
                    <td mat-cell *matCellDef="let element" style="white-space:nowrap;">
                      {{element.referencePO}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="registration">
                    <th mat-header-cell *matHeaderCellDef style="width:20%;">
                      <div style="margin-top:-0.25em;">Registration</div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.registration}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef style="width:20%;">
                      <div style="margin-top:-0.25em;">Service</div>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <div>{{element.serviceTypeDescription}}</div>

                    </td>
                  </ng-container>
                  <ng-container matColumnDef="crew">
                    <th mat-header-cell *matHeaderCellDef style="width:20%;">
                      <div style="margin-top:-0.25em;">Crew</div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.crewName}}</td>
                  </ng-container>
                  <ng-container matColumnDef="pax">
                    <th mat-header-cell *matHeaderCellDef style="width:20%;">
                      <div style="margin-top:-0.25em;">PAX</div>
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.paxName}}</td>
                  </ng-container>

                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumnClientProfile; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnClientProfile"></tr>
              </table>
            </div>
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="remarks" style="font-size:small">Remarks</label>
              <div class="form-group" style="margin-left: 0em; margin-top:-0.5em; ">
                <textarea formControlName="remarks" readonly style="width:39.25em; height:8em; font-size:small; padding:0em 0em 0em 0.25em;background-color:#f8f8f8; border-radius:0.25em">   
                </textarea>
              </div>
            </div>
          </div>
        </div> -->
      </div>


    </div>
    <div>
      <div style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em;display: flex; height:3em">
        <div style="margin-right:0.5em">

          <button type="button" mat-button class="btn-high" [disabled]="loading" (click)="clickSave()"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save</button>&nbsp;
          <button type="button" mat-button class="btn-low" (click)="clickClose()" >Close</button>
          


          <button type="button" mat-button class="btn-high" style="margin-left: 0.5em;  justify-content: center !important;" (click)="downloadWordReport()">&nbsp;
            <mat-icon *ngIf="downloading2">
              <svg class="black-spinner" viewBox="0 0 50 50">
                <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="black" stroke-width="4"></circle>
              </svg>
            </mat-icon>
             Report&nbsp;
          </button>&nbsp;  
          <button type="button" mat-button class="btn-high" style="  margin-left: 0.5em;" (click)="downloadExcel()">&nbsp;
            <mat-icon *ngIf="downloading1">
              <svg class="black-spinner" viewBox="0 0 50 50">
                <circle class="spinner-path" cx="25" cy="25" r="20" fill="none" stroke="black" stroke-width="4"></circle>
              </svg>
            </mat-icon>
           Export To BC&nbsp;
          </button>&nbsp;  



          <button *ngIf="false" type="button" mat-button class="btn-high" style="margin-left: 5em;" (click)="sendMessageClick()">&nbsp;Send Message&nbsp;</button>&nbsp;  

        </div>
        <div *ngIf="errMsg!=''" class="d-block" style="margin:0; display:flex;margin-top:0em; background-color:#f9d2de; padding-left:0.25em; width:24em"><label style="color:black; font-size: small">{{errMsg}}</label></div>
        <div *ngIf="showSuccessMsg">
          <app-success-message [successMsg]="successMsg"></app-success-message>
        </div>

      </div>
    </div>
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
