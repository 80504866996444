<div style="background-color:#f0f0f0;overflow-y:hidden ">
  
  
  <div  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="display: inline-flex; justify-content: space-between; width: 100%;">
    <div>
      <div style="display: inline-flex;">
        <div style="margin-left: 0.5em; margin-bottom: 0.5em;">
          <h3 style="margin-bottom: 0em;">Active Trips</h3>
        </div>
      </div>
    </div>

    <div style="display: flex;">
  
      <button mat-icon-button mat-dialog-close><i class="material-icons" style="font-size: x-large;">close </i></button>
    </div>
  </div>

  <div style="padding: 1em 1em 1em 1em; background-color: white;">
    <div style="overflow-y: auto; height: 33em; scrollbar-color: #c1c1c1 white">
      <app-spin style="width: 15em;" *ngIf="showSpin"></app-spin>
      <div style="margin-bottom: 1em; margin-top: -0.5em;  " >
 

        <div style="display: flex; ">
          <h6 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 9em; text-align: end;"> Customer Name :</h6>
          <div>
            <div style="padding-top: 0.1em;" > {{customerName}}  </div>
          </div>
        </div>
        <div style="display: flex;  margin: -0.5em 0;">
          <h6 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 9em; text-align: end;"> Person Name :</h6>
          <div>
            <div style="padding-top: 0.1em;" > {{travelDocument.fullName}} </div>
          </div>
        </div>
        <div style="display: flex;  margin: -0.5em 0;">
          <h6 style="color: dodgerblue; padding: 0.25em; padding-left: 0.5em; width: 9em; text-align: end;"> Document Type :</h6>
          <div>
            <div style="padding-top: 0.1em;" > {{travelDocument.documentTypeDescription}} - {{travelDocument.documentNumber}} </div>
          </div>
        </div>
      </div>

      <table *ngIf="!showSpin" mat-table [dataSource]="activeTripList" style="width: 100%;">
        <tr>
          <ng-container matColumnDef="tripCode">
            <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: left !important; width: 19%;">
              Trip Code
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: left !important">{{element.tripCode}}</td>
          </ng-container>
          <ng-container matColumnDef="icaoList">
            <th mat-header-cell *matHeaderCellDef style="font-weight: bold; text-align: left !important; width: 72%;">
              Airports
            </th>
            <td mat-cell *matCellDef="let element" style="text-align: left !important">{{element.icaoList}}</td>
          </ng-container>

 
          <ng-container matColumnDef="open">
            <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 15%;">
              Action
            </th>
            <td mat-cell *matCellDef="let element" style="align-content: center; text-align: center;">
              <!-- <div *ngFor="let t of element.tripList"> -->
              <div style="display:flex; height:1.75em">
                <div style="width:7em; display: flex">
                  <div style="margin-right:0.25em ; margin-left: 1em;">
                    <a mat-button class="btn-high grid"  (click)="clickOpenTrip($event, element);" style="align-self:center">
                      Open Trip
                    </a>
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
    </div>
  </div>
</div>