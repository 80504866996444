<div style="background-color:#f8f8f8;  height: 100%;">
    <div style="padding: 0.5em; background-color:white ; height: 100%;">
        <form *ngIf="advisory_details_form" [formGroup]="advisory_details_form">


            <div style="display: flex;padding-left:1em; justify-content: space-between; width: 100%;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
                <div style="display: flex; margin-top:0.3125em;">
                    <span class="material-symbols-outlined"
                        style="font-size: x-large; margin-left: -0.3em;margin-right: 0.2em;">warning</span>
                    <h5>Advisory Alerts Dashboard</h5>
                </div>
                <div style="display: flex;">
                    <button  *ngIf="isDialog"  mat-icon-button  (click)="clickRouteNew('advisory-details','')" > <i class="material-icons"
                            style="font-size: large;  ">open_in_new</i> </button>
                    <button *ngIf="isDialog"  mat-icon-button mat-dialog-close> <i class="material-icons"
                            style="font-size: x-large;">close </i> </button>
                </div>
            </div>
 
            <div style="display: flex; margin-bottom: 1em;">
                <div style="display: flex;">

                    <div>
                        <div
                            style="border: 0.0125em solid #337dff; margin-right:0.5em; padding-left:0.25em; border-radius: 0.25em">
                            <div style="display: flex;margin-top:0.25em">
                                <div style="font-size: small; font-weight: bold">Enter a Date Range</div>
                                <div style="font-size:x-small; margin-top:0.35em">&nbsp;(MM/DD/YYYY)</div>
                            </div>
                            <div style="display: flex; width: 12.25em; margin-top:-1.25em">
                                <mat-date-range-input [rangePicker]="picker"
                                    style="width:10em; display: flex; margin-top:0.5em; ">
                                    <input matStartDate formControlName="effectiveDate" placeholder="Start date"
                                        maxlength="10"
                                        [ngClass]="{ 'is-invalid1': isValideffectiveDate==false,  'is-valid1': isValideffectiveDate==true }"
                                        (dateChange)="dateOnchange('S', $event)"
                                        style="font-size:small;margin-top:0.4em; margin-right:0em;background-color: white; border-radius:0.25em;padding-left:0.125em ">
                                    <input matEndDate formControlName="expirationDate" placeholder="End date"
                                        maxlength="10"
                                        [ngClass]="{ 'is-invalid1': !isValidexpirationDate,  'is-valid1': isValidexpirationDate }"
                                        (dateChange)="dateOnchange('E', $event)"
                                        style="font-size:small;margin-left:0em; background-color: white; border-radius:0.25em;padding-left:0.125em; margin-top: 0.35em; ">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"
                                    style="margin-top:0.7em; margin-left:-0.7em"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </div>
                        </div>
                    </div>





                    <div style=" margin-right:0.25em">
                        <button type="button" mat-button class="btn-low" style="width:8em;"
                            [ngClass]="selectedAirportCountText!=''?'btn-low-highlight':'btn-low'"
                            (click)="clickAirportFilter()">Airport{{selectedAirportCountText}}</button>
                    </div>



                    <div style=" margin-right:0.25em">
                        <button type="button" mat-button class="btn-low" style="width:7em;"
                            [ngClass]="selectedCountryCountText!=''?'btn-low-highlight':'btn-low'"
                            (click)="clickCountryFilter()">Country{{selectedCountryCountText}}</button>
                    </div>

                    <div style=" margin-right:0.25em">
                        <button type="button" mat-button class="btn-low" style="width:7em;"
                            [ngClass]="selectedRatingCountText!=''?'btn-low-highlight':'btn-low'"
                            (click)="clickRatingFilter()">Rating{{
                            selectedRatingCountText }}</button>
                    </div>

                    <div style=" margin-right:0.25em">
                        <button type="button" mat-button class="btn-low" style="width:7em;"
                            [ngClass]="selectedVendorCountText!=''?'btn-low-highlight':'btn-low'"
                            (click)="clickVendorFilter()">Vendor{{
                            selectedVendorCountText }}</button>
                    </div>

                    <div style=" margin-right:0.25em">
                        <button type="button" mat-button class="btn-low" style="width:7em;"
                            [ngClass]="selectedHotelCountText!=''?'btn-low-highlight':'btn-low'"
                            (click)="clickHotelFilter()">Hotel{{
                            selectedHotelCountText }}</button>
                    </div>

                    <div style=" margin-right:0.25em">
                        <button type="button" mat-button class="btn-low" style="width:9.5em;"
                            [ngClass]="selectedAdvisoryTypeCountText!=''?'btn-low-highlight':'btn-low'"
                            (click)="clickAdvisoryTypeFilter()">Alert Type{{ selectedAdvisoryTypeCountText
                            }}</button>
                    </div>

                    <div style="display: flex; height: 1.6em;  ">

                        <button mat-button class="btn-low " style="margin-left: 1em;" (click)="getData()">
                            <mat-icon style="font-size: medium; margin: 0.25em -0.3em 0 0;">refresh</mat-icon>
                            Refresh
                        </button>
                    </div>
                    <div style="display: flex; height: 1.6em;  ">

                        <button class="btn-low" mat-button style="margin-left: 1em;"
                            (click)="clickReset()"><mat-icon style="font-size: medium; margin: 0.25em -0.3em 0 0;">restart_alt </mat-icon>Reset</button>
                    </div>
                    
                    <div style="  margin-top: 2em; ">
                        <div style="margin: 1em; margin-bottom: 0; margin-top: -2em;  margin-left: 1em;">
                            <div style="display: flex;margin-left:-.65em">
                                <div style="display: grid;">
                                    <mat-slide-toggle (click)="$event.stopPropagation()" formControlName="isDisabled" (change)="showDisabledRecordsOnly($event)"  style="transform:scale(.8);font-size:medium" color="primary">Show Disabled Records </mat-slide-toggle>
                                </div>
                                 
                                
                            </div> 
                        </div>
                     
                        <div  *ngIf="totalRecords > 0" style="margin-left: 5em;" >   {{totalRecordsText}}</div>
                    </div>

                </div>

            </div>

            <div style="display: flex;">

                <!--Airport list-->
                <div *ngIf="showAirportList"
                    style="float: left; min-width: 12em; margin-top: -2.75em; margin-left: 16em; background-color: white;
                      border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid;
                      font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                    <div style="display:flex; justify-content:space-between">
                        <div style="font-weight: bold; text-decoration:underline;">Airport ICAO</div>
                        <div style="float:right" (click)="showAirportList=!showAirportList"><i class="material-icons"
                                style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                    </div>
                    <div style="font-size: small; margin-left:0em">
                        <div style="margin-right:0.5em;">
                            <div
                                style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                                <div><i class="material-icons"
                                        style="font-size: medium; margin-top:0.25em; color: lightgray">search</i>
                                </div>
                                <input type="text" formControlName="airportSearch" class="input-noborder"
                                    placeholder="Search"
                                    style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;"
                                    (keyup)="displayNameAirport($event)" />
                            </div>
                        </div>
                    </div>
                    <div *ngIf="airportList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em"
                        class="scroll-thin">
                        <div *ngFor="let a of filteredAirportList | async; let i=index"
                            [style.margin-top.em]="i==0?0.5:0.25" class="mat-checkbox-smaller">
                            <div #tripAirportRefs [attr.lookupId]="a.lookupId"
                                style="display: flex; font-size: small; margin-top:-0.5em">
                                <div>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="checkAirportChange($event, a)"
                                        [checked]="a.selected">{{a.description == null ? 'No Airport': a.description}}</mat-checkbox>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style="margin-top:2em; height:0.75em">
                        <hr color="#f8f8f8" />
                    </div>
                    <div style="display: flex; float: right; ">
                        <div style="margin-right:0.5em">
                            <button type="button" mat-button class="btn-low" (click)="clickResetAirport()"
                                style="height:2em">RESET</button>
                        </div>
                        <div>
                            <button type="button" mat-button class="btn-high" (click)="clickDoneAirport()"
                                style="height:2em">DONE</button>
                        </div>
                    </div>
                </div>
                <!--Airport list end-->

                <!--country list--><!--margin-left: 32.25em;-->
                <div *ngIf="showCountryList"
                    style="float: left; min-width: 11em; margin-top: -2.75em; background-color: white; margin-left: 24.3em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                    <div style="display:flex; justify-content:space-between">
                        <div style="font-weight: bold; text-decoration:underline">Countries</div>
                        <div style="float:right" (click)="showCountryList=!showCountryList"><i class="material-icons"
                                style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                    </div>
                    <div style="font-size: small; margin-left:0em">
                        <div style="margin-right:0.5em;">
                            <div
                                style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                                <div><i class="material-icons"
                                        style="font-size: medium; margin-top:0.25em; color: lightgray">search</i>
                                </div>
                                <input type="text" formControlName="countrysearch" class="input-noborder"
                                    placeholder="Search"
                                    style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />
                                <!--(keyup)="countrySearchChange($event)"-->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="countryList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em"
                        class="scroll-thin">
                        <div *ngFor="let v of filteredCountryList | async; let i=index"
                            [style.margin-top.em]="i==0?0.5:0.25">
                            <div #tripCountryRefs [attr.lookupId]="v.lookupId"
                                style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                                <div>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="checkCountryChange($event, v)"
                                        [checked]="v.selected">{{v.description}}</mat-checkbox>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style="margin-top:2em; height:0.75em">
                        <hr color="#f8f8f8" />
                    </div>
                    <div style="display: flex; float: right">
                        <div style="margin-right:0.5em">
                            <button type="button" mat-button class="btn-low" (click)="clickResetCountry()"
                                style="height:2em">RESET</button>
                        </div>
                        <div>
                            <button type="button" mat-button class="btn-high" (click)="clickDoneCountry()"
                                style="height:2em">DONE</button>
                        </div>
                    </div>
                </div>
                <!--country list end-->


                <!--rating list--><!--margin-left: 32.25em;-->
                <div *ngIf="showRatingList"
                    style="float: left; min-width: 11em; margin-top: -2.75em; background-color: white; margin-left: 31.8em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                    <div style="display:flex; justify-content:space-between">
                        <div style="font-weight: bold; text-decoration:underline">Ratings</div>
                        <div style="float:right" (click)="showRatingList=!showRatingList"><i class="material-icons"
                                style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                    </div>
                    <div style="font-size: small; margin-left:0em">
                        <div style="margin-right:0.5em;">
                            <div
                                style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                                <div><i class="material-icons"
                                        style="font-size: medium; margin-top:0.25em; color: lightgray">search</i>
                                </div>
                                <input type="text" formControlName="ratingsearch" class="input-noborder"
                                    placeholder="Search"
                                    style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />
                                <!--(keyup)="ratingSearchChange($event)"-->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="ratingList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em"
                        class="scroll-thin">
                        <div *ngFor="let v of filteredRatingList | async; let i=index"
                            [style.margin-top.em]="i==0?0.5:0.25">
                            <div #tripRatingRefs [attr.lookupId]="v.lookupId"
                                style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                                <div>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="checkRatingChange($event, v)"
                                        [checked]="v.selected">{{v.description}}</mat-checkbox>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style="margin-top:2em; height:0.75em">
                        <hr color="#f8f8f8" />
                    </div>
                    <div style="display: flex; float: right">
                        <div style="margin-right:0.5em">
                            <button type="button" mat-button class="btn-low" (click)="clickResetRating()"
                                style="height:2em">RESET</button>
                        </div>
                        <div>
                            <button type="button" mat-button class="btn-high" (click)="clickDoneRating()"
                                style="height:2em">DONE</button>
                        </div>
                    </div>
                </div>
                <!--rating list end-->


                <!--vendor list--><!--margin-left: 32.25em;-->
                <div *ngIf="showVendorList"
                    style="float: left; min-width: 11em; margin-top: -2.75em; background-color: white; margin-left: 38.8em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                    <div style="display:flex; justify-content:space-between">
                        <div style="font-weight: bold; text-decoration:underline">Vendors</div>
                        <div style="float:right" (click)="showVendorList=!showVendorList"><i class="material-icons"
                                style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                    </div>
                    <div style="font-size: small; margin-left:0em">
                        <div style="margin-right:0.5em;">
                            <div
                                style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                                <div><i class="material-icons"
                                        style="font-size: medium; margin-top:0.25em; color: lightgray">search</i>
                                </div>
                                <input type="text" formControlName="vendorsearch" class="input-noborder"
                                    placeholder="Search"
                                    style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />
                                <!--(keyup)="vendorSearchChange($event)"-->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="vendorList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em"
                        class="scroll-thin">
                        <div *ngFor="let v of filteredVendorList | async; let i=index"
                            [style.margin-top.em]="i==0?0.5:0.25">
                            <div #tripVendorRefs [attr.lookupGUID]="v.lookupGUID"
                                style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                                <div>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="checkVendorChange($event, v)"
                                        [checked]="v.selected">{{v.description}}</mat-checkbox>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style="margin-top:2em; height:0.75em">
                        <hr color="#f8f8f8" />
                    </div>
                    <div style="display: flex; float: right">
                        <div style="margin-right:0.5em">
                            <button type="button" mat-button class="btn-low" (click)="clickResetVendor()"
                                style="height:2em">RESET</button>
                        </div>
                        <div>
                            <button type="button" mat-button class="btn-high" (click)="clickDoneVendor()"
                                style="height:2em">DONE</button>
                        </div>
                    </div>
                </div>
                <!--vendor list end-->


                <!--hotel list--><!--margin-left: 32.25em;-->
                <div *ngIf="showHotelList"
                    style="float: left; min-width: 11em; margin-top:  -2.75em; background-color: white; margin-left: 46.3em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                    <div style="display:flex; justify-content:space-between">
                        <div style="font-weight: bold; text-decoration:underline">Hotels</div>
                        <div style="float:right" (click)="showHotelList=!showHotelList"><i class="material-icons"
                                style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                    </div>
                    <div style="font-size: small; margin-left:0em">
                        <div style="margin-right:0.5em;">
                            <div
                                style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                                <div><i class="material-icons"
                                        style="font-size: medium; margin-top:0.25em; color: lightgray">search</i>
                                </div>
                                <input type="text" formControlName="hotelsearch" class="input-noborder"
                                    placeholder="Search"
                                    style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />
                                <!--(keyup)="hotelSearchChange($event)"-->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="hotelList.length>0" style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em"
                        class="scroll-thin">
                        <div *ngFor="let v of filteredHotelList | async; let i=index"
                            [style.margin-top.em]="i==0?0.5:0.25">
                            <div #tripHotelRefs [attr.lookupId]="v.lookupId"
                                style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                                <div>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="checkHotelChange($event, v)"
                                        [checked]="v.selected">{{v.description}}</mat-checkbox>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style="margin-top:2em; height:0.75em">
                        <hr color="#f8f8f8" />
                    </div>
                    <div style="display: flex; float: right">
                        <div style="margin-right:0.5em">
                            <button type="button" mat-button class="btn-low" (click)="clickResetHotel()"
                                style="height:2em">RESET</button>
                        </div>
                        <div>
                            <button type="button" mat-button class="btn-high" (click)="clickDoneHotel()"
                                style="height:2em">DONE</button>
                        </div>
                    </div>
                </div>
                <!--hotel list end-->

                <!--advisoryType list--><!--margin-left: 32.25em;-->
                <div *ngIf="showAdvisoryTypeList"
                    style="float: left; min-width: 11em; margin-top: -2.75em; background-color: white; margin-left: 53.8em; border-top: 0.0625em lightgrey solid; border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid; border-right: 0.0625em lightgrey solid; font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000; position: absolute">
                    <div style="display:flex; justify-content:space-between">
                        <div style="font-weight: bold; text-decoration:underline">Advisory Types</div>
                        <div style="float:right" (click)="showAdvisoryTypeList=!showAdvisoryTypeList"><i
                                class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i>
                        </div>
                    </div>
                    <div style="font-size: small; margin-left:0em">
                        <div style="margin-right:0.5em;">
                            <div
                                style="margin-left: 0em; margin-top:0em; margin-bottom:0em; margin-right: 0.25em; display: flex; border-bottom: solid 0.0625em lightgray;">
                                <div><i class="material-icons"
                                        style="font-size: medium; margin-top:0.25em; color: lightgray">search</i>
                                </div>
                                <input type="text" formControlName="advisoryTypesearch" class="input-noborder"
                                    placeholder="Search"
                                    style="padding: 0em;width:11.5em;height:1.5625em;font-size:small;" />
                                <!--(keyup)="advisoryTypeSearchChange($event)"-->
                            </div>
                        </div>
                    </div>
                    <div *ngIf="advisoryTypeList.length>0"
                        style="max-height: 20em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                        <div *ngFor="let v of filteredAdvisoryTypeList | async; let i=index"
                            [style.margin-top.em]="i==0?0.5:0.25">
                            <div #tripAdvisoryTypeRefs [attr.lookupId]="v.lookupId"
                                style="display: flex; font-size: small; margin-top:-0.5em" class="mat-checkbox-smaller">
                                <div>
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="checkAdvisoryTypeChange($event, v)"
                                        [checked]="v.selected">{{v.description}}</mat-checkbox>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style="margin-top:2em; height:0.75em">
                        <hr color="#f8f8f8" />
                    </div>
                    <div style="display: flex; float: right">
                        <div style="margin-right:0.5em">
                            <button type="button" mat-button class="btn-low" (click)="clickResetAdvisoryType()"
                                style="height:2em">RESET</button>
                        </div>
                        <div>
                            <button type="button" mat-button class="btn-high" (click)="clickDoneAdvisoryType()"
                                style="height:2em">DONE</button>
                        </div>
                    </div>
                </div>
                <!--advisoryType list end-->

            </div>



            <div style="overflow-y:auto;overflow-x: hidden; margin-top:-.5em; height: 27em;" [ngClass]="currentScreenSize">
                <table id="msg" mat-table style="width: 77.5em;" matSort matSortActive="advisoryId" matSortDisableClear matSortDirection="asc"
                    [dataSource]="advisoryList">
                    <!-- AdvisoryId Column  [ngClass]="element.isActive ?'':'redCell'" -->
                    <ng-container matColumnDef="advisoryId">
                        <th mat-header-cell *matHeaderCellDef style="text-align:left !important;flex:0 0 2%;width:2%">
                            ID
                        </th>
                        <td class="top-align-cell" mat-cell *matCellDef="let element"   
                            style="text-align:left !important;align-content: baseline;flex:0 0 2%;width:2%">
                            <span>{{element.advisoryId}}</span>
                        </td>
                    </ng-container>

                    <!-- AdvisoryRating Column -->
                    <ng-container matColumnDef="advisoryRating">
                        <th mat-header-cell *matHeaderCellDef style="text-align:left !important;flex:0 0 4%;width:4%">
                            Rating
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style="align-content: baseline;text-align:left !important;flex:0 0 4%;width:4%">
                            <span>{{element.advisoryRating}}</span>
                        </td>
                    </ng-container>

                    <!-- EffectiveDate Column -->
                    <ng-container matColumnDef="effectiveDate">
                        <th mat-header-cell *matHeaderCellDef style="text-align:left !important;flex:0 0 10%;width:10%">
                            Effective Dates(z)
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style="text-align:left !important;flex:0 0 10%;  display: grid;">
                            <span style="display: flex;">{{element.effectiveDate}} <span
                                    *ngIf="element.expirationDate">- {{element.expirationDate}}</span>z</span>
                            <span style="display: flex;">{{element.advisoryValidTimeStart}} <span
                                    *ngIf="element.advisoryValidTimeEnd">-
                                    {{element.advisoryValidTimeEnd}}</span></span>
                        </td>
                    </ng-container>

                    <!-- HotelName Column -->
                    <ng-container matColumnDef="hotelName">
                        <th mat-header-cell *matHeaderCellDef
                            style="text-align:left !important;   flex:0 0 10%;width:10%">
                            Area of Interest
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style="align-content: baseline; text-align:left !important;flex:0 0 10%;width:10%; ">
                            <div *ngIf="element.icao">{{ element.icao }}&nbsp; {{ element.iata }}, </div> 
                            <div *ngIf="element.countryName">{{ element.countryName }}</div>
                            <div *ngIf="element.stateProvinceName">{{ element.stateProvinceName }}</div>
                            <div *ngIf="element.groundHandlerVendorName"> 
                                 {{ element.groundHandlerVendorName}} </div>
                            <div *ngIf="element.ssnVendorName!=null" style="cursor: pointer"> {{ element.ssnVendorName }}</div>
                            <div *ngIf="element.fuelSupplierName!=null"> {{ element.fuelSupplierName }}
                            </div>
                            <div *ngIf="element.intoplaneAgent!=null"> {{ element.intoplaneAgent  }}
                            </div>
                            <div *ngIf="element.hotelName!=null"> {{ element.hotelName }}</div>
                        </td>
                            <!-- <td mat-cell *matCellDef="let element" style="text-align:center !important;flex:0 0 5%;width:5%">
                                <span *ngIf="element.loading" class="spinner-border spinner-border-sm mr-1"></span>
                                <div *ngIf="element.ssnVendorName!=null" style="cursor: pointer"> {{ element.ssnVendorName }}</div>
                            
                                <div *ngIf="element.messageFolderID == 4" style="cursor: pointer;">
                                    {{element.actualSender? element.actualSender : ''}}
                                </div>
                                <div *ngIf="element.assignedTo.trim()!='' &&  element.messageFolderID!= 4" style="cursor: pointer">
                                    {{element.assignedTo}}
                                </div>
                            </td> -->
                    </ng-container>

                    <!-- AdvisoryType Column -->
                    <ng-container matColumnDef="advisoryType">
                        <th mat-header-cell *matHeaderCellDef
                            style="   text-align:left !important;flex:0 0 9%;width:9%">
                            Alert Type
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style=" align-content: baseline;text-wrap: balance; text-align:left !important;flex:0 0 9%;width:9%">
                            {{element.advisoryType}}
                        </td>
                    </ng-container>

                    <!-- Issue Column -->
                    <ng-container matColumnDef="issue">
                        <th mat-header-cell *matHeaderCellDef
                            style="   text-align:left !important;flex:0 0 35%;width:35%">
                            Issue
                            <mat-progress-bar mode="indeterminate" style="width:30%;margin-left:.5em;margin-top:.75em" color="primary"
                                *ngIf="isLoading"></mat-progress-bar>
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style="text-align:left !important; display: grid; flex:0 0 35%;margin-right: 1em !important; ">
                            <span style="font-weight: bold;">{{element.title}}</span>
                            <span>{{element.issue}}</span>
                        </td>
                    </ng-container>

                    <!-- Resolution Column -->
                    <!-- <ng-container matColumnDef="resolution">
                        <th mat-header-cell *matHeaderCellDef style="text-align:left !important;flex:0 0 13%;width:13%">
                            Resolution
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style="text-align:left !important; align-content: baseline; flex:0 0 13%;width:13%">
                            {{element.resolution}}
                        </td>
                    </ng-container> -->

                    <!-- IsHeadlineNews Column -->
                    <!-- <ng-container matColumnDef="isHeadlineNews">
                        <th mat-header-cell *matHeaderCellDef style="text-align:left !important;flex:0 0 4%;width:4%">
                            Headline News
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style=" align-content: baseline; text-align:left !important;flex:0 0 4%;width:4%">
                            {{element.isHeadlineNews == true ? 'Yes': ''}}
                        </td>
                    </ng-container> -->

                    <!-- IsPublicNews Column -->
                    <ng-container matColumnDef="isPublicNews">
                        <th mat-header-cell *matHeaderCellDef style="text-align:left !important;flex:0 0 4%;width:4%">
                            Public News
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style=" align-content: baseline; text-align:left !important;flex:0 0 4%;width:4%">
                            {{element.isPublicNews == true ? 'Yes': ''}}
                        </td>
                    </ng-container>

                    <!-- AdvisorySourceDescription Column -->
                    <ng-container matColumnDef="advisorySourceDescription">
                        <th mat-header-cell *matHeaderCellDef style="text-align:left !important;flex:0 0 6%;width:6%">
                            Source
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style=" align-content: baseline; text-align:left !important;flex:0 0 6%;width:6%">
                            {{element.source}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef style="text-align:center !important;flex:0 0 5%;width:5%">
                            Action
                            <button mat-button class="btn-high" (click)="clickEdit('Add', element)">Add</button>
                        </th>
                        <td mat-cell *matCellDef="let element"
                            style=" align-content: baseline; text-align:center !important;flex:0 0 5%;width:5%">
                            <button mat-button class="btn-low">Edit</button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="clickEdit('Edit', row)"></tr>
                </table>
            </div>
        </form>
    </div>
</div>