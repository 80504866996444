<div *ngIf="upPII" style="margin-top:0em; padding-top:0em;">
  <form *ngIf="trip_details_internal_form" [formGroup]="trip_details_internal_form">
    <div class="divLeft">
      <!--div style="padding-left:0em; ">
        <div style="display: flex;width:102em">
          <div style="float:left; display:flex; width:62.8em">
            <div style="margin-right:0.5em"><h5>{{selectedTripCode}} - Trip Details {{tripCodeType}}  {{customerTripID!=''?'Client Trip # '+customerTripID:''}}</h5></div>
            <div style="height:1.5em; margin-top:0em; margin-right:1em" *ngIf="lockdownTrip">
              <app-warning-message [warningMsg]="warningMsg"></app-warning-message>
            </div>
          </div>
          <div class="d-flex float-right">
          <div style="width:22em">
            <mat-button-toggle-group [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}" (click)="getOut()">
              <mat-button-toggle value="note"><div style="display: flex">Trip Instructions <div *ngIf="opsNotes!=''" style="width:1em"><i class="material-icons" style="font-size: small;cursor: pointer">notes</i></div></div></mat-button-toggle>-->
      <!--<mat-button-toggle value="client"><div style="display: flex">Client Instructions<div *ngIf="clientInstructions!=''" style="width:1em"><i class="material-icons" style="font-size: small;cursor: pointer">notes</i></div></div></mat-button-toggle>-->
      <!--<mat-button-toggle value="filter">Audit & Filters</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="d-flex">
            <div style="margin-right:.5em; margin-top:0em">
              <button type="button" mat-button class="btn-low" (click)="clickSubmit()" style="height:2em">
                <div style="display: flex;">
                  <div style="margin-top: 0.25em; margin-left:-0.25em">
                    <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>
                  </div>
                  <div>
                    REFRESH
                  </div>
                </div>
              </button>
            </div>
            <div style="margin-top:0em;margin-right:0em ">
              <button type="button" mat-button class="btn-low" style="font-weight: normal !important; height: 2em !important" (click)="clickOpenTripSearch()">
                <div style="display: flex; margin-top:0em">
                  <div style="margin-top: 0.25em; margin-left:0em">
                    <i class="material-icons" style="font-size: medium;cursor: pointer; color:#337dff">arrow_back</i>
                  </div>
                  <div style="margin-top:0em; font-weight:normal !important">
                    Open Trip Schedules
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>-->
      <div style="margin-left:0em;padding:0em; display: flex; ">

        <div>
          <div>
            <div style="float:left; display:flex;">
              <div style="margin-right:0.5em; display: inline-flex;">
                <h5>{{selectedTripCode}} - Trip Details {{tripCodeType}} {{customerTripID!=''?'Client Trip #
                  '+customerTripID:''}}</h5>
                  <i class="material-icons" style="cursor: pointer; margin-left: 0.25em; margin-top: 0.1em;" (click)="clickSubmit()">refresh</i>
              </div>
              <div style="height:1.5em; margin-top:0em; margin-right:1em" *ngIf="lockdownTrip">
                <app-warning-message [warningMsg]="warningMsg"></app-warning-message>
              </div>
            </div>
          </div>
          <div class="d-inline-flex">
            <div
              style="min-width: 31.75em;max-width:fit-content; margin-right: 0.25em; background-color: #f8f8f8; border-radius:0.25em;border: 0.0625em lightgrey solid; padding-left:0.25em; padding-right:0.25em; padding-top:0.25em;height:6.25em;">
              <div
                style="display:flex; justify-content: space-between ; margin-top:-0.5em; font-size: small; text-align: right">
                <div style="display:flex; margin-right:0.5em;text-overflow:ellipsis;white-space:nowrap">
                  <div style="margin-right:0.25em; margin-top:0.25em; width:5.25em;">Account</div>
                  <div *ngIf="grantAccessCompanyProfiles" class="divHover"
                    style="font-size:medium; font-weight: bold; margin-top:0em; float: left; cursor:pointer"
                    (click)="clickClient()"> {{clientName}}</div>
                  <div *ngIf="!grantAccessCompanyProfiles"
                    style="font-size:medium; font-weight: bold; margin-top:0em; float: left"> {{clientName}}</div>
                </div>
                <div style="float:right; margin-top:0.5em">
                  <div *ngIf="!tripStarted"><button type="button" mat-button class="btn-med"
                      style="padding-top:0em; padding-left:0.5em !important; width: 6em; margin-top:-0.25em"
                      (click)="clickUpdate()">
                      <div style="margin-top:0em; display: flex"><i class="material-icons"
                          style="font-size: medium; margin-top:0.125em; width:1.125em">update</i>
                        <div style="margin-top:-0.125em">Update</div>
                      </div>
                    </button></div>
                </div>
              </div>

              <div style="display: flex; margin-top: -0.25em; font-size: small;">
                <div style="width:5.65em">
                  <div></div>
                  <div>Registration</div>
                  <!--<div style="float: right">Registration</div>-->
                </div>
                <div *ngIf="grantAccessAircraftProfiles" class="divHover"
                  style="font-size: small; font-weight: bold; margin-top: 0em; cursor: pointer" title="{{acTooltip}}"
                  (click)="clickAircraft()">{{registration}}</div>
                <div *ngIf="!grantAccessAircraftProfiles" style="font-size:small; font-weight:bold; margin-top:0em"
                  title="{{acTooltip}}">{{registration}}</div>
              </div>

              <div style="display:flex">
                <div style="margin-top:0.15em; margin-left:2.8em; margin-right:0.25em"><i
                    class="material-icons-outlined" style="font-size:x-large;color: gray">flight</i></div>
                <div>
                  <div style="display: flex">
                    <div style="margin-right:0.5em">
                      <div style="font-size: small; margin-top:-0.25em">{{acType}}</div>
                    </div>
                    <div style="margin-right:0.5em">
                      <div *ngIf="homeBaseAirport!=''"
                        style="font-size: small; margin-top:-0.25em; white-space:nowrap; display: flex">
                        <div style="margin-left: -0.25em; margin-top:0.25em"><i class="material-icons-outlined"
                            style="font-size:large;color: gray">location_on</i></div>
                        <div style="margin-right:0.25em">HOME BASE</div>
                        <div
                          style="background-color: #19967d; color: white;border: solid lightgray 0.0625em;border-radius: 0.25em; height:1.5em; padding:0em 0.0625em 0em 0.0625em;">
                          {{homeBaseAirport}}</div>
                      </div>
                    </div>
                  </div>
                  <div style="display: flex;margin-top:-0.5em; font-size:small">
                    <div *ngIf="aircraftInfo!=''" style="white-space: nowrap;margin-right:1.5em">{{aircraftInfo}}</div>
                    <div *ngIf="flightPlanProviderName!=''" style="white-space: nowrap;margin-right:1.5em">
                      {{flightPlanProviderName!=''?'Flight Plan '+flightPlanProviderName:''}}</div>
                    <div *ngIf="uplinkVendor!=''" style="white-space: nowrap;">{{uplinkVendor!=''?'Datalink
                      '+uplinkVendor:''}}</div>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedGroundStopList.length>0" style="font-size: small; margin-top:0em;">
                <div style="display: flex">
                  <div style="margin-right: 0.25em; text-align: right; width:5.25em">Route</div>
                  <div>
                    <div *ngIf="selectedGroundStopList.length>11" style="display: flex" class="divHover"
                      (mouseover)="showWholeRoute=true" (mouseout)="showWholeRoute=!showWholeRoute">
                      <div *ngFor="let gs of selectedGroundStopList; let i=index">
                        <div *ngIf="i<=10" style="display: flex">
                          <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div>
                          <div>{{i==selectedGroundStopList.length-1? '': '-'}}</div>
                        </div>
                        <div *ngIf="i==11" style="display: flex">
                          <div [style.background-color]="gs.groundStopTypeColor">....</div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="selectedGroundStopList.length<=11" style="display: flex">
                      <div *ngFor="let gs of selectedGroundStopList; let i=index">
                        <div *ngIf="i<=10" style="display: flex">
                          <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div>
                          <div>{{i==selectedGroundStopList.length-1? '': '-'}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="showWholeRoute"
                  style="margin-top:0.25em; margin-left: 5.25em; background-color: #f8f8f8; position:absolute; z-index:1000; border: 0.0625em solid lightgray; border-radius:0.25em; padding-left:0.25em; padding-right:0.25em">
                  <div style="font-weight: bold">Route: </div>
                  <div *ngFor="let gs of selectedGroundStopList; let i=index">
                    <div *ngIf="i % 11==0" style="display: flex">
                      <div style="display: flex">
                        <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div>
                        <div>{{i==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 1 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+1].groundStopTypeColor">
                          {{selectedGroundStopList[i+1].icao}}</div>
                        <div>{{(i+1)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 2 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+2].groundStopTypeColor">
                          {{selectedGroundStopList[i+2].icao}}</div>
                        <div>{{(i+2)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 3 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+3].groundStopTypeColor">
                          {{selectedGroundStopList[i+3].icao}}</div>
                        <div>{{(i+3)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 4 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+4].groundStopTypeColor">
                          {{selectedGroundStopList[i+4].icao}}</div>
                        <div>{{(i+4)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 5 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+5].groundStopTypeColor">
                          {{selectedGroundStopList[i+5].icao}}</div>
                        <div>{{(i+5)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 6 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+6].groundStopTypeColor">
                          {{selectedGroundStopList[i+6].icao}}</div>
                        <div>{{(i+6)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 7 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+7].groundStopTypeColor">
                          {{selectedGroundStopList[i+7].icao}}</div>
                        <div>{{(i+7)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 8 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+8].groundStopTypeColor">
                          {{selectedGroundStopList[i+8].icao}}</div>
                        <div>{{(i+8)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 9 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+9].groundStopTypeColor">
                          {{selectedGroundStopList[i+9].icao}}</div>
                        <div>{{(i+9)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                      <div *ngIf="i + 10 < selectedGroundStopList.length" style="display: flex">
                        <div [style.background-color]="selectedGroundStopList[i+10].groundStopTypeColor">
                          {{selectedGroundStopList[i+10].icao}}</div>
                        <div>{{(i+10)==selectedGroundStopList.length-1? '': '-'}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div
              style="margin-right:0.25em; background-color: #f8f8f8; border-radius:0.25em;border: 0.0625em lightgrey solid; padding-left:0.5em; padding-right:0.25em;height:6.25em; width:fit-content">

              <div style="font-weight: bold; font-size: small;">
                <div class="d-inline-flex justify-content-between" style="width:100%" >
                  <div>Shortcuts</div>
                  <div style="width:11.85em">
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="openTripPlanner()">
                      <div style="display:flex;align-items:center">
                        <i class="material-icons-two-tone icon_lightblue"
                           style="font-size: medium;">connecting_airports</i>
                        <div>Trip Planner</div>
                      </div>
                    </button>
                  </div>
                </div>
             
              </div>
              <div class="d-inline-flex">
                <div class="flex-column" style="margin-right:.25em">
                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="clickAddEditLegs()">
                      <div style="display:flex;align-items:center"><i class="material-icons-two-tone icon_lightblue"
                          style="font-size: medium;">add_circle</i>
                        <div>Manage Legs</div>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="clickAddEditCrewPax()">
                      <div style="display:flex;align-items:center"><i class="material-icons-two-tone icon_lightblue"
                          style="font-size: medium;">manage_accounts</i>
                        <div>Manifest</div>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="clickContact()">
                      <div style="display:flex;align-items:center"><i class="material-icons icon_lightblue"
                          style="font-size: medium;">contact_page</i>
                        <div>Contacts</div>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="flex-column" style="margin-right:.25em">
                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="clickTripDocuments()">
                      <div style="display:flex;align-items:center"><i class="material-icons icon_lightblue"
                          style="font-size: medium;">description</i>
                        <div>Trip Documents</div>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="clickAddBRTasks()">
                      <div style="display:flex;align-items:center"><i class="material-icons icon_lightblue"
                          style="font-size: medium;">grading</i>
                        <div>Business Rules</div>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="clickAddServices()">
                      <div style="display:flex;align-items:center"><i class="material-icons icon_lightblue"
                          style="font-size: medium;">local_gas_station</i>
                        <div>Services</div>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="flex-column" style="margin-right:.25em">

                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)=" clickFuelQuoteSummary()">
                      <div style="display:flex;align-items:center"><i class="material-icons icon_lightblue"
                          style="font-size: medium;">request_quote</i>
                        <div>Manage Fuel Quotes</div>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="openTripSummaryReport()">
                      <div style="display:flex;align-items:center"><i class="material-icons icon_lightblue"
                          style="font-size: medium;">summarize</i>
                        <div>Trip Summary</div>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="viewEmails()">
                      <div style="display:flex;align-items:center"><i class="material-icons icon_lightblue"
                          style="font-size: medium;">message</i>
                        <div>Trip Messages</div>
                      </div>
                    </button>
                  </div>
                </div>
                <div class="flex-column">
                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="clickTripCloseout()">
                      <div style="display:flex;align-items:center"><i class="material-icons icon_lightblue"
                          style="font-size: medium;">settings</i>
                        <div>Trip Closeout</div>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="viewClientInstructions()">
                      <div style="display:flex;align-items:center"><i class="material-icons icon_lightblue"
                          style="font-size: medium;">integration_instructions</i>
                        <div>Client Instructions</div>
                      </div>
                    </button>
                  </div>
                  <div>
                    <button mat-button class="btn-med narrow" style="width:100%" (click)="viewFlightPlanPreferences()">
                      <div style="display:flex;align-items:center"><i class="material-icons icon_lightblue"
                          style="font-size: medium;">route</i>
                        <div>Flight Plan Preferences</div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div>

          <div class="d-flex justify-content-between">
            <div style="height:2em;">
              <mat-button-toggle-group [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}"
                (click)="getOut()">
                <mat-button-toggle value="note">
                  <div style="display: flex">Trip Instructions <div *ngIf="opsNotes!=''" style="width:1em"><i
                        class="material-icons" style="font-size: small;cursor: pointer">notes</i></div>
                  </div>
                </mat-button-toggle>
                <!--<mat-button-toggle value="client"><div style="display: flex">Client Instructions<div *ngIf="clientInstructions!=''" style="width:1em"><i class="material-icons" style="font-size: small;cursor: pointer">notes</i></div></div></mat-button-toggle>-->
                <mat-button-toggle value="filter">Audit & Filters</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="d-flex">
              <!-- <div style="margin-right:.5em; margin-top:0em">
                <button type="button" mat-button class="btn-low" (click)="clickSubmit()" style="height:2em">
                  <div style="display: flex;">
                    <div style="margin-top: 0.25em; margin-left:-0.25em">
                      <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>
                    </div>
                    <div>
                      REFRESH
                    </div>
                  </div>
                </button>
              </div> -->
              <div style="margin-top:-0.5em;margin-right:0em ">
                <button mat-icon-button (click)="clickOpenTripSearch()" >  <i class="material-icons-outlined" style="font-size:xx-large;  ">arrow_circle_left</i> </button>
                <!-- <button type="button" mat-button class="btn-low"
                  style="font-weight: normal !important; height: 2em !important" (click)="clickOpenTripSearch()">
                  <div style="display: flex; margin-top:0em">
                    <div style="margin-top: 0.25em; margin-left:0em">
                      <i class="material-icons" style="font-size: medium;cursor: pointer; color:#337dff">arrow_back</i>
                    </div>
                    <div style="margin-top:0em; font-weight:normal !important">
                      Open Trip Schedules
                    </div>
                  </div>
                </button> -->
              </div>
            </div>
          </div>

          <div *ngIf="selectedOption=='note'" class="note-box" style="margin-top:.05em">
            <button style="float:right;margin-top:.5em;" mat-button class="btn-low"
              (click)="clickTripNotes('alerts')">Edit</button>
            <div style="white-space: pre-wrap;" [innerHTML]="getHTML(nonStandardClientInstructions)"></div>
          </div>

          <!--<div *ngIf="selectedOption=='client'" class="note-box">
    <button style="float:right;margin-top:.5em" mat-button class="btn-low grid" (click)="clickTripNotes('client')">Edit</button>
    <div style="white-space:pre-wrap" [innerHTML]="getHTML(clientInstructions)"></div>
  </div>-->
          <div *ngIf="selectedOption=='filter'" class="d-inline-flex" style="margin-top: -0.25em;">
            <div
              style="width:18em; height:6.25em; background-color: #f8f8f8; border-radius:0.25em;border: 0.0625em lightgrey solid; padding-left:0.25em; padding-right:0.25em; padding-top:0em; margin-right:0.25em">
              <div style="font-weight: bold; font-size: small">Audit Trail</div>
              <div style="display: flex; margin-top:0.25em">
                <div style="margin-right:0.5em;">
                  <button mat-button class="btn-med"
                    style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
                    (click)="clickAudit('')">
                    <div style="display: flex">
                      <div style="margin-top: 0em;margin-bottom:-0.25em "><i class="material-icons"
                          style="font-size: medium;">manage_search</i></div>
                      <div style="margin-top:-0.25em; font-weight: normal !important;">Audit Trail {{auditHrText}}</div>
                    </div>
                  </button>
                </div>
                <div class="slidecontainer" style="margin-top:-2em; margin-right:0em; width:6em">
                  <input type="range" min="0" max="8" class="slider" [value]="auditIndex" step="1"
                    (change)="auditSlideChange($event)">
                </div>
              </div>
              <div style="height:2.5em">
                <div style="display:flex; margin-top:-0.5em; font-size: small">
                  <div *ngFor="let u of internalUsernameList; let i=index">
                    <div *ngIf="i<2" style="margin-right:0.25em;">
                      <button mat-button class="btn-low"
                        style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
                        (click)="clickAudit(u.userName)">
                        <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
                      </button>
                    </div>
                    <div *ngIf="i==2" style="margin-right:0.25em;">
                      <button mat-button class="btn-low"
                        style="align-self:center;min-width: 1em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
                        (click)="clickshowMoreInternalUsernameList()">
                        <div style="margin-top:-0.25em; font-weight: normal !important;">...</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div style="display:flex; margin-top:0em; font-size: small">
                  <div *ngFor="let u of externalUsernameList; let i=index">
                    <div *ngIf="i<2" style="margin-right:0.25em;">
                      <button mat-button class="btn-green" style="align-self:center;min-width: 4em; height:1.5em"
                        (click)="clickAudit(u.userName)">
                        <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
                      </button>
                    </div>
                    <div *ngIf="i==2" style="margin-right:0.25em;">
                      <button mat-button class="btn-low"
                        style="align-self:center;min-width: 1em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
                        (click)="clickshowMoreExternalUsernameList()">
                        <div style="margin-top:-0.25em; font-weight: normal !important;">...</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div *ngIf="showMoreInternalUsernameList" class="scroll-thin"
                  style="float: left; overflow-y:auto; position:absolute; margin-left: 15em; background-color:#f8f8f8; margin-top:-2em; display: flex; justify-content: space-between; max-height:35em; min-width: 10em; border: solid gray 0.0625em; border-radius:0.25em; padding:0.5em 1em 0.5em 1em; z-index: 1000;">
                  <div>
                    <div *ngFor="let u of internalUsernameList; let i=index"
                      style="margin-left:auto; margin-right:auto">
                      <div *ngIf="i>=2" style="margin-right:0.25em;">
                        <button mat-button class="btn-low"
                          style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
                          (click)="clickAudit(u.userName)">
                          <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div style="float: right">
                    <i class="material-icons" style="cursor: pointer; color:black;font-size: medium"
                      (click)="clickshowMoreInternalUsernameList()">close</i>
                  </div>
                </div>
                <div *ngIf="showMoreExternalUsernameList" class="scroll-thin"
                  style="float: left; overflow-y:auto;  position:absolute; margin-left: 15em;background-color:#f8f8f8; margin-top:-2em; display: flex; justify-content: space-between; max-height:35em; min-width: 10em; border: solid gray 0.0625em; border-radius:0.25em; padding:0.5em 1em 0.5em 1em; z-index: 1000;">
                  <div>
                    <div *ngFor="let u of externalUsernameList; let i=index"
                      style="margin-left:auto; margin-right:auto">
                      <div *ngIf="i>=2" style="margin-right:0.25em;">
                        <button mat-button class="btn-green" style="align-self:center;min-width: 4em;height:1.5em"
                          (click)="clickAudit(u.userName)">
                          <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div style="float: right">
                    <i class="material-icons" style="cursor: pointer; color:black;font-size: medium"
                      (click)="clickshowMoreExternalUsernameList()">close</i>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div style="width:20.75em; background-color: #f8f8f8; border-radius:0.25em;border: 0.0625em lightgrey solid; padding-left:0.25em; padding-right:0.25em; padding-top:0em; height:6.25em; ">
                <div style="display: flex">
                  <div style="font-weight: bold; font-size:small; width:17.25em">Filter Options</div>
                  <div style="margin-left:0em; margin-top:0.125em">
                    <button type="button" mat-button class="btn-low" (click)="clickSubmit()" style="height: 1.5em; width: 7em;">
                      <div style="display: flex;margin-top:-0.125em">
                        <div style="margin-top: 0.25em; margin-left:-0.25em">
                          <i class="material-icons" style="font-size: medium;cursor: pointer; color: #337dff;">refresh</i>
                        </div>
                        <div>
                          REFRESH
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div style="display: flex;margin-top:0.25em">
                  <div style="margin-right:0.5em; font-size:small;font-weight: bold">Include</div>
                  <div class="d-flex flex-sm-row" style="font-size: medium;margin-left:-0.75em; margin-top: -0.25em;">
                    <mat-slide-toggle style="transform:scale(.8); height:2em" color="primary" [checked]="includeDisabledTask" formControlName="includeDisabledTask" (change)="includeDisabledTaskChange($event)">Disabled Tasks</mat-slide-toggle>
                  </div>
                </div>
                <div style="display: flex; margin-top:-0.5em">
                  <div style="margin-top:0.5em; margin-right:0.25em;">
                    <button type="button" mat-button class="btn-low" style="padding-left:0.25em !important; padding-right:0.25em !important;" (click)="clickTripCode()">Trip Code</button>
                  </div>
                  <div style="margin-top:0.5em; margin-right:0.25em;">
                    <button type="button" mat-button class="btn-low" style="padding-left:0.25em !important; padding-right:0.25em !important; width:5.75em" [ngClass]="selectedServiceTypeCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickServiceType()">Service{{selectedServiceTypeCountText}}</button>
                  </div>
                  <div style="margin-top:0.5em; margin-right:0.25em;">
                    <button type="button" mat-button class="btn-low" style="padding-left:0.25em !important; padding-right:0.25em !important; width:5.25em" [ngClass]="selectedTaskStatusCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickTaskStatus()">Status{{selectedTaskStatusCountText}}</button>
                  </div>
                  <div style="margin-top:0.5em; margin-right:0.25em;">
                    <button type="button" mat-button class="btn-low" style="padding-left:0.25em !important; padding-right:0.25em !important; width:7.5em" [ngClass]="selectedAcctStatusCountText!=''?'btn-low-highlight':'btn-low'" (click)="clickAcctStatus()">Accounting{{selectedAcctStatusCountText}}</button>
                  </div>
                  <div>
                    <div *ngIf="errGSMsg!=''" class="d-block" style="margin-left:1em; display:flex;margin-top:0.5em; background-color:#f9d2de; padding-left:0.25em; width:16em"><label style="color:black; font-size: small">{{errGSMsg}}</label></div>
                  </div>
                </div>
                
                <div *ngIf="showTripCodeList" style="position: absolute; float: left; min-width: 11em; margin-top: 0em; margin-left: -17.5em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                  <div style="font-weight: bold; text-decoration:underline">Trip Code</div>
                  <div style="max-height: 28.5em;margin-bottom:-1.5em" class="scroll-thin">
                    <table mat-table *ngIf="tripCodeList" [dataSource]="tripCodeList" class="example-container mat-elevation-z8">
                      <tr>
                        <ng-container matColumnDef="select">
                          <th mat-header-cell *matHeaderCellDef style="width:10%; ">
                            <div style="margin-top:-0.25em;">Select</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="white-space:nowrap; ">
                            <mat-radio-button (click)="$event.stopPropagation()" style="transform: scale(0.75)"
                                              (change)="tripCodeOnChange($event, element)"
                                              [checked]="element.selected"></mat-radio-button>

                          </td>
                        </ng-container>
                        <ng-container matColumnDef="tripcode">
                          <th mat-header-cell *matHeaderCellDef style="width:15%; text-align:left !important; ">
                            <div style="margin-top:-0.25em;">Trip Codes</div>
                          </th>
                          <td mat-cell #tripCodeRefs *matCellDef="let element" [attr.tripCode]="element.tripCode" style="white-space:nowrap; text-align:left !important; ">{{element.tripCode}} </td>
                        </ng-container>
                        <ng-container matColumnDef="route">
                          <th mat-header-cell *matHeaderCellDef style="width:50%; text-align:left !important; ">
                            <div style="margin-top:-0.25em;">Route</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: left !important; ">
                            <div *ngFor="let gs of element.groundStopList; let i=index">
                              <div *ngIf="i % 7==0" style="display: flex">
                                <div style="display: flex">
                                  <div [style.background-color]="gs.groundStopTypeColor">{{gs.icao}}</div><div>{{i==element.groundStopList.length-1? '': '-'}}</div>
                                </div>
                                <div *ngIf="i + 1 < element.groundStopList.length" style="display: flex">
                                  <div [style.background-color]="element.groundStopList[i+1].groundStopTypeColor">{{element.groundStopList[i+1].icao}}</div><div>{{(i+1)==element.groundStopList.length-1? '': '-'}}</div>
                                </div>
                                <div *ngIf="i + 2 < element.groundStopList.length" style="display: flex">
                                  <div [style.background-color]="element.groundStopList[i+2].groundStopTypeColor">{{element.groundStopList[i+2].icao}}</div><div>{{(i+2)==element.groundStopList.length-1? '': '-'}}</div>
                                </div>
                                <div *ngIf="i + 3 < element.groundStopList.length" style="display: flex">
                                  <div [style.background-color]="element.groundStopList[i+3].groundStopTypeColor">{{element.groundStopList[i+3].icao}}</div><div>{{(i+3)==element.groundStopList.length-1? '': '-'}}</div>
                                </div>
                                <div *ngIf="i + 4 < element.groundStopList.length" style="display: flex">
                                  <div [style.background-color]="element.groundStopList[i+4].groundStopTypeColor">{{element.groundStopList[i+4].icao}}</div><div>{{(i+4)==element.groundStopList.length-1? '': '-'}}</div>
                                </div>
                                <div *ngIf="i + 5 < element.groundStopList.length" style="display: flex">
                                  <div [style.background-color]="element.groundStopList[i+5].groundStopTypeColor">{{element.groundStopList[i+5].icao}}</div><div>{{(i+5)==element.groundStopList.length-1? '': '-'}}</div>
                                </div>
                                <div *ngIf="i + 6 < element.groundStopList.length" style="display: flex">
                                  <div [style.background-color]="element.groundStopList[i+6].groundStopTypeColor">{{element.groundStopList[i+6].icao}}</div><div>{{(i+6)==element.groundStopList.length-1? '': '-'}}</div>
                                </div>

                              </div>
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="startDate">
                          <th mat-header-cell *matHeaderCellDef style="width:15%; ">
                            <div style="margin-top:-0.25em;">Start Date</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="white-space:nowrap;">{{element.estimatedTripStartDate}} </td>
                        </ng-container>
                        <ng-container matColumnDef="endDate">
                          <th mat-header-cell *matHeaderCellDef style="width:15%;">
                            <div style="margin-top:-0.25em;">End Date</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="white-space:nowrap;">{{element.estimatedTripEndDate}} </td>
                        </ng-container>
                      </tr>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnTripCode; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnTripCode"></tr>
                    </table>
                  </div>
                  <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                  <div style="display: flex; float: right">
                    <div style="margin-right:0.5em">
                      <button type="button" mat-button class="btn-low" style="height:2em" (click)="clickCloseTripCode()">CLOSE</button>
                    </div>
                    <div>
                      <button type="button" mat-button class="btn-high" (click)="clickDoneTripCode()" style="height:2em">DONE</button>
                    </div>
                  </div>
                </div>

                <div *ngIf="showServiceTypeList" style="position: absolute; float: left; min-width: 11em; margin-top: 0em; margin-left: 5em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                  <div style="display: flex; justify-content:space-between">
                    <div style="font-weight: bold; text-decoration:underline">Service Type</div>
                    <div style="float:right" (click)="showServiceTypeList=!showServiceTypeList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                  </div>
                  <div *ngIf="serviceTypeList.length>0" style="max-height: 28.5em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                    <div *ngFor="let s of serviceTypeList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                      <div style="display: flex; font-size: small; margin-top:-0.5em">
                        <div>
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onServiceTypeChange($event, s)" [checked]="s.selected">{{s.serviceTypeDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                  <div style="display: flex; float: right">
                    <div style="margin-right:0.5em">
                      <button type="button" mat-button class="btn-low" (click)="clickResetServiceType()" style="height:2em">RESET</button>
                    </div>
                    <div>
                      <button type="button" mat-button class="btn-high" (click)="clickDoneServiceType()" style="height:2em">DONE</button>
                    </div>
                  </div>
                </div>

                <div *ngIf="showTaskStatusList" style="position: absolute; float: left; min-width: 15em; margin-top: 0em; margin-left: 10em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                  <div style="display: flex; justify-content:space-between">
                    <div style="font-weight: bold; text-decoration:underline">Task Status</div>
                    <div style="float:right" (click)="showTaskStatusList=!showTaskStatusList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                  </div>
                  <div *ngIf="taskStatusList.length>0" style="max-height: 25em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                    <div *ngFor="let t of taskStatusList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                      <div style="display: flex; font-size: small; margin-top:-0.5em">
                        <div>
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onTaskStatusChange($event, t)" [checked]="t.selected">{{t.taskStatusDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                  <div style="display: flex; float: right">
                    <div style="margin-right:0.5em">
                      <button type="button" mat-button class="btn-low" (click)="clickResetTaskStatus()" style="height:2em">RESET</button>
                    </div>
                    <div>
                      <button type="button" mat-button class="btn-high" (click)="clickDoneTaskStatus()" style="height:2em">DONE</button>
                    </div>
                  </div>
                </div>

                <div *ngIf="showAccountingStatusList" style="position: absolute; float: left; min-width: 20em; margin-top: 0em; margin-left: 5.25em; background-color: white; border-top: 0.0625em lightgrey solid;border-bottom: 0.0625em lightgrey solid; border-left: 0.0625em lightgrey solid;border-right: 0.0625em lightgrey solid;font-size: small; padding: 0.5em 0.5em 0.5em 0.5em; border-radius: 0.25em; z-index: 1000">
                  <div style="display: flex; justify-content:space-between">
                    <div style="font-weight: bold; text-decoration:underline">Accounting Status</div>
                    <div style="float:right" (click)="showAccountingStatusList=!showAccountingStatusList"><i class="material-icons" style="font-size: medium;cursor: pointer; color: gray">close</i></div>
                  </div>
                  <div *ngIf="accountingStatusList.length>0" style="max-height: 28.5em;margin-top:0.5em; margin-bottom:-1.5em" class="scroll-thin">
                    <div *ngFor="let a of accountingStatusList; let i=index" [style.margin-top.em]="i==0?0.125:-1" class="mat-checkbox-smaller">
                      <div style="display: flex; font-size: small; margin-top:-0.5em">
                        <div>
                          <mat-checkbox (click)="$event.stopPropagation()" (change)="onAcctStatusChange($event, a)" [checked]="a.selected">{{a.accountingStatusDescription}}</mat-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top:2em; height:0.75em"><hr color="#f8f8f8" /></div>
                  <div style="display: flex; float: right">
                    <div style="margin-right:0.5em">
                      <button type="button" mat-button class="btn-low" (click)="clickResetAcctStatus()" style="height:2em">RESET</button>
                    </div>
                    <div>
                      <button type="button" mat-button class="btn-high" (click)="clickDoneAcctStatus()" style="height:2em">DONE</button>
                    </div>
                  </div>
                </div>
              </div> -->
          </div>
        </div>
      </div>
      <div style="display: flex;">
        <div style="width:90.5em">
          <!--<div [style.width.em]="childTableWidth">-->
          <div class="form-inline" style="margin-top:0.5em; width: 100%; justify-content: space-between;">
            <div style="display: inline-flex; align-items: center;">
              <div style="margin-right:0.5em">
                <ul class="tabul">
                  <li
                    [ngClass]="{ 'btn-high':selectedTabName=='groundStop','btn-low pointer':selectedTabName!='groundStop'  }"
                    style="border-left: black 0.0625em solid;" (click)="clickTab('groundStop')">
                    <div style="display: flex; margin-left:0.2em">
                      <div style="margin-top: 0.25em;margin-right: 0.25em;">
                        <i class="material-icons" style="font-size: x-large;cursor: pointer"
                          [style.color]="selectedTabName=='groundStop'?'white':'gray'">airline_stops</i>
                      </div>
                      <div style="margin-top:0.25em">Airport View</div>
                    </div>
                  </li>
                  <li [ngClass]="{ 'btn-high':selectedTabName=='leg','btn-low pointer':selectedTabName!='leg' }"
                    (click)="clickTab('leg')">
                    <div style="display: flex; margin-left: 0.2em">
                      <div style="margin-top: 0.5em;margin-right: 0.25em;">
                        <i class="material-icons" style="font-size: large;cursor: pointer"
                          [style.color]="selectedTabName=='leg'?'white':'gray'">language</i>
                      </div>
                      <div style="margin-top:0.25em">Leg View</div>
                    </div>
                  </li>
                  <!-- <li
                    [ngClass]="{ 'btn-high':selectedTabName=='flightPlan','btn-low pointer':selectedTabName!='flightPlan' }"
                    (click)="clickTab('flightPlan')">
                    <div style="display: flex; margin-left: 0.2em">
                      <div style="margin-top: 0.5em;margin-right: 0.25em;">
                        <i class="material-icons" style="font-size: large;cursor: pointer"
                          [style.color]="selectedTabName=='flightPlan'?'white':'gray'">flight</i>
                      </div>
                      <div style="margin-top:0.25em">Flight Plan Preferences</div>
                    </div>
                  </li> -->
                </ul>
              </div>
              <div style="margin-left:-0.5em; min-width: 31em;">
                <mat-slide-toggle style="transform:scale(.8); height:2em; width: 11em; margin-left: -1em;" color="primary" [checked]="hidePastStop"
                  formControlName="hidePastStop" (change)="hidePastStopChange($event)">Hide Prior
                  Legs<!--Hide Past Stops--></mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em; width: 13em; margin-left:-2em" *ngIf="hasCancelledStop"
                  color="primary" [checked]="includeActiveStopOnly" formControlName="includeActiveStopOnly"
                  (change)="includeActiveStopOnlyChange($event)">Show Active Legs Only</mat-slide-toggle>
                <mat-slide-toggle style="transform:scale(.8); height:2em; margin-left:-1em" color="primary"
                  [checked]="includeDisabledTask" formControlName="includeDisabledTask"
                  (change)="includeDisabledTaskChange($event)">Show Disabled Tasks</mat-slide-toggle>
              </div>


              <div *ngIf="selectedOption=='note'" style="display:inline-flex;">
                <div style="display:flex; font-size: small">
                  <div style="margin-right:0.5em;">
                    <button mat-button class="btn-med"
                      style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
                      (click)="clickAudit('')">
                      <div style="display: flex">
                        <div style="margin-top: 0em;margin-bottom:-0.25em "><i class="material-icons"
                            style="font-size: medium;">manage_search</i></div>
                        <div style="margin-top:-0.25em; font-weight: normal !important;">Audit Trail {{auditHrText}}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <div style="display:flex; margin-top:0em; font-size: small">
                  <div *ngFor="let u of externalUsernameList; let i=index">
                    <div *ngIf="i<2" style="margin-right:0.25em;">
                      <button mat-button class="btn-green" style="align-self:center;min-width: 4em; height:1.5em"
                        (click)="clickAudit(u.userName)">
                        <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
                      </button>
                    </div>
                    <div *ngIf="i==2" style="margin-right:0.25em;">
                      <button mat-button class="btn-green"
                        style="align-self:center;min-width: 1.5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
                        (click)="clickshowMoreExternalUsernameList()">
                        <div style="margin-top:-0.25em; font-weight: normal !important;">...</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div style="display:flex; font-size: small">
                  <div *ngFor="let u of internalUsernameList; let i=index">
                    <div *ngIf="i<2" style="margin-right:0.25em;">
                      <button mat-button class="btn-low"
                        style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
                        (click)="clickAudit(u.userName)">
                        <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
                      </button>
                    </div>
                    <div *ngIf="i==2" style="margin-right:0.25em;">
                      <button mat-button class="btn-low"
                        style="align-self:center;min-width: 1.5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
                        (click)="clickshowMoreInternalUsernameList()">
                        <div style="margin-top:-0.25em; font-weight: normal !important;">...</div>
                      </button>
                    </div>
                  </div>
                </div>

                <div *ngIf="showMoreInternalUsernameList" class="scroll-thin"
                  style="float: left; overflow-y:auto; position:absolute; margin-left: 15em; background-color:#f8f8f8; margin-top:-2em; display: flex; justify-content: space-between; max-height:35em; min-width: 10em; border: solid gray 0.0625em; border-radius:0.25em; padding:0.5em 1em 0.5em 1em; z-index: 1000;">
                  <div>
                    <div *ngFor="let u of internalUsernameList; let i=index"
                      style="margin-left:auto; margin-right:auto">
                      <div *ngIf="i>=2" style="margin-right:0.25em;">
                        <button mat-button class="btn-low"
                          style="align-self:center;min-width: 5em;padding-left:0.25em !important; padding-right:0.25em !important; height:1.5em"
                          (click)="clickAudit(u.userName)">
                          <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div style="float: right">
                    <i class="material-icons" style="cursor: pointer; color:black;font-size: medium"
                      (click)="clickshowMoreInternalUsernameList()">close</i>
                  </div>
                </div>
                <div *ngIf="showMoreExternalUsernameList" class="scroll-thin"
                  style="float: left; overflow-y:auto;  position:absolute; margin-left: 15em;background-color:#f8f8f8; margin-top:-2em; display: flex; justify-content: space-between; max-height:35em; min-width: 10em; border: solid gray 0.0625em; border-radius:0.25em; padding:0.5em 1em 0.5em 1em; z-index: 1000;">
                  <div>
                    <div *ngFor="let u of externalUsernameList; let i=index"
                      style="margin-left:auto; margin-right:auto">
                      <div *ngIf="i>=2" style="margin-right:0.25em;">
                        <button mat-button class="btn-green" style="align-self:center;min-width: 4em;height:1.5em"
                          (click)="clickAudit(u.userName)">
                          <div style="margin-top:-0.25em; font-weight: normal !important;">{{u.userName}}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div style="float: right">
                    <i class="material-icons" style="cursor: pointer; color:black;font-size: medium"
                      (click)="clickshowMoreExternalUsernameList()">close</i>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div style="margin-top: .75em;float:right; padding-left: 0.5em;">
          <a style="cursor: pointer; font-size:small; color: Highlight" (click)="clickLegend();">Legend</a>
        </div>
      </div>
      <div>
        <div>
          <app-ground-stoplist-internal *ngIf="selectedTabName=='groundStop'" [selectedTabName]="selectedTabName"
            [requestInput]="request" [reloadChildPage]="reloadChildPage" [refreshChildColumn]="refreshChildColumn"
            [hidePastStop]="hidePastStop" [lockdownTrip]="lockdownTrip" (updateParentPage)="onUpdateParentPage($event)"
            [includeActiveStopOnly]="includeActiveStopOnly"></app-ground-stoplist-internal>
        </div>
        <div style="display: flex;" *ngIf="selectedTabName=='leg'">
          <div style="margin-right:0.5em">
            <app-ground-stop-leglist [selectedTabName]="selectedTabName" [requestInput]="request"
              [reloadChildPage]="reloadChildPage" [refreshChildColumn]="refreshChildColumn"
              [hidePastStop]="hidePastStop" [includeActiveStopOnly]="includeActiveStopOnly"
              [lockdownTrip]="lockdownTrip" (updateParentPage)="onUpdateParentPage($event)"
              [clientName]="clientName"></app-ground-stop-leglist>
          </div>
          <!--<div style="width:25.5em;">--><!--for map / trip alert--><!--</div>-->
        </div>
        <div>
          <app-flight-plan-leglist *ngIf="selectedTabName=='flightPlan'" [selectedTabName]="selectedTabName"
            [requestInput]="request" [reloadChildPage]="reloadChildPage" [refreshChildColumn]="refreshChildColumn"
            [hidePastStop]="hidePastStop" (updateParentPage)="onUpdateParentPage($event)"></app-flight-plan-leglist>
        </div>
      </div>
    </div>

  </form>
</div>
