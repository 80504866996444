<div *ngIf="upPII && grantAccessToManageManifestProfiles" style="margin-top:0em; padding-top:0em; display:flex">
  <form *ngIf="customer_person_travel_loclist_form" [formGroup]="customer_person_travel_loclist_form">
    <div>    
      <table mat-table *ngIf="recordList" [dataSource]="recordList" class="example-container">
       
        <tr>
          <ng-container matColumnDef="documentType">
            <th mat-header-cell *matHeaderCellDef style="width:8%; border-bottom:none;text-align:left !important">
              <div style="width:100%;padding-right:.25em;padding-left:.25em" class="d-flex justify-content-between">
                <div class="material-icons-outlined">description</div>
                <div>Type</div>
                <div>&nbsp;</div>
              </div>
            </th>
            <td mat-cell *matCellDef="let element"><a *ngIf="element.hasDocument && element.documentNumber==''" style="color:blue; cursor:pointer; text-decoration:underline" (click)="clickPreviewDocument($event, element)">{{element.documentTypeDescription}}</a>{{(element.hasDocument && element.documentNumber!='' || element.hasDocument==false)?element.documentTypeDescription:''}}</td>
          </ng-container>
          <ng-container matColumnDef="documentNumber">
            <th mat-header-cell *matHeaderCellDef style="width:8%; border-bottom:none">
              <div style="margin-top:-0.25em;">Number</div>
            </th>
            <td mat-cell *matCellDef="let element"><a *ngIf="element.hasDocument" style="color:Highlight; cursor:pointer; text-decoration:underline" (click)="clickPreviewDocument($event, element)">{{element.documentNumber}}</a>{{element.hasDocument? '': element.documentNumber}}</td>
            <!--<td mat-cell *matCellDef="let element">{{element.documentNumber}}</td>-->
          </ng-container>
          <ng-container matColumnDef="issuingCountry">
            <th mat-header-cell *matHeaderCellDef style="width:13%; border-bottom:none">
              <div style="margin-top:-0.25em;">Issuing&nbsp;Country</div>
            </th>
            <td mat-cell *matCellDef="let element" (click)="clickEdit($event, element);">{{element.documentIssuingCountryName}}</td>
          </ng-container>
          <ng-container matColumnDef="effectiveDate">
            <th mat-header-cell *matHeaderCellDef style="width:6%; border-bottom:none">
              <div style="margin-top:-0.25em;">Effective<br />Date</div>
            </th>
            <td mat-cell *matCellDef="let element" (click)="clickEdit($event, element);">{{element.documentDOI}}</td>
          </ng-container>
          <ng-container matColumnDef="expirationDate">
            <th mat-header-cell *matHeaderCellDef style="width:6%; border-bottom:none">
              <div style="margin-top:-0.25em;">Expiration<br />Date</div>
            </th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger': element.isExpired}" (click)="clickEdit($event, element);">{{element.documentDOE}}</td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef style="width:18%; border-bottom:none">
              <div style="margin-top:-0.25em;">Name Used</div>
            </th>
            <td mat-cell *matCellDef="let element" (click)="clickEdit($event, element);">{{element.fullName}}</td>
          </ng-container>
          <ng-container matColumnDef="whenToUse">
            <th mat-header-cell *matHeaderCellDef style="width:11%; border-bottom:none">
              <div style="margin-top:-0.25em;">When to Use</div>
            </th>
            <td mat-cell *matCellDef="let element" style="white-space:nowrap" (click)="clickEdit($event, element);">{{element.documentUsageRule}}</td>
          </ng-container>
          <ng-container matColumnDef="subDoc">
            <th mat-header-cell *matHeaderCellDef style="width:10%; border-bottom:none">
              <div style="margin-top:0em;">Visas</div><!--<div style="margin-top:-0.5em;padding-bottom:0.25em">Count</div>-->
            </th>
            <td mat-cell *matCellDef="let element" style="white-space:pre-wrap;" (click)="clickEdit($event, element);">
              <div> {{element.visaCountryName}}</div>
              <div *ngIf="element.expiredVisaCountryName" style="background-color:  #f8d7da; " > {{element.expiredVisaCountryName}}</div>
              <!--{{element.subDocumentCount==0? '&nbsp;': element.subDocumentCount}}-->
            </td>
          </ng-container>
          <ng-container matColumnDef="data_entry_status">
            <th mat-header-cell *matHeaderCellDef style="width:10%">
              <div style="margin-top:-0.25em;">Data Entry<br /> Status</div>
            </th>
            <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger': element.dataEntryStatusID!=2}" (click)="clickEdit($event, element);">{{ element.dataEntryStatusDescription }}</td>
          </ng-container>

          <ng-container matColumnDef="disabled">
            <th mat-header-cell *matHeaderCellDef [hidden]="hideColumn" style="width:3%; border-bottom:none">
              <div style="margin-top:-0.25em;">Disabled</div>
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="hideColumn"  (click)="clickEdit($event, element);">{{element.isActive? '&nbsp;': 'Yes'}}</td>
          </ng-container>

          <ng-container matColumnDef="hasActiveTrip">
            <th mat-header-cell *matHeaderCellDef   style="width:7%; border-bottom:none">
              <div style="margin-top:-0.25em;">Used in Active Trips</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.hasActiveTrip" matTooltipClass="tooltipLineBreak" matTooltip="{{element.activeTripsDetails}}"
                (click)="openCustomerPersonTravelDocActiveTripsComponent($event, element)"
                style="color:Highlight; cursor:pointer; text-decoration:underline">
            
                {{element.hasActiveTrip? 'Yes': '&nbsp;'}}
              </div>
            </td>
          </ng-container>


          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="width:5%; border-bottom:none">
              <div>Action</div><div style="margin-top:0em;padding-bottom:0.25em">
              <!--<a mat-button class="btn-high grid" style="align-self:center" (click)="clickAdd($event, null);">Add</a>--></div>
            </th>
            <td mat-cell *matCellDef="let element">
              <a mat-button class="btn-low grid" *ngIf="userType=='internal'" style="align-self:center" (click)="clickEdit($event, element);">
                Edit
              </a>
              <a mat-button class="btn-low grid" *ngIf="userType=='customer'" style="align-self:center" (click)="clickEdit($event, element);">
                View
              </a>
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
    </div>
    <!--<div>
      <mat-paginator style="border: none;width:108em;margin-top:1em" #paginator *ngIf="recordList && totalPageNumber>1" [length]="totalRecords" [pageSize]="pageSize"
                     [pageIndex]="currentPageIndex" [showFirstLastButtons]="totalPageNumber>2" (page)="pageEvent = pageIndexChange($event)">
      </mat-paginator>
    </div>-->
  </form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
