import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges} from '@angular/core';
import * as atlas from 'azure-maps-control';
import { AuthenticationType, ControlPosition } from 'azure-maps-control';
import { Subscription } from 'rxjs';
import { AuthenticateService } from '../services/authenticate.service';
import { VendorService } from '../services/vendor.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ResponseModel } from '../models/response.model';
import { MapProfileModel } from '../models/map-profile.model';
import { getCoords } from '@turf/turf';
import { VendorMapProfileEditComponent } from './vendor-map-profile-edit.component';

@Component({
  selector: 'app-vendor-map-profile-list',
  templateUrl: './vendor-map-profile-list.component.html',
  styleUrls: ['./vendor-map-profile-list.component.css']
})
export class VendorMapProfileListComponent implements OnChanges {

  @Input() defaultLat: number;
  @Input() defaultLon: number;
  @Input() markerTitle: string;
  @Input() vendorGUID: string;
  @Input() includeDisabledRecords: boolean
  @Input() airportID: number;
  subscriptions: Subscription[] = [];
  map: any;
  points: any = [];
  mapProfileList: MapProfileModel[];
  displayColumn:string[]=['locationDescription','notes','share_client','disabled','action']
  tempLat: number;
  tempLong: number;
  
  constructor(private readonly _authService: AuthenticateService, private _vendorService: VendorService, private readonly _dialog: MatDialog) {

  }

  ngOnChanges(changes: SimpleChanges) {
    this._authService.updateAccessTime();
    this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
      this.includeDisabledRecords = false;
    this.getData();
  }

  getData() {
    this._vendorService.getMapProfileByVendorGUID<ResponseModel<MapProfileModel[]>>(this.vendorGUID, !this.includeDisabledRecords).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.mapProfileList = response.model;
        }
        else {
          if (response.code == "401")
            this._authService.logout();
        }
      }
      this.createMap();
    });

  }


  createMap() {
    if (this.map)
      this.map.dispose();
    this.map = new atlas.Map('map-profile', {

      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "satellite_road_labels",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      self.map.controls.add([
        new atlas.control.ZoomControl(),
        new atlas.control.StyleControl({ mapStyles: ['satellite_road_labels', 'road_shaded_relief'] })
      ], {
        position: atlas.ControlPosition.TopRight
      });

      self.mapProfileList.forEach((v, i) => {
        var marker = new atlas.HtmlMarker({
          text: v.markerText,
          color:v.iconColor,
          position: [v.longitude, v.latitude],
          htmlContent: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="37" viewBox="0 0 30 37" xml:space="preserve"><rect x="0" y="0" rx="8" ry="8" width="30" height="30" fill="{color}"/><polygon fill="{color}" points="10,29 20,29 15,37 10,29"/><text x="15" y="20" style="font-size:16px;font-family:arial;fill:#ffffff;" text-anchor="middle">{text}</text></svg>',
          //popup: new atlas.Popup({
          //  content: '<div style="padding:10px">' + v.mapLocationDescription + '</div>',
          //  pixelOffset: [0, -30],
          //  closeButton:false
          //})
        });

        self.map.markers.add(marker);
        self.map.events.add('click', marker, () => {
          marker.togglePopup();
        });
        self.points.push([v.longitude, v.latitude]);
        dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {         
          name: v.mapLocationDescription
        }));
      });


      var popupTemplate = "<div style='padding: 10px'>{name}</div>";
      var symbolLayer = new atlas.layer.SymbolLayer(dataSource);
      self.map.layers.add(symbolLayer);
      var popup = new atlas.Popup({
        pixelOffset: [0, -30],
        closeButton: false
      });
      //Add a hover event to the symbol layer.
      self.map.events.add('mouseover', symbolLayer, function (e) {
        //Make sure that the point exists.
        if (e.shapes && e.shapes.length > 0) {
          var pos = e.position;
          var offset = [0, 0];
          var properties;
          var content, coordinate;

          if (e.shapes[0] instanceof atlas.Shape) {
            properties = e.shapes[0].getProperties();

            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].getType() === 'Point') {
              coordinate = e.shapes[0].getCoordinates();
              content = popupTemplate.replace(/{name}/g, properties.name);
              offset = [0, -18];
            }
          } else {
            properties = e.shapes[0].properties;
            //If the shape is a point feature, show the popup at the points coordinate.
            if (e.shapes[0].type === 'Point') {
              coordinate = e.shapes[0].geometry.coordinates;
              content = popupTemplate.replace(/{name}/g, properties.name);
              offset = [0, -18];
            }
          }
          popup.setOptions({
            //Update the content of the popup.
            content: content,

            //Update the popup's position with the symbol's coordinate.
            position: coordinate

          });
          //Open the popup.
          popup.open(self.map);
        }
      });

      self.map.events.add('mouseleave', symbolLayer, function () {
        popup.close();
      });

      var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

      if (self.mapProfileList?.length <= 1) {
        let ctr = [self.defaultLon, self.defaultLat];
        if (self.mapProfileList?.length == 1)
          ctr = [self.mapProfileList[0].longitude, self.mapProfileList[0].latitude];
        self.map.setCamera({
          center: ctr,
          zoom: 15
        });
      }
      else {
        self.map.setCamera({
          bounds: bbox,
          padding: 40
        });
      }

    });

  }


  clickEdit(mapProfile: MapProfileModel) {
    let mapProfileID = 0;
    if (mapProfile != null)
      mapProfileID = mapProfile.mapProfileID;
    let s = "";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.width = "64em";
    //dialogConfig.maxHeight = "95vh";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.hasBackdrop = false;
    dialogConfig.data = { allProfiles: this.mapProfileList.filter(x=>x.isActive==true), mapProfileID: mapProfileID, defaultLat:this.defaultLat, defaultLon:this.defaultLon, airportID:this.airportID, vendorGUID:this.vendorGUID };
    const dialogRef = this._dialog.open(VendorMapProfileEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result==true) {
        this.getData();
      }

    });
  }

}
