import { Component, OnInit, Input, Inject } from '@angular/core';
import * as atlas from 'azure-maps-control';
import { AuthenticationType, ControlPosition } from 'azure-maps-control';
import { AuthenticateService } from '../services/authenticate.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ResponseModel } from '../models/response.model';
import { MapProfileModel } from '../models/map-profile.model';
import { getCoords } from '@turf/turf';
import { CommonService } from '../services/common.service';
import { Observable, forkJoin, of } from 'rxjs';
import { MapLocationModel } from '../models/map-location.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VendorService } from '../services/vendor.service';

@Component({
  selector: 'app-vendor-map-profile-edit',
  templateUrl: './vendor-map-profile-edit.component.html',
  styleUrls: ['./vendor-map-profile-edit.component.css']
})
export class VendorMapProfileEditComponent implements OnInit {
  vendor_map_profile_edit_form: UntypedFormGroup;
  @Input() defaultLat: number;
  @Input() defaultLon: number;
  map: any;
  points: any = [];
  tempLat: number;
  tempLong: number;
  mapProfileID: number;
  mapLocationList: MapLocationModel[];
  mapLocationDescription: string;
  mapLocationID: number;
  iconColor: string=null;
  iconName: string = "grade";
  isPublic: boolean = true;
  isActive: boolean = true;
  notes: string;
  latitude: number;
  longitude: number;
  modifiedBy: string;
  modifiedDate: string;
  hasMarker: boolean = false;
  marker: atlas.HtmlMarker;
  vendorGUID: string;
  airportID: number;
  showSuccessMsg: boolean = false;
  errMsg: string = "";
  submitted: boolean = false;
  allProfiles: MapProfileModel[];
  toggleMarkers: boolean = false;
  allMarkers: atlas.HtmlMarker[] = [];

  constructor(private readonly _dialogRef: MatDialogRef<VendorMapProfileEditComponent>, private readonly _authService: AuthenticateService, private readonly _commonService: CommonService, private readonly _vendorService: VendorService, private readonly _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private _data: any, private readonly _formBuilder: UntypedFormBuilder) {
    this.mapProfileID = _data.mapProfileID;
    this.defaultLat = _data.defaultLat;
    this.defaultLon = _data.defaultLon;
    this.airportID = _data.airportID;
    this.vendorGUID = _data.vendorGUID;
    this.allProfiles = _data.allProfiles;
  }

  ngOnInit() {
    this._authService.updateAccessTime();

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.mapLocationList = responses[0].model;

        }
        else {
          if (responses[0].code == "401")
            this._authService.signOut();
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          let mapProfile = responses[1].model;
          this.latitude = mapProfile.latitude;
          this.longitude = mapProfile.longitude;
          this.iconColor = mapProfile.iconColor;
          this.iconName = mapProfile.iconName;
          this.notes = mapProfile.notes;
          this.isPublic = mapProfile.isPublic;
          this.isActive = mapProfile.isActive;
          this.mapLocationID = mapProfile.mapLocationID;
          this.modifiedBy = mapProfile.modifiedBy;
          this.modifiedDate = mapProfile.modifiedDate;
        }
        else {
          if (responses[1].code == "401")
            this._authService.signOut();
        }
      }
      this.createMap();
      this.initControls();
    });
  }

  getAllData(): Observable<any[]> {
    let getMapLocationResponse = this._commonService.getMapLocationList();
    let getMapProfileResponse;
    if (this.mapProfileID == 0)
      getMapProfileResponse = of(null)
    else
      getMapProfileResponse = this._commonService.getMapProfileByMapProfileID(this.mapProfileID);    

    return forkJoin([getMapLocationResponse, getMapProfileResponse]);
  }

  
  initControls() {
    this.vendor_map_profile_edit_form = this._formBuilder.group({
      map_location: [this.mapLocationID, Validators.required],
      notes: this.notes,
      isActive: this.isActive,
      isPublic:this.isPublic
    });
  }


  get f() { return this.vendor_map_profile_edit_form.controls; }

  clickSave() {
    this.submitted = true;
    if (this.marker == undefined) {
      this.errMsg = "A location marker is required."
      return;
    }
    if (this.vendor_map_profile_edit_form.invalid)
      return;
    let request = new MapProfileModel();
    request.mapProfileID = this.mapProfileID;
    request.vendorGUID = this.vendorGUID;
    request.airportID = this.airportID;
    request.mapLocationID = this.f.map_location.value;
    request.latitude = this.latitude;
    request.longitude = this.longitude;
    request.notes = this.f.notes.value;
    request.isActive = this.f.isActive.value;
    request.isPublic = Boolean(this.f.isPublic.value);
    this._vendorService.saveVendorMapProfile<ResponseModel<number>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.mapProfileID = response.model;
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
            this._dialogRef.close(true);
          },1000)
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
          else {
            this.errMsg = "An error has occurred."
          }
        }
      }
    })
  }

  createMap() {
    if (this.map)
      this.map.dispose();
    this.map = new atlas.Map('map-profile-edit', {

      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "satellite",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map2 SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: '0ZmwW5PO2QvFn8pwz_BpZe4BIlwfW_5Kt59aOQNR5Qo'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      self.map.controls.add([
        new atlas.control.ZoomControl()
      ], {
        position: atlas.ControlPosition.TopRight
      });

      if (self.mapProfileID != 0) {

        self.marker = new atlas.HtmlMarker({
          color:self.iconColor,
          htmlContent: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="37" viewBox="0 0 30 37" xml:space="preserve"><rect x="0" y="0" rx="8" ry="8" width="30" height="30" fill="{color}"/><polygon fill="{color}" points="10,29 20,29 15,37 10,29"/><text x="15" y="20" style="font-size:16px;font-family:arial;fill:#ffffff;" text-anchor="middle">{text}</text></svg>',
          position: [self.longitude, self.latitude],
          draggable: true
        });
        self.map.events.add('drag', self.marker, function () {
          var pos = self.marker.getOptions().position;
          self.longitude = Math.round(pos[0] * 100000) / 100000;
          self.latitude = Math.round(pos[1] * 100000) / 100000;

        });

        self.map.markers.add(self.marker);
        self.hasMarker = true;

      }
      else {
        self.map.events.add('contextmenu', function (e) {
          self.marker = new atlas.HtmlMarker({
            color: self.iconColor,
            position: e.position,
            htmlContent: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="37" viewBox="0 0 30 37" xml:space="preserve"><rect x="0" y="0" rx="8" ry="8" width="30" height="30" fill="{color}"/><polygon fill="{color}" points="10,29 20,29 15,37 10,29"/><text x="15" y="20" style="font-size:16px;font-family:arial;fill:#ffffff;" text-anchor="middle">{text}</text></svg>',
            draggable: true,
            pixelOffset: [0, 0]
          });

         
          self.map.events.add('drag', self.marker, function () {
            var pos = self.marker.getOptions().position;
            self.longitude = Math.round(pos[0] * 100000) / 100000;
            self.latitude = Math.round(pos[1] * 100000) / 100000;
           
          });
          if (!self.hasMarker) {
            self.longitude = Math.round(e.position[0] * 100000) / 100000;
            self.latitude = Math.round(e.position[1] * 100000) / 100000;

            self.map.markers.add(self.marker);
            self.hasMarker = true;
          }
        });

      }


      self.allProfiles.forEach((v, i) => {
        if (v.mapProfileID != self.mapProfileID) { 
        var marker = new atlas.HtmlMarker({
          text: v.markerText,
          color: v.iconColor,
          position: [v.longitude, v.latitude],
          htmlContent: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="37" viewBox="0 0 30 37" xml:space="preserve"><rect x="0" y="0" rx="8" ry="8" width="30" height="30" fill="{color}"/><polygon fill="{color}" points="10,29 20,29 15,37 10,29"/><text x="15" y="20" style="font-size:16px;font-family:arial;fill:#ffffff;" text-anchor="middle">{text}</text></svg>',
          popup: new atlas.Popup({
            content: '<div style="padding:10px">' + v.mapLocationDescription + '</div>',
            pixelOffset: [0, -30],
            closeButton: false
          })
        });

        self.allMarkers.push(marker);

        self.points.push([v.longitude, v.latitude]);
      }
      });

      var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

      if (self.allProfiles?.length <= 1) {
        let ctr = [self.defaultLon, self.defaultLat];
        if (self.allProfiles?.length == 1)
          ctr = [self.allProfiles[0].longitude, self.allProfiles[0].latitude];
        self.map.setCamera({
          center: ctr,
          zoom: 15
        });
      }
      else {
        self.map.setCamera({
          bounds: bbox,
          padding: 40
        });
      }

        //let ctr = [self.defaultLon, self.defaultLat];
        //if (self.mapProfileID != 0)
        //  ctr = [self.longitude, self.latitude];
        //self.map.setCamera({
        //  center: ctr,
        //  zoom: 15
        //});
  
      
    });

  }

  updateIcon() {
    this.iconColor = this.mapLocationList.find(x => x.mapLocationID == this.f.map_location.value).iconColor;
    this.iconName = this.mapLocationList.find(x => x.mapLocationID == this.f.map_location.value).iconName;
    if (this.marker != undefined) {
      this.marker.setOptions({
        color:this.iconColor,
        htmlContent: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="37" viewBox="0 0 30 37" xml:space="preserve"><rect x="0" y="0" rx="8" ry="8" width="30" height="30" fill="{color}"/><polygon fill="{color}" points="10,29 20,29 15,37 10,29"/><text x="15" y="20" style="font-size:16px;font-family:arial;fill:#ffffff;" text-anchor="middle">{text}</text></svg>',
      });
    }
 
  }

  updateMarkers() {
    if (this.toggleMarkers == true) {
      this.allMarkers.forEach(x => {
        this.map.markers.add(x);
        this.map.events.add('click', x, () => {
          x.togglePopup();
        });
      })
    }
    else {
      this.map.markers.remove(this.allMarkers);
    }
    //this.allProfiles.forEach((v, i) => {
    //  var marker = new atlas.HtmlMarker({
    //    text: v.markerText,
    //    color: v.iconColor,
    //    position: [v.longitude, v.latitude],
    //    htmlContent: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30" height="37" viewBox="0 0 30 37" xml:space="preserve"><rect x="0" y="0" rx="8" ry="8" width="30" height="30" fill="{color}"/><polygon fill="{color}" points="10,29 20,29 15,37 10,29"/><text x="15" y="20" style="font-size:16px;font-family:arial;fill:#ffffff;" text-anchor="middle">{text}</text></svg>',
    //    popup: new atlas.Popup({
    //      content: '<div style="padding:10px">' + v.mapLocationDescription + '</div>',
    //      pixelOffset: [0, -30],
    //      closeButton:false
    //    })
    //  });

    //  this.map.markers.add(marker);
    //  this.allMarkers.push(marker);
    //  this.map.events.add('click', marker, () => {
    //    marker.togglePopup();
    //  });
    //  this.points.push([v.longitude, v.latitude]);
    //  //dataSource.add(new atlas.data.Feature(new atlas.data.Point([v.longitude, v.latitude]), {
    //  //  name: v.mapLocationDescription
    //  //}));
    //});

  }
}


