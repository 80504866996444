import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseModel } from '../models/response.model';
import { VendorModel } from '../models/vendor.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { VendorService } from '../services/vendor.service';
import { VendorProfileEditDialogComponent } from './vendor-profile-edit-dialog.component';
import { VendorProfileListComponent } from './vendor-profile-list.component';

@Component({
  selector: 'app-vendor-profile-setup',
  templateUrl: './vendor-profile-setup.component.html',
  styleUrls: ['./vendor-profile-setup.component.css']
})
/** vendor-profile-setup-edit-dialog component*/
export class VendorProfileSetupComponent implements OnInit {
  vendor_profile_setup_form: UntypedFormGroup;
  vendorGUID: string;
  showSpin: boolean = false;
  childHasUpdates: boolean = false;
  errMsg: string = "";
  vendorName: string;
  legalEntityName: string;
  contractStatus: string;
  location: string;
  rating: string;
  creationDate: string;
  accountType: string;
  roleList: string;
  facilityType: string;
  complianceStatus: string;
  regulatoryStatus: string;
  operationsStatus: string;
  accountingStatus: string;
  icao: string;
  latitude: number;
  longitude: number;
  modifiedBy: string;
  modifiedDate: string;
  selectedTabName: string = "notes";
  includeDisabledRecords: boolean;
  airportID: number;
  isPopup: boolean = false;
  grantAccessVendorProfiles: boolean = false;
    /** vendor-profile-setup-edit-dialog ctor */
  constructor(@Optional() private _dialogRef: MatDialogRef<VendorProfileSetupComponent>, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _vendorService: VendorService, @Optional() @Inject(MAT_DIALOG_DATA) private _data: any,) {
    if (_data) {
      this.vendorGUID = _data.vendorGUID;
      this.isPopup = true;
    }

  }

  ngOnInit() {
    this.grantAccessVendorProfiles = false;
    if (localStorage.getItem('up.vPro') == 'true') {
      this.grantAccessVendorProfiles = true;
    }
    if (!this.grantAccessVendorProfiles)
      return;
    if (!this.isPopup) {
      this.vendorGUID = this._route.snapshot.paramMap.get('vendorGUID');
      if (this.vendorGUID == null || this.vendorGUID == "" || this.vendorGUID == undefined) {
        this._route.queryParams.subscribe(params => {
          this.vendorGUID = params['vendorGUID'];

        });
      }
    }
    this.getData();
      this.initControls();
  }

  getModifiedByHandler(getModifiedBy: string) {

    this.f.modifiedBy.setValue(getModifiedBy);
  }
  getModifiedDateHandler(getModifiedDate: string) {

    this.f.modifiedDate.setValue(getModifiedDate);
  }

  updateParentPageHandler(e: any) {

    this.childHasUpdates = e;
  }

  getData() {

    this._vendorService.getVendorProfileByVendorGUID<ResponseModel<VendorModel>>(this.vendorGUID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let vendor = response.model;
          this.vendorName = vendor.vendorName;
          this.legalEntityName = vendor.legalEntityName;
          this.contractStatus = vendor.contractStatusDescription;
          this.location = vendor.vendorNameCom;
          this.rating = vendor.ratingDescription;
          this.facilityType = vendor.facilityDescription;
          this.creationDate = vendor.accountCreationDate;
          this.roleList = vendor.roleList;
          this.complianceStatus = vendor.vendorComplianceStatusDescription;
          this.regulatoryStatus = vendor.regulatoryStatus;
          this.operationsStatus = vendor.operationStatus;
          this.accountingStatus = vendor.accountingStatus;
          this.modifiedBy = vendor.modifiedBy;
          this.modifiedDate = vendor.modifiedDate;
          this.accountType = vendor.vendorStatusDescription;
          this.latitude = vendor.airportLatitude;
          this.longitude = vendor.airportLongitude;
          this.airportID = vendor.airportID;
          this.icao = vendor.icao;
        }
        else {
          if (response.code == "401")
            this._authService.signOut();
          else
            this.errMsg = "An error occurred.";
        }


      }
      this.initControls();
    });
   

  }

  initControls() {
    this.vendor_profile_setup_form = this._formBuilder.group({
      vendorName: this.vendorName,
      legalEntityName:this.legalEntityName,
      location: this.location,
      compliance_status: this.complianceStatus,
      contractStatus:this.contractStatus,
      rating: this.rating,
      creationDate: this.creationDate,
      account_type: this.accountType,
      facility_type: this.facilityType,
      role: this.roleList,
      regulatory_status: this.regulatoryStatus,
      accounting_status: this.accountingStatus,
      operation_status: this.operationsStatus,
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate,
      includeDisabledRecords: false
    });
  }

  get f() { return this.vendor_profile_setup_form.controls; }

  clickClose() {
    if (this.isPopup) {
      this._dialogRef.close(true)
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.hasBackdrop = false;
      dialogConfig.panelClass = "custom-dialog-container";
      dialogConfig.height = "50em";

      dialogConfig.data = {
        pageFrom:"vp"
      };


      this._dialog.open(VendorProfileListComponent, dialogConfig);
    }
    else
      this._router.navigate(['/vendors/vendor-profile-list', { pf: "vp" }]);

  }


  clickTab(s: string) {

    if (this.selectedTabName != s)
      this.selectedTabName = s;

  }
  includeDisabledRecordsOnChange(event: any) {
    this.includeDisabledRecords = event.checked;
  }

  clickEdit() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //dialogConfig.width = "46.5em";//"1000px";
    //dialogConfig.height = "13em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: 'Edit Profile', vendorGUID: this.vendorGUID, pageName: 'main' };
    const dialogRef = this._dialog.open(VendorProfileEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.actionName == "edit") {
        this.getData();
      }
      else {
        this.showSpin = false;
      }
    });

  }
  openInNew() {
    this._dialogRef.close();
    window.open('/vendors/vendor-profile-setup?vendorGUID=' + this.vendorGUID, "_blank");
  }
}
