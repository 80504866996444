import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { AdvisoryLookupModel } from '../models/advisory.model';
import { HotelModel } from '../models/hotel.model';

@Component({
  selector: 'app-advisory-tags',
  templateUrl: './advisory-tags.component.html',
  styleUrls: ['./advisory-tags.component.css']
})

export class AdvisoryTagsComponent implements OnInit {

  advisory_tags_form: UntypedFormGroup;
  isDialog: boolean = false;

  isAirport: boolean;
  advisoryTagsList: AdvisoryLookupModel[] = [];
  displayedColumn: string[] = ['select',   'advisoryTag'];
  filteredAdvisoryTagsList: AdvisoryLookupModel[] = [];
  // hotelList: HotelModel[] = [];
  // filteredHotel: HotelModel;

  title: string = '';
  dialogTitle: string = '';
  selectedhotelGUID: string; //= 'd43db1f2-8257-4816-a863-cce854cfffef';

  constructor(@Optional() private readonly _dialogRef: MatDialogRef<AdvisoryTagsComponent>, private readonly _commonService: CommonService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any, private readonly _authService: AuthenticateService,
    private readonly _formBuilder: UntypedFormBuilder,
  ) {

    if (this.data) {
      this.isDialog = true;
      this.advisoryTagsList = data.advisoryTagsList;
      // this.filteredAdvisoryTagsList = data.filteredAdvisoryTagsList;


      this.dialogTitle = 'Advisory tags';



      // // // // // this.advisoryTagsList?.forEach(x => x.selected = false);

      // for (let advisoryTag of this.advisoryTagsList) {
      //   // Check if the current tag is in the filteredAdvisoryTagsList
      //   if (this.filteredAdvisoryTagsList?.some(filteredTag => filteredTag.lookupId === advisoryTag.lookupId)) {
      //     advisoryTag.selected = true;
      //   }
      // }

    }

  }

  ngOnInit() {

    // this._authService.updateAccessTime();

    this.initControls();


  }

  initControls() {
    this.advisory_tags_form = this._formBuilder.group({
      // userComments: [this.userComments]
      hotelGUID: [this.selectedhotelGUID]
    });


  }

  get f() { return this.advisory_tags_form.controls; }

  tagOnChange(e: any, element: AdvisoryLookupModel) {
    element.selected = e.checked;

    // if (e.checked) {
    //   this.filteredAdvisoryTagsList.push(element);
    // }
    // else {
    //   let index = this.filteredAdvisoryTagsList.findIndex(d => d.lookupId == element.lookupId)
    //   //     this.selectedDocTypeList.splice(index, 1 );

    //   this.filteredAdvisoryTagsList.splice(index, 1);
    // }
  }


  clickClose(val) {
    this._dialogRef.close(val);
  }


  clickUpdateTags() {

    this.filteredAdvisoryTagsList = this.advisoryTagsList.filter(x => x.selected == true);

    this._dialogRef.close(this.filteredAdvisoryTagsList);
  }

  // clickUpdateHotels() { 
  //   this._dialogRef.close(this.filteredHotel);
  // }

  // changeHotel(e: any, element) {
  //   this.hotelList.forEach(x => x.selected = false); 
  //   element.selected = true;
  //   this.selectedhotelGUID = element.hotelGUID;
  //   this.filteredHotel = element;
  // }

}



