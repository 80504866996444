import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-spin-details',
  templateUrl: './spin-details.component.html',
  styleUrls: ['./spin-details.component.css']
})
export class SpinDetailsComponent implements OnInit {
  isClose: boolean = false;

  ngOnInit() {

  }

  clickClose() {
    this.isClose = !this.isClose;
  }
}
