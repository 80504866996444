import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, Optional } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { AuthenticateService } from '../services/authenticate.service'
import { VendorModel } from '../models/vendor.model';
import { Observable, forkJoin, merge } from 'rxjs';
import { CommonService } from '../services/common.service';
import { ResponseModel } from '../models/response.model';
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { VendorService } from '../services/vendor.service';
import { VendorComplianceStatusModel } from '../models/vendor-compliance-status.model';
import { RatingModel } from '../models/rating.model';
import { ContractStatusModel } from '../models/contract-status.model';
import { CountryModel } from '../models/country.model';
import { VendorRoleModel } from '../models/vendor-roles.model';
import { MatSort, Sort } from '@angular/material/sort';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { VendorStatusModel } from '../models/vendor-status.model';
import { VendorProfileEditDialogComponent } from './vendor-profile-edit-dialog.component';
import { MaintenanceDashboardComponent } from '../common-utility/maintenance-dashboard.component';
import { ThirdPartyVendorsComponent } from '../common-utility/third-party-vendors.component';
import { VendorProfileSetupComponent } from './vendor-profile-setup.component';

export interface SortFields {
  displayName: string;
  columnName: string;
  selected: boolean;
}

@Component({
  selector: 'app-vendor-profile-list',
  templateUrl: './vendor-profile-list.component.html',
  styleUrls: ['./vendor-profile-list.component.css']
})

export class VendorProfileListComponent  implements OnInit, AfterViewInit {
  vendor_profile_list_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  vendor_account: string = "";
  country_select: string = "";
  airport: string = "";
  compliance_status: string = "";
  contract_status: string = "";
  rating: string = "";
  recordList: VendorModel[] = [];
  complianceStatusList: VendorComplianceStatusModel[];
  ratingList: RatingModel[];
  contractStatusList: ContractStatusModel[];
  countryList: CountryModel[];
  vendorRoleList: VendorRoleModel[] = [];
  vendorStatusList: VendorStatusModel[];
  selectedVendorStatusID: number;
  limitTo: number = 10;
  scrHeight: number;
  pageFrom: string = "";
  isPopup: boolean=false;
  displayedColumn: string[] = ['country_name', 'airport', 'vendor_name', 'falicity_type', 'vendor_role', 'contract_status', 'rating', 'method_of_payment', 'compliance_status', 'related_vendors',
    'service_arrangement','flight_brief_note_count', 'action'];
  sortBy: SortFields[] = [
    { displayName: 'Country Name', columnName: 'CountryName', selected:false },
    { displayName: 'Airport', columnName: 'Airport', selected: false },
    { displayName: 'Vendor Name', columnName: 'VendorName', selected: false }
  ]
  dataSource = new MatTableDataSource<VendorModel>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  request: VendorModel;
  grantAccessVendorProfiles: boolean = false;


  constructor(@Optional() private _dialogRef: MatDialogRef<VendorProfileListComponent>, private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _vendorService: VendorService, @Optional() @Inject(MAT_DIALOG_DATA) private _data: any,) {
      if (_data) {
        this.airport = _data.icao;
        this.isPopup = true;
        this.pageFrom = _data.pageFrom;
      }

  }


  ngOnInit() {
    this.grantAccessVendorProfiles = false;
    if (localStorage.getItem('up.vPro') == 'true') {
      this.grantAccessVendorProfiles = true;
    }
    if (!this.grantAccessVendorProfiles)
      return;
    if (!this.isPopup) {
      this.pageFrom = this._route.snapshot.paramMap.get('pf');
      if (this.pageFrom == null || this.pageFrom == "" || this.pageFrom == undefined) {
        this._route.queryParams.subscribe(params => {
          this.pageFrom = params['pf'];

        });
      }
      this.airport = this._route.snapshot.paramMap.get('icao');
      if (this.airport == null || this.airport == "" || this.airport == undefined) {
        this._route.queryParams.subscribe(params => {
          this.airport = params['icao'];

        });
      }
      if (this.pageFrom == null || this.pageFrom == undefined) {
        this.pageFrom = "";
      }
    }
      if (this.pageFrom == "") {
        localStorage.removeItem('vendor.icao');
        localStorage.removeItem('vendor.name');
        localStorage.removeItem('vendor.country');
        localStorage.removeItem('vendor.role');
        localStorage.removeItem('vendor.comstat');
        localStorage.removeItem('vendor.rating');
        localStorage.removeItem('vendor.constat');
        localStorage.removeItem('vendor.tab');
        localStorage.removeItem('vendor.order');
        localStorage.removeItem('vendor.status');

      }
    
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.selectedTabName = 'Active';
    this.selectedVendorStatusID = 1;
    this.scrHeight = window.innerHeight;
    //if (this.scrHeight < 900) {
    //  this.pageSize = 10;
    //}
    //else {
    //  this.pageSize = 15;
    //}
    this.initControls();
    this.getData();
    
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }
  getAllData(): Observable<any[]> {
    let countryResponse = this._commonService.getCountryList();
    let roleResponse = this._vendorService.getVendorRoleList();
    let contractStatusResponse = this._vendorService.getContractStatusList();
    let ratingResponse = this._commonService.getRatingList();
    let complianceStatusResponse = this._vendorService.getVendorComplianceStatusList();
    let vendorStatusResponse = this._vendorService.getVendorStatusList();
    return forkJoin([countryResponse, roleResponse, contractStatusResponse, ratingResponse, complianceStatusResponse, vendorStatusResponse]);

  }

  getData() {
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.countryList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }

      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.vendorRoleList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }

      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.contractStatusList = responses[2].model;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }

      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.ratingList = responses[3].model;
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }

      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          this.complianceStatusList = responses[4].model;
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          this.vendorStatusList = responses[5].model;
        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (this.pageFrom == "vp") {
        if (localStorage.getItem('vendor.icao') != null && localStorage.getItem('vendor.icao') != "" && localStorage.getItem('vendor.icao') != "undefined") {
          this.f.airport.setValue(localStorage.getItem('vendor.icao'));
        }
        if (localStorage.getItem('vendor.name') != null && localStorage.getItem('vendor.name') != "" && localStorage.getItem('vendor.name') != "undefined") {
          this.f.vendor_account.setValue(localStorage.getItem('vendor.name'));
        }
        if (localStorage.getItem('vendor.country') != null && localStorage.getItem('vendor.country') != "" && localStorage.getItem('vendor.country') != "undefined") {
          this.f.country_select.setValue(localStorage.getItem('vendor.country'));
        }
        if (localStorage.getItem('vendor.role') != null && localStorage.getItem('vendor.role') != "" && localStorage.getItem('vendor.role') != "undefined") {
          this.vendorRoleList.forEach(v => {
            if (localStorage.getItem('vendor.role').indexOf(v.vendorRoleID.toString()) != -1)
              v.selected = true;
          });
          this.f.checkRoles.setValue(this.vendorRoleList.filter(v=>v.selected==true));
        }
        if (localStorage.getItem('vendor.comstat') != null && localStorage.getItem('vendor.comstat') != "" && localStorage.getItem('vendor.comstat') != "undefined") {
          this.f.compliance_status.setValue(Number(localStorage.getItem('vendor.comstat')));
        }
        if (localStorage.getItem('vendor.rating') != null && localStorage.getItem('vendor.rating') != "" && localStorage.getItem('vendor.rating') != "undefined") {
          this.f.rating.setValue(Number(localStorage.getItem('vendor.rating')));
        }
        if (localStorage.getItem('vendor.constat') != null && localStorage.getItem('vendor.constat') != "" && localStorage.getItem('vendor.constat') != "undefined") {
          this.f.contract_status.setValue(Number(localStorage.getItem('vendor.constat')));
        }
        if (localStorage.getItem('vendor.tab') != null && localStorage.getItem('vendor.tab') != "" && localStorage.getItem('vendor.tab') != "undefined") {
          this.selectedTabName = localStorage.getItem('vendor.tab');
        }
        if (localStorage.getItem('vendor.status') != null && localStorage.getItem('vendor.status') != "" && localStorage.getItem('vendor.status') != "undefined") {
          this.selectedVendorStatusID = Number(localStorage.getItem('vendor.status'));
        }
        this.getSearchResults();
      }
      if (this.pageFrom == 'airportBrief')
        this.getSearchResults();
      this.showSpin = false;
    });
  }

  initControls() {
    this.vendor_profile_list_form = this._formBuilder.group({
      vendor_account: this.vendor_account,
      country_select: this.country_select,
      airport: this.airport,
      compliance_status: this.compliance_status,
      contract_status: this.contract_status,
      rating: this.rating,
      checkRoles: ''

    });
  }

  get f() { return this.vendor_profile_list_form.controls; }


  checkVendorRole(event: any, role: VendorRoleModel) {
    if (event.source.selected)
      role.selected = true;
    else
      role.selected = false;
    //this.getSearchResults();
  }

  clickReset() {
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.dataSource.data = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;
    this.selectedTabName = 'Active';
    
    this.vendor_account = "";
    this.country_select = "";
    this.airport = "";
    this.compliance_status = "";
    this.contract_status = "";
    this.rating = "";
    this.loading = false;
    this.vendorRoleList.forEach(v => {
      v.selected = false;
    });
   
    localStorage.removeItem('vendor.icao');
    localStorage.removeItem('vendor.name');
    localStorage.removeItem('vendor.country');
    localStorage.removeItem('vendor.role');
    localStorage.removeItem('vendor.comstat');
    localStorage.removeItem('vendor.rating');
    localStorage.removeItem('vendor.constat');
    localStorage.removeItem('vendor.tab');
    localStorage.removeItem('vendor.order');
    this.initControls();
    this.f.checkRoles.setValue(this.vendorRoleList.filter(v => v.selected == true));

  }

  clickShowAllRecords() {
    this.errMsg = "";
    //if (this.f.customer_select.value == "") {
    //  this.errMsg = "Please select a customer";
    //  return;
    //}
    this.f.text_search.setValue('');
    this.getSearchResults();

  }

  onSubmit() {
    this.errMsg = ""
    //if (this.f.customer_select.value == "") {
    //  this.errMsg = "Please select a customer";
    //  return;
    //}
    if (this.f.text_search.value == "") {
      this.errMsg = "Please enter a keyword.";
      return;
    }

    this.loading = true;
    this.getSearchResults();
  }

  getSearchResults() {
    this.errMsg = "";
    this.recordList = [];
    this.totalRecords = 0;
    this.dataSource.data = [];    
    this._authService.updateAccessTime();
    this.request = new VendorModel();
    this.request.pageIndex = this.currentPageIndex;
    this.request.pageSize = this.pageSize;
    this.request.totalRecords = 0;
    if (this.f.vendor_account.value != "")
      this.request.vendorName = this.f.vendor_account.value;
    if (this.f.contract_status.value != "" && !isNaN(this.f.contract_status.value))
        this.request.contractStatusID = Number(this.f.contract_status.value);
    if (this.f.airport.value != "")
      this.request.icao = this.f.airport.value;
    if (this.f.country_select.value != "")
      this.request.countryGUID = this.f.country_select.value;
    if (this.f.compliance_status.value != "" && !isNaN(this.f.compliance_status.value))
        this.request.complianceStatusID = Number(this.f.compliance_status.value);
    if (this.f.rating.value != "" && !isNaN(this.f.rating.value))
        this.request.ratingID = Number(this.f.rating.value);
    let selectedRoles = this.vendorRoleList.filter(v => v.selected == true);
    if (selectedRoles.length > 0)
      this.request.roleList = Array.prototype.map.call(selectedRoles, s => s.vendorRoleID).toString();
    //   request.roleList = this.vendorRoleList.map((v) => v.selected === true);
    this.request.sortOrder = Array.prototype.map.call(this.sortBy, s => s.columnName).toString();
    this.request.vendorStatusID = this.selectedVendorStatusID;
    if (this.selectedVendorStatusID == 1 && this.f.vendor_account.value == "" && (this.f.contract_status.value == "" || isNaN(this.f.contract_status.value))
      && this.f.airport.value == "" && this.f.country_select.value == "" && (this.f.compliance_status.value == "" || isNaN(this.f.compliance_status.value))
      && (this.f.rating.value == "" || isNaN(this.f.rating.value)) && selectedRoles.length == 0) {
      this.errMsg = "Please select at least one search criteria."
      return;
    }
    this.showSpin = true;
    this.loading = true;

    this._vendorService.getVendorProfileList<ResponseModel<VendorModel[]>>(this.request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          this.dataSource.data = this.recordList;
          this.dataSource.sort = this.sort;
          if (this.recordList.length > 0) {
            this.totalRecordsText = this.recordList.length.toString() + ' record count';
            this.totalRecords = this.dataSource.data.length;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
            this.msg = "";
          }

        }
        else {
          this.recordList = [];
          //this.msg = "No data returned.";
        }
      }
      else {
        this.recordList = [];
        this.msg = "";
      }
      this.loading = false;
      this.showSpin = false;

    })

  }


  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
    }
    this.getSearchResults();

  }

  clickTab(e: VendorStatusModel) {
    this.errMsg = "";
    this.selectedTabName = e.vendorStatusDescription;
    this.selectedVendorStatusID = e.vendorStatusID;
   // if (this.recordList.length>0)
      this.getSearchResults();
    //this.dataSource.data = this.recordList.filter(v => v.vendorStatusID == e.vendorStatusID);
    //this.totalRecords = this.dataSource.data.length;

  }

  setSearchKeys() {
    localStorage.setItem('vendor.tab', this.selectedTabName);
    localStorage.setItem('vendor.status', this.selectedVendorStatusID.toString());    
    if (this.request.icao != undefined)
      localStorage.setItem('vendor.icao', this.request.icao);
    if (this.request.vendorName != undefined)
      localStorage.setItem('vendor.name', this.request.vendorName);
    if (this.request.countryGUID != undefined)
      localStorage.setItem('vendor.country', this.request.countryGUID);
    if (this.request.roleList!=undefined)
      localStorage.setItem('vendor.role', this.request.roleList);
    else
      localStorage.setItem('vendor.role', null);
    if (this.request.complianceStatusID != undefined)
      localStorage.setItem('vendor.comstat', this.request.complianceStatusID.toString());
    else
      localStorage.setItem('vendor.comstat', null);
    if (this.request.ratingID!=undefined)
      localStorage.setItem('vendor.rating', this.request.ratingID.toString());
    else
      localStorage.setItem('vendor.rating', null);
    if (this.request.contractStatusID!=undefined)
      localStorage.setItem('vendor.constat', this.request.contractStatusID.toString());
    else
      localStorage.setItem('vendor.constat', null);
   
    localStorage.setItem('vendor.order',  Array.prototype.map.call(this.sortBy, s => s.columnName).toString());

  }
  clickEdit(event: any, profile: VendorModel) {
    this.setSearchKeys();
    if (!this.isPopup)
      this._router.navigate(['/vendors/vendor-profile-setup', { vendorGUID: profile.vendorGUID }]);
    else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.hasBackdrop = false;
      dialogConfig.panelClass = "custom-dialog-container";
      dialogConfig.height = "50em";

      dialogConfig.data = {
        vendorGUID: profile.vendorGUID
      };
      this._dialogRef.close();
      this._dialog.open(VendorProfileSetupComponent, dialogConfig);
    }



  }

  clickAdd(event: any, profile: VendorModel) {
    let s = "";
    let vendorGUID = null;
    if (profile == null) {
      s = "Add New Profile";
    }
    else {
      s = "Edit";
      vendorGUID = profile.vendorGUID;
    }

    let navigationExtras: NavigationExtras = {
      queryParams: {
        "vendorGUID": vendorGUID
      }
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    //  dialogConfig.width = "46.5em";//"1000px";
    //  dialogConfig.height = "13em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, vendorGUID: vendorGUID, pageName:'list' };
    const dialogRef = this._dialog.open(VendorProfileEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        switch (result.actionName) {
          case "edit":
            this.setSearchKeys();
            if (!this.isPopup)
              this._router.navigate(['/vendors/vendor-profile-setup', { vendorGUID: result.newId }]);
            else {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.autoFocus = true;
              dialogConfig.disableClose = true;
              dialogConfig.hasBackdrop = false;
              dialogConfig.panelClass = "custom-dialog-container";
              dialogConfig.height = "50em";

              dialogConfig.data = {
                vendorGUID: result.newId
              };

              this._dialog.open(VendorProfileSetupComponent, dialogConfig);
            }
            break;
          case "search":
            this.getSearchResults();
            break;
        }
      }


       });


  }
  
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sortBy, event.previousIndex, event.currentIndex);
    this.getSearchResults();
  }

  
  openThirdPartyVendorComponent(){
    // if(this.isDialog){  
    //   this.clickClose(true);
    // } 
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.disableClose = true;
    config.height = "23em";//"1000px";
    config.width = "43em";//"1000px";

    // config.minHeight = "20em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = { isDialog: true, v: this._authService.getCurrentTimeNumber() };
    if (this.isPopup)
      this._dialogRef.close();
    this._dialog.open(ThirdPartyVendorsComponent, config);
  }

  openInNew() {
    this._dialogRef.close();
    this.setSearchKeys();
      window.open('/vendors/vendor-profile-list' + "?pf=vp");
    }
  

}





