import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AuthenticateService } from '../services/authenticate.service';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { ResponseModel } from '../models/response.model';
import { MapPointsModel } from '../models/map-points.model';
import { AirportDetailsComponent } from './airport-details.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AirportDetailsEditComponent } from './airport-details-edit.component';

@Component({
  selector: 'app-nearby-airports',
  templateUrl: './nearby-airports.component.html',
  styleUrls: ['./nearby-airports.component.css']
})
export class NearbyAirportsComponent implements OnChanges {
  @Input() airportID: number;
  @Input() isPreferred: boolean;
  @Input() distance: number;
  @Output() public updateParentPage = new EventEmitter();
  latLongs: MapPointsModel[] = [];
  nearbyAirports: AirportModel[];
  errMsg: string;
  displayedColumn: string[] = ['preferred', 'airport', 'distance', 'airport_of_entry', 'restrictions', 'slots', 'ppr', 'landing_permit_private','landing_permit_charter'];
  constructor(private readonly _authService: AuthenticateService, private readonly _airportService: AirportService, private readonly _dialog: MatDialog,) {

  }
    ngOnChanges(changes: SimpleChanges) {
      this._authService.updateAccessTime();
      this.latLongs = [];
      let request = new AirportModel();
      request.airportID = this.airportID;
      request.countryId = 0;
      request.isPreferred = this.isPreferred;
      request.distance = this.distance;
      this._airportService.getNearbyAirportList<ResponseModel<AirportModel[]>>(request).subscribe(response => {
        if (response != null) {
          if (response.code == "200" && response.message == "") {
            this.nearbyAirports = response.model;
            this.nearbyAirports.forEach(x => {
              let latLong = new MapPointsModel();
              latLong.latitude = x.latitude;
              latLong.longitude = x.longitude;
              latLong.title = x.icao;
              latLong.isMainPoint = false;
              latLong.isPreferred = x.isPreferredAlternate;
              this.latLongs.push(latLong);
            });
            this.updateParentPage.emit({ alternateLatLongs: this.latLongs });     
          }
          else {
            if (response.code == "401")
              this._authService.logout();
            else
              this.errMsg = "An error occurred.";
          }
        }
      });
    }

  openAirportDetails(airport: AirportModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.hasBackdrop = false;
    //    dialogConfig.maxWidth = "70em";
    dialogConfig.data = {
     airportID:airport.airportID

    };
    this._dialog.open(AirportDetailsEditComponent, dialogConfig);
  }
}
