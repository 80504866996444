import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { TripLegModel } from '../models/trip-leg.model';
import { AuthenticateService } from '../services/authenticate.service';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { GroundStopService } from '../services/ground-stop.service';
import * as atlas from 'azure-maps-control';
import * as turf from '@turf/turf';
import { AuthenticationType, ControlPosition, ControlStyle } from 'azure-maps-control';
import { CustomerService } from '../services/customer.service';
import { CompanyEventModel } from '../models/company-event.model';
import { HeadlineModel } from '../models/headline.model';
import { AircraftDocumentModel } from '../models/aircraft-document.model';
import { GroundStopManifestDocumentModel } from '../models/ground-stop-manifest-document.model';
import { CustomerDocumentModel } from '../models/customer-document.model';
import { TripDashboardModel } from '../models/trip-dashboard';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { DialogService } from '../services/dialog.service';
import { Sort } from '@angular/material/sort';
import { TripRevisionModel } from '../models/trip-revision.model';
import { TripAuditDialogComponent } from '../ground-stops/trip-audit-dialog.component';
import { CompanyEventDialogComponent } from './company-event-dialog.component';
import { HeadlineDialogComponent } from './headline-dialog.component';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { ChecklistDashboardDialogComponent } from './checklist-dashboard-dialog.component';
import { TripModel } from '../models/trip.model';
import { TripAlertDialogComponent } from './trip-alert-dialog.component';
import { CustomerModel } from '../models/customer.model';
import { CompanyEventEditDialogComponent } from './company-event-edit-dialog.component';
import { HeadlineEditDialogComponent } from './headline-edit-dialog.component';
import { IconLegendDialogComponent } from '../common-utility/icon-legend-dialog.component';
import { BusinessRulesModel } from '../models/business-rules.model';
import { AzureMapComponent } from '../common-utility/azure-map.component';
import { ResponseModel } from '../models/response.model';
import { CommonService } from '../services/common.service';
import { AuditTrail } from '../models/audit-trail.model';
import { MatAccordion } from '@angular/material/expansion';
import { GroundStopModel } from '../models/ground-stop.model'; 
import Chart from 'chart.js'; 
import { AdvisoryModel } from '../models/advisory.model';
import { AdvisoryDetailsComponent } from '../advisory/advisory-details.component';
import { AdvisoryEditComponent } from '../advisory/advisory-edit.component';
import { ViewAdvisoryComponent } from '../advisory/view-advisory.component';

export interface HeadlineGroup {
  headlineType: string;
  headlines: HeadlineModel[];
}
export class TableElement {
  legItem: TripLegModel;
  expanded: boolean;
  refreshChildRow: boolean;
  updateTask: boolean;
}
@Component({
  selector: 'app-current-events-dashboard',
  templateUrl: './current-events-dashboard.component.html',
  styleUrls: ['./current-events-dashboard.component.css']
})
/** current-events-dashboard-client component*/
export class CurrentEventsDashboardComponent implements OnInit {
/** current-events-dashboard-client ctor */

  currents_event_dashboard_form: UntypedFormGroup;
  recordList: TripLegModel[];
  eventList: CompanyEventModel[];
  headlineList: HeadlineModel[];
  headlines: HeadlineGroup[] = [];
  expiredACDocList: AircraftDocumentModel[];
  expiredCustomerDocList: CustomerDocumentModel[];
  expiredTravelDocList: GroundStopManifestDocumentModel[];
  missingTravelDocuments: GroundStopManifestModel[];
  waitingTaskList: GroundStopTaskModel[];
  waitingVendorTaskList: GroundStopTaskModel[];
  manifestList: GroundStopManifestModel[];
  missingCrewList: TripLegModel[] = [];
  missingPaxList: TripLegModel[] = [];
  tripRevisionList: TripRevisionModel[] = [];
  customerRevisions: CustomerModel[] = [];
  tbaCrewList: GroundStopManifestModel[] = [];
  tbaPaxList: GroundStopManifestModel[] = [];
  followUpCrewList: GroundStopManifestModel[] = [];
  followUpPaxList: GroundStopManifestModel[] = [];
  businessRulesList: BusinessRulesModel[] = [];
  missingSSNList: TripLegModel[] = [];
  openTaskList: GroundStopTaskModel[] = [];
  onHoldList: TripLegModel[] = [];
  holdTripCount: number=0;
  holdTaskCount: number=0;
  queryHour: string = "24";  
  displayedColumn: string[] = ['trip_code', 'registration', 'departure_date', 'crew_count', 'pax_count', 'dep_vendor_name', 'ete', 'leg', 'arrival_date', 'arr_vendor_name', 'icon'];
  displayedColumnTrip: string[] = ['submit_date', 'customer_name', 'trip_code', 'registration', 'leg_count'];
  displayedColumnCloseout: string[] = ['last_arrival', 'customer_name', 'trip_code', 'registration', 'days_to_end'];
  displayedAuditTrail: string[] = ['user_name', 'user_type', 'account_type'];
  showSpin: boolean = false;
  points: any = [];
  tripCount: number = 0;
  clientCount: number = 0;
  clickedRows = new Set<TripLegModel>();
  map: atlas.Map;
  upPII: boolean = false;
  grantAccessCompanyProfiles: boolean = false;
  grantAccessAircraftProfiles: boolean = false;
  sortedData: TripLegModel[];
  customerName: string;
  newTripList: TripModel[];
  tripCloseoutList: TripModel[];
  totalLegCount: number = 0;
  legList: TableElement[];
  grantAccessCompanyEvents: boolean = false;


  totalRecordsText: string;
  orgAuditRecordList: TripRevisionModel[] = []; 
  auditRecordList: TripRevisionModel[] = []; 
  user_type: string = "";
  queryHr: number;
  queryHrText: string;
  
  includeDemo: boolean;
  pastHours: number;
  auditTrailList: AuditTrail[] = []; 
  advisoryAlertList: AdvisoryModel[] = []; 



  chart: Chart;

  @ViewChild('MyChart') canvas: any;
  
  @ViewChild(MatAccordion) alertAccordion: MatAccordion; 
  mobileCount: number = 0;
  webCount: number = 0;
  // advisoryAlertLis t2: AdvisoryModel[] = [];
  
  constructor(private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopService: GroundStopService, private readonly _commonService: CommonService,   
    private readonly _customerService: CustomerService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog     ) {

  }

  getTripScheduleData() {
    let request = new TripLegModel();
    request.queryHour = Number(this.queryHour);
    this.showSpin = true;
    this._groundStopService.getTripScheduleList<ResponseModel<TripDashboardModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          let tripDashboard = <TripDashboardModel>response.model;
          this.recordList = tripDashboard.tripLegs;
          if (this.recordList != null) {
            this.sortedData = this.recordList.slice();
          }
          this.expiredACDocList = tripDashboard.expiredAircraftDocuments; 
          this.expiredCustomerDocList = tripDashboard.expiredCustomerDocuments;
          if (tripDashboard.tripLegs != null) {
            this.missingCrewList = tripDashboard.tripLegs.filter(x => x.departureCrewCount == 0);
            this.missingPaxList = tripDashboard.tripLegs.filter(x => x.departurePaxCount == 0 && !x.isDeptFerryFlight);
            this.onHoldList = tripDashboard.tripLegs.filter(x => x.departureOnHold || x.nextArrivalOnHold);
            this.holdTripCount = Array.from(new Set(this.onHoldList.map((item) => item.tripCode))).length;
            Array.from(new Set(this.onHoldList.map((item) => item.departureGroundStopTaskList))).forEach(v => {
              this.holdTaskCount += v.length;
            });
            Array.from(new Set(this.onHoldList.map((item) => item.nextArrivalGroundStopTaskList))).forEach(v => {
              this.holdTaskCount += v.length;
            });
            let tempTripCode: string = "";
            let tempClient: string = "";
            tripDashboard.tripLegs.sort(function (a, b) {
              if (a.tripCode < b.tripCode) { return -1; }
              if (a.tripCode > b.tripCode) { return 1; }
              return 0
            });
            tripDashboard.tripLegs.forEach(x => {
              if (tempClient != x.customerGUID) {
                tempClient = x.customerGUID;
                this.clientCount += 1;
              }
              if (tempTripCode != x.tripCode) {
                tempTripCode = x.tripCode;
                this.tripCount += 1;
              }
              //x.departureManifest.forEach(y => {
              //  this.manifestList.push(y);
              //});
              //x.arrivalManifest.forEach(y => {
              //  this.manifestList.push(y);
              //});
              //console.log("from legs")
              //console.log(this.manifestList.length);
            });
            let gs = new GroundStopModel();
            gs.groundStopGUIDList = Array.prototype.map.call(this.recordList, s => s.groundStopGUID).toString();
            gs.groundStopGUIDList.concat(Array.prototype.map.call(this.recordList, s => s.nextArrivalGroundStopGUID).toString());
            this._groundStopService.getAllCrewPaxListBygsIdList<ResponseModel<GroundStopModel[]>>(gs).subscribe(response => {
              if (response != null) {
                this.manifestList = [];
                if (response.code == "200" && response.message == "") {
                  this.recordList.forEach(x => {
                    let manifest;
                    let i;
                    i = response.model.findIndex(y => y.groundStopGUID == x.groundStopGUID);
                    if (i > -1) {
                      manifest = response.model[i].allCrewPaxList;
                      this.manifestList = this.manifestList.concat(manifest.filter(y => y.groundStopManifestTypeDescription.includes("Departure") && y.groundStopGUID == x.groundStopGUID));
                    
                    }
                    i = response.model.findIndex(y => y.groundStopGUID == x.nextArrivalGroundStopGUID);
                    if (i > -1) {
                      manifest = response.model[i].allCrewPaxList;
                      this.manifestList = this.manifestList.concat(manifest.filter(y => y.groundStopManifestTypeDescription.includes("Arrival") && y.groundStopGUID == x.nextArrivalGroundStopGUID && this.manifestList.findIndex(z=>z.personGUID==y.personGUID)==-1));
                     
                    }
                  
                  })
                  //response.model.forEach(x => {
                  //     //if (objManifestGS != null)
                  //          //{
                  //          //    objLeg.DepartureManifest = objManifestGS.AllCrewPaxList.Where(x => x.GroundStopManifestTypeDescription.Contains("Departure") == true && x.GroundStopGUID == objLeg.GroundStopGUID).ToList();
                  //          //    objLeg.ArrivalManifest = objManifestGS.AllCrewPaxList.Where(x => x.GroundStopManifestTypeDescription.Contains("Arrival") == true && x.GroundStopGUID == objLeg.NextArrivalGroundStopGUID).ToList();
                  //          //}
                  //  this.manifestList = this.manifestList.concat(x.allCrewPaxList);
                  //});
                  this.missingTravelDocuments = this.manifestList.filter(x => x.isBellAlert && x.lastName.toLowerCase() != 'crew tba'
                    && x.lastName.toLowerCase() != 'pax tba' && x.lastName.toLowerCase() != 'crew followup' && x.lastName.toLowerCase() != 'pax followup');
                  this.tbaPaxList = this.manifestList.filter(x => x.lastName.toLowerCase() == 'pax tba');
                  this.tbaCrewList = this.manifestList.filter(x => x.lastName.toLowerCase() == 'crew tba');
                  this.followUpCrewList = this.manifestList.filter(x => x.lastName.toLowerCase() == 'crew followup');
                  this.followUpPaxList = this.manifestList.filter(x => x.lastName.toLowerCase() == 'pax followup');
                }
                else {
                  if (response.code == "401") {
                    this._authService.signOut();
                  }
                }
              }
            });
            this.manifestList.forEach(x => {
              x.groundStopManifestDocumentList.forEach(y => {
                if (y.isExpiredForGroundStop) {
                  if (!this.expiredTravelDocList.includes(y))
                    this.expiredTravelDocList.push(y);
                }
              });
            });
            
            this.waitingTaskList = tripDashboard.waitingTaskList;
            this.waitingVendorTaskList = tripDashboard.waitingVendorTaskList;
            this.tripRevisionList = tripDashboard.allTripRevisionList;
            this.businessRulesList = tripDashboard.businessRulesApprovalList;
            this.missingSSNList = tripDashboard.missingSSNList;
            this.openTaskList = tripDashboard.openTaskList;
          }
          this.showSpin = false;
        }
        else {
          if (response.code == "401") {
            this._authService.signOut();
          }
        }
        this.createMap();
      
      }
      else {
        this.showSpin = false;
      }
    });
    

  }

  getAllData(): Observable<any[]> {
    
    let companyEvent = new CompanyEventModel();
    companyEvent.includeExpired = false;
    companyEvent.isActive = true;
    companyEvent.isDisplayOnly = true;
    let getCompanyEventResponse = this._customerService.getCompanyEventListBycId(companyEvent);
    let getHeadlineResponse = this._customerService.getHeadlineListBycId();
    let getNewTripListResponse= this._groundStopService.getNewTripList();
    let getUpcomingTripCloseoutListResponse = this._groundStopService.getUpcomingTripCloseoutList();

    let request = new TripLegModel();
    request.queryHour = Number(this.queryHour);
    // this.showSpin = true;
    let getAdvisoryAlertListResponse = this._groundStopService.getAdvisoryAlertListForHomepage(request);

    return forkJoin([getCompanyEventResponse, getHeadlineResponse, getNewTripListResponse, getUpcomingTripCloseoutListResponse, getAdvisoryAlertListResponse]);

  }

  ngOnInit() {
    this._authService.updateAccessTime();
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }
    if (localStorage.getItem('up.cPro') != 'true')
      this.grantAccessCompanyProfiles = false;
    else
      this.grantAccessCompanyProfiles = true;
    if (localStorage.getItem('up.acPro') != 'true')
      this.grantAccessAircraftProfiles = false;
    else
      this.grantAccessAircraftProfiles = true;

    this.customerName = localStorage.getItem('cn');
    if (localStorage.getItem('up.cEvent') != 'true')
      this.grantAccessCompanyEvents = false;
    else
      this.grantAccessCompanyEvents = true;
    
    this.getData(false);

    // if(this.f.tafDesc.value != null)
  }

  initControls() {
    this.currents_event_dashboard_form = this._formBuilder.group({});
  }

  getData(isUpdate: boolean) {
    if (isUpdate == false) {
      this.recordList = [];
      this.advisoryAlertList = [];
      this.expiredACDocList = [];
      this.expiredTravelDocList = [];
      this.missingTravelDocuments = [];
      this.waitingTaskList = [];
      this.waitingVendorTaskList = [];
      this.expiredCustomerDocList = [];
      this.missingCrewList = [];
      this.missingPaxList = [];
      this.onHoldList = [];
      this.manifestList = [];
      this.sortedData = [];
      this.businessRulesList = [];
      this.tripRevisionList = [];
      this.missingSSNList = [];
      this.openTaskList = [];
      this.tbaCrewList = [];
      this.tbaPaxList = [];
      if (this.map != undefined)
        this.map.dispose();
    }
    
    this.headlines = [];
    this.tripCount = 0;
    this.clientCount = 0;
    this.holdTripCount = 0;
    this.holdTaskCount = 0;
    this.clickedRows = new Set<TripLegModel>();
    //this.showSpin = true;
    this.getTripScheduleData();
    this.getAllData().subscribe(responses => {
      
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.eventList = responses[0].model;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.headlineList = responses[1].model;
          let tempType;
          //this.headlineList.sort(function (a, b) {
          //  if (a.headlineTypeDescription < b.headlineTypeDescription) { return -1; }
          //  if (a.headlineTypeDescription > b.headlineTypeDescription) { return 1; }
          //  return 0
          //});       
          this.headlineList.forEach(x => {
            if (tempType != x.headlineTypeDescription) {
              tempType = x.headlineTypeDescription;
              this.headlines.push({ headlineType: tempType, headlines: this.headlineList.filter(y => y.headlineTypeDescription == tempType) });
            }
          });
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.newTripList = responses[2].model;
          this.totalLegCount = 0;
          this.newTripList.forEach(x => {
            this.totalLegCount += x.legCount;
          })
          
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].message == "") {
          this.tripCloseoutList = responses[3].model;
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }
      
      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          this.advisoryAlertList = responses[4].model;
 

        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }
     // this.showSpin = false;
      
      this.getAuditTrailData();

    });
  }
  
  createMap() {
    this.map = new atlas.Map('map', {
      language: 'en-US',
      view: 'Auto',
      showBuildingModels: true,
      showLogo: false,
      style: "road_shaded_relief",
      showFeedbackLink: false,
      //zoom: 11,
      //center: new atlas.data.Position(6.7735, 51.2277),
      //Add your Azure Maps subscription client ID to the map SDK.
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: 'Fhd-makXth7kDF_WhzXweKjTEF8DafsW5gZ5sphgPKQ'
      }
    });

    var self = this;
    self.points = [];
    self.map.events.add('ready', function () {
      var dataSource = new atlas.source.DataSource();
      self.map.sources.add(dataSource);
      var zoomControl = new atlas.control.ZoomControl();

      /* Add the zoom control to the map*/
      self.map.controls.add(zoomControl, {
        position: ControlPosition.TopRight
      });

      if (self.recordList.length > 0) {
        self.recordList.forEach(v => {
          if (self.clickedRows.size == 0 || self.clickedRows.has(v)) {
            var startPosition = [v.departureLongitude, v.departureLatitude];
            var endPosition = [v.nextArrivalLongitude, v.nextArrivalLatitude];
            var startPoint = new atlas.data.Feature(new atlas.data.Point(startPosition), {
              title: v.departureICAO,
              name: v.departureAirportName
            });

            var endPoint = new atlas.data.Feature(new atlas.data.Point(endPosition), {
              title: v.nextArrivalICAO,
              name: v.nextArrivalAirportName
            });
            self.points.push(startPosition);
            self.points.push(endPosition);
            var path = atlas.math.getGeodesicPath([startPosition, endPosition]);
            var poly = new atlas.data.LineString(path);
            dataSource.add([poly, startPoint, endPoint]);
          }
        });
        var popupTemplate = "<div class='popup'>{name}</div>";
        var symbolLayer = new atlas.layer.SymbolLayer(dataSource, null, {
          iconOptions: {
            image: 'pin-round-darkblue',
            cluster: false,
            optional: true,
            allowOverlap: true,
            size: .5
          },
          textOptions: {
            textField: ['get', 'title'],
            offset: [0, -1.5],
            cluster: false,
            allowOverlap: true,
            size: 10
          },
          filter: ['any', ['==', ['geometry-type'], 'Point']]
        });
        self.map.layers.add([new atlas.layer.LineLayer(dataSource, null, {
          strokeColor: 'black',
          strokeWidth: 1.5
        }),
          symbolLayer
        ]);
        var popup = new atlas.Popup({
          pixelOffset: [0, -18],
          closeButton: false
        });
        //Add a hover event to the symbol layer.
        self.map.events.add('mouseover', symbolLayer, function (e) {
          //Make sure that the point exists.
          if (e.shapes && e.shapes.length > 0) {
            var pos = e.position;
            var offset = [0, 0];
            var properties;
            var content, coordinate;

            if (e.shapes[0] instanceof atlas.Shape) {
              properties = e.shapes[0].getProperties();

              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].getType() === 'Point') {
                coordinate = e.shapes[0].getCoordinates();
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            } else {
              properties = e.shapes[0].properties;
              //If the shape is a point feature, show the popup at the points coordinate.
              if (e.shapes[0].type === 'Point') {
                coordinate = e.shapes[0].geometry.coordinates;
                content = popupTemplate.replace(/{name}/g, properties.name);
                offset = [0, -18];
              }
            }
            popup.setOptions({
              //Update the content of the popup.
              content: content,

              //Update the popup's position with the symbol's coordinate.
              position: coordinate

            });
            //Open the popup.
            popup.open(self.map);
          }
        });

        self.map.events.add('mouseleave', symbolLayer, function () {
          popup.close();
        });

        var bbox = atlas.data.BoundingBox.fromLatLngs(self.points);

        self.map.setCamera({
          bounds: bbox,
          padding: 40
        });
      }


    });
  }


  clickRow(row:any) {
    if (this.clickedRows.has(row))
      this.clickedRows.delete(row);
    else {
      this.clickedRows = new Set<TripLegModel>();
      this.clickedRows.add(row);
    }
    this.map.dispose();
    this.createMap();
  }
  openCompanyEvent(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;    
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { companyEventID:id };
    this._dialog.open(CompanyEventDialogComponent, dialogConfig);
    //const dialogRef = this._dialog.open(SwayComponent, dialogConfig);

  }

  addCompanyEvent() {
    if (!this.grantAccessCompanyEvents)
      return;
    let s = "";
    let companyEventID = 0;
      s = "ADD New Record";
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { dialogTitle: s, companyEventID: companyEventID };


    const dialogRef = this._dialog.open(CompanyEventEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData(true);
      }
    });



  }

  openHeadline(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { headlineID: id };
    this._dialog.open(HeadlineDialogComponent, dialogConfig);

  }

  addHeadline() {

    let s = "";
    let headlineID = 0;
      s = "ADD New Record";
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { dialogTitle: s, headlineID: headlineID };


    const dialogRef = this._dialog.open(HeadlineEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getData(true);
      }
    });

  }

  openAlert(obj: any, type: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.data = { alertObject: obj, alertType: type, manifest: this.manifestList, legs: this.recordList };

    const dialogRef = this._dialog.open(TripAlertDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      
       // this.getData(false);
      
    });

  }

  openChecklist(obj: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = false;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = "70em";
    //dialogConfig.panelClass = "custom-dialog-container2";
    dialogConfig.data = { leg: obj };
    this._dialog.open(ChecklistDashboardDialogComponent, dialogConfig);

  }


  openTrip(p: TripModel) {
    window.open('/ground-stops/trip-details-internal?tripId=' + p.tripCodeGUID + '&aircraftId=' + p.aircraftGUID + '&customerId=' + p.customerGUID + '&pf=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }

  //openClient(p: TripModel) {
  //  window.open('/customers/customer-profile-setup-edit?customerGuid=' + p.customerGUID + "&v=" + this._authService.getCurrentTimeNumber(), '_blank');
  //}

  sortData(sort: Sort) {
    const data = this.recordList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'trip_code':
          return compare(a.tripCode, b.tripCode, isAsc);
        case 'registration':
      return compare(a.registration, b.registration, isAsc);
        case 'departure_date':
          return compare(new Date(a.departureDateTimeUTCText), new Date(b.departureDateTimeUTCText), isAsc);
        case 'crew_count':
          return compare(a.departureCrewCount, b.departureCrewCount, isAsc);
        case 'pax_count':
          return compare(a.departurePaxCount, b.departurePaxCount, isAsc);        
        case 'ete':
          return compare(a.ete, b.ete, isAsc);
        case 'leg':
          return compare(a.departureICAO, b.departureICAO, isAsc);
        case 'arrival_date':
          return compare(new Date(a.nextArrivalDateTimeUTCText), new Date(b.nextArrivalDateTimeUTCText), isAsc);
        default:
          return 0;
      }
    });

    function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  openAudit(revision: TripModel) {
    if (!this.upPII)
      return;
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: revision.customerGUID, aircraftGUID: revision.aircraftGUID, tripCodeGUID: revision.tripCodeGUID, tripCode:revision.tripCode,
      pastHours: 8,
      userName: "", v: this._authService.getCurrentTimeNumber()
    };
    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  openLink(link: string) {
    let url: string = '';
    if (!/^http[s]?:\/\//.test(link)) {
      link = '//' + link;
    }

    window.open(link, '_blank');
  }

  openLegend() {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    let legendType: string = "checkList";
    dialogConfig.data = {
      dialogTitle: s, legendType: legendType
    };

    const dialogRef = this._dialog.open(IconLegendDialogComponent, dialogConfig);
   
  }

  openMapComponent() {
    this.legList = [];
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80em";
    dialogConfig.height = "40em";
    //dialogConfig.panelClass = "custom-dialog-container"
    let x = new TableElement();
    this.sortedData.forEach(v => {
      x = new TableElement();
      x.legItem = v;
      if (this.clickedRows.size == 0 || this.clickedRows.has(v)) {
        this.legList.push(x);
      }
    });
    
    dialogConfig.data = {
      legList: this.legList
    };

    this._dialog.open(AzureMapComponent, dialogConfig);

  }


  getAuditTrailData() {
    this._authService.updateAccessTime();
    //this.showSpin = true;
   
    this._commonService.getAuditTrail<ResponseModel<AuditTrail[]>>().subscribe(response => {
      //this.recordList = [];
      //this.totalRecordsText = "";
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.auditTrailList = response.model;
         
          this.mobileCount = this.auditTrailList.filter(x => x.mobileCount > 0)?.length
          this.webCount = this.auditTrailList.filter(x => x.webCount > 0)?.length
          this.createChart1();

        }
        else {
          this.totalRecordsText = "No record found";
        }
        //this.showSpin = false;
      
      }
      else {
        if (response.code == "401") {
          
          this._authService.signOut();
        }
        else {
          //this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      //this.showSpin = false;
    });
    
  }

  setRecordList() {
    this.auditRecordList = [];
    switch (this.user_type) {
      case "internal":
        this.auditRecordList = this.orgAuditRecordList.filter(x=>x.isExternal==false);
        break;
      case "customer":
        this.auditRecordList = this.orgAuditRecordList.filter(x => x.isExternal == true);
        break;
      default:
        this.auditRecordList = this.orgAuditRecordList;
        break;
    }
    if (this.auditRecordList.length > 1) {
      this.totalRecordsText = this.auditRecordList.length.toString() + " Records Found";
    }
    else {
      this.totalRecordsText = this.auditRecordList.length.toString() + " Record Found";
    }
  }

  clickRouteNew(route: string){
    window.open(route + "?v=" + this._authService.getCurrentTimeNumber());
  }

  
  createChart1() {


        var ctx = this.canvas.nativeElement;
        let countries:string[]=[];
        let counts:number[]=[];
 

        this.chart = new Chart(ctx, {
          type: 'doughnut',

          data: {// values on X-Axis
            labels: ['WEB','MOBILE'],
            datasets: [
              {
                label: "countries",
                data: [this.webCount, this.mobileCount],
                backgroundColor: ['#7BD3EA', '#0D9276'],

        
                
              }
            ]
          },          
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Chart.js Doughnut Chart'
              },
              datalabels: {
                display: true,
                color: 'black',
                formatter: (value, context) => {
                  return context.chart.data.labels[context.dataIndex] + ': ' + value;
                }
              },
 
            },
            
            cutoutPercentage: 70,
            animation: {
              onComplete: function () {
                var chartInstance = this.chart;
                var ctx = chartInstance.ctx;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                var fontSize = 70;
                var fontStyle = 'normal';
                var fontFamily = 'Helvetica Neue';
                ctx.font = fontSize + 'px ' + fontStyle + ' ' + fontFamily;
                ctx.fillStyle = '#000';
    
                var centerX = (chartInstance.chartArea.left + chartInstance.chartArea.right) / 2;
                var centerY = (chartInstance.chartArea.top + chartInstance.chartArea.bottom) / 2;
    
                var total = 0;
                // chartInstance.data.datasets[0].data.forEach(function (value) {
                //   total += value;
                // });

                total = 2;
    
                var countText = total.toString(); // Displaying the total count in the center
    
                ctx.fillText(countText, centerX, centerY);
              }

            }
          },

        });





  

        
       
      
  }

  clickAdvisoryDetails() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4b"
    config.minWidth = "64em";
    config.maxWidth = "83em";//"1000px";
    config.minHeight = "36.5em";//"1000px";
    config.maxHeight = "41em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px" };

    let title = 'Add';
    let advisoryId = 0;

    config.data = { gsId: "", tripCodeId: "", dialogTitle: title, advisoryId: advisoryId, fromHomePage: true, v: this._authService.getCurrentTimeNumber() };
    const dialogRef = this._dialog.open(AdvisoryDetailsComponent, config);
  }

  
  viewAdvisory(item: AdvisoryModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container"
    dialogConfig.width="60em"

    dialogConfig.data = {
      advisoryID: item.advisoryId, advisoryDetails: item, fromAdvisoryHomepage: true, queryHour: Number(this.queryHour)
    };

      this._dialog.open(ViewAdvisoryComponent, dialogConfig);
        
       
      
  }
  


  clickAddAdvisory() {
    //  const dialogRef = this.dialog.open(GenerateMessageTemplateComponent, { restoreFocus: false });
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4b"
    config.minWidth = "64em";
    config.maxWidth = "92em";//"1000px";
    config.minHeight = "36.5em";//"1000px";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    // config.position = { left: "100px" };

    let title = 'Add';
    let advisoryId = 0;

    config.data = { gsId: "", tripCodeId: "", dialogTitle: title, advisoryId: advisoryId, isDialog: true, v: this._authService.getCurrentTimeNumber() };
    const dialogRef = this._dialog.open(AdvisoryEditComponent, config);
  }
 

}



