<div style="margin-top:0em; padding-top:0em; display:flex">
<form [formGroup]="vendor_address_form">
    <div>
      <table mat-table [dataSource]="recordList" class="example-container">
        <tr>
         
           <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef width="90%">
              Address
            </th>
            <td mat-cell *matCellDef="let element" style="text-align:left !important">{{element.address}}</td>
          </ng-container>
          <ng-container matColumnDef="disabled">
            <th mat-header-cell *matHeaderCellDef width="5%">
              Disabled
            </th>
            <td mat-cell *matCellDef="let element">{{element.isActive? '':'Yes'}}</td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef width="5%">
              Action <br /><a mat-button class="btn-high grid" (click)="clickEdit($event,null)">ADD</a>
            </th>
            <td mat-cell *matCellDef="let element; let i=index ">
              <a mat-button class="btn-low grid" (click)="clickEdit($event, element);">
                EDIT
              </a>
            </td>
          </ng-container>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
      </table>
    </div>
    
</form>
</div>

<app-spin *ngIf="showSpin"></app-spin>
