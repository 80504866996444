import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpHandler, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CdkMenuModule } from '@angular/cdk/menu';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyButton as MatButton, MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyPaginatorIntl as MatPaginatorIntl, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { FileSelectDirective, FileUploadModule } from 'ng2-file-upload';
import { ChartsModule } from 'ng2-charts';
import { NgbAccordionModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './layout/nav-menu.component';
import { AuthenticateService } from './services/authenticate.service';
import { MenuService } from './services/menu.service';
import { IdentityService } from './services/identity.service';
import { CommonService } from './services/common.service';
import { DialogService } from './services/dialog.service';
import { TextFieldModule } from '@angular/cdk/text-field'
//import { LogonComponent } from './user-accounts/logon.component';
import { MenuComponent } from './nav-menu/menu.component';
import { MenuItemComponent } from './nav-menu/menu-item.component';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from './main/header.component';
import { LogoutComponent } from './user-accounts/logout.component';
import { RegisterUserComponent } from './user-accounts/register-user.component';
import { ChangePasswordComponent } from './user-accounts/change-password.component';
import { ResetPasswordComponent } from './user-accounts/reset-password.component';
//import { UpdateUserComponent } from './user-accounts/update-user.component';
import { BlankComponent } from './common-utility/blank.component';
import { UpdateMyAccountComponent } from './user-accounts/update-my-account.component';
import { GetUsernameComponent } from './user-accounts/get-username.component';
import { RoundUpNumberPipe } from './common-utility/round-up-number.pipe';
import { UsernameComponent } from './user-accounts/username.component';
import { PasswordComponent } from './user-accounts/password.component';
import { LayoutHeaderComponent } from './layout/layout-header.component';
import { ConfirmDialogComponent } from './common-utility/confirm-dialog.component';
import { AlertDialogComponent } from './common-utility/alert-dialog.component';
import { SpinComponent } from './common-utility/spin.component';
import { ResetPasswordAdminComponent } from './user-accounts/reset-password-admin.component';
import { UserPermissionAdminComponent } from './user-accounts/user-permission-admin.component';
//import { UserPermissionPersonEditComponent } from './user-accounts/user-permission-person-edit.component';
//import { UserPermissionRegistrationEditComponent } from './user-accounts/user-permission-registration-edit.component';
//import { UserPermissionRestrictEditComponent } from './user-accounts/user-permission-restrict-edit.component';
import { FeedbackComponent } from './user-accounts/feedback.component';
import { CustomerService } from './services/customer.service';
import { CustomerCommlistComponent } from './customers/customer-commlist.component';
import { CustomerMaillistComponent } from './customers/customer-maillist.component';
import { CustomerDocumentlistComponent } from './customers/customer-documentlist.component';
import { CustomerCommEditDialogComponent } from './customers/customer-comm-edit-dialog.component';
import { CustomerMailEditDialogComponent } from './customers/customer-mail-edit-dialog.component';
import { CustomerDocumentEditDialogComponent } from './customers/customer-document-edit-dialog.component';
import { UserPermissionAdminEditDialogComponent } from './user-accounts/user-permission-admin-edit-dialog.component';
import { UserPermissionRestrictEditDialogComponent } from './user-accounts/user-permission-restrict-edit-dialog.component';
import { UserPermissionRegistrationEditDialogComponent } from './user-accounts/user-permission-registration-edit-dialog.component';
import { UserPermissionPersonEditDialogComponent } from './user-accounts/user-permission-person-edit-dialog.component';
import { CustomerProfileSetupComponent } from './customers/customer-profile-setup.component';
import { CustomerProfileMainEditDialogComponent } from './customers/customer-profile-main-edit-dialog.component';
import { CustomerProfileSetupEditComponent } from './customers/customer-profile-setup-edit.component';
import { CustomerRegulatoryServicesEditDialogComponent } from './customers/customer-regulatory-service-edit-dialog.component';
import { CustomerProfileSalesEditDialogComponent } from './customers/customer-profile-sales-edit-dialog.component';
import { CustomerNotesEditDialogComponent } from './customers/customer-notes-edit-dialog.component';
import { CustomerHomebaseEditDialogComponent } from './customers/customer-homebase-edit-dialog.component';
import { CustomerPersonlistComponent } from './persons/customer-personlist.component';
import { CustomerPersonEditDialogComponent } from './persons/customer-person-edit-dialog.component';
import { PersonService } from './services/person.service';
import { CustomerPersonDetailsComponent } from './persons/customer-person-details.component';
import { CustomerPersonMaillistComponent } from './persons/customer-person-maillist.component';
import { CustomerPersonMailEditDialogComponent } from './persons/customer-person-mail-edit-dialog.component';
import { CustomerPersonTravelDoclistComponent } from './persons/customer-person-travel-doclist.component';
import { CustomerPersonTravelDocSelectionDialogComponent } from './persons/customer-person-travel-doc-selection-dialog.component';
import { PersonPassportEditDialogComponent } from './persons/person-passport-edit-dialog.component';
import { PersonTravelDocCountrySelectionDialogComponent } from './persons/person-travel-doc-country-selection-dialog.component';
import { PersonPassportVisalistDialogComponent } from './persons/person-passport-visalist-dialog.component';
import { CustomerHaltServicesEditDialogComponent } from './customers/customer-halt-services-edit-dialog.component';
import { CustomerAccountingProfileEditDialogComponent } from './customers/customer-accounting-profile-edit-dialog.component';
import { PersonVisaEditDialogComponent } from './persons/person-visa-edit-dialog.component';
import { PersonTravelDocEditDialogComponent } from './persons/person-travel-doc-edit-dialog.component';
import { CustomerPersonCommlistComponent } from './persons/customer-person-commlist.component';
import { CustomerPersonCommEditDialogComponent } from './persons/customer-person-comm-edit-dialog.component';
import { CustomerPersonRewardlistComponent } from './persons/customer-person-rewardlist.component';
import { PersonRewardEditDialogComponent } from './persons/person-reward-edit-dialog.component';
import { GroundStopService } from './services/ground-stop.service';
//import { TripQuickSearchControlComponent } from './ground-stops/trip-quick-search-control.component';
import { TripCodeTacticalViewlistComponent } from './ground-stops/trip-code-tactical-viewlist.component';
//import { GroundStopCreatorDialogComponent } from './ground-stops/ground-stop-creator-dialog.component';
import { AirportService } from './services/airport.service';
import { AircraftTripViewlistComponent } from './ground-stops/aircraft-trip-viewlist.component';
//import { TripDetailsComponent } from './ground-stops/trip-details.component';
//import { GroundStoplistComponent } from './ground-stops/ground-stoplist.component';
import { AircraftProfileComponent } from './aircraft/aircraft-profile.component';
import { AircraftService } from './services/aircraft.service'
import { AircraftMainEditDialogComponent } from './aircraft/aircraft-main-edit-dialog.component';
import { AircraftProfileDetailsComponent } from './aircraft/aircraft-profile-details.component';
import { AircraftProfileEditComponent } from './aircraft/aircraft-profile-edit.component';
import { AircraftDocumentsComponent } from './aircraft/aircraft-documents.component';
import { AircraftDocumentEditDialogComponent } from './aircraft/aircraft-document-edit-dialog.component';
import { AircraftOwnerOperatorComponent } from './aircraft/aircraft-owner-operator.component';
import { AircraftOwnerOperatorEditDialogComponent } from './aircraft/aircraft-owner-operator-edit-dialog.component';
import { AircraftRegulatoryServicesComponent } from './aircraft/aircraft-regulatory-services.component';
import { AircraftRegulatoryServicesEditDialogComponent } from './aircraft/aircraft-regulatory-services-edit-dialog.component';
import { AircraftCommsComponent } from './aircraft/aircraft-comms.component';
import { AircraftCommEditDialogComponent } from './aircraft/aircraft-comm-edit-dialog.component';
import { GroundStopEditorDialogComponent } from './ground-stops/ground-stop-editor-dialog.component';
//import { GroundStopDetailsComponent } from './ground-stops/ground-stop-details.component';
import { SafeUrlPipe } from './common-utility/safe-url.pipe';
import { SafeHtmlPipe } from './common-utility/safe-html.pipe';
import { GroundStopAdvancedService } from './services/ground-stop-advanced.service';
import { FormatNumberPipe } from './common-utility/format-number.pipe';
//import { GroundStopAlternateAirportEditComponent } from './ground-stop-advanced/ground-stop-alternate-airport-edit.component';
import { GroundStopCrewPaxlistComponent } from './ground-stop-advanced/ground-stop-crew-paxlist.component';
import { GroundStopCrewPaxEditDialogComponent } from './ground-stop-advanced/ground-stop-crew-pax-edit-dialog.component';
import { PersonTravelDocSelectionDialogComponent } from './ground-stop-advanced/person-travel-doc-selection-dialog.component';
import { TripCodeDetailViewlistComponent } from './ground-stops/trip-code-detail-viewlist.component';
import { GroundStopTasklistComponent } from './ground-stop-advanced/ground-stop-tasklist.component';
import { GroundStopServiceSelectionDialogComponent } from './ground-stop-advanced/ground-stop-service-selection-dialog.component';
import { GroundStopTaskEditDialogComponent } from './ground-stop-advanced/ground-stop-task-edit-dialog.component';
import { AircraftCustomerProfileComponent } from './aircraft/aircraft-customer-profile.component';
import { AircraftCustomerProfileEditDialogComponent } from './aircraft/aircraft-customer-profile-edit-dialog.component';
import { AircraftEquipmentComponent } from './aircraft/aircraft-equipment.component';
import { CustomerRegulatoryServicesComponent } from './customers/customer-regulatory-services.component';
import { CustomerNoteComponent } from './customers/customer-note.component';
import { CustomerHomebaseAirportComponent } from './customers/customer-homebase-airport.component';
import { CustomerHaltServicesComponent } from './customers/customer-halt-services.component';
import { CustomerAccountingProfileComponent } from './customers/customer-accounting-profile.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { AircraftFlightPlanningComponent } from './aircraft/aircraft-flight-planning.component';
import { HotelEditDialogComponent } from './hotels/hotel-edit-dialog.component';
import { HotellistComponent } from './hotels/hotellist.component';
import { GenerateMessageTemplateComponent } from './ground-stops/generate-message-template.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { GroundStopServiceTypeSelectionDialogComponent } from './ground-stop-advanced/ground-stop-service-type-selection-dialog.component';
import { AirportBiDashboardComponent } from './airports/airport-bi-dashboard.component';
import { SuccessMessageComponent } from './common-utility/success-message.component';
import { GenerateTaskDocumentsComponent } from './ground-stop-advanced/generate-task-documents.component';
import { DocumentDownloadsDialogComponent } from './ground-stops/document-downloads-dialog.component';
import { SessionExpirationAlertDialogComponent } from './common-utility/session-expiration-alert-dialog.component';
import { VendorBiDashboardComponent } from './vendors/vendor-bi-dashboard.component';
import { GroundStopAlternateAirportEditDialogComponent } from './ground-stop-advanced/ground-stop-alternate-airport-edit-dialog.component';
import { GroundStopDetailsDialogComponent } from './ground-stops/ground-stop-details-dialog.component';
//import { TripSetupComponent } from './ground-stops/trip-setup.component';
import { GenerateApis91Component } from './apis/generate-apis-91.component';
import { TripSetupNoteDialogComponent } from './ground-stops/trip-setup-note-dialog.component';
import { MessageService } from './services/message.service';
import { WarningMessageComponent } from './common-utility/warning-message.component';
import { AirportDetailsComponent } from './airports/airport-details.component';
import { VendorService } from './services/vendor.service';
import { CovidBiDashboardComponent } from './flight-watch/covid-bi-dashboard.component';
import { GroundStopCreatorCityPairDialogComponent } from './ground-stops/ground-stop-creator-citypair-dialog.component';
import { AirportLocatorComponent } from './airports/airport-locator.component';
import { VendorLocatorComponent } from './vendors/vendor-locator.component';
import { FlightFollowingEditDialogComponent } from './ground-stop-advanced/flight-following-edit-dialog.component';
import { FlightFollowingNewCommDialogComponent } from './ground-stop-advanced/flight-following-new-comm-dialog.component';
import { VendorDetailsComponent } from './vendors/vendor-details.component';
import { TripAuditDialogComponent } from './ground-stops/trip-audit-dialog.component';
import { TripAuditUserCommentDialogComponent } from './ground-stops/trip-audit-user-comment-dialog.component';
import { MatTreeModule } from '@angular/material/tree';
import { DevTicketsBiDashboardComponent } from './bi-dashboards/dev-tickets-bi-dashboard.component';
//import { IncommingTripLegComponent } from './ground-stops/incomming-trip-leg.component';
import { TripAuditDashboardComponent } from './ground-stops/trip-audit-dashboard.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { TaskRevisionRequiredEditDialogComponent } from './ground-stop-advanced/task-revision-required-edit-dialog.component';
import { WeatherChartsDialogComponent } from './flight-brief/weather-charts-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
//import { TaskQueueDashboardComponent } from './ground-stop-advanced/task-queue-dashboard.component';
import { CompileFlightBriefDialogComponent } from './flight-brief/compile-flight-brief-dialog.component';
import { IconLegendDialogComponent } from './common-utility/icon-legend-dialog.component';
import { TripSummaryReportDialogComponent } from './ground-stops/trip-summary-report-dialog.component';
import { AircraftSelectionDialogComponent } from './ground-stops/aircraft-selection-dialog.component';
import { TaskQueuelistComponent } from './ground-stop-advanced/task-queuelist.component';
import { DeliveryCommsDialogComponent } from './ground-stop-advanced/delivery-comms-dialog.component';
import { VendorProfileSetupComponent } from './vendors/vendor-profile-setup.component';
import { FlightBriefSectionsComponent } from './flight-brief/flight-brief-sections.component';
import { BuildFlightBriefDialogComponent } from './flight-brief/build-flight-brief-dialog.component';
import { FlightBriefDocumentsComponent } from './flight-brief/flight-brief-documents.component';
import { VendorProfileListComponent } from './vendors/vendor-profile-list.component';
import { VendorProfileEditDialogComponent } from './vendors/vendor-profile-edit-dialog.component';
import { CustomerBiDashboardComponent } from './bi-dashboards/customer-bi-dashboard.component';
import { CustomerProfileBiDashboardComponent } from './bi-dashboards/customer-profile-bi-dashboard.component';
import { VendorNotesModel } from './models/vendor-notes.model';
import { VendorNoteComponent } from './vendors/vendor-note.component';
import { VendorNotesEditDialogComponent } from './vendors/vendor-notes-edit-dialog.component';
import { VendorCommsComponent } from './vendors/vendor-comms.component';
import { VendorCommEditDialogComponent } from './vendors/vendor-comm-edit-dialog.component';
import { VendorPersonlistComponent } from './vendors/vendor-personlist.component';
import { VendorPersonCommlistComponent } from './vendors/vendor-person-commlist.component';
import { VendorPersonCommEditDialogComponent } from './vendors/vendor-person-comm-edit-dialog.component';
import { VendorPersonDetailsComponent } from './vendors/vendor-person-details.component';
import { VendorPersonEditDialogComponent } from './vendors/vendor-person-edit-dialog.component';
import { VendorHoursListComponent } from './vendors/vendor-hours-list.component';
import { VendorHoursEditDialogComponent } from './vendors/vendor-hours-edit-dialog.component';
import { HoursEditDialogComponent } from './vendors/hours-edit-dialog.component';
import { VendorAddressComponent } from './vendors/vendor-address.component';
import { VendorAddressEditDialogComponent } from './vendors/vendor-address-edit-dialog.component';
import { VendorDocumentlistComponent } from './vendors/vendor-documentlist.component';
import { VendorDocumentEditDialogComponent } from './vendors/vendor-document-edit-dialog.component';
import { UserPermissionClientComponent } from './user-accounts/user-permission-client.component';
import { UserPermissionClientEditDialogComponent } from './user-accounts/user-permission-client-edit-dialog.component';
import { UserPermissionRestrictClientEditDialogComponent } from './user-accounts/user-permission-restrict-client-edit-dialog.component';
import { WeatherTemplateComponent } from './ground-stop-advanced/weather-template.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { TripQuickSearchControlClientComponent } from './ground-stops-client/trip-quick-search-control-client.component';
import { TripCodeTacticalViewlistClientComponent } from './ground-stops-client/trip-code-tactical-viewlist-client.component';
import { GroundStopClientService } from './services/ground-stop-client.service';
import { TripCodeDetailViewlistClientComponent } from './ground-stops-client/trip-code-detail-viewlist-client.component';
import { AircraftTripViewlistClientComponent } from './ground-stops-client/aircraft-trip-viewlist-client.component';
import { TripDetailsClientComponent } from './ground-stops-client/trip-details-client.component';
import { AirportNotesEditDialogComponent } from './airports/airport-notes-edit-dialog.component';
import { CountryContentEditDialogComponent } from './country/country-content-edit-dialog.component';
import { CountryService } from './services/country.service';
import { GroundStopCreatorClientDialogComponent } from './ground-stops-client/ground-stop-creator-client-dialog.component';
import { GroundStoplistClientComponent } from './ground-stops-client/ground-stoplist-client.component';
import { GroundStopTaskClientDialogComponent } from './ground-stop-advanced-client/ground-stop-task-client-dialog.component';
import { GroundStopAdvancedClientService } from './services/ground-stop-advanced-client.service';
import { CurrentEventsDashboardClientComponent } from './current-events-client/current-events-dashboard-client.component';
import { CompanyEventDialogComponent } from './current-events/company-event-dialog.component';
import { HeadlineDialogComponent } from './current-events/headline-dialog.component';
import { CompanyEventEditDialogComponent } from './current-events/company-event-edit-dialog.component';
import { ClientAlertDialogComponent } from './current-events-client/client-alert-dialog.component';
import { CompanyEventListComponent } from './current-events/company-event-list.component';
import { FlightFollowingClientDialogComponent } from './ground-stop-advanced-client/flight-following-client-dialog.component';
import { HotelClientDialogComponent } from './hotels/hotel-client-dialog.component';
import { TripClientInstructionEditDialogComponent } from './ground-stops-client/trip-client-instruction-edit-dialog.component';
import { ChecklistDashboardClientDialogComponent } from './current-events-client/checklist-dashboard-client-dialog.component';
import { AzureMapComponent } from './common-utility/azure-map.component';
import { EmailClientDialogComponent } from './messages-client/email-client-dialog.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CurrentEventsDashboardComponent } from './current-events/current-events-dashboard.component';
import { TripAlertDialogComponent } from './current-events/trip-alert-dialog.component';
import { ClientFeedbackComponent } from './common-utility/client-feedback.component';
import { HeadlineListComponent } from './current-events/headline-list.component';
import { HeadlineEditDialogComponent } from './current-events/headline-edit-dialog.component';
import { ChecklistDashboardDialogComponent } from './current-events/checklist-dashboard-dialog.component';
import { NewFeaturesDialogComponent } from './common-utility/new-features-dialog.component';
import { PrivacyPolicyDialogComponent } from './common-utility/privacy-policy-dialog.component';
import { TermsOfUseDialogComponent } from './common-utility/terms-of-use-dialog.component';
import { TripDetailsInternalComponent } from './ground-stops/trip-details-internal.component';
import { TripSetupDialogComponent } from './ground-stops/trip-setup-dialog.component';
import { GroundStoplistInternalComponent } from './ground-stops/ground-stoplist-internal.component';
import { ContactUsComponent } from './common-utility/contact-us.component';
import { ClientHaltServicesViewDialogComponent } from './ground-stop-advanced/client-halt-services-view-dialog.component';
import { TripQuickSearchControlInternalComponent } from './ground-stops/trip-quick-search-control-internal.component';
import { TemplateService } from './services/template.service';
import { TripNotesEditDialogComponent } from './ground-stops/trip-notes-edit-dialog.component';
import { AccountGrouplistComponent } from './account-groups/account-grouplist.component';
import { AccountGroupEditDialogComponent } from './account-groups/account-group-edit-dialog.component';
import { AccountGroupSelectionDialogComponent } from './account-groups/account-group-selection-dialog.component';
import { CustomerGroupService } from './services/customer-group-service';
import { AccountGroupAuditDialogComponent } from './account-groups/account-group-audit-dialog.component';
import { ManageBusinessRulesComponent } from './business-rules/manage-business-rules.component';
import { BusinessRulesEditComponent } from './business-rules/business-rules-edit.component';
import { SubmitCurrentEventComponent } from './current-events/submit-current-event.component';
import { TripLeglistComponent } from './trip-legs/trip-leglist.component';
import { TripLegService } from './services/trip-leg.service';
import { GroundStopLeglistComponent } from './trip-legs/ground-stop-leglist.component';
import { GroundStopLegTasklistComponent } from './trip-legs/ground-stop-leg-tasklist.component';
import { BusinessRulesService } from './services/business-rules-service';
import { ChooseSSNDialogComponent } from './common-utility/choose-ssn-dialog.component';
import { LeglistEditorDialogComponent } from './trip-legs/leglist-editor-dialog.component';
import { LegInsertDialogComponent } from './trip-legs/leg-insert-dialog.component';
import { FuelBiDashboardComponent } from './bi-dashboards/fuel-bi-dashboard.component';
import { AddBusinessRulesTasksDialogComponent } from './ground-stop-advanced/add-business-rules-tasks-dialog.component';
import { TripPersonlistEditorDialogComponent } from './trip-legs/trip-personlist-editor-dialog.component';
import { TripPersonEditorDialogComponent } from './trip-legs/trip-person-editor-dialog.component';
import { TripServicelistEditorDialogComponent } from './trip-legs/trip-servicelist-editor-dialog.component';
import { TripServiceEditorDialogComponent } from './trip-legs/trip-service-editor-dialog.component';
import { TripCodeSearchComponent } from './ground-stops/trip-code-search.component';
import { GroundStopLeglistClientComponent } from './trip-legs-client/ground-stop-leglist-client.component';
import { TripLegClientService } from './services/trip-leg-client.service';
import { TripLeglistClientComponent } from './trip-legs-client/trip-leglist-client.component';
import { AzureMapInsetComponent } from './common-utility/azure-map-inset.component';
import { TripLegDocumentDownloadsDialogComponent } from './trip-legs/trip-leg-document-downloads-dialog.component';
import { FlightPlanPreferenceEditComponent } from './flight-plan-preference/flight-plan-preference-edit.component';
import { GroundStopLegTasklistClientComponent } from './trip-legs-client/ground-stop-leg-tasklist-client.component';
//import { LegDetailEditorDialogComponent } from './trip-legs/leg-detail-editor-dialog.component';
import { FlightPlanLeglistComponent } from './flight-plan-preference/flight-plan-leglist.component';
import { FlightPlanPreferenceService } from './services/flight-plan-preference.service';
import { FlightPlanPreferenceDisplayComponent } from './flight-plan-preference/flight-plan-preference-display.component';
import { FlightPlanPreferenceAuditDialogComponent } from './flight-plan-preference/flight-plan-preference-audit-dialog.component';
import { EmailInternalDialogComponent } from './messages/email-internal-dialog.component';
import { EmailAddressSelectionDialogComponent } from './messages/email-address-selection-dialog.component';
import { TaskDocumentSelectionDialogComponent } from './messages/task-document-selection-dialog.component';
import { SignatureEditorComponent } from './user-accounts/signature-editor.component';
import { TripDocumentSelectionDialogComponent } from './messages/trip-document-selection-dialog.component';
import { TripPersonlistEditorClientDialogComponent } from './trip-legs-client/trip-personlist-editor-client-dialog.component';
import { TripPersonEditorClientDialogComponent } from './trip-legs-client/trip-person-editor-client-dialog.component';
import { GenerateApis135Component } from './apis/generate-apis-135.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { FpiMarketingBiComponent } from './bi-dashboards/fpi-marketing-bi.component';
import { LegFerryFlightEditorDialogComponent } from './trip-legs/leg-ferry-flight-editor-dialog.component';
import { TripSummarySpecificReportDialogComponent } from './ground-stops/trip-summary-specific-report-dialog.component';
import { MessageListComponent } from './messages/message-list.component';
import { MessageCenterComponent } from './messages/message-center.component';
import { MessageSearchComponent } from './messages/message-search.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { GenerateApisCaricomComponent } from './apis/generate-apis-caricom.component';
import { MessageLinkComponent } from './messages/message-link.component';
import { HelpGuidesComponent } from './help-guides/help-guides.component';
import { PortalModule } from '@angular/cdk/portal';
import { HelpGuideCategoriesComponent } from './help-guides/help-guide-categories.component';
import { HelpGuideService } from './services/help-guide.service';
import { HelpGuideDetailsComponent } from './help-guides/help-guide-details.component';
import { HelpGuideTopicsComponent } from './help-guides/help-guide-topics.component';
import { HelpGuideDetailsEditComponent } from './help-guides/help-guide-details-edit.component';
import { LegDetailEditorDialogComponent } from './trip-legs/leg-detail-editor-dialog.component';
import { FinanceBiDashboardComponent } from './bi-dashboards/finance-bi-dashboard.component';
import { TripQuoteBiDashboardComponent } from './bi-dashboards/trip-quote-bi-dashboard.component';
import { LinkMessageTripDetailViewlistComponent } from './messages/link-message-trip-detail-viewlist.component';
import { TaskServiceDialogComponent } from './messages/task-service-dialog.component';

import { CancelAirportDialogComponent } from './ground-stops/cancel-airport-dialog.component';

//import { NavMenuComponent } from './nav-menu/nav-menu.component';
//import { HomeComponent } from './home/home.component';
//import { CounterComponent } from './counter/counter.component';
//import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { LegMatchBiDashboardComponent } from './bi-dashboards/leg-match-bi-dashboard.component';
import { MessageBiDashboardComponent } from './bi-dashboards/message-bi-dashboard.component';

import { TripMessageCenterComponent } from './ground-stops/trip-message-center.component';
import { ManageContingencyDialogComponent } from './ground-stops/manage-contingency-dialog.component';
import { ContingencyEditDialogComponent } from './ground-stops/contingency-edit-dialog.component';
import { DisplayDialogComponent } from './ckeditor5/display-dialog.component';
import { ContingencyStopComponent } from './ground-stops/contingency-stop.component';
import { MessageImportComponent } from './messages/message-import.component';
import { MessageImportDialogComponent } from './messages/message-import-dialog.component';
import { FolderSelectionDialogComponent } from './messages/folder-selection-dialog.component';
import { UpdateMessageFolderComponent } from './add-message-folder/update-message-folder.component';
import { MessageFolderEditDialogComponent } from './add-message-folder/message-folder-edit-dialog.component';

import { FuelpricingComponent } from './fuelpricing/fuelpricing.component';
//import { FuelPriceUpload1Component } from './fuelpricing/fuel-price-upload1.component';
import { FuelSupplierComponent } from './fuelmenu/fuel-supplier-profile.component';
import { FuelSupplierEditDialogComponent } from './fuelmenu/fuel-supplier-profile-edit-dialog.component';
import { DatePipe } from '@angular/common';


import { FuelPricingSummaryComponent } from './fuelmenu/fuel-pricing-summary.component';
import { ClipboardDetailsDialogComponent } from './messages/clipboard-details-dialog.component';
import { MessageCenterClientComponent } from './messages-client/message-center-client.component';
import { MessageListClientComponent } from './messages-client/message-list-client.component';
import { EmailAddressSelectionClientDialogComponent } from './messages-client/email-address-selection-client-dialog.component';
import { FuelService } from './services/fuel.service';
// import { EmailAddressSelectionClientDialogComponent } from './messages/email-address-selection-client-dialog.component';
import { AirportDocumentsEditDialogComponent } from './airports/airport-documents-edit-dialog.component';
import { FuelPricingEditDialogComponent } from './fuelmenu/fuel-pricing-edit-dialog.component';
//import { EmailAddressSelectionClientDialogComponent } from './messages/email-address-selection-client-dialog.component';
import { ReviseFuelPricingComponent } from './fuelmenu/revise-fuel-pricing.component';
import { TaxesAndFeesComponent } from './taxesandfees/taxesandfees.component';
import { TaxesandFeesEditDialogComponent } from './taxesandfees/taxesandfees-edit-dialog.component';
import { CountryDocumentsEditDialogComponent } from './country/country-documents-edit-dialog.component';
import { CountryDetailsComponent } from './country/country-details.component';
import { DecimalDirective } from './directives/decimal.directive';
import { MatTableResponsiveDirective } from './directives/mat-table-responsive.directive';
import { CurrencyComponent } from './currency/currency.component';
import { CurrencyUploadComponent } from './currency/currency-upload.component';
import { ApisAccountComponent } from './apis/apis-account.component';
import { ApisAccountEditComponent } from './apis/apis-account-edit.component';
import { CountryLocatorComponent } from './country/country-locator.component';
import { CountrySegmentEditDialogComponent } from './country/country-segment-edit-dialog.component';
import { CountryRequiredPermitDocumentsComponent } from './country/country-required-permit-documents-dialog.component';
import { CountryNotesAndDocumentsEditDialogComponent } from './country/country-notes-and-documents-edit-dialog.component';
// import { CountryNotesAndDocumentsEditDialogComponent } from './country/country-notes-and-documents-edit-dialog.component';
import { FuelClientMarkupComponent } from './fuelmenu/fuel-client-markup.component';
import { FuelClientMarkupEditDialogComponent } from './fuelmenu/fuel-client-markup-edit-dialog.component';
import { FuelCommissionsComponent } from './fuelmenu/fuel-commissions.component';
import { FuelCommissionsEditDialogComponent } from './fuelmenu/fuel-commissions-edit-dialog.component';
import { FuelMenuComponent } from './fuelmenu/fuel-menu.component';
import { EngineeringTicketsComponent } from './common-utility/engineering-tickets.component';
import { MissingTaxesAirportComponent } from './taxesandfees/missing-taxes-airport.component';
import { MissingTaxesAirportAllDetailsComponent } from './taxesandfees/missing-taxes-airport-all-details.component';
import { TemplateDashboardComponent } from './templates/template-dashboard.component';
import { TemplateEditComponent } from './templates/template-edit.component';
import { TemplateLegendDialogComponent } from './templates/template-legend-dialog.component';
import { PersonalInfoComponent } from './user-accounts/personal-info.component';
import { PasswordRecoveryComponent } from './user-accounts/password-recovery.component';
import { MultiFactorAuthComponent } from './user-accounts/multi-factor-auth.component';
import { FuelTaskEditDialogComponent } from './fuel-quote/fuel-task-edit-dialog.component';
import { MissingPricesAirportComponent } from './fuelmenu/missing-prices-airport.component';
import { CountryDocumentTypeUpdateDialog } from './country/country-document-type-update-dialog.component';
import { CountryDocumentTypeEditDialog } from './country/country-document-type-edit-dialog.component';
import { TaxesAndFeesDescriptionEditDialog } from './fuelmenu/taxesandfees-description-edit-dialog.component';
import { TaxesAndFeesDescriptionDialog } from './fuelmenu/taxesandfees-description-dialog.component';
import { FuelExchangeRateMarkupComponent } from './fuelmenu/fuel-markup-exchange.component';
import { FuelQuoteHistoryDialogComponent } from './fuel-quote/fuel-quote-history-dialog.component';
import { FuelExchangeRateMarkupEditComponent } from './fuelmenu/fuel-markup-exchange-edit.component';
import { FuelPriceLocationUploadComponent } from './fuelmenu/fuel-price-locations-upload.component';
import { RouteSequenceDialogComponent } from './trip-legs/route-sequence-dialog.component';
import { ManageRouteSequenceDialog } from './ground-stops/manage-route-sequence-dialog.component';
import { LocationPreferencesComponent } from './fuelmenu/location-preferences.component';
import { LocationMetricsComponent } from './fuelmenu/location-metrics.component';
import { RouteSequenceConfirmationDialog } from './ground-stops/manage-route-sequence-confirmation-dialog.compnent';
import { TripMessagesClientComponent } from './messages-client/trip-messages-client.component';
import { OverFlightPermitTaskComponent } from './ground-stop-advanced/overflight-permit-task.component';
import { LandingPermitPrivateTaskComponent } from './ground-stop-advanced/landing-permit-private-task.component';
import { FeedbackDialogComponent } from './common-utility/feedback-dialog.component';
import { StarRatingComponent } from './common-utility/star-rating.component';
import { SlotArrivalTaskComponent } from './ground-stop-advanced/slot-arrival-task.component';
// import { BarRatingModule } from 'ngx-bar-rating';

import { FuelQuoteSummaryComponent } from './fuel-quote/fuel-quote-summary.component';
import { FuelQuoteSummaryHistoryComponent } from './fuel-quote/fuel-quote-summary-history.component';
import { FuelTaskClientDialogComponent } from './fuel-quote/fuel-task-client-dialog.component';
import { FuelTaskQueuelistComponent } from './fuelmenu/fuel-task-queuelist.component';
import { TaskCloseConfirmationDialog } from './ground-stop-advanced/task-close-confirmation-dialog.component';
import { TripCloseOutQueueComponent } from './current-events/trip-closeout-queue.component';
import { InternalGroundStopTaskEditDialogComponent } from './ground-stop-advanced/internal-ground-stop-task-edit-dialog.component';
import { InternalPublicGroundStopTaskEditDialogComponent } from './ground-stop-advanced/internal-public-ground-stop-task-edit-dialog.component';
import { MaintenanceDashboardComponent } from './common-utility/maintenance-dashboard.component';
import { ThirdPartyVendorsComponent } from './common-utility/third-party-vendors.component';
import { GlobalSettingsDashboardComponent } from './common-utility/global-settings-dashboard.component';
import { FpcPicklistDataComponent } from './common-utility/fpc-picklist-data.component';
import { AccountingDashboardComponent } from './accounting/accounting-dashboard.component';
import { TripCloseOutBiilingCompletedComponent } from './accounting/trip-closeout-biiling-completed.component';
import { DocumentTypeListComponent } from './documents/document-type-list.component';
import { DocumentTypeEditComponent } from './documents/document-type-edit.component';
import { CustomerProfileSetupComponent2 } from './customers/customer-profile-setup2.component';
import { GtsBillableServicesComponent } from './accounting/gts-billable-services.component';
import { CustomerInformationDialogComponent } from './customers/customer-information-dialog.component';
import { ManageContactDetailsComponent } from './persons/manage-contact-details.component';
import { PersonAccountGrouplistComponent } from './persons/person-account-grouplist.component';
import { AdminServicesClientComponent } from './user-accounts/admin-services-client.component';
import { PushNotificationComponent } from './user-accounts/push-notification.component';
import { FlightPlanTaskComponent } from './ground-stop-advanced/flight-plan-task.component';
import { FlightPlanModalPopupComponent } from './ground-stop-advanced/flight-plan-modal-popup.component';
import { HighlightPipe } from './common-utility/highlight.pipe';
import { CustomerInstructionsComponent } from './customers/customer-instructions.component';
import { AirportDetailsEditComponent } from './airports/airport-details-edit.component';
import { AirportNotesComponent } from './airports/airport-notes.component';
import { AirportDocumentsComponent } from './airports/airport-documents.component';
import { NearbyAirportsComponent } from './airports/nearby-airports.component';
import { AirportMapComponent } from './airports/airport-map.component';
import { AirportBusinessRulesComponent } from './airports/airport-business-rules.component';
import { ManageAirportsComponent } from './airports/manage-airports.component';
import { AirportRevisionModalComponent } from './airports/airport-revision-modal-dialog.component';
import { AdvisoryService } from './services/advisory.service';
import { AdvisoryDetailsComponent } from './advisory/advisory-details.component';
import { AdvisoryEditComponent } from './advisory/advisory-edit.component';
import { AdvisoryVendorDialogComponent } from './advisory/advisory-vendor-dialog/advisory-vendor-dialog.component';
import { AdvisoryTagsComponent } from './advisory/advisory-tags.component';
import { AdvisoryHotelsComponent } from './advisory/advisory-hotels.component';
import { PersonAuditReportComponent } from './persons/person-audit-report.component';
import { TripPlannerComponent } from './trip-planner/trip-planner.component';
import { VendorSelectionComponent } from './trip-planner/vendor-selection.component';
import { AirportBriefComponent } from './trip-planner/airport-brief.component';
import { ValidateManifestDataComponent } from './ground-stop-advanced/validate-manifest-data.component';
import { UkGarTaskComponent } from './ground-stop-advanced/uk-gar-task.component';
import { DownloadDocumentsComponent } from './messages/download-documents.component';
import { SpinDetailsComponent } from './common-utility/spin-details.component';
import { RecentTripsComponent } from './recent-trips/recent-trips.component';
import { ViewAdvisoryComponent } from './advisory/view-advisory.component';
import { TripActivityComponent } from './trip-planner/trip-activity.component';


export const OtherOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchGestures: 'auto',
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
}

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });
    return next.handle(authReq);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    //NavMenuComponent,
    //HomeComponent,
    //CounterComponent,
    //FetchDataComponent
    NavMenuComponent,
    //LogonComponent,
    MenuComponent,
    MenuItemComponent,
    MainComponent,
    HeaderComponent,
    LogoutComponent,
    RegisterUserComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    //UpdateUserComponent,
    BlankComponent,
    UpdateMyAccountComponent,
    GetUsernameComponent,
    RoundUpNumberPipe,
    UsernameComponent,
    PasswordComponent,
    LayoutHeaderComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    SpinComponent,
    ResetPasswordAdminComponent,
    UserPermissionAdminComponent,
    //UserPermissionPersonEditComponent,
    //UserPermissionRegistrationEditComponent,
    //UserPermissionRestrictEditComponent,
    FeedbackComponent,
    CustomerCommlistComponent,
    CustomerMaillistComponent,
    CustomerDocumentlistComponent,
    CustomerCommEditDialogComponent,
    CustomerMailEditDialogComponent,
    CustomerDocumentEditDialogComponent,
    UserPermissionAdminEditDialogComponent,
    UserPermissionRestrictEditDialogComponent,
    UserPermissionRegistrationEditDialogComponent,
    UserPermissionPersonEditDialogComponent,
    CustomerCommEditDialogComponent,
    CustomerProfileSetupComponent,
    CustomerProfileMainEditDialogComponent,
    CustomerProfileSetupEditComponent,
    CustomerRegulatoryServicesEditDialogComponent,
    CustomerProfileSalesEditDialogComponent,
    CustomerNotesEditDialogComponent,
    CustomerHomebaseEditDialogComponent,
    CustomerPersonlistComponent,
    CustomerPersonEditDialogComponent,
    CustomerPersonDetailsComponent,
    CustomerPersonMaillistComponent,
    CustomerPersonMailEditDialogComponent,
    CustomerPersonTravelDoclistComponent,
    CustomerPersonTravelDocSelectionDialogComponent,
    PersonPassportEditDialogComponent,
    PersonTravelDocCountrySelectionDialogComponent,
    PersonPassportVisalistDialogComponent,
    PersonVisaEditDialogComponent,
    PersonTravelDocEditDialogComponent,
    CustomerPersonCommlistComponent,
    CustomerPersonCommEditDialogComponent,
    CustomerPersonRewardlistComponent,
    PersonRewardEditDialogComponent,
    CustomerHomebaseEditDialogComponent,
    CustomerHaltServicesEditDialogComponent,
    CustomerAccountingProfileEditDialogComponent,
    AircraftProfileComponent,
    AircraftMainEditDialogComponent,
    AircraftProfileDetailsComponent,
    AircraftProfileEditComponent,
    AircraftDocumentsComponent,
    AircraftDocumentEditDialogComponent,
    AircraftOwnerOperatorComponent,
    AircraftOwnerOperatorEditDialogComponent,
    AircraftRegulatoryServicesComponent,
    AircraftRegulatoryServicesEditDialogComponent,
    AircraftCommsComponent,
    AircraftCommEditDialogComponent,
    //TripQuickSearchControlComponent,
    TripCodeTacticalViewlistComponent,
    //GroundStopCreatorDialogComponent,
    AircraftTripViewlistComponent,
    //TripDetailsComponent,
    //GroundStoplistComponent,
    GroundStopEditorDialogComponent,
    //GroundStopDetailsComponent,
    SafeUrlPipe,
    SafeHtmlPipe,
    FormatNumberPipe,
    //GroundStopAlternateAirportEditComponent,
    GroundStopCrewPaxlistComponent,
    GroundStopCrewPaxEditDialogComponent,
    PersonTravelDocSelectionDialogComponent,
    TripCodeDetailViewlistComponent,
    GroundStopTasklistComponent,
    GroundStopServiceSelectionDialogComponent,
    GroundStopTaskEditDialogComponent,
    AircraftCommEditDialogComponent,
    AircraftCustomerProfileComponent,
    AircraftCustomerProfileEditDialogComponent,
    AircraftEquipmentComponent,
    CustomerRegulatoryServicesComponent,
    CustomerNoteComponent,
    CustomerHomebaseAirportComponent,
    CustomerHaltServicesComponent,
    CustomerAccountingProfileComponent,
    AircraftFlightPlanningComponent,
    HotelEditDialogComponent,
    HotellistComponent,
    AircraftFlightPlanningComponent,
    GenerateMessageTemplateComponent,
    GroundStopServiceTypeSelectionDialogComponent,
    AirportBiDashboardComponent,
    SuccessMessageComponent,
    GenerateTaskDocumentsComponent,
    DocumentDownloadsDialogComponent,
    SessionExpirationAlertDialogComponent,
    VendorBiDashboardComponent,
    GroundStopAlternateAirportEditDialogComponent,
    GroundStopDetailsDialogComponent,
    CustomerBiDashboardComponent,
    //TripSetupComponent,
    GenerateApis91Component,
    TripSetupNoteDialogComponent,
    WarningMessageComponent,
    AirportDetailsComponent,
    CovidBiDashboardComponent,
    AirportLocatorComponent,
    VendorLocatorComponent,
    GroundStopCreatorCityPairDialogComponent,
    FlightFollowingEditDialogComponent,
    FlightFollowingNewCommDialogComponent,
    VendorDetailsComponent,
    TripAuditDialogComponent,
    TripAuditUserCommentDialogComponent,
    DevTicketsBiDashboardComponent,
    //IncommingTripLegComponent,
    TripAuditDashboardComponent,
    TaskRevisionRequiredEditDialogComponent,
    WeatherChartsDialogComponent,
    //TaskQueueDashboardComponent,
    CompileFlightBriefDialogComponent,
    IconLegendDialogComponent,
    TripSummaryReportDialogComponent,
    AircraftSelectionDialogComponent,
    TaskQueuelistComponent,
    DeliveryCommsDialogComponent,
    VendorProfileSetupComponent,
    FlightBriefSectionsComponent,
    BuildFlightBriefDialogComponent,
    FlightBriefDocumentsComponent,
    VendorProfileListComponent,
    VendorProfileEditDialogComponent,
    CustomerProfileBiDashboardComponent,
    VendorNoteComponent,
    VendorNotesEditDialogComponent,
    VendorCommsComponent,
    VendorCommEditDialogComponent,
    VendorPersonlistComponent,
    VendorPersonCommlistComponent,
    VendorPersonCommEditDialogComponent,
    VendorPersonDetailsComponent,
    VendorPersonEditDialogComponent,
    VendorHoursListComponent,
    VendorHoursEditDialogComponent,
    HoursEditDialogComponent,
    VendorAddressComponent,
    VendorAddressEditDialogComponent,
    VendorDocumentlistComponent,
    VendorDocumentEditDialogComponent,
    UserPermissionClientComponent,
    UserPermissionClientEditDialogComponent,
    UserPermissionRestrictClientEditDialogComponent,
    WeatherTemplateComponent,
    TripQuickSearchControlClientComponent,
    TripCodeTacticalViewlistClientComponent,
    TripCodeDetailViewlistClientComponent,
    AircraftTripViewlistClientComponent,
    TripDetailsClientComponent,
    WeatherTemplateComponent,
    AirportNotesEditDialogComponent,
    CountryContentEditDialogComponent,
    GroundStopCreatorClientDialogComponent,
    GroundStoplistClientComponent,
    GroundStopTaskClientDialogComponent,
    CurrentEventsDashboardClientComponent,
    CompanyEventDialogComponent,
    HeadlineDialogComponent,
    CompanyEventEditDialogComponent,
    ClientAlertDialogComponent,
    CompanyEventListComponent,
    FlightFollowingClientDialogComponent,
    HotelClientDialogComponent,
    TripClientInstructionEditDialogComponent,
    ChecklistDashboardClientDialogComponent,
    AzureMapComponent,
    CurrentEventsDashboardComponent,
    TripAlertDialogComponent,
    ClientFeedbackComponent,
    HeadlineListComponent,
    HeadlineEditDialogComponent,
    ChecklistDashboardDialogComponent,
    AzureMapComponent,
    EmailClientDialogComponent,
    NewFeaturesDialogComponent,
    PrivacyPolicyDialogComponent,
    TermsOfUseDialogComponent,
    TripDetailsInternalComponent,
    TripSetupDialogComponent,
    GroundStoplistInternalComponent,
    ContactUsComponent,
    ClientHaltServicesViewDialogComponent,
    TripQuickSearchControlInternalComponent,
    TripNotesEditDialogComponent,
    AccountGrouplistComponent,
    AccountGroupEditDialogComponent,
    AccountGroupSelectionDialogComponent,
    AccountGroupAuditDialogComponent,
    ManageBusinessRulesComponent,
    BusinessRulesEditComponent,
    SubmitCurrentEventComponent,
    TripLeglistComponent,
    GroundStopLeglistComponent,
    GroundStopLegTasklistComponent,
    LeglistEditorDialogComponent,
    ChooseSSNDialogComponent,
    LegInsertDialogComponent,
    FuelBiDashboardComponent,
    AddBusinessRulesTasksDialogComponent,
    TripPersonlistEditorDialogComponent,
    TripPersonEditorDialogComponent,
    TripServicelistEditorDialogComponent,
    TripServiceEditorDialogComponent,
    TripCodeSearchComponent,
    GroundStopLeglistClientComponent,
    TripLeglistClientComponent,
    GroundStopLeglistClientComponent,
    AzureMapInsetComponent,
    TripLegDocumentDownloadsDialogComponent,
    FlightPlanPreferenceEditComponent,
    GroundStopLegTasklistClientComponent,
    LegDetailEditorDialogComponent,
    FlightPlanLeglistComponent,
    FlightPlanPreferenceDisplayComponent,
    FlightPlanPreferenceAuditDialogComponent,
    EmailInternalDialogComponent,
    EmailAddressSelectionDialogComponent,
    TaskDocumentSelectionDialogComponent,
    SignatureEditorComponent,
    TripDocumentSelectionDialogComponent,
    TripPersonlistEditorClientDialogComponent,
    TripPersonEditorClientDialogComponent,
    GenerateApis135Component,
    FpiMarketingBiComponent,
    MessageListComponent,
    MessageCenterComponent,
    MessageSearchComponent,
    GenerateApisCaricomComponent,
    LegFerryFlightEditorDialogComponent,
    TripSummarySpecificReportDialogComponent,
    MessageLinkComponent,
    HelpGuidesComponent,
    HelpGuideCategoriesComponent,
    HelpGuideDetailsComponent,
    HelpGuideTopicsComponent,
    HelpGuideDetailsEditComponent,
    FinanceBiDashboardComponent,
    TripQuoteBiDashboardComponent,
    LinkMessageTripDetailViewlistComponent,
    TaskServiceDialogComponent,
    CancelAirportDialogComponent,
    LegMatchBiDashboardComponent,
    MessageBiDashboardComponent,
    TripMessageCenterComponent,
    ManageContingencyDialogComponent,
    ContingencyEditDialogComponent,
    DisplayDialogComponent,
    ContingencyStopComponent,
    MessageImportComponent,
    MessageImportDialogComponent,
    FolderSelectionDialogComponent,
    UpdateMessageFolderComponent,
    MessageFolderEditDialogComponent,
    FuelpricingComponent,
    //FuelPriceUpload1Component,
    // FuelPriceUploadComponent,
    FuelSupplierComponent,
    FuelPricingSummaryComponent,
    FuelSupplierEditDialogComponent,
    ClipboardDetailsDialogComponent,
    EmailAddressSelectionClientDialogComponent,
    AirportDocumentsEditDialogComponent,
    MessageCenterClientComponent,
    MessageListClientComponent,
    ClipboardDetailsDialogComponent,
    //EmailAddressSelectionClientDialogComponent,
    FuelPricingEditDialogComponent,
    ClipboardDetailsDialogComponent,
    //EmailAddressSelectionClientDialogComponent,
    ReviseFuelPricingComponent,
    CountryDocumentsEditDialogComponent,
    CountryDetailsComponent,
    TaxesAndFeesComponent,
    TaxesandFeesEditDialogComponent,
    DecimalDirective,
    CurrencyComponent,
    CurrencyUploadComponent,
    MatTableResponsiveDirective,
    ApisAccountComponent,
    ApisAccountEditComponent,
    CountryLocatorComponent,
    CountrySegmentEditDialogComponent,
    CountryRequiredPermitDocumentsComponent,
    CountryNotesAndDocumentsEditDialogComponent,
    FuelClientMarkupComponent,
    FuelClientMarkupEditDialogComponent,
    FuelCommissionsComponent,
    FuelCommissionsEditDialogComponent,
    FuelMenuComponent,
    EngineeringTicketsComponent,
    MissingTaxesAirportComponent,
    MissingTaxesAirportAllDetailsComponent,
    TemplateDashboardComponent,
    TemplateEditComponent,
    TemplateLegendDialogComponent,
    PersonalInfoComponent,
    PasswordRecoveryComponent,
    MultiFactorAuthComponent,
    FuelTaskEditDialogComponent,
    MissingPricesAirportComponent,
    CountryDocumentTypeUpdateDialog,
    CountryDocumentTypeEditDialog,
    TaxesAndFeesDescriptionDialog,
    TaxesAndFeesDescriptionEditDialog,
    FuelExchangeRateMarkupComponent,
    FuelQuoteHistoryDialogComponent,
    FuelExchangeRateMarkupEditComponent,
    RouteSequenceDialogComponent,
    LocationPreferencesComponent,
    FuelPriceLocationUploadComponent,
    LocationMetricsComponent,
    ManageRouteSequenceDialog,
    RouteSequenceConfirmationDialog,
    TripMessagesClientComponent,
    OverFlightPermitTaskComponent,
    LandingPermitPrivateTaskComponent,
    SlotArrivalTaskComponent,
    FeedbackDialogComponent,
    StarRatingComponent,
    FuelQuoteSummaryComponent,
    FuelQuoteSummaryHistoryComponent,
    FuelTaskClientDialogComponent,
    FuelTaskQueuelistComponent,
    InternalGroundStopTaskEditDialogComponent,
    TripCloseOutQueueComponent,
    TaskCloseConfirmationDialog,
    InternalPublicGroundStopTaskEditDialogComponent,
    MaintenanceDashboardComponent,
    ThirdPartyVendorsComponent,
    GlobalSettingsDashboardComponent,
    FpcPicklistDataComponent,
    AccountingDashboardComponent,
    TripCloseOutBiilingCompletedComponent,
    DocumentTypeListComponent,
    DocumentTypeEditComponent,
    CustomerProfileSetupComponent2,
    ManageContactDetailsComponent,
    GtsBillableServicesComponent,
    CustomerInformationDialogComponent,
    ManageContactDetailsComponent,
    PersonAccountGrouplistComponent,
    CustomerInformationDialogComponent,
    AdminServicesClientComponent,
    PushNotificationComponent,
    FlightPlanTaskComponent,
    FlightPlanModalPopupComponent,
    HighlightPipe,
    CustomerInstructionsComponent,
    AirportDetailsEditComponent,
    AirportNotesComponent,
    AirportDocumentsComponent,
    NearbyAirportsComponent,
    AirportMapComponent,
    AirportBusinessRulesComponent,
    ManageAirportsComponent,
    AirportRevisionModalComponent,
    AdvisoryDetailsComponent,
    AdvisoryEditComponent,
    AdvisoryVendorDialogComponent,
    AdvisoryTagsComponent,
    AdvisoryHotelsComponent,
    PersonAuditReportComponent,
    TripPlannerComponent,
    VendorSelectionComponent,
    AirportBriefComponent,
    ValidateManifestDataComponent,
    UkGarTaskComponent,
    DownloadDocumentsComponent,
    SpinDetailsComponent,
    RecentTripsComponent,
    ViewAdvisoryComponent,
    TripActivityComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSliderModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FileUploadModule,
    ChartsModule,
    AppRoutingModule,
    NgbModule,
    MatRadioModule,
    MatExpansionModule,
    MatListModule,
    DragDropModule,
    ClipboardModule,
    MatTreeModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatSortModule,
    MatMenuModule,
    MatChipsModule,
    MatTabsModule,
    MatAutocompleteModule,
    CKEditorModule,
    MatCardModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    OverlayModule,
    TextFieldModule,
    CdkMenuModule,
    PortalModule,
    NgbAccordionModule,
    NgbTooltipModule,
    MatAutocompleteModule,
    // BarRatingModule,
    //RouterModule.forRoot([
    //  { path: '', component: HomeComponent, pathMatch: 'full' },
    //  { path: 'counter', component: CounterComponent },
    //  { path: 'fetch-data', component: FetchDataComponent },
    //])
  ],
  providers: [AuthenticateService, MenuService, IdentityService, CommonService, DialogService, CustomerService,
    PersonService, AircraftService, GroundStopService, AirportService, GroundStopAdvancedService, MessageService, VendorService,
    GroundStopClientService, PersonService, AircraftService, GroundStopService, AirportService, GroundStopAdvancedService, MessageService,
    VendorService, CountryService, GroundStopAdvancedClientService, TemplateService, CustomerGroupService, TripLegService, Title,
    BusinessRulesService, TripLegClientService, FlightPlanPreferenceService, HelpGuideService, FuelService, DatePipe, AdvisoryService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: OtherOptions }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
