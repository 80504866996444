import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { CommAddressTypeModel } from '../models/comm-address-type.model';
import { AddressRatingModel } from '../models/address-rating.model';
import { AddressUseModel } from '../models/address-use.model';
import { CustomerService } from '../services/customer.service';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { PersonClassModel } from '../models/person-class.model';
import { PersonService } from '../services/person.service';
import { CountryModel } from '../models/country.model';
import { PersonRoleModel } from '../models/person-role.model';
import { PersonModel } from '../models/person.model';
import { ReturnObjModel } from '../models/return-obj.model';
import { DataEntryStatusModel } from '../models/data-entry-status.model';
import { PersonTravelDocumentModel } from '../models/person-travel-document.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { ConfirmDialogComponentWithMultipleLines } from '../common-utility/confirm-with-multiple-lines-dialog.component';

@Component({
  selector: 'app-customer-person-edit-dialog',
  templateUrl: './customer-person-edit-dialog.component.html',
  styleUrls: ['./customer-person-edit-dialog.component.css']
})

export class CustomerPersonEditDialogComponent implements OnInit, AfterViewInit {
  //@Input() dialogTitle: string;
  //@Input() personGUID: string;
  //@Input() customerGUID: string;
  //@Input() inputPersonClass: PersonClassModel;
  dialogTitle: string;
  personGUID: string;
  customerGUID: string;
  inputPersonClass: PersonClassModel;

  person_edit_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = false;
  personClassList: PersonClassModel[];
  countryList: CountryModel[];
  personRoleList: PersonRoleModel[]; 
  avaiPersonRoleList: PersonRoleModel[];
  selPersonRoleList: PersonRoleModel[];
  selectedPersonClass: PersonClassModel;
  firstName: string;
  lastName: string;
  middleName: string;
  title: string;
  dob: Date;
  gender: string;
  city: string;
  state_province: string;
  selectedBirthCountryGUID: string;
  selectedResidenceCountryGUID: string;
  selectedNationalityCountryGUID: string;
  isActive: number;
  modifiedBy: string;
  modifiedDate: string;
  displayedColumn: string[] = ['personRole'];
  userType: string;
  isModified: number = 0;
  returnObj: ReturnObjModel;
  showContactButton: boolean = false;
  @ViewChild('dob') dobRef: ElementRef;
  showSuccessMsg: boolean = false;
  //@Output() pageChanged = new EventEmitter();
  //@Output() pId: string;
  grantAccessToManageManifestProfiles: boolean = false;
  upPII: boolean = false;
  dataEntryStatusList: DataEntryStatusModel[];
  selectedDataEntryStatusID: number;
  travelDocumentList: PersonTravelDocumentModel[];
  travelSubDocumentList: PersonTravelDocumentModel[];
  grantAccessToAccountGrouping: boolean = false;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private confirmDialogRef2: MatDialogRef<ConfirmDialogComponentWithMultipleLines>;

  private eventSubscription: Subscription;
  eesetiasOutOfScope: number;
  eesetiasOutOfScopeExpirationDate: Date;
  @ViewChild('eesetiasOutOfScopeExpirationDate') outOfScopeRef: ElementRef;
  hasActiveTrip: boolean;
  continueSave: any;
  activeTripsDetails: string = '';

  constructor(private readonly _dialogRef: MatDialogRef<CustomerPersonEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _personService: PersonService,
    private readonly _dialogService: DialogService
  ) {
    this.personGUID = _data.personGUID;    
    this.inputPersonClass = _data.selectedPersonClass;
    
    if (_data.customerGUID == null) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }
    this.dialogTitle = _data.dialogTitle;
  }

  getAllData(): Observable<any[]> {
    let getPersonClassResponse = this._personService.getPersonClassList<ResponseModel<PersonClassModel[]>>();
    let getCountryResponse = this._commonService.getCountryListWithUSAFirst<ResponseModel<CountryModel[]>>();
    let getPersonRoleResponse = this._personService.getPersonRoleList<ResponseModel<PersonRoleModel[]>>(this.personGUID);
    let getPersonResponse;
    if (this.personGUID != null && this.personGUID != "") {
      getPersonResponse = this._personService.getPersonBypId(this.personGUID);
    }
    else {
      getPersonResponse = of(null);
    }
    let dataEntryStatusResponse;
    if (this.userType == "internal") {
      dataEntryStatusResponse = this._commonService.getDataEntryStatusList<ResponseModel<DataEntryStatusModel[]>>();
    }
    else {
      dataEntryStatusResponse = of(null);
    }
    let personDocumentResponse;
    if (this.personGUID != null && this.personGUID != "") {
      let request = new PersonTravelDocumentModel();
      request.personGUID = this.personGUID;
      request.isActive = true;

      personDocumentResponse = this._personService.getCustomerPersonTravelDocument<ResponseModel<PersonTravelDocumentModel[]>>(request);
    }
    else {
      personDocumentResponse = of(null);
    }
    return forkJoin([getPersonClassResponse, getCountryResponse, getPersonRoleResponse, getPersonResponse, dataEntryStatusResponse, personDocumentResponse]);

    //if (this.personGUID != null && this.personGUID != "") {
    //  let getPersonResponse = this._personService.getPersonBypId(this.personGUID);
    //  return forkJoin([getPersonClassResponse, getCountryResponse, getPersonRoleResponse, getPersonResponse]);
    //}
    //else
    //  return forkJoin([getPersonClassResponse, getCountryResponse, getPersonRoleResponse]);
  }

  ngOnInit() {
    
    this._authService.updateAccessTime();
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }
    if (!this.upPII || !this.grantAccessToManageManifestProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }

    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
    this.personClassList = [];
    this.countryList = [];
    this.personRoleList=[];
    this.avaiPersonRoleList=[];
    this.selPersonRoleList=[];
    this.firstName = "";
    this.lastName = "";
    this.middleName = "";
    this.title = "";
    this.gender = "";
    this.city = "";
    this.state_province = "";
    this.selectedBirthCountryGUID = "";
    this.selectedResidenceCountryGUID = "";
    this.selectedNationalityCountryGUID = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.showContactButton = false;
    this.returnObj = new ReturnObjModel();
    this.showSuccessMsg = false;
    this.dataEntryStatusList = [];
    this.travelDocumentList = [];
    this.selectedDataEntryStatusID = 0;
    this.eesetiasOutOfScope = 0;
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.personClassList = responses[0].model;
          this.selectedPersonClass = this.personClassList.find(x => x.personClassGUID?.toLowerCase() == this.inputPersonClass.personClassGUID?.toLowerCase());
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.countryList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.personRoleList = responses[2].model;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }
      if (this.personGUID != null && this.personGUID != "") {
        if (responses[3] != null) {
          if (responses[3].code == "200" && responses[3].message == "") {
            let person = new PersonModel();
            person = responses[3].model;
            this.firstName = person.firstName;
            this.lastName = person.lastName;
            this.middleName = person.middleName;
            this.hasActiveTrip = person.hasActiveTrip;
            this.activeTripsDetails = person.activeTripsDetails;
            
            this.gender = person.gender;
            if (person.dob != null && person.dob != "")
              this.dob = new Date(person.dob);
            this.title = person.jobTitle;
            this.city = person.cityOfBirth;
            this.state_province = person.state_Prov_OfBirth;
            if (person.countryOfBirthGUID != "" && person.countryOfBirthGUID!=null)
              this.selectedBirthCountryGUID = person.countryOfBirthGUID.toLowerCase();
            if (person.nationalityGUID!="")
              this.selectedNationalityCountryGUID = person.nationalityGUID.toLowerCase();
            if (person.countryOfResidenceGUID!="")
              this.selectedResidenceCountryGUID = person.countryOfResidenceGUID.toLowerCase();
            this.modifiedBy = person.modifiedBy;
            this.modifiedDate = person.modifiedDate;
            
            if (person.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;
            if (person.eesetiasOutOfScope)
              this.eesetiasOutOfScope = 1;
            else
              this.eesetiasOutOfScope = 0;
            if (person.eesetiasOutOfScopeExpirationDate != null && person.eesetiasOutOfScopeExpirationDate != "")
              this.eesetiasOutOfScopeExpirationDate = new Date(person.eesetiasOutOfScopeExpirationDate);
            this.selectedDataEntryStatusID = person.dataEntryStatusID;
          }
          else {
            if (responses[3].code == "401") {
              this._authService.signOut();
            }
          }
        }      
      }
      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].message == "") {
          this.dataEntryStatusList = responses[4].model;

        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[5] != null) {
        if (responses[5].code == "200" && responses[5].message == "") {
          this.travelDocumentList = responses[5].model;          
        }
        else {
          if (responses[5].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();

      this.showSpin = false;

      this.setPersonRoleList();


    });
  }

  initControls() {
    this.person_edit_form = this._formBuilder.group({
      //comm_address_type_select: this.selectedCommAddressTypeId, //this.commAddressTypeList[0].commAddressTypeGUID,
      //address_rating_select: this.selectedAddressRatingId, //[this.addressRatingList[0].addressRatingGUID],
      //comm_address_status: this.isActive,
      firstName: [this.firstName, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z ',-.]*$")]
      )],
      lastName: [this.lastName, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z ',-.]*$")]
      )],
      middleName: [this.middleName],
      title: [this.title],
      dob: [this.dob],
      gender_select: [this.gender],
      city: [this.city],
      state_province: [this.state_province],
      birth_country_select: [this.selectedBirthCountryGUID],
      residence_country_select: [this.selectedResidenceCountryGUID],
      nationality_country_select: [this.selectedNationalityCountryGUID],
      person_class_select: [this.selectedPersonClass],
      person_status: this.isActive,
      modifiedBy: [this.modifiedBy],
      modifiedDate: [this.modifiedDate],
      data_entry_status_select: [this.selectedDataEntryStatusID],
      eesetiasOutOfScopeExpirationDate: this.eesetiasOutOfScopeExpirationDate,
      eesetiasOutOfScope: this.eesetiasOutOfScope
    });
  }


  get f() { return this.person_edit_form.controls; }

  ngAfterViewInit() {
    
  }

  personClassOnchange(e: any) {
    this.selectedPersonClass = this.f.person_class_select.value;
    //let y = this.f.person_class_select.value.personClassDescription;
    
    this.setPersonRoleList();
  }

  clickAddItem(e: any, item: PersonRoleModel) {
    this.personRoleList.forEach(x => {
      if (x.personRoleGUID.toLowerCase() == item.personRoleGUID.toLowerCase()) {
        x.selected = true;
      }
    })
    this.setPersonRoleList();

  }

  clickRemoveItem(e: any, item: PersonRoleModel) {
    this.personRoleList.forEach(x => {
      if (x.personRoleGUID.toLowerCase() == item.personRoleGUID.toLowerCase()) {
        x.selected = false;
      }
    })
    this.setPersonRoleList();

  }

  setPersonRoleList() {
    this.errMsg = "";
    if (this.selectedPersonClass.personClassGUID != "") {
      this.avaiPersonRoleList = this.personRoleList.filter(u => u.selected == false && u.personClassGUID.toLowerCase() == this.selectedPersonClass.personClassGUID.toLowerCase());
    }
    else {
      this.avaiPersonRoleList = this.personRoleList.filter(u => u.selected == false);
    }
    this.selPersonRoleList = this.personRoleList.filter(u => u.selected == true);
  }

  clickSelectAll() {
    this.personRoleList.forEach(x => {
      if (this.selectedPersonClass.personClassGUID == "") {
        x.selected = true;
      }
      else {
        if (this.selectedPersonClass.personClassGUID.toLowerCase() == x.personClassGUID.toLowerCase()) {
          x.selected = true;
        }
      }
      
    })

    this.setPersonRoleList();
  }

  clickRemoveAll() {
    this.personRoleList.forEach(x => {
      x.selected = false;
    })

    this.setPersonRoleList();
  }

  clickSave(isClickSaved: boolean) {
    
    
    this.errMsg = "";
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    if (this.dobRef.nativeElement.value !== "") {
      if (this.f.dob.value != null) {
     // if (this.f.dob.value != "" && this.f.dob.value != null) {
        let s1 = CustomValidators.formatDateToMMDDYYYY(new Date(this.f.dob.value));
        let f1 = CustomValidators.isValidDate(s1);
        if (!f1) {
          this.f.dob.setErrors({ isValidDate: f1 });

        }
        if (new Date(this.f.dob.value).getTime() > new Date().getTime()) {
          this.f.dob.setErrors({ isFutureDate: true });
        }
      }
      else {
        this.f.dob.setErrors({ isValidDate: false });

      }
    }
    if (this.f.firstName.value.trim() == "") {
      this.f.firstName.setErrors({ required: true });
      return;
    }
    if (this.f.lastName.value.trim() == "") {
      this.f.lastName.setErrors({ required: true });
      return;
    }
    //if (this.selPersonRoleList.length == 0) {
    //  this.errMsg = "You must select at least one person role.";
    //}

    if (this.person_edit_form.invalid || this.errMsg != "" || this.selPersonRoleList.length == 0) {
      return;
    }

    let nameMsg = "";
    if (this.travelDocumentList != null) {
      this.travelDocumentList.forEach(x => {
        if (x.firstName != this.f.firstName.value || x.middleName != this.f.middleName.value || x.lastName != this.f.lastName.value) {
          if (nameMsg == "")
            nameMsg = "Name does not match the following documents:";
          nameMsg += "\n" + x.documentIssuingCountryName + " " + x.documentTypeDescription + " - ";
          let matchString = "";
          if (x.firstName != this.f.firstName.value)
            matchString += "First Name ";
          if (x.middleName != this.f.middleName.value)
            matchString += "Middle Name ";
          if (x.lastName != this.f.lastName.value)
            matchString += "Last Name ";
          nameMsg += matchString.trim();
        }
      });
    }
    if (nameMsg == "")
    {
      this.showConfirmationAlert(isClickSaved)

    }
    else {
      this.confirmDialogRef = this._dialogService.openConfirmDialog(nameMsg + "\n \n Continue?",false);
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result)
          this.showConfirmationAlert(isClickSaved);          
      });
    }


  }

  saveData() {
    this.loading = true;
    let obj = new PersonModel();
    obj.customerGUID = "";
    if (this.userType == "internal" || (this.userType == "customer" && this.grantAccessToAccountGrouping))
      obj.customerGUID = this.customerGUID;
    obj.personGUID = "";
    if (this.personGUID != "" && this.personGUID != null)
      obj.personGUID = this.personGUID;
    obj.personRoleGUIDList = "";
    if (this.selPersonRoleList.length > 0)
      obj.personRoleGUIDList = Array.prototype.map.call(this.selPersonRoleList, s => s.personRoleGUID).toString();
    obj.firstName = this.f.firstName.value;
    obj.lastName = this.f.lastName.value;
    obj.middleName = this.f.middleName.value;
    obj.jobTitle = "";
    if (this.f.title.value != "")
      obj.jobTitle = this.f.title.value;
    obj.dob = "";
    if (this.f.dob.value != null) {
      obj.dob = this.dobRef.nativeElement.value;
    }
    obj.gender = "";
    if (this.f.gender_select.value != "")
      obj.gender = this.f.gender_select.value;
    obj.cityOfBirth = "";
    if (this.f.city.value != "")
      obj.cityOfBirth = this.f.city.value;
    obj.state_Prov_OfBirth = "";
    if (this.f.state_province.value != "")
      obj.state_Prov_OfBirth = this.f.state_province.value;
    obj.countryOfBirthGUID = "";
    if (this.f.birth_country_select.value != "")
      obj.countryOfBirthGUID = this.f.birth_country_select.value;
    obj.countryOfResidenceGUID = "";
    if (this.f.residence_country_select.value != "")
      obj.countryOfResidenceGUID = this.f.residence_country_select.value;
    obj.nationalityGUID = "";
    if (this.f.nationality_country_select.value != "")
      obj.nationalityGUID = this.f.nationality_country_select.value;
    obj.isActive = true;
    if (obj.personGUID != "") {
      if (this.f.person_status.value == "1")
        obj.isActive = true;
      else
        obj.isActive = false;
    }
    obj.selectedPersonClassGUID = this.selectedPersonClass.personClassGUID;
    obj.dataEntryStatusID = 0;
    if (this.userType == 'internal')
      obj.dataEntryStatusID = Number(this.f.data_entry_status_select.value);

    if (this.f.eesetiasOutOfScope.value == "1")
      obj.eesetiasOutOfScope = true;
    else
      obj.eesetiasOutOfScope = false;
  
    obj.eesetiasOutOfScopeExpirationDate = "";
    if (this.f.eesetiasOutOfScopeExpirationDate.value != null) {
      obj.eesetiasOutOfScopeExpirationDate = this.outOfScopeRef.nativeElement.value;
    }

      

    this._personService.saveCustomerPerson<ResponseModel<string>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != "") {
          this.personGUID = response.model;
          if (this.userType == 'internal' && obj.personGUID == "" && this.personGUID != "") {
            this.selectedDataEntryStatusID = 2;
            this.f.data_entry_status_select.setValue(this.selectedDataEntryStatusID);
          }
          this.returnObj.refresh = true;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          //if (obj.personGUID == "") {
          //  //this.showContactButton = true;
          //  this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          //}
          //else {
          //  this._dialogService.openAlertDialog("Your information has been submitted successfully.");
          //  //this.clickClose();
          //}
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          if (response.message != "")
            this.errMsg = response.message
          else
            this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    })
  }


  showConfirmationAlert(isClickSaved : boolean) {
    if (this.f.person_status.value == "0" && this.hasActiveTrip && isClickSaved) {
      this.confirmDialogRef2 = this._dialogService.openConfirmWithMultipleLinesDialog(['This Person is used in Active Trips. Do you want to Continue? '], this.activeTripsDetails);
      this.eventSubscription = this.confirmDialogRef2.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          //user clicked yes 
          this.confirmDialogRef2.close(true);
          this.saveData();
        }
        else {
          //user clicked no  
        }
      }); 
    }
    else { 
      this.saveData();
    }
  }

  clickOpenContactDetails() {
    this.returnObj.refresh = false;
    this.returnObj.newId = this.personGUID;   
    this._dialogRef.close(this.returnObj);
  }

  clickClose() {
    this._dialogRef.close(this.returnObj);
  }
  setValidity() {
    if (this.f.eesetiasOutOfScope.value == "1") {
      this.f.eesetiasOutOfScopeExpirationDate.addValidators(Validators.required);
    }
    else {
      this.f.eesetiasOutOfScopeExpirationDate.clearValidators();
    }
    this.f.eesetiasOutOfScopeExpirationDate.updateValueAndValidity();
  }
}
