<!-- <div style="max-width:40em; margin-left:auto; margin-right: auto">
  <div>Loading....</div>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  
  border: 1px solid black;
</div> -->


<div  *ngIf="!isClose"  style="background-color:#f8f8f8;  height: 19em;  "  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div style="background-color:#f8f8f8; padding: 0.5em; height: 100%;">


    <div style="display: flex;padding-left:0em;background-color:white; width: 30em; font-size: x-large; color: #0fa4ee;
        padding-bottom: 0.5em; justify-content: center; ">
      <div style="margin-top:0.3125em; margin-left: -1.5em; width: 18em;">
        <div style="display: flex; width: 100%; justify-content: space-between; margin-left: 7em;">
          <span>LOADING</span>
           <div style="display: flex;"> 
            <button *ngIf="!isClose"  mat-icon-button  (click)="clickClose()" > <i class="material-icons"
                style="font-size: xx-large;">close</i> </button>
            <!-- <button *ngIf="!isClose"  mat-icon-button  (click)="clickClose()" > <i class="material-icons"
                style="font-size: xx-large;"> keyboard_arrow_up</i> </button> -->
            <!-- <button *ngIf="isClose"  mat-icon-button  (click)="clickClose()" > <i class="material-icons"
                style="font-size: xx-large;">keyboard_arrow_down </i> </button> -->
          </div>
        </div>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
    <div  *ngIf="!isClose" >
      
    
      <div style="margin: 0.2em; height: 1em;   "></div>
      <h4> Analyzing Trip Details</h4>
      <div style="display: flex">
        <!-- <div style="width: 0.5em;">
          <div style="width: 0.2em; height: 10.5em;margin-top: 0em;float: right; background-color: #f18e8e; ">
          </div>
        </div> -->
        <div>
          <ul>
            <li>Trip Instructions</li>
            <li>Airports Itinerary details</li>
            <li>Vendors details</li>
            <li>Manifest details</li>
            <li>Formatting manifest Tool-tips</li>
            <li>Checking for expiry manifest documents</li>
          </ul>
        </div>
        
        <!-- <div style="width: 0.5em;">
          <div style="width: 0.2em; height: 10.5em;margin-top: 0em;float: right; background-color: #f18e8e; ">
          </div>
        </div> -->
        <div>
          <ul>
            <li>Retrieving airport services</li>
            <li>Checking Notes, Attachments, Messages</li>
            <li>Validating changes and adding Alert Bells</li>
            <li>Updating Trip Alerts</li>
            <li>Loading Map</li>
            <li>Loading Audit Trail</li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</div>