import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChildren, ViewContainerRef, QueryList, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
import { GroundStopEditorDialogComponent } from './ground-stop-editor-dialog.component';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopTaskEditDialogComponent } from '../ground-stop-advanced/ground-stop-task-edit-dialog.component';
import { GroundStopServiceTypeSelectionDialogComponent } from '../ground-stop-advanced/ground-stop-service-type-selection-dialog.component';
import { ServiceClassModel } from '../models/service-class.model';
import { GroundStopCrewPaxEditDialogComponent } from '../ground-stop-advanced/ground-stop-crew-pax-edit-dialog.component';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAlternateAirportEditDialogComponent } from '../ground-stop-advanced/ground-stop-alternate-airport-edit-dialog.component';
import { GroundStopDetailsDialogComponent } from './ground-stop-details-dialog.component';
import { AirportDetailsComponent } from '../airports/airport-details.component';
import { GroundStopAlternateAirportModel } from '../models/ground-stop-alternate-airport.model';
import { FlightFollowingEditDialogComponent } from '../ground-stop-advanced/flight-following-edit-dialog.component';
import { TripRevisionModel } from '../models/trip-revision.model';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
import { TripAuditDialogComponent } from './trip-audit-dialog.component';
import { TaskRevisionRequiredEditDialogComponent } from '../ground-stop-advanced/task-revision-required-edit-dialog.component';
import { ClientHaltServicesViewDialogComponent } from '../ground-stop-advanced/client-halt-services-view-dialog.component';
import { DialogService } from '../services/dialog.service';
import { TripPersonEditorDialogComponent } from '../trip-legs/trip-person-editor-dialog.component';
import { TripMessageCenterComponent } from './trip-message-center.component';
import { Subscription } from 'rxjs';
import { OverFlightPermitTaskComponent } from '../ground-stop-advanced/overflight-permit-task.component';
import { FuelTaskEditDialogComponent } from '../fuel-quote/fuel-task-edit-dialog.component';
import { LandingPermitPrivateTaskComponent } from '../ground-stop-advanced/landing-permit-private-task.component';
import { SlotArrivalTaskComponent } from '../ground-stop-advanced/slot-arrival-task.component';
import { InternalGroundStopTaskEditDialogComponent } from '../ground-stop-advanced/internal-ground-stop-task-edit-dialog.component';
import { InternalPublicGroundStopTaskEditDialogComponent } from '../ground-stop-advanced/internal-public-ground-stop-task-edit-dialog.component';
import { CustomerPersonDetailsComponent } from '../persons/customer-person-details.component';
import { FlightPlanTaskComponent } from '../ground-stop-advanced/flight-plan-task.component';
import { UkGarTaskComponent } from '../ground-stop-advanced/uk-gar-task.component';
import { AirportModel } from '../models/airport.model';
import { AirportBriefComponent } from '../trip-planner/airport-brief.component';

@Component({
  selector: 'app-ground-stoplist-internal',
  templateUrl: './ground-stoplist-internal.component.html',
  styleUrls: ['./ground-stoplist-internal.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})

export class GroundStoplistInternalComponent implements OnChanges {

  @Input() requestInput: TripModel;
  @Input() reloadChildPage: boolean;
  @Input() selectedTabName: string;
  @Input() v: number;
  @Input() refreshChildColumn: string;
  @Input() hidePastStop: boolean;
  @Input() includeActiveStopOnly: boolean;
  @Input() lockdownTrip: boolean;

  request: TripModel;
  //customerGUID: string;
  ground_stoplist_internal_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = false;
  isLoading: boolean = false;
  //selectedTabName: string;
  totalRecordsText: string;
  public totalRecords: number = 0;
  public pageSize: number = 6;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;

  public recordList: GroundStopModel[];

  @Output() public updateParentPage = new EventEmitter();
  subcription: Subscription;

  //displayedColumn: string[] = ['airport_itinerary_crew_pax', 'fbo_handler', 'airport_service', 'preflight_service', 'flightplan_service', 'flight_following', 'callLog', 'action'];
  displayedColumn: string[] = ['airport_itinerary_crew_pax_fbo_service', 'callLog', 'action'];

  scrHeight: number;
  upPII: boolean = false;
  gsId: string = "";
  gstId: string = "";
  @ViewChildren('groundStopGUIDRefs', { read: ViewContainerRef }) groundStopGUIDRefs: QueryList<ViewContainerRef>;
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";
  public pageIndex: number = 0;
  @ViewChild('uiElement', { static: false }) public uiElement: ElementRef;

  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _dialogService: DialogService
  ) {

  }
  public async onScrollLoadData() {
    const nativeElement = this.uiElement.nativeElement
    if (nativeElement.clientHeight + Math.round(nativeElement.scrollTop) >= nativeElement.scrollHeight - 1 && this.recordList.length !== this.totalRecords && !this.isLoading && !this.showSpin) {
      this.pageIndex += 1;
      this.request.pageIndex = this.pageIndex;
      await this.getSearchResults(this.request, true);

    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    //if ((!this.reloadChildPage && this.refreshChildColumn == "") || this.selectedTabName != 'groundStop') {
    //    return;
    //}
    if (this.selectedTabName != 'groundStop') {
      return;
    }
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.request = (JSON.parse(JSON.stringify(this.requestInput)));
    if (!this.reloadChildPage && this.refreshChildColumn == "") {
    }
    else {
      if (this.refreshChildColumn == "") {
        this.showSpin = true;
        this.recordList = [];
        this.totalRecordsText = '';
        this.totalRecords = 0;
        this.currentPageIndex = 0;
        this.totalPageNumber = 0;
        this.scrHeight = window.innerHeight;
        //if (this.scrHeight < 900) {
        //  this.pageSize = 10;

        //}
        //else {
        //  this.pageSize = 15;
        //}
        if (this.request.groundStopTaskGUID != null && this.request.groundStopTaskGUID != undefined && this.request.groundStopTaskGUID != "")
          this.pageSize = 0;
        else
          this.pageSize = 6;
        this.pageIndex = 0;
        this.request.pageIndex = this.currentPageIndex;
        this.request.pageSize = this.pageSize;

        this.request.totalRecords = this.totalRecords;
        if (this.request.groundStopGUID != null && this.request.groundStopGUID != undefined && this.request.groundStopGUID != "")
          this.gsId = this.request.groundStopGUID.toLowerCase();
        if (this.request.groundStopTaskGUID != null && this.request.groundStopTaskGUID != undefined && this.request.groundStopTaskGUID != "")
          this.gstId = this.request.groundStopTaskGUID.toLowerCase();
        this.initControls();
        await this.getSearchResults(this.request);

      }
      else {
        await this.getTripRevisionUsernameListBygIdList('');
      }
    }

  }


  initControls() {
    this.ground_stoplist_internal_form = this._formBuilder.group({

    })
  }

  get f() { return this.ground_stoplist_internal_form.controls; }

  async getSearchResults(request: TripModel, fromScroll: boolean = false) {

    this._authService.updateAccessTime();
    this.showSpin = true;
    this.totalRecordsText = "";
    this.isLoading = true;
    if (fromScroll == false) {
      this.pageIndex = 0;
      request.pageIndex = 0;// this.request.pageIndex;
      if (this.recordList.length > 0) {
        request.pageSize = this.recordList.length;// this.request.pageSize;
        this.recordList = [];
      }

      else
        request.pageSize = 6;
    }
    if (this.subcription)
      this.subcription.unsubscribe();
    return new Promise(() => {
      //const response: any = await this._groundStopService.getGroundStopListForInternalByConditions<ResponseModel<GroundStopModel[]>>(request).toPromise();
      this.subcription = this._groundStopService.getGroundStopListForInternalByConditions<ResponseModel<GroundStopModel[]>>(request).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model.length > 0) {
            this.recordList = [...this.recordList, ...response.model];
            if (this.recordList[0].totalRecords > 0) {
              this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
              this.totalRecords = this.recordList[0].totalRecords;
              this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
              //let gst = new GroundStopTaskModel();
              //gst.serviceClassID = 1;
              //gst.groundStopGUIDList = Array.prototype.map.call(this.recordList, s => s.groundStopGUID).toString();
              //this.updateServiceListByServiceClassGroundStopIDList(gst);
              this.updateParentPage.emit({ updateSection: 'groundStop' });
              //this.recordList.forEach(x => {
              //  this.getTripRevisionUsernameListBygId(x.groundStopGUID);
              //});

            }

            // open speicifc task page
            if (this.gsId != null && this.gsId != undefined && this.gsId != "") {
              let gs = new GroundStopModel()
              let i: number = this.recordList.findIndex(x => x.groundStopGUID.toLowerCase() == this.gsId);
              if (i != -1) {
                let root = this;
                setTimeout(function () {
                  var selectedItem = root.groundStopGUIDRefs.find(r => r.element.nativeElement.getAttribute('groundStopGUID').toLowerCase() === root.recordList[i].groundStopGUID.toLowerCase());
                  selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
                }, 500);
                //gs = this.recordList[i];
              }
              //  let findTask: boolean = false;
              //  let root = this;
              //  if (gs != null) {
              //    if (this.gstId != null && this.gstId != undefined && this.gstId != "") {
              //      let gst = new GroundStopTaskModel();
              //      let aaps: GroundStopTaskModel[] = [];
              //      let daps: GroundStopTaskModel[] = [];
              //      aaps = gs.arrAirportServiceTaskList;
              //      if (aaps.length > 0) {
              //        gst = aaps.find(x => x.groundStopTaskGUID == this.gstId);
              //        if (gst != null) {
              //          findTask = true;
              //          this.clickServiceType(null, gs, gst);
              //        }
              //      }
              //      if (!findTask) {
              //        daps = gs.depAirportServiceTaskList;
              //        if (daps.length > 0) {
              //          gst = daps.find(x => x.groundStopTaskGUID == this.gstId);
              //          if (gst != null) {
              //            findTask = true;
              //            this.clickServiceType(null, gs, gst);
              //          }
              //        }
              //      }
              //      let aprfs: GroundStopTaskModel[] = [];
              //      let dprfs: GroundStopTaskModel[] = [];
              //      aprfs = gs.arrPreflightServiceTaskList;
              //      if (aprfs.length > 0) {
              //        gst = aprfs.find(x => x.groundStopTaskGUID == this.gstId);
              //        if (gst != null) {
              //          findTask = true;
              //          this.clickServiceType(null, gs, gst);
              //        }
              //      }
              //      if (!findTask) {
              //        dprfs = gs.depPreflightServiceTaskList;
              //        if (dprfs.length > 0) {
              //          gst = dprfs.find(x => x.groundStopTaskGUID == this.gstId);
              //          if (gst != null) {
              //            findTask = true;
              //            this.clickServiceType(null, gs, gst);
              //          }
              //        }
              //      }
              //      let afps: GroundStopTaskModel[] = [];
              //      let dfps: GroundStopTaskModel[] = [];
              //      afps = gs.arrFlightPlanServiceTaskList;
              //      if (afps.length > 0) {
              //        gst = afps.find(x => x.groundStopTaskGUID == this.gstId);
              //        if (gst != null) {
              //          findTask = true;
              //          this.clickServiceType(null, gs, gst);
              //        }
              //      }
              //      if (!findTask) {
              //        dfps = gs.depFlightPlanServiceTaskList;
              //        if (dfps.length > 0) {
              //          gst = dfps.find(x => x.groundStopTaskGUID == this.gstId);
              //          if (gst != null) {
              //            findTask = true;
              //            this.clickServiceType(null, gs, gst);
              //          }
              //        }
              //      }
              //      let affs: GroundStopTaskModel[] = [];
              //      let dffs: GroundStopTaskModel[] = [];
              //      affs = gs.arrFlightFollowingTaskList;
              //      if (affs.length > 0) {
              //        gst = affs.find(x => x.groundStopTaskGUID == this.gstId);
              //        if (gst != null) {
              //          findTask = true;
              //          this.clickServiceType(null, gs, gst);
              //        }
              //      }
              //      if (!findTask) {
              //        dfps = gs.depFlightFollowingTaskList;
              //        if (dffs.length > 0) {
              //          gst = dffs.find(x => x.groundStopTaskGUID == this.gstId);
              //          if (gst != null) {
              //            findTask = true;
              //            this.clickServiceType(null, gs, gst);
              //          }
              //        }
              //      }

              //    }
              //    setTimeout(function () {
              //      var selectedItem = root.groundStopGUIDRefs.find(r => r.element.nativeElement.getAttribute('groundStopGUID').toLowerCase() === root.recordList[i].groundStopGUID.toLowerCase());
              //      selectedItem.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
              //    }, 500);
              //  }

            }
            // open speicifc task page end
          }
          else {
            this.totalRecordsText = "No record found";

            //this._dialogService.openAlertDialog("Failed to create the trip at this time. Please try later.");
            //this._dialogRef.close(this.isModified);
          }
          this.isLoading = false;
          this.showSpin = false;
          //this.sendDataToParent.emit({ totalRecordsText: this.totalRecordsText });
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to get trips at this time. Please try later.";
          }
        }
        this.isLoading = false;
        this.showSpin = false;
      });
    });
  }

  async getTripRevisionUsernameListBygIdList(gId: string) {
    if (gId != "") {
      let obj = new TripRevisionModel();
      obj.groundStopGUIDList = gId;
      obj.pastHours = this.request.pastHours;
      const response: any = this._groundStopService.getTripRevisionUserNameListBygIdListHours<ResponseModel<GroundStopModel[]>>(obj).toPromise();

      if (response != null && response.code == "200") {
        this.recordList.find(x => x.groundStopGUID.toLowerCase() == gId.toLowerCase()).tripRevisionList = [];
        if (response.model.length > 0) {
          let gsList: GroundStopModel[];
          //gs = new GroundStopModel();
          gsList = response.model;
          this.recordList.find(x => x.groundStopGUID.toLowerCase() == gId.toLowerCase()).tripRevisionList = gsList[0].tripRevisionList;
        }
      }


    }
    else {
      let obj = new TripRevisionModel();
      obj.pastHours = this.request.pastHours;
      obj.groundStopGUIDList = Array.prototype.map.call(this.recordList, s => s.groundStopGUID).toString();
      const response: any = this._groundStopService.getTripRevisionUserNameListBygIdListHours<ResponseModel<GroundStopModel[]>>(obj).toPromise();
      if (response != null && response.code == "200") {
        let gsList: GroundStopModel[];
        gsList = response.model;
        for (var i = 0; i < this.recordList.length; i++) {
          this.recordList[i].tripRevisionList = [];
          for (var j = 0; j < gsList.length; j++) {
            if (this.recordList[i].groundStopGUID.toLowerCase() == gsList[j].groundStopGUID.toLowerCase()) {
              this.recordList[i].tripRevisionList = gsList[j].tripRevisionList;
              break;
            }
          }
        }
      }


    }


  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
      this.request.pageSize = this.pageSize;
      this.request.totalRecords = this.totalRecords;
    }
    this.request.pageIndex = this.currentPageIndex;
    this.getSearchResults(this.request);

  }

  clickAdd(event: any, p: GroundStopModel) {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    // edit exsting ground stop
    dialogConfig.data = { dialogTitle: s, customerGUID: this.request.customerGUID, tripCodeGUID: p.tripCodeGUID, v: this._authService.getCurrentTimeNumber() };
    const dialogRef = this._dialog.open(GroundStopEditorDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.sendDataToParent.emit();
        this.totalRecords = 0;
        this.totalRecordsText = "";
        this.totalPageNumber = 0;
        this.currentPageIndex = 0;
        this.request.pageIndex = this.currentPageIndex;
        this.request.totalRecords = this.totalRecords;
        this.getSearchResults(this.request);
        this.updateParentPage.emit({ updateSection: 'groundStop' });

      }

    });

  }

  clickServiceType(e: any, gsItem: GroundStopModel, gstItem: GroundStopTaskModel, taskType: string) {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.minWidth = "54em";
    let dialogHeight = "";
    if (gstItem.serviceTypeID == 1 || gstItem.vendorRequired == true || gstItem.serviceTypeID == 35 || gstItem.serviceTypeID == 3
      || gstItem.serviceTypeID == 7 || gstItem.serviceTypeID == 20 || gstItem.serviceTypeID == 56) {

      dialogConfig.minHeight = "39.2em";
    }
    else {
      //dialogConfig.height = "37em";
      //dialogHeight = "37em";
      dialogConfig.minHeight = "37em";
    }
    //dialogConfig.height = "40em";//"500px";
    dialogConfig.disableClose = true;
    let leg: string = "";
    leg = gsItem.icao;
    if (gsItem.nextICAO != "" && taskType == 'D') {
      leg += "-" + gsItem.nextICAO;
    }
    if (gsItem.prevICAO != "" && taskType == 'A') {
      leg = gsItem.prevICAO + "-" + leg;
    }
    let depGroundStopGUID: string = "";
    let arrGroundStopGUID: string = "";

    if (taskType == 'D') {
      depGroundStopGUID = gsItem.groundStopGUID;
      arrGroundStopGUID = gsItem.nextGroundStopGUID;
    }
    else {
      depGroundStopGUID = gsItem.prevGroundStopGUID;
      arrGroundStopGUID = gsItem.groundStopGUID;
    }

    dialogConfig.data = {
      groundStopGUID: gsItem.groundStopGUID, tripCodeGUID: gsItem.tripCodeGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID,
      serviceTypeID: gstItem.serviceTypeID, vendorReq: gstItem.vendorRequired, countryId: gsItem.countryGUID, icao: gsItem.icao,
      serviceTypeDesc: gstItem.serviceTypeDescription, v: this._authService.getCurrentTimeNumber(),
      customerGUID: this.request.customerGUID, leg: leg, nextGroundStopGUID: arrGroundStopGUID, //gsItem.nextGroundStopGUID,
      depGroundStopGUID: depGroundStopGUID
    };
    //if (gstItem.serviceTypeID != 69 && gstItem.serviceTypeID != 70 && gstItem.serviceTypeID != 44 && gstItem.serviceTypeID != 45 && gstItem.serviceTypeID != 46
    //  && gstItem.serviceTypeID != 48 && gstItem.serviceTypeID != 49 && gstItem.serviceTypeID != 60 && gstItem.serviceTypeID != 61 && gstItem.serviceTypeID != 62) {


    let dialogRef;
    switch (gstItem.formType) {
      case 1: //ops legacy
        dialogRef = this._dialog.open(GroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 2: //OpsOverflightPermitForm
        dialogRef = this._dialog.open(OverFlightPermitTaskComponent, dialogConfig);
        break;
      case 3: //OpsSlotForm
        dialogRef = this._dialog.open(SlotArrivalTaskComponent, dialogConfig);
        break;
      case 4: //InternalOnly
        dialogRef = this._dialog.open(InternalGroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 5: //InternalPublic
        dialogRef = this._dialog.open(InternalPublicGroundStopTaskEditDialogComponent, dialogConfig);
        break;
      case 7: //FuelTaskForm
        dialogRef = this._dialog.open(FuelTaskEditDialogComponent, dialogConfig);
        break;
      case 8: //landing
        dialogRef = this._dialog.open(LandingPermitPrivateTaskComponent, dialogConfig);
        break;
      case 9: //flight plan
        dialogRef = this._dialog.open(FlightPlanTaskComponent, dialogConfig);
        break;
      case 10: //gar
        dialogRef = this._dialog.open(UkGarTaskComponent, dialogConfig);
        break;
      default:
        dialogRef = this._dialog.open(GroundStopTaskEditDialogComponent, dialogConfig);
        break;
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result.isModified) {
        this.showSpin = true;

        let groundStopGUIDListStr: string = "";
        result.groundStopGUIDList.forEach(item => {
          groundStopGUIDListStr += item
          if(result.groundStopGUIDList.indexOf(item) < result.groundStopGUIDList.length - 1) {
            groundStopGUIDListStr += ','
          }
        })
        if(groundStopGUIDListStr == '') {
          groundStopGUIDListStr = gstItem.groundStopGUID;
        }
        let obj = new GroundStopTaskModel();
        obj.serviceClassID = gstItem.serviceClassID;
        obj.groundStopGUIDList = groundStopGUIDListStr;
        obj.countryGUID = gsItem.countryGUID;
        obj.serviceTypeID = gstItem.serviceTypeID;
        obj.tripCode = gstItem.tripCode;
        //this.updateServiceListByServiceClassGroundStopID(obj);
        if (gstItem.serviceTypeID != 35) {
          this.updateServiceListByServiceClassGroundStopIDList(obj);
          this.updateParentPage.emit({ updateSection: 'groundStop' });
          this.getTripRevisionUsernameListBygIdList(gsItem.groundStopGUID);
          this.getPointOfConact(gsItem.groundStopGUID);
        }
        else {
          this.getTripInfo(gsItem.tripCodeGUID);
          this.updateParentPage.emit({ updateSection: 'groundStop' });
          this.getTripRevisionUsernameListBygIdList(gsItem.groundStopGUID);
        }
      }
    });

    // else {
    //   const dialogRef = this._dialog.open(FlightFollowingEditDialogComponent, dialogConfig);
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (result) {
    //       this.showSpin = true;
    //       let obj = new GroundStopTaskModel();
    //       obj.serviceClassID = gstItem.serviceClassID;
    //       obj.groundStopGUIDList = gsItem.groundStopGUID;
    //       //this.updateServiceListByServiceClassGroundStopID(obj);
    //       this.updateServiceListByServiceClassGroundStopIDList(obj);
    //       this.updateParentPage.emit({ updateSection: 'groundStop' });
    //       this.getTripRevisionUsernameListBygIdList(gsItem.groundStopGUID);
    //     }
    //   });
    // }

  }


  updateServiceListByServiceClassGroundStopIDList(obj: GroundStopTaskModel) {
    obj.serviceTypeIDList = this.request.serviceTypeIDList;
    obj.tripTaskStatusGUIDList = this.request.tripTaskStatusGUIDList;
    obj.includeDisabledTask = this.request.includeDisabledTask;
    this._authService.updateAccessTime();
    this.showSpin = true;
    this._groundStopService.getGroundStopTaskListByscIdgsIdList<ResponseModel<GroundStopModel[]>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        let gsstring: string[];
        gsstring = obj.groundStopGUIDList.split(',');
        let gsId: string;
        for (var i = 0; i < gsstring.length; i++) {
          gsId = gsstring[i];
          let rIndex = this.recordList.findIndex(x => x.groundStopGUID.toLowerCase() == gsId.toLowerCase());
          switch (obj.serviceClassID) {
            case 1:
              this.recordList[rIndex].airportServiceTaskList = [];
              this.recordList[rIndex].arrAirportServiceTaskList = [];
              this.recordList[rIndex].depAirportServiceTaskList = [];
              break;
            case 5:
              this.recordList[rIndex].flightPlanServiceTaskList = [];
              this.recordList[rIndex].arrFlightPlanServiceTaskList = [];
              this.recordList[rIndex].depFlightPlanServiceTaskList = [];
              break;
            case 6:
              this.recordList[rIndex].flightFollowingTaskList = [];
              this.recordList[rIndex].arrFlightFollowingTaskList = [];
              this.recordList[rIndex].depFlightFollowingTaskList = [];
              break;
            case 7:
              this.recordList[rIndex].preflightServiceTaskList = [];
              this.recordList[rIndex].arrPreflightServiceTaskList = [];
              this.recordList[rIndex].depPreflightServiceTaskList = [];
              break;
          }
        }
        if (response.model.length > 0) {
          let gsList: GroundStopModel[];
          gsList = response.model;
          gsList.forEach(y => {
            let rIndex = this.recordList.findIndex(x => x.groundStopGUID.toLowerCase() == y.groundStopGUID.toLowerCase());
            switch (obj.serviceClassID) {
              case 1:
                this.recordList[rIndex].airportServiceTaskList = y.airportServiceTaskList;
                this.recordList[rIndex].arrAirportServiceTaskList = y.arrAirportServiceTaskList;
                this.recordList[rIndex].depAirportServiceTaskList = y.depAirportServiceTaskList
                break;
              case 5:
                this.recordList[rIndex].flightPlanServiceTaskList = y.flightPlanServiceTaskList;
                this.recordList[rIndex].arrFlightPlanServiceTaskList = y.arrFlightPlanServiceTaskList;
                this.recordList[rIndex].depFlightPlanServiceTaskList = y.depFlightPlanServiceTaskList;
                break;
              case 6:
                this.recordList[rIndex].flightFollowingTaskList = y.flightFollowingTaskList;
                this.recordList[rIndex].arrFlightFollowingTaskList = y.arrFlightFollowingTaskList;
                this.recordList[rIndex].depFlightFollowingTaskList = y.depFlightFollowingTaskList;
                break;
              case 7:
                this.recordList[rIndex].preflightServiceTaskList = y.preflightServiceTaskList;
                this.recordList[rIndex].arrPreflightServiceTaskList = y.arrPreflightServiceTaskList;
                this.recordList[rIndex].depPreflightServiceTaskList = y.depPreflightServiceTaskList;
                break;
            }
          });


        }


      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.showSpin = false;
    })
  }

  clickAddServices(event: any, item: GroundStopModel) {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "34em";//"1000px";
    dialogConfig.minHeight = "23em";//"500px";
    dialogConfig.maxHeight = "40em";//"500px";
    dialogConfig.disableClose = true;

    if (item.hasHaltServices) {
      dialogConfig.data = {
        customerGUID: this.request.customerGUID, v: this._authService.getCurrentTimeNumber()
      };

      const dialogRef = this._dialog.open(ClientHaltServicesViewDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.openServiceSelection(item);

        }
      });
    }
    else {
      this.openServiceSelection(item);
    }

  }

  openServiceSelection(item: GroundStopModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "34em";//"1000px";
    dialogConfig.minHeight = "39em";//"500px";
    dialogConfig.maxHeight = "40em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      groundStopGUID: item.groundStopGUID, serviceClassID: 0, icao: item.icao, tripCodeGUID: item.tripCodeGUID,
      customerGUID: this.request.customerGUID, v: this._authService.getCurrentTimeNumber()
    };

    //const dialogRef = this._dialog.open(GroundStopServiceSelectionDialogComponent, dialogConfig);
    const dialogRef = this._dialog.open(GroundStopServiceTypeSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        let scList: ServiceClassModel[];
        scList = result.serviceClassList;
        //this.showSpin = true;
        if (result.applyAllGroundStops == false) {
          if (scList.length > 0) {
            scList.forEach((x, index) => {
              let gst = new GroundStopTaskModel();
              gst.groundStopGUIDList = item.groundStopGUID;
              gst.serviceClassID = x.serviceClassID;
              //this.updateServiceListByServiceClassGroundStopID(gst);
              this.updateServiceListByServiceClassGroundStopIDList(gst);
              this.showSpin = false;
            });
          }
        }
        else {
          let gslist: GroundStopModel[];
          //this.showSpin = true;
          gslist = this.recordList.filter(x => x.tripCodeGUID.toLowerCase() == item.tripCodeGUID.toLowerCase())
          gslist = gslist.filter((x, index) => index < (gslist.length - 1));
          let gsIdlist = Array.prototype.map.call(gslist, s => s.groundStopGUID).toString();
          if (scList.length > 0) {
            scList.forEach((y, indexY) => {
              let gst = new GroundStopTaskModel();
              gst.groundStopGUIDList = gsIdlist;
              gst.serviceClassID = y.serviceClassID;
              //this.updateServiceListByServiceClassGroundStopID(gst);
              this.updateServiceListByServiceClassGroundStopIDList(gst);
            });
          }

        }

      }
    });


  }


  clickEditCrewPax(event: any, item: GroundStopModel) {
    this._authService.updateAccessTime();
    let s: string = "";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "58em";//"1000px";
    dialogConfig.minHeight = "41.5em";//"500px";
    dialogConfig.disableClose = true;
    let currentIndex: number = -1;
    let mtype = "";
    if (item.isFirstGroundStop == false && item.nextGroundStopGUID == "") {
      mtype = 'Arrival'
    }
    else {
      if (item.isFirstGroundStop == true) {
        mtype = 'Departure'
        currentIndex = 0;
      }
    }

    dialogConfig.data = {
      dialogTitle: s, groundStopGUID: item.groundStopGUID, customerGUID: this.request.customerGUID, nextGroundStopGUID: item.nextGroundStopGUID,
      currentIndex: currentIndex, currentGroundStopSequenceID: item.groundStopSequenceID, tripCodeGUID: item.tripCodeGUID,
      v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(GroundStopCrewPaxEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        this.getTripInfo(item.tripCodeGUID);
        this.updateParentPage.emit({ updateSection: 'groundStop' });
        this.getTripRevisionUsernameListBygIdList(item.groundStopGUID);
      }
    });
  }

  //getCrewPaxBygsIdList(obj: GroundStopModel) {
  //  this._authService.updateAccessTime();
  //  this.showSpin = true;
  //  this._groundStopService.getAllCrewPaxListBygsIdList<ResponseModel<GroundStopModel[]>>(obj).subscribe(response => {
  //    if (response != null && response.code == "200") {
  //      let s1 = obj.groundStopGUIDList.split(',');
  //      for (var i = 0; i < s1.length; i++) {
  //        let rIndex = this.recordList.findIndex(x => x.groundStopGUID.toLowerCase() == s1[i].toLowerCase());
  //        if (this.recordList[rIndex].isFirstGroundStop == false) {
  //          this.recordList[rIndex].arrivalPIC = null;
  //          this.recordList[rIndex].arrivalSIC = null
  //          this.recordList[rIndex].arrivalCrewList = [];
  //          this.recordList[rIndex].arrivalPAXList = []
  //          this.recordList[rIndex].arrivalCrewCount = 0;
  //          this.recordList[rIndex].arrivalPAXCount = 0;
  //          this.recordList[rIndex].isDeptFerryFlight = false;
  //        }
  //        if (this.recordList[rIndex].isLastGroundStop == false) {
  //          this.recordList[rIndex].departurePIC = null;
  //          this.recordList[rIndex].departureSIC = null;
  //          this.recordList[rIndex].departureCrewList = [];
  //          this.recordList[rIndex].departurePAXList = [];
  //          this.recordList[rIndex].departureCrewCount = 0;
  //          this.recordList[rIndex].departurePAXCount = 0;
  //          this.recordList[rIndex].isDeptFerryFlight = false;
  //        }

  //      }
  //      if (response.model.length > 0) {
  //        let gsList: GroundStopModel[];
  //        gsList = response.model;

  //        gsList.forEach(y => {
  //          let rIndex = this.recordList.findIndex(x => x.groundStopGUID.toLowerCase() == y.groundStopGUID.toLowerCase());
  //          if (this.recordList[rIndex].isFirstGroundStop == false) {
  //            this.recordList[rIndex].arrivalPIC = null;
  //            this.recordList[rIndex].arrivalSIC = null
  //            this.recordList[rIndex].arrivalCrewList = [];
  //            this.recordList[rIndex].arrivalPAXList = []
  //            this.recordList[rIndex].arrivalPIC = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'Arrival PIC')[0];
  //            this.recordList[rIndex].arrivalSIC = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'Arrival SIC')[0];
  //            this.recordList[rIndex].arrivalCrewList = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'Arrival Crew');
  //            this.recordList[rIndex].arrivalPAXList = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'Arrival PAX');
  //            this.recordList[rIndex].arrivalCrewCount = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription.indexOf('PAX') == -1 && x.groundStopManifestTypeDescription.indexOf('Arrival') != -1).length;
  //            this.recordList[rIndex].arrivalPAXCount = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription.indexOf('PAX') != -1 && x.groundStopManifestTypeDescription.indexOf('Arrival') != -1).length;
  //            this.recordList[rIndex].isDeptFerryFlight = y.allCrewPaxList[0].isDeptFerryFlight;
  //          }
  //          if (this.recordList[rIndex].isLastGroundStop == false) {
  //            this.recordList[rIndex].departurePIC = null;
  //            this.recordList[rIndex].departureSIC = null;
  //            this.recordList[rIndex].departureCrewList = [];
  //            this.recordList[rIndex].departurePAXList = [];
  //            this.recordList[rIndex].departurePIC = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'Departure PIC')[0];
  //            this.recordList[rIndex].departureSIC = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'Departure SIC')[0];
  //            this.recordList[rIndex].departureCrewList = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'Departure Crew');
  //            this.recordList[rIndex].departurePAXList = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription == 'Departure PAX');
  //            this.recordList[rIndex].departureCrewCount = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription.indexOf('PAX') == -1 && x.groundStopManifestTypeDescription.indexOf('Departure') != -1).length;
  //            this.recordList[rIndex].departurePAXCount = y.allCrewPaxList.filter(x => x.groundStopManifestTypeDescription.indexOf('PAX') != -1 && x.groundStopManifestTypeDescription.indexOf('Departure') != -1).length;
  //            this.recordList[rIndex].isDeptFerryFlight = y.allCrewPaxList[0].isDeptFerryFlight;
  //          }

  //        });


  //      }


  //    }

  //    this.showSpin = false;

  //  })

  //}

  clickEditAlternateAirport(event: any, item: GroundStopModel) {
    this._authService.updateAccessTime();
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    let leg: string = "";
    leg = item.icao;
    if (item.nextICAO != "") {
      leg += "-" + item.nextICAO;
    }

    dialogConfig.data = {
      groundStopGUID: item.groundStopGUID, leg: leg, nextGroundStopGUID: item.nextGroundStopGUID,
      tripCodeGUID: item.tripCodeGUID, aircraftGUID: this.request.aircraftGUID, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(GroundStopAlternateAirportEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        //this.getGroundStopAltAirports(item.groundStopGUID);
        this.getTripInfo(item.tripCodeGUID);
      }
    });

  }

  clickGroundStopDetail(event: any, item: GroundStopModel) {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      groundStopGUID: item.groundStopGUID, isFirstGroundStop: item.isFirstGroundStop, nextGroundStopGUID: item.nextGroundStopGUID,
      tripCodeGUID: item.tripCodeGUID, tripCodeType: item.tripCodeType, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(GroundStopDetailsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh == true) {
        let rIndex: number;
        this.getTripInfo(item.tripCodeGUID);
        this.updateParentPage.emit({ updateSection: 'groundStop' });
        this.updateParentPage.emit({ updateSection: 'leg' }); // update route
        this.getTripRevisionUsernameListBygIdList('');

      }
    });

  }

  getGroundStopItinerary(gsID: string) {
    let rIndex = this.recordList.findIndex(x => x.groundStopGUID.toLowerCase() == gsID.toLowerCase());
    let obj = new GroundStopModel();
    obj.groundStopGUID = gsID
    this._groundStopService.getGroundStopItineraryBygsId<ResponseModel<GroundStopModel>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let gs = new GroundStopModel();
          gs = response.model;
          this.recordList[rIndex].groundStopTypeDescription = gs.groundStopTypeDescription;
          this.recordList[rIndex].groundStopTypeGUID = gs.groundStopTypeGUID;
          this.recordList[rIndex].groundStopSequenceID = gs.groundStopSequenceID;
          this.recordList[rIndex].isFirstGroundStop = gs.isFirstGroundStop;
          this.recordList[rIndex].isTechStop = gs.isTechStop;
          this.recordList[rIndex].ete = gs.ete;
          this.recordList[rIndex].departureFARTypeDescription = gs.departureFARTypeDescription;
          this.recordList[rIndex].departureCallSign = gs.callSign;
          this.recordList[rIndex].faR_CallSign = gs.faR_CallSign;
          this.recordList[rIndex].groundStopTypeColor = gs.groundStopTypeColor;
          this.recordList[rIndex].departurePetsOnBoard = gs.departurePetsOnBoard;
          this.recordList[rIndex].departureWeaponsOnBoard = gs.departureWeaponsOnBoard;
          this.recordList[rIndex].arrivalStatusColorCode = gs.arrivalStatusColorCode;
          this.recordList[rIndex].arrivalStatusDescription = gs.arrivalStatusDescription;
          this.recordList[rIndex].arrivalStatusID = gs.arrivalStatusID;
          this.recordList[rIndex].departureStatusColorCode = gs.departureStatusColorCode;
          this.recordList[rIndex].departureStatusDescription = gs.departureStatusDescription;
          this.recordList[rIndex].departureStatusID = gs.departureStatusID;
          this.recordList[rIndex].arrivalDateTextUTC = gs.arrivalDateTextUTC;
          this.recordList[rIndex].arrivalTimeUTC = gs.arrivalTimeUTC;
          this.recordList[rIndex].departureDateTextUTC = gs.departureDateTextUTC;
          this.recordList[rIndex].departureTimeUTC = gs.departureTimeUTC;
        }

      }

    })

  }

  getTripInfo(tripID: string) {
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.totalRecordsText = "";
    let obj = new TripModel();
    this.pageIndex = 0;
    obj.pageIndex = 0;// this.request.pageIndex;
    obj.pageSize = 0; this.recordList.length;// this.request.pageSize;
    obj.totalRecords = this.request.totalRecords;
    obj.tripCodeGUID = tripID;
    obj.customerGUID = this.request.customerGUID;
    obj.aircraftGUID = this.request.aircraftGUID;
    obj.groundStopTypeGUIDList = this.request.groundStopTypeGUIDList;
    obj.tripTaskStatusGUIDList = this.request.tripTaskStatusGUIDList;
    obj.accountingStatusGUIDList = this.request.accountingStatusGUIDList;
    obj.serviceTypeIDList = this.request.serviceTypeIDList;
    obj.includeDisabledTask = this.request.includeDisabledTask;
    obj.pastHours = this.request.pastHours;
    obj.hidePastStop = this.request.hidePastStop;
    this._groundStopService.getGroundStopListForInternalByConditions<ResponseModel<GroundStopModel[]>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        let gsList: GroundStopModel[];
        gsList = [];
        gsList = response.model
        let data: GroundStopModel[];
        data = [];
        data = this.recordList;
        this.recordList = [];
        if (response.model.length > 0) {

          data.forEach(x => {
            if (x.tripCodeGUID.toLowerCase() == tripID.toLowerCase()) {
              gsList.forEach(y => {
                if (x.groundStopGUID.toLowerCase() == y.groundStopGUID.toLowerCase()) {
                  x = y;
                  this.recordList.push(x);
                }
              });
            }
            else {
              this.recordList.push(x);
            }

          });

        }

        this.showSpin = false;

      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }

      }
      this.showSpin = false;
    })

  }

  //clickPersonName(e: any, item: GroundStopManifestModel, gsItem: GroundStopModel) {
  //  //let navigationExtras: NavigationExtras = {
  //  //  queryParams: {
  //  //    "pId": item.personGUID,
  //  //    "selectedPersonClassDescription": item.personClassDescription,
  //  //    "selectedPersonClassGUID": item.personClassGUID,
  //  //    v: this._authService.getCurrentTimeNumber()
  //  //  }
  //  //};
  //  //this._router.navigate(['/persons/customer-person-details'], navigationExtras);
  //  if (item.capacityID >= 6 && item.capacityID <= 13) {
  //    return;
  //  }
  //  //window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID + '&selectedPersonClassDescription=' + item.personClassDescription + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  //  window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');

  //}

  clickPersonName(e: any, item: GroundStopManifestModel, gsItem: GroundStopModel) {

    if (item.capacityID != 1) {
      return;
    }

    if (gsItem.groundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID || gsItem.groundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID) {
      //window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.panelClass = "custom-dialog-container";
      dialogConfig.maxHeight = "95vh";
      dialogConfig.hasBackdrop = false;
      dialogConfig.data = { personGUID: item.personGUID, customerGUID: this.request.customerGUID, selectedPersonClassGUID: item.personClassGUID, selectedPersonClassDescription: item.personClassDescription };

      const dialogRef = this._dialog.open(CustomerPersonDetailsComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // this.getAllData();
        }

      });
    }
    else {
      let personLegType: string = "";
      let personType: string = "";
      let i = item.groundStopManifestTypeDescription.indexOf(' ');
      personLegType = item.groundStopManifestTypeDescription.substring(0, i);
      personType = item.groundStopManifestTypeDescription.substring(i + 1);

      let s = "";
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.hasBackdrop = false;
      dialogConfig.disableClose = true;
      let gsId: string = "";
      let nextGSId: string = "";
      let depCountryId: string = "";
      if (personLegType == 'Departure') {
        gsId = gsItem.groundStopGUID;
        nextGSId = gsItem.nextGroundStopGUID;
        depCountryId = gsItem.countryGUID;
      }
      else {
        gsId = gsItem.prevGroundStopGUID;
        nextGSId = gsItem.groundStopGUID;
        depCountryId = gsItem.prevCountryGUID;
      }
      let x = 0;
      dialogConfig.data = {
        tripCodeGUID: gsItem.tripCodeGUID, isPassportNotRequired: item.isPassportNotRequired, customerGUID: this.request.customerGUID, personGUID: item.personGUID, groundStopGUID: gsId, nextArrivalGroundStopGUID: nextGSId,
        departureCountryGUID: depCountryId, personType: personType, actionType: 'Edit', v: this._authService.getCurrentTimeNumber()
      };

      const dialogRef = this._dialog.open(TripPersonEditorDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result.refresh == true) {
          this.getTripInfo(gsItem.tripCodeGUID);
          this.updateParentPage.emit({ updateSection: 'groundStop' });
          this.getTripRevisionUsernameListBygIdList('');
        }
      });

      //dialogConfig.data = {
      //  dialogTitle: s, customerGUID: this.request.customerGUID, tripCodeGUID: this.request.tripCodeGUID, groundStopGUID: item.groundStopGUID,
      //  personGUID: item.personGUID, personLegType: personLegType, personType: personType, actionType: 'Edit'

      //};

      //const dialogRef = this._dialog.open(GroundStopPersonEditorDialogComponent, dialogConfig);
      //dialogRef.afterClosed().subscribe(result => {


      //});
    }

  }

  clickPointOfContact(e: any, itemGs: GroundStopModel, itemGst: GroundStopTaskModel) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    dialogConfig.data = {
      vendorId: itemGst.vendorGUID,
      icao: itemGs.icao
    };
    this._dialog.open(VendorDetailsComponent, dialogConfig);

  }

  getGroundStopAltAirports(gsId: string) {
    this._authService.updateAccessTime();

    let obj = new GroundStopModel();
    obj.groundStopGUID = gsId;
    this._groundStopService.getGroundStopAlternateAirportBygsId<ResponseModel<GroundStopAlternateAirportModel[]>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        let rIndex: number = -1;
        rIndex = this.recordList.findIndex(x => x.groundStopGUID.toLowerCase() == obj.groundStopGUID.toLowerCase());

        this.recordList[rIndex].arrivalGroundStopAlternateAirportList = [];
        this.recordList[rIndex].departureGroundStopAlternateAirportList = [];
        this.recordList[rIndex].etopGroundStopAlternateAirportList = [];
        this.recordList[rIndex].etpGroundStopAlternateAirportList = [];
        if (response.model.length > 0) {
          let aList: GroundStopAlternateAirportModel[];
          aList = response.model;

          this.recordList[rIndex].arrivalGroundStopAlternateAirportList = aList.filter(x => x.alternateAirportTypeID == 1);
          this.recordList[rIndex].departureGroundStopAlternateAirportList = aList.filter(x => x.alternateAirportTypeID == 2);
          this.recordList[rIndex].etopGroundStopAlternateAirportList = aList.filter(x => x.alternateAirportTypeID == 3);
          this.recordList[rIndex].etpGroundStopAlternateAirportList = aList.filter(x => x.alternateAirportTypeID == 4);

        }

        //this.showSpin = false;

      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }

      }
      //this.showSpin = false;
    })

  }

  getPointOfConact(gsId: string) {
    this._authService.updateAccessTime();

    let obj = new GroundStopModel();
    obj.groundStopGUID = gsId;
    this._groundStopService.getPointOfContactListBygsId<ResponseModel<GroundStopTaskModel[]>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        let rIndex: number = -1;
        rIndex = this.recordList.findIndex(x => x.groundStopGUID.toLowerCase() == obj.groundStopGUID.toLowerCase());
        this.recordList[rIndex].pointOfContactList = response.model;

        //this.showSpin = false;

      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }

      }
      //this.showSpin = false;
    })

  }

  clickAltICAO(e: any, gs: GroundStopModel, a: GroundStopAlternateAirportModel) {
    //const dialogConfig = new MatDialogConfig();
    //dialogConfig.autoFocus = true;
    ////dialogConfig.disableClose = true;
    //dialogConfig.panelClass = "custom-dialog-container";
    //// dialogConfig.width = "45em";
    //dialogConfig.data = {
    //  icao: a.icao,
    //  icaoIata: a.icaO_IATA,
    //  groundStopGUID: gs.groundStopGUID,
    //  tripCodeGUID: gs.tripCodeGUID,
    //  customerGUID: this.request.customerGUID
    //};
    //this._dialog.open(AirportDetailsComponent, dialogConfig);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";
    let airport = new AirportModel();
    airport.airportID = a.airportID;
    airport.latitude = a.Latitude;
    airport.longitude = a.Longitude;
    airport.icao = a.icao;
    airport.iata = a.iata;
    airport.countryId = a.countryID,
      airport.airportName = a.airportName;
    dialogConfig.data = {
      selectedAirport: airport,
      airportID: a.airportID,
      icao: a.icao,
      customerGUID: this.request.customerGUID,
      farTypeID: gs.departureFARTypeID,
      farType: gs.departureFARTypeDescription,
      route: '',
      handlerGUID: a.vendorGUID,
      ssnGUID: null,
      callFrom: 'trip'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);
  }


  clickICAO(e: any, gs: GroundStopModel) {
    //const dialogConfig = new MatDialogConfig();
    //dialogConfig.autoFocus = true;
    ////dialogConfig.disableClose = true;
    //dialogConfig.panelClass = "custom-dialog-container";
    ////    dialogConfig.maxWidth = "70em";
    //dialogConfig.data = {
    //  icao: gs.icao,
    //  icaoIata: gs.icaO_IATA,
    //  groundStopGUID: gs.groundStopGUID,
    //  tripCodeGUID: gs.tripCodeGUID,
    //  customerGUID: this.request.customerGUID

    //};
    //this._dialog.open(AirportDetailsComponent, dialogConfig);
    let airport = new AirportModel();
    airport.airportID = gs.airportID;
    airport.latitude = gs.latitude;
    airport.longitude = gs.longitude;
    airport.icao = gs.icao;
    airport.iata = gs.iata;
    airport.countryId = gs.countryID,
    airport.airportName = gs.airportName;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";

    dialogConfig.data = {
      selectedAirport: airport,
      airportID: gs.airportID,
      icao: gs.icao,
      customerGUID: this.request.customerGUID,
      farTypeID: gs.departureFARTypeID,
      farType: gs.departureFARTypeDescription,
      route: '',
      handlerGUID: gs.vendorList.find(x => x.vendorRoleDescription == 'Handler')?.vendorGUID,
      ssnGUID: gs.vendorList.find(x => x.vendorRoleDescription == 'SSN')?.vendorGUID,
      callFrom: 'trip'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);
  }

  clickUsername(e: any, gsItem: GroundStopModel, username: string) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: this.request.customerGUID, aircraftGUID: this.request.aircraftGUID, tripCodeGUID: gsItem.tripCodeGUID, groundStopGUID: gsItem.groundStopGUID,
      pastHours: this.request.pastHours,
      userName: username, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  clickTripRevisionRequired(e: any, gsItem: GroundStopModel, gstItem: GroundStopTaskModel) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, groundStopGUID: gsItem.groundStopGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID, isRevisionRequiredValue: gstItem.isRevisionRequiredValue,
      v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TaskRevisionRequiredEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.showSpin = true;
        this.updateParentPage.emit({ updateSection: 'groundStop' });
        this.getTripRevisionUsernameListBygIdList(gsItem.groundStopGUID);
        let scList: ServiceClassModel[];
        scList = gsItem.serviceClassList;
        scList.forEach((x, index) => {
          let gst = new GroundStopTaskModel();
          gst.groundStopGUIDList = gsItem.groundStopGUID;
          gst.serviceClassID = x.serviceClassID;
          this.updateServiceListByServiceClassGroundStopIDList(gst);
          this.showSpin = false;
        });

      }

    });
  }

  //  source: inOrout, selectedLegIndex:this.request.tripLegOrder-1
  // , inOrout: string
  openMessages(e: any, gsItem: GroundStopModel, gstItem: GroundStopTaskModel, serviceLocation: string) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.maxWidth = "92em";//"1000px";
    dialogConfig.width = "89em";//"1000px";
    // dialogConfig.height = "42.5em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      tripCodeGUID: gsItem.tripCodeGUID, groundStopGUID: gsItem.groundStopGUID,
      nextGroundStopGUID: gsItem.nextGroundStopGUID, serviceTypeID: gstItem.serviceTypeID,
      aircraftGUID: this.request.aircraftGUID, customerGUID: this.request.customerGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID, hidePastStop: this.hidePastStop,
      registration: this.request.registration, tripCodeText: this.request.tripCode, serviceLocation: serviceLocation, includeActiveStopOnly: this.includeActiveStopOnly,
      lockdownTrip: this.lockdownTrip
    };

    const dialogRef = this._dialog.open(TripMessageCenterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
