<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-.75em;width:100%" class="d-inline-flex justify-content-between">
  <div class="d-inline-flex">
    <i class="material-icons mr-1">warning</i>
    <h5>
      Advisory Alerts
    </h5>
  </div>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>
<div *ngIf="advisory" style="background-color:white;padding:.5em; max-height: 43em; overflow-y: auto;">
  <h5><a href="{{advisory.weblink}}" target="_blank" >{{advisory.title}}</a></h5>
  <div class="d-inline-flex mb-2">
    <div class="mr-2">
      <span style="font-weight:bold">Rating: </span>{{advisory.advisoryRating}}
    </div>
    <div>
      <span style="font-weight:bold">Type: </span>{{advisory.advisoryType}}
    </div>
  </div>
  <div class="mr-2">
    <span style="font-weight:bold">Effective Dates: </span>{{advisory.effectiveDatesDisplay}}
  </div>
  <div class="d-inline-flex mb-2">
    <div class="mr-2" style="font-weight:bold">
      Area of Interest:
    </div>
    <div>
      <div *ngIf="advisory.icao">{{ advisory.icao }}&nbsp; {{ advisory.iata }}, </div>
      <div *ngIf="advisory.countryName">{{ advisory.countryName }}</div>
      <div *ngIf="advisory.stateProvinceName">{{ advisory.stateProvinceName }}</div>
      <div *ngIf="advisory.groundHandlerVendorName">
        {{ advisory.groundHandlerVendorName}}
      </div>
      <div *ngIf="advisory.ssnVendorName!=null"> {{ advisory.ssnVendorName }}</div>
      <div *ngIf="advisory.fuelSupplierName!=null">
        {{ advisory.fuelSupplierName }}
      </div>
      <div *ngIf="advisory.intoplaneAgent!=null">
        {{ advisory.intoplaneAgent  }}
      </div>
      <div *ngIf="advisory.hotelName!=null"> {{ advisory.hotelName }}</div>
    </div>
  </div>
  <div  style="font-weight:bold">Issue</div>
  <div style="white-space:pre-wrap">
    {{advisory.issue}}
  </div>
  <div class="mt-2" *ngIf="documents!=null && documents.length>0">
    <div style="font-weight:bold">
      Documents
    </div>
    <div *ngFor="let item of documents; let i=index" style="margin-top:-0.25em">
      <ng-container *ngIf="item.isActive">
        <div style="font-size: small; display:flex; padding:0em; height: 2em;">
          <div>
            <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline"
               title="{{item.documentName}}" (click)="previewDocument($event, item)">{{item.documentName}}</a>
          </div>
        </div>
      </ng-container>
    </div>

  </div>
  
        <div  *ngIf="affectedLegs!=null && affectedLegs.length>0" style="margin-top: 0.5em; height: 0.25em; background: #f0f0f0; margin-bottom: .9em; margin: 0.8em -0.5em 0 -0.5em;"></div>

  
  <div class="mt-2" *ngIf="affectedLegs!=null && affectedLegs.length>0">
    <div style="font-weight:bold; margin-top: 1em; color: red;">
      Affected Legs
    </div>
    <div *ngFor="let item of affectedLegs; let i=index" style="margin-top:0.25em; font-size:medium;">
      <div class="font-weight-bold" style="font-size:medium">{{item.customerName}}</div>
      <div class="font-weight-bold ml-1"><a class="link" (click)="openTrip(item)">{{item.tripCode}}</a></div>
      <ng-template ngFor let-obj2 [ngForOf]="item.legDetails?.split(';')">
        <div class="ml-1">
          {{obj2}}
        </div>
      </ng-template>
    </div>
  
  </div>

</div>

