import { AirportModel } from "./airport.model";

export class MapPointsModel {
  latitude: number;
  longitude: number;
  title: string;
  nextTitle: string;
  isMainPoint: boolean;
  airport: AirportModel;
  isPreferred: boolean;
  nextLatitude: number;
  nextLongitude: number;
  icao: string;
  nextICAO: string;
}
