import { AirportDocumentModel } from "./airport-document.model";
import { AirportFrequencyModel } from "./airport-frequency.model";
import { AirportNotesModel } from "./airport-notes.model";
import { VendorModel } from "./vendor.model";

export class AirportModel {
  airportID: number;
  icao: string;
  iata: string;
  airportName: string;
  airportLocation: string;
  airportLocationWithCountryNameAbbr: string;
  searchField: string;
  pageSize: number;
  pageIndex: number;
  totalRecords: number;
  route: string;
  wrongAirportErrMsg: string;
  duplicateIATAErrMsg: string;
  latitude: number;
  longitude: number;
  runwayMaxLength: number;
  elevation: number;
  isO2Letter: string;
  isApisApproved: boolean;
  cityName: string;
  stateProvinceAbbreviation: string;
  faaid: string;
  approachList: string;
  primaryRunway: string;
  runwayMaxWidth: number;
  variation: string;
  lighting: string;
  fuelType: string;
  distanceFromCity: string;
  airportOfEntry: string;
  requiresSlots: string;
  customs: string;
  requiresHandling: string;
  airportType: string;
  utcConversion: string;
  hasDaylightSavings: string;
  hasDaylightSavingsBool: boolean;
  dlsStart: string;
  dlsEnd: string;
  countryId: number;
  countryName: string;
  airportNotes: AirportNotesModel[];
  airportFrequencies: AirportFrequencyModel[];
  airportUTCDateTime: string;
  airportLocalDateTime: string;
  utcOffsetMinutes: number;
  searchKey: string;
  searchType: string;
  latitudeDMS: string;
  longitudeDMS: string;
  countryGUID: string;
  utcTimeConversionDisplay: string;
  selected: boolean;
  documentList: AirportDocumentModel[];
  nearbyAirports: AirportModel[];
  distanceFromAirport: number;
  isPreferred: boolean;
  isPreferredAlternate: boolean;
  requiresSlotsBool: boolean;
  requiresPPR: boolean;
  requiresLandingPermit: boolean;
  farTypeID: number;
  distance: number;
  recordLimit: number;
  cityID: number;
  stateProvinceID: number;
  currentUTCConversion: string;
  remarks: string;
  stateProvinceName: string;
  requiresHandlingBool: boolean;
  hasCustoms: boolean;
  isActive: boolean;
  modifiedBy: string;
  modifiedDate: string;
  hasRestrictions: boolean;
  airportOfEntryID: number;
  hasExpiredRevisions: boolean;
  hasExpiredDocuments: boolean;
  flagFile: string;
  requiresLandingPermitPrivate: boolean = false;
  requiresLandingPermitCharter: boolean = false;  
  requiresRevision: boolean = false;
  advisoryCount: number;
  handler: VendorModel;
  ssn: VendorModel;
  nextAirportID: number;
  nextICAO: string;
  nextAirportName: string;
  nextLatitude: number;
  nextLongitude: number;
  routeCount: number;
  isRequiredSlots: number;
  isRequiredPPR: number;
  countryRequiresLandingPermit: string;
  countryRequiresLandingPermitPrivate: string;
  countryRequiresLandingPermitCharter: string;
  ete: string;
  distinceToNext: number;
  hasClientPreferredVendor: boolean;
  isLoadingDoc: boolean;
  revisionDate: string;
}
