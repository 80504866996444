import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { TripLegModel } from '../models/trip-leg.model';
import { TripRevisionModel } from '../models/trip-revision.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerService } from '../services/customer.service';

export interface TripList {
  tripCode: string;
  legs: TripLegModel[];
}

export interface AlertList {
  categoryName: string;
  alertObject: any;
}

export interface RevisionList {
  registration: string;
  icao: string;
  departureDateTimeUTC: string;
  departureDateTimeLocal: string;
  arrivalDateTimeUTC: string;
  arrivalDateTimeLocal: string;
  revisionList: TripRevisionModel[];
}

@Component({
    selector: 'app-client-alert-dialog',
    templateUrl: './client-alert-dialog.component.html',
    styleUrls: ['./client-alert-dialog.component.css']
})

export class ClientAlertDialogComponent implements OnInit {
  @Input() alertObject: any;
  @Input() alertType: string;
  @Input() manifest: GroundStopManifestModel[];
  @Input() legs: TripLegModel[];
  manifestLegs: TripLegModel[] = [];
  trip: TripList[] = [];
  revisions: RevisionList[] = [];
  showSpin: boolean = false;
  title: string;
  tripCode: string;
  tripLeg: string;
  upPII: boolean = false;
  alertList: AlertList[] = [];
  displayedColumn: string[] = ['transactionDate', 'changeRequestType', 'changeRequest'];

    /** company-event-dialog ctor */
  constructor(private readonly _dialogRef: MatDialogRef<ClientAlertDialogComponent>, private readonly _authService: AuthenticateService, private readonly _formBuilder: UntypedFormBuilder, 
    private readonly _customerService: CustomerService, @Inject(MAT_DIALOG_DATA) private _data: any,) {
    this.alertObject = _data.alertObject;
    this.alertType = _data.alertType;
    this.manifest = _data.manifest;
    this.legs = _data.legs;
  }

  ngOnInit() {
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }
    this.alertObject.sort(function (a, b) {
      if (a.tripCode < b.tripCode) { return -1; }
      if (a.tripCode > b.tripCode) { return 1; }
      return 0
    });
    this.legs.sort(function (a, b) {
      if (a.tripCode < b.tripCode) { return -1; }
      if (a.tripCode > b.tripCode) { return 1; }
      return 0
    });
    switch (this.alertType) {
      case "expiredManifest":
        this.title = "Expired Manifest Documents";
        this.manifest = this.manifest.filter(x => x.expiredDocCount > 0);
        this.legs.forEach(v => {
          if (Array.prototype.map.call(this.manifest, s => s.groundStopGUID).toString().indexOf(v.groundStopGUID) > -1)
            this.manifestLegs.push(v);
        });
        break;
      case "missingManifest":
        this.title = "Missing Manifest Documents";
        this.manifest = this.manifest.filter(x => x.isBellAlert);
        this.legs.forEach(v => {
          if (Array.prototype.map.call(this.manifest, s => s.groundStopGUID).toString().indexOf(v.groundStopGUID) > -1)
            this.manifestLegs.push(v);
        });
        break;
      case "task":
      case "onHold":
        if (this.alertType=='task')
          this.title = "Tasks Waiting for Reply";
        else
          this.title = "On Hold";

        this.legs.forEach(v => {
          if (Array.prototype.map.call(this.alertObject, s => s.groundStopGUID).toString().indexOf(v.groundStopGUID) > -1)
            this.manifestLegs.push(v);
        });
        break;
      case "aircraft":
        this.title = "Expired Aircraft Documents";
        let registration;
        this.alertObject.forEach(v => {
          if (registration != v.registration) {
            registration = v.registration;
            this.alertList.push({ categoryName: registration, alertObject: this.alertObject.filter(x => x.registration == registration) });
          }
        });        
        break;
      case "customer":
        this.title = "Expired Client Documents";
        break;
      case "missingCrew":
      case "missingPax":
        if (this.alertType == "missingCrew")
          this.title = "Legs Missing Crew";
        else
          this.title = "Legs Missing PAX";        
        let tempTripCode: string = "";
        this.alertObject.sort(function (a, b) {
          if (a.tripCode < b.tripCode) { return -1; }
          if (a.tripCode > b.tripCode) { return 1; }
          return 0
        });
        this.alertObject.forEach(x => {          
          if (tempTripCode != x.tripCode) {
            tempTripCode = x.tripCode;
            this.trip.push({ tripCode: tempTripCode, legs: this.alertObject.filter(y=> y.tripCode == tempTripCode) });
          }
        });
        break;
      case "revisions":
        this.title = this.alertObject[0].tripCode + " Audit Trail Details - Past 24 Hours";
        let tempGS: string = "";
        this.alertObject[0].revisions.forEach(x => {
          if (tempGS != x.groundStopGUID) {
            tempGS = x.groundStopGUID;
            this.revisions.push({
              registration: x.registration, icao: x.icao, departureDateTimeUTC: x.departureDateTimeUTC, departureDateTimeLocal: x.departureDateTimeLocal, arrivalDateTimeUTC: x.arrivalDateTimeUTC, arrivalDateTimeLocal: x.arrivalDateTimeLocal,
              revisionList: this.alertObject[0].revisions.filter(y => y.groundStopGUID.toLowerCase() == x.groundStopGUID.toLowerCase())
            });
          }
        });
        break;
    }
    
  }


  openTrip(p: TripLegModel) {
    if (p)
    window.open('/ground-stops-client/trip-details-client?tripId=' + p.tripCodeGUID + '&aircraftId=' + p.aircraftGUID + '&pf=&tType=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }
  
  openAircraft(p: any) {
    window.open('aircraft/aircraft-profile-details?aircraftGuid=' + p.alertObject[0].aircraftGUID + '&customerGuid=&textSearch=&filterBy=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }

  openCustomer() {
    window.open('customers/customer-documentlist?tabName=document&customerGuid=&text_search=&filter_by=DocumentName&includeDisabledRecords=false');
  }
  clickClose() {
    this._dialogRef.close();
  }
}
