import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { GroundStopTypeModel } from '../models/ground-stop-type.model';
import { GroundStopService } from '../services/ground-stop.service';
import { TripModel } from '../models/trip.model';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GroundStopModel } from '../models/ground-stop.model';
//import { GroundStopCreatorDialogComponent } from './ground-stop-creator-dialog.component';
//import { GroundStopEditorDialogComponent } from './ground-stop-editor-dialog.component';
import { GlobalConstant } from '../common-utility/global-constant';
import { GroundStopTaskModel } from '../models/ground-stop-task.model';
import { GroundStopTaskEditDialogComponent } from '../ground-stop-advanced/ground-stop-task-edit-dialog.component';
import { GroundStopServiceTypeSelectionDialogComponent } from '../ground-stop-advanced/ground-stop-service-type-selection-dialog.component';
import { ServiceClassModel } from '../models/service-class.model';
import { GroundStopCrewPaxEditDialogComponent } from '../ground-stop-advanced/ground-stop-crew-pax-edit-dialog.component';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAlternateAirportEditDialogComponent } from '../ground-stop-advanced/ground-stop-alternate-airport-edit-dialog.component';
//import { GroundStopDetailsDialogComponent } from './ground-stop-details-dialog.component';
import { AirportDetailsComponent } from '../airports/airport-details.component';
import { GroundStopAlternateAirportModel } from '../models/ground-stop-alternate-airport.model';
import { FlightFollowingEditDialogComponent } from '../ground-stop-advanced/flight-following-edit-dialog.component';
import { TripRevisionModel } from '../models/trip-revision.model';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';
//import { TripAuditDialogComponent } from './trip-audit-dialog.component';
import { TaskRevisionRequiredEditDialogComponent } from '../ground-stop-advanced/task-revision-required-edit-dialog.component';
import { TripAuditDialogComponent } from '../ground-stops/trip-audit-dialog.component';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { DialogService } from '../services/dialog.service';
import { GroundStopTaskClientDialogComponent } from '../ground-stop-advanced-client/ground-stop-task-client-dialog.component';
import { FlightFollowingClientDialogComponent } from '../ground-stop-advanced-client/flight-following-client-dialog.component';
import { EmailClientDialogComponent } from '../messages-client/email-client-dialog.component';
import { TripMessagesClientComponent } from '../messages-client/trip-messages-client.component';
import { CustomerPersonDetailsComponent } from '../persons/customer-person-details.component';
import { AirportBriefComponent } from '../trip-planner/airport-brief.component';
import { AirportModel } from '../models/airport.model';

@Component({
  selector: 'app-ground-stoplist-client',
  templateUrl: './ground-stoplist-client.component.html',
  styleUrls: ['./ground-stoplist-client.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})

export class GroundStoplistClientComponent implements OnInit, OnChanges {

  @Input() requestInput: TripModel;
  @Input() reloadChildPage: boolean;
  @Input() selectedTabName: string;
  @Input() v: number;
  @Input() refreshChildColumn: string;
  @Input() hidePastStop: boolean;
  @Input() customerGUID: string;
  request: TripModel;
  
  ground_stoplist_client_form: UntypedFormGroup;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = true;
  //selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 15;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;

  recordList: GroundStopModel[];

  @Output() public updateParentPage = new EventEmitter();

  //displayedColumn: string[] = ['airport_itinerary_crew_pax', 'airport_service', 'preflight_service', 'flightplan_service', 'flight_following', 'callLog', 'action'];
  displayedColumn: string[] = ['airport_itinerary_crew_pax_services'];

  scrHeight: number;
  grantAccessToManageManifestProfiles: boolean;
  grantAccessToVendorProfiles: boolean;
  upPII: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _groundStopClientService: GroundStopClientService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _groundStopService: GroundStopService, private readonly _dialogService: DialogService
  ) {

  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //if (!this.reloadChildPage && this.refreshChildColumn == "") {
      
    //  return;
    //}
    if (this.selectedTabName != 'groundStop') {
      return;
    }
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }
    else {
      this.upPII = true;
    }

    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";

    this.grantAccessToManageManifestProfiles = false;
    this.grantAccessToVendorProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    if (localStorage.getItem('up.vPro') == 'true') {
      this.grantAccessToVendorProfiles = true;
    }
    this.request = (JSON.parse(JSON.stringify(this.requestInput)));
    if (!this.reloadChildPage && this.refreshChildColumn == "") {
    }
    else {
      if (this.refreshChildColumn == "") {
        this.showSpin = true;
        this.recordList = [];
        this.totalRecordsText = '';
        this.totalRecords = 0;
        this.currentPageIndex = 0;
        this.totalPageNumber = 0;
        this.scrHeight = window.innerHeight;

        this.pageSize = 0;

        this.request.pageIndex = this.currentPageIndex;
        this.request.pageSize = this.pageSize;

        this.request.totalRecords = this.totalRecords;
        this.initControls();
        this.getSearchResults();
      }
      else {

        //this.getTripRevisionUsernameListBygIdList('');
      }
    }
  }


  initControls() {
    this.ground_stoplist_client_form = this._formBuilder.group({

    })
  }

  get f() { return this.ground_stoplist_client_form.controls; }

  getSearchResults() {
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.totalRecordsText = "";
    this._groundStopClientService.getGroundStopListForClientByConditions<ResponseModel<GroundStopModel[]>>(this.request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)
            //this.updateParentPage.emit({ updateSection: 'groundStop' });
           
          }

        }
        else {
          this.totalRecordsText = "No record found";
        }
        this.showSpin = false;
        
      }
      else {
        if (response.code == "401") {
          if (response.message == "")
            this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  getTripRevisionUsernameListBygIdList(gId: string) {
    if (gId != "") {
      let obj = new TripRevisionModel();
      obj.groundStopGUIDList = gId;
      obj.pastHours = this.request.pastHours;
      this._groundStopService.getTripRevisionUserNameListBygIdListHours<ResponseModel<GroundStopModel[]>>(obj).subscribe(response => {
        if (response != null && response.code == "200") {
          this.recordList.find(x => x.groundStopGUID.toLowerCase() == gId.toLowerCase()).tripRevisionList = [];
          if (response.model.length > 0) {
            let gsList: GroundStopModel[];
            gsList = response.model;
            this.recordList.find(x => x.groundStopGUID.toLowerCase() == gId.toLowerCase()).tripRevisionList = gsList[0].tripRevisionList;
          }
        }
      })

    }
    else {
      let obj = new TripRevisionModel();
      obj.pastHours = this.request.pastHours;
      obj.groundStopGUIDList = Array.prototype.map.call(this.recordList, s => s.groundStopGUID).toString();
      this._groundStopService.getTripRevisionUserNameListBygIdListHours<ResponseModel<GroundStopModel[]>>(obj).subscribe(response => {
        if (response != null && response.code == "200") {
          let gsList: GroundStopModel[];
          gsList = response.model;
          for (var i = 0; i < this.recordList.length; i++) {
            this.recordList[i].tripRevisionList = [];
            for (var j = 0; j < gsList.length; j++) {
              if (this.recordList[i].groundStopGUID.toLowerCase() == gsList[j].groundStopGUID.toLowerCase()) {
                this.recordList[i].tripRevisionList = gsList[j].tripRevisionList;
                break;
              }
            }
          }
        }
      })

    }


  }

  pageIndexChange(e: any) {
    //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
    this.currentPageIndex = e.pageIndex;
    if (this.pageSize != e.pageSize) {
      this.totalRecords = 0;
      this.totalRecordsText = "";
      this.totalPageNumber = 0;
      this.pageSize = e.pageSize;
      this.request.pageSize = this.pageSize;
      this.request.totalRecords = this.totalRecords;
    }
    this.request.pageIndex = this.currentPageIndex;
    this.getSearchResults();

  }
 
  clickServiceType(e: any, gsItem: GroundStopModel, gstItem: GroundStopTaskModel) {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = "54em";
    let dialogHeight = "";
    if (gstItem.serviceTypeID == 1 || gstItem.vendorRequired == true || gstItem.serviceTypeID == 35 || gstItem.serviceTypeID == 3
      || gstItem.serviceTypeID == 7 || gstItem.serviceTypeID == 20 || gstItem.serviceTypeID == 56) {

      dialogConfig.minHeight = "39.2em";
    }
    else {
      dialogConfig.minHeight = "37em";
    }
    dialogConfig.disableClose = true;
    let leg: string = "";
    leg = gsItem.icao;
    if (gsItem.nextICAO != "") {
      leg += "-" + gsItem.nextICAO;
    }

    dialogConfig.data = {
      groundStopGUID: gsItem.groundStopGUID, tripCodeGUID: gsItem.tripCodeGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID,
      serviceTypeID: gstItem.serviceTypeID, vendorReq: gstItem.vendorRequired, countryId: gsItem.countryGUID, icao: gsItem.icao,
      serviceTypeDesc: gstItem.serviceTypeDescription, v: this._authService.getCurrentTimeNumber(),
      customerGUID: this.customerGUID, leg: leg, nextGroundStopGUID: gsItem.nextGroundStopGUID
    };

    if (gstItem.serviceTypeID != 69 && gstItem.serviceTypeID != 70) {
      const dialogRef = this._dialog.open(GroundStopTaskClientDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {

        }
      });
    }
    else {
      const dialogRef = this._dialog.open(FlightFollowingClientDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {

        }
      });
    }
    

  }

  

  clickPersonName(e: any, item: GroundStopManifestModel, gs: GroundStopModel) {
    //let navigationExtras: NavigationExtras = {
    //  queryParams: {
    //    "pId": item.personGUID,
    //    "selectedPersonClassDescription": item.personClassDescription,
    //    "selectedPersonClassGUID": item.personClassGUID,
    //    v: this._authService.getCurrentTimeNumber()
    //  }
    //};
    //this._router.navigate(['/persons/customer-person-details'], navigationExtras);
    if (item.capacityID !=1) {
      return;
    }
    if (this.grantAccessToManageManifestProfiles) {
      //window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID + '&selectedPersonClassDescription=' + item.personClassDescription + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
   //   window.open('/persons/customer-person-details?pId=' + item.personGUID + '&selectedPersonClassGUID=' + item.personClassGUID + '&tripCodeGUID=' + gs.tripCodeGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.panelClass = "custom-dialog-container";
      dialogConfig.maxHeight = "95vh";
      dialogConfig.hasBackdrop = false;
      dialogConfig.data = { personGUID: item.personGUID, customerGUID: this.customerGUID, selectedPersonClassGUID: item.personClassGUID, selectedPersonClassDescription: item.personClassDescription };

      const dialogRef = this._dialog.open(CustomerPersonDetailsComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
         // this.getAllData();
        }

      });
    }
    else
      return;
  }

  clickAltICAO(e: any, gs: GroundStopModel, a: GroundStopAlternateAirportModel) {
    //const dialogConfig = new MatDialogConfig();
    //dialogConfig.autoFocus = true;
    ////dialogConfig.disableClose = true;
    //dialogConfig.panelClass = "custom-dialog-container";
    //// dialogConfig.width = "45em";
    //dialogConfig.data = {
    //  icao: a.icao,
    //  icaoIata: a.icaO_IATA,
    //  groundStopGUID: gs.groundStopGUID,
    //  tripCodeGUID: gs.tripCodeGUID
    //};
    //this._dialog.open(AirportDetailsComponent, dialogConfig);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";
    let airport = new AirportModel();
    airport.airportID = a.airportID;
    airport.latitude = a.Latitude;
    airport.longitude = a.Longitude;
    airport.icao = a.icao;
    airport.iata = a.iata;
    airport.countryId = a.countryID,
      airport.airportName = a.airportName;
    dialogConfig.data = {
      selectedAirport: airport,
      airportID: a.airportID,
      icao: a.icao,
      customerGUID: this.customerGUID,
      farTypeID: gs.departureFARTypeID,
      farType: gs.departureFARTypeDescription,
      route: '',
      handlerGUID: a.vendorGUID,
      ssnGUID: null,
      callFrom: 'trip'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);
  }


  clickICAO(e: any, gs: GroundStopModel) {
    //const dialogConfig = new MatDialogConfig();
    //dialogConfig.autoFocus = true;
    ////dialogConfig.disableClose = true;
    //dialogConfig.panelClass = "custom-dialog-container";
    ////    dialogConfig.maxWidth = "70em";
    //dialogConfig.data = {
    //  icao: gs.icao,
    //  icaoIata: gs.icaO_IATA,
    //  groundStopGUID: gs.groundStopGUID,
    //  tripCodeGUID: gs.tripCodeGUID

    //};
    //this._dialog.open(AirportDetailsComponent, dialogConfig);
    let airport = new AirportModel();
    airport.airportID = gs.airportID;
    airport.latitude = gs.latitude;
    airport.longitude = gs.longitude;
    airport.icao = gs.icao;
    airport.iata = gs.iata;
    airport.airportName = gs.airportName;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;
    dialogConfig.panelClass = "custom-dialog-container";
    dialogConfig.width = "80em";

    dialogConfig.data = {
      selectedAirport: airport,
      airportID: gs.airportID,
      icao: gs.icao,
      customerGUID: this.customerGUID,
      farTypeID: gs.departureFARTypeID,
      farType: gs.departureFARTypeDescription,
      route: '',
      handlerGUID: gs.vendorList.find(x=>x.vendorRoleDescription=='Handler').vendorGUID,
      ssnGUID: gs.vendorList.find(x => x.vendorRoleDescription == 'SSN').vendorGUID,
      callFrom: 'trip'
    };

    this._dialog.open(AirportBriefComponent, dialogConfig);
  }

  clickUsername(e: any, gsItem: GroundStopModel, username: string) {
    let s = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = {
      dialogTitle: s, customerGUID: this.request.customerGUID, aircraftGUID: this.request.aircraftGUID, tripCodeGUID: gsItem.tripCodeGUID, groundStopGUID: gsItem.groundStopGUID,
      pastHours: this.request.pastHours,
      userName: username, v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(TripAuditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {


    });

  }

  openMessages(e: any, gsItem: GroundStopModel, gstItem: GroundStopTaskModel, serviceLocation: string) {
      let s = "";
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      //dialogConfig.width = "65em";//"1000px";
      //dialogConfig.height = "28em";//"500px";
      dialogConfig.disableClose = true;
      dialogConfig.hasBackdrop = false;
      dialogConfig.panelClass = "custom-dialog-container"
      dialogConfig.data = {
        dialogTitle: s, tripCodeGUID: gsItem.tripCodeGUID, tripCode: gsItem.tripCode, registration:gsItem.registration, groundStopGUID: gsItem.groundStopGUID, groundStopTaskGUID: gstItem.groundStopTaskGUID, serviceTypeDescription: gstItem.serviceTypeDescription
      };

      const dialogRef = this._dialog.open(TripMessagesClientComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {


      });

  }


}
