import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { TripModel } from '../models/trip.model';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { TripLegModel } from '../models/trip-leg.model';
import { TripLegService } from '../services/trip-leg.service';
import { GroundStopManifestModel } from '../models/ground-stop-manifest.model';
import { GroundStopAdvancedService } from '../services/ground-stop-advanced.service';
import { GroundStopManifestDocumentModel } from '../models/ground-stop-manifest-document.model';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { PersonTravelDocSelectionDialogComponent } from '../ground-stop-advanced/person-travel-doc-selection-dialog.component';
import { ReturnObjModel } from '../models/return-obj.model';
import { CustomerPersonDetailsComponent } from '../persons/customer-person-details.component';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-trip-person-editor-dialog',
  templateUrl: './trip-person-editor-dialog.component.html',
  styleUrls: ['./trip-person-editor-dialog.component.css'],

})

export class TripPersonEditorDialogComponent implements OnInit, AfterViewInit {

  dialogTitle: string;
  customerGUID: string;
  tripCodeGUID: string;
  personGUID: string;
  groundStopGUID: string;
  nextArrivalGroundStopGUID: string;
  personType: string;
  departureCountryGUID: string;
  actionType: string;
  //alreadyHaveCrewOnTrip: boolean;
  //alreadyHavePaxOnTrip: boolean;
  trip_person_editor_form: UntypedFormGroup;
  loading = false;

  submitted = false;
  errMsg: string;
  errMsg2: string;
  msg: string;

  showSpin: boolean = false;

  isModified: boolean = false;

  showSuccessMsg: boolean = false;

  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;

  dataChanged: boolean;


  personName: string = "";
  leg: string = "";
  sameDocumentForDepArr: boolean = true;
  addToAllLeg: boolean = false;
  isPassportNotRequired: boolean = false;
  depDocumentList: GroundStopManifestDocumentModel[] = [];
  arrDocumentList: GroundStopManifestDocumentModel[] = [];
  returnObj = new ReturnObjModel();
  lockdownTrip: boolean;
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";
  tripLeg: TripLegModel;
  person: GroundStopManifestModel;
  capacityID: number = 0;
  legOrder: number;
  arrivalDateTimePassed: boolean = false;
  upPII: boolean = false;

  manifestDetails: GroundStopManifestModel;
  dob: string;
  totalTravelDocList: GroundStopManifestDocumentModel[] = [];
  newTooltip: string = '';
  showButton: boolean = true;

  depPassportList: GroundStopManifestDocumentModel[] = [];
  arrPassportList: GroundStopManifestDocumentModel[] = [];
  totalPassportList: GroundStopManifestDocumentModel[] = [];

  depPnPLList: GroundStopManifestDocumentModel[] = [];
  arrPnPLList: GroundStopManifestDocumentModel[] = [];
  totalPnPLList: GroundStopManifestDocumentModel[] = [];

  depPilotList: GroundStopManifestDocumentModel[] = [];
  arrPilotList: GroundStopManifestDocumentModel[] = [];
  totalPilotList: GroundStopManifestDocumentModel[] = [];
  isRefresh: boolean = false;
  nextArrivalIcao: string = '';
  departureIcao: string = '';
  departureIata: string = '';
  nextArrivalIata: string = '';

  

  constructor(private readonly _dialogRef: MatDialogRef<TripPersonEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _groundStopAdvancedService: GroundStopAdvancedService, private readonly _dialogService: DialogService,
    private readonly _tripLegService: TripLegService, private readonly _dialog: MatDialog, private datePipe: DatePipe
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }
    this.tripCodeGUID = _data.tripCodeGUID;
    //this.tripLeg = _data.tripLeg;
    //this.person = _data.p;
    this.personGUID = _data.personGUID;
    this.groundStopGUID = _data.groundStopGUID;
    this.nextArrivalGroundStopGUID = _data.nextArrivalGroundStopGUID;
    this.personType = _data.personType;
    this.departureCountryGUID = _data.departureCountryGUID;
    this.actionType = _data.actionType;


    // if (_data.isPassportNotRequired != undefined && _data.isPassportNotRequired != null) {
    //   this.isPassportNotRequired = _data.isPassportNotRequired;
    // }

    //this.alreadyHaveCrewOnTrip = _data.alreadyHaveCrewOnTrip;
    //this.alreadyHavePaxOnTrip = _data.alreadyHavePaxOnTrip;

  }

  getAllData(): Observable<any[]> {
    let reqTrip = new TripModel();
    reqTrip.tripCodeGUID = this.tripCodeGUID;
    reqTrip.customerGUID = this.customerGUID;
    reqTrip.personGUID = this.personGUID;
    reqTrip.personRole = this.personType;
    let getActiveLegResponse = this._tripLegService.getActiveTripLegListForPersonEditBytId(reqTrip);
    let getPersonLegResponse = this._tripLegService.getTripLegPersonListBytId(reqTrip);
    let request = new TripLegModel();
    //request.groundStopGUID = this.tripLeg.groundStopGUID;
    //request.nextArrivalGroundStopGUID = this.tripLeg.nextArrivalGroundStopGUID;
    //request.personGUID = this.person.personGUID
    request.groundStopGUID = this.groundStopGUID;
    request.nextArrivalGroundStopGUID = this.nextArrivalGroundStopGUID;
    request.personGUID = this.personGUID
    request.customerGUID = this.customerGUID;
    request.personType = this.personType;
    let getPersonLegDocResponse;
    if (this.actionType == 'Edit') {
      getPersonLegDocResponse = this._tripLegService.getPersonDocumentForLegBygsIdpId(request);
    }
    else {
      getPersonLegDocResponse = of(null);
    }
    let getPersonLegDefaultDocResponse;
    if (this.actionType == 'Add') {
      let requestDoc = new GroundStopManifestModel();
      requestDoc.groundStopCountryGUID = this.departureCountryGUID;
      requestDoc.personType = this.personType;
      requestDoc.personGUID = this.personGUID
      requestDoc.customerGUID = this.customerGUID;
      getPersonLegDefaultDocResponse = this._groundStopAdvancedService.getCustomerPersonDefaultTravelDocumentBypId(requestDoc);



    }
    else {
      getPersonLegDefaultDocResponse = of(null);
    }

    let request4 = new GroundStopManifestModel();
    request4.personGUID = this.personGUID;
    request4.groundStopGUID = this.groundStopGUID;
    request4.nextArrivalGroundStopGUID = this.nextArrivalGroundStopGUID;

    let getPersonDetailsResponse = this._tripLegService.getManifestDetails<ResponseModel<GroundStopManifestModel>>(request4);

    let request5 = new GroundStopManifestDocumentModel();
    request5.personGUID = this.personGUID;
    request5.selectedPersonTravelDocumentGUIDList = ''; // this.selectedPersonTravelDocumentGUIDList;
    let getPersonDoc = this._groundStopAdvancedService.getCustomerPersonTravelDocumentBypId<ResponseModel<GroundStopManifestDocumentModel[]>>(request5);


    return forkJoin([getActiveLegResponse, getPersonLegResponse, getPersonLegDocResponse, getPersonLegDefaultDocResponse, getPersonDetailsResponse]);
  }

  ngOnInit() {

    this.showSpin = false;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.errMsg2 = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;
    }
    else {
      this.upPII = true;
    }
    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.msg = "";

    this.showSuccessMsg = false;

    // this.getData();

    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].model != null) {
          let legList: TripLegModel[] = [];
          legList = responses[0].model;
          this.tripLeg = legList.find(x => x.groundStopGUID.toLowerCase() == this.groundStopGUID.toLowerCase() && x.nextArrivalGroundStopGUID.toLowerCase() == this.nextArrivalGroundStopGUID.toLowerCase());
          this.lockdownTrip = this.tripLeg.lockdownTrip;
          this.arrivalDateTimePassed = this.tripLeg.nextArrivalDateTimePassed;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].model != null) {
          this.person = responses[1].model[0];
          this.capacityID = this.person.capacityID;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].model != null) {
          let doclist: GroundStopManifestDocumentModel[];
          doclist = responses[2].model;
          if (doclist.length > 0) {
            this.depDocumentList = doclist.filter(x => x.legType == 'Departure');
            this.arrDocumentList = doclist.filter(x => x.legType == 'Arrival');
          }
          //if (this.arrDocumentList.length == 0) {
          //  this.sameDocumentForDepArr = true;
          //}
          //else {
          let hasdiffdoc: boolean = false;
          if (this.depDocumentList.length == this.arrDocumentList.length) {
            this.arrDocumentList.forEach(x => {
              if (this.depDocumentList.findIndex(y => y.personTravelDocumentGUID.toLowerCase() == x.personTravelDocumentGUID.toLowerCase()) == -1) {
                hasdiffdoc = true;
              }
            });
            this.sameDocumentForDepArr = !hasdiffdoc;
          }
          else {
            this.sameDocumentForDepArr = false;
          }

          /*}*/
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null) {
        if (responses[3].code == "200" && responses[3].model != null) {
          this.depDocumentList = responses[3].model;
          if (this.depDocumentList.length > 0) {
            this.sameDocumentForDepArr = true;
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[4] != null) {
        if (responses[4].code == "200" && responses[4].model != null) {
          this.manifestDetails = responses[4].model;

          this.isPassportNotRequired = this.manifestDetails.isPassportNotRequired;
          this.departureIcao = this.manifestDetails.departureIcao;
          this.departureIata = this.manifestDetails.departureIata;
          this.nextArrivalIcao = this.manifestDetails.nextArrivalIcao;
          this.nextArrivalIata = this.manifestDetails.nextArrivalIata;


        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      // if (responses[5] != null) {
      //   if (responses[5].code == "200" && responses[5].message == "") {
      //     this.totalTravelDocList = responses[5].model;

      //     this.totalTravelDocList = this.totalTravelDocList.filter(x => x.documentTypeDescription == 'Passport');


      //     // this.totalTravelDocList.forEach(row => {
      //     //   row.disabled = false;
      //     //   if (row.selected) {
      //     //     this.selection.select(row);
      //     //   }
      //     // });
      //   }
      //   else {
      //     if (responses[5].code == "401") {
      //       this._authService.signOut();
      //     }
      //   }
      // }

      if (this.actionType == "Add") {
        this.dataChanged = true;
      }
      this.personType = this.person.personType;
      this.personName = this.person.personName;

      this.leg = "LEG " + this.tripLeg.tripLegOrder.toString() + " " + this.tripLeg.leg_ICAOs;
      this.legOrder = this.tripLeg.tripLegOrder;

      this.initControls();

    });



  }

  initControls() {

    this.trip_person_editor_form = this._formBuilder.group({
      sameDocumentForDepArr: [this.sameDocumentForDepArr],
      addToAllLeg: [false],
      // isPassportNotRequired: [false],
      isPassportNotRequired: [this.isPassportNotRequired],



      ////removeFromCurrentLeg:[true],
      //removeFromAllActiveLeg: [false],
      //removeFromTrip: [false],      
    });
  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //  this.routeRef.nativeElement.focus();
    //}, 500);
  }

  get f() { return this.trip_person_editor_form.controls; }

  clickSelectDocument(legType: string) {
    let s = "";
    this.showButton = false;
    1
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "45em";//"1000px";
    dialogConfig.height = "28em";//"500px";
    dialogConfig.disableClose = true;
    let sdoc = "";

    if (legType == 'Departure') {
      if (this.f.isPassportNotRequired.value) {
        let filteredDepDocList = this.depDocumentList.filter(x => x.documentTypeDescription != 'Passport')
        if (filteredDepDocList.length > 0) {
          sdoc = Array.prototype.map.call(filteredDepDocList, s => s.personTravelDocumentGUID).toString();
        }
      }
      else {
        if (this.depDocumentList.length > 0) {
          sdoc = Array.prototype.map.call(this.depDocumentList, s => s.personTravelDocumentGUID).toString();
        }
      }
      // if (this.depDocumentList.length > 0) {
      //   sdoc = Array.prototype.map.call(this.depDocumentList, s => s.personTravelDocumentGUID).toString();
      // }
    }

    if (legType == 'Arrival') {
      if (this.f.isPassportNotRequired.value) {
        let filteredArrDocList = this.arrDocumentList.filter(x => x.documentTypeDescription != 'Passport')
        if (filteredArrDocList.length > 0) {
          sdoc = Array.prototype.map.call(filteredArrDocList, s => s.personTravelDocumentGUID).toString();
        }
      }
      else {
        if (this.depDocumentList.length > 0) {
          sdoc = Array.prototype.map.call(this.arrDocumentList, s => s.personTravelDocumentGUID).toString();
        }
      }
      // if (this.depDocumentList.length > 0) {
      //   sdoc = Array.prototype.map.call(this.arrDocumentList, s => s.personTravelDocumentGUID).toString();
      // }
    }

    dialogConfig.data = {
      dialogTitle: s, personGUID: this.person.personGUID, isPassportNotRequired: this.f.isPassportNotRequired.value, personName: this.person.personName, personType: this.person.personType, selectedPersonTravelDocumentGUIDList: sdoc,
      v: this._authService.getCurrentTimeNumber()
    };

    const dialogRef = this._dialog.open(PersonTravelDocSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.dataChanged = true;
        if (legType == 'Departure') {
          this.depDocumentList = [];
          this.depDocumentList = result.groundStopManifestDocumentList;
        }
        if (legType == 'Arrival') {
          this.arrDocumentList = [];
          this.arrDocumentList = result.groundStopManifestDocumentList;
        }

      }
    });

    setTimeout(() => {
      this.showButton = true;

    }, 2000);
  }

  sameDocumentForDepArrChange(e: any) {
    this.dataChanged = true;
    this.sameDocumentForDepArr = e.checked;
    if (this.sameDocumentForDepArr) {
      this.arrDocumentList = [];
    }
    else {
      this.getCustomerPersonDefaultDocument();
    }


    if (this.f.isPassportNotRequired.value) {
      this.arrDocumentList = this.arrDocumentList.filter(x => x.documentTypeDescription != 'Passport')
      this.depDocumentList = this.depDocumentList.filter(x => x.documentTypeDescription != 'Passport')

    }
  }

  addToAllLegChange(e: any) {
    this.dataChanged = true;
    this.addToAllLeg = e.checked;
  }

  isPassportNotRequiredChange(e: any) {
    this.dataChanged = true;
    this.isPassportNotRequired = e.checked;



    if (this.f.isPassportNotRequired.value) {
      this.arrDocumentList = this.arrDocumentList.filter(x => x.documentTypeDescription != 'Passport')
      this.depDocumentList = this.depDocumentList.filter(x => x.documentTypeDescription != 'Passport')

    }

  }

  //removeFromCurrentLegChange(e:any) {
  //  this.dataChanged = true;
  //  //this.removeFromCurrentLeg= e.checked;
  //}

  removeFromAllActiveLegChange(e: any) {
    this.errMsg = "";
    this.dataChanged = true;
    //this.removeFromAllActiveLeg = e.checked;
  }

  removeFromTripChange(e: any) {
    this.errMsg = "";
    this.dataChanged = true;
    //this.removeFromTrip = e.checked;
  }

  getCustomerPersonDefaultDocument() {
    let requestDoc = new GroundStopManifestModel();
    requestDoc.personType = this.person.personType;
    requestDoc.personGUID = this.person.personGUID
    requestDoc.customerGUID = this.customerGUID;
    if (this.sameDocumentForDepArr) {
      requestDoc.groundStopCountryGUID = this.tripLeg.departureCountryGUID;
    }
    else {
      requestDoc.groundStopCountryGUID = this.tripLeg.nextArrivalCountryGUID;
    }
    this._groundStopAdvancedService.getCustomerPersonDefaultTravelDocumentBypId<ResponseModel<GroundStopManifestDocumentModel[]>>(requestDoc).subscribe(response => {
      if (response != null && response.code == "200") {

        this.arrDocumentList = [];
        this.arrDocumentList = response.model;


        if (this.f.isPassportNotRequired.value) {
          this.arrDocumentList = this.arrDocumentList.filter(x => x.documentTypeDescription != 'Passport')
          this.depDocumentList = this.depDocumentList.filter(x => x.documentTypeDescription != 'Passport')

        }
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  clickSave(actionName: string) {
    this._authService.updateAccessTime();
    this.submitted = true;
    if (this.loading == true) {
      return;
    }

    if (this.actionType != 'RemoveOption' && this.actionType != 'Remove') {
      let applyCrewToAllStops: boolean;
      applyCrewToAllStops = false;
      let applyPaxToAllStops: boolean;
      applyPaxToAllStops = false;
      if (this.f.addToAllLeg.value == true) {
        applyCrewToAllStops = true;
        applyPaxToAllStops = true;
      }

      //let selPerson: GroundStopManifestModel;

      let gsm: GroundStopManifestModel;
      gsm = new GroundStopManifestModel();
      gsm.personGUID = this.person.personGUID;
      gsm.personName = this.person.personName;
      gsm.groundStopManifestTypeDescription = this.person.personType;
      gsm.personType = this.person.personType;
      gsm.groundStopGUID = this.tripLeg.groundStopGUID;
      gsm.applyCrewToAllGroundStops = applyCrewToAllStops;
      gsm.applyPaxToAllGroundStops = applyPaxToAllStops;
      gsm.customerGUID = this.customerGUID;
      gsm.tripCodeGUID = this.tripCodeGUID;
      gsm.groundStopManifestDocumentList = this.depDocumentList;
      gsm.nextArrivalGroundStopGUID = this.tripLeg.nextArrivalGroundStopGUID;



      gsm.personDocumentTooltip = this.generateTootip();


      gsm.isMultiplePassports = false;


      if (this.sameDocumentForDepArr) {
        gsm.nextArrivalGroundStopManifestDocumentList = this.depDocumentList;

        this.arrPassportList = this.depDocumentList.filter(x => x.documentTypeDescription == 'Passport');
        this.depPassportList = this.arrPassportList;


        this.depPilotList = this.depDocumentList.filter(x => x.documentTypeDescription == 'Pilot License');
        this.arrPilotList = this.depPilotList;

        this.depPnPLList = this.depDocumentList.filter(x => x.documentTypeDescription == 'Passport' || x.documentTypeDescription == 'Pilot License');
        this.arrPnPLList = this.depPnPLList;

        // this.depPnPLList = this.depDocumentList.filter(x => x.documentTypeDescription == 'Passport' || x.documentTypeDescription == 'Pilot License');

        if (this.depPassportList?.length > 1) {
          gsm.isMultiplePassports = true;
          gsm.isMultiplePassportsType = 'both';

        }
      }
      else {
        gsm.nextArrivalGroundStopManifestDocumentList = this.arrDocumentList;

        this.arrPassportList = this.arrDocumentList.filter(x => x.documentTypeDescription == 'Passport');
        this.depPassportList = this.depDocumentList.filter(x => x.documentTypeDescription == 'Passport');

        this.depPilotList = this.depDocumentList.filter(x => x.documentTypeDescription == 'Pilot License');
        this.arrPilotList = this.arrDocumentList.filter(x => x.documentTypeDescription == 'Pilot License');

        this.depPnPLList = this.depDocumentList.filter(x => x.documentTypeDescription == 'Passport' || x.documentTypeDescription == 'Pilot License');
        this.arrPnPLList = this.arrDocumentList.filter(x => x.documentTypeDescription == 'Passport' || x.documentTypeDescription == 'Pilot License');


        if (this.depPassportList?.length > 1 || this.arrPassportList?.length > 1 || (this.arrPassportList?.length > 0 && this.depPassportList?.length > 0)) {
          gsm.isMultiplePassports = true;

        }
      }

      // this.totalPassportList = this.depPassportList;
      // this.totalPassportList = this.totalPassportList?.concat(this.arrPassportList);

      // this.totalPilotList = this.depPilotList;
      // this.totalPilotList = this.totalPilotList?.concat(this.arrPilotList);

      // this.totalPnPLList = this.depPnPLList;
      // this.totalPnPLList = this.totalPnPLList?.concat(this.arrPnPLList);



      const currentDate = new Date();
      let departureDate = new Date();
      // if (this.manifestDetails.departureDateUTC != null)
      //   departureDate = new Date(this.manifestDetails.departureDateUTC);


      if (this.manifestDetails.departureDateUTC != null)
        departureDate = new Date(moment.utc(this.manifestDetails.departureDateUTC).format('YYYY-MM-DD HH:mm:ss'));
      else
        departureDate = new Date();//.format('YYYY-MM-DD HH:mm:ss'));

      // console.log(departureDate); // Outputs formatted UTC time

      let totalDocList = this.depPassportList;
      gsm.hasExpiredPassport = false;


      if (this.personType == 'PIC') {
        totalDocList = this.depPnPLList;
        totalDocList = totalDocList?.concat(this.arrPnPLList);


        if (this.f.isPassportNotRequired.value) {

          gsm.hasExpiredPassport = totalDocList?.length == 0 ||  this.depPilotList?.length == 0 || this.arrPilotList?.length == 0 ||
            totalDocList?.some(passport => {
              const documentDOE = new Date(passport.documentDOE);
              return documentDOE < departureDate
            });
        }
        else {
          gsm.hasExpiredPassport = totalDocList?.length == 0 || this.depPassportList?.length == 0 || this.arrPassportList?.length == 0 || this.depPilotList?.length == 0 || this.arrPilotList?.length == 0 ||
            totalDocList?.some(passport => {
              const documentDOE = new Date(passport.documentDOE);
              return documentDOE < departureDate
            });
        }


      }
      else {
        totalDocList = this.depPassportList;
        totalDocList = totalDocList.concat(this.arrPassportList);


        if (!this.f.isPassportNotRequired.value) {

          gsm.hasExpiredPassport = totalDocList?.length == 0 || this.depPassportList?.length == 0 || this.arrPassportList?.length == 0 || totalDocList?.some(passport => {
            const documentDOE = new Date(passport.documentDOE);
            return documentDOE < departureDate
          });
        }

      }

      let isPilotLicensePresent = true;
      let isPassportRequired = !this.f.isPassportNotRequired.value;

      if (this.personType == 'PIC') {
        const depPilotList = this.depPnPLList.some(x => x.documentTypeDescription === 'Pilot License');
        const arrPilotList = this.arrPnPLList.some(x => x.documentTypeDescription === 'Pilot License');
        isPilotLicensePresent = depPilotList || arrPilotList;
      }

      let isPassportListEmpty = this.arrPassportList?.length === 0 && this.depPassportList?.length === 0;
      gsm.isBellAlert = (!isPilotLicensePresent || (isPassportRequired && isPassportListEmpty));

      if (this.f.isPassportNotRequired.value == false && gsm.hasExpiredPassport == true) {
        isPassportListEmpty = true;
        // gsm.isBellAlert = true;

        if (this.personType == 'PIC') {
          isPilotLicensePresent = false;
        }
      }

      gsm.isBellAlertDescription = null;
      gsm.fromEdit = true;

      if (gsm.isBellAlert || (this.f.isPassportNotRequired.value == false && gsm.hasExpiredPassport == true)) {
        if (this.f.isPassportNotRequired.value == true) {
          if (!isPilotLicensePresent)
            gsm.isBellAlertDescription = 'Missing Pilot License';
        }
        else {
          if (isPassportListEmpty && !isPilotLicensePresent)
            gsm.isBellAlertDescription = 'Missing Passport or Pilot License';
          if (isPassportListEmpty && isPilotLicensePresent)
            gsm.isBellAlertDescription = 'Missing Passport';
          if (!isPassportListEmpty && !isPilotLicensePresent)
            gsm.isBellAlertDescription = 'Missing Pilot License';
        }
      }


      gsm.tripLegList = this.person.tripLegList;
      gsm.isTempTrip = false;
      gsm.isPassportNotRequired = this.f.isPassportNotRequired.value;


      if (gsm.applyCrewToAllGroundStops || gsm.applyPaxToAllGroundStops) {
        gsm.fromEdit = false;
      }
      this.loading = true;
      this._tripLegService.saveGroundStopManifestAndDocumentForLeg<ResponseModel<boolean>>(gsm).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model) {
            this.isModified = true;
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
            }, 1000);
            this.returnObj.refresh = this.isModified;
            this.returnObj.actionName = "";
            this._dialogRef.close(this.returnObj);
          }
          else {
            //
            //this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        this.loading = false;
      })

    }
    else {
      //let selPerson: GroundStopManifestModel;

      let gsm: GroundStopManifestModel;
      gsm = new GroundStopManifestModel();
      gsm.personGUID = this.person.personGUID;
      gsm.personName = this.personName;
      gsm.groundStopManifestTypeDescription = this.person.personType;
      gsm.personType = this.person.personType;
      gsm.groundStopGUID = this.tripLeg.groundStopGUID;
      gsm.nextArrivalGroundStopGUID = this.tripLeg.nextArrivalGroundStopGUID;
      gsm.customerGUID = this.customerGUID;
      gsm.tripCodeGUID = this.tripCodeGUID;
      gsm.removeFromAllActiveLeg = false;
      gsm.removeFromTrip = false;
      if (this.actionType == 'RemoveOption') {
        gsm.removeFromAllActiveLeg = true; //this.f.removeFromAllActiveLeg.value;
        gsm.removeFromTrip = false; // this.f.removeFromTrip.value;
      }
      gsm.isTempTrip = false;
      this.loading = true;
      this._tripLegService.removeGroundStopManifestForLeg<ResponseModel<boolean>>(gsm).subscribe(response => {
        if (response != null && response.code == "200") {
          if (response.model) {
            this.isModified = true;
            this.showSuccessMsg = true;
            setTimeout(() => {
              this.showSuccessMsg = false;
            }, 1000);
            this.returnObj.refresh = this.isModified;

            if ((gsm.removeFromAllActiveLeg && this.legOrder == 1) || gsm.removeFromTrip) {
              this.returnObj.actionName = "remove";
            }
            else {
              this.returnObj.actionName = "";
            }

            this._dialogRef.close(this.returnObj);
          }
          else {
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        else {
          if (response.code == "401") {
            //this.errMsg = response.message
            this._authService.signOut();
          }
          else {
            this.errMsg = "Failed to save the record. Please try again later.";
          }
        }
        this.loading = false;
      })

      //let s: string = "";    

      //if (actionName == '') {
      //  if (this.f.removeFromAllActiveLeg.value == false && this.f.removeFromTrip.value == false) {
      //      this.errMsg = "Please select at least one removing type.";
      //      return;
      //  }
      //}
      //if (actionName == 'Remove') {
      //  s = "Are you sure that you want to remove the person from the current leg?";
      //}
      //else {
      //  if (this.f.removeFromTrip.value == true) {
      //    s = "Are you sure that you want to remove the person from whole trip?";
      //  }
      //  else {
      //    if (this.f.removeFromAllActiveLeg.value == true) {
      //      s = "Are you sure that you want to remove the person from current leg and all next active legs?";
      //    }
      //  }
      //}

      //this.confirmDialogRef = this._dialogService.openConfirmDialog(s);
      //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      //  this.eventSubscription.unsubscribe();
      //  if (result) {
      //    let selPerson: GroundStopManifestModel;

      //    let gsm: GroundStopManifestModel;
      //    gsm = new GroundStopManifestModel();
      //    gsm.personGUID = this.person.personGUID;
      //    gsm.personName = this.personName;
      //    gsm.groundStopManifestTypeDescription = this.person.personType;
      //    gsm.personType = this.person.personType;
      //    gsm.groundStopGUID = this.tripLeg.groundStopGUID;
      //    gsm.nextArrivalGroundStopGUID = this.tripLeg.nextArrivalGroundStopGUID;
      //    gsm.customerGUID = this.customerGUID;
      //    gsm.tripCodeGUID = this.tripCodeGUID;
      //    gsm.removeFromAllActiveLeg = false;
      //    gsm.removeFromTrip = false;
      //    if (this.actionType == 'RemoveOption') {
      //      gsm.removeFromAllActiveLeg = this.f.removeFromAllActiveLeg.value;
      //      gsm.removeFromTrip = this.f.removeFromTrip.value;
      //    }
      //    gsm.isTempTrip = false;
      //    this.loading = true;
      //    this._tripLegService.removeGroundStopManifestForLeg<ResponseModel<boolean>>(gsm).subscribe(response => {
      //      if (response != null && response.code == "200") {
      //        if (response.model) {
      //          this.isModified = true;
      //          this.showSuccessMsg = true;
      //          setTimeout(() => {
      //            this.showSuccessMsg = false;
      //          }, 1000);
      //          this.returnObj.refresh = this.isModified;

      //          if ((gsm.removeFromAllActiveLeg && this.legOrder == 1) || gsm.removeFromTrip) {
      //            this.returnObj.actionName = "remove";
      //          }
      //          else {
      //            this.returnObj.actionName = "";
      //          }

      //          this._dialogRef.close(this.returnObj);
      //        }
      //        else {
      //          this.errMsg = "Failed to save the record. Please try again later.";
      //        }
      //      }
      //      else {
      //        if (response.code == "401") {
      //          //this.errMsg = response.message
      //          this._authService.signOut();
      //        }
      //        else {
      //          this.errMsg = "Failed to save the record. Please try again later.";
      //        }
      //      }
      //      this.loading = false;
      //    })


      //  }
      //  else {
      //    if (actionName == 'Remove') {
      //      this.actionType = 'Edit';
      //    }
      //  }
      //});


    }

  }

  clickRemovePerson() {
    this.actionType = 'Remove';
    this.clickSave('Remove');
    //this.removeFromCurrentLegRef.nativeElement.focus();
    //setTimeout(() => {
    //  this.routeRef.nativeElement.focus();
    //}, 500);
  }

  clickRemovePersonOption() {
    this.actionType = 'RemoveOption';
    this.clickSave('RemoveOption');
    //this.removeFromCurrentLegRef.nativeElement.focus();
    //setTimeout(() => {
    //  this.routeRef.nativeElement.focus();
    //}, 500);
  }

  clickClose() {
    if (this.dataChanged && !this.lockdownTrip && !this.arrivalDateTimePassed) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save your changes?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave('');
        }
        else {
          this.returnObj.refresh = false;
          this._dialogRef.close(this.returnObj);
        }
      });
    }
    else {
      if (this.isRefresh) {
        this.returnObj.refresh = true;
        this.returnObj.actionName = "";
        this._dialogRef.close(this.returnObj);
      }
      else {
        
        this.returnObj.refresh = false;
        this._dialogRef.close(this.returnObj);
      }
    }
  }

  clickOpenPersonDetail() {

    this.isRefresh = true;

    let personClassGUID: string = "";
    let personClassDescription: string = "";
    if (this.personType == 'PIC' || this.personType == 'SIC' || this.personType == 'Crew') {
      personClassGUID = "F82AA48E-31B7-4667-9FAA-24204DC4A65B";
      personClassDescription = "Crew";
    }
    if (this.personType == 'PAX') {
      personClassGUID = "72433A83-D1FF-4391-B92C-564A817C5438";
      personClassDescription = "PAX";
    }
    if (personClassGUID != "") {
      /*window.open('/persons/customer-person-details?pId=' + this.personGUID + '&selectedPersonClassGUID=' + personClassGUID + '&selectedPersonClassDescription=' + personClassDescription + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');*/
      //window.open('/persons/customer-person-details?pId=' + this.personGUID + '&selectedPersonClassGUID=' + personClassGUID + '&v=' + this._authService.getCurrentTimeNumber(), '_blank');

      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.disableClose = true;
      dialogConfig.panelClass = "custom-dialog-container";
      dialogConfig.maxHeight = "95vh";
      dialogConfig.hasBackdrop = false;
      dialogConfig.data = { personGUID: this.personGUID, customerGUID: this.customerGUID, selectedPersonClassGUID: personClassGUID, selectedPersonClassDescription: personClassDescription };

      const dialogRef = this._dialog.open(CustomerPersonDetailsComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getAllData();
        }

      });

    }
  }



  getData() {
    let request = new GroundStopManifestModel();
    // request.aircraftGuid = this.aircraftId;

    request.personGUID = this.personGUID;
    request.groundStopGUID = this.groundStopGUID;
    request.nextArrivalGroundStopGUID = this.nextArrivalGroundStopGUID;



    this._tripLegService.getManifestDetails<ResponseModel<GroundStopManifestModel>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "" && response.model != null) {

          this.manifestDetails = response.model;
          this.isPassportNotRequired = this.manifestDetails.isPassportNotRequired;
          this.dob = this.manifestDetails.dob;



        }
      }
    });

  }


  generateTootip() {
    //  d.documentTypeDescription  d.documentNumber   d.isO2Letter   d.documentDOE


    // let tooltip = this.manifestDetails.lastName + ', ' + this.manifestDetails.firstName + ' ' + this.manifestDetails.middleName + `\n`
    //   + 'Gender: ' + this.manifestDetails.gender + `\n` +
    //   'DOB: ' + this.manifestDetails.dob + `\n` +
    //   distinctList.map(d => d.documentTypeDescription + ' ' + d.documentNumber + ' ' + d.isO2Letter + ' ' + d.documentDOE).join(`\n`)
    //   + (this.f.isPassportNotRequired.value == true ? `\n` + 'Passport Not Required' : '');

    // debugger;

    this.depDocumentList.forEach(x => { x.documentServiceLocation = 'D'; });
    this.arrDocumentList.forEach(x => { x.documentServiceLocation = 'A'; });
    
    let totalDocList = this.depDocumentList;
    if (this.sameDocumentForDepArr) {
      // totalDocList = totalDocList.concat(this.arrDocumentList);
    }
    else {
      totalDocList = totalDocList.concat(this.arrDocumentList);
    }



    let distinctList = totalDocList?.filter((value, index, self) =>
      index === self.findIndex((t) => t.personTravelDocumentGUID === value.personTravelDocumentGUID)
    );
 
    totalDocList.sort((a, b) => {
      // First, compare by documentServiceLocation
      const locationComparison = b.documentServiceLocation.localeCompare(a.documentServiceLocation);

      // If documentServiceLocation is the same, then compare by documentTypeDescription
      if (locationComparison === 0) {
        return b.documentTypeDescription.localeCompare(a.documentTypeDescription);
      } 
      return locationComparison;
    });

    let tooltip = (this.manifestDetails.lastName || '') + ', '
      + (this.manifestDetails.firstName || '') + ' '
      + (this.manifestDetails.middleName || '') + `\n`
      + (this.manifestDetails.gender ? 'Gender: ' + this.manifestDetails.gender + `\n` : '')
      + (this.manifestDetails.dob ? 'DOB: ' + this.datePipe.transform(this.manifestDetails.dob, 'dd-MMM-yyyy')?.toUpperCase() + `\n` : '')
      + (this.f.isPassportNotRequired.value === true ? `Passport Not Required\n` : '')
      // + distinctList.map(d =>
      + totalDocList
      // .sort((a, b) => a.documentServiceLocation.localeCompare(b.documentServiceLocation))  
      .map(d =>
        (this.sameDocumentForDepArr ? '' : (d.documentServiceLocation + ': ') )  +
        (d.documentTypeDescription || '') + ' '
        + (d.documentNumber || '') + ' '
        + (d.isO2Letter || '') + ' '
        + (this.datePipe.transform(d.documentDOE, 'dd-MMM-yyyy')?.toUpperCase() || '')
      ).join(`\n`);
 







    // let tooltip = (this.manifestDetails.lastName || '') + ', '
    //   + (this.manifestDetails.firstName || '') + ' '
    //   + (this.manifestDetails.middleName || '') + `\n`
    //   + (this.manifestDetails.gender ? 'Gender: ' + this.manifestDetails.gender + `\n` : '')
    //   + (this.manifestDetails.dob ? 'DOB: ' + this.datePipe.transform(this.manifestDetails.dob, 'dd-MMM-yyyy')?.toUpperCase() + `\n` : '')
    //   + (this.f.isPassportNotRequired.value === true ? `Passport Not Required\n` : '')


    //    //n For Departure airport documents
    //   // + `\n`
    //   + (this.depDocumentList?.length > 0 ? 'Departure: ' + this.departureIcao + ' / ' + this.departureIata : '') + `\n`
    //   + this.depDocumentList.map(d =>
    //     // (d.documentServiceLocation ) + ': ' +
    //     (d.documentTypeDescription || '') + ' '
    //     + (d.documentNumber || '') + ' '
    //     + (d.isO2Letter || '') + ' '
    //     + (this.datePipe.transform(d.documentDOE, 'dd-MMM-yyyy')?.toUpperCase() || '')).join(`\n`)

    //     //n For Arrival airport documents
    //     + `\n` + `\n`
    //   + (this.arrDocumentList?.length > 0 ? 'Arrival: ' + this.nextArrivalIcao + ' / ' + this.nextArrivalIata : '') + `\n`
    //     + this.arrDocumentList.map(d =>
    //       // (d.documentServiceLocation ) + ': ' +
    //       (d.documentTypeDescription || '') + ' '
    //       + (d.documentNumber || '') + ' '
    //       + (d.isO2Letter || '') + ' '
    //       + (this.datePipe.transform(d.documentDOE, 'dd-MMM-yyyy')?.toUpperCase() || '')



    //   ).join(`\n`);

    // console.log(tooltip);



    return tooltip;

  }


}
