import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ViewEncapsulation, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { IdentityService } from '../services/identity.service'
import { IdentityRequestModel } from '../models/identity-request.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CustomerModel } from '../models/customer.model';
import { CommonService } from '../services/common.service';
import { PersonCommsAddressModel } from '../models/person-comms-address.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CustomPaginator } from '../common-utility/custom-paginator-configuration';
import { DOCUMENT } from '@angular/common';
import { PersonModel } from '../models/person.model';
import { PersonService } from '../services/person.service';
import { PersonClassModel } from '../models/person-class.model';
import { PersonMailAddressModel } from '../models/person-mail-address.model';
import { PersonTravelDocumentModel } from '../models/person-travel-document.model';
import { CustomerPersonTravelDocSelectionDialogComponent } from './customer-person-travel-doc-selection-dialog.component';
import { PersonPassportEditDialogComponent } from './person-passport-edit-dialog.component';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { PersonPassportVisalistDialogComponent } from './person-passport-visalist-dialog.component';
import { PersonTravelDocEditDialogComponent } from './person-travel-doc-edit-dialog.component';
import { PersonRoleModel } from '../models/person-role.model';
import { DialogService } from '../services/dialog.service'; 
import { CustomerPersonTravelDocActiveTripsComponent } from './customer-person-travel-doc-active-trips.component';

@Component({
  selector: 'app-customer-person-travel-doclist',
  templateUrl: './customer-person-travel-doclist.component.html',
  styleUrls: ['./customer-person-travel-doclist.component.css'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator('') }
  ],
  //encapsulation: ViewEncapsulation.None
})

export class CustomerPersonTravelDoclistComponent implements OnInit, OnChanges {
  customer_person_travel_loclist_form: UntypedFormGroup;
  @Input() personId: string;
  @Input() customerGUID: string;
  // @Input() customerName: string;
  //@Input() tabName: string;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  userType: string;
  showSpin: boolean = false;
  //selectedTabName: string;
  totalRecordsText: string;
  totalRecords: number = 0;
  pageSize: number = 10;
  currentPageIndex: number = 0;
  totalPageNumber: number = 0;
  // customerGUID: string;
  text_search: string;
  filter_by: string;  
  recordList: PersonTravelDocumentModel[];
  currentDate: string = new Date().toUTCString();

  displayedColumn: string[] = ['documentType', 'documentNumber', 'issuingCountry', 'effectiveDate', 'expirationDate', 'name', 'whenToUse', 'subDoc', 'data_entry_status', 'disabled', 'hasActiveTrip', 'action'];
  displayedColumn2: string[] = ['title'];

  @Input() filterOption: number;
  @Output() public updateParentPage = new EventEmitter();
  upPII: boolean = false;
  grantAccessToManageManifestProfiles: boolean;
  hideColumn: boolean = false;
  constructor(private readonly formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService, private _router: Router, private readonly _formBuilder: UntypedFormBuilder,
    private readonly _commonService: CommonService, private readonly _route: ActivatedRoute, private readonly _dialog: MatDialog,
    private readonly _personService: PersonService, private readonly _dialogService: DialogService) {

  }

  ngOnInit() {
    

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.customer_person_travel_loclist_form = this._formBuilder.group({

    });

    //this.includeDisabledRecords = changes.includeDisabledRecords.currentValue;
    //if (this.includeDisabledRecords == undefined || this.includeDisabledRecords == null)
    //  this.includeDisabledRecords = false;

    this.filterOption = changes.filterOption.currentValue;
    if (this.filterOption == undefined || this.filterOption == null)
      this.filterOption = 1;

    this._authService.updateAccessTime();
    this.grantAccessToManageManifestProfiles = false;
    if (localStorage.getItem('up.mPro') == 'true') {
      this.grantAccessToManageManifestProfiles = true;
    }
    this.upPII = false;
    if (localStorage.getItem('up.pii') == 'true') {
      this.upPII = true;
    }
    if (!this.upPII || !this.grantAccessToManageManifestProfiles) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      return;
    }

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }
    if (this.userType == 'customer') {
      this.hideColumn = true;
    }
    this.errMsg = "";
    this.msg = "";
    this.recordList = [];
    this.totalRecordsText = '';
    this.totalRecords = 0;
    this.currentPageIndex = 0;
    this.totalPageNumber = 0;

    this.getResults();

  }

  public getResults() {
    this.errMsg = "";
    this.msg = "";
    this.showSpin = true;
    this._authService.updateAccessTime();
    let request = new PersonTravelDocumentModel();
    request.personGUID = this.personId;
    request.pageIndex = this.currentPageIndex;
    request.pageSize = this.pageSize;
    request.totalRecords = this.totalRecords;
    if (this.filterOption != 3)
      request.isActive = true;
    else
      request.isActive = false;
    if (this.filterOption == 2)
      request.filterDataEntryStatus = true;
    else
      request.filterDataEntryStatus = false;

    this._personService.getCustomerPersonTravelDocument<ResponseModel<PersonTravelDocumentModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          if (this.recordList[0].totalRecords > 0) {
            this.totalRecordsText = this.recordList[0].totalRecords.toString() + ' record count';
            this.totalRecords = this.recordList[0].totalRecords;
            //this.totalPageNumber = Math.ceil(this.totalRecords / this.pageSize)

          }

        }
        else {
          this.recordList = [];
          this.msg = "No records found.";
        }
      }
      else {
        this.recordList = [];
      }
      this.updateParentPage.emit({ docCount: this.totalRecords });        
      this.loading = false;
      this.showSpin = false;

    })


  }

  //pageIndexChange(e: any) {
  //  //e.length; e.pageIndex; e.pageSize; e.previousPageIndex
  //  this.currentPageIndex = e.pageIndex;
  //  this.getResults();

  //}

  clickPreviewDocument(e:any, doc: PersonTravelDocumentModel) {
    let personTravelDocumentGUID = doc.personTravelDocumentGUID;
    this._personService.getCustomerPersonTravelDocumentByptdId<ResponseModel<PersonTravelDocumentModel>>(personTravelDocumentGUID,true).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let docObj = response.model;
          let byteArray = UtilityFunctions.base64ToArrayBuffer(docObj.bFile);
          let file = new Blob([byteArray], { type: docObj.mimeType });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);

        }
        else {
          this.errMsg = "No Document returned.";
        }
      }
      else {
        this.errMsg = "No Document returned.";
      }
    })

  }

  clickEdit(e: any, doc: PersonTravelDocumentModel) {
    let doctype = doc.documentTypeDescription;
    if (doctype.toLowerCase() == 'passport') {
      this.openPassportVisalistPage(doc);
    }
    else {
      this.openTravelDocEditPage(doc);
    }
  }

  clickAdd(event: any, doc: PersonTravelDocumentModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
  //  dialogConfig.width = "30em";
    //dialogConfig.height = "12em";
    dialogConfig.disableClose = true;
    dialogConfig.data = {personGUID: this.personId };
    const dialogRef = this._dialog.open(CustomerPersonTravelDocSelectionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        switch (result.actionName) {
          case "passport":
            this.openPassportEditPage();
            break;
          case "traveldoc":
            let doc1 = new PersonTravelDocumentModel();
            doc1.documentTypeGUID = result.newId;
            this.openTravelDocEditPage(doc1);
            break;
          case "visa":
            let doc2 = new PersonTravelDocumentModel();
            doc2.personTravelDocumentGUID = result.newId;
            if (doc2.personTravelDocumentGUID != '') {
              this.openPassportVisalistPage(doc2);
            }
            else {
              this.openPassportEditPage();
            }
            break;
        }
        //if (result.actionName == "passport") {
        //  this.openPassportEditPage();
        //}
        //if (result.actionName == "traveldoc") {
        //  this.openTravelDocEditPage(doc);
        //}
        
      }
    });

  }

  openPassportVisalistPage(doc: PersonTravelDocumentModel) {
    
    let s = "";
    let personTravelDocumentGUID =doc.personTravelDocumentGUID;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "70em";//"1000px";
    //dialogConfig.height = "40em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: this.personId, customerGUID: this.customerGUID , personTravelDocumentGUID: personTravelDocumentGUID };
    //dialogConfig.data = { dialogTitle: s };

    const dialogRef = this._dialog.open(PersonPassportVisalistDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getResults();
      }

    });
    
  }

  openPassportEditPage() {
    let s = "New Record";
    let personTravelDocumentGUID = "";

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "25em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: this.personId, customerGUID: this.customerGUID , personTravelDocumentGUID: personTravelDocumentGUID };
    //dialogConfig.data = { dialogTitle: s };

    const dialogRef = this._dialog.open(PersonPassportEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.refresh) {
        this.getResults();
      }
      else {
        if (result.newId != "") {
          let doc = new PersonTravelDocumentModel();
          doc.personTravelDocumentGUID = result.newId;
          this.openPassportVisalistPage(doc);
          
        }
      }
    });
  }

  openTravelDocEditPage(doc: PersonTravelDocumentModel) {
    let s = "";
    let personTravelDocumentGUID = "";
    let documentTypeGUID = "";
    if (doc != null) {
      if (doc.documentTypeGUID != "" && doc.documentTypeGUID != null && doc.documentTypeGUID != undefined) {
        documentTypeGUID = doc.documentTypeGUID;
      }
      if (doc.personTravelDocumentGUID == "" || doc.personTravelDocumentGUID == null || doc.personTravelDocumentGUID ==undefined) {
        personTravelDocumentGUID = "";
        s = "New Record";
      }
      else {
        personTravelDocumentGUID = doc.personTravelDocumentGUID;
        s = "Edit Record"
      }
      
    }
    else {
      s = "New Record";
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "65em";//"1000px";
    //dialogConfig.height = "23em";//"500px";
    dialogConfig.disableClose = true;

    dialogConfig.data = { dialogTitle: s, personGUID: this.personId,customerGUID: this.customerGUID , personTravelDocumentGUID: personTravelDocumentGUID, documentTypeGUID: documentTypeGUID };
    //dialogConfig.data = { dialogTitle: s };

    const dialogRef = this._dialog.open(PersonTravelDocEditDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getResults();
      }

    });

  }



  openCustomerPersonTravelDocActiveTripsComponent(e: any, element: PersonTravelDocumentModel) {
    e.stopPropagation();
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container4"
    config.width = "40em";
    config.maxWidth = "40em";
    config.minHeight = "40em";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.data = {
      dialogTitle: '', activeTripList: element.activeTripList, travelDocument: element,
      personTravelDocumentGUID: element.personTravelDocumentGUID  
      // customerName: this.customerName,
    };

    this._dialog.open(CustomerPersonTravelDocActiveTripsComponent, config);
  }

}
