<div *ngIf="upPII && grantAccessToManageManifestProfiles" style="background-color:#f8f8f8;" >
  <div style="display: inline-flex; justify-content: space-between;    width: 100%;" cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>
    <div style="display: flex;" >
      <div style="padding-left:1em;padding-right:1em;margin-top:0.3125em;">
        <h5 class="mat-h2">{{dialogTitle}}</h5>
      </div>
    </div>
    <div style="min-width: 50em">
      <div *ngIf="errMsg!=''" class="alert alert-danger p-0 small mt-3">{{errMsg}}</div>
    </div>
          <button mat-icon-button mat-dialog-close> <i class="material-icons" style="font-size: x-large;">close </i> </button>

    <!--<div style="font-weight: 700; margin-top:0.3125em; cursor: pointer;padding-right:0.5em;">
    <i class="material-icons" style="color:black;" (click)="clickClose()">close</i>
    </div>-->
  </div>

  <!-- (ngSubmit)="clickSave(false)" -->
  <form *ngIf="person_edit_form" [formGroup]="person_edit_form" >
    <div>
      <div style="display:flex; margin-top:0em; padding-left: 1em">
        <div style="margin-top:0em;background-color:white; padding-left:0.5em;padding-bottom:0.5em">
          <div>
            <div style="display:flex">
              <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                <label for="lastName" style="font-size:small">Last Name</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="lastName" class="form-control" maxlength="255"
                    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
                    style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                </div>
                <div *ngIf="submitted && f.lastName.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.lastName.errors.required">Last Name is required.</div>
                  <div *ngIf="f.lastName.errors.pattern">Invalid last name</div>
                </div>
              </div>
              <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                <label for="firstName" style="font-size:small">First Name</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="firstName" class="form-control" maxlength="255"
                    [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                    style="padding: 0em;width:11em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                </div>
                <div *ngIf="submitted && f.firstName.errors" class="alert alert-danger p-0 small">
                  <div *ngIf="f.firstName.errors.required">First Name is required.</div>
                  <div *ngIf="f.firstName.errors.pattern">Invalid first name</div>
                </div>
              </div>
              <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
                <label for="middleName" style="font-size:small">Middle Name</label>
                <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                  <input type="text" formControlName="middleName" class="form-control" maxlength="255"
                    style="padding: 0em;width:13em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
                </div>
      
              </div>
            </div>
            <!--<div style="display:flex">
          <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback" style="width:14em">
            <div *ngIf="f.lastName.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:13em">Last name is a required field.</div>
          </div>
          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback" >
            <div *ngIf="f.firstName.errors.required" style="background-color: #f9d2de;margin-top:0em;padding-left:0.25em; width:13em;">First name is a required field.</div>
          </div>
        </div>-->
      
          </div>
          <div>
            <label for="title" style="font-size:small">Title</label>
            <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
              <input type="text" formControlName="title" class="form-control" maxlength="255"
                style="padding: 0em;width:36.5em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
            </div>
          </div>
          <div style="display:flex;">
            <div>
              <label for="dob" style="font-size:small">Date of Birth</label>
              <div style="display:flex;margin-top:-0.5em">
                <input type="text" [matDatepicker]="dobdatepicker" placeholder="mm/dd/yyyy" formControlName="dob" #dob
                  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }"
                  style="padding: 0.25em; width: 8.5em; height: 1.5625em; font-size: small; background-color:#eaf0fd"
                  maxlength="10" />
                <mat-datepicker-toggle matSuffix [for]="dobdatepicker"
                  style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                <mat-datepicker #dobdatepicker></mat-datepicker>
              </div>
              <div *ngIf="submitted && f.dob.errors" class="alert alert-danger p-0 small">
                <div *ngIf="f.dob.errors.isValidDate==false">Invalid date of birth. format: mm/dd/yyyy</div>
                <div *ngIf="f.dob.errors.isFutureDate">Cannot be future date.</div>
              </div>
            </div>
            <div style="margin-left: 6.5em; margin-top:0em">
              <label for="gender_select" style="font-size:small">Gender</label>
              <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <select *ngIf="f" formControlName="gender_select" style="width:11em;"
                  class="form-control form-control-sm dropdown">
                  <option value="" selected></option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                </select>
              </div>
            </div>
          </div>
          <div style="display:flex">
            <div style="margin-left: 0em;margin-top:0em;margin-right:0.625em;">
              <label for="city" style="font-size:small">City of Birth</label>
              <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="city" class="form-control" maxlength="255"
                  style="padding: 0em;width:17.8em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
              </div>
            </div>
            <div>
              <label for="state_province" style="font-size:small">State/Province of Birth</label>
              <div style="margin-left: 0em; margin-top:-0.5em; margin-bottom:0em">
                <input type="text" formControlName="state_province" class="form-control" maxlength="255"
                  style="padding: 0em;width:17.8em;height:1.5625em;font-size:small;background-color:#eaf0fd" />
              </div>
            </div>
          </div>
          <div>
            <label for="birth_country_select" style="font-size:small">Country of Birth</label>
            <div style="margin-top:-0.5em;">
              <select *ngIf="f" formControlName="birth_country_select" style="width:36.5em"
                class="form-control form-control-sm dropdown">
                <option value="" selected></option>
                <option *ngFor="let cty of countryList" [ngValue]="cty.countryGUID">{{cty.countryName}}</option>
              </select>
            </div>
          </div>
          <div>
            <label for="residence_country_select" style="font-size:small">Country of Residence</label>
            <div style="margin-top:-0.5em;">
              <select *ngIf="f" formControlName="residence_country_select" class="form-control form-control-sm dropdown"
                style="width:36.5em">
                <option value="" selected></option>
                <option *ngFor="let cty of countryList" [ngValue]="cty.countryGUID">{{cty.countryName}}</option>
              </select>
            </div>
          </div>
          <div>
            <label for="nationality_country_select" style="font-size:small">Country of Nationality</label>
            <div style="margin-top:-0.5em;">
              <select *ngIf="f" formControlName="nationality_country_select" class="form-control form-control-sm dropdown"
                style="width:36.5em;">
                <option value="" selected></option>
                <option *ngFor="let cty of countryList" [ngValue]="cty.countryGUID">{{cty.countryName}}</option>
              </select>
            </div>
          </div>
          <div style="display:flex">
            <div class="mr-2">
              <label for="eesetiasOutOfScope" style="font-size:small">eu-LISA Out Of Scope</label>
              <div style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="eesetiasOutOfScope" style="width:8em"
                  class="form-control form-control-sm dropdown" (change)="setValidity()">
                  <option value="0" selected>No</option>
                  <option value="1">Yes</option>
                </select>
              </div>
            </div>
            <div>
              <label for="eesetiasOutOfScopeExpirationDate" style="font-size:small">Expiration Date</label>
              <div class="d-flex">
                <div style="display:flex;margin-top:-0.35em">
                  <input type="text" [matDatepicker]="outofscopedatepicker" placeholder="mm/dd/yyyy"
                    formControlName="eesetiasOutOfScopeExpirationDate" #eesetiasOutOfScopeExpirationDate class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.eesetiasOutOfScopeExpirationDate.errors }"
                    style="padding: 0.25em; width: 8.5em; height: 1.5625em; font-size: small; background-color:#eaf0fd"
                    maxlength="10" />
                  <mat-datepicker-toggle matSuffix [for]="outofscopedatepicker"
                    style="margin-top:-0.75em; margin-left:-0.75em; padding:0em;"></mat-datepicker-toggle>
                  <mat-datepicker #outofscopedatepicker></mat-datepicker>
                </div>
                <div *ngIf="submitted && f.eesetiasOutOfScopeExpirationDate.errors" class="alert alert-danger p-0 small ml-1"
                  style="margin-top:-.5em">
                  <div *ngIf="f.eesetiasOutOfScopeExpirationDate.errors.isValidDate==false">Invalid date. Format: mm/dd/yyyy
                  </div>
                  <div *ngIf="f.eesetiasOutOfScopeExpirationDate.errors.required">Expiration Date is required.</div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="personGUID!=''" style="display:flex; ">
            <div *ngIf="userType=='internal'" style="margin-top:0em; margin-right:0.625em">
              <label for="data_entry_status_select" style="font-size:small">Data Entry Status</label>
              <div style="margin-top:-0.35em;margin-bottom:0em">
                <select *ngIf="f" formControlName="data_entry_status_select" class="form-control form-control-sm dropdown">
                  <option *ngFor="let d of dataEntryStatusList" [ngValue]="d.dataEntryStatusID">
                    {{d.dataEntryStatusDescription}}</option>
                </select>
              </div>
            </div>

            <!-- <div style="margin-top:0em; margin-right:0.625em">
              <label for="person_status" style="font-size:small">Disable Record</label>
              <div style="margin-top:-0.35em;">
                <select *ngIf="f" formControlName="person_status" style="width:8em"
                  class="form-control form-control-sm dropdown">
                  <option value="1" selected>No</option>
                  <option value="0">Yes</option>
                </select>
              </div>
            </div>
            <div style="margin-right:1em" class="form-group">
              <label for="modifiedBy" style="font-size:small">Modified
                By </label>
              <div style="margin-top:-0.35em;">
                <input type="text" formControlName="modifiedBy" readonly class="form-control"
                  style="width:9em;height:1.5625em;font-size:small;padding:0" />
              </div>
            </div>
            <div class="form-group">
              <label for="modifiedDate" style="font-size:small">Modified
                Date </label>
              <div style="margin-top:-0.35em;">
                <input type="text" formControlName="modifiedDate" readonly class="form-control"
                  style="width:9em;height:1.5625em;font-size:small;padding:0" />
              </div>
            </div> -->



          </div>
          <!--<div *ngIf="errMsg!=''" class="d-flex" style="padding-left:0.25em; display:flex;margin-top:0.25em;margin-bottom:-1em; white-space:pre-wrap;background-color: #f9d2de;width:17em;height:1.5em"><label style="color:black; font-size: small; margin-top:0em">{{errMsg}}</label></div>-->
        </div>
        <div style="width:0.5em; background-color: #f8f8f8;"></div>
        <div style="margin-top:0em; padding-right:0em;margin-right:1em; background-color: white">
          <div style="padding-left: 0.5em;width:max-content ">
            <label for="person_class_select" style="font-size:small">Filter by Class</label>
            <div style="margin-top:-0.5em;">
              <select *ngIf="f" formControlName="person_class_select" class="form-control form-control-sm dropdown"
                style="font-size: small;background-color:#eaf0fd; border-radius:0.25em;border-color: lightgrey"
                (change)="personClassOnchange($event)">
                <option *ngFor="let pc of personClassList" [ngValue]="pc">{{pc.personClassDescription}}</option>
              </select>
            </div>
          </div>
          <div style="display:flex; margin-top:0.25em;padding-left: 0.5em; ">
            <div style="width:12em; overflow-y: auto;overflow-x:hidden">
              <table mat-table *ngIf="avaiPersonRoleList" [dataSource]="avaiPersonRoleList"
                class="example-container2 mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="personRole">
                    <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                      Role Types
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a
                        style="cursor:pointer; text-decoration:underline; color: blue"
                        (click)="clickAddItem($event, element)">{{element.roleDescription}}</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>
      
            </div>
            <div style="margin-top: 2em;margin-left:0em">
              <button type="button" mat-button class="btn-high" [hidden]="avaiPersonRoleList && avaiPersonRoleList.length==0"
                (click)="clickSelectAll()"
                style="width: 7em; padding-left:0.5em !important; padding-right:0.5em !important">Select
                All</button><br /><br />
              <button type="button" mat-button class="btn-low" [hidden]="selPersonRoleList && selPersonRoleList.length==0"
                (click)="clickRemoveAll()"
                style="width: 7em; padding-left:0.5em !important; padding-right:0.5em !important">Remove All</button>
            </div>
            <div style="margin-left:1em;width:12em; overflow-y: auto;overflow-x:hidden">
              <table mat-table *ngIf="selPersonRoleList" [dataSource]="selPersonRoleList"
                class="example-container2 mat-elevation-z8">
                <tr>
                  <ng-container matColumnDef="personRole">
                    <th mat-header-cell *matHeaderCellDef style="background-color: #f8f8f8;">
                      Selected Types
                    </th>
                    <td mat-cell *matCellDef="let element" style="line-height: 1.5625em;border-top: 0.0625em solid #000"><a
                        style="cursor:pointer; text-decoration:underline; color: blue"
                        (click)="clickRemoveItem($event, element)">{{element.roleDescription}}</a></td>
                  </ng-container>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
              </table>
      
            </div>
      
          </div>
          <div *ngIf="submitted && selPersonRoleList.length == 0" class="alert alert-danger pl-1 pr-1 pt-0 pb-0 small ml-2"
            style="width:max-content">You must select at least one person role.</div>
          <!--<div style="height:0.5em;background-color: #f8f8f8;"></div>-->
      
        </div>
      
      </div>
    </div>
   
  <div style="padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; display: flex; height:3em; justify-content: space-between; width: 100%;">
    <div style="display: flex;">

      <div style="margin-right: 1em">
        <button mat-button class="btn-high" [disabled]="loading" (click)="clickSave(true)"><span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>&nbsp;Save&nbsp;</button>&nbsp;
        <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white">&nbsp;Close&nbsp;</button>&nbsp;
        <button mat-button *ngIf="showContactButton" class="btn-high" (click)="clickOpenContactDetails()">Open Contact Details</button>&nbsp;
      </div>
      <div *ngIf="showSuccessMsg">
        <app-success-message [successMsg]=""></app-success-message>
      </div>
    </div>
    
    <div style="display: flex; margin-top: -0.6em; margin-right: 1em;">
      <div style="margin-top:0em; margin-right:0.625em">
        <label for="person_status" style="font-size:small">Disable Record</label>
        <div style="margin-top:-0.35em;">
          <select *ngIf="f" formControlName="person_status" style="width:8em" class="form-control form-control-sm dropdown">
            <option value="1" selected>No</option>
            <option value="0">Yes</option>
          </select>
        </div>
      </div>
      <div style="margin-right:1em" class="form-group">
        <label for="modifiedBy" style="font-size:small">Modified
          By<!--{{personGUID!=''? 'Modified by': 'Created by' }}--></label>
        <div style="margin-top:-0.35em;">
          <input type="text" formControlName="modifiedBy" readonly class="form-control"
            style="width:9em;height:1.5625em;font-size:small;padding:0" />
        </div>
      </div>
      <div class="form-group">
        <label for="modifiedDate" style="font-size:small">Modified
          Date<!--{{personGUID!=''? 'Modified Date': 'Date' }}--></label>
        <div style="margin-top:-0.35em;">
          <input type="text" formControlName="modifiedDate" readonly class="form-control"
            style="width:9em;height:1.5625em;font-size:small;padding:0" />
        </div>
      </div> 
    </div>





  </div>
 </form>
</div>
  <app-spin *ngIf="showSpin"></app-spin>

