import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { AdvisoryService } from '../services/advisory.service';
import { AdvisoryModel } from '../models/advisory.model';
import { ResponseModel } from '../models/response.model';
import { AdvisoryDocumentModel } from '../models/advisory-document.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { saveAs } from 'file-saver';
import { GroundStopService } from '../services/ground-stop.service';

@Component({
  selector: 'app-view-advisory',
  templateUrl: './view-advisory.component.html',
  styleUrls: ['./view-advisory.component.css']
})
export class ViewAdvisoryComponent implements OnInit {
  advisoryID: number;
  advisory: AdvisoryModel;
  documents: AdvisoryDocumentModel[];
  fromAdvisoryHomepage: boolean = false;
  advisoryDetails: AdvisoryModel;
  queryHour: number;
  showSpin: boolean;
  affectedLegs: AdvisoryModel[] = [];

  constructor(private _dialogRef: MatDialogRef<ViewAdvisoryComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _advisoryService: AdvisoryService, private readonly _authService: AuthenticateService,
    private readonly _groundStopService: GroundStopService, private readonly _dialog: MatDialog,) {
    this.advisoryID = _data.advisoryID;
    
    if (_data.fromAdvisoryHomepage != undefined && _data.fromAdvisoryHomepage != null) {
      this.fromAdvisoryHomepage = _data.fromAdvisoryHomepage;
      this.advisoryDetails = _data.advisoryDetails;
      this.queryHour = _data.queryHour;
    }
    
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    this._advisoryService.getAdvisoryByAdvisoryID<ResponseModel<AdvisoryModel>>(this.advisoryID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.advisory = response.model;
          this.documents = this.advisory.advisoryDocumentsList;
        }
        else {
          if (response.code == "401")
            this._authService.logout();
        }
      }
    });

    this.getAffectedLegsByAdvisory();

  }
 
  getAffectedLegsByAdvisory() {
 
    let request = new AdvisoryModel();
    request.queryHour = this.queryHour;
    request.storyValue = this.advisoryDetails.storyValue;
    request.icon = this.advisoryDetails.icon;
    request.icao = this.advisoryDetails.icao;
    
    this.showSpin = true;
    this._groundStopService.getAffectedLegsForAdvisoryAlertByConditions<ResponseModel<AdvisoryModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.affectedLegs = response.model;

        }
        else {
          if (response.code == "401")
            this._authService.logout();
        }
      }
    });
  }

  previewDocument(e: any, advisoryDoc: AdvisoryDocumentModel) {
    let advisoryDocumentID = advisoryDoc.advisoryDocumentID;
    if (advisoryDoc.bFile != null) {
      let byteArray = UtilityFunctions.base64ToArrayBuffer(advisoryDoc.bFile);
      let file = new Blob([byteArray], { type: advisoryDoc.mimeType });
      if (advisoryDoc.mimeType == "application/octet-stream") {
        saveAs(file, advisoryDoc.documentName);
      }
      else {
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }

    }


  }


  openTrip(p: any) {
    window.open('/ground-stops/trip-details-internal?tripId=' + p.tripCodeGUID + '&aircraftId=' + p.aircraftGUID + '&customerId=' + p.customerGUID + '&pf=&v=' + this._authService.getCurrentTimeNumber(), '_blank');
  }


}
