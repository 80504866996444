import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { PersonTravelDocumentModel } from '../models/person-travel-document.model';
import { ResponseModel } from '../models/response.model';
import { PersonService } from '../services/person.service';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-customer-person-travel-doc-active-trips',
  templateUrl: './customer-person-travel-doc-active-trips.component.html',
  styleUrls: ['./customer-person-travel-doc-active-trips.component.css']
})

export class CustomerPersonTravelDocActiveTripsComponent implements OnInit {

  customer_person_travel_doc_active_trips: UntypedFormGroup;
  isDialog: boolean = false;

  personTravelDocumentGUID: string;
  activeTripList: PersonTravelDocumentModel[] = [];

  displayedColumn: string[] = ['tripCode', 'icaoList', 'open'];
  showSpin: boolean = false;
  totalRecordsText: string = '';
  errMsg: string = '';
  travelDocument: PersonTravelDocumentModel;
  fullName: string = '';
  documentNumber: string = '';
  documentTypeDescription: string = '';
  customerName: string = '';


  constructor(@Optional() private readonly _dialogRef: MatDialogRef<CustomerPersonTravelDocActiveTripsComponent>, private readonly _commonService: CommonService,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any, private readonly _authService: AuthenticateService,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _personService: PersonService,
  ) {

    if (this.data) {
      this.isDialog = true;
      // this.activeTripList = this.data.activeTripList;
      this.personTravelDocumentGUID = this.data.personTravelDocumentGUID;

      // this.customerName = this.data.customerName;
      this.travelDocument = this.data.travelDocument;
      
      

    }

  }

  ngOnInit() {

    // this._authService.updateAccessTime();
    
    this.getData();
  }
  
  initControls() {
    this.customer_person_travel_doc_active_trips = this._formBuilder.group({
      // userComments: [this.userComments]
    });
  }

  get f() { return this.customer_person_travel_doc_active_trips.controls; }

  // this.activeTripList = response.model[0].activeTripList;
  
  getData() {

    this._personService.getCustomerPersonTravelDocumentByptdId<ResponseModel<PersonTravelDocumentModel>>(this.personTravelDocumentGUID).subscribe(response => {
      if (response != null && response.code == "200") { 
          this.activeTripList = response.model.activeTripList;
          // this.travelDocument = response.model;
        this.customerName = response.model.customerName;
    
 

      
        this.showSpin = false;

      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    });

  }



  clickClose(val) {
    this._dialogRef.close(val);


  }


  clickOpenTrip(e: any, item: PersonTravelDocumentModel) {
    window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');
  }
}











