export class FileModel {
  fileName: string;
  fileSize: number;
  status: string;
  filePath: string;
  bFile: string;
  mimeType: string;
  fileType: string;
  tempId: number;
  allowedFileTypeList: string[];
  allowedFileUploadNumber: number;
  docGUID: string;
  isInternal: boolean;
  isActive: boolean;
}
