export class MessageLinkModel{
  messageLinkGUID: string;
  messageGUID: string;
  customerGUID: string;
  customerName: string;
  vendorGUID: string;
  vendorName: string;
  tripCodeGUID: string;
  tripCode: string;
  groundStopGUID: string;
  leg: string;
  groundStopTaskGUID: string;
  serviceTypeDescription: string;
  taskStatus: string;
  modifiedBy: string;
  modifiedDate: string;
  aircraftGUID: string;
  isClientMessage: boolean;
  customerTripID: string;
  showAsAutolink: boolean;
}
