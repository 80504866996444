import { GroundStopManifestDocumentModel } from "./ground-stop-manifest-document.model";
import { TripLegModel } from "./trip-leg.model";

export class GroundStopManifestModel {
  tripCodeGUID: string;
  groundStopSequenceID: number;
  customerGUID: string;
  groundStopGUID: string;
  personGUID: string;
  personName: string;
  personNameAbbr: string;
  groundStopManifestTypeDescription: string;
  groundStopManifestType: string;
  RoleDescription: string;
  lastName: string;
  firstName: string;
  selected: boolean;
  middleName: string;
  isActive: boolean;
  //personRoleGUIDList: string;
  modifiedBy: string;
  modifiedDate: string;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  searchKeyword: string;
  personType: string;
  selectedAllCrewPaxGUIDList: string;
  groundStopManifestDocumentList: GroundStopManifestDocumentModel[];
  personClassGUID: string;
  personClassDescription: string;
  applyAllGroundStops: boolean;
  groundStopManifestTypeIDList: string;
  hasPersonTravelDoc: boolean;
  applyCrewToAllGroundStops: boolean;
  applyPaxToAllGroundStops: boolean;
  loadFromPrevDeparture: boolean = false;
  loadFromArrival: boolean = false;
  groundStopGUIDList: string;
  documentUsageRule: string;
  visaCountryName: string;
  hasRequiredDocument: boolean;
  isMinorOnboard: boolean;
  groundStopCountryGUID: string;
  tooltip: string;
  expiredDocCount: number;
  isDeptFerryFlight: boolean;
  isArrFerryFlight: boolean;
  additionalOptions: number;
  capacityID: number;
  missingInfoTooltip: string;
  personTypeOrder: number;
  tripLegList: TripLegModel[];
  nextArrivalGroundStopGUID: string;
  nextArrivalGroundStopManifestDocumentList: GroundStopManifestDocumentModel[];
  removeFromAllActiveLeg: boolean;
  removeFromTrip: boolean;
  passportCount: number;
  alreadyHaveCrewOnTrip: boolean;
  alreadyHavePaxOnTrip: boolean
  isTempTrip: boolean;
  disabled: boolean;
  totalPassportCount: number;
  dob: string;
  gender: string;
  personLegType: string;
  isPassportNotRequired: boolean;
  personDocumentTooltip: string;
  airportViewTooltip: string;
  isBellAlert: boolean;
  isBellAlertDescription: string;

  isMultiplePassports: boolean;
  isMultiplePassportsType: string;
  departureDateUTC: string;
  hasExpiredPassport: boolean;
  fromEdit: boolean;
  documentsNotRequired: boolean;
  departureIcao: string;
  nextArrivalIcao: string;
  departureIata: string;
  nextArrivalIata: string;
}
