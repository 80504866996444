
<div cdkDrag
     cdkDragRootElement=".cdk-overlay-pane"
     cdkDragHandle style="margin-top:-1em" mat-dialog-title>
  <label >
    {{icaoIata}} / {{airportData.airportName}} / {{airportData.airportLocation}} {{countryData.countryName}}
  </label>
    <!--<button *ngIf="icaoIata" mat-button class="btn btn-high btn-grid ml-3" (click)="editAirport()">Edit</button>-->
  
  <div *ngIf="groundStopGUID!=''" style="margin-top:-.75em" class="d-flex justify-content-start font-weight-normal">
    <div class="mr-3">
      <div style="font-size: small">Registration</div>
      <div style="font-weight: bold; font-size: small;margin-top:-1em">{{registration}}</div>
    </div>
    <div class="mr-3">
      <div style="font-size: small;">Trip Code</div>
      <div style="font-weight: bold; font-size: small;margin-top:-1em">{{tripCode}}</div>
    </div>
    <div class="mr-3">
      <div style="font-size: small">Business Stop</div>
      <div style="font-size: small; display: flex;margin-top:-1em"><div>{{prevICAO!=''?prevICAO+'-':''}}</div><div style="font-weight: bold">{{icao}}</div><div>{{nextICAO!=''?'-'+nextICAO:''}}</div></div>
    </div>
    <div *ngIf="arrivalDateTime!=''" class="mr-3">
      <div style="font-size: small">Arrival({{icao}})</div>
      <div style="font-size: small;font-weight: bold; margin-top:-1em">{{arrivalDateTime}}</div>
      <div style="font-size: small; margin-top:-1em"> {{arrivalDateTimeLocal!=''?arrivalDateTimeLocal:''}}</div>
    </div>
    <div *ngIf="departureDateTime!=''" class="mr-3">
      <div style="font-size: small">Departure({{icao}})</div>
      <div style="font-size: small;font-weight: bold; margin-top:-1em">{{departureDateTime}}</div>
      <div style="font-size: small; margin-top:-1em"> {{departureDateTimeLocal!=''?departureDateTimeLocal:''}}</div>
    </div>
    <div class="mr-3">
      <div style="font-size: small;">{{aircraftManufacturer}} {{aircraftModel}}</div>
      <div style="font-size: small;margin-top:-1em">{{aircraftInfo}}</div>
    </div>
  </div>

  <div *ngIf="userType=='internal'" style="margin-top:-.25em">
    <mat-radio-group class="example-radio-group" [(ngModel)]="detailType">
      <mat-radio-button style="transform:scale(.75);font-size:large" value="airport">Airport  </mat-radio-button>
      <mat-radio-button style="transform:scale(.75);font-size:large" value="vendor">Vendor  </mat-radio-button>
      <mat-radio-button style="transform:scale(.75);font-size:large" value="country">Country  </mat-radio-button>
      <mat-radio-button style="transform:scale(.75);font-size:large" value="businessRules">Business Rules</mat-radio-button>
      <mat-radio-button style="transform:scale(.75);font-size:large" value="map">Map</mat-radio-button>
    </mat-radio-group>
  </div>
 
</div>


<div mat-dialog-content style="margin-top:-1.5em;padding-bottom:0; background-color:white;border-radius:.25em;font-size:small !important; max-height: 35em; height: 35em;" class="mx-1 pb-3">
  <div class="example-action-buttons" *ngIf="detailType=='vendor'">
    <button mat-button (click)="expandAccordions()">Expand All</button>
    <button mat-button (click)="collapseAccordions()">Collapse All</button>
  </div>

  <div *ngIf="detailType=='airport' && (  noteCount!=0)" class="example-headers-align" style="max-height: 25em;">
    <div *ngIf="airportNotes!=null || noteCount!=0">
      <div style="color:#146ec3; font-size:  x-large;"><i class="material-icons" style="font-size:  large;">text_snippet </i>  Notes</div>
      <div>
        <div class="row" *ngIf="noteCount!=0">
          <div class="col-11">
          </div>
          <div class="col-1" *ngIf="userType=='internal'">
            <button *ngIf="grantAccessToAirportDocs" type="button" mat-button class="btn-high grid" (click)="editAirportNote(null)">ADD</button>
          </div>
        </div>
        <ng-template ngFor let-element [ngForOf]="airportNotes" let-i="index">
          <div class="row font-small">
            <div class="col-3">
              <label>{{element.noteType}}</label>
            </div>
            <div class="col-8">
              <label style="white-space:pre-wrap">{{element.airportNote}}</label>
            </div>
            <div class="col-1" *ngIf="userType=='internal'">
              <button *ngIf="grantAccessToAirportDocs" type="button" mat-button class="btn-low grid" (click)="editAirportNote(element)">EDIT</button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div style="margin: 0.2em; height: 0.2em; background-color: #e3e1e1; "></div>

    <div>
      <div style="color:#146ec3; font-size:   x-large;"><i class="material-icons" style="font-size:  large;">topic </i> Airport Documents</div>
      <div>
        <div class="row" style="display: flex; justify-content: space-between;">

          <div class="col-3" class="row font-small">
            <div style="width: 12em;  margin-left: 2.3em;">
              <b style="white-space:pre-wrap">Type</b>
            </div>

            <div style="width: 19em; margin-left: 1em;">

              <b style="white-space:pre-wrap">Name</b>
            </div>

            <div style="width: 6em; margin-left: 1em;">
              <b style="white-space:pre-wrap"> Effective </b>
            </div>

            <div style="width: 6em; margin-left: 1em;">
              <b style="white-space:pre-wrap"> Expiration</b>
            </div>

          </div>
          <div>
            <button *ngIf="grantAccessToAirportDocs" type="button" mat-button class="btn-high grid" (click)="editAirportDocument(null)">ADD</button>
          </div>


        </div>
        <div *ngFor="let element of airportDocuments; let i=index">
          <div class="row font-small" style="display: flex; justify-content: space-between;">
            <div class="col-3" class="row font-small" [ngClass]="{ 'redColor': isDateExpired(element.expirationDate)  }">
              <div style="width: 12em;  margin-left: 2.3em;">
                <label style="white-space:pre-wrap">{{element.documentTypeList}}</label>
              </div>

              <div style="width: 19em; margin-left: 1em;">

                <a href="javascript:void(0)" (click)="getAirportDocuments(element)" matTooltipClass="tooltipLineBreak"
                   matTooltip="{{ element.documentDescription }}">{{element.documentName}}</a>
              </div>

              <div style="width: 6em; margin-left: 1em;">
                <label style="white-space:pre-wrap">{{element.effectiveDate}}</label>
              </div>

              <div style="width: 6em; margin-left: 1em;">
                <label style="white-space:pre-wrap">{{ element.expirationDate}}</label>
              </div>

            </div>
            <div>
              <button *ngIf="grantAccessToAirportDocs" type="button" mat-button class="btn-low grid" (click)="editAirportDocument(element)">EDIT</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="margin: 0.2em; height: 0.2em; background-color: #e3e1e1; "></div>

    <div>
      <div style="color:#146ec3; font-size:   x-large;"><i class="material-icons" style="font-size:  large;">topic </i> Country Documents</div>
      <div>
        <div class="row" style="display: flex; justify-content: space-between;">

          <div class="col-3" class="row font-small">
            <div style="width: 12em;  margin-left: 2.3em;">
              <b style="white-space:pre-wrap">Type</b>
            </div>

            <div style="width: 19em; margin-left: 1em;">

              <b style="white-space:pre-wrap">Name</b>
            </div>

            <div style="width: 6em; margin-left: 1em;">
              <b style="white-space:pre-wrap"> Effective </b>
            </div>

            <div style="width: 6em; margin-left: 1em;">
              <b style="white-space:pre-wrap"> Expiration</b>
            </div>

          </div>
          <div>
            <button *ngIf="grantAccessToAirportDocs" type="button" mat-button class="btn-high grid" (click)="editCountryDocument(null)">ADD</button>
          </div>


        </div>
        <div *ngFor="let element of countryDocuments; let i=index">
          <div class="row font-small" style="display: flex; justify-content: space-between;">
            <div class="col-3" class="row font-small" [ngClass]="{ 'redColor': isDateExpired(element.expirationDate)  }">
              <div style="width: 12em;  margin-left: 2.3em;">
                <label style="white-space:pre-wrap">{{element.documentTypeList}}</label>
              </div>

              <div style="width: 19em; margin-left: 1em;">

                <a href="javascript:void(0)" (click)="getCountryDocuments(element)" matTooltipClass="tooltipLineBreak"
                   matTooltip="{{ element.documentDescription }}">{{element.documentName}}</a>
              </div>

              <div style="width: 6em; margin-left: 1em;">
                <label style="white-space:pre-wrap">{{element.effectiveDate}}</label>
              </div>

              <div style="width: 6em; margin-left: 1em;">
                <label style="white-space:pre-wrap">{{ element.expirationDate}}</label>
              </div>

            </div>
            <div>
              <button *ngIf="grantAccessToAirportDocs" type="button" mat-button class="btn-low grid" (click)="editCountryDocument(element)">EDIT</button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div style="margin: 0.2em; height: 0.2em; background-color: #e3e1e1; "></div>

    <div>
      <div class="d-flex justify-content-between">
        <div style="color:#146ec3; font-size:x-large;"><span class="material-symbols-outlined" style="font-size:large">nearby</span> Nearby Airports</div>
        <div class="d-inline-flex">
          <mat-checkbox style="margin-top:.15em;margin-right:1em" color="primary" [(ngModel)]="isPreferred" [ngModelOptions]="{standalone: true}" >Preferred</mat-checkbox>
          <label style="font-size:small;margin-top:.25em;width:10em">Distance in NM: {{distance}}</label>
          <mat-slider (mousedown)="$event.stopPropagation()" style="margin-top:-.7em" thumbLabel tickInterval="50" step="50" min="50" max="1000" color="primary" [(ngModel)]="distance" [ngModelOptions]="{standalone: true}" ></mat-slider>

        </div> 
        <!--<div class="d-inline-flex mt-1">

          <label style="font-size:small;margin-left:1em;">Distance in NM:</label>
          <mat-radio-group class="example-radio-group" [(ngModel)]="distance" (change)="filterNearbyAirports()">
            <mat-radio-button style="transform:scale(.75);font-size:large" value="50">50  </mat-radio-button>
            <mat-radio-button style="transform:scale(.75);font-size:large" value="100">100  </mat-radio-button>
            <mat-radio-button style="transform:scale(.75);font-size:large" value="150">150  </mat-radio-button>
          </mat-radio-group>
        </div>-->
      </div>
      <div style="max-height: 15em; overflow-y: auto; overflow-x: hidden;">
        <app-nearby-airports [airportID]="airportID" [isPreferred]="isPreferred" [distance]="distance" ></app-nearby-airports>
      </div>
      <!--<div class="row font-small font-weight-bold">
    <div class="col-2">
      <label>ICAO</label>
    </div>
    <div class="col-2">
      <label>IATA</label>
    </div>
    <div class="col-3">
      <label>Airport Name</label>
    </div>
    <div class="col-3">
      <label>City</label>
    </div>
    <div class="col-2">
      <label>Distance</label>
    </div>
  </div>
  <div>
    <ng-template ngFor let-element [ngForOf]="nearbyAirports" let-i="index">
      <div class="row font-small">
        <div class="col-2">
          <a  style="color:Highlight; cursor:pointer; text-decoration:underline" (click)="openAirportDetails(element)">{{element.icao}}</a>
        </div>
        <div class="col-2">
          <label>{{element.iata}}</label>
        </div>
        <div class="col-3">
          <label>{{element.airportName}}</label>
        </div>
        <div class="col-3">
          <label>{{element.cityName}}</label>
        </div>
        <div class="col-2">
          <label>{{element.distanceFromAirport | number: '0.0-2'}} NM</label>
        </div>
      </div>
    </ng-template>
  </div>-->
    </div>

    <div style="margin: 0.2em; height: 0.2em; background-color: #e3e1e1; "></div>

    <div>
      <div style="color:#146ec3; font-size:   x-large;"><i class="material-icons" style="font-size:  large;">flight </i>  Runways</div>
      <div class="font-small">
        <div class="row">
          <div class="col-3">
            <label>Primary Runway</label>
          </div>
          <div class="col-9">
            <label>{{airportData.primaryRunway}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <label>Max Runway Length (ft)</label>
          </div>
          <div class="col-9">
            <label>{{airportData.runwayMaxLength}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <label>Max Runway Width (ft)</label>
          </div>
          <div class="col-9">
            <label>{{airportData.runwayMaxWidth}}</label>
          </div>
        </div>
      </div>
    </div>

    <div style="margin: 0.2em; height: 0.2em; background-color: #e3e1e1; "></div>
    <div>
      <div style="color:#146ec3; font-size:   x-large;"><i class="material-icons" style="font-size:  large;">radio </i>  Frequency</div>
      <div>
        <ng-template ngFor let-element [ngForOf]="airportFrequency" let-i="index">
          <div class="row font-small">
            <div class="col-3">
              <label>{{element.frequencyCommsType}}</label>
            </div>
            <div class="col-9">
              <label>{{element.frequency}} MHz</label>
            </div>
          </div>
        </ng-template>
      </div>
    </div>




    <div style="margin: 0.2em; height: 0.2em; background-color: #e3e1e1; "></div>

    <div>
      <div style="color:#146ec3; font-size:   x-large;"><i class="material-icons" style="font-size:  large;">place </i>  Location Details</div>
      <div>
        <div class="font-small">
          <div class="row" *ngIf="airportData.faaid!=null">
            <div class="col-3">
              <label>FAA Identifier</label>
            </div>
            <div class="col-9">
              <label>{{airportData.faaid}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Lat/Long</label>

            </div>
            <div class="col-9">
              <label>{{airportData.latitudeDMS}} / {{airportData.longitudeDMS}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Elevation (ft)</label>
            </div>
            <div class="col-9">
              <label>{{airportData.elevation}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Variation</label>
            </div>
            <div class="col-9">
              <label>{{airportData.variation}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Lighting</label>
            </div>
            <div class="col-9">
              <label>{{airportData.lighting}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Approach</label>
            </div>
            <div class="col-9">
              <label>{{airportData.approachList}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Airport of Entry</label>
            </div>
            <div class="col-9">
              <label>{{airportData.airportOfEntry}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Fuel Type</label>
            </div>
            <div class="col-9">
              <label>{{airportData.fuelType}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Distance From City</label>
            </div>
            <div class="col-9">
              <label>{{airportData.distanceFromCity}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Current UTC Offset</label>
            </div>
            <div class="col-9">
              <label>{{airportData.utcConversion}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Observes Daylight Savings</label>
            </div>
            <div class="col-9">
              <label>{{airportData.hasDaylightSavings}}</label>
            </div>
          </div>
          <div class="row" *ngIf="airportData.hasDaylightSavings=='Yes'">
            <div class="col-3">
              <label>DLS Start</label>
            </div>
            <div class="col-9">
              <label>{{airportData.dlsStart}}</label>
            </div>
          </div>
          <div class="row" *ngIf="airportData.hasDaylightSavings=='Yes'">
            <div class="col-3">
              <label>DLS End</label>
            </div>
            <div class="col-9">
              <label>{{airportData.dlsEnd}}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <label>Airport Type</label>
            </div>
            <div class="col-9">
              <label>{{airportData.airportType}}</label>
            </div>
          </div>
          <!--<div class="row">
        <div class="col-3">
          <label>Slots Required</label>
        </div>
        <div class="col-9">
          <label>{{airportData.requiresSlots}}</label>
        </div>
        </div>-->
          <div class="row">
            <div class="col-3">
              <label>Handling Required</label>
            </div>
            <div class="col-9">
              <label>{{airportData.requiresHandling}}</label>
            </div>
          </div>
          <!-- <div class="row">
          <div class="col-3">
            <label>Customs</label>
          </div>
          <div class="col-9">
            <label>{{airportData.customs}}</label>
          </div>
        </div> -->

        </div>
      </div>
    </div>
    <div style="margin: 0.2em; height: 0.2em; background-color: #e3e1e1; "></div>

    <!-- <div>
      <div style="color:#146ec3; font-size:  large;"><i class="material-icons" style="font-size:  large;">public </i>  Map</div>
      <div>
        <div *ngIf="airportData.latitude" style="width:30em;height:15em">
          <app-azure-map-inset [markerTitle]="icao" [defaultLat]="latitude" [defaultLon]="longitude">
          </app-azure-map-inset>
        </div>
      </div>
  </div> -->




  </div>

  <div   *ngIf="detailType=='vendor'" class="example-headers-align" multi>
    <ng-template ngFor let-element [ngForOf]="vendorRole" let-i="index">
      <div style="min-width:50em !important;max-width:65em !important; margin-bottom: 2em;" *ngIf="vendorRole!=null">
        <div   *ngIf="element.vendorRoleDescription!='Permit Assistance'">
          <mat-panel-title>
            <mat-icon style="color:#146ec3">{{element.iconName}}</mat-icon>&nbsp;
            <label style="color:#146ec3; font-size: large;  ">{{element.vendorRoleDescription}}</label>
          </mat-panel-title>
        </div>
        <ng-template *ngIf="element.vendorRoleDescription!='Fuel Supplier' && element.vendorRoleDescription!='Permit Assistance'" ngFor let-vendors [ngForOf]="allVendorData" let-i="index">
          <mat-accordion *ngIf="vendors.vendorRoleDescription == element.vendorRoleDescription" class="example-headers-align" multi>
            <mat-expansion-panel  [expanded]="openAccordions"   *ngIf="allVendorData!=null && allVendorData.length>0"  >
              <mat-expansion-panel-header>

                <mat-panel-title>
                  <label style="font-size:medium;font-weight:bold;color:#146ec3; width: max-content;">{{vendors.vendorName}}</label><i class="material-icons-two-tone blue" matTooltip="Preferred" *ngIf="vendors.isPreferred">grade</i>
                </mat-panel-title>
                <mat-panel-description>
                  <div>
                    <div *ngIf="vendors.groundHandler!=null && vendors.groundHandler!=''">
                      <label>G/H - {{vendors.groundHandler}}</label>
                    </div>
                    <div *ngIf="vendors.permitType!=null && vendors.permitType!='n/a'">
                      <label>{{vendors.permitType}}</label>
                    </div>
                  </div>
                  <div>
                    <button *ngIf="grantAccessToVendorProfiles" type="button" mat-button class="btn-low grid" (click)="clickEdit($event, vendors); ">EDIT</button>
                  </div>
                </mat-panel-description>

              </mat-expansion-panel-header>

              <div class="example-headers-align">
                <div *ngIf="vendors.vendorRoleID==1" style="margin-bottom: 2em;">
                  <div>
                    <div>
                      <label class="font-weight-bold" style="font-size: large;">FPI HANDLING NOTES</label>
                    </div>

                    <div *ngIf="vendors.serviceArrangement!=null" style="font-weight:bold;color:#cc0000;margin-bottom:.35em">Note: All inquiries and service requests should be sent to {{vendors.serviceArrangement}}.</div>
                  </div>
                  <!--<div *ngIf="userType=='internal'" class="d-inline-flex">
      <div>
        <mat-radio-group id="briefType" aria-label="Select an option" [(ngModel)]="briefType" [ngModelOptions]="{standalone: true}">
          <mat-radio-button style="transform:scale(.75)" color="primary" value="internal">Internal</mat-radio-button>
          <mat-radio-button style="transform:scale(.75)" color="primary" value="client">Client</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="vendors.serviceArrangement!=null" style="font-weight:bold;color:#cc0000;margin-bottom:.35em">Note: All inquiries and service requests should be sent to {{vendors.serviceArrangement}}.</div>
    </div>-->
                  <div class="d-flex justify-content-between font-small">
                    <div>
                      <label class="font-weight-bold">{{airportData.icao}} - {{vendors.vendorName}}</label>
                    </div>
                    <div>
                      <button class="btn btn-primary" style="font-size:x-small" (click)="generateHandlingNotes('pdf',vendors.vendorGUID)"><span *ngIf="loadingPDF" class="spinner-border spinner-border-sm mr-1"></span>PDF</button>
                      <button class="btn btn-primary ml-1" style="font-size:x-small" (click)="generateHandlingNotes('word',vendors.vendorGUID)"><span *ngIf="loadingWord" class="spinner-border spinner-border-sm mr-1"></span>Word</button>
                    </div>
                  </div>
                  <!--<div class="ml-2" *ngIf="vendors.relatedVendors && vendors.vendorRoleID==1">
      <div class="d-inline-flex" *ngIf="!(groundStopGUID!=null && groundStopGUID != undefinded)">
        <label for="related_vendors" style="font-size:small">SSN</label>
        <label class="ml-2" style="font-size:small" *ngIf="groundStopGUID!=null && groundStopGUID != undefinded">{{selectedSSNVendorName}}</label>
        <select *ngIf="!(groundStopGUID!=null && groundStopGUID != undefinded)" formControlName="related_vendors" class="ml-2 form-control form-control-sm dropdown" (change)="updateComms($event)">
          <option *ngIf="vendors.serviceArrangement == null || vendors.serviceArrangement.includes('Handler')">None</option>
          <option *ngFor="let ven of vendors.relatedVendors" [ngValue]="ven.vendorID">{{ven.vendorName}}{{ven.ratingID==4? ' **Primary':''}}</option>
        </select>
      </div>
    </div>
    <div *ngIf="userType=='internal'">
      <label>Payment Method: {{vendors.vendorMethodOfPaymentDescription}}</label>
    </div>
    <hr />-->
                  <div *ngIf="vendors.relatedVendorDetails && (vendors.relatedVendorDetails[0].vendorComms.length>0 || vendors.relatedVendorDetails[0].vendorPersons.length>0)">
                    <div><label class="font-weight-bold">Primary Contacts - {{vendors.relatedVendorDetails[0].vendorName}}</label></div>
                    <div>
                      <table width="100%">
                        <ng-template ngFor let-person [ngForOf]="vendors.relatedVendorDetails[0].vendorPersons" let-i="index">
                          <tr *ngIf="briefType=='internal' || (briefType=='client' && person.includeInBrief)">
                            <td width="20%">{{person.jobTitle}}</td>
                            <td>{{person.firstName}} {{person.lastName}}</td>
                          </tr>
                          <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
                            <tr *ngIf="briefType=='internal' || (briefType=='client' && comm.includeInBrief)"><td width="20%">{{person.jobTitle}} {{comm.commAddressType}}</td><td>{{comm.address}}</td></tr>
                          </ng-template>
                        </ng-template>
                        <ng-template *ngIf="vendors.relatedVendorDetails[0].vendorComms && vendors.relatedVendorDetails[0].vendorComms.length>0" ngFor let-comm [ngForOf]="vendors.relatedVendorDetails[0].vendorComms" let-i="index">
                          <tr><td width="20%">{{comm.commAddressType}}</td><td>{{comm.address}}</td></tr>
                        </ng-template>
                      </table>
                    </div>
                    <br />
                  </div>
                  <hr />
                  <div *ngIf="vendors.vendorComms!=null &&vendors.vendorComms.length>0" class="font-small">
                    <div><label class="font-weight-bold">Primary Contacts</label></div>
                    <div>
                      <table width="100%">
                        <ng-template ngFor let-person [ngForOf]="vendors.vendorPersons" let-i="index">
                          <tr>
                            <td>{{person.jobTitle}}</td>
                            <td>{{person.firstName}} {{person.lastName}}</td>
                          </tr>
                          <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
                            <tr><td width="20%">{{person.jobTitle}} {{comm.commAddressType}}</td><td>{{comm.address}}</td></tr>
                          </ng-template>
                        </ng-template>
                        <ng-template ngFor let-comm [ngForOf]="vendors.vendorComms" let-i="index">
                          <tr><td width="20%">{{comm.commAddressType}}</td><td>{{comm.address}}</td></tr>
                        </ng-template>
                      </table>
                    </div>
                    <br />
                  </div>
                  <div *ngIf="vendors.airportNotes!=notes && vendors.airportNotes.length>0" class="font-small">
                    <div>
                      <label class="font-weight-bold">{{vendors.airportNotes[0].groupName}}</label>
                    </div>
                    <div>
                      <table width="100%">
                        <ng-template ngFor let-note [ngForOf]="vendors.airportNotes" let-i="index">
                          <tr><td width="20%" valign="top">{{note.noteTypeDescription}}</td><td style="white-space:pre-wrap">{{note.note}}</td></tr>
                        </ng-template>
                      </table>
                    </div>
                    <br />
                  </div>
                  <div *ngIf="vendors.customsNotes!=notes && vendors.customsNotes.length>0" class="font-small">
                    <div>
                      <label class="font-weight-bold">{{vendors.customsNotes[0].groupName}}</label>
                    </div>
                    <div>
                      <table width="100%">
                        <ng-template ngFor let-note [ngForOf]="vendors.customsNotes" let-i="index">
                          <tr><td width="20%" valign="top">{{note.noteTypeDescription}}</td><td style="white-space:pre-wrap">{{note.note}}</td></tr>
                        </ng-template>
                      </table>
                    </div>
                    <br />
                  </div>
                  <div *ngIf="vendors.handlingNotes!=notes && vendors.handlingNotes.length>0" class="font-small">
                    <div>
                      <label class="font-weight-bold">{{vendors.handlingNotes[0].groupName}}</label>
                    </div>
                    <div>
                      <table width="100%">
                        <ng-template ngFor let-note [ngForOf]="vendors.handlingNotes" let-i="index">
                          <tr><td width="20%" valign="top">{{note.noteTypeDescription}}</td><td style="white-space:pre-wrap">{{note.note}}</td></tr>
                        </ng-template>
                      </table>
                    </div>
                  </div>
                </div>
                <div *ngIf="((vendors.vendorComms!=null && vendors.vendorComms.length>0) || (vendors.vendorPersons && vendors.vendorPersons.length>0))" style="margin-bottom: 2em;">
                  <div>
                    <div>
                      <label class="font-weight-bold" style="font-size: large;">COMMS</label>
                    </div>

                  </div>
                  <ng-template ngFor let-person [ngForOf]="vendors.vendorPersons" let-i="index">
                    <div class="row justify-content-end">
                      <div class="col-3 align-items-end font-weight-bold">
                        <label>{{person.jobTitle}}</label>
                      </div>
                      <div class="col-9">
                        <label>{{person.firstName}} {{person.lastName}}</label>
                      </div>
                    </div>
                    <ng-template ngFor let-comm [ngForOf]="person.personCommsAddress" let-i="index">
                      <div class="row justify-content-end">
                        <div class="col-3 align-items-end font-weight-bold">
                          <label>{{person.jobTitle}} {{comm.commAddressType}}</label>
                        </div>
                        <div class="col-9">
                          <label>{{comm.address}}</label>
                        </div>
                      </div>
                    </ng-template>
                  </ng-template>
                  <ng-template ngFor let-comm [ngForOf]="vendors.vendorComms" let-i="index">

                    <div class="row justify-content-end">
                      <div class="col-3 align-items-end font-weight-bold">
                        <label>{{comm.commAddressType}}</label>
                      </div>
                      <div class="col-5" style="word-wrap:break-word">
                        <a *ngIf="comm.commAddressType=='Weblink'" href="{{comm.address}}" target="_blank" rel="noopener noreferrer">{{comm.address}}</a>
                        <label *ngIf="comm.commAddressType!='Weblink'">{{comm.address}}</label>
                      </div>
                      <div class="col-4">
                        <label>{{comm.commRemarks}}</label>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div *ngIf="vendors.vendorNotes!=null && vendors.vendorNotes.length>0" style="margin-bottom:2em;">
                  <div>
                    <div>
                      <label class="font-weight-bold" style="font-size: large;">NOTES</label>
                    </div>
                  </div>
                  <ng-template ngFor let-note [ngForOf]="vendors.vendorNotes" let-i="index">
                    <div *ngIf="(vendors.vendorRoleID==1 && note.includeInBrief==false) || vendors.vendorRoleID!=1" class="row justify-content-end">
                      <div class="col-3 align-items-end font-weight-bold">
                        <label>{{note.noteTypeDesc}}</label>
                      </div>
                      <div class="col-9">
                        <label style="white-space:pre-wrap">{{note.vendorNote}}</label>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div *ngIf="vendors.vendorDocuments!=null && vendors.vendorDocuments.length>0">
                  <div>
                    <div>
                      <label class="font-weight-bold" style="font-size: large;">DOCUMENTS</label>
                    </div>
                  </div>
                  <div *ngIf="vendors.vendorDocuments!=null && vendors.vendorDocuments.length>0" class="row justify-content-end" style="padding-right: .5em;padding-top:.5em">
                    <div class="col-3 align-items-end font-weight-bold">
                      <label>Document Type</label>
                    </div>
                    <div class="col-5 align-items-end font-weight-bold">
                      <label>Document Name</label>
                    </div>
                    <div class="col-2 align-items-end font-weight-bold">
                      Effective
                    </div>
                    <div class="col-2 align-items-end font-weight-bold">
                      Expires
                    </div>
                    
                  </div>
                  <ng-template ngFor let-doc [ngForOf]="vendors.vendorDocuments" let-i="index">
                    <div class="row justify-content-end" style="padding-right: .5em">
                      <div class="col-3 align-items-end">
                        <label>{{doc.documentTypeDescription}}</label>
                      </div>
                      <div class="col-5 align-items-end">
                        <a class="link" style="white-space:pre-wrap;cursor:pointer" (click)="viewDocument(doc)">{{doc.documentName}}</a>
                      </div>
                      <div class="col-2 align-items-end">
                        <label>{{doc.effectiveDate}}</label>
                      </div>
                      <div class="col-2 align-items-end">
                        <label>{{doc.expirationDate}}</label>
                      </div>
                     
                    </div>
                  </ng-template>
                </div>
                <!--<div>
    <div>
      <div>
        <label class="font-weight-bold">OTHER</label>
      </div>
    </div>
    <div class="row">
      <div class="col-3 font-weight-bold">
        <label>Contract Required</label>
      </div>
      <div class="col-9">
        <label>{{vendors.contractRequired}}</label>
      </div>
    </div>
    <div *ngIf="vendors.contractRequired=='Yes'" class="row">
      <div class="col-3 font-weight-bold">
        <label>Contract Signed</label>
      </div>
      <div class="col-9">
        <label>{{vendors.contractSigned}}</label>
      </div>
    </div>-->
                <!--<div *ngIf="vendors.facilityDescription!='n/a'" class="row">
    <div class="col-3 font-weight-bold">
      <label>Facility Description</label>
    </div>
    <div class="col-9">
      <label>{{vendors.facilityDescription}}</label>
    </div>
  </div>-->
                <!--</div>-->

              </div>






            </mat-expansion-panel>
            
          </mat-accordion>

        </ng-template>

        <ng-template *ngIf="element.vendorRoleDescription=='Fuel Supplier'" ngFor let-fuel [ngForOf]="fuelSuppliers" let-i="index">
          <mat-accordion  class="example-headers-align" multi>
            <mat-expansion-panel   [expanded]="openAccordions"  *ngIf="allVendorData!=null && allVendorData.length>0"  >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <label style="font-size:larger;font-weight:bold;color:#146ec3">{{fuel.vendorName}}</label>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-template ngFor let-vendors [ngForOf]="allVendorData" let-i="index">
                <div *ngIf="vendors.vendorName == fuel.vendorName">
                  <div class="row">
                    <div class="col-3 font-weight-bold">
                      <label>Into Plane Agent</label>
                    </div>
                    <div class="col-9">
                      <label>{{vendors.intoPlaneAgent}}</label>
                    </div>
                  </div>

                </div>
              </ng-template>
              <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel *ngIf="fuel.vendorComms!=null && fuel.vendorComms.length>0">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <label class="font-weight-bold">COMMS</label>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template ngFor let-comm [ngForOf]="fuel.vendorComms" let-i="index">

                    <div class="row justify-content-end">
                      <div class="col-3 align-items-end font-weight-bold">
                        <label>{{comm.commAddressType}}</label>
                      </div>
                      <div class="col-5" style="word-wrap:break-word">
                        <a *ngIf="comm.commAddressType=='Weblink'" href="{{comm.address}}" target="_blank" rel="noopener noreferrer">{{comm.address}}</a>
                        <label *ngIf="comm.commAddressType!='Weblink'">{{comm.address}}</label>
                      </div>
                      <div class="col-4">
                        <label>{{comm.commRemarks}}</label>
                      </div>
                    </div>
                  </ng-template>
                </mat-expansion-panel>
                <mat-expansion-panel *ngIf="fuel.vendorNotes!=null && fuel.vendorNotes.length>0">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <label class="font-weight-bold">NOTES</label>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-template ngFor let-note [ngForOf]="fuel.vendorNotes" let-i="index">
                    <div class="row justify-content-end">
                      <div class="col-3 align-items-end font-weight-bold">
                        <label>{{note.noteTypeDesc}}</label>
                      </div>
                      <div class="col-9">
                        <label style="white-space:pre-wrap">{{note.vendorNote}}</label>
                      </div>
                    </div>
                  </ng-template>
                </mat-expansion-panel>

              </mat-accordion>
            </mat-expansion-panel>

          </mat-accordion>

        </ng-template>


      </div>
    </ng-template>
  </div>
  
  <div *ngIf="detailType=='country'" class="example-headers-align"  >

    <div >  
      <div style="color:#146ec3; font-size:   x-large;"><i class="material-icons" style="font-size:  large;">topic </i> Country Documents</div> 
      <div>  
        <div class="row"   style="display: flex; justify-content: space-between;">

          <div  class="col-3" class="row font-small">
            <div style="width: 12em;  margin-left: 2.3em;">
              <b style="white-space:pre-wrap">Type</b>
            </div> 
            
            <div style="width: 19em; margin-left: 1em;"   >

              <b style="white-space:pre-wrap"  >Name</b>
            </div> 
            
            <div style="width: 6em; margin-left: 1em;">
              <b style="white-space:pre-wrap"> Effective </b>
            </div>
            
            <div style="width: 6em; margin-left: 1em;">
              <b style="white-space:pre-wrap"> Expiration</b>
            </div>
          
          </div>
          <div>
            <button *ngIf="grantAccessToAirportDocs" type="button" mat-button class="btn-high grid" (click)="editCountryDocument(null)">ADD</button>
          </div>


        </div> 
        <div *ngFor = "let element of countryDocuments; let i=index"  >
          <div class="row font-small" style="display: flex; justify-content: space-between;">
            <div  class="col-3" class="row font-small"  [ngClass]="{ 'redColor': isDateExpired(element.expirationDate)  }" >
              <div style="width: 12em;  margin-left: 2.3em;">
                <label style="white-space:pre-wrap">{{element.documentTypeList}}</label>
              </div> 
              
              <div style="width: 19em; margin-left: 1em;"  >

                <a href="javascript:void(0)" (click)="getCountryDocuments(element)" matTooltipClass="tooltipLineBreak"
                matTooltip="{{ element.documentDescription }}">{{element.documentName}}</a>
              </div> 
              
              <div style="width: 6em; margin-left: 1em;">
                <label style="white-space:pre-wrap">{{element.effectiveDate}}</label>
              </div>
              
              <div style="width: 6em; margin-left: 1em;">
                <label style="white-space:pre-wrap">{{ element.expirationDate}}</label>
              </div>
            
            </div>
            <div>
              <button *ngIf="grantAccessToAirportDocs"  type="button" mat-button class="btn-low grid" (click)="editCountryDocument(element)">EDIT</button>
            </div> 
          </div>
        </div>
      </div>
    </div>
    <div style="margin: 0.2em; height: 0.2em; background-color: #e3e1e1; "></div>

    <div   style="min-width:50em !important;max-width:65em !important" *ngIf="vendorData!=null && vendorData.length>0">
      <div  >
        <!-- <div>
          <mat-icon style="color:#146ec3">confirmation_number</mat-icon>&nbsp;
          <label style="color:#146ec3">Permit Vendors</label>
        </div> -->
        <div style="color:#146ec3; font-size: x-large;"><i class="material-icons" style="font-size:  large;">confirmation_number </i>  Permit Vendors</div> 

      </div>

      <div class="example-headers-align" multi>
        <ng-template ngFor let-element [ngForOf]="vendorData" let-i="index">

          <div *ngIf="vendorData!=null && vendorData.length>0">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <div>
                  <b><label>{{element.vendorName}}</label><span *ngIf="isPreferred">*</span></b>
                </div>
                <div> {{element.permitType}} </div>
              </div>
              <div>
                <button *ngIf="grantAccessToVendorProfiles" type="button" mat-button class="btn-low grid" (click)="clickEdit($event, element); ">EDIT</button>
              </div>
            </div>
            <ng-template ngFor let-comm [ngForOf]="element.vendorComms" let-i="index">

              <div class="row justify-content-end font-small">
                <div class="col-3 align-items-end">
                  <label>{{comm.commAddressType}}</label>
                </div>
                <div class="col-5" style="word-wrap:break-word">
                  <a *ngIf="comm.commAddressType=='Weblink'" href="{{comm.address}}" target="_blank" rel="noopener noreferrer">{{comm.address}}</a>
                  <label *ngIf="comm.commAddressType!='Weblink'">{{comm.address}}</label>
                </div>
                <div class="col-4">
                  <label>{{comm.commRemarks}}</label>
                </div>
              </div>
            </ng-template>
            <ng-template ngFor let-note [ngForOf]="element.vendorNotes" let-i="index">
              <div class="row justify-content-end font-small">
                <div class="col-3 align-items-end">
                  <label>{{note.noteTypeDesc}}</label>
                </div>
                <div class="col-9">
                  <label style="white-space:pre-wrap">{{note.vendorNote}}</label>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </div>

    </div>

    <div *ngIf="vendorData!=null && vendorData.length>0" style="margin: 0.2em; height: 0.2em; background-color: #e3e1e1; "></div>

    <div>
      <div  >
        <!-- <div>
          <mat-icon style="color:#146ec3;">flag</mat-icon>&nbsp;
          <label style="color:#146ec3;">Country Details</label>
        </div> -->
        <div style="color:#146ec3; font-size:  x-large;"><i class="material-icons" style="font-size:  large;">flag </i>  Country Details</div> 

      </div>
      <div class="font-small">
        <div class="row">
          <div class="col-3">
            <label>ISO 2 Letter Code</label>
          </div>
          <div class="col-9">
            <label>{{countryData.isO2Letter}}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <label>ISO 3 Letter Code</label>
          </div>
          <div class="col-9">
            <label>{{countryData.isO3Letter}}</label>
          </div>
        </div>
        <div class="row" *ngIf="countryData.apisCountryCode!=countryData.isO3Letter">
          <div class="col-3">
            <label>APIS Country Code</label>
          </div>
          <div class="col-9">
            <label>{{countryData.apisCountryCode}}</label>
          </div>
        </div>
        <ng-template ngFor let-element [ngForOf]="registrationPrefix" let-i="index">
          <div class="row font-small">
            <div class="col-3">
              <label>Registration Prefix</label>
            </div>
            <div class="col-9">
              <label>{{element.registrationPrefix}}</label>
            </div>
          </div>
        </ng-template>

      </div>
    </div>


    <div style="margin: 0.2em; height: 0.2em; background-color: #e3e1e1; "></div>


    <ng-template ngFor let-element [ngForOf]="countryContentSegment" let-i="index">
      <div *ngIf="countryContent!=null">
        <div [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
          <!-- <div>
            <mat-icon style="color:#146ec3">{{element.icon}}</mat-icon>&nbsp;
            <label style="color:#146ec3">{{element.countryContentSegment}}</label>
          </div> -->
          <div style="color:#146ec3; font-size:  x-large;"><i class="material-icons" style="font-size:  large;">{{element.icon}} </i>  {{element.countryContentSegment}}</div> 

        </div>
        <!--<div class="col">
          <button type="button" mat-button class="btn-high grid" (click)="editCountryContent(element)">EDIT</button>
        </div>-->
        <ng-template ngFor let-note [ngForOf]="countryContent" let-i="index">
          <div *ngIf="note.countryContentSegment == element.countryContentSegment" class="row font-small">
            <div class="col-3">
              <label>{{note.countryContentCategory}}</label>
            </div>
            <div class="col-9">
              <label>{{note.countryContentDescription}}</label>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>

    <div  *ngIf="countryContent!=null" style="margin: 0.2em; height: 0.2em; background-color: #e3e1e1; "></div>

    <!-- <div>
      <div [collapsedHeight]="'2.5em'" [expandedHeight]="'2.5em'">
 
        <div style="color:#146ec3; font-size:  x-large;"><i class="material-icons" style="font-size:  large;">public </i> Map</div> 

      </div>
      <iframe width="400" height="180" id="gmap_canvas" [src]="countryMapLink | safeUrl" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
      </iframe>
    </div> -->
  </div>




  <div *ngIf="detailType=='businessRules'">
    <table *ngIf="businessRulesList && businessRulesList.length>0" mat-table [dataSource]="businessRulesList" class="example-container">
      <tr>
        <ng-container matColumnDef="brID">
          <th mat-header-cell *matHeaderCellDef width="5%">
            BR ID
          </th>
          <td mat-cell *matCellDef="let element">{{element.businessRulesID}}</td>
        </ng-container>
        <ng-container matColumnDef="customerName">
          <th mat-header-cell *matHeaderCellDef width="20%">
            Client
          </th>
          <td mat-cell *matCellDef="let element">{{element.customerName}}</td>
        </ng-container>
        <ng-container matColumnDef="registration">
          <th mat-header-cell *matHeaderCellDef width="10%">
            Registration
          </th>
          <td mat-cell *matCellDef="let element">{{element.registrationList}}</td>
        </ng-container>
        <ng-container matColumnDef="far_type">
          <th mat-header-cell *matHeaderCellDef width="10%">
            FAR Type
          </th>
          <td mat-cell *matCellDef="let element">{{element.farTypeDescription}}</td>
        </ng-container>
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef width="10%">
            Country
          </th>
          <td mat-cell *matCellDef="let element">{{element.countryName}}</td>
        </ng-container>
        <ng-container matColumnDef="icao">
          <th mat-header-cell *matHeaderCellDef width="5%">
            ICAO
          </th>
          <td mat-cell *matCellDef="let element">{{element.icao}}</td>
        </ng-container>
        <ng-container matColumnDef="service_class">
          <th mat-header-cell *matHeaderCellDef width="10%">
            Service Class
          </th>
          <td mat-cell *matCellDef="let element">{{element.serviceClassDescription}}</td>
        </ng-container>
        <ng-container matColumnDef="service_type">
          <th mat-header-cell *matHeaderCellDef width="10%">
            Service Type
          </th>
          <td mat-cell *matCellDef="let element">{{element.serviceTypeDescription}}</td>
        </ng-container>
        <ng-container matColumnDef="vendorName">
          <th mat-header-cell *matHeaderCellDef width="20%">
            Vendor
          </th>
          <td mat-cell *matCellDef="let element">{{element.vendorName}}</td>
        </ng-container>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
    </table>
  </div>

<div *ngIf="detailType=='map'" style="min-height: 10em;" >
 
  <div>  
    <div style="color:#146ec3; font-size:  large;"><i class="material-icons" style="font-size:  large;">public </i>  Map</div> 
    <div>   
      <div   style="width:44em;height:30em">
        <app-azure-map-inset [markerTitle]="icao" [defaultLat]="latitude" [defaultLon]="longitude" [airports]="airports">
        </app-azure-map-inset>
      </div>
    </div>
</div>
</div>

</div>



<mat-dialog-actions *ngIf="groundStopGUID!='' || showClose" align="left">
  <button mat-dialog-close mat-button class="btn-low">Close</button>
</mat-dialog-actions>

<app-spin *ngIf="isLoading"></app-spin>
