<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="d-flex justify-content-between">
    <h5>
      Validate Requirements
    </h5>
    <i class="material-symbols-outlined" mat-dialog-close>close</i>
  </div>
  <div *ngIf="garData" class="d-flex" style="font-size:small">
    <div style="margin-right:2em">
      Aircraft Registration {{garData[0].travel.routeID}}
    </div>
    <div style="margin-right:2em">
      <div>
        <div class="d-inline-flex">
          <div style="width:9.35em;margin-right:.5em">Departure Date/Time</div><div>{{garData[0].travel.departureDate}} {{garData[0].travel.departureTime}}</div>
        </div>
      </div>
      <div>
        <div class="d-inline-flex">
          <div style="width:9.35em;margin-right:.5em">Arrival Date/Time</div><div>{{garData[0].travel.arrivalDate}} {{garData[0].travel.arrivalTime}}</div>
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="d-inline-flex">
          <div style="width:6.75em;margin-right:.5em"> Departure Port</div><div>{{garData[0].travel.departurePort.icaoCode}}</div>
        </div>
      </div>
      <div>
        <div class="d-inline-flex">
          <div style="width:6.75em;margin-right:.5em"> Arrival Port</div><div>{{garData[0].travel.arrivalPort.icaoCode}}</div>
        </div>
      </div>
    </div>
  </div>
  <table mat-table [dataSource]="garData" class="example-container">
    <tr>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef width="5%">
          Type
        </th>
        <td mat-cell *matCellDef="let element">{{element.person.isCrew=='Y'? 'Crew':'Pax'}}</td>
      </ng-container>
      <ng-container matColumnDef="surname">
        <th mat-header-cell *matHeaderCellDef width="14%" style="text-align:left !important">
          Surname
        </th>
        <td mat-cell *matCellDef="let element" style="text-align:left !important" [ngClass]="{'alert-danger':element.person.surname==null || element.person.surname==''  }">{{element.person.surname}}</td>
      </ng-container>
      <ng-container matColumnDef="given_name">
        <th mat-header-cell *matHeaderCellDef width="20%" style="text-align:left !important">
          Given Name
        </th>
        <td mat-cell *matCellDef="let element" style="text-align:left !important" [ngClass]="{'alert-danger':element.person.firstName==null || element.person.firstName==''  }">{{element.person.firstName}} {{element.person.middleName}}</td>
      </ng-container>
      <ng-container matColumnDef="dob">
        <th mat-header-cell *matHeaderCellDef width="10%">
          DOB
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger':element.person.dateOfBirth==null || element.person.dateOfBirth==''  }">{{element.person.dateOfBirth}}</td>
      </ng-container>
      <ng-container matColumnDef="nationality">
        <th mat-header-cell *matHeaderCellDef width="6%">
          Nationality
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger':element.person.nationality==null || element.person.nationality==''  }">{{element.person.nationality}}</td>
      </ng-container>
      <ng-container matColumnDef="sex">
        <th mat-header-cell *matHeaderCellDef width="6%">
          Sex
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger':element.person.sex==null || element.person.sex==''  }">{{element.person.sex}}</td>
      </ng-container>
      <ng-container matColumnDef="document_type">
        <th mat-header-cell *matHeaderCellDef width="6%">
          Document Type Code
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger':element.document?.documentType==null }">{{element.document? element.document.documentType : ''}}</td>
      </ng-container>
      <ng-container matColumnDef="document_number">
        <th mat-header-cell *matHeaderCellDef width="10%">
          Document Number
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger':element.document?.documentNumber==null }">{{element.document? element.document.documentNumber : ''}}</td>
      </ng-container>
      <ng-container matColumnDef="document_country">
        <th mat-header-cell *matHeaderCellDef width="6%">
          Document Country
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger':element.document?.countryOfIssue==null }">{{element.document? element.document.countryOfIssue : ''}}</td>
      </ng-container>
      <ng-container matColumnDef="document_expiration">
        <th mat-header-cell *matHeaderCellDef width="10%">
          Document Expiration
        </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'alert-danger':element.document?.documentExpirationDate==null || element.document?.documentExpirationDate < element.travel.departureDate || element.document?.documentExpirationDate < element.travel.arrivalDate }">{{element.document? element.document.documentExpirationDate : ''}}</td>
      </ng-container>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumn"></tr>
  </table>  
  <div style="width:100%" class="mt-2 d-flex justify-content-between">
    <div class="d-inline-flex">
    <div>
      <button mat-button mat-dialog-close class="btn-low" style="background-color:grey; color: white;">
        <div style="display:flex">
          <i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
          <div>Close</div>
        </div>
      </button>
    </div>

    <div style="display:flex;margin-top:.25em;margin-left:1.5em">
      <div class="alert-danger" style="width:2em;height:1.25em;margin-right:.25em"></div>
      <div style="font-size:small;font-style:italic">Missing Information or Expired Document</div>
    </div>
  </div>
      <div  *ngIf="canSubmit" style="float:right">
        <button mat-button (click)="submitGar()" class="btn-high" style="background-color:grey; color: white;">
          <div style="display:flex">
            <span class="material-symbols-outlined align-self-center mr-1">
              control_camera
            </span>
            <div>Submit UK GAR With Missing Information</div>
          </div>
        </button>
      </div>
    
  </div>
</div>
