import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { VendorModel } from '../models/vendor.model';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { VendorService } from '../services/vendor.service';
import { VendorDetailsComponent } from '../vendors/vendor-details.component';


@Component({
  selector: 'app-vendor-selection',
  templateUrl: './vendor-selection.component.html',
  styleUrls: ['./vendor-selection.component.css']
})
export class VendorSelectionComponent implements OnInit {

  airportID: number;
  icao: string;
  title: string;
  handler: string;
  ssn: string;
  vendorList: VendorModel[];
  userType: string;
  displayColumns: string[] = ['handler', 'ssn', 'payment', 'action'];
  constructor(private _dialogRef: MatDialogRef<VendorSelectionComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _vendorService: VendorService, private readonly _authService: AuthenticateService, private readonly _dialog: MatDialog,) {
    this.airportID = _data.airportID;
    this.icao = _data.icao;
    this.title = _data.title;
    this.handler = _data.handler;
    this.ssn = _data.ssn;
  }

  ngOnInit() {
    this.userType = localStorage.getItem('ut').toLowerCase();
    this._authService.updateAccessTime();
    this._vendorService.getHandlerAndSSNByAirportID<ResponseModel<VendorModel[]>>(this.airportID).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          this.vendorList = response.model;
          if (this.userType == "c") {
            this.vendorList = this.vendorList.filter(x => (x.vendorGUID == this.handler && (x.relatedVendors != null && x.relatedVendors[0].vendorGUID == this.ssn || x.relatedVendors == null && this.ssn == null))
              || (x.isPreferred && (x.relatedVendors == null || x.relatedVendors[0].isPreferred)) || x.isClientPreference && (x.relatedVendors == null || x.relatedVendors[0].isClientPreference));
          }
        }
        else {
          if (response.code == "401")
            this._authService.logout();
        }
      }
    });
   
  }

  updateVendors(vendor: VendorModel) {
    this._dialogRef.close(vendor);
  }

  openVendorDetails(vendorGUID: string) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.disableClose = true;
    dialogConfig.panelClass = "custom-dialog-container";
    //    dialogConfig.maxWidth = "70em";
    dialogConfig.data = {
      vendorId: vendorGUID,
      icao: this.icao
    };
    this._dialog.open(VendorDetailsComponent, dialogConfig);

  }

  
}
