import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { GroundStopService } from '../services/ground-stop.service';
import { CriticalAlertsModel } from '../models/critical-alerts.model';
import { ResponseModel } from '../models/response.model';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-critical-alerts',
  templateUrl: './critical-alerts.component.html',
  styleUrls: ['./critical-alerts.component.css']
})

export class CriticalAlertsComponent implements OnInit {

  critical_alerts_form: UntypedFormGroup;
  isDialog: boolean = false;

  timeFilter: string = '90';
  errMsg: string = null;
  showSpin: boolean = false;
  totalRecordsText: string = null;
  recordList: CriticalAlertsModel[] = [];


  displayedColumn: string[] = ['customer_name', 'trip_code', 'service', 'depIcao', 'depDate', 'arrIcao', 'arrDate', 'open'];
  downloading: boolean = false;

  constructor(@Optional() private readonly _dialogRef: MatDialogRef<CriticalAlertsComponent>, private readonly _commonService: CommonService,
  @Optional() @Inject(MAT_DIALOG_DATA) private data: any,  private readonly _authService: AuthenticateService, 
    private readonly _formBuilder: UntypedFormBuilder,  private readonly _groundStopService: GroundStopService,
    ) {
   
      if (this.data) {
        this.isDialog = true;
        // this.username = this.data.username;
      }  
   
  }

  ngOnInit() {

    // this._authService.updateAccessTime();

    this.initControls();
    this.getData();

   }

  initControls() {
    this.critical_alerts_form = this._formBuilder.group({
      date_type: [this.timeFilter]
    });


  }

  get f() { return this.critical_alerts_form.controls; }


 

  clickClose() {
    this._dialogRef.close(false); 
  }


  getData() {
    this._authService.updateAccessTime();
    this.showSpin = true;
    this.totalRecordsText = "";
    let request = new CriticalAlertsModel();
    request.timeFilter = this.f.date_type.value;
    

    this._groundStopService.getMissingTasksForCriticalAlerts<ResponseModel<CriticalAlertsModel[]>>(request).subscribe(response => {
      this.recordList = [];
      this.totalRecordsText = "";
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          this.recordList = response.model;
          

        }
        else {
          this.totalRecordsText = "No record found";
        }
        this.showSpin = false;

      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get Data at this time. Please try later.";
        }
      }
      this.showSpin = false;
    });
  }


  dateTypeChange(e: any) {
    this.getData();
  }



  clickOpenTrip(e: any, item: CriticalAlertsModel) {
    e.stopPropagation();  

    window.open('/ground-stops/trip-details-internal?customerId=' + item.customerGUID + "&tripId=" + item.tripCodeGUID + "&aircraftId=" + item.aircraftGUID + "&gsId=" + item.groundStopGUID + "&gstId=" + item.groundStopTaskGUID + "&pf=&v=" + this._authService.getCurrentTimeNumber(), '_blank');

  }


  exportToExcel() {
    let request = new CriticalAlertsModel();
    request.timeFilter = this.f.date_type.value;

    this.downloading = true;

    this._groundStopService.getMissingTasksForCriticalAlerts<ResponseModel<CriticalAlertsModel[]>>(request).subscribe(response => {
      // this.recordList = [];
      this.totalRecordsText = "";
      if (response != null && response.code == "200") {
        if (response.model.length > 0) {
          let tempRecordList = response.model;

          let wb = new Workbook();
          let ws = wb.addWorksheet("Missing Services")
          let header = ['Customer Name', 'Trip Code', 'Missing Service', 'Departure ICAO', 'Departure Date', 'Arrival ICAO', 'Arrival Date' ];

          // let header = ["Country Name", "ICAO", "IATA", "Fuel Quote Count", "Fuel Release Count", "Registration Count"]
          ws.addRow(header);
          tempRecordList.forEach(al => {

            ws.addRow([al.customerName, al.tripCode, al.serviceTypeDescription, al.departureIcao, al.departureDateUTC, al.arrivalIcao, al.arrivalDateUTC]);
          })

          wb.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, "Missing Services.xlsx");
          });

          
        }
        else {
          this.totalRecordsText = "No record found";
        }
        this.downloading = false;

      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get Data at this time. Please try later.";
        }
      }
      this.downloading = false;
    });

 
  }


}

 