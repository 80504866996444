import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AlertDialogComponent } from '../common-utility/alert-dialog.component';
import { DialogService } from '../services/dialog.service';
import { GroundStopModel } from '../models/ground-stop.model';
import { AirportService } from '../services/airport.service';
import { AirportModel } from '../models/airport.model';
import { DateTimeObjModel } from '../models/dateTimeObj.model';
import { DistanceTimeModel } from '../models/distance-time.model';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { TripModel } from '../models/trip.model';
import { GroundStopService } from '../services/ground-stop.service';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { FARTypeModel } from '../models/far-type.model';
import { TripLegModel } from '../models/trip-leg.model';
import { AircraftTripModel } from '../models/aircraft-trip.model';
import { GroundStopClientService } from '../services/ground-stop-client.service';
import { TripLegService } from '../services/trip-leg.service';
import { LegInsertDialogComponent } from './leg-insert-dialog.component';
import { CancelAirportDialogComponent } from '../ground-stops/cancel-airport-dialog.component';
import { Arrival_DepartureStatusModel } from '../models/arrival_departure-status.model';
import { ManageContingencyDialogComponent } from '../ground-stops/manage-contingency-dialog.component';
import { ContingencyStopComponent } from '../ground-stops/contingency-stop.component';
import { RouteSequenceDialogComponent } from './route-sequence-dialog.component';
import { DatePipe } from '@angular/common';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-leglist-editor-dialog',
  templateUrl: './leglist-editor-dialog.component.html',
  styleUrls: ['./leglist-editor-dialog.component.css']
})

export class LeglistEditorDialogComponent implements OnInit, AfterViewInit {
  
  dialogTitle: string;
  customerGUID: string;
  aircraftGUID: string;
  tripCodeGUID: string;
  currentIndex: number;
  leglist_editor_form: UntypedFormGroup;
  loading = false;

  submitted = false;
  errMsg: string;
  errMsg2: string;
  duplicateIATAErrMsg: string;
  msg: string;

  showSpin: boolean = false;
  userType: string;
  client_name: string;
  registration: string;
  trip_code: string;
  speed: number;
  route: string;
  isModified: boolean = false;
  loadingAirport: boolean = false;
  existingGroundStopList: GroundStopModel[]=[];
  groundStopList: GroundStopModel[] = [];
  airportList: AirportModel[];
  
  validatedAirport: boolean = false;
  showSuccessMsg: boolean = false;

  displayedColumn: string[] = ['leg_num', 'on_hold', 'dept', 'arrv', 'techStop', 'contingency', 'departureDate_Time', 'departure_status', 'arrivalDate_Time', 'arrival_status',
    'ete', 'eteChanged','crew_count', 'pax_count', 'ferry_flight', 'farType', 'callSign', 'pets', 'weapons', 'action'];
  displayedColumn2: string[] = ['leginfo', 'departure_arrival', 'other']; // 9, 2,3
  displayedColumn3: string[] = ['leg', 'hold', 'h1', 'tech','h2', 'departure','arrival', 'h3','ferry', 'h4']; // 9, 2,3

  @ViewChild('routeRef', { static: false }) routeRef: ElementRef;
  private confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  farTypeList: FARTypeModel[];
  lockdownTrip: boolean = false;
  cancellationGroundStopTypeGUID = "a2c7bf22-d054-4564-949b-f0d55d1f6b10";
  contingencyGroundStopTypeGUID = "9523bc15-7e5f-4022-b5c6-574f7ea63649";
  legList: TripLegModel[] = [];
  existingLegList: TripLegModel[] = [];
  displayLegList: TripLegModel[] = [];
  showWholeRoute: boolean = false;
  time_type: string;
  minDate: Date = new Date();
  dataChanged: boolean;
  adStatusList: Arrival_DepartureStatusModel[];
  upPII: boolean = false;
  origDisplayLegList: TripLegModel[] = [];
  isFirstAssignment: boolean = true;

  constructor(private readonly _dialogRef: MatDialogRef<LeglistEditorDialogComponent>, @Inject(MAT_DIALOG_DATA) private _data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _airportService: AirportService,  private readonly _groundStopService: GroundStopService,
    private readonly _groundStopClientService: GroundStopClientService, private readonly _dialogService: DialogService,
    private readonly _tripLegService: TripLegService, private readonly _dialog: MatDialog
  ) {

    if (_data.customerGUID == null || _data.customerGUID == undefined) {
      this.customerGUID = "";
    }
    else {
      this.customerGUID = _data.customerGUID;
    }    
     
    this.tripCodeGUID = _data.tripCodeGUID;
    this.aircraftGUID = _data.aircraftGUID;
    this.dialogTitle = _data.dialogTitle;
    this.currentIndex = _data.currentIndex;

  }

  getAllData(): Observable<any[]> {
    let request = new AircraftTripModel();   
    request.customerGUID = this.customerGUID;    
    request.tripCodeGUID = this.tripCodeGUID;
    let getTripResponse = this._groundStopClientService.getTripBytId(request);    
    let getFARListResponse = this._groundStopService.getFARTypeList();
    let getStatusListResponse = this._groundStopService.getArrivalDepartureStatusList();
    return forkJoin([getTripResponse, getFARListResponse, getStatusListResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.errMsg2 = "";
    this.duplicateIATAErrMsg = "";
    this.msg = "";
    if (localStorage.getItem('up.pii') != 'true') {
      this.upPII = false;      
    }
    else {
      this.upPII = true;
    }

    if (!this.upPII) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this.clickClose();
      return;
    }
    this.route = "";

    this.client_name = "";
    this.registration = "";
    this.trip_code = "";
    //this.trip_status = "";
    //this.tripCodeType = "";
    this.existingGroundStopList = [];
    this.groundStopList = [];
    this.airportList = [];
    this.validatedAirport = false; 
    //this.activeGroundStopList = [];
    this.showSuccessMsg = false;
    this.farTypeList = [];
    this.lockdownTrip = false;
    this.legList=[];
    this.time_type = "utc";
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].model != null) {
          let t = new TripModel();
          t = responses[0].model;       
          this.existingGroundStopList = t.groundStopList;
          this.lockdownTrip = t.lockdownTrip;       
          this.trip_code = t.tripCode;
          //this.tripCodeType = t.tripCodeType;
          this.speed = t.speed;
          this.registration = t.registration;
          this.client_name = t.customerName;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }    

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.farTypeList = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null) {
        if (responses[2].code == "200" && responses[2].message == "") {
          this.adStatusList = responses[2].model;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      this.initControls();
      this.getLegList();
      //this.showSpin = false;

    });


  }

  initControls() {
    if (this.customerGUID != "") {
      this.customerGUID = this.customerGUID.toLowerCase();
    }
    this.leglist_editor_form = this._formBuilder.group({
      route: [this.route, Validators.compose([       
        Validators.pattern("^[-a-zA-Z0-9,\* \n\r]*$")]
      )],
      time_type: [this.time_type]

    });


  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //  this.routeRef.nativeElement.focus();
    //}, 500);
  }

  get f() { return this.leglist_editor_form.controls; }

  getLegList() {
    this._authService.updateAccessTime();
    this.showSpin = true;
    let request = new TripModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.customerGUID = this.customerGUID;
    request.aircraftGUID = this.aircraftGUID;
    request.groundStopTypeGUIDList = "";
    request.includeActiveStopOnly = false;
    request.isFromLegEdit = true;
    this._tripLegService.getGroundStopLegListByConditions<ResponseModel<TripLegModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.legList = [];
        this.existingLegList = [];
        
        if (response.model.length > 0) {
          this.legList = response.model;
          let leg :TripLegModel;
          this.legList.forEach(x => {
            x.isValidArrDate = true;
            x.isValidArrTime = true;
            x.isValidDeptDate = true;
            x.isValidDeptTime = true;
            leg = new TripLegModel();          
            leg = JSON.parse(JSON.stringify(x));
            this.existingLegList.push(leg);
          });
          this.setDisplayLeg();

          if (this.isFirstAssignment) {
            // this.origDisplayLegList = this.displayLegList;
            this.origDisplayLegList = cloneDeep(this.displayLegList);
            this.isFirstAssignment = false
          }
          
        }
        else {          
        }
        
      }
      else {
        if (response.code == "401") {

          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  setDisplayLeg() {
    this.displayLegList = [];
    this.displayLegList = this.legList.filter(x => x.departureGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID && x.nextArrivalGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID
      );

    //this.displayLegList = this.legList.filter(x => x.departureGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID && x.nextArrivalGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID
    //  && x.departureGroundStopTypeGUID.toLowerCase() != this.contingencyGroundStopTypeGUID && x.nextArrivalGroundStopTypeGUID.toLowerCase() != this.contingencyGroundStopTypeGUID);
    let i: number = 0;
    this.displayLegList.forEach(x => {
      i++;
      x.displayTripLegOrder = i;
      if (!x.isDepartureEditable && x.departureGroundStopTypeGUID != this.contingencyGroundStopTypeGUID) {
        x.departureDateUTC_DTType = null;
        x.departureDateLocal_DTType = null;
        x.departureTimeUTC = null;
        x.departureTimeLocal = null;
      }
      if (!x.isNextArrivalEditable && x.nextArrivalGroundStopTypeGUID != this.contingencyGroundStopTypeGUID) {
        x.nextArrivalDateUTC_DTType = null;
        x.nextArrivalDateLocal_DTType = null;
        x.nextArrivalTimeUTC = null;
        x.nextArrivalTimeLocal = null;
      }
    })
  }

  getTripBytId() {
    this._authService.updateAccessTime();
    let request = new AircraftTripModel();
    request.tripCodeGUID = this.tripCodeGUID;
    request.customerGUID = this.customerGUID;
    this._groundStopClientService.getTripBytId<ResponseModel<TripModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          let t = new TripModel();
          t = response.model;
          this.existingGroundStopList = t.groundStopList;
          this.lockdownTrip = t.lockdownTrip;
          this.trip_code = t.tripCode;
          //this.tripCodeType = t.tripCodeType;
          this.speed = t.speed;
          this.registration = t.registration;
          this.client_name = t.customerName;
        }
        else {

        }
        this.showSpin = false;

      }
      else {
        if (response.code == "401") {
          if (response.message == "")
            this._authService.signOut();
          else
            this.errMsg = response.message;
        }
        else {
          this.errMsg = "Failed to get trips at this time. Please try later.";
        }
      }
      this.showSpin = false;
    })

  }

  timeTypeChange(e: any) {
    this.time_type = e.target.value;
    let x = 0;
  }

  clickValidateAirport() {
    this.errMsg = "";
    this.duplicateIATAErrMsg = "";
    this.loadingAirport = true;
    this.validatedAirport = true; 

    let request = new AirportModel();

    request.route = this.f.route.value;
    this._airportService.validateAirport<ResponseModel<AirportModel[]>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.airportList = [];
        this.airportList = response.model;
        var activeLegList: TripLegModel[];
        activeLegList = this.existingLegList.filter(x => x.departureGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID && x.departureGroundStopTypeGUID.toLowerCase() != this.contingencyGroundStopTypeGUID
          && x.nextArrivalGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID && x.nextArrivalGroundStopTypeGUID.toLowerCase() != this.contingencyGroundStopTypeGUID);
        
        if (activeLegList.length == 0 && this.airportList.length == 1) {
          this.f.route.setErrors({ wrongAirport: true });
          this.errMsg = "Please enter at lease two airports";
          this.loadingAirport = false;
          return;
        }
        if (this.airportList[0].wrongAirportErrMsg == "" && this.airportList[0].duplicateIATAErrMsg == "") {
          var data: TripLegModel[] = [];;
          let y: TripLegModel;  
          this.existingLegList.forEach(x => {
            y = new TripLegModel();
            y = JSON.parse(JSON.stringify(x));
            data.push(y);
          });
          
          this.legList = [];
          
          let re = /,/gi;
          this.f.route.setValue(Array.prototype.map.call(this.airportList, s => s.icao).toString().replace(re, "-"));
          
          let lastActiveLeg:TripLegModel=null;
          let lastLeg :TripLegModel=null;
          for (var i = data.length - 1; i >= 0; i--) {
            if (data[i].departureGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID && data[i].departureGroundStopTypeGUID.toLowerCase() != this.contingencyGroundStopTypeGUID && data[i].nextArrivalGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID && data[i].nextArrivalGroundStopTypeGUID.toLowerCase() != this.contingencyGroundStopTypeGUID) {
              lastActiveLeg = new TripLegModel();
              lastActiveLeg= data[i];
              break;
            }
          }
          lastLeg = data[data.length - 1];
          let startAirport = new AirportModel();
          startAirport.icao = "";
          startAirport.iata = "";
          startAirport.latitude = null;
          startAirport.longitude = null;
          let gsId: string = "";
          let gsTypeGUID: string = "";
          let gsTypeColor: string = "";
          if (lastActiveLeg != null) {
            startAirport.icao = lastActiveLeg.nextArrivalICAO;
            startAirport.iata = lastActiveLeg.nextArrivalIATA;
            startAirport.latitude = lastActiveLeg.nextArrivalLatitude;
            startAirport.longitude = lastActiveLeg.nextArrivalLongitude;
            gsId = lastActiveLeg.nextArrivalGroundStopGUID;
            gsTypeGUID = lastActiveLeg.nextArrivalGroundStopTypeGUID;
            gsTypeColor = lastActiveLeg.nextArrivalGroundStopTypeColor;
          }
          else {
            startAirport.icao = lastLeg.nextArrivalICAO;
            startAirport.iata = lastLeg.nextArrivalIATA;
            startAirport.latitude = lastLeg.nextArrivalLatitude;
            startAirport.longitude = lastLeg.nextArrivalLongitude;
            gsId = lastLeg.nextArrivalGroundStopGUID;
            gsTypeGUID = lastLeg.nextArrivalGroundStopTypeGUID;
            gsTypeColor = lastLeg.nextArrivalGroundStopTypeColor;
          }
                  
          let gsSeq: number = lastLeg.nextArrivalGroundStopSequenceID;
          let legOrder: number = lastLeg.tripLegOrder;
          
          this.airportList.forEach((x, index) => {
            let objLeg = new TripLegModel();            
            objLeg.departureICAO = startAirport.icao;
            objLeg.departureIATA = startAirport.iata;
            objLeg.departureLatitude = startAirport.latitude;
            objLeg.departureLongitude = startAirport.longitude;
            objLeg.departureDateUTC = "";
            objLeg.departureDateUTC_DTType = null;
            objLeg.departureTimeUTC = "";
            objLeg.departureDateLocal = "";
            objLeg.departureDateLocal_DTType = null;
            objLeg.departureTimeLocal = "";
            objLeg.departureUTCTimeConversion = "";
            objLeg.groundStopSequenceID = gsSeq;
            objLeg.groundStopGUID = gsId;
            objLeg.departureGroundStopTypeGUID = gsTypeGUID;
            objLeg.departureGroundStopTypeColor = gsTypeColor;
            gsSeq += 1;
            objLeg.nextArrivalICAO = x.icao;
            objLeg.nextArrivalIATA = x.iata;
            objLeg.nextArrivalLatitude = x.latitude;
            objLeg.nextArrivalLongitude = x.longitude;
            objLeg.nextArrivalDateUTC = "";
            objLeg.nextArrivalDateUTC_DTType = null;
            objLeg.nextArrivalTimeUTC = "";
            objLeg.nextArrivalDateLocal = "";
            objLeg.nextArrivalDateLocal_DTType = null;
            objLeg.nextArrivalTimeLocal = "";
            objLeg.nextArrivalUTCTimeConversion = "";
            objLeg.nextArrivalGroundStopSequenceID = gsSeq;
            objLeg.nextArrivalGroundStopGUID = "";
            if (gsTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID && gsTypeGUID.toLowerCase() != this.contingencyGroundStopTypeGUID) {
              objLeg.nextArrivalGroundStopTypeGUID = gsTypeGUID;
              objLeg.nextArrivalGroundStopTypeColor = gsTypeColor;
            }
            else {
              gsTypeGUID = "";
              gsTypeColor = "";
              objLeg.nextArrivalGroundStopTypeGUID = gsTypeGUID;
              objLeg.nextArrivalGroundStopTypeColor = gsTypeColor;
            }
            objLeg.isValidArrDate = true;
            objLeg.isValidArrTime = true;
            objLeg.isValidDeptDate = true;
            objLeg.isValidDeptTime = true;
            objLeg.arrReadOnly = false;
            objLeg.depReadOnly = false;
            objLeg.depStartAt = new Date();
            objLeg.arrStartAt = new Date();
            legOrder += 1;
            objLeg.tripLegOrder = legOrder
            objLeg.cancelled = false;
            objLeg.departureFARTypeID = 0;
            if (objLeg.departureGroundStopTypeGUID != "" && objLeg.departureGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID) {
              objLeg.cancelled = true;
            }
            gsId = "";
            gsTypeGUID = gsTypeGUID;
            gsTypeColor = gsTypeColor;
            data.push(objLeg);
            startAirport = x;            
            
          });

          data.forEach(x => {
            this.legList.push(x);
          })
          this.setDisplayLeg();
          this.validatedAirport = false;
          this.dataChanged = true;
        }
        
        if (this.airportList[0].wrongAirportErrMsg != "") {
          this.errMsg = "Invalid airports: " + this.airportList[0].wrongAirportErrMsg;
          this.f.route.setErrors({ wrongAirport: true});
          this.validatedAirport = true;
        }
        if (this.airportList[0].duplicateIATAErrMsg != "") {
          this.duplicateIATAErrMsg = "Duplicated IATAs: " + this.airportList[0].duplicateIATAErrMsg + "\n";
          this.duplicateIATAErrMsg += "Please change to use ICAO.";
          this.f.route.setErrors({ duplicateIATA: true });
          this.validatedAirport = true;
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to validate airport. Please try again later.";
          this.loadingAirport = false;
        }
      }
      this.loadingAirport = false;
    })

  }

  //dateInputOnchange(dateType: string, e: any, item: GroundStopModel) {
  //  if (dateType == "A") {
  //    item.arrivalDateUTC = null;

  //  }
  //  if (dateType == "D") {
  //    item.departureDateUTC = null;

  //  }
  //}

  dateInputOnchange(dateType: string, e: any, item: TripLegModel, index: number = null) {
    if (dateType == "A") {
      item.nextArrivalDateUTC_DTType = null;
      item.nextArrivalDateLocal_DTType = null;
    }
    if (dateType == "D") {
      item.departureDateUTC_DTType = null;
      item.departureDateLocal_DTType = null;
    }
    // this.checkWhetherEteIsDisabled(this.displayLegList[index], this.origDisplayLegList[index], index);

  }


  dateOnchange(dateType: string, e: any, item: TripLegModel, index: number = null) {
    this.errMsg2 = "";
    this.dataChanged = true;
    if (this.time_type == 'utc') {
      if (dateType == "D") {
        item.departureDateLocal = "";
        item.departureDateLocal_DTType = null;
        item.departureTimeLocal = "";
        if (e.target._elementRef.nativeElement.value != "") {
          if (e.target.value == null) {
            item.isValidDeptDate = false;
            item.departureDateUTC = e.target._elementRef.nativeElement.value;
          }
          else {
            if (new Date(e.target.value).getUTCFullYear() >= (new Date().getUTCFullYear()-1)) {
              item.isValidDeptDate = true;
              item.departureDateUTC_DTType = e.target.value;
              item.departureDateUTC = CustomValidators.formatDateToMMDDYYYY(e.target.value);
              this.getAirportUTCTimeConversion(item, "D", this.time_type).subscribe(res1 => {
                if (res1 == true) {
                  this.calculateArrivalDateTime(item);
                  item.depStartAt = new Date(item.departureDateUTC);
                  this.legList.forEach((x, index) => {
                    if (x.tripLegOrder >= item.tripLegOrder) {
                      x.arrStartAt = item.depStartAt;
                      x.depStartAt = item.depStartAt;
                    }
                  });
                }
              });
            }
            else {
              item.isValidDeptDate = false;
              item.departureDateUTC = e.target._elementRef.nativeElement.value;
            }            
          }
        }
        else {
          item.isValidDeptDate = true;
          item.departureDateUTC = "";
          item.departureDateUTC_DTType = null;
        }
      }

      if (dateType == "A") {
        item.nextArrivalDateLocal = "";
        item.nextArrivalDateLocal_DTType = null;
        item.nextArrivalTimeLocal = "";
        if (e.target._elementRef.nativeElement.value != "") {
          if (e.target.value == null) {
            item.isValidArrDate = false;
            item.nextArrivalDateUTC = e.target._elementRef.nativeElement.value;
          }
          else {
            if (new Date(e.target.value).getUTCFullYear() >= (new Date().getUTCFullYear() - 1)) {
              item.isValidArrDate = true;
              item.nextArrivalDateUTC_DTType = e.target.value;
              item.nextArrivalDateUTC = CustomValidators.formatDateToMMDDYYYY(e.target.value);
              this.getAirportUTCTimeConversion(item, "A", this.time_type).subscribe(res1 => {
                if (res1 == true) {
                  item.arrStartAt = new Date(item.nextArrivalDateUTC);
                  this.calculateDepartureDateTime(item);
                  this.legList.forEach((x, index) => {
                    if (x.tripLegOrder >= item.tripLegOrder) {
                      x.depStartAt = item.arrStartAt;
                      x.arrStartAt = item.arrStartAt;
                    }

                  });
                }
              });
            }
            else {
              item.isValidArrDate = false;
              item.nextArrivalDateUTC = e.target._elementRef.nativeElement.value;
            }
          }
        }
        else {
          item.isValidArrDate = true;
          item.nextArrivalDateUTC = "";
          item.nextArrivalDateUTC_DTType = null;
        }
      }
    }
    else {
      if (dateType == "D") {
        item.departureDateUTC = "";
        item.departureDateUTC_DTType = null;
        item.departureTimeUTC = "";
        if (e.target._elementRef.nativeElement.value != "") {
          if (e.target.value == null) {
            item.isValidDeptDate = false;
            item.departureDateLocal = e.target._elementRef.nativeElement.value;
          }
          else {
            if (new Date(e.target.value).getUTCFullYear() >= (new Date().getUTCFullYear() - 1)) {
              item.isValidDeptDate = true;
              item.departureDateLocal_DTType = e.target.value;
              item.departureDateLocal = CustomValidators.formatDateToMMDDYYYY(e.target.value);
              this.getAirportUTCTimeConversion(item, "D", this.time_type).subscribe(res1 => {
                if (res1 == true) {
                  this.calculateArrivalDateTime(item);
                  item.depStartAt = new Date(item.departureDateLocal);
                  this.legList.forEach((x, index) => {
                    if (x.tripLegOrder >= item.tripLegOrder) {
                      x.arrStartAt = item.depStartAt;
                      x.depStartAt = item.depStartAt;
                    }
                  });
                }
              });
            }
            else {
              item.isValidDeptDate =false;              
              item.departureDateLocal = e.target._elementRef.nativeElement.value            
            }                      
          }
        }
        else {
          item.isValidDeptDate = true;
          item.departureDateLocal = "";
          item.departureDateLocal_DTType = null;
        }
      }

      if (dateType == "A") {
        item.nextArrivalDateUTC = "";
        item.nextArrivalDateUTC_DTType = null;
        item.nextArrivalTimeUTC = "";
        if (e.target._elementRef.nativeElement.value != "") {
          if (e.target.value == null) {
            item.isValidArrDate = false;
            item.nextArrivalDateLocal = e.target._elementRef.nativeElement.value;
          }
          else {
            if (new Date(e.target.value).getUTCFullYear() >= (new Date().getUTCFullYear() - 1)) {
              item.isValidArrDate = true;
              item.nextArrivalDateLocal_DTType = e.target.value;
              item.nextArrivalDateLocal = CustomValidators.formatDateToMMDDYYYY(e.target.value);
              this.getAirportUTCTimeConversion(item, "A", this.time_type).subscribe(res1 => {
                if (res1 == true) {
                  item.arrStartAt = new Date(item.nextArrivalDateLocal);
                  this.calculateDepartureDateTime(item);
                  this.legList.forEach((x, index) => {
                    if (x.tripLegOrder > item.tripLegOrder) {
                      x.depStartAt = item.arrStartAt;
                      x.arrStartAt = item.arrStartAt;
                    }

                  });
                }
              });
            }
            else {
              item.isValidArrDate = false;
              item.nextArrivalDateLocal = e.target._elementRef.nativeElement.value;
            }            
          }
        }
        else {
          item.isValidArrDate = true;
          item.nextArrivalDateLocal = "";
          item.nextArrivalDateLocal_DTType = null;
        }
      }

    }


    if (dateType == 'A') {
 
      this.checkWhetherEteIsDisabledArrival(this.displayLegList[index], this.origDisplayLegList[index], index);
    }
    else {

      this.checkWhetherEteIsDisabledDeparture(this.displayLegList[index], this.origDisplayLegList[index], index);
    }
    
  }

  timeOnchange(dateType: string, e: any, item: TripLegModel, index: number = null) {
    this.errMsg2 = "";
    this.dataChanged = true;
    if (this.time_type == 'utc') {
      if (dateType == "D") {
        item.departureDateLocal = "";
        item.departureDateLocal_DTType = null;
        item.departureTimeLocal = "";
        if (e.target.value != "") {
          let timeObj = new DateTimeObjModel();
          timeObj.timeString = e.target.value;
          timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
          item.departureTimeUTC = timeObj.timeString;
          item.isValidDeptTime = timeObj.isValidDateTime;
          if (item.isValidDeptTime) {            
            this.getAirportUTCTimeConversion(item, "D", this.time_type).subscribe(res1 => {
              if (res1 == true) {
                this.calculateArrivalDateTime(item);
              }
            });

          }

        }
        else {
          item.departureTimeUTC = "";
          item.isValidDeptTime = true;
        }
      }

      if (dateType == "A") {
        item.nextArrivalDateLocal = "";
        item.nextArrivalDateLocal_DTType = null;
        item.nextArrivalTimeLocal = "";
        if (e.target.value != "") {
          let timeObj = new DateTimeObjModel();
          timeObj.timeString = e.target.value;
          timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
          item.nextArrivalTimeUTC = timeObj.timeString;
          item.isValidArrTime = timeObj.isValidDateTime;
          if (item.isValidArrTime) {           
            this.getAirportUTCTimeConversion(item, "A", this.time_type).subscribe(res1 => {
              if (res1 == true) {
                this.calculateDepartureDateTime(item);
              }
            });

          }

        }
        else {
          item.nextArrivalTimeUTC = "";
          item.isValidArrTime = true;
        }
      }
    }
    else {
      if (dateType == "D") {
        item.departureDateUTC = "";
        item.departureDateUTC_DTType = null;
        item.departureTimeUTC = "";
        if (e.target.value != "") {
          let timeObj = new DateTimeObjModel();
          timeObj.timeString = e.target.value;
          timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
          item.departureTimeLocal = timeObj.timeString;
          item.isValidDeptTime = timeObj.isValidDateTime;
          if (item.isValidDeptTime) {
            this.getAirportUTCTimeConversion(item, "D", this.time_type).subscribe(res1 => {
              if (res1 == true) {
                this.calculateArrivalDateTime(item);
              }
            });

          }

        }
        else {
          item.departureTimeLocal = "";
          item.isValidDeptTime = true;
        }
      }

      if (dateType == "A") {
        item.nextArrivalDateUTC = "";
        item.nextArrivalDateUTC_DTType = null;
        item.nextArrivalTimeUTC = "";
        if (e.target.value != "") {
          let timeObj = new DateTimeObjModel();
          timeObj.timeString = e.target.value;
          timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
          item.nextArrivalTimeLocal = timeObj.timeString;
          item.isValidArrTime = timeObj.isValidDateTime;
          if (item.isValidArrTime) {           
            this.getAirportUTCTimeConversion(item, "A", this.time_type).subscribe(res1 => {
              if (res1 == true) {
                this.calculateDepartureDateTime(item);
              }
            });

          }

        }
        else {
          item.nextArrivalTimeLocal = "";
          item.isValidArrTime = true;
        }
      }
    }

    // this.checkWhetherEteIsDisabled(this.displayLegList, this.origDisplayLegList, index);


    if (dateType == 'A') {

      this.checkWhetherEteIsDisabledArrival(this.displayLegList[index], this.origDisplayLegList[index], index);
    }
    else {

      this.checkWhetherEteIsDisabledDeparture(this.displayLegList[index], this.origDisplayLegList[index], index);
    }
    
  }

  getAirportUTCTimeConversion(objCityPair: TripLegModel, dateType: string, time_type: string): Observable<boolean> {
    return new Observable<boolean>(ob => {
      let f: boolean = false;
      let request = new AirportModel();
      if (time_type == 'utc') {
        if (dateType == "D") {
          request.icao = objCityPair.departureICAO;
          request.airportUTCDateTime = objCityPair.departureDateUTC;
          if (objCityPair.departureTimeUTC != "") {
            request.airportUTCDateTime += " " + objCityPair.departureTimeUTC;
          }
          else {
            objCityPair.departureDateLocal_DTType = objCityPair.departureDateUTC_DTType;
            objCityPair.departureDateLocal = objCityPair.departureDateUTC;       
            objCityPair.departureTimeLocal = "";
            ob.next(true);
            f = true;
          }
        }
        if (dateType == "A") {
          request.icao = objCityPair.nextArrivalICAO;
          request.airportUTCDateTime = objCityPair.nextArrivalDateUTC;
          if (objCityPair.nextArrivalTimeUTC != "") {
            request.airportUTCDateTime += " " + objCityPair.nextArrivalTimeUTC;
          }
          else {
            objCityPair.nextArrivalDateLocal_DTType = objCityPair.nextArrivalDateUTC_DTType;
            objCityPair.nextArrivalDateLocal = objCityPair.nextArrivalDateUTC;      
            objCityPair.nextArrivalTimeLocal = "";
            ob.next(true);
            f = true;
          }
        }

        if (f == false) {
          this._airportService.getAirportLocalTimeFromUTC<ResponseModel<AirportModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              let obj = new AirportModel();
              obj = response.model;
              if (dateType == "D") {
                objCityPair.departureUTCTimeConversion = obj.utcTimeConversionDisplay;

                let ldt = obj.airportLocalDateTime;
                if (ldt != "") {
                  let i = ldt.indexOf(' ');
                  let d = ldt.substring(0, i);
                  let t = ldt.substring(i + 1);

                  objCityPair.departureDateLocal_DTType = new Date(d);
                  objCityPair.departureDateLocal = d;
                  if (objCityPair.departureTimeUTC != "")
                    objCityPair.departureTimeLocal = t;
                  else
                    objCityPair.departureTimeLocal = "";

                }

              }
              if (dateType == "A") {
                objCityPair.nextArrivalUTCTimeConversion = obj.utcTimeConversionDisplay;
                let ldt = obj.airportLocalDateTime;
                if (ldt != "") {
                  let i = ldt.indexOf(' ');
                  let d = ldt.substring(0, i);
                  let t = ldt.substring(i + 1);

                  objCityPair.nextArrivalDateLocal_DTType = new Date(d);
                  objCityPair.nextArrivalDateLocal = d;
                  if (objCityPair.nextArrivalTimeUTC != "")
                    objCityPair.nextArrivalTimeLocal = t;
                  else
                    objCityPair.nextArrivalTimeLocal = "";

                }

              }
              ob.next(true);

            }
            else {
              ob.next(false);
              if (response.code == "401") {

                this._authService.signOut();
              }

            }

          })
        }

      }
      else {
        if (dateType == "D") {
          request.icao = objCityPair.departureICAO;
          request.airportLocalDateTime = objCityPair.departureDateLocal;
          if (objCityPair.departureTimeLocal != "") {
            request.airportLocalDateTime += " " + objCityPair.departureTimeLocal;
          }
        }
        if (dateType == "A") {
          request.icao = objCityPair.nextArrivalICAO;
          request.airportLocalDateTime = objCityPair.nextArrivalDateLocal;
          if (objCityPair.nextArrivalTimeLocal != "") {
            request.airportLocalDateTime += " " + objCityPair.nextArrivalTimeLocal;
          }
        }

        this._airportService.getAirportUTCTimeFromLocal<ResponseModel<AirportModel>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            let obj = new AirportModel();
            obj = response.model;
            if (dateType == "D") {
              objCityPair.departureUTCTimeConversion = obj.utcTimeConversionDisplay;

              let ldt = obj.airportUTCDateTime;
              if (ldt != "") {
                let i = ldt.indexOf(' ');
                let d = ldt.substring(0, i);
                let t = ldt.substring(i + 1);

                objCityPair.departureDateUTC_DTType = new Date(d);//
                objCityPair.departureDateUTC = d;
                if (objCityPair.departureTimeLocal != "")
                  objCityPair.departureTimeUTC = t;
                else
                  objCityPair.departureTimeUTC = "";
              }

            }
            if (dateType == "A") {
              objCityPair.nextArrivalUTCTimeConversion = obj.utcTimeConversionDisplay;

              let ldt = obj.airportUTCDateTime;
              if (ldt != "") {
                let i = ldt.indexOf(' ');
                let d = ldt.substring(0, i);
                let t = ldt.substring(i + 1);

                objCityPair.nextArrivalDateUTC_DTType = new Date(d);
                objCityPair.nextArrivalDateUTC = d;
                if (objCityPair.nextArrivalTimeLocal != "")
                  objCityPair.nextArrivalTimeUTC = t;
                else
                  objCityPair.nextArrivalTimeUTC = "";

              }

            }
            ob.next(true);
            

          }
          else {
            ob.next(false);
            if (response.code == "401") {

              this._authService.signOut();
            }

          }

        })

      }
      
    });
  }

  calculateArrivalDateTime(objCityPair: TripLegModel) {
    if (objCityPair.departureDateUTC != "" && objCityPair.departureTimeUTC != "" && objCityPair.nextArrivalDateUTC != "" && objCityPair.nextArrivalTimeUTC != "") {
      //this.confirmDialogRef = this._dialogService.openConfirmDialog("You updated the Departure Date / Time it might impact Arrival Date time. Do you want to update arrival date / time?");
      //this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
      //  this.eventSubscription.unsubscribe();
      //  if (result) {
      //    objCityPair.nextArrivalDateUTC = "";
      //    objCityPair.nextArrivalTimeUTC = "";
      //    objCityPair.nextArrivalDateLocal = "";
      //    objCityPair.nextArrivalTimeLocal = "";
      //    this.getArrivalDateTime(objCityPair);
      //  }
      //});
          objCityPair.nextArrivalDateUTC = "";
          objCityPair.nextArrivalTimeUTC = "";
          objCityPair.nextArrivalDateLocal = "";
          objCityPair.nextArrivalTimeLocal = "";
          this.getArrivalDateTime(objCityPair);

    }
    else {
      this.getArrivalDateTime(objCityPair);
    }
  }

  getArrivalDateTime(objCityPair: TripLegModel) {
    if (objCityPair.departureDateUTC != "" && objCityPair.departureTimeUTC != "" && (objCityPair.nextArrivalDateUTC == "" || objCityPair.nextArrivalTimeUTC == "")) {
      if (objCityPair.isValidDeptDate && objCityPair.isValidDeptTime) {
        if (objCityPair.departureLatitude != null && objCityPair.departureLongitude != null && objCityPair.nextArrivalLatitude != null && objCityPair.nextArrivalLongitude != null) {
          let request = new DistanceTimeModel();
          request.departureLatitude = objCityPair.departureLatitude;
          request.departureLongitude = objCityPair.departureLongitude;
          request.arrivalLatitude = objCityPair.nextArrivalLatitude;
          request.arrivalLongitude = objCityPair.nextArrivalLongitude;
          request.airSpeed = this.speed; //460;
          request.bias = 15;
          this._airportService.getDistanceTripTime<ResponseModel<DistanceTimeModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              let obj = new DistanceTimeModel();
              obj = response.model;
              let h = Number(obj.tripTimeHour);
              let m = Number(obj.tripTimeMinute);
              let dt = new Date(objCityPair.departureDateUTC + " " + objCityPair.departureTimeUTC);
              let adt = UtilityFunctions.addHours(dt, h);
              adt = UtilityFunctions.addMinutes(dt, m);

              objCityPair.nextArrivalDateUTC_DTType = adt
              objCityPair.nextArrivalDateUTC = CustomValidators.formatDateToMMDDYYYY(objCityPair.nextArrivalDateUTC_DTType);
              let ah = adt.getHours();
              let am = adt.getMinutes();
              if (am < 10) {
                objCityPair.nextArrivalTimeUTC = ah.toString() + ":0" + am.toString();
              }
              else {
                objCityPair.nextArrivalTimeUTC = ah.toString() + ":" + am.toString();
              }
              objCityPair.ete = obj.tripTime;
              this.getAirportUTCTimeConversion(objCityPair, "A", "utc").subscribe(res1 => {
                if (res1 == true) {

                }
              });
            }
            else {
              if (response.code == "401") {
                this._authService.signOut();
              }

            }
            this.loading = false;
          })
        }
      }
    }
  }

  calculateDepartureDateTime(objCityPair: TripLegModel) {
    if (objCityPair.nextArrivalDateUTC != "" && objCityPair.nextArrivalTimeUTC != "" && (objCityPair.departureDateUTC == "" || objCityPair.departureTimeUTC == "")) {
      if (objCityPair.isValidArrDate && objCityPair.isValidArrTime) {
        if (objCityPair.departureLatitude != null && objCityPair.departureLongitude != null && objCityPair.nextArrivalLatitude != null && objCityPair.nextArrivalLongitude != null) {
          let request = new DistanceTimeModel();
          request.departureLatitude = objCityPair.departureLatitude;
          request.departureLongitude = objCityPair.departureLongitude;
          request.arrivalLatitude = objCityPair.nextArrivalLatitude;
          request.arrivalLongitude = objCityPair.nextArrivalLongitude;
          request.airSpeed = this.speed; //460;
          request.bias = 15;
          this._airportService.getDistanceTripTime<ResponseModel<DistanceTimeModel>>(request).subscribe(response => {
            if (response != null && response.code == "200") {
              let obj = new DistanceTimeModel();
              obj = response.model;
              let h = Number(obj.tripTimeHour);
              let m = Number(obj.tripTimeMinute);
              let dt = new Date(objCityPair.nextArrivalDateUTC + " " + objCityPair.nextArrivalTimeUTC);
              let ddt = UtilityFunctions.addHours(dt, -h);
              ddt = UtilityFunctions.addMinutes(dt, -m);
              objCityPair.departureDateUTC_DTType = ddt;
              objCityPair.depStartAt = objCityPair.departureDateUTC_DTType;
              objCityPair.departureDateUTC = CustomValidators.formatDateToMMDDYYYY(objCityPair.departureDateUTC_DTType);
              let dh = ddt.getHours();
              let dm = ddt.getMinutes();
              if (dm < 10) {
                objCityPair.departureTimeUTC = dh.toString() + ":0" + dm.toString();
              }
              else {
                objCityPair.departureTimeUTC = dh.toString() + ":" + dm.toString();
              }
              objCityPair.ete = obj.tripTime;
              this.getAirportUTCTimeConversion(objCityPair, "D", "utc").subscribe(res1 => {
                if (res1 == true) {

                }
              });
            }
            else {
              if (response.code == "401") {
                this._authService.signOut();
              }

            }
            this.loading = false;
          })


        }
      }
    }

  }

  farTypeChange(e: any, item: TripLegModel) {
    this.dataChanged = true;
  }

  techStopChange(e: any, item: TripLegModel) {
    this.dataChanged = true;
  }

  callSignChange(e: any, item: TripLegModel) {
    this.dataChanged = true;
  }

  petChange(e: any, item: TripLegModel) {
    this.dataChanged = true;
  }

  weaponChange(e: any, item: TripLegModel) {
    this.dataChanged = true;
  }

  ferryFlightChange(e: any, item: TripLegModel) {
    this.dataChanged = true;
  }

  clickInsertLeg(e: any, item: TripLegModel,insertType:string) {
    if (this.dataChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes you just made?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave('legAdd', item, insertType);
        }
        else {
          this.legList = [];
          let obj: TripLegModel;
          this.existingLegList.forEach(x => {
            obj = new TripLegModel();
            obj = x;
            this.legList.push(x);
          });
          this.setDisplayLeg();
          this.f.route.setValue('');
          this.openLegAddPage(item,insertType);                
          
        }
      });
    }
    else {
      this.openLegAddPage(item,insertType);
    }

  }

  clickSave(flag: string, item: TripLegModel, insertType:string="") {
    this._authService.updateAccessTime();
    this.errMsg2 = "";
    this.groundStopList = [];
    let hasError: boolean = false;
    this.legList.forEach(x => {
      if (!x.isValidDeptDate || !x.isValidDeptTime || !x.isValidArrDate || !x.isValidArrTime) {
        hasError = true;
      }
    });
    if (hasError) {
      return;
    }
    this.submitted = true;
    if (this.loading == true) {
      return;
    }
    let tempLegList: TripLegModel[] = [];
    let leg: TripLegModel;
    let lastSegNum: number = 1;

    let fillFAR: boolean = true;
    this.legList.forEach(x => {
      if (x.departureFARTypeID == 0 && !x.nextArrivalDateTimePassed && x.departureGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID && x.nextArrivalGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID && x.departureGroundStopTypeGUID.toLowerCase() != this.contingencyGroundStopTypeGUID && x.nextArrivalGroundStopTypeGUID.toLowerCase() != this.contingencyGroundStopTypeGUID) {
        fillFAR = false;
      }
    })

    if (!fillFAR) {
      return;
    }

    // active leg list
    let activeLegList: TripLegModel[] = [];
    this.legList.forEach((x, index) => {
      if (x.departureGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID 
        && x.nextArrivalGroundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID && x.isDepartureEditable && x.isNextArrivalEditable) {
        leg = new TripLegModel();
        leg = x;
        activeLegList.push(leg);       
      }
    });

    

    let gs: GroundStopModel;
    let i: number = 0;
    activeLegList.forEach((x, index) => {
      gs = new GroundStopModel();
        gs.isValidArrDate = true;
        gs.isValidArrTime = true;
        gs.isValidDeptDate = true;
        gs.isValidDeptTime = true;
        if (index == 0) {
          gs.arrivalDateUTC = null;
          gs.arrivalDateTextUTC = "";
          gs.arrivalTimeUTC = "";
          gs.arrivalDateLocal = null;
          gs.arrivalDateTextLocal = "";
          gs.arrivalTimeLocal = "";
          gs.arrivalUTCTimeConversion = "";
          gs.isTechStop = false;
          gs.arrivalPetsOnBoard = false;
          gs.arrivalWeaponsOnBoard = false;
          gs.isArrFerryFlight = false;
          gs.arrivalOnHold = false;
          gs.groundStopSequenceID = x.groundStopSequenceID;
          gs.departureDateUTC = x.departureDateUTC_DTType;
          gs.departureDateTextUTC = x.departureDateUTC;
          gs.departureTimeUTC = x.departureTimeUTC;
          gs.departureDateLocal = x.departureDateLocal_DTType;
          gs.departureDateTextLocal = x.departureDateLocal;
          gs.departureTimeLocal = x.departureTimeLocal;
          gs.departureUTCTimeConversion = x.departureUTCTimeConversion;
          gs.fARTypeID = x.departureFARTypeID;
          gs.callSign = x.departureCallSign;
          gs.departurePetsOnBoard = x.departurePetsOnBoard;
          gs.departureWeaponsOnBoard = x.departureWeaponsOnBoard;
          gs.isDeptFerryFlight = x.isDeptFerryFlight;
          gs.ete = x.ete;
          gs.eteChanged = x.eteChanged;
          gs.icao = x.departureICAO;
          gs.groundStopTypeGUID = x.departureGroundStopTypeGUID;
          gs.oldGroundStopTypeGUID = gs.groundStopTypeGUID;
          gs.cancelled = x.cancelled;
          gs.groundStopGUID = x.groundStopGUID;
          gs.departureOnHold = x.departureOnHold;
          gs.departureStatusID = x.departureStatusID;
        }
        else {
          if ((index > 0)) {
            gs.arrivalDateUTC = activeLegList[index - 1].nextArrivalDateUTC_DTType;
            gs.arrivalDateTextUTC = activeLegList[index - 1].nextArrivalDateUTC;
            gs.arrivalTimeUTC = activeLegList[index - 1].nextArrivalTimeUTC;
            gs.arrivalDateLocal = activeLegList[index - 1].nextArrivalDateLocal_DTType;
            gs.arrivalDateTextLocal = activeLegList[index - 1].nextArrivalDateLocal;
            gs.arrivalTimeLocal = activeLegList[index - 1].nextArrivalTimeLocal;
            gs.arrivalUTCTimeConversion = activeLegList[index - 1].nextArrivalUTCTimeConversion;
            if (activeLegList[index - 1].isNextArrivalTechStopNumber == 1) {
              gs.isTechStop = true;
            }
            else {
              gs.isTechStop = false;
            }
            gs.arrivalPetsOnBoard = activeLegList[index - 1].departurePetsOnBoard;
            gs.arrivalWeaponsOnBoard = activeLegList[index - 1].departureWeaponsOnBoard;
            gs.isArrFerryFlight = activeLegList[index - 1].isDeptFerryFlight;
            gs.arrivalOnHold = activeLegList[index - 1].departureOnHold;
            gs.arrivalStatusID = activeLegList[index - 1].nextArrivalStatusID;
            gs.groundStopSequenceID = activeLegList[index - 1].nextArrivalGroundStopSequenceID;
            gs.departureDateUTC = x.departureDateUTC_DTType;
            gs.departureDateTextUTC = x.departureDateUTC;
            gs.departureTimeUTC = x.departureTimeUTC;
            gs.departureDateLocal = x.departureDateLocal_DTType;
            gs.departureDateTextLocal = x.departureDateLocal;
            gs.departureTimeLocal = x.departureTimeLocal;
            gs.departureUTCTimeConversion = x.departureUTCTimeConversion;
            gs.fARTypeID = x.departureFARTypeID;
            gs.callSign = x.departureCallSign;
            gs.departurePetsOnBoard = x.departurePetsOnBoard;
            gs.departureWeaponsOnBoard = x.departureWeaponsOnBoard;
            gs.isDeptFerryFlight = x.isDeptFerryFlight;
            gs.ete = x.ete;
            gs.eteChanged = x.eteChanged;
            gs.icao = x.departureICAO;
            gs.groundStopTypeGUID = x.departureGroundStopTypeGUID;
            gs.oldGroundStopTypeGUID = gs.groundStopTypeGUID;
            gs.cancelled = x.cancelled;
            //gs.groundStopGUID = activeLegList[index - 1].nextArrivalGroundStopGUID;
            gs.groundStopGUID = x.groundStopGUID;
            gs.departureOnHold = x.departureOnHold;
            gs.departureStatusID = x.departureStatusID;
          }
        }
        this.groundStopList.push(gs);     
        i = index;

    });

    // add last active stop 
    gs = new GroundStopModel();
    gs.isValidArrDate = true;
    gs.isValidArrTime = true;
    gs.isValidDeptDate = true;
    gs.isValidDeptTime = true;
    gs.icao = activeLegList[i].nextArrivalICAO;
    gs.arrivalDateUTC = activeLegList[i].nextArrivalDateUTC_DTType;
    gs.arrivalDateTextUTC = activeLegList[i].nextArrivalDateUTC;
    gs.arrivalTimeUTC = activeLegList[i].nextArrivalTimeUTC;
    gs.arrivalDateLocal = activeLegList[i].nextArrivalDateLocal_DTType;
    gs.arrivalDateTextLocal = activeLegList[i].nextArrivalDateLocal;
    gs.arrivalTimeLocal = activeLegList[i].nextArrivalTimeLocal;
    gs.arrivalUTCTimeConversion = activeLegList[i].nextArrivalUTCTimeConversion;
    if (activeLegList[i].isNextArrivalTechStopNumber == 1) {
      gs.isTechStop = true;
    }
    else {
      gs.isTechStop = false;
    }
    gs.arrivalPetsOnBoard = activeLegList[i].departurePetsOnBoard;
    gs.arrivalWeaponsOnBoard = activeLegList[i].departureWeaponsOnBoard;
    gs.isArrFerryFlight = activeLegList[i].isDeptFerryFlight;
    gs.arrivalOnHold = activeLegList[i].departureOnHold;
    gs.groundStopSequenceID = activeLegList[i].nextArrivalGroundStopSequenceID;
    gs.arrivalStatusID = activeLegList[i].nextArrivalStatusID;
    gs.departureDateUTC = null;
    gs.departureDateTextUTC = "";
    gs.departureTimeUTC = "";
    gs.departureDateLocal = null;
    gs.departureDateTextLocal = "";
    gs.departureTimeLocal = "";
    gs.departureUTCTimeConversion = "";
    gs.ete = "";
    gs.departureWeaponsOnBoard = false;
    gs.arrivalWeaponsOnBoard = false;  
    gs.callSign = "";
    gs.fARTypeID = 0;
    gs.groundStopTypeGUID = activeLegList[i].nextArrivalGroundStopTypeGUID;
    gs.oldGroundStopTypeGUID = gs.groundStopTypeGUID;
    gs.cancelled = activeLegList[i].cancelled;
    gs.groundStopGUID = activeLegList[i].nextArrivalGroundStopGUID;
    gs.departureOnHold = false;
    this.groundStopList.push(gs);
    // add last active stop end
    // active leg list end

    // not active leg list
    let notActiveLegList: TripLegModel[] = [];
    this.legList.forEach((x, index) => {
      if ((x.departureGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID 
        || x.nextArrivalGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID ||
        x.departureGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID
        || x.nextArrivalGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID)
        && x.groundStopSequenceID && x.nextArrivalGroundStopSequenceID == (x.groundStopSequenceID + 1)) {
        leg = new TripLegModel();
        leg = x;
        notActiveLegList.push(leg);
      }
    });
    i = 0;
    let fIndex: number;
    notActiveLegList.forEach((x, index) => {
      if (x.departureGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID || x.departureGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID) {
        if (this.groundStopList.findIndex(n => n.groundStopSequenceID == x.groundStopSequenceID) == -1) {
          gs = new GroundStopModel();
          gs.isValidArrDate = true;
          gs.isValidArrTime = true;
          gs.isValidDeptDate = true;
          gs.isValidDeptTime = true;
          fIndex = this.legList.findIndex(y => y.nextArrivalGroundStopSequenceID == x.groundStopSequenceID);
          if (fIndex != -1) {
            gs.arrivalDateUTC = this.legList[fIndex].nextArrivalDateUTC_DTType;
            gs.arrivalDateTextUTC = this.legList[fIndex].nextArrivalDateUTC;
            gs.arrivalTimeUTC = this.legList[fIndex].nextArrivalTimeUTC;
            gs.arrivalDateLocal = this.legList[fIndex].nextArrivalDateLocal_DTType;
            gs.arrivalDateTextLocal = this.legList[fIndex].nextArrivalDateLocal;
            gs.arrivalTimeLocal = this.legList[fIndex].nextArrivalTimeLocal;
            gs.arrivalUTCTimeConversion = this.legList[fIndex].nextArrivalUTCTimeConversion;
            gs.arrivalStatusID = this.legList[fIndex].nextArrivalStatusID;
            if (this.legList[fIndex].isNextArrivalTechStopNumber == 1) {
              gs.isTechStop = true;
            }
            else {
              gs.isTechStop = false;
            }
            gs.arrivalPetsOnBoard = this.legList[fIndex].departurePetsOnBoard;
            gs.arrivalWeaponsOnBoard = this.legList[fIndex].departureWeaponsOnBoard;
            gs.arrivalOnHold = this.legList[fIndex].departureOnHold;
          }
          else {
            gs.arrivalDateUTC = null;
            gs.arrivalDateTextUTC = "";
            gs.arrivalTimeUTC = "";
            gs.arrivalDateLocal = null;
            gs.arrivalDateTextLocal = "";
            gs.arrivalTimeLocal = "";
            gs.arrivalUTCTimeConversion = "";
            gs.isTechStop = false;
            gs.arrivalPetsOnBoard = false;
            gs.arrivalWeaponsOnBoard = false;
            gs.arrivalOnHold = false;
          }
          gs.groundStopSequenceID = x.groundStopSequenceID;
          gs.departureDateUTC = x.departureDateUTC_DTType;
          gs.departureDateTextUTC = x.departureDateUTC;
          gs.departureTimeUTC = x.departureTimeUTC;
          gs.departureDateLocal = x.departureDateLocal_DTType;
          gs.departureDateTextLocal = x.departureDateLocal;
          gs.departureTimeLocal = x.departureTimeLocal;
          gs.departureUTCTimeConversion = x.departureUTCTimeConversion;
          gs.fARTypeID = x.departureFARTypeID;
          gs.callSign = x.departureCallSign;
          gs.departurePetsOnBoard = x.departurePetsOnBoard;
          gs.departureWeaponsOnBoard = x.departureWeaponsOnBoard;
          gs.ete = x.ete;
          gs.eteChanged = x.eteChanged;
          gs.icao = x.departureICAO;
          gs.groundStopTypeGUID = x.departureGroundStopTypeGUID;
          gs.oldGroundStopTypeGUID = gs.groundStopTypeGUID;
          gs.cancelled = x.cancelled;
          gs.groundStopGUID = x.groundStopGUID;
          gs.departureOnHold = x.departureOnHold;
          gs.departureStatusID = x.departureStatusID;
          this.groundStopList.push(gs);
        }
        
      }
      
      if (x.nextArrivalGroundStopTypeGUID.toLowerCase() == this.cancellationGroundStopTypeGUID || x.nextArrivalGroundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID) {
        if (this.groundStopList.findIndex(n => n.groundStopSequenceID == x.nextArrivalGroundStopSequenceID) == -1) {
          gs = new GroundStopModel();
          gs.isValidArrDate = true;
          gs.isValidArrTime = true;
          gs.isValidDeptDate = true;
          gs.isValidDeptTime = true;
          gs.arrivalDateUTC = x.nextArrivalDateUTC_DTType;
          gs.arrivalDateTextUTC = x.nextArrivalDateUTC;
          gs.arrivalTimeUTC = x.nextArrivalTimeUTC;
          gs.arrivalDateLocal = x.nextArrivalDateLocal_DTType;
          gs.arrivalDateTextLocal = x.nextArrivalDateLocal;
          gs.arrivalTimeLocal = x.nextArrivalTimeLocal;
          gs.arrivalUTCTimeConversion = x.nextArrivalUTCTimeConversion;
          gs.arrivalStatusID = x.nextArrivalStatusID;
          if (x.isNextArrivalTechStopNumber == 1) {
            gs.isTechStop = true;
          }
          else {
            gs.isTechStop = false;
          }
          gs.arrivalPetsOnBoard = x.departurePetsOnBoard;
          gs.arrivalWeaponsOnBoard = x.departureWeaponsOnBoard;
          gs.arrivalOnHold = x.departureOnHold;
          gs.groundStopSequenceID = x.nextArrivalGroundStopSequenceID;
          gs.icao = x.nextArrivalICAO;
          gs.groundStopTypeGUID = x.nextArrivalGroundStopTypeGUID;
          gs.oldGroundStopTypeGUID = gs.groundStopTypeGUID;
          gs.cancelled = x.cancelled;
          gs.groundStopGUID = x.nextArrivalGroundStopGUID;
          fIndex = this.legList.findIndex(y => y.groundStopSequenceID == x.nextArrivalGroundStopSequenceID);
          if (fIndex != -1) {           
            gs.departureDateUTC = this.legList[fIndex].departureDateUTC_DTType;
            gs.departureDateTextUTC = this.legList[fIndex].departureDateUTC;
            gs.departureTimeUTC = this.legList[fIndex].departureTimeUTC;
            gs.departureDateLocal = this.legList[fIndex].departureDateLocal_DTType;
            gs.departureDateTextLocal = this.legList[fIndex].departureDateLocal;
            gs.departureTimeLocal = this.legList[fIndex].departureTimeLocal;
            gs.departureUTCTimeConversion = this.legList[fIndex].departureUTCTimeConversion;
            gs.fARTypeID = this.legList[fIndex].departureFARTypeID;
            gs.callSign = this.legList[fIndex].departureCallSign;
            gs.departurePetsOnBoard = this.legList[fIndex].departurePetsOnBoard;
            gs.departureWeaponsOnBoard = this.legList[fIndex].departureWeaponsOnBoard;
            gs.ete = this.legList[fIndex].ete;
            gs.departureOnHold = this.legList[fIndex].departureOnHold;
            gs.departureStatusID = this.legList[fIndex].departureStatusID;
          }
          else {
            gs.departureDateUTC = null;
            gs.departureDateTextUTC = "";
            gs.departureTimeUTC ="";
            gs.departureDateLocal = null;
            gs.departureDateTextLocal = "";
            gs.departureTimeLocal = "";
            gs.departureUTCTimeConversion = "";
            gs.fARTypeID = 0;
            gs.callSign = "";
            gs.departurePetsOnBoard = false;
            gs.departureWeaponsOnBoard = false;
            gs.ete = "";
            gs.departureOnHold = false;
          }
          
          this.groundStopList.push(gs);
        }

      }
      

    });  
    // not active leg list end
    this.groundStopList.sort((a, b) => (a.groundStopSequenceID > b.groundStopSequenceID) ? 1 : -1);
    
    let hasAllValidFields = true;
    let activeGSList: GroundStopModel[] = [];
    let aGS: GroundStopModel;
    this.groundStopList.forEach(x => {
      if (x.groundStopTypeGUID == "" || x.groundStopTypeGUID == undefined || x.groundStopTypeGUID == null) {
        aGS = new GroundStopModel();
        x.groundStopTypeGUID = "";
        aGS = x;
        activeGSList.push(aGS);
      }
      else {
        if (x.groundStopTypeGUID.toLowerCase() != this.cancellationGroundStopTypeGUID && x.groundStopTypeGUID.toLowerCase() != this.contingencyGroundStopTypeGUID) {
          aGS = new GroundStopModel();
          aGS = x;
          activeGSList.push(aGS);
        }
      }
    });

    activeGSList.forEach((x, index) => {
      if (x.arrivalDateTextUTC != "") {
        if (new Date(x.arrivalDateTextUTC).getUTCFullYear() < (new Date().getUTCFullYear()-1)) {
          x.isValidArrDate == false;
          if (hasAllValidFields == true) {
            hasAllValidFields = x.isValidArrDate;
          }
        }
        else {
          x.isValidArrDate = CustomValidators.isValidDate(x.arrivalDateTextUTC);
          if (hasAllValidFields == true && x.isValidArrDate == false) {
            hasAllValidFields = x.isValidArrDate;
          }
          if (x.isValidArrDate) {
            x.arrivalDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.arrivalDateTextUTC));
            x.arrivalDateUTC = new Date(x.arrivalDateTextUTC);
          }
        }
      }

      if (x.arrivalTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.arrivalTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.arrivalTimeUTC = timeObj.timeString;
        let arrtv = timeObj.isValidDateTime;
        x.isValidArrTime = arrtv;
        if (hasAllValidFields == true && arrtv == false) {
          hasAllValidFields = arrtv;
        }
      }
      // dept
      
      if (x.departureDateTextUTC != "") {
        if (new Date(x.departureDateTextUTC).getUTCFullYear() < (new Date().getUTCFullYear() - 1)) {
          x.isValidDeptDate == false;
          if (hasAllValidFields == true) {
            hasAllValidFields = x.isValidDeptDate;
          }
        }
        else {
          x.isValidDeptDate = CustomValidators.isValidDate(x.departureDateTextUTC);
          if (hasAllValidFields == true && x.isValidDeptDate == false) {
            hasAllValidFields = x.isValidDeptDate;
          }
          if (x.isValidDeptDate) {
            x.departureDateTextUTC = CustomValidators.formatDateToMMDDYYYY(new Date(x.departureDateTextUTC));
            x.departureDateUTC = new Date(x.departureDateTextUTC);
          }
        }        
      }     

      if (x.departureTimeUTC != "") {
        let timeObj = new DateTimeObjModel();
        timeObj.timeString = x.departureTimeUTC;
        timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
        x.departureTimeUTC = timeObj.timeString;
        let depttv = timeObj.isValidDateTime;
        x.isValidDeptTime = depttv;
        if (hasAllValidFields == true && depttv == false) {
          hasAllValidFields = depttv;
        }
      }

      if (index > 0) {
        let prev = activeGSList[index - 1];
          if (x.arrivalDateUTC != null && prev.departureDateUTC != null && !(x.groundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID || prev.groundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID)) {
            if (x.arrivalDateUTC.getTime() < prev.departureDateUTC.getTime()) {

              this.errMsg2 = "Arrival date can not be earlier than the departure date of the previous business stop.";
              return;
            }
            else {
              if (x.arrivalTimeUTC != "" && prev.departureTimeUTC != "") {
                let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);
                let td = new Date(prev.departureDateTextUTC + " " + prev.departureTimeUTC);
                if (ta.getTime() < td.getTime()) {

                  this.errMsg2 = "Arrival date time can not be earlier than the departure date time of the previous business stop.";
                  return;
                }
                else {
                  let dif = (ta.getTime() - td.getTime()) / 1000 / 3600;
                  if (dif >= 24) {
                    this.errMsg2 = "ETE can not be more than 24 hours.";
                    return;
                  }

                }
              }
            }
          }

          if (x.arrivalDateUTC != null && x.departureDateUTC != null && !(x.groundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID || prev.groundStopTypeGUID.toLowerCase() == this.contingencyGroundStopTypeGUID)) {
            if (x.arrivalDateUTC.getTime() > x.departureDateUTC.getTime()) {
              this.errMsg2 = "Arrival date time can not be later than the next departure date time.";
              return;
            }
            else {
              if (x.arrivalTimeUTC != "" && x.departureTimeUTC != "") {
                let ta = new Date(x.arrivalDateTextUTC + " " + x.arrivalTimeUTC);
                let td = new Date(x.departureDateTextUTC + " " + x.departureTimeUTC);
                if (ta.getTime() > td.getTime()) {

                  this.errMsg2 = "Arrival date time can not be later than the next departure date time.";
                  return;

                }
              }

            }

          }
        
      }
    });

    if (this.leglist_editor_form.invalid || this.errMsg2!='' || !hasAllValidFields) {
      return;
    }
    
    this.loading = true;
    
    let obj = new TripModel();  
    obj.customerGUID = this.customerGUID;   
    obj.aircraftGUID = this.aircraftGUID;
    obj.tripCode = this.trip_code;
    obj.tripCodeGUID = this.tripCodeGUID;
    obj.groundStopList = this.groundStopList;
    
    this._tripLegService.updateGroundStopsForLegList<ResponseModel<TripModel>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {          
          if (response.model.message != "") {
            this.errMsg = response.model.message;
          }
          else {
            if (response.model.tripCodeGUID != "") {
              this.f.route.setValue('');
              this.dataChanged = false;
              this.isModified = true;
              this.showSuccessMsg = true;
              setTimeout(() => {
                this.showSuccessMsg = false;
                if (flag == '') {
                  this.clickClose();
                }
                else {
                  this.getLegList();
                  if (flag == 'legAdd') {
                    this.openLegAddPage(item,insertType);
                  }
                  if (flag == 'stopSeq') {
                    this.openStopSeqPage();
                  }
                  
                }

              }, 1000);

              
            }
            else {
              
              this.errMsg2 = "Failed to update the trip at this time. Please try later.";
              
            }
          }
        }
        else {
          this.errMsg2 = "Failed to update the trip at this time. Please try later.";
        }
      }
      else {
        if (response.code == "401") {
          this._authService.signOut();
        }
        else {
          this.errMsg2 = "Failed to update the trip at this time. Please try later.";
        }
      }
      this.loading = false;
    })

  }

  openStopSeqPage() {

  }

  openLegAddPage(item: TripLegModel, insertType:string) {
    this._authService.updateAccessTime();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    
    dialogConfig.data = {
      legList: this.displayLegList, currentLeg: item.displayTripLegOrder, tripCodeGUID: this.tripCodeGUID, customerGUID: this.customerGUID,
      aircraftGUID: this.aircraftGUID, speed: this.speed, v: this._authService.getCurrentTimeNumber(), insertType:insertType
    };

    const dialogRef = this._dialog.open(LegInsertDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.isModified = true;
        this.getTripBytId();
        this.getLegList();
      }
    });

  }

  clickClose() {
    if (this.dataChanged) {
      this.confirmDialogRef = this._dialogService.openConfirmDialog("Do you want to save all changes you just made before close the page?");
      this.eventSubscription = this.confirmDialogRef.afterClosed().subscribe(result => {
        this.eventSubscription.unsubscribe();
        if (result) {
          this.clickSave('', null);
        }
        else {
          this._dialogRef.close(this.isModified);
        }
      });
    }
    else {
      this._dialogRef.close(this.isModified);
    }
  }

  onHoldChange(e: any, item: TripLegModel) {
    this.dataChanged = true;
  }

  cancelAirports() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "45em";//"1000px";
    //dialogConfig.height = "35em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    dialogConfig.data = {
      customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID, tripCodeGUID: this.tripCodeGUID, registration: this.registration,
      tripCode: this.trip_code

    };

    const dialogRef = this._dialog.open(CancelAirportDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getLegList();
        this.isModified = true;
        this.getTripBytId();
        this.getLegList();
      }

    });
  }


  manageContingencies(tripLeg: TripLegModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "45em";//"1000px";
    //dialogConfig.height = "35em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    let groundStopGuid = null;
    let isNext = false;
    let selectedGS;
    if (tripLeg != undefined) {
      let priorStop;
      if (tripLeg.displayTripLegOrder > 1)
        priorStop=this.displayLegList[tripLeg.displayTripLegOrder-1].groundStopGUID
      groundStopGuid = tripLeg.groundStopGUID;
      isNext = true;
      selectedGS = [{
        groundStop: tripLeg,
        isCancel: false,
        prevGroundStopGUID: priorStop,
        nextGroundStopGUID: tripLeg.nextArrivalGroundStopGUID
      }];

    }
    dialogConfig.data = {
      customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID, tripCodeGUID: this.tripCodeGUID, registration: this.registration,
      tripCode: this.trip_code, groundStopGuid:groundStopGuid,isNext:isNext, selectedGS:selectedGS

    };

    const dialogRef = this._dialog.open(ContingencyStopComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getLegList();
        this.isModified = true;
        this.getTripBytId();
        this.getLegList();
      }

    });
  }

  manageRouteSequence(tripLeg: TripLegModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    //dialogConfig.width = "45em";//"1000px";
    //dialogConfig.height = "35em";//"500px";
    dialogConfig.disableClose = true;
    dialogConfig.hasBackdrop = false;

    let groundStopGuid = null;
    let isNext = false;
    let selectedGS;
    if (tripLeg != undefined) {
      let priorStop;
      if (tripLeg.displayTripLegOrder > 1)
        priorStop=this.displayLegList[tripLeg.displayTripLegOrder-1].groundStopGUID
      groundStopGuid = tripLeg.groundStopGUID;
      isNext = true;
      selectedGS = [{
        groundStop: tripLeg,
        isCancel: false,
        prevGroundStopGUID: priorStop,
        nextGroundStopGUID: tripLeg.nextArrivalGroundStopGUID
      }];

    }

    dialogConfig.data = {
      customerGUID: this.customerGUID, aircraftGUID: this.aircraftGUID, tripCodeGUID: this.tripCodeGUID, registration: this.registration,
      tripCode: this.trip_code, groundStopGuid:groundStopGuid,isNext:isNext, selectedGS:selectedGS

    };

    const dialogRef = this._dialog.open(RouteSequenceDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(response => {
      this._dialogRef.close({result: true});
    });
  }

  statusChange(e: any, item: TripLegModel,type:string) {
    this.dataChanged = true;
    if (type == 'D')
      item.departureStatusColorCode = this.adStatusList.filter(x => x.arrival_DepartureStatusID == item.departureStatusID)[0].colorCode;
    else
      item.nextArrivalStatusColorCode = this.adStatusList.filter(x => x.arrival_DepartureStatusID == item.nextArrivalStatusID)[0].colorCode;
  }

  eteTimeChange(e: any, item: TripLegModel) {
    this.dataChanged = true;
      let timeObj = new DateTimeObjModel();
      timeObj.timeString = e.target.value;
      timeObj = CustomValidators.isValid24HHMMTimeFormat(timeObj);
      if (!timeObj.isValidDateTime) {
       // e.target.setErrors({ pattern: true });
      }
      else {
        item.ete = timeObj.timeString;
        e.target.value = timeObj.timeString;
        let ete: string[] = item.ete.split(':');
        let h = Number(ete[0]);
        let m = Number(ete[1]);
        let dt = new Date(item.departureDateUTC + " " + item.departureTimeUTC);
        let adt = UtilityFunctions.addHours(dt, h);
        adt = UtilityFunctions.addMinutes(dt, m);

        item.nextArrivalDateUTC_DTType = adt
        item.nextArrivalDateUTC = CustomValidators.formatDateToMMDDYYYY(item.nextArrivalDateUTC_DTType);
        let ah = adt.getHours();
        let am = adt.getMinutes();
        if (am < 10) {
          item.nextArrivalTimeUTC = ah.toString() + ":0" + am.toString();
        }
        else {
          item.nextArrivalTimeUTC = ah.toString() + ":" + am.toString();
        }
        this.getAirportUTCTimeConversion(item, "A", "utc").subscribe(res1 => {
          if (res1 == true) {

          }
        });
        //this.updateNextArrivalDateTime = true;
      }

    

  }

 


  checkWhetherEteIsDisabledArrival(element: TripLegModel, orig: TripLegModel, index: number) {

    const datepipe: DatePipe = new DatePipe('en-US')

    let arrDateTime = new Date(`${datepipe.transform(orig.nextArrivalDateUTC, 'yyyy-MM-dd')}T${(orig.nextArrivalTimeUTC)?.padStart(5, '0')}:00`);
    let depDateTime = new Date(`${datepipe.transform(orig.departureDateUTC, 'yyyy-MM-dd')}T${(orig.departureTimeUTC)?.padStart(5, '0')}:00`);

    let newArrDateTime = new Date(`${datepipe.transform(element.nextArrivalDateUTC, 'yyyy-MM-dd')}T${(element.nextArrivalTimeUTC)?.padStart(5, '0')}:00`);
    let newDepDateTime = new Date(`${datepipe.transform(element.departureDateUTC, 'yyyy-MM-dd')}T${(element.departureTimeUTC)?.padStart(5, '0')}:00`);


    // console.log((depDateTime.getTime() - newDepDateTime.getTime()) / (1000 * 60 * 60), (arrDateTime.getTime() - newArrDateTime.getTime()) / (1000 * 60 * 60))

    /// 2 hours is 7200000 milliSeconds. Checking the changes is greater than 2 hours
    // if (Math.abs(depDateTime.getTime() - newDepDateTime.getTime()) > 7200000 || Math.abs(arrDateTime.getTime() - newArrDateTime.getTime()) > 7200000) {
    if (arrDateTime.getTime() != newArrDateTime.getTime()) {
      element.eteChanged = false;
    }


    // if (!(element.departureTimeUTC) && datepipe.transform(orig.departureTimeUTC, 'yyyy-MM-dd') != datepipe.transform(element.departureDateUTC, 'yyyy-MM-dd')) {
    //   element.eteChanged = false;
    // }

    if (!(element.nextArrivalTimeUTC) && datepipe.transform(orig.nextArrivalTimeUTC, 'yyyy-MM-dd') != datepipe.transform(element.nextArrivalDateUTC, 'yyyy-MM-dd')) {
      element.eteChanged = false;
    }
  }

  checkWhetherEteIsDisabledDeparture(element: TripLegModel, orig: TripLegModel, index: number) {

    const datepipe: DatePipe = new DatePipe('en-US')

    let arrDateTime = new Date(`${datepipe.transform(orig.nextArrivalDateUTC, 'yyyy-MM-dd')}T${(orig.nextArrivalTimeUTC)?.padStart(5, '0')}:00`);
    let depDateTime = new Date(`${datepipe.transform(orig.departureDateUTC, 'yyyy-MM-dd')}T${(orig.departureTimeUTC)?.padStart(5, '0')}:00`);

    let newArrDateTime = new Date(`${datepipe.transform(element.nextArrivalDateUTC, 'yyyy-MM-dd')}T${(element.nextArrivalTimeUTC)?.padStart(5, '0')}:00`);
    let newDepDateTime = new Date(`${datepipe.transform(element.departureDateUTC, 'yyyy-MM-dd')}T${(element.departureTimeUTC)?.padStart(5, '0')}:00`);

 

    /// 2 hours is 7200000 milliSeconds. Checking the changes is greater than 2 hours
    // if (Math.abs(depDateTime.getTime() - newDepDateTime.getTime()) > 7200000 || Math.abs(arrDateTime.getTime() - newArrDateTime.getTime()) > 7200000) {
    if (Math.abs(depDateTime.getTime() - newDepDateTime.getTime()) > 7200000) {
      element.eteChanged = false;
    }


    // if (!(element.departureTimeUTC) && datepipe.transform(orig.departureTimeUTC, 'yyyy-MM-dd') != datepipe.transform(element.departureDateUTC, 'yyyy-MM-dd')) {
    //   element.eteChanged = false;
    // }

    if (!(element.nextArrivalTimeUTC) && datepipe.transform(orig.nextArrivalTimeUTC, 'yyyy-MM-dd') != datepipe.transform(element.nextArrivalDateUTC, 'yyyy-MM-dd')) {
      element.eteChanged = false;
    }
  }

}
