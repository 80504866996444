<table mat-table [dataSource]="nearbyAirports" class="example-container">
  <tr>
    <ng-container matColumnDef="preferred">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Preferred
      </th>
      <td mat-cell *matCellDef="let element">{{element.isPreferredAlternate? 'Yes':''}}</td>
    </ng-container>
    <ng-container matColumnDef="airport">
      <th mat-header-cell *matHeaderCellDef width="20%">
        Airport
      </th>
      <td mat-cell *matCellDef="let element" style="text-align:left !important;" >{{element.icao}} {{element.iata}} {{element.airportName}} {{element.airportLocationWithCountryNameAbbr}}</td>
    </ng-container>
    <ng-container matColumnDef="distance">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Distance
      </th>
      <td mat-cell *matCellDef="let element">{{element.distanceFromAirport}}</td>
    </ng-container>
    <ng-container matColumnDef="airport_of_entry">
      <th mat-header-cell *matHeaderCellDef width="6%" matTooltip="Airport of Entry">
        AOE
      </th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.airportOfEntry=='Yes'? 'beige':'', 'font-weight': element.airportOfEntry=='Yes'? '500':''}"><div>{{element.airportOfEntry}}</div></td>
    </ng-container>
    <ng-container matColumnDef="restrictions">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Restrictions
      </th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.hasRestrictions? 'beige':'', 'font-weight': element.hasRestrictions? '500':''}">{{element.hasRestrictions==null? '-' : element.hasRestrictions? 'Yes':'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="slots">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Slots
      </th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.isRequiredSlots >0? 'beige':'', 'font-weight': element.isRequiredSlots !=0? '500':''}">{{element.isRequiredSlots==null? '-' : element.isRequiredSlots==1? 'Yes': element.isRequiredSlots==2? 'Notes':'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="ppr">
      <th mat-header-cell *matHeaderCellDef width="5%">
        PPR
      </th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.isRequiredPPR >0? 'beige':'', 'font-weight': element.isRequiredPPR !=0? '500':''}">{{element.isRequiredPPR==null? '-' : element.isRequiredPPR==1? 'Yes': element.isRequiredPPR==2? 'Notes':'No'}}</td>
    </ng-container>
    <ng-container matColumnDef="landing_permit_private">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Landing Private
      </th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.countryRequiresLandingPermitPrivate=='Yes' || element.countryRequiresLandingPermitPrivate=='See Notes'? 'beige':'', 'font-weight': element.countryRequiresLandingPermitPrivate=='Yes' || element.countryRequiresLandingPermitPrivate=='See Notes'? '500':''}">{{element.countryRequiresLandingPermitPrivate==null? '-' : element.countryRequiresLandingPermitPrivate}}</td>
    </ng-container>
    <ng-container matColumnDef="landing_permit_charter">
      <th mat-header-cell *matHeaderCellDef width="5%">
        Landing Charter
      </th>
      <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': element.countryRequiresLandingPermitCharter=='Yes' || element.countryRequiresLandingPermitCharter=='See Notes'? 'beige':'', 'font-weight': element.countryRequiresLandingPermitCharter=='Yes' || element.countryRequiresLandingPermitCharter=='See Notes'? '500':''}">{{element.countryRequiresLandingPermitCharter==null? '-' : element.countryRequiresLandingPermitCharter}}</td>
    </ng-container>

  </tr>
  <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumn" (click)="openAirportDetails(row)"></tr>
</table>
