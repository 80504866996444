<div [id]="messageGUID">
  <div class="dialog-buttons minimizedDialog" cdkDrag cdkDragRootElement=".dialog-buttons" cdkDragHandle
       style="border-radius:5px; margin-bottom: -0.5em; margin-right: -0.4em; display: flex; justify-content: space-between;">


    <div *ngIf="isMinimized" style="margin-top: 0.5em; display: flex;">
      <mat-icon style="font-size: large; margin-top:0.2em; margin-left: 0.2em; margin-right: 0.2em;">email</mat-icon><span>{{displayMessage }}</span>
    </div>

    <!-- <button *ngIf = "!isMinimized" mat-icon-button (click)="minimizeDialog()">  <i class="material-icons" style="font-size:x-large;  ">remove</i> </button> -->
    <div style="display: flex;">
      <button *ngIf="isMinimized" mat-icon-button (click)="maximizeDialog()"> <i class="material-icons" style="font-size: large; margin-right: -1.2em; ">open_in_full</i> </button>
      <button *ngIf="isMinimized" mat-icon-button (click)="clickClose()"> <i class="material-icons" style="font-size: x-large;">close </i> </button>
    </div>
  </div>
  <div class="model-content" [ngClass]="{'minimized': isMinimized}">



    <div *ngIf="grantAccessToMessages" style="padding: 1em 1.5em 0.5em 1.5em; max-height:54em; ">

      <form *ngIf="email_internal_form" [formGroup]="email_internal_form">
        <div *ngIf="messageGUID!='' && !isDraft">
          <div class="d-flex justify-content-between mb-2" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <div class="d-flex">
              <div style="width:22em">
                <button mat-button *ngIf="!showSpin" [disabled]="loading || lockdownTrip" (click)="replyMessage('reply')" class="btn-med mr-1"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">reply</i><div>Reply</div></div></button>
                <button mat-button *ngIf="!showSpin" [disabled]="loading || lockdownTrip" (click)="replyMessage('replyall')" class="btn-med mr-1"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">reply_all</i><div>Reply All</div></div></button>
                <button mat-button *ngIf="!showSpin" [disabled]="loading || lockdownTrip" (click)="replyMessage('forward')" class="btn-med mr-1"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">forward</i><div>Forward</div></div></button>
              </div>
              <div style="display: flex;">
                <div style="width: 28.5em;">
                  <button mat-button class="btn-med  mr-1" (click)="onPrintClick()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">print</i><div>Print</div></div></button>
                  <button mat-button *ngIf="(!showSpin && linkGroup.length==0 && clientName=='') || autoLinkVisible" [disabled]="loading" class="btn-med mr-1" (click)="linkMessage()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">add_link</i><div>Link</div></div></button>
                  <button mat-button *ngIf="(assignedTo!=currentUser ) " [disabled]="loading" class="btn-med mr-1" (click)="assignMe(true)"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">person_add</i><div>Assign Me</div></div></button>
                  <button mat-button *ngIf="(assignedTo==currentUser && (linkGroup.length ==0 || canAutolink)) " [disabled]="loading" class="btn-med mr-1" (click)="assignMe(false)"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">person_remove</i><div>Remove Me</div></div></button>
                  <button mat-button *ngIf="!showSpin && isActive && messageFolderID!=11" [disabled]="loading" class="btn-med  mr-1" (click)="deleteMessage(false)"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">delete</i><div>Delete</div></div></button>
                  <button mat-button *ngIf="!showSpin && (!isActive || messageFolderID==11)" [disabled]="loading" class="btn-med  mr-1" (click)="deleteMessage(true)"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">delete</i><div>Undelete</div></div></button>
                  <button mat-button *ngIf="!isFlagged" [disabled]="loading" class="btn-med  mr-1" (click)="FlagMessage()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">flag</i><div>Flag</div></div></button>
                  <button mat-button *ngIf="isFlagged" [disabled]="loading" class="btn-med  mr-1" (click)="FlagMessage()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">clear</i><div>UnFlag</div></div></button>
                </div>
                <!-- <button mat-button *ngIf="reloadBtnVisible && !isDraft" class="btn-med mr-1" (click)="reloadEmbeddedImage()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">refresh</i><div>Load Image</div></div></button> -->
                <div matTooltip="Reload Image" style="display:flex"><i class="material-symbols-outlined" style="font-size: x-large;  margin-right: 0.25em" (click)="reloadEmbeddedImage()">autorenew</i> </div>
              </div>

              <div class="d-inline-flex">
                <div style="width:3em;">
                </div>
              </div>

              <!-- *ngIf = " !showAssigned" -->
              <div style="margin-top:-.5em; display: flex;" *ngIf="messageGuid!='' && !isDraft && !showAssigned">
                <button mat-icon-button aria-label="previous message" [disabled]="isFirstMessage" (click)="showPrevious()">
                  <mat-icon>arrow_upward</mat-icon>
                </button>
                <button mat-icon-button aria-label="next message" [disabled]="isLastMessage" (click)="showNext()">
                  <mat-icon>arrow_downward</mat-icon>
                </button>
              </div>


            </div>



            <div *ngIf="(isDraft || showAssigned) " style="float: right; margin-right: 2em;">
              <button mat-button class="btn-high" (click)="saveMessageDraft(true)"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">save</i><div style="margin-left: 0.5em;">Save Draft</div></div></button>
            </div>

            <button *ngIf="isMinimized" mat-icon-button (click)="maximizeDialog()"> <i class="material-icons" style="font-size:x-large; ">crop_square</i> </button>
            <!-- <div style="float: right">
              <button mat-button class="btn-low" (click)="clickClose()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i><div>Close</div></div></button>&nbsp;
            </div>   -->

            <div style="float: right; margin-left:  .5em; margin-top: -.5em; display: flex;" align="end">
              <button *ngIf="!isMinimized" mat-icon-button (click)="minimizeDialog()"> <i class="material-icons" style="font-size:x-large;  ">remove</i> </button>
              <button mat-icon-button (click)="clickClose()"> <i class="material-icons" style="font-size: x-large;">close</i> </button>
            </div>

          </div>
          <div class="d-inline-flex" id="emailHead">
            <div *ngIf="!printing">
              <div class="form-inline form-group" style="flex-flow: inherit !important;">
                <label class="searchLabel">From</label>
                <div style="font-size:small;width:40em;border-bottom:solid thin gray">
                  {{emailFrom}}
                  <span>{{senderCustomerName}}</span>
                </div>
                <div>
                  <div style="width:11em">
                    <label style="font-size:small;float:right">{{receivedDateTime==null? sentDateTime : receivedDateTime | date:'E M/d HH:mm'}} Z</label>
                  </div>
                  <div style="width:11em" *ngIf="isDelayed">
                    <label style="font-size:x-small;float:right">Sent: {{sentDateTime | date:'E M/d HH:mm'}} Z</label>
                  </div>
                </div>
              </div>
              <div class="form-inline form-group" style="flex-flow: inherit !important;">
                <label class="searchLabel">To</label>
                <div style="font-size: small; width: 55em; border-bottom: solid thin gray; word-wrap: break-word;">
                  {{emailTo}}
                </div>

              </div>
              <div class="form-inline form-group" style="flex-flow: inherit !important;" *ngIf="emailCc">
                <label class="searchLabel">CC</label>
                <div style="font-size: small; width: 55em; border-bottom: solid thin gray; word-wrap: break-word;">
                  {{emailCc}}
                </div>
              </div>
              <div class="form-inline form-group" style="flex-flow: inherit !important;">
                <label class="searchLabel">Subject</label>
                <div style="font-size: small; width: 55em; border-bottom: solid thin gray; word-wrap: break-word;">
                  {{subject}}
                </div>
              </div>
            </div>
            <div *ngIf="printing">
              <div style="display: grid;margin-bottom: 15px;">
                <span><strong>From:  </strong>{{emailFrom}} <label style="font-size:small;float:right">{{sentDateTime | date:'E M/d HH:mm'}} Z</label></span>
                <span><strong>To: </strong>{{emailTo}}</span>
                <span><strong>CC: </strong>{{emailCc}}</span>
                <span><strong>Subject: </strong>{{subject}}</span>
                <span>

                </span>
              </div>
            </div>
            <div *ngIf="(linkGroup.length>0 || clientName!='' || assignedTo!='') && errMsg==''" class="ml-2 p-2 border border-info rounded" style="font-size:x-small;width:25em;max-height: 15.5em;min-height: 9.5em;overflow-y:auto" [ngStyle]="{'margin-bottom':printing?'25px':'0px'}">
              <div style="display: flex;">
                <label *ngIf="assignedTo!=''  || messageGUID ==''" style="font-size:x-small" class="mr-1">Assigned To: {{assignedTo}}</label>
                <button *ngIf="!autoLinkVisible && !showAssigned && messageGUID != '' && linkGroup.length ==1" mat-button class="btn-med" (click)="updatelinkMessage(element)" style="display: inline-flex; margin-left: 1em;font-size: xx-small !important; "><span class="mat-button-wrapper" style="font-size: xx-small;">Update Link</span></button>

                <button *ngIf="autoLinkVisible" mat-button class="btn-med" (click)="autoLink(linkGroup[0].messageGUID)" style="display: inline-flex;font-size: xx-small !important;  padding: -1px 0.25rem 0 0 !important">AutoLink {{linkGroup.length>1?linkGroup[0].message:''}}</button>

              </div>
              <div *ngIf="linkGroup.length>0 || clientName!=''">
                <!-- <div *ngIf="!autoLinkVisible" style="display: inline-flex;">Link Details </div>
                <div *ngIf="autoLinkVisible" style="display: inline-flex; margin-right: 0.5em;"><b>Auto Link Details</b></div> -->
                <button *ngIf="!autoLinkVisible && !showAssigned && messageGUID != '' &&  linkGroup.length >1" mat-button class="btn-med" (click)="removelinkMessage()" style="display: inline-flex; margin-left: 1em; padding: 0px 0.25rem 0 0 !important;font-size: xx-small !important; "><i class="material-symbols-outlined" style="font-size: medium;  ">close</i>Remove Link</button>


                <ng-template ngFor let-link [ngForOf]="linkGroup">
                  <!--<span class="d-flex flex-column">{{clientName}}</span>   "-->
                  <!-- <button *ngIf="!autoLinkVisible && !showAssigned && messageGUID != '' && linkGroup.length ==1" mat-button class="btn-med" (click)="updatelinkMessage(element)" style="display: inline-flex; margin-left: 1em;font-size: xx-small !important; "><span class="mat-button-wrapper" style="font-size: xx-small;">Update Link</span></button>

                  <button *ngIf="autoLinkVisible" mat-button class="btn-med" (click)="autoLink(link.messageGUID)" style="display: inline-flex;  padding: -1px 0.25rem 0 0 !important">AutoLink {{linkGroup.length>1?link.message:''}}</button>
                   -->
                  <!-- {{linkGroup.length>1?link.message:''}} -->
                  <span class="d-flex flex-column">{{link.custName}}</span>

                <ng-template ngFor let-trip [ngForOf]="link.tripList">
                  <div>
                    <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" (click)="clickOpenTrip(trip.tripCodeGUID,trip.custGUID,trip.aircraftGUID,trip.legList);">{{trip.tripCode}}</a>
                  </div>

                    <div>
                      <ng-template ngFor let-leg [ngForOf]="trip.legList">
                        {{leg.tripLeg}}
                        <div class="d-flex flex-column">
                          <ng-template ngFor let-task [ngForOf]="leg.taskList">
                            <span>
                              {{task.serviceTypeDescription}} ({{task.taskStatus}})
                            </span>
                          </ng-template>
                        </div>
                      </ng-template>
                    </div>
                  </ng-template>
                </ng-template>
                <!-- <span class="d-flex flex-column">{{clientName}}</span> -->

              </div>
            </div>
          </div>
        </div>
        <div style="display:inline-flex">
          <div style="width:6em" *ngIf="messageGUID=='' || isDraft">
            <div>
              <div *ngIf="loading" class="spinner-border spinner-border-sm mr-1" style="margin-left:1.5em;margin-top:0.25em; z-index:100; position:absolute"></div>
              <button mat-button class="btn-high" *ngIf="!showSpin" [disabled]="loading" (click)="clickSend()"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em; margin-right:0.25em">send</i><div>Send</div></div></button>
            </div>
            <div style="margin-top:0.5em; width:5em;">
              <button *ngIf="(groundStopTaskGUID!='' || tripCodeGUID!='') && showCommsInTrip && hasDefualtEmails" mat-button class="btn-low" [disabled]="loadingPreSelectedCommm" (click)="clickLoadPreSelectedEmail()"><div>Load Pre</div><div style="margin-top:-0.5em">Selected</div><div style="margin-top:-0.5em">Comms</div></button>
            </div>
            <div style="margin-top:1.5em">
              <div *ngIf="groundStopTaskGUID!=''">
                <button mat-button class="btn-low" style="width:6em; padding-left:0.6em !important" (click)="clickTemplate()">Templates</button>
                <app-spin *ngIf="downloading2"></app-spin>
              </div>
              <div *ngIf="groundStopTaskGUID!='' && publicNote!=''" style="margin-top:0.25em">
                <button mat-button class="btn-low" [disabled]="loadingNote" style="width:6em; padding-left:0.25em !important" (click)="clickInsertPublicNote($event)"><div>Insert</div><div style="margin-top:-0.5em">Public Note</div></button>
              </div>
            </div>
          </div>
          <div>
            <div style="display: flex;" *ngIf="messageGUID=='' || isDraft">
              <div style="display: flex; justify-content:space-between; min-width:57em; height: 28px; margin-bottom:0.25em">
                <div style="display: flex; margin-right:0em; width:53.5em;" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
                  <div style="margin-right: 0.5em; width: 5em; text-align: right; font-size: small ">From</div>
                  <div style="border-bottom: 0.0625em solid lightgray; width: 30em; margin-bottom:0.25em; font-size: small">
                    {{emailFrom}}
                  </div>
                  <div class="d-flex" *ngIf="isPending && scheduleMessage!='' && scheduleMessage!=null">
                    <div style="font-size:small">Scheduled Delivery: {{scheduleMessage}}</div>
                    <button mat-button [disabled]="loading" class="btn-med ml-1 mr-1" (click)="deleteMessage(false)"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">delete</i><div>Delete</div></div></button>
                  </div>
                  <button mat-button *ngIf="!showSpin && isActive && isDraft && !isPending" [disabled]="loading" class="btn-med  mr-1 ml-2" (click)="deleteMessage(false)"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">delete</i><div>Delete Draft</div></div></button>
                  <button mat-button *ngIf=" (isDraft && linkGroup.length == 0)" [disabled]="loading" class="btn-med mr-1" (click)="linkMessage()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">add_link</i><div>Link</div></div></button>


                  <!-- *ngIf = " !showAssigned" -->
                  <div style="margin-top:-.5em; display: flex;" *ngIf="(messageFolderID!=0 || !isActive) && (messageGUID!='' && !isDraft && !showAssigned)">
                    <button mat-icon-button aria-label="previous message" [disabled]="isFirstMessage" (click)="showPrevious()">
                      <mat-icon>arrow_upward</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="next message" [disabled]="isLastMessage" (click)="showNext()">
                      <mat-icon>arrow_downward</mat-icon>
                    </button>
                  </div>

                  <!-- <button mat-button   [disabled]="loading" class="btn-med  mr-1 ml-2" (click)="deleteMessage()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">Flag</i><div>Flag</div></div></button>
                  <button mat-button *ngIf="!showSpin && isActive && isDraft && !isPending" [disabled]="loading" class="btn-med  mr-1 ml-2" (click)="deleteMessage()"><div style="display:flex"><i class="material-symbols-outlined" style="font-size: medium; margin-top: 0.25em; margin-right: 0.25em">delete</i><div>UnFlag</div></div></button> -->

                  <div style="float: right; margin-left: 17em;">
                    <button *ngIf="(isDraft || showAssigned || messageGUID== '') && showDraftBtn  " mat-button class="btn-high" (click)="saveMessageDraft(true)"><div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">save</i><div style="margin-left: 0.5em;">Save Draft</div></div></button>
                  </div>
                </div>

                <div style="float: right; margin-left: 5.5em; margin-top: -.5em;" align="end">
                  <button *ngIf="!isMinimized" mat-icon-button (click)="minimizeDialog()"> <i class="material-icons" style="font-size:x-large;  ">remove</i> </button>
                  <button mat-icon-button (click)="clickClose()"> <i class="material-icons" style="font-size: x-large;">close</i> </button>
                </div>

              </div>

            </div>
            <div class="d-inline-flex">
              <div>
                <div style="display: flex; " *ngIf="messageGUID=='' || isDraft">


                  <div>
                    <div style="display: flex;">
                      <div style="margin-right:0.5em;">
                        <button mat-button class="btn-low" (click)="clickEmailSelection()" [disabled]="messageGUID!='' && !isDraft">To</button>
                      </div>
                      <div>
                        <div>
                          <input type="text" formControlName="emailTo" (keydown)="emailToKeydown($event)" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.emailTo.errors }" style="font-size: small; padding: 0.25em;width:50em;height:2em;border-radius: 0.25em; background-color:#eaf0fd" [readonly]="messageGUID!='' && !isDraft" />
                        </div>
                        <div *ngIf="submitted && f.emailTo.errors" class="invalid-feedback" style="margin-top:0em; display:flex">
                          <!--<div *ngIf="f.emailTo.errors.required" style="background-color: #f9d2de;margin-top:0em;margin-left:0.25em; padding-left:0.25em; min-width:9em">Please enter at leaset one email address. </div>-->
                          <div *ngIf="f.emailTo.errors.validateEmail==false" style="background-color: #f9d2de;margin-top:0em;margin-left:0.25em; padding-left:0.25em; min-width:9em">{{wrongEmailToMsg}}</div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex;margin-top:0.5em">
                      <div style="display: flex; width: 45em; margin-right: 0.25em ">
                        <div style="margin-right:0.5em;">
                          <button mat-button class="btn-low" (click)="clickEmailSelection()" [disabled]="messageGUID!='' && !isDraft">Cc</button>
                        </div>
                        <div>
                          <input type="text" [readonly]="messageGUID!='' && !isDraft" formControlName="emailCc" class="form-control" (keydown)="emailCcKeydown($event)" [ngClass]="{ 'is-invalid': submitted && f.emailCc.errors}" style="font-size: small; padding: 0.25em;width:50em;height:2em;border-radius: 0.25em; background-color:#eaf0fd" />
                        </div>
                        <div *ngIf="submitted && f.emailCc.errors" class="invalid-feedback" style="margin-top:0em; display:flex">
                          <div *ngIf="f.emailCc.errors.validateEmail==false" style="background-color: #f9d2de;margin-top:0em;margin-left:0.25em; padding-left:0.25em; min-width:9em">{{wrongEmailCcMsg}}</div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div style="display: flex; margin-top:.5em " *ngIf="messageGUID=='' || isDraft">
                  <!--<div style="width:4em"></div>-->
                  <div style="display: flex;margin-bottom:0.25em;">
                    <div style="display:flex; margin-right: 0.25em">
                      <div style="margin-right:0.5em; width: 5em;text-align: right;font-size:small">Subject</div>
                      <div>
                        <div><input type="text" formControlName="subject" class="form-control" style="font-size: small; padding: 0.25em;width:50em;height:2em;background-color:#eaf0fd; border-radius: 0.25em" [ngClass]="{ 'is-invalid': submitted && f.subject.errors}" /></div>
                        <div *ngIf="submitted && f.subject.errors" class="invalid-feedback" style="margin-top:0em">
                          <div *ngIf="f.subject.errors.required" style="background-color: #f9d2de;margin-top:0em;margin-left:0.25em; padding-left:0.25em; width:9em">Subject is required</div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div style="display: flex;margin-bottom:0.25em; margin-right:.25em">
                  <!-- || messageAttachmentList.length>0 -->
                  <div *ngIf="messageGUID=='' || isDraft || messageAttachmentList.length>0 || (attachmentCount!=null && uploadCount!=null)" style="margin-bottom:0.25em; margin-right:.25em; text-align: right">
                    <div *ngIf="messageGUID=='' || isDraft" style="margin-right:0.75em;">
                      <label style="border: 0.0625em solid lightgray; width:3em; height:1.75em; border-radius: 0.25em; cursor:pointer;margin-left:0.5em; padding-left:0.25em; padding-right:0.75em; " title="Attach documents from your pc">
                        <input #document formControlName="document" id="document" type="file" ng2FileDrop [uploader]="uploader" multiple style="display:none" accept="*" (change)="importFile($event)" maxFileSize="28000000" (click)="onFileClick($event)" />
                        <i class="material-icons" style="font-size: x-large; transform: rotate(-45deg);color:gray;">attachment</i>
                      </label>
                      <div *ngIf="!finishAllUploads" class="spinner-border spinner-border-sm mr-1" style="position:absolute; margin-top:0.5em; margin-left:-1.75em"></div>
                    </div>
                    <!-- <div *ngIf="messageGUID!='' && !isDraft" style="margin-right:0.5em;">
    <label style="border: 0.0625em solid lightgray; width:3em; height:1.75em; border-radius: 0.25em; cursor:pointer;margin-left:0.5em; padding-left:0.25em; padding-right:0.75em; ">
      <i class="material-icons" style="font-size: x-large; transform: rotate(-45deg);color:gray;">attachment</i>
    </label>
  </div> -->
                    <div *ngIf="messageGUID!='' && !isDraft && messageAttachmentList.length>0" style="margin-right:0.5em; border: 0.0625em solid lightgray; display: flex; border-radius: 0.25em;  ">
                      <label style=" display: flex;   margin-top: 0.2em; margin-bottom: -0.01em;">
                        <i class="material-icons" style="cursor: pointer; font-size: large;  color:gray;  ">attach_file</i>
                        <i *ngIf="messageAttachmentList.length>0" class="material-icons" title="Download all attachments" style="  font-size: large; cursor: pointer;" (click)="downloadAllAttachments()">download</i>

                        <span style="margin-top: -0.2em;margin-right:.25em; font-size:small">{{messageAttachmentList.length}} </span>
                      </label>
                    </div>


                    <div *ngIf="messageGUID=='' || isDraft">
                      <div style="margin-left:0.5em; margin-top:-0.125em" *ngIf="groundStopTaskGUID!='' && taskDocCount>0">
                        <div style="border: 0.0625em solid lightgray; border-radius: 0.25em; cursor: pointer; padding: 0.25em 0.85em 0em 0em; width:3em; height:1.75em; " title="Attach task documents" (click)="clickTaskDocSelection()">
                          <i class="material-icons" style="font-size:large; transform: rotate(45deg); color: gray; ">file_present</i>
                        </div>
                      </div>

                      <div style="margin-left: 0.5em; margin-top: 0.25em" *ngIf="tripCodeGUID!=''">
                        <div style="border: 0.0625em solid lightgray; border-radius: 0.25em; cursor: pointer; padding: 0.25em 0.85em 0em 0em; width:3em; height:1.75em; " title="Attach trip documents" (click)="clickTripDocSelection()">
                          <i class="material-icons" style="font-size:large; transform: rotate(45deg); color: gray; ">file_present</i>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="attachmentCount!=null && uploadCount!=null" style="font-size:small; display:flex;margin-top:.5em">
                      <span matTooltip="Missing Attachments. Please view in Outlook." *ngIf="attachmentCount != uploadCount" class="material-symbols-outlined" style="font-size:medium;color:red;margin-top:.15em;margin-right:.25em">
                        error
                      </span><span *ngIf="attachmentCount != uploadCount"> {{uploadCount}}/{{attachmentCount}}</span>
                      <!-- <span *ngIf="attachmentCount == uploadCount"> {{attachmentCount}}</span> -->
                    </div>
                  </div>                  
                  <div *ngIf="messageGUID=='' || isDraft || messageAttachmentList.length>0 || embeddedImageListAtt.length>0" ng2FileDrop [uploader]="uploader"
                       style="max-height:8.5em;overflow:auto; border: 1px solid lightgray ;border-radius:0.25em;padding-left:0.25em"
                       [ngStyle]="{'width': messageGUID=='' || isDraft ? '40.5em' : '45.5em'}" (onFileDrop)="dropFile($event)">
                    <div>
                      <div *ngFor="let ma of messageAttachmentList; let i=index">
                        <div style="font-size: small; display:flex; padding:0em; margin-right:0.5em;">
                          <div style="white-space: nowrap">
                            <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" title="{{ma.documentName}}" (click)="clickPreviewDocument($event, ma,false)">{{ma.documentName}}</a>
                            <i class="material-icons" title="download {{ma.documentName}}" style=" font-size: medium; cursor: pointer;padding-left: 5px;" (click)="downloadAttachment(ma,false)">cloud_download</i>
                          </div>
                          <div *ngIf="messageGUID=='' || isDraft" style="margin-top:0.25em; margin-left: 0.25em"><i class="material-icons" style="color:red; font-size: small; cursor: pointer;" title="click to remove the document" (click)="clickRemoveDocument($event, ma)">cancel</i></div>
                        </div>
                      </div>
                      <div *ngFor="let ma of embeddedImageListAtt; let i=index">
                        <div style="font-size: small; display:flex; padding:0em; margin-right:0.5em;">
                          <div style="white-space: nowrap">
                            <a style="cursor: pointer; color:blue; font-size: small; text-decoration:underline" title="{{ma.documentName}}" (click)="clickPreviewDocument($event, ma,true)">{{ma.documentName}}</a>
                            <i class="material-icons" title="download {{ma.documentName}}" style=" font-size: medium; cursor: pointer;padding-left: 5px;" (click)="downloadAttachment(ma,true)">cloud_download</i>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="font-size:small; margin-top:.25em;margin-left:.5em" *ngIf="totalMessageAttachmentFileSize!=0">
                    {{totalMessageAttachmentFileSizeMB<1 ? totalMessageAttachmentFileSizeKB + ' KB' : totalMessageAttachmentFileSizeMB + ' MB'}}
                  </div>
                  <!-- <i *ngIf="messageAttachmentList.length>0" class="material-icons" title="Download all attachments" style="  font-size: large; cursor: pointer;" (click)="downloadAllAttachments()">download</i>  -->

                </div>

              </div>
              <div>
                <div *ngIf="((linkGroup.length>0 || clientName!='' || assignedTo!='' ) && isPending) || (messageGUID=='' ||  isDraft ||  showAssigned)" class="ml-2 p-2 border border-info rounded" style="font-size:small;width:22em;max-height:10em;overflow-y:auto">

                  <div style="display: flex;">
                    <label *ngIf="assignedTo!='' || (linkGroup.length>0 || clientName!=''  ||  isDraft || messageGUID =='')" style="font-size:small"
                           class="mr-1">Assigned To: {{assignedTo}}</label>
                    <button *ngIf="(!showAssigned && messageGUID != '' ) || (isDraft && linkGroup.length==1)" mat-button class="btn-med" (click)="updatelinkMessage(element)" style="display: inline-flex;font-size: xx-small !important; margin-left: 1em; padding: 0px 0.25rem 0 0 !important">Update Link</button>

                  </div>

                  <!-- <span *ngIf="linkGroup.length>0 || clientName!='' || trip_Code!=''" style="display: inline-flex;">Link Details </span> -->
                  <button *ngIf="(!showAssigned && messageGUID != '' ) || (isDraft && linkGroup.length>0)" mat-button class="btn-med" (click)="removelinkMessage('')" style="display: inline-flex; margin-left: 1em; padding: 0px 0.25rem 0 0 !important"><i class="material-symbols-outlined" style="font-size: medium;  ">close</i>Remove Link</button>
                  <!-- <button *ngIf="(!showAssigned && messageGUID != '' ) || (isDraft && linkGroup.length==1)" mat-button class="btn-med" (click)="updatelinkMessage(element)" style="display: inline-flex; margin-left: 1em; padding: 0px 0.25rem 0 0 !important">Update Link</button> -->

                  <div *ngIf=" showAssigned">
                    <!-- <span class="d-flex flex-column">{{clientName}}</span> -->
                    <a *ngIf="linkGroup.length == 0 " class="d-flex flex-column" (click)="clickOpenTrip(link.tripCodeGUID,link.custGUID,link.aircraftGUID);">
                      {{trip_Code}}
                    </a>
                  </div>


                  <span *ngIf="!showAssigned && linkGroup.length == 0" class="d-flex flex-column">{{clientName}}</span>

                  <ng-template ngFor let-link [ngForOf]="linkGroup">
                    <!-- <button mat-button class="btn-med" style="display: inline-flex; padding: 0px 0.25rem 0 0 !important; margin-left: 1em;" (click)="clickOpenTrip(link.tripCodeGUID,link.custGUID,link.aircraftGUID);">{{link.tripCode}}</button>-->
                    <!-- <div>
                      <label *ngIf="assignedTo!=''" style="font-size:small" class="mr-1">Assigned To: {{assignedTo}}</label>
                    </div> -->
                    <span class="d-flex flex-column">{{clientName}}</span>
                    <ng-template ngFor let-trip [ngForOf]="link.tripList">
                      <a class="d-flex flex-column" (click)="clickOpenTrip(trip.tripCodeGUID,trip.custGUID,trip.aircraftGUID);">  {{trip.tripCode}}</a>

                      <div>
                        <ng-template ngFor let-leg [ngForOf]="trip.legList">
                          {{leg.tripLeg}}
                          <div class="d-flex flex-column">
                            <ng-template ngFor let-task [ngForOf]="leg.taskList">
                              <span>
                                {{task.serviceTypeDescription}} ({{task.taskStatus}})
                              </span>
                            </ng-template>
                          </div>
                        </ng-template>
                      </div>
                    </ng-template>
                  </ng-template>

                </div>
              </div>
            </div>
          </div>


        </div>


        <div>
          <div style="display: flex;">
            <div *ngIf="showSuccessMsg">
              <app-success-message [successMsg]="successMsg"></app-success-message>
            </div>
            <div *ngIf="showSuccessMsg2">
              <app-success-message [successMsg]="successMsg2"></app-success-message>
            </div>
            <div *ngIf="showSuccessCopy">
              <app-success-message [successMsg]="successCopy"></app-success-message>
            </div>
            <div></div>
          </div>
          <div style="display: flex;">
            <div *ngIf="errMsg!=''" class="d-block" style="margin:0; margin-top:0em; background-color:#f9d2de; padding-left:0.25em; min-width:20em;font-size: small; ">{{errMsg}}</div>
            <div></div>
          </div>
        </div>
        <!--<div id="emailHTML" name="emailHTML" #emailHTML style="padding:1em; height:25em;width:71em;overflow:auto;border:solid thin;border-color:lightgray;border-radius:.25em" *ngIf="messageGUID!='' && !isDraft" [innerHTML]="getHTML(emailContent)">

        </div>-->
        <!--<div>
          <mat-radio-group id="displayType" (change)="updateDisplay()" aria-label="Select an option" [(ngModel)]="displayType" [ngModelOptions]="{standalone: true}">
            <mat-radio-button style="transform:scale(.75)" color="primary" value="html">HTML</mat-radio-button>
            <mat-radio-button style="transform:scale(.75)" color="primary" value="text">Text</mat-radio-button>
          </mat-radio-group>
        </div>-->
        <div>
          <ckeditor #emailCKEditor name="emailCKEditor" [editor]="Editor" [config]="CKEConfig" (ready)="onReady($event)" (change)="onEditorChange($event)" [disabled]="isDisabled" style="line-height:1.2em; background-color:#ffffff !important;"></ckeditor>
        </div>
        <!-- <div>
          <button (click)="copyToClipboard()">Copy to Clipboard</button>
          <span *ngIf="copied" class="success-message">Copied!</span>
        </div> -->
        <div style="font-size:x-small;display:flex; justify-content: space-between; " *ngIf="messageGUID!=''" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
          <i class="material-symbols-outlined"
             style="font-size: small;  color:royalblue;float:right;">drag_indicator</i>
          <a *ngIf="webLink!=''" href="{{webLink}}" target="_blank" rel="noopener noreferrer">View in Outlook</a>

          <span>
            <i class="material-symbols-outlined"
               style="font-size: small;  color:royalblue; margin-right: 0.25em; float:left" (click)="openClipboard()">info</i>
            <i class="material-symbols-outlined"
               style="font-size: small;  color:royalblue; margin-right: 0.25em; float:left" (click)="copyToClipboard()">content_copy</i>Message ID: {{messageGUID}}
          </span>
          <!-- <span><i class="material-symbols-outlined"
            style="font-size: small;  color:royalblue; margin-right: 0.25em; float:left" (click)="copyToClipboard()">content_copy</i>Gstop ID: {{groundStopGUID}}</span>
          <span><i class="material-symbols-outlined"
                style="font-size: small;  color:royalblue; margin-right: 0.25em; float:left" (click)="copyToClipboard()">content_copy</i>GsTask ID: {{groundStopTaskGUID}}</span>
              -->
        </div>
        <div style="font-size:x-small;display:flex;float:right; " *ngIf="messageGUID ==''" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
          <i class="material-symbols-outlined"
             style="font-size: small;  color:royalblue; margin-right: 86em">drag_indicator</i>
        </div>
      </form>

    </div>
    <app-spin *ngIf="showSpin"></app-spin>

  </div>
</div>
