import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType, HttpClientModule, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { GlobalConstant } from "../common-utility/global-constant";
import { BusinessRulesModel } from "../models/business-rules.model";
import { TripLegTypeModel } from "../models/trip-leg-type.model";
import { GroundStopModel } from "../models/ground-stop.model";
import { GroundStopTaskModel } from "../models/ground-stop-task.model";
import { TripModel } from "../models/trip.model";

@Injectable()
export class BusinessRulesService {
  constructor(private readonly http: HttpClient) {

  }

  getBusinessRulesByConditions<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesByConditions", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getBusinessRulesBybrId<T>(businessRulesID: number): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesBybrId?BusinessRulesID=" + businessRulesID, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getBusinessRulesClientList<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesClientList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }
  getBusinessRulesAircraftList<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesAircraftList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }
  getBusinessRulesVendorList<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesVendorList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }
  getBusinessRulesAirportList<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesAirportList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }
  getBusinessRulesPersonList<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesPersonList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripLegTypeList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "BusinessRules/getTripLegTypeList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  saveBusinessRules<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/saveBusinessRules", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getBusinessRulesApprovalStatusList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesApprovalStatusList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getBusinessRulesServiceStatusList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesServiceStatusList", {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getBusinessRulesCountryList<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesCountryList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getBusinessRulesLegTypeList<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesLegTypeList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getBusinessRulesServiceList<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesServiceList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTemplatesServiceList<T>(): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "BusinessRules/getTemplatesServiceList",  {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getBusinessRulesStatusList<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesStatusList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getBusinessRulesForGroundStopByTripCodeGUID<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesForGroundStopByTripCodeGUID", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  insertTaskFromBusinessRules<T>(request: GroundStopTaskModel[]): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/insertTaskFromBusinessRules", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  findSimilarBusinessRules<T>(request: BusinessRulesModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/findSimilarBusinessRules", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getBusinessRulesLocationsToAvoidByTripCodeGUID<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "BusinessRules/getBusinessRulesLocationsToAvoidByTripCodeGUID", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  addRemoveBusinessRules<T>(airportID: number, noteTypeID: number, isAdd: boolean): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "BusinessRules/addRemoveBusinessRules?airportID=" + airportID + "&noteTypeID=" + noteTypeID + "&isAdd=" + isAdd, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

}
