import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthenticateService } from '../../services/authenticate.service';
import { ResponseModel } from '../../models/response.model';
import { AirportModel } from '../../models/airport.model';
import { Observable, forkJoin } from 'rxjs';
import { VendorService } from 'src/app/services/vendor.service';
import { AdvisoryService } from 'src/app/services/advisory.service';
import { CommonService } from 'src/app/services/common.service';
import { VendorModel } from 'src/app/models/vendor.model';
import { AdvisoryLookupModel, AdvisoryModel } from 'src/app/models/advisory.model';
import { lookup } from 'dns';


@Component({
  selector: 'app-advisory-vendor-dialog',
  templateUrl: './advisory-vendor-dialog.component.html',
  styleUrls: ['./advisory-vendor-dialog.component.css']
})
export class AdvisoryVendorDialogComponent {
  isDialog: boolean;
  currentAdvisoryId: any;
  icao: any;
  errMsg: string;
  vendorList: VendorModel[];
  groundHandlerList: VendorModel[];
  // intop laneAgent: VendorModel[];
  ssnvendorList: VendorModel[];
  advisory_vendor_form: UntypedFormGroup;

  hotelName: string = '';
  vendorName: string = '';
  vendorGUID: string = '';
  airportID: number = 0;
  vendorID: number = 0;
  groundHandlerGUID: string = '';
  ssnVendorGUID: string = '';

  fuelSupplierList: AdvisoryLookupModel[];
  intoplaneAgentList: AdvisoryLookupModel[];
  ipaVendorID: any;
  vendorFuelID: any;
  ipaAgent: any;
  fslookupGUID: any;
  showModified: boolean = true;
  originalIntoplaneAgentList: AdvisoryLookupModel[];

  hasGroundHandlerTag: boolean = false;
  hasFuelTag: boolean = false;


  constructor(@Optional() private readonly _dialogRef: MatDialogRef<AdvisoryVendorDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any, private readonly _authService: AuthenticateService,
    private readonly _vendorService: VendorService,
    private readonly _advisoryService: AdvisoryService, private readonly _commonService: CommonService,
    private readonly _formBuilder: UntypedFormBuilder,
  ) {

    if (this.data) {
      this.isDialog = true;
      // this.currentAdvisoryId = data.advisoryId;
    }

    this.currentAdvisoryId = data.advisoryId;
    this.icao = data.icao;
    this.groundHandlerGUID = data.groundHandlerGUID;
    this.ssnVendorGUID = data.ssnVendorGUID;
    this.fslookupGUID = data.fuelSupplierGUID;
    this.hasGroundHandlerTag = data.hasGroundHandlerTag;
    this.hasFuelTag = data.hasFuelTag;

    if (this.fslookupGUID != '' && this.fslookupGUID != null) {
      this.showModified = false;
    }
    else {

      this.showModified = true;
    }
    this.ipaAgent = data.intoplaneAgent;

  }

  ngOnInit() {



    this.getFuelSupplierAndIPA();



    let request = new VendorModel();
    request.searchKey = this.icao;
    request.searchType = 'airport';

    this._vendorService.getVendorBySearchKey<ResponseModel<VendorModel[]>>(request).subscribe(response => {
      if (response != null) {
        if (response.code == "200" && response.message == "") {
          if (response.model.length == 0) {
            this.errMsg = "No results found";
            // this.isLoading = false;
            return;
          }
          this.vendorList = response.model;

          this.groundHandlerList = this.vendorList.filter(x => ((x.vendorRoleDescription != 'Fuel Supplier') && (x.vendorRoleDescription != 'SSN - Supervision')));
          this.ssnvendorList = this.vendorList.filter(x => x.vendorRoleDescription == 'SSN - Supervision' && x.mainVendorName == '');
          // this.fuelSupplierList = this.vendorList.filter(x => (x.vendorRoleDescription == 'Fuel Supplier' && x.vendorFuelID != -1 && x.mainVendorName == ''));
          // this.intopla neAgentList = this.vendorList.filter(x => (x.vendorRoleDescription == 'Fuel Supplier' && x.vendorFuelID != -1 && x.mainVendorName != ''));




          this.initControls();


        }
      }
    });

    // this.initControls();


  }


  initControls() {

    this.advisory_vendor_form = this._formBuilder.group({


      airportID: [this.airportID],
      icao: [this.icao, Validators.required],
      vendorName: [this.vendorName],
      vendorGUID: [this.vendorGUID],
      vendorFuelID: [this.vendorFuelID],
      groundHandlerGUID: [this.groundHandlerGUID],
      ssnVendorGUID: [this.ssnVendorGUID],
      ipaVendorID: [this.ipaVendorID],
      fslookupGUID: [this.fslookupGUID],
      ipAgent: [''],
      ipaAgent: [{ value: this.ipaAgent, disabled: this.showModified }],


    });

    // this.checkServiceTypeArray = new UntypedFormControl(this.selectedServiceTypeList); // for mat-select
    // this.checkTaskStatusArray = new UntypedFormControl(this.selectedTaskStatusList); // for mat-select
  }


  getFuelSupplierAndIPA() {
    let request = new AdvisoryModel();

    request.icao = this.icao;
    this._advisoryService.getFuelSuppliersAndIntoplaneAgentsForAdvisories<ResponseModel<AdvisoryModel>>(request).subscribe(response => {
      if (response != null && response.code == "200") {
        this.fuelSupplierList = response.model.fuelSupplierList;
        this.originalIntoplaneAgentList = response.model.intoplaneAgentList;


        if (this.ipaAgent != '') {
          this.intoplaneAgentList = this.originalIntoplaneAgentList?.filter(x => x.lookupGUID == this.fslookupGUID);

          this.ipaAgent = this.originalIntoplaneAgentList?.find(x => x.lookupGUID == this.fslookupGUID)?.description;
          this.showModified = false;
        }
        if (this.fslookupGUID != '' && this.fslookupGUID != null) {
          this.showModified = false;
        }
        else {

          this.showModified = true;
        }

      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }

    })
  }

  get f() { return this.advisory_vendor_form.controls; }



  clickClose() {
    this._dialogRef.close();
  }

  clickUpdate() {

    // this.f.ipAgent.setValue(this.f.ipaAgent.value);
    let result = this.advisory_vendor_form.value;

    if (this.hasGroundHandlerTag) {

      result.groundHandlerVendorName = this.groundHandlerList?.filter(x => x.vendorGUID == this.f.groundHandlerGUID.value)[0]?.vendorName;
      result.ssnVendorName = this.ssnvendorList?.filter(x => x.vendorGUID == this.f.ssnVendorGUID.value)[0]?.vendorName;
    } else {
      result.groundHandlerVendorName = '';
      result.ssnVendorName = '';
      result.groundHandlerGUID = '';
      result.ssnVendorGUID = '';
    }

    if (this.hasFuelTag) {
      result.fuelSupplierName = this.fuelSupplierList?.filter(x => x.lookupGUID == this.f.fslookupGUID.value)[0]?.description;
      result.intoplaneAgent = this.f.ipaAgent.value;
    } else {
      result.fuelSupplierName = '';
      result.intoplaneAgent = '';
      result.fslookupGUID = '';
    }

    this._dialogRef.close(result);
  }

  onFuelSupplierChange() {

    this.intoplaneAgentList = this.originalIntoplaneAgentList?.filter(x => x.lookupGUID == this.f.fslookupGUID.value);
    if (this.f.fslookupGUID.value != null && this.f.fslookupGUID.value != '') {
      this.showModified = false;
    }
    else {
      this.showModified = true;
      this.f.ipaAgent.setValue('');
    }

  }





}
