<div *ngIf="upPII" style="background-color:#f8f8f8; overflow-x:hidden; overflow-y:hidden">
  <div style="display: flex; padding-left:1em; justify-content: space-between;" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div style="display: flex;">
      <div>
        <i class="material-icons-outlined" style="font-size: xx-large;cursor: pointer; color: gray">contact_page</i>
      </div>
      <div style="margin-top:0.3125em;display:flex">
        <h5>Contacts {{userType=='internal'?'- Delivery Comms':''}}</h5>
      </div>
    </div>

    <div style="display: flex;">
      <button mat-icon-button mat-dialog-close><i class="material-icons" style="font-size: x-large;">close </i></button>
    </div>
  </div>
  <div>
    <form *ngIf="delivery_comms_form" [formGroup]="delivery_comms_form">
      <div style="display:flex; margin-top:0em; padding-left: 1em; padding-right:1em; ">
        <div style="margin-top:0em;background-color:white; padding:0.5em 0.5em 0.5em 0.5em;">
          <div *ngIf="userType=='internal'">
            <div style="font-size:small; font-weight: bold">Selected Comms</div>
            <div style="display: flex">
              <div style="margin-right:0.5em">
                <textarea formControlName="selected_comms"
                  style="width:50em; height:5em;font-size:small; padding:0em 0em 0em 0.25em;background-color:#eaf0fd"></textarea>
              </div>
              <div>
                <button mat-button class="btn-high" [cdkCopyToClipboard]="f.selected_comms.value">
                  <div>Copy to</div>
                  <div>Clipboard</div>
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="userType=='customer'" style="display:flex;">
            <div style="font-size: small; margin-right:1em">
              <div style="font-weight:bold">Flight Pro International - Operations</div>
              <ul style="margin-left:-2em">
                <li>Operations Phone: {{opsPhone}}</li>
                <li>Operations Email: {{opsEmail}}</li>
                <li>Sales Email Address: {{salesEmail}}</li>
                <li>FPI Office Address: {{fpiAddress}}</li>
              </ul>
            </div>
            <div><img src="../../assets/images/FlightProLogoWhiteBG.png" style="border:0em" /></div>
          </div>
          <div class="example-action-buttons" *ngIf="personClassList.length>0 || gsList.length>0"
            style="margin-left:-1em; margin-top:-0.5em">
            <button mat-button (click)="openAll()" color="primary" style="outline:none; font-size: small">Expand
              All</button>
            <button mat-button (click)="closeAll()" color="primary" style="outline: none; font-size: small">Collapse
              All</button>
          </div>
          <div style="max-height:31em; overflow-y: auto; margin-top:-0.5em">
            <!--crew/pax-->
            <div style="margin-left:-1em">
              <mat-accordion class="example-headers-align" [multi]="true" style="padding-left:0em !important">
                <mat-expansion-panel *ngFor="let item of personClassList, let i=index" [expanded]="item.panelOpenState"
                  (opened)="item.panelOpenState=true" (closed)="item.panelOpenState=false">
                  <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'"
                    style="font-weight:500; font-size:medium !important">
                    <mat-panel-title>
                      <div>
                        Client - {{item.personClassDescription}}
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div>
                    <table mat-table *ngIf="item.personList" [dataSource]="item.personList"
                      class="example-container mat-elevation-z8">
                      <tr>
                        <ng-container matColumnDef="personType">
                          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:15%">
                            <div style="margin-left: 0.5em">Address Use</div>
                          </th>
                          <td mat-cell *matCellDef="let element"
                            style="line-height: 1.5625em; text-align: left !important; vertical-align:top;">
                            <div style="width: 8em;">{{element.personType}}</div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="contact">
                          <th mat-header-cell *matHeaderCellDef style="text-align: left !important;width:15%">
                            <div style="margin-left: 0.5em">Contact Information</div>
                          </th>
                          <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; vertical-align:top">
                            <div style="width: 20em;">{{element.personName}} <div style="display: inline-flex;"
                                *ngIf="element.personName != ''">&nbsp;-&nbsp;</div> {{element.jobTitle}}</div>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="comms">
                          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:30%">
                            <div style="margin-left: 0em">Comms</div>
                          </th>
                          <td mat-cell *matCellDef="let element"
                            style="line-height: 1.5625em; text-align: left !important; ">
                            <div *ngFor="let c of element.commAddressList; let i=index" style="display: flex">
                              <div style="margin-right: 0.5em; width:7em">{{c.commAddressType}}</div>
                              <div style="margin-right: 0.5em; width:20em; word-wrap: break-word;" class="link"
                                (click)="clickSelect($event, c);">{{c.address}}</div>
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="tags">
                          <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:25%">
                            <div style="margin-left: 0em">Tags & Remarks</div>
                          </th>
                          <td mat-cell *matCellDef="let element"
                            style="line-height: 1.5625em; text-align: left !important; ">
                            <div *ngFor="let c of element.commAddressList; let i=index" style="display: flex">
                              <div style="margin-right: 0.5em; width:20em">{{c.addressUseDescriptionList}}</div>
                              <div style="margin-right: 0.5em; width: 15.5em;">
                                <a *ngIf="c.commRemarks && c.commRemarks.length>20" style="cursor:pointer"
                                  matTooltipClass="tooltipLineBreak" matTooltip="{{c.commRemarks}}">{{
                                  c.commRemarks.substring(0,19)+'...' }}</a><span
                                  *ngIf="c.commRemarks && c.commRemarks.length<=20">{{c.commRemarks}}</span>
                              </div>
                              <!--<div style="margin-right: 0.5em; width: 10em;">{{c.commRemarks}}</div>-->
                              <div><a mat-button class="btn-high grid" style="align-self:center;"
                                  (click)="clickSelect($event, c);">Select</a></div>
                            </div>
                          </td>
                        </ng-container>
                      </tr>
                      <tr mat-header-row *matHeaderRowDef="displayedColumnPerson; sticky: true"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumnPerson"></tr>
                    </table>
                  </div>

                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <!--crew/pax end-->
            <!--vendor-->
            <div *ngFor="let g of gsList; let i=index" style="font-size: small;">
              <div style="font-weight: bold">{{g.icao}}{{g.iata!=''?' /
                '+g.iata:''}}&nbsp;{{g.airportName!=''?g.airportName+', ':''}}{{g.airportCountryName}}</div>
              <div style="margin-left:-1em">
                <mat-accordion class="example-headers-align" [multi]="true" style="padding-left:0em !important">
                  <mat-expansion-panel *ngFor="let item of g.groundStopTaskList, let i=index"
                    [expanded]="item.panelOpenState" (opened)="item.panelOpenState=true"
                    (closed)="item.panelOpenState=false">
                    <mat-expansion-panel-header [collapsedHeight]="'2em'" [expandedHeight]="'2em'"
                      style="font-weight:500; font-size:medium !important">
                      <mat-panel-title>
                        <div>
                          {{item.countryName!=''?item.countryName+'
                          '+item.serviceTypeDescription:item.serviceTypeDescription}}&nbsp;-&nbsp;{{item.vendorName}}
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                      <table mat-table *ngIf="item.personList" [dataSource]="item.personList"
                        class="example-container mat-elevation-z8">
                        <tr>
                          <ng-container matColumnDef="contact">
                            <th mat-header-cell *matHeaderCellDef style="text-align: left !important;width:20%">
                              <div style="margin-left: 0.5em">Contact Information</div>
                            </th>
                            <td mat-cell *matCellDef="let element" style="line-height: 1.5625em; vertical-align:top">
                              <div style="width: 28.25em;">{{element.personName}} <div style="display: inline-flex;"
                                  *ngIf="element.personName != ''">&nbsp;-&nbsp;</div> {{element.jobTitle}}</div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="comms">
                            <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:35%">
                              <div style="margin-left: 0em">Comms</div>
                            </th>
                            <td mat-cell *matCellDef="let element"
                              style="line-height: 1.5625em; text-align: left !important; ">
                              <div *ngFor="let c of element.commAddressList; let i=index" style="display: flex">
                                <div style="margin-right: 0.5em; width:7em">{{c.commAddressType}}</div>
                                <div style="margin-right: 0.5em; width:20em; word-wrap: break-word;" class="link"
                                  (click)="clickSelect($event, c);">{{c.address}}</div>

                                <!--<div style="margin-right: 0.5em; width: 10em;">{{c.commRemarks}}</div>-->
                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="tags">
                            <th mat-header-cell *matHeaderCellDef style="text-align: left !important; width:45%">
                              <div style="margin-left: 0em">Tags & Remarks</div>
                            </th>
                            <td mat-cell *matCellDef="let element"
                              style="line-height: 1.5625em; text-align: left !important; ">
                              <div *ngFor="let c of element.commAddressList; let i=index" style="display: flex">
                                <div style="margin-right: 0.5em; width:20em">{{c.addressUseDescriptionList}}</div>
                                <div style="margin-right: 0.5em; width: 15.5em;">
                                  <a *ngIf="c.commRemarks && c.commRemarks.length>20" style="cursor:pointer"
                                    matTooltipClass="tooltipLineBreak" matTooltip="{{c.commRemarks}}">{{
                                    c.commRemarks.substring(0,19)+'...' }}</a><span
                                    *ngIf="c.commRemarks && c.commRemarks.length<=20">{{c.commRemarks}}</span>
                                </div>
                                <!--<div style="margin-right: 0.5em; width: 10em;">{{c.commRemarks}}</div>-->
                                <div><a mat-button class="btn-high grid" style="align-self:center;"
                                    (click)="clickSelect($event, c);">Select</a></div>
                              </div>
                            </td>
                          </ng-container>

                        </tr>
                        <tr mat-header-row *matHeaderRowDef="displayedColumnVendor; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnVendor"></tr>
                      </table>
                    </div>

                  </mat-expansion-panel>
                </mat-accordion>
              </div>

            </div>
            <!--vendor end-->

          </div>

        </div>
      </div>
    </form>
  </div>
  <div
    style="justify-content:flex-start;padding-left: 1em; padding-top:0.5em; padding-bottom:0.5em; display: flex;height:3em">
    <div style="margin-right:1em">

      <button mat-button class="btn-low" (click)="clickClose()" style="background-color:grey; color: white;">
        <div style="display:flex"><i class="material-icons" style="font-size: medium; margin-top:0.25em">close</i>
          <div>Close</div>
        </div>
      </button>
    </div>

  </div>
</div>

<!--<app-spin *ngIf="showSpin"></app-spin>-->