import { AdvisoryModel } from "./advisory.model";
import { AirportModel } from "./airport.model";
import { VendorModel } from "./vendor.model";

export class TripPlannerModel {
  airportList: string;
  customerGUID: string;
  countryID: number;
  stateProvinceID: number;
  farTypeID: number;
  tripCodeGUID: string;
  daysOut: string;
  airport: AirportModel;
  vendor: VendorModel;
  airportID: number;
  vendorGUID: string;
  ssnVendorGUID: string;
  ssnVendorID: number;
  advisories: AdvisoryModel[];
}
