import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, OnChanges, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { UserModel } from '../models/user.model';
import { CustomerModel } from '../models/customer.model';
import { VendorModel } from '../models/vendor.model';
import { ApplicationModel } from '../models/application.model';
import { AuthenticateService } from '../services/authenticate.service';
import { UserTypeModel } from '../models/user-type.model';
import { Observable, forkJoin, Subscription, of } from 'rxjs';
import { IdentityService } from '../services/identity.service';
import { ResponseModel } from '../models/response.model';
import { CommonService } from '../services/common.service';
import { CustomValidators } from '../common-utility/custom.validators';
import { DialogService } from '../services/dialog.service';
import { ConfirmDialogComponent } from '../common-utility/confirm-dialog.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UtilityFunctions } from '../common-utility/utility.functions';
import { CustomerGroupModel } from '../models/customer-group.model';
import { CustomerGroupService } from '../services/customer-group-service';
import { GlobalConstant } from '../common-utility/global-constant';
import { CustomerProfileSetupComponent2 } from '../customers/customer-profile-setup2.component';
import { AdminServicesClientComponent } from './admin-services-client.component';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})

export class RegisterUserComponent implements OnInit, AfterViewInit {
  register_user_form: UntypedFormGroup;
  selectedClientName: string = "";
  userSaved: boolean = false;
  loading = false;
  submitted = false;
  user: UserModel;
  errMsg: string;
  msg: string;
  customerList: CustomerModel[];
  vendorList: VendorModel[];
  appList: ApplicationModel[];
  userTypeList: UserTypeModel[];
  hidePwd: boolean = true;
  hideCPwd: boolean = true;
  selectedUserType: string = ""; //UserTypeModel;
  selectedUserTypeDisplay: string = ""; //UserTypeModel;
  selectedAppList: string;
  internalEmailEndsWithList: string[];
  changePwdInDays: number;
  userType: string;
  showSpin: boolean = false;
  passUsername: number = 0;
  private dialogRef: MatDialogRef<ConfirmDialogComponent>;
  private eventSubscription: Subscription;
  ua: number;
  grantAccessToAccountGrouping: boolean = false;
  customerGUID: string = "";
  user_role: string = "s";
  c_grantAccessToManageManifestProfiles: boolean = false;
  c_grantAccessCompanyProfiles: boolean = false;
  c_grantAccessAircraftProfiles: boolean = false;
  c_grantAccessSubmitTripsandRequestQuotes: boolean = false;
  c_grantAccessToMessages: boolean = false;
  c_grantAccessToManageCompanyEvents: boolean = false;
  c_grantAccessToInvoices: boolean = false;
  c_grantAccessToPIIData: boolean = false;
  c_grantAccessToAccountGrouping: boolean = false;
  c_grantAccessToAllMessages: boolean = false;
  customerGroupList: CustomerGroupModel[] = [];
  customerGroupGUID: string = "";
  i_grantAccessToManageManifestProfiles: boolean = false;
  i_grantAccessCompanyProfiles: boolean = false;
  i_grantAccessAircraftProfiles: boolean = false;
  i_grantAccessToMessages: boolean = false;
  i_grantAccessToLockedTrips: boolean = false;
  i_grantAccessToInvoices: boolean = false;
  i_grantAccessToCompanyBusinessRules: boolean = false;
  i_grantAccessToVendorProfiles: boolean = false;
  i_grantAccessCreateTripAlerts: boolean = false;
  i_grantAccessToManageCompanyEvents: boolean = false;
  i_grantAccessToHeadlineNews: boolean = false;
  i_grantAccessToPIIData: boolean = false;
  i_grantAccessToManageAccountGrouping: boolean = false;
  i_grantAccessToFuel: boolean = false;
  i_grantAccessToAirport: boolean = false;
  i_grantAccessToAPIS: boolean = false;
  i_grantAccessToTripCloseout: boolean = false;
  i_grantAccessSubmitToBilling: boolean = false;
  i_grantAccessToMessageTemplates: boolean = false;
  i_grantAccessToHelpGuides: boolean = false;
  i_grantAccessToAccountingDashboard: boolean = false;
  isPopup: boolean = false;

  constructor(private _router: Router, private _route: ActivatedRoute,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _identityService: IdentityService, private readonly _commonService: CommonService,
    private readonly _authService: AuthenticateService, private readonly _dialogService: DialogService, private readonly _customerGroupService: CustomerGroupService,
    private readonly _dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private readonly _dialogRef: MatDialogRef<RegisterUserComponent>) {
    
  }

  getAllData(): Observable<any[]> {
    let getAppsResponse = this._identityService.getApplications<ResponseModel<ApplicationModel[]>>();
    let getChangePwdInDays = this._identityService.getPwdExpirationPolicyInDays<ResponseModel<number>>();
    let getInternalEmailResponse;
    let getCustomersResponse;
    let getCustomerGroupResponse;

    getInternalEmailResponse = this._identityService.getInternalEmailEndsWith<ResponseModel<string[]>>();
    if (this.userType == "internal") {
      if (this.selectedUserType == "customer") {
        getCustomersResponse = this._commonService.getCustomers<ResponseModel<CustomerModel[]>>();
      }
      else {
        getCustomersResponse = of(null);
      }

      getCustomerGroupResponse = of(null);
    }
    else {
      if (this.userType == "customer") {
        if (this.grantAccessToAccountGrouping) {
          let req = new CustomerGroupModel();
          getCustomersResponse = this._customerGroupService.getSelectedCustomerListBycgrpId(req);
          getCustomerGroupResponse = this._customerGroupService.getCustomerGroupListBycId(req);
        }
        else {
          getCustomersResponse = of(null);
          getCustomerGroupResponse = of(null);
        }
      }
      else {
        getCustomersResponse = of(null);
        getCustomerGroupResponse = of(null);
      }
    }
    return forkJoin([getAppsResponse, getChangePwdInDays, getInternalEmailResponse, getCustomersResponse, getCustomerGroupResponse]);
  }

  ngOnInit() {
    this._authService.updateAccessTime();
    //this.ua = Number(localStorage.getItem('ua'));
    this.ua = 0;
    if (localStorage.getItem('up.isA') != null && localStorage.getItem('up.isA') != "") {
      if (localStorage.getItem('up.isA').toLowerCase() == localStorage.getItem('up.isAB').toLowerCase()) {
        this.ua = 1;
      }
    }
    if (this.ua != 1) {
      this._dialogService.openAlertDialog("Permission denied.", "OK");
      this._router.navigate(['/common-utility/blank']);
      return;
    }

    switch (localStorage.getItem('ut').toLowerCase()) {
      case "i":
        this.userType = "internal";
        break;
      case "c":
        this.userType = "customer";
        break;
      case "v":
        this.userType = "vendor";
        break;
      default:
        this.userType = "";
        break;
    }

    if (this.data) {
      this.isPopup = true;
      this.customerGUID = this.data.customerGUID;
      this.selectedClientName = this.data.customerName;
      this.selectedUserType = this.data.selectedUserType;
      if (this.selectedUserType != "" && this.selectedUserType != undefined && this.selectedUserType != null) {
        if (this.userType == "internal") {
          if (this.selectedUserType == "customer") {
            this.selectedUserTypeDisplay = "Client";
            this.getCustomerGroupList();
          }
          else {
            this.selectedUserTypeDisplay = "FPC Operations";
          }
        }
        else {
          this.selectedUserTypeDisplay = "";
        }
      }
      else {
        this.selectedUserTypeDisplay = "";
      }
    }
    else {
      this._route.queryParams.subscribe(params => {
        this.selectedUserType = params["selectedUserType"];
        if (this.selectedUserType != "" && this.selectedUserType != undefined && this.selectedUserType != null) {
          if (this.userType == "internal") {
            if (this.selectedUserType == "customer") {
              this.selectedUserTypeDisplay = "Client";
              this.getCustomerGroupList();
            }
            else {
              this.selectedUserTypeDisplay = "FPC Operations";
            }
          }
          else {
            this.selectedUserTypeDisplay = "";
          }
        }
        else {
          this.selectedUserTypeDisplay = "";
        }
  
      });
    }

    if (this.selectedUserType == "" || this.selectedUserType == undefined || this.selectedUserType == null) {
      this.selectedUserType = "customer";

    }

    if (localStorage.getItem('up.cGrp') == 'true') {
      this.grantAccessToAccountGrouping = true;
    }
    this.showSpin = true;
    this.errMsg = "";
    this.msg = "";
    this.passUsername = 0;
    this.userTypeList = [];
    this.appList = [];
    this.vendorList = [];
    this.customerList = [];
    this.internalEmailEndsWithList = [];


    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.appList = responses[0].model;
          this.selectedAppList = this.appList[0].applicationId;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[1] != null) {
        if (responses[1].code == "200" && responses[1].message == "") {
          this.changePwdInDays = responses[1].model;
        }
        else {
          if (responses[1].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[2] != null && responses[2].message == "") {
        if (responses[2].code == "200") {
          this.internalEmailEndsWithList = responses[2].model;
        }
        else {
          if (responses[2].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[3] != null && responses[3].message == "") {
        if (responses[3].code == "200") {
          this.customerList = responses[3].model;
          if (this.customerList.length > 0) {
            if (this.userType == "customer" && (this.customerGUID == "" || this.customerGUID == undefined)) {
              this.customerGUID = this.customerList.find(x => x.isDefaultClient == true).customerGUID.toLowerCase();
            }
          }
        }
        else {
          if (responses[3].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (responses[4] != null && responses[4].message == "") {
        if (responses[4].code == "200") {
          this.customerGroupList = responses[4].model;
        }
        else {
          if (responses[4].code == "401") {
            this._authService.signOut();
          }
        }
      }

      //if (this.userType == "internal") {
      //  this.user_role = "a";
      //  this.selectedUserType = 'internal';
      //}
      //else {
      //  this.selectedUserType = this.userType;
      //  this.user_role = "s";
      //}

      if (this.selectedUserType == "internal") {
        this.user_role = "a";
      }
      else {
        this.user_role = "s";
      }

      this.setPermission(false);

      this.initControls();

      this.showSpin = false;


    });

  }

  ngAfterViewInit() {
    //setTimeout(() => {
    //  this.showSpin = false;
    //}, 100);

  }

  initControls() {

    this.register_user_form = this._formBuilder.group({
      //user_type: [this.userTypeList[0]], //[this.selectedUserType],
      customer_select: this.customerGUID,
      vendor_select: null,
      //first_name: ['', Validators.required],
      //last_name: ['', Validators.required],
      first_name: ['', Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z ',-.]*$")]
      )],
      last_name: ['', Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z ',-.]*$")]
      )],
      job_title: ['', Validators.compose([
        Validators.pattern("^[a-zA-Z ',-.]*$")]
      )],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,100}$")]
      )],
      password_email: ['', Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,100}$")]
      )],
      phone_number: ['', Validators.compose([
        Validators.required,
        //Validators.pattern("^[a-zA-Z0-9- ().]*$")]
        Validators.pattern("^[0-9- ().]*$")]
      )],
      password_phone_number: ['', Validators.compose([
        Validators.required,
        //Validators.pattern("^[a-zA-Z0-9- ().]*$")]
        Validators.pattern("^[0-9- ().]*$")]
      )],
      username: ['', Validators.required],
      //username: ['', Validators.compose([
      //  Validators.required,
      //  Validators.minLength(8),
      //  Validators.maxLength(50),
      //  Validators.pattern("^[a-zA-Z0-9._]{8,50}$"),
      //  CustomValidators.cannotContainSpace,
      //  CustomValidators.cannotContainEmailSign
      //]
      //)],
      //password: ['', Validators.compose([
      //  Validators.required,
      //  Validators.minLength(8),
      //  Validators.maxLength(50),
      //  Validators.pattern("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~`!@#$%^&*()_\\-+={}|;:<>,.?/'\"\\\\\\[\\]])([a-zA-Z0-9~`!@#$%^&*()_\\-+={}|;:<>,.?/'\"\\\\\\[\\]]{8,50})$")
      //]
      //)],
      //confirm_password: ['', Validators.required],
      //auto_generate_pwd: false,
      //enable_change_pwd_policy: false,
      isAdmin: false,
      user_role: this.user_role,
      c_grantAccessToManageManifestProfiles: this.c_grantAccessToManageManifestProfiles,
      c_grantAccessCompanyProfiles: this.c_grantAccessCompanyProfiles,
      c_grantAccessAircraftProfiles: this.c_grantAccessAircraftProfiles,
      c_grantAccessSubmitTripsandRequestQuotes: this.c_grantAccessSubmitTripsandRequestQuotes,
      c_grantAccessToMessages: this.c_grantAccessToMessages,
      c_grantAccessToAllMessages: this.c_grantAccessToAllMessages,
      c_grantAccessToManageCompanyEvents: this.c_grantAccessToManageCompanyEvents,
      c_grantAccessToInvoices: this.c_grantAccessToInvoices,
      c_grantAccessToPIIData: this.c_grantAccessToPIIData,
      c_grantAccessToAccountGrouping: this.c_grantAccessToAccountGrouping,
      customer_group_select: this.customerGroupGUID,
      i_grantAccessToManageManifestProfiles: this.i_grantAccessToManageManifestProfiles,
      i_grantAccessCompanyProfiles: this.i_grantAccessCompanyProfiles,
      i_grantAccessAircraftProfiles: this.i_grantAccessAircraftProfiles,
      i_grantAccessToMessages: this.i_grantAccessToMessages,
      i_grantAccessToLockedTrips: this.i_grantAccessToLockedTrips,
      i_grantAccessToInvoices: this.i_grantAccessToInvoices,
      i_grantAccessToCompanyBusinessRules: this.i_grantAccessToCompanyBusinessRules,
      i_grantAccessToVendorProfiles: this.i_grantAccessToVendorProfiles,
      i_grantAccessCreateTripAlerts: this.i_grantAccessCreateTripAlerts,
      i_grantAccessToManageCompanyEvents: this.i_grantAccessToManageCompanyEvents,
      i_grantAccessToHeadlineNews: this.i_grantAccessToHeadlineNews,
      i_grantAccessToPIIData: this.i_grantAccessToPIIData,
      i_grantAccessToManageAccountGrouping: this.i_grantAccessToManageAccountGrouping,
      i_grantAccessToFuel: this.i_grantAccessToFuel,
      i_grantAccessToAirport: this.i_grantAccessToAirport,
      i_grantAccessToAPIS: this.i_grantAccessToAPIS,
      i_grantAccessToTripCloseout: this.i_grantAccessToTripCloseout,
      i_grantAccessSubmitToBilling: this.i_grantAccessSubmitToBilling,
      i_grantAccessToMessageTemplates: this.i_grantAccessToMessageTemplates,
      i_grantAccessToHelpGuides: this.i_grantAccessToHelpGuides,
      i_grantAccessToAccountingDashboard: this.i_grantAccessToAccountingDashboard
    });

  }

  get f() { return this.register_user_form.controls; }

  openAdminServicesComponent() {
    const config = new MatDialogConfig();
    config.panelClass = "custom-dialog-container3"
    //config.minWidth = "76em";
    //config.maxWidth = "92em";//"1000px";
    config.height = "max-content";
    config.width = "max-content";
    config.restoreFocus = false;
    config.hasBackdrop = false;
    config.position = { left: "10px", top: "140px" };
    config.data = { gsId: "", tripCodeId: "", isDialog: true, v: this._authService.getCurrentTimeNumber(), 
      customerGUID: this.customerGUID, customerName: this.selectedClientName, userType: this.data.selectedUserType };

    this.clickClose(true);

    this._dialog.open(AdminServicesClientComponent, config);
  }

  clickClose(val) {
    this._dialogRef.close(val);
  }

  saveUser() {

  }

  addNewUser() {
    this.f.first_name.reset();
    this.f.last_name.reset();
    this.f.job_title.reset();
    this.f.email.reset();
    this.f.password_email.reset();
    this.f.phone_number.reset();
    this.f.password_phone_number.reset();
    this.f.username.reset();

    this.msg = "";
    this.userSaved = false;
  }

  setPermission(afterInitControl: boolean) {
    if (this.selectedUserType == 'internal') {
      if (this.user_role == 'a') {
        this.i_grantAccessToPIIData = true;
        this.i_grantAccessToManageManifestProfiles = true;
        this.i_grantAccessCompanyProfiles = true;
        this.i_grantAccessAircraftProfiles = true;
        this.i_grantAccessToMessages = true;
        this.i_grantAccessToLockedTrips = true;
        this.i_grantAccessToInvoices = true;
        this.i_grantAccessToCompanyBusinessRules = true;
        this.i_grantAccessToVendorProfiles = true;
        this.i_grantAccessCreateTripAlerts = true;
        this.i_grantAccessToManageCompanyEvents = true;
        this.i_grantAccessToHeadlineNews = true;
        this.i_grantAccessToManageAccountGrouping = true;

      }
      else {
        this.i_grantAccessToPIIData = true;
        this.i_grantAccessToManageManifestProfiles = true;
        this.i_grantAccessCompanyProfiles = true;
        this.i_grantAccessAircraftProfiles = true;
        this.i_grantAccessToMessages = true;
        this.i_grantAccessToLockedTrips = false;
        this.i_grantAccessToInvoices = false;
        this.i_grantAccessToCompanyBusinessRules = false;
        this.i_grantAccessToVendorProfiles = false;
        this.i_grantAccessCreateTripAlerts = false;
        this.i_grantAccessToManageCompanyEvents = false;
        this.i_grantAccessToHeadlineNews = false;
        this.i_grantAccessToManageAccountGrouping = false;
      }

      if (afterInitControl) {
        this.f.i_grantAccessToPIIData.setValue(this.i_grantAccessToPIIData);
        this.f.i_grantAccessToManageManifestProfiles.setValue(this.i_grantAccessToManageManifestProfiles);
        this.f.i_grantAccessCompanyProfiles.setValue(this.i_grantAccessCompanyProfiles);
        this.f.i_grantAccessAircraftProfiles.setValue(this.i_grantAccessAircraftProfiles);
        this.f.i_grantAccessToMessages.setValue(this.i_grantAccessToMessages);
        this.f.i_grantAccessToLockedTrips.setValue(this.i_grantAccessToLockedTrips);
        this.f.i_grantAccessToInvoices.setValue(this.i_grantAccessToInvoices);
        this.f.i_grantAccessToCompanyBusinessRules.setValue(this.i_grantAccessToCompanyBusinessRules);
        this.f.i_grantAccessToVendorProfiles.setValue(this.i_grantAccessToVendorProfiles);
        this.f.i_grantAccessCreateTripAlerts.setValue(this.i_grantAccessCreateTripAlerts);
        this.f.i_grantAccessToManageCompanyEvents.setValue(this.i_grantAccessToManageCompanyEvents);
        this.f.i_grantAccessToHeadlineNews.setValue(this.i_grantAccessToHeadlineNews);
        this.f.i_grantAccessToManageAccountGrouping.setValue(this.i_grantAccessToManageAccountGrouping);
      }

    }
    else {
      if (this.selectedUserType == 'customer') {
        if (this.user_role == 'a') {
          this.c_grantAccessToPIIData = true;
          this.c_grantAccessToManageManifestProfiles = true;
          this.c_grantAccessCompanyProfiles = true;
          this.c_grantAccessAircraftProfiles = true;
          this.c_grantAccessSubmitTripsandRequestQuotes = true;
          this.c_grantAccessToMessages = true;
          this.c_grantAccessToAllMessages = true;
          this.c_grantAccessToManageCompanyEvents = true;
          this.c_grantAccessToInvoices = true;
          if (this.customerGroupList.length > 0) {
            this.c_grantAccessToAccountGrouping = true;
          }
          else {
            this.c_grantAccessToAccountGrouping = false;
          }

        }
        else {
          this.c_grantAccessToPIIData = true;
          this.c_grantAccessToManageManifestProfiles = true;
          this.c_grantAccessCompanyProfiles = true;
          this.c_grantAccessAircraftProfiles = true;
          this.c_grantAccessSubmitTripsandRequestQuotes = true;
          this.c_grantAccessToMessages = true;
          this.c_grantAccessToAllMessages = false;
          this.c_grantAccessToManageCompanyEvents = false;
          this.c_grantAccessToInvoices = false;
          this.c_grantAccessToAccountGrouping = false;
        }
        if (afterInitControl) {
          this.f.c_grantAccessToPIIData.setValue(this.c_grantAccessToPIIData);
          this.f.c_grantAccessToManageManifestProfiles.setValue(this.c_grantAccessToManageManifestProfiles);
          this.f.c_grantAccessCompanyProfiles.setValue(this.c_grantAccessCompanyProfiles);
          this.f.c_grantAccessAircraftProfiles.setValue(this.c_grantAccessAircraftProfiles);
          this.f.c_grantAccessSubmitTripsandRequestQuotes.setValue(this.c_grantAccessSubmitTripsandRequestQuotes);
          this.f.c_grantAccessToMessages.setValue(this.c_grantAccessToMessages);
          this.f.c_grantAccessToAllMessages.setValue(this.c_grantAccessToAllMessages);
          this.f.c_grantAccessToManageCompanyEvents.setValue(this.c_grantAccessToManageCompanyEvents);
          this.f.c_grantAccessToInvoices.setValue(this.c_grantAccessToInvoices);
          this.f.c_grantAccessToAccountGrouping.setValue(this.c_grantAccessToAccountGrouping);
          this.customerGroupGUID = "";
          this.f.customer_group_select.setValue(this.customerGroupGUID);
        }

      }
      else {
        // vendor
      }

    }
  }

  userRoleChange(e: any) {
    this.user_role = e.target.value;
    this.setPermission(true);
  }

  c_restrictChange(e: any, controlName: string) {
    //this.dataChanged = true;
    if (controlName == 'c_grantAccessToPIIData') {
      if (this.f.c_grantAccessToPIIData.value == false) {
        this.c_grantAccessToPIIData = false;
        this.c_grantAccessToManageManifestProfiles = false;
        this.c_grantAccessCompanyProfiles = false;
        this.c_grantAccessAircraftProfiles = false;
        this.c_grantAccessSubmitTripsandRequestQuotes = false;
        this.c_grantAccessToMessages = false;
        this.c_grantAccessToManageCompanyEvents = false;
        this.c_grantAccessToInvoices = false;
        this.c_grantAccessToAccountGrouping = false;

        this.register_user_form.get('c_grantAccessToManageManifestProfiles').disable();
        this.register_user_form.get('c_grantAccessCompanyProfiles').disable();
        this.register_user_form.get('c_grantAccessAircraftProfiles').disable();
        this.register_user_form.get('c_grantAccessSubmitTripsandRequestQuotes').disable();
        this.register_user_form.get('c_grantAccessToMessages').disable();
        this.register_user_form.get('c_grantAccessToAllMessages').disable();
        this.register_user_form.get('c_grantAccessToManageCompanyEvents').disable();
        this.register_user_form.get('c_grantAccessToInvoices').disable();
        this.register_user_form.get('c_grantAccessToAccountGrouping').disable();
        this.customerGroupGUID = "";
        this.f.customer_group_select.setValue(this.customerGroupGUID);
        //this.initControls();

      }
      else {
        this.c_grantAccessToPIIData = true;
        this.register_user_form.get('c_grantAccessToManageManifestProfiles').enable();
        this.register_user_form.get('c_grantAccessCompanyProfiles').enable();
        this.register_user_form.get('c_grantAccessAircraftProfiles').enable();
        this.register_user_form.get('c_grantAccessSubmitTripsandRequestQuotes').enable();
        this.register_user_form.get('c_grantAccessToMessages').enable();
        //this.register_user_form.get('c_grantAccessToAllMessages').enable();
        this.register_user_form.get('c_grantAccessToManageCompanyEvents').enable();
        this.register_user_form.get('c_grantAccessToInvoices').enable();
        this.register_user_form.get('c_grantAccessToAccountGrouping').enable();

      }
    }
    else {
      switch (controlName) {
        case "c_grantAccessToManageManifestProfiles":
          this.c_grantAccessToManageManifestProfiles = e.checked;
          break;
        case "c_grantAccessCompanyProfiles":
          this.c_grantAccessCompanyProfiles = e.checked;
          break;
        case "c_grantAccessAircraftProfiles":
          this.c_grantAccessAircraftProfiles = e.checked;
          break;
        case "c_grantAccessSubmitTripsandRequestQuotes":
          this.c_grantAccessSubmitTripsandRequestQuotes = e.checked;
          break;
        case "c_grantAccessToMessages":
          this.c_grantAccessToMessages = e.checked;
          if (this.c_grantAccessToMessages == false) {
            this.c_grantAccessToAllMessages = false;
            this.register_user_form.get('c_grantAccessToAllMessages').disable();
          }
          else {
            this.register_user_form.get('c_grantAccessToAllMessages').enable();
          }
          break;
        case "c_grantAccessToManageCompanyEvents":
          this.c_grantAccessToManageCompanyEvents = e.checked;
          break;
        case "c_grantAccessToInvoices":
          this.c_grantAccessToInvoices = e.checked;
          break;
        case "c_grantAccessToAccountGrouping":
          this.c_grantAccessToAccountGrouping = e.checked;
          break;
      }

    }
    //this.initControls();
    //this.sendDataToParentEndPoint();
  }

  i_restrictChange(e: any, controlName: string) {
    if (controlName == 'i_grantAccessToPIIData') {
      if (this.f.i_grantAccessToPIIData.value == false) {
        this.i_grantAccessToPIIData = false;
        this.i_grantAccessToManageManifestProfiles = false;
        this.i_grantAccessCompanyProfiles = false;
        this.i_grantAccessAircraftProfiles = false;
        this.i_grantAccessToMessages = false;
        this.i_grantAccessToLockedTrips = false;
        this.i_grantAccessToInvoices = false;
        this.i_grantAccessToCompanyBusinessRules = false;
        this.i_grantAccessToVendorProfiles = false;
        this.i_grantAccessCreateTripAlerts = false;
        this.i_grantAccessToManageCompanyEvents = false;
        this.i_grantAccessToHeadlineNews = false;
        this.i_grantAccessToManageAccountGrouping = false;

        this.register_user_form.get('i_grantAccessToManageManifestProfiles').disable();
        this.register_user_form.get('i_grantAccessCompanyProfiles').disable();
        this.register_user_form.get('i_grantAccessAircraftProfiles').disable();
        this.register_user_form.get('i_grantAccessToMessages').disable();
        this.register_user_form.get('i_grantAccessToLockedTrips').disable();
        this.register_user_form.get('i_grantAccessToInvoices').disable();
        this.register_user_form.get('i_grantAccessToCompanyBusinessRules').disable();
        this.register_user_form.get('i_grantAccessToVendorProfiles').disable();
        this.register_user_form.get('i_grantAccessCreateTripAlerts').disable();
        this.register_user_form.get('i_grantAccessToManageCompanyEvents').disable();
        this.register_user_form.get('i_grantAccessToHeadlineNews').disable();
        this.register_user_form.get('i_grantAccessToManageAccountGrouping').disable();
        //this.initControls();
      }
      else {
        this.i_grantAccessToPIIData = true;
        this.register_user_form.get('i_grantAccessToManageManifestProfiles').enable();
        this.register_user_form.get('i_grantAccessCompanyProfiles').enable();
        this.register_user_form.get('i_grantAccessAircraftProfiles').enable();
        this.register_user_form.get('i_grantAccessToMessages').enable();
        this.register_user_form.get('i_grantAccessToLockedTrips').enable();
        this.register_user_form.get('i_grantAccessToInvoices').enable();
        this.register_user_form.get('i_grantAccessToCompanyBusinessRules').enable();
        this.register_user_form.get('i_grantAccessToVendorProfiles').enable();
        this.register_user_form.get('i_grantAccessCreateTripAlerts').enable();
        this.register_user_form.get('i_grantAccessToManageCompanyEvents').enable();
        this.register_user_form.get('i_grantAccessToHeadlineNews').enable();
        this.register_user_form.get('i_grantAccessToManageAccountGrouping').enable();

      }
    }
    else {
      //if (e.checked) {
      switch (controlName) {
        case "i_grantAccessToManageManifestProfiles":
          this.i_grantAccessToManageManifestProfiles = e.checked;
          break;
        case "i_grantAccessCompanyProfiles":
          this.i_grantAccessCompanyProfiles = e.checked;
          break;
        case "i_grantAccessAircraftProfiles":
          this.i_grantAccessAircraftProfiles = e.checked;
          break;
        case "i_grantAccessToMessages":
          this.i_grantAccessToMessages = e.checked;
          break;
        case "i_grantAccessToLockedTrips":
          this.i_grantAccessToLockedTrips = e.checked;
          break;
        case "i_grantAccessToInvoices":
          this.i_grantAccessToInvoices = e.checked;
          break;
        case "i_grantAccessToCompanyBusinessRules":
          this.i_grantAccessToCompanyBusinessRules = e.checked;
          break;
        case "i_grantAccessToVendorProfiles":
          this.i_grantAccessToVendorProfiles = e.checked;
          break;
        case "i_grantAccessCreateTripAlerts":
          this.i_grantAccessCreateTripAlerts = e.checked;
          break;
        case "i_grantAccessToManageCompanyEvents":
          this.i_grantAccessToManageCompanyEvents = e.checked;
          break;
        case "i_grantAccessToHeadlineNews":
          this.i_grantAccessToHeadlineNews = e.checked;
          break;
        case "i_grantAccessToManageAccountGrouping":
          this.i_grantAccessToManageAccountGrouping = e.checked;
          break;
      }
      //}
    }

  }

  onSubmit() {
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.submitted = true;
    if (this.userType == "internal") {
      if (this.selectedUserType == "customer") {
        if (this.f.customer_select.value == "") {
          if (this.errMsg != "")
            this.errMsg += "\n";
          //this.errMsg += "If you choose user type is client, please select a client.";
          this.errMsg += "Please select a client.";
        }
      }
      else {
        if (this.selectedUserType == "vendor") {
          if (this.f.vendor_select.value == "") {
            if (this.errMsg != "")
              this.errMsg += "\n";
            this.errMsg += "If you choose user type is vendor, please select a vendor.";
          }
        }
      }
    }

    if (this.errMsg != "") {
      return;
    }

    if (this.f.first_name.value.trim() == "") {
      this.f.first_name.setErrors({ required: true });
      return;
    }
    if (this.f.last_name.value.trim() == "") {
      this.f.last_name.setErrors({ required: true });
      return;
    }

    if (this.f.email.value.trim() == "") {
      this.f.email.setErrors({ required: true });
      return;
    }
    else {
      this.checkEmail().subscribe(res => {
        if ((res != null || res != undefined) && res == true) {
          //this.errMsg = "The email address has already been used in the company";
          this.f.email.setErrors({ sameEmailValidator: true });
          return;
        }

        if (this.f.username.value == "") {
          this.createUsername().subscribe(res => {
            if ((res != null || res != undefined) && res != "") {
              this.f.username.setValue(res);
              this.formValidation();
            }
          })
        }
        else {
          this.formValidation();
        }

      })
    }

  }

  formValidation() {

    let f1 = true;
    if (this.f.email.value != '') {
      //if (this.userType == "internal") {
      f1 = CustomValidators.internalEmailValidator(this.internalEmailEndsWithList, this.f.email.value, this.selectedUserType);
      if (f1 == false) {
        this.f.email.setErrors({ internalEmailValidator: !f1 });
      }
      //}
    }

    let p = true;
    if (this.f.email.value != "" && this.f.email.value != null && this.f.password_email.value != "" && this.f.password_email.value != null) {
      let p = CustomValidators.textMatchValidator(this.f.email.value, this.f.password_email.value);

      if (this.register_user_form.invalid || p == false) {
        if (p == false) {
          this.f.confirm_email.setErrors({ textMatchValidator: !p });
        }

      }
    }

    if (this.selectedUserType == 'customer' && this.f.c_grantAccessToAccountGrouping.value == true && this.customerGroupList.length > 0) {
      if (this.f.customer_group_select.value == "") {
        this.f.customer_group_select.setErrors({ required: true });
      }
    }
    else {
      this.c_grantAccessToAccountGrouping = false;
      this.f.c_grantAccessToAccountGrouping.setValue(this.c_grantAccessToAccountGrouping);
    }

    if (this.register_user_form.invalid || this.selectedAppList == "" || !f1 || !p || this.errMsg != "") {
      return;
    }

    this.dialogRef = this._dialogService.openConfirmDialog(`Are you sure you want to create the user account? 
      A confirmation email will be sent to ${this.f.email.value.trim()} with final instructions.`);
    this.eventSubscription = this.dialogRef.afterClosed().subscribe(result => {
      this.eventSubscription.unsubscribe();
      if (result) {
        this.loading = true;
        this._authService.updateAccessTime();
        this.user = new UserModel();
        this.user.firstName = this.f.first_name.value.trim();
        this.user.lastName = this.f.last_name.value.trim();
        this.user.email = this.f.email.value.trim();
        this.user.phoneNumber = this.f.phone_number.value.trim();
        this.user.username = this.f.username.value;
        this.user.jobTitle = this.f.job_title.value;

        this.user.customerGuid = null;
        this.user.vendorGuid = null;

        if (this.userType == "internal") {
          if (this.selectedUserType == "customer")
            this.user.customerGuid = this.f.customer_select.value; // store customerId value

          if (this.selectedUserType == "vendor")
            this.user.vendorGuid = this.f.vendor_select.value; // store actual value in dropdown list     
        }
        else {
          if (this.userType == "customer" && this.grantAccessToAccountGrouping && this.customerList.length > 0) {
            this.user.customerGuid = this.f.customer_select.value;
          }
        }
        this.selectedAppList = this.appList[0].applicationId;
        this.user.applicationIdList = this.selectedAppList;//this.appList.filter(a => a.selected).map(a => a.applicationId).toString();
        this.user.enablePasswordChangePolicy = false; //this.f.enable_change_pwd_policy.value;
        if (this.userType == "internal") {
          //this.user.userTypeId = this.f.user_type.value.userTypeId; //this.selectedUserType.userTypeId; 
          //this.user.userType = this.f.user_type.value.userTypeName; // this.selectedUserType.userTypeName;
          this.user.userType = this.selectedUserType;
        }
        //this.user.isAdmin = this.f.isAdmin.value;
        //if (this.f.isAdmin.value) {
        //  this.user.isAB = localStorage.getItem('up.isA');
        //}
        //else {
        //  this.user.isAB = localStorage.getItem('up.isB');
        //}
        if (this.user_role == 'a') {
          this.user.isAB = localStorage.getItem('up.isA');
        }
        else {
          this.user.isAB = localStorage.getItem('up.isB');
        }

        this.user.grantAccessToManageManifestProfiles = false;
        this.user.grantAccessCompanyProfiles = false;
        this.user.grantAccessAircraftProfiles = false;
        this.user.grantAccessSubmitTripsandRequestQuotes = false;
        this.user.grantAccessToMessages = false;
        this.user.grantAccessToManageCompanyEvents = false;
        this.user.grantAccessCreateTripAlerts = false;
        this.user.grantAccessToInvoices = false;
        this.user.grantAccessToHeadlineNews = false;
        this.user.grantAccessToReleaseEvents = false;
        this.user.grantAccessToLockedTrips = false;
        this.user.grantAccessToCompanyBusinessRules = false;
        this.user.grantAccessToVendorProfiles = false;
        this.user.grantAccessToPIIData = false;
        this.user.grantAccessToManageAccountGrouping = false;
        this.user.grantAccessToAccountGrouping = false;
        this.user.grantAccessToAllMessages = false;
        this.user.grantAccessToAirportDocuments = false;
        this.user.grantAccessToFuel = false;
        this.user.grantAccessToAPISCredentials = false;
        this.user.grantAccessToTripCloseout = false;
        this.user.grantAccessSubmitToBilling = false;
        this.user.grantAccessToMessageTemplates = false;
        this.user.grantAccessToHelpGuides = false;
        this.user.grantAccessToAccountingDashboard = false;
        this.user.customerGroupGUID = "";
        if (this.selectedUserType == "internal") {
          this.user.grantAccessToPIIData = this.f.i_grantAccessToPIIData.value;
          this.user.grantAccessToManageManifestProfiles = this.f.i_grantAccessToManageManifestProfiles.value;
          this.user.grantAccessCompanyProfiles = this.f.i_grantAccessCompanyProfiles.value;
          this.user.grantAccessAircraftProfiles = this.f.i_grantAccessAircraftProfiles.value;
          this.user.grantAccessToMessages = this.f.i_grantAccessToMessages.value;
          this.user.grantAccessToLockedTrips = this.f.i_grantAccessToLockedTrips.value;
          this.user.grantAccessToInvoices = this.f.i_grantAccessToInvoices.value;
          this.user.grantAccessToCompanyBusinessRules = this.f.i_grantAccessToCompanyBusinessRules.value;
          this.user.grantAccessToVendorProfiles = this.f.i_grantAccessToVendorProfiles.value;
          this.user.grantAccessCreateTripAlerts = this.f.i_grantAccessCreateTripAlerts.value;
          this.user.grantAccessToManageCompanyEvents = this.f.i_grantAccessToManageCompanyEvents.value;
          this.user.grantAccessToHeadlineNews = this.f.i_grantAccessToHeadlineNews.value;
          this.user.grantAccessToManageAccountGrouping = this.f.i_grantAccessToManageAccountGrouping.value;
          this.user.grantAccessToAirportDocuments = this.f.i_grantAccessToAirport.value;
          this.user.grantAccessToFuel = this.f.i_grantAccessToFuel.value;
          this.user.grantAccessToAPISCredentials = this.f.i_grantAccessToAPIS.value;
          this.user.grantAccessToTripCloseout = this.f.i_grantAccessToTripCloseout.value;
          this.user.grantAccessSubmitToBilling = this.f.i_grantAccessSubmitToBilling.value;
          this.user.grantAccessToMessageTemplates = this.f.i_grantAccessToMessageTemplates.value;
          this.user.grantAccessToHelpGuides = this.f.i_grantAccessToHelpGuides.value;
          this.user.grantAccessToAccountingDashboard = this.f.i_grantAccessToAccountingDashboard.value;
        }
        else {
          if (this.selectedUserType == "customer") {
            this.user.grantAccessToPIIData = this.f.c_grantAccessToPIIData.value;
            this.user.grantAccessToManageManifestProfiles = this.f.c_grantAccessToManageManifestProfiles.value;
            this.user.grantAccessCompanyProfiles = this.f.c_grantAccessCompanyProfiles.value;
            this.user.grantAccessAircraftProfiles = this.f.c_grantAccessAircraftProfiles.value;
            this.user.grantAccessSubmitTripsandRequestQuotes = this.f.c_grantAccessSubmitTripsandRequestQuotes.value;
            this.user.grantAccessToMessages = this.f.c_grantAccessToMessages.value;
            this.user.grantAccessToAllMessages = this.f.c_grantAccessToAllMessages.value;
            this.user.grantAccessToManageCompanyEvents = this.f.c_grantAccessToManageCompanyEvents.value;
            this.user.grantAccessToAccountGrouping = this.f.c_grantAccessToAccountGrouping.value;
            this.user.customerGroupGUID = "";
            if (this.user.grantAccessToAccountGrouping)
              this.user.customerGroupGUID = this.f.customer_group_select.value;
          }
        }

        this.user.isNewUser = true;

        this._identityService.registerUser<ResponseModel<boolean>>(this.user).subscribe(response => {
          if (response != null && response.code == "200") {
            if (response.model == true) {
              this.msg = response.message;
              this.userSaved = true;
            }
            else {
              this.errMsg = response.message
            }
          }
          else {
            if (response.code == "401") {
              this._authService.signOut();
              //this.errMsg = response.message
            }
            else {
              this.errMsg = "Failed to register the user. Please try again later.";
            }
          }
          this.loading = false;
          this.submitted = false;
        })


      }
    })

  }

  checkEmail(): Observable<any> {
    return new Observable<any>(ob => {
      this.user = new UserModel();
      this.user.isNewUser = true;
      this.user.email = this.f.email.value;
      this.user.customerGuid = null;
      this.user.vendorGuid = null;
      if (this.userType == "internal") {
        if (this.selectedUserType == "customer")
          this.user.customerGuid = this.f.customer_select.value; // store customerId value
        if (this.selectedUserType == "vendor")
          this.user.vendorGuid = this.f.vendor_select.value; // store actual value in dropdown list     
      }
      else {
        if (this.grantAccessToAccountGrouping && this.customerList.length > 0) {
          this.user.customerGuid = this.f.customer_select.value;
        }
      }
      try {
        this._identityService.checkEmail<ResponseModel<number>>(this.user).subscribe(response => {
          if (response != null && response.code == "200") {
            if (Number(response.model) > 0) {
              //this.errMsg = '"' + this.f.username.value + '" has been used in our system. Please enter a different username.';                  
              ob.next(true);
            }
            else {
              ob.next(false);
            }
          }
          else {
            ob.next(null);
          }
        });
      }
      catch (error) {
        //console.log(error);
        ob.next(null);
      }
    });

  }

  checkUsername(username: string): Observable<any> {
    return new Observable<any>(ob => {
      this.user = new UserModel();
      this.user.isNewUser = true;
      this.user.username = username;
      try {
        this._identityService.checkUsername<ResponseModel<UserModel>>(this.user).subscribe(response => {
          if (response != null && response.code == "200") {
            if (Number(response.model) > 0) {
              ob.next(true);
            }
            else {
              ob.next(false);
            }
          }
          else {
            ob.next(null);
          }
        });
      }
      catch (error) {
        //console.log(error);
        ob.next(null);
      }
    });

  }

  clickCheckUsername() {
    this.errMsg = "";
    this.msg = "";
    this.passUsername = 0;
    if (this.f.username.value == "") {
      this.passUsername = -2;
    }
    else {
      //this.checkUsername(this.f.username.value);
      this.checkUsername(this.f.username.value).subscribe(res => {
        if ((res != null || res != undefined) && res == true) {
          this.f.username.setErrors({ checkExistingUsername: true });
          this.passUsername = -1;
        }
        else {
          this.f.username.updateValueAndValidity();
          this.passUsername = 1;
          if (this.f.username.invalid) {
            this.passUsername = -2;
          }
        }
      });
    }
  }

  firstNameChange(e: any) {
    let s = e.target.value.trim();
    e.target.value = s;
    if (e.target.value != "" && this.f.last_name.value != "") {
      //this.fillUsernameLength();
      var reg = new RegExp('^[a-zA-Z \',-.]*$');
      let f1 = reg.test(e.target.value)
      let f2 = reg.test(this.f.last_name.value);
      if (f1 && f2) {
        this.createUsername().subscribe(res => {
          if ((res != null || res != undefined) && res != "") {
            this.f.username.setValue(res);
          }
        })
      }
      else {
        this.f.username.setValue('');
      }
    }
    else
      this.f.username.setValue('');
  }

  lastNameChange(e: any) {
    let s = e.target.value.trim();
    e.target.value = s;
    if (e.target.value != "" && this.f.first_name.value != "") {
      //this.fillUsernameLength();
      var reg = new RegExp('^[a-zA-Z \',-.]*$');
      let f1 = reg.test(e.target.value)
      let f2 = reg.test(this.f.first_name.value);
      if (f1 && f2) {
        this.createUsername().subscribe(res => {
          if ((res != null || res != undefined) && res != "") {
            this.f.username.setValue(res);
          }
        })
      }
      else {
        this.f.username.setValue('');
      }
    }
    else
      this.f.username.setValue('');

  }

  createUsername(): Observable<string> {
    return new Observable<string>(ob => {
      let request = new UserModel();
      request.firstName = this.f.first_name.value;
      request.lastName = this.f.last_name.value;
      try {
        this._identityService.generateUsername<ResponseModel<string>>(request).subscribe(response => {
          if (response != null && response.code == "200") {
            if (response.model != "") {
              //this.errMsg = '"' + this.f.username.value + '" has been used in our system. Please enter a different username.';                  
              ob.next(response.model);
            }
            else {
              ob.next(null);
            }
          }
          else {
            ob.next(null);
          }
        });
      }
      catch (error) {
        //console.log(error);
        ob.next(null);
      }
    });

  }


  fillUsernameLength() {
    var n = 0;
    if (this.f.username.value.length < 8) {
      switch (this.f.username.value.length) {
        case 3:
          n = UtilityFunctions.getRandomInt(10000, 99999);
          this.f.username.setValue(this.f.username.value + n.toString());
          break;
        case 4:
          n = UtilityFunctions.getRandomInt(1000, 9999);
          this.f.username.setValue(this.f.username.value + n.toString());
          break;
        case 5:
          n = UtilityFunctions.getRandomInt(100, 999);
          this.f.username.setValue(this.f.username.value + n.toString());
          break;
        case 6:
          n = UtilityFunctions.getRandomInt(10, 99);
          this.f.username.setValue(this.f.username.value + n.toString());
          break;
        case 7:
          n = UtilityFunctions.getRandomInt(1, 9);
          this.f.username.setValue(this.f.username.value + n.toString());
          break;
      }
    }
  }

  usernameOnchange(e: any) {
    //console.log(e.target.value);
    this.passUsername = 0;
    this.checkUsername(e.target.value).subscribe(res => {
      if ((res != null || res != undefined) && res == true) {
        this.f.username.setErrors({ checkExistingUsername: true });
      }
      else {
        this.f.username.updateValueAndValidity();
      }
    });
  }

  clickUserType(u) {
    this.customerGroupGUID = "";
    this.customerGroupList = [];
    if (this.selectedUserType != u) {
      this.selectedUserType = u;

      this.errMsg = "";
      this.msg = "";
      this.passUsername = 0;
      this.submitted = false;
      this.f.email.updateValueAndValidity();
      this.f.customer_select.setValue('');
      this.f.vendor_select.setValue('');
      this.customerList = [];
      this.vendorList = [];
      this.user_role = "a";
      if (u == "customer") {
        this.user_role = "s";
        this.loadClientList();
      }
      else {
        if (u == "vendor") {
          this.user_role = "s";
          this.loadVendorList();
        }
      }
      //this.f.user_role.setValue(this.user_role);
      this.setPermission(false);
      this.initControls();
    }
  }

  loadClientList() {
    this.showSpin = true;
    this._commonService.getCustomers<ResponseModel<CustomerModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.customerList = response.model;
        this.customerList = this.customerList.filter(x => x.customerTypeId == 1);
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.showSpin = false;
    })
  }

  loadVendorList() {
    this.showSpin = true;
    this._commonService.getVendors<ResponseModel<VendorModel[]>>().subscribe(response => {
      if (response != null && response.code == "200") {
        this.vendorList = response.model;
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }

      }
      this.showSpin = false;
    })
  }

  //changeUserType(e: UserTypeModel) {

  //  this.f.email.updateValueAndValidity();

  //  if (e.userTypeName.toLowerCase() == "customer") {

  //    this.f.customer_select.setValue('');

  //  }
  //  else {
  //    if (e.userTypeName.toLowerCase() == "vendor") {      
  //      this.f.vendor_select.setValue('');       
  //    }
  //  }
  //}

  changeCustomer(e: any) {
    this.f.email.updateValueAndValidity();
    this.customerGroupList = [];
    this.customerGroupGUID = "";
    if (this.selectedUserType == 'customer') {
      this.getCustomerGroupList();
    }

  }

  changeVendor(e: any) {
    //alert(e.target.value);
    this.f.email.updateValueAndValidity();

  }

  getCustomerGroupList() {
    let req = new CustomerGroupModel();
    req.customerGUID = this.customerGUID;
    this._customerGroupService.getCustomerGroupListBycId<ResponseModel<CustomerGroupModel[]>>(req).subscribe(response => {
      if (response != null && response.code == "200") {
        this.c_grantAccessToAccountGrouping = false;
        this.customerGroupList = response.model;
        if (this.customerGroupList.length > 0) {
          if (this.user_role == 'a') {
            this.c_grantAccessToAccountGrouping = true;
          }
          else {
            this.c_grantAccessToAccountGrouping = false;
          }
        }
        this.f.c_grantAccessToAccountGrouping.setValue(this.c_grantAccessToAccountGrouping);
      }
    })
  }
  //checkAutoGeneratePwd() {
  //  //alert(this.f.auto_generate_pwd.value);
  //  if (this.f.auto_generate_pwd.value == true) {
  //    this._identityService.generatePassword<ResponseModel<string>>().subscribe(response => {
  //      if (response != null && response.code == "200") {
  //        if (response.message == "") {
  //          this.f.password.setValue(response.model);
  //          this.f.confirm_password.setValue(response.model);
  //        }
  //      }
  //    })

  //  }
  //}

  clickReset() {
    //if (this.userType == "internal") {
    //  this.customerGroupGUID = "";
    //  this.customerGUID = "";
    //  this.user_role = 'a';
    //  this.selectedUserType = this.userType;
    //  this.clickUserType(this.userType);
    //}
    //else {
    //  if (this.userType == "customer") {
    //    this.user_role = 's';
    //    this.selectedUserType = this.userType;
    //  }
    //}
    if (this.selectedUserType == "internal") {
      this.user_role = 'a';
    }
    else {
      this.user_role = 's';
    }

    this.setPermission(false);

    this.initControls();


    this.errMsg = "";
    this.msg = "";
    this.submitted = false;
    this.passUsername = 0;
  }


}
