import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEventType, HttpClientModule, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";
import { GlobalConstant } from "../common-utility/global-constant";
import { AuthenticateService } from "./authenticate.service";
import { TripModel } from "../models/trip.model";
import { TripRevisionModel } from "../models/trip-revision.model";
import { GroundStopModel } from "../models/ground-stop.model";
import { TripLegModel } from "../models/trip-leg.model";
import { GroundStopManifestModel } from "../models/ground-stop-manifest.model";
import { GroundStopTaskModel } from "../models/ground-stop-task.model";


@Injectable()
export class TripLegService {
  constructor(private readonly http: HttpClient, private readonly _authService: AuthenticateService) {

  }

  getTripClientListForLegByDateTimeRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripClientListForLegByDateTimeRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripRegistrationListForLegByDateTimeRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripRegistrationListForLegByDateTimeRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripAirportListForLegByDateTimeRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripAirportListForLegByDateTimeRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripPersonListForLegByDateTimeRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripPersonListForLegByDateTimeRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripVendorListForLegByDateTimeRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripVendorListForLegByDateTimeRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripCodeListForLegByDateTimeRange<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripCodeListForLegByDateTimeRange", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripLegListByConditions<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripLegListByConditions", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getGroundStopLegListByConditions<T>(request: TripModel, forReport: boolean = false): Observable<T> {
  //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getGroundStopLegListByConditions?forReport="+forReport, request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripRevisionUserNameListBydgId_agId<T>(request: TripRevisionModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripRevisionUserNameListBydgId_agId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTaskListBygIdTaskType<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTaskListBygIdTaskType", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripLegBydgIdagId<T>(request: TripLegModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripLegBydgIdagId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  insertGroundStopAirport<T>(request: GroundStopModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/insertGroundStopAirport", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripLegPersonListBytId<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripLegPersonListBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getActiveTripLegListForPersonEditBytId<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getActiveTripLegListForPersonEditBytId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getPersonDocumentForLegBygsIdpId<T>(request: TripLegModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getPersonDocumentForLegBygsIdpId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  updateGroundStopsForLegList<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/updateGroundStopsForLegList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  saveGroundStopManifestAndDocumentForLeg<T>(request: GroundStopManifestModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/saveGroundStopManifestAndDocumentForLeg", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  removeGroundStopManifestForLeg<T>(request: GroundStopManifestModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/removeGroundStopManifestForLeg", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }
  
  removeGroundStopManifestForAllLegs<T>(request: GroundStopManifestModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/removeGroundStopManifestForAllLegs", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripLegServiceTypeListBytIdsClass<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripLegServiceTypeListBytIdsClass", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  createGroundStopTaskFromLeg<T>(request: GroundStopTaskModel[]): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/createGroundStopTaskFromLeg", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }


  getAlertListForTripLegs<T>(request: TripLegModel[]): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getAlertListForTripLegs", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripLegDocumentListByIds<T>(request: TripLegModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripLegDocumentListByIds", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  addPersonToLeg<T>(request: GroundStopManifestModel[]): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/addPersonToLeg", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  updateFerryFlightByLeg<T>(request: TripLegModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/updateFerryFlightByLeg", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getPersonForEditBygsIdpId<T>(request: GroundStopManifestModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getPersonForEditBygsIdpId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripLegPersonListBydgIdagId<T>(request: TripLegModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getTripLegPersonListBydgIdagId", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  updateGroundStopByTripLeg<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/updateGroundStopByTripLeg", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getSimpleGroundStopLegList<T>(request: TripModel): Observable<T> {
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getSimpleGroundStopLegList?", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  insertGroundStopAirportList<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/insertGroundStopAirportList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  updateGroundStopAirport<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/updateGroundStopAirport", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }
  downloadTripCSV<T>(trip:TripModel): Observable<T>{
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1',   
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/downloadTripCSV", trip, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));
  }

  getContingencyTripLegList<T>(request: TripModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getContingencyTripLegList", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getManifestDetails<T>(request: GroundStopManifestModel): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.post<T>(GlobalConstant.baseUrl + "TripLeg/getManifestDetails", request, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

  getTripActivityByAirportIDTimeSpan<T>(airportID:number, timeSpan:string): Observable<T> {
    //debugger;
    var headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + localStorage.getItem('token'),
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      //'Pragma': 'no-cache',
      'Expires': '-1'
    });

    return this.http.get<T>(GlobalConstant.baseUrl + "TripLeg/getTripActivityByAirportIDTimeSpan?airportID=" + airportID + "&timeSpan=" + timeSpan, {
      headers: headerOptions
    }).pipe(retry(0), catchError((error: HttpErrorResponse) => { return throwError(error.message) }));

  }

}
