import { Component, Inject, OnInit, ViewChild, AfterViewInit, Input, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap, NavigationExtras } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl, FormsModule, FormControlDirective } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CustomValidators } from '../common-utility/custom.validators';
import { ResponseModel } from '../models/response.model';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AirportService } from '../services/airport.service';
import { DialogService } from '../services/dialog.service';
import { NoteTypeModel } from '../models/note-type.model';
import { AirportNotesModel } from '../models/airport-notes.model';
import { BusinessRulesService } from '../services/business-rules-service';


@Component({
  selector: 'app-airport-notes-edit-dialog',
  templateUrl: './airport-notes-edit-dialog.component.html',
  styleUrls: ['./airport-notes-edit-dialog.component.css']
})
/** airport-profile-setup-add component*/
export class AirportNotesEditDialogComponent implements OnInit {
  airport_notes_edit_form: UntypedFormGroup;
  @Input() dialogTitle: string;
  @Input() airportID: number;
  @Input() airportNoteID: number;
  loading = false;
  submitted = false;
  errMsg: string;
  msg: string;
  showSpin: boolean = true;
  noteTypeList: NoteTypeModel[];
  airport_note: string;
  selectedNoteTypeId: number;
  modifiedBy: string;
  modifiedDate: string;
  isModified: boolean;
  isActive: number;
  showSuccessMsg: boolean = false;
  requiresYN: boolean = false;
  isRequired: number = 0;
  revisionDate: string;
  revisionRemarks: string;
  businessRuleCount: number;
  advisoryCount: number;
  noteTypeDescription: string;
  leadTime: string;
  expectedApproval: string;
  validTimeStart: string;
  validTimeEnd: string;
  @ViewChild('noteTypeList') noteTypeListRef: ElementRef

  constructor(private readonly _dialogRef: MatDialogRef<AirportNotesEditDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private readonly _formBuilder: UntypedFormBuilder, private readonly _authService: AuthenticateService,
    private readonly _commonService: CommonService,
    private readonly _airportService: AirportService,
    private readonly _dialogService: DialogService,
    private readonly _businessRulesService:BusinessRulesService
  ) {
    this.airportID = data.airportID;
    this.airportNoteID = data.airportNoteID;
    this.dialogTitle = data.dialogTitle;

  }

  getAllData(): Observable<any[]> {

    let getNoteType = this._commonService.getNoteTypeListByxId<ResponseModel<NoteTypeModel[]>>(this.airportID.toString(),"Airport");

    let getAirportNoteResponse = this._airportService.getAirportNoteByAirportNoteId<ResponseModel<AirportNotesModel>>(this.airportNoteID);

    return forkJoin([getNoteType, getAirportNoteResponse]);
  }

  ngOnInit() {
    this.showSpin = true;
    this._authService.updateAccessTime();
    this.errMsg = "";
    this.msg = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.noteTypeList = responses[0].model;
          
       //   this.selectedAirportTypeId = null;// this.airportTypeList[0].airportTypeID;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }

      if (this.airportNoteID != null && this.airportNoteID != 0) {
        if (responses[1] != null) {
          if (responses[1].code == "200" && responses[1].message == "") {
            let airportNote = new AirportNotesModel();
            airportNote = responses[1].model;
              this.noteTypeList = this.noteTypeList.concat([{ noteTypeID: airportNote.noteTypeID, noteTypeDescription: airportNote.noteType, requiresYN:airportNote.requiresYN }]);
            this.selectedNoteTypeId = airportNote.noteTypeID;
            this.requiresYN = airportNote.requiresYN;
            this.airport_note = airportNote.airportNote;
            this.modifiedBy = airportNote.modifiedBy;
            this.modifiedDate = airportNote.modifiedDate;
            this.isRequired = airportNote.isRequired;
            this.revisionDate = airportNote.revisionDate;
            this.revisionRemarks = airportNote.revisionRemarks;
            this.businessRuleCount = airportNote.businessRuleCount;
            this.advisoryCount = airportNote.advisoryCount;
            this.noteTypeDescription = airportNote.noteType;
            this.leadTime = airportNote.leadTime;
            this.expectedApproval = airportNote.expectedApproval;
            this.validTimeStart = airportNote.validTimeStart;
            this.validTimeEnd = airportNote.validTimeEnd;
            if (airportNote.isActive == true)
              this.isActive = 1;
            else
              this.isActive = 0;

          }
        }

      }

      this.initControls();

      this.showSpin = false;


    });

  }

  initControls() {
    this.airport_notes_edit_form = this._formBuilder.group({
      note_type: this.selectedNoteTypeId,
      airport_note: this.airport_note,
      note_status: this.isActive,
      modifiedBy: this.modifiedBy,
      modifiedDate: this.modifiedDate,
      revisionDate:this.revisionDate,
      remarks: this.revisionRemarks,
      lead_time: [this.leadTime, Validators.pattern("^(([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?)$")],
      approval: [this.expectedApproval, Validators.pattern("^(([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?)$")],
      valid_time_start: [this.validTimeStart,  Validators.pattern("^(([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?)$")],
      valid_time_end: [this.validTimeEnd, Validators.pattern("^(([0-9]{1,2}[dD])?([0-9]{1,2}[hH])?([0-9]{1,3}[mM])?)$")],
    });
  }

  get f() { return this.airport_notes_edit_form.controls; }

  updateNoteType() {
    this.requiresYN = this.noteTypeList.find(x => x.noteTypeID === this.f.note_type.value).requiresYN;
    this.noteTypeDescription = this.noteTypeList.find(x => x.noteTypeID === this.f.note_type.value).noteTypeDescription;
    this.selectedNoteTypeId = this.f.note_type.value; 
  }

  clickSave() {
    this._authService.updateAccessTime();
    this.submitted = true;
    this.errMsg = "";
    if (this.f.note_type.value == null) {
      this.errMsg = "Note Type is required.";
    }
    if (this.f.airport_note.value == null) {
      if (this.errMsg != "") {
        this.errMsg += "\n";
        this.errMsg += "Note is required.";
      }
      else
        this.errMsg = "Note is required.";    
    }
    if (this.airport_notes_edit_form.invalid || this.errMsg != "") {
      return;
    }
    
    if ((this.isRequired==1 && this.businessRuleCount == 0) || (this.isRequired==0 && this.businessRuleCount > 0)) {
      let isAdd=false;
      if (this.businessRuleCount == 0)
        isAdd = true;
      let msg = "Business rule will be ";
      msg += isAdd? "created." : "removed.";

      this._dialogService.openConfirmDialog(msg, false, "OK", "Cancel").afterClosed().subscribe(response => {
        if (response) {
          this._businessRulesService.addRemoveBusinessRules<ResponseModel<boolean>>(this.airportID, this.selectedNoteTypeId, isAdd).subscribe(response => {
            if (response != null) {
              if (response.code == "200" && response.message == "") {
                if (response.model == false) {
                  this.errMsg = "An error occurred updating business rules."
                }
                else
                  this.saveData(true);
              }
            }
          });
        }
        else
          return;

      });
    }
    else {
      this.saveData(false);
    }
   

  }

  saveData(refresh:boolean) {
    this.loading = true;
    let obj = new AirportNotesModel();
    obj.airportNotesID = 0;
    if (this.airportNoteID != 0 && this.airportNoteID != null)
      obj.airportNotesID = this.airportNoteID;
    obj.airportID = this.airportID;
    obj.noteTypeID = this.selectedNoteTypeId;
    obj.airportNote = this.f.airport_note.value;
    if (this.f.note_status.value == "1")
      obj.isActive = true;
    else
      obj.isActive = false;
    obj.revisionDate = this.f.revisionDate.value;
    obj.revisionRemarks = this.f.remarks.value;
    obj.isRequired = this.isRequired;
    obj.leadTime = this.f.lead_time.value;
    obj.expectedApproval = this.f.approval.value;
    obj.validTimeStart = this.f.valid_time_start.value;
    obj.validTimeEnd = this.f.valid_time_end.value;
    this._airportService.saveAirportNote<ResponseModel<number>>(obj).subscribe(response => {
      if (response != null && response.code == "200") {
        if (response.model != null) {
          this.airportNoteID = response.model;
          let d = new Date();
          let m = d.getMonth() + 1;
          let dt = m.toString() + '/' + d.getDate().toString() + '/' + d.getFullYear().toString() + ' ' + d.getHours().toString() + ':' + d.getMinutes().toString();
          this.f.modifiedBy.setValue(localStorage.getItem('un'));
          this.f.modifiedDate.setValue(dt);
          this.showSuccessMsg = true;
          setTimeout(() => {
            this.showSuccessMsg = false;
          }, 1000);
          this.isModified = true;
          if (refresh)
            this.ngOnInit();
        }
        else {
          this._dialogService.openAlertDialog("Failed to save this record at this time. Please try later.");
          //this._dialogRef.close(this.isModified);
        }
      }
      else {
        if (response.code == "401") {
          //this.errMsg = response.message
          this._authService.signOut();
        }
        else {
          this.errMsg = "Failed to save the record. Please try again later.";
        }
      }
      this.loading = false;
    });
  }

  clickAddNew() {
    this._authService.updateAccessTime();
    this.submitted = false;
    this.errMsg = "";
    this.msg = "";
    this.airportNoteID = 0;
    this.selectedNoteTypeId = null;
    this.airport_note = "";
    this.modifiedBy = "";
    this.modifiedDate = "";
    this.isActive = 1;
    this.noteTypeList = [];
    this.getAllData().subscribe(responses => {
      if (responses[0] != null) {
        if (responses[0].code == "200" && responses[0].message == "") {
          this.noteTypeList = responses[0].model;

          //   this.selectedAirportTypeId = null;// this.airportTypeList[0].airportTypeID;
        }
        else {
          if (responses[0].code == "401") {
            this._authService.signOut();
          }
        }
      }
    });
    this.initControls();    
  }

  clickClose() {
     this._dialogRef.close(this.isModified);
  }


}
